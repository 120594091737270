import axios from "axios";
import { JsonResp, unwrapJsonResp } from "./JsonResp";

export interface Plan {
  active: boolean;
  currentPlan?: boolean;
  isMonthly?: boolean;
  displayName: string;
  displayPrice: number;
  igIncluded: number;
  igIncludedUpTo?: number;
  otherIncludedUpTo?: number | string;
  otherIncluded: number;
  price: { usd: number; aud: number; eur: number; gbp: number };
  primaryCbId: { monthly: string; annual: string };
  secondaryCbIds: string[];
  tooManyAccounts: boolean;
  _id: string;
}

interface PlansData {
  plans: Plan[];
}

export async function getPlans() {
  const { data } = await axios.get<JsonResp<PlansData>>("/plans");
  return unwrapJsonResp(data).plans;
}

interface ChangePlanPayload {
  planId: string;
  ugcLibraryAddon: boolean;
}

export interface PlanEstimate {
  subscription_estimate: {
    currency_code: string;
    next_billing_at: number;
  };
  invoice_estimate?: {
    recurring: boolean;
    date: number;
    sub_total: number;
    total: number;
    credits_applied: number;
    amount_paid: number;
    amount_due: number;
    currency_code: string;
  };
  next_invoice_estimate?: {
    recurring: boolean;
    date: number;
    sub_total: number;
    total: number;
    credits_applied: number;
    amount_paid: number;
    amount_due: number;
    currency_code: string;
  };
  credit_note_estimates: {
    sub_total: number;
    total: number;
    amount_allocated: number;
    amount_available: number;
    round_off_amount: number;
    currency_code: string;
  }[];
}

export async function getPlanEstimate(payload: ChangePlanPayload) {
  const { data } = await axios.post<JsonResp<{ estimate: PlanEstimate }>>(
    "/profile/changePlanEstimate",
    payload
  );

  return unwrapJsonResp(data).estimate;
}

export async function changePlan(payload: ChangePlanPayload) {
  await axios.post("/profile/changePlan", payload);
}

export async function getPlanEstimateV2(payload: {
  plan: string;
  numberAccounts: number;
  currency: string;
  billingPeriod: "month" | "year";
}) {
  const { data } = await axios.post("/user/v2/getPlanEstimate", payload);

  return unwrapJsonResp(data);
}

export async function getPlansV2(payload: {
  _id: string;
  currency: string;
  numberAccounts: number;
}) {
  const { data } = await axios.post("/user/v2/getPlans", payload);

  return unwrapJsonResp(data);
}
