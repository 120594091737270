export const ngDeps = {
  $rootScope: {
    go(state) {
      return Promise.resolve(state);
    },
  },
};

export function injectNgDeps(deps) {
  Object.assign(ngDeps, deps);
}

export function getNgDep(dep) {
  if (!ngDeps[dep]) {
    return null;
  }
  return ngDeps[dep];
}

export default ngDeps;
