import React from "react";

import { FIELDS } from "constants/PostFormFields";
import { ExternalLink } from "ui/Link";
import { Alert, AlertType } from "ui/alert";
import { getStoryLabel } from "utils/accounts";
import { ONLY_MANUAL_PLATFORMS } from "constants/Platforms";
import { findManualPlatform } from "utils/accounts";

export function PostAlerts({
  postFormValue,
  oldTiktokAccounts,
  ttIsNotification,
  oldThreadsAccounts,
  thIsNotification,
  isThreadsPostingEnabled,
  platforms,
  selectedPlatform,
}) {
  const hasManualTTPlatform = findManualPlatform(
    postFormValue?.accounts || [],
    "TT"
  );
  const hasManualTHPlatform = findManualPlatform(
    postFormValue?.accounts || [],
    "TH"
  );

  const hasManualPlatform =
    hasManualTTPlatform ||
    hasManualTHPlatform ||
    platforms.some((platform) => ONLY_MANUAL_PLATFORMS.includes(platform.TYPE));

  const isMultiPostSelected = selectedPlatform === "LINKED";

  /* Tiktok posting available */
  const isTTPostSelected = selectedPlatform === "TT";

  const isTTPostSelectedOrIsMultiPostSelected =
    isTTPostSelected || isMultiPostSelected;

  const isManualTTAccountPostOrMultiPosts =
    hasManualTTPlatform || isMultiPostSelected;

  const showTTAlertForOldTTAccounts =
    isTTPostSelectedOrIsMultiPostSelected &&
    isManualTTAccountPostOrMultiPosts &&
    oldTiktokAccounts.length > 0;
  const showTTAlertToSwitchToAutoPosting =
    isTTPostSelectedOrIsMultiPostSelected &&
    ttIsNotification &&
    oldTiktokAccounts.length === 0;

  /* Threads posting */
  const isTHPostSelected = selectedPlatform === "TH";

  const isTHPostSelectedOrIsMultiPostSelected =
    isTHPostSelected || isMultiPostSelected;

  const isManualTHAccountPostOrMultiPosts =
    hasManualTHPlatform || isMultiPostSelected;

  const showAlertForOldTHAccounts =
    isThreadsPostingEnabled &&
    isTHPostSelectedOrIsMultiPostSelected &&
    isManualTHAccountPostOrMultiPosts &&
    oldThreadsAccounts.length > 0;

  /* Note re Reels - keep here in case customers already have them scheduled */
  const showReelsAlert = postFormValue[FIELDS.STORY_TYPE] === "reel";

  /* Manual posting alert */
  const showManualPostingAlert =
    (isMultiPostSelected && hasManualPlatform) ||
    ONLY_MANUAL_PLATFORMS.includes(selectedPlatform) ||
    (postFormValue.isStory && postFormValue[FIELDS.PUBLISH_STORY_MANUALLY]);

  return (
    <div>
      {showReelsAlert && (
        <Alert type={AlertType.info} icon={true} className={`tw-mb-4`}>
          All videos under 90 seconds are now Reels! You can auto-publish a reel
          by uploading a video. Prefer getting a notification? Continue here.
        </Alert>
      )}

      {showTTAlertForOldTTAccounts && (
        <>
          <Alert type={AlertType.info} icon={true}>
            Auto Publishing now available for TikTok Business Accounts!{" "}
            <ExternalLink href="https://help.skedsocial.com/en/articles/8310119-tiktok-auto-publishing-is-here">
              Learn more here
            </ExternalLink>
            .
          </Alert>

          {!showManualPostingAlert && (
            <Alert type={AlertType.info} icon={true}>
              This post will not be published automatically to your notification
              accounts. We will notify the users you have set up in the account
              settings to publish.{" "}
              <ExternalLink href="https://help.skedsocial.com/en/articles/3969654-how-does-manual-story-publishing-work#_ga=2.50066982.1217543947.1693183197-1999928699.1691640271">
                More about manual TikTok post publishing.
              </ExternalLink>
            </Alert>
          )}
        </>
      )}
      {showTTAlertToSwitchToAutoPosting && <></>}

      {showAlertForOldTHAccounts && (
        <>
          <Alert type={AlertType.info} icon={true}>
            Auto Publishing now available for Threads Accounts!
          </Alert>
        </>
      )}

      {showManualPostingAlert && (
        <Alert type={AlertType.info} icon={true}>
          This{" "}
          {getStoryLabel(
            postFormValue[FIELDS.STORY_TYPE],
            platforms,
            selectedPlatform,
            postFormValue?.accounts || []
          )}{" "}
          will <b>not</b> be published automatically
          {hasManualPlatform ? ` to your notification accounts` : null}. We will
          notify the users you have set up in the account settings to publish.{" "}
          <ExternalLink href="https://help.skedsocial.com/en/articles/3969654-how-does-manual-story-publishing-work?_ga=2.96062876.1607453936.1596491428-1055563477.1595571774">
            More about manual{" "}
            {selectedPlatform === "TT" || selectedPlatform === "TH"
              ? "post"
              : getStoryLabel(
                  postFormValue[FIELDS.STORY_TYPE],
                  platforms,
                  selectedPlatform,
                  postFormValue?.accounts || []
                )}{" "}
            publishing
          </ExternalLink>
          .
        </Alert>
      )}
    </div>
  );
}
