import {
  TOGGLE_COLLABORATE_WIDGET,
  BULK_CHANGE_COLLAB_STATE_ROUTINE,
  CHANGE_COLLAB_STATE_ROUTINE,
  HANDLE_STATUS_CHANGED,
  INIT_EXTERNAL_COLLAB_ROUTINE,
  ADD_EXTERNAL_COLLAB_THEME_ROUTINE,
  UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE,
  DELETE_EXTERNAL_COLLAB_THEME_ROUTINE,
  ADD_EXTERNAL_COLLAB_LINK_ROUTINE,
  UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE,
  DELETE_EXTERNAL_COLLAB_LINK_ROUTINE,
  GET_COLLABORATION_COMMENTS_ROUTINE,
  GET_COLLABORATION_USERS_ROUTINE,
  GET_COLLABORATION_UNREADS_COUNT_ROUTINE,
  MARK_COLLABORATION_CHANNEL_AS_READ,
  ADD_COLLABORATION_COMMENT_ROUTINE,
  EDIT_COLLABORATION_COMMENT_ROUTINE,
  DELETE_COLLABORATION_COMMENT_ROUTINE,
  NEW_COLLABORATION_COMMENT_RECEIVED,
  COLLABORATION_COMMENT_EDITED,
  NEW_UNREAD_NOTIFICATION_RECEIVED,
  CLEAN_STORE_COMMENTS,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

export const toggleCollaborateWidget = (index, post) => ({
  type: TOGGLE_COLLABORATE_WIDGET,
  index,
  post,
});

export const bulkChangeCollabState = createAction(
  BULK_CHANGE_COLLAB_STATE_ROUTINE.TRIGGER,
  function prepare(postIds, postType, key) {
    return {
      payload: {
        postIds,
        postType,
        key,
      },
    };
  }
);

export const changeCollabState = createAction(
  CHANGE_COLLAB_STATE_ROUTINE.TRIGGER,
  function prepare(postIds, postType, key, callback) {
    return {
      payload: {
        postIds,
        postType,
        key,
        callback,
      },
    };
  }
);

export const initExternalCollab = createAction(
  INIT_EXTERNAL_COLLAB_ROUTINE.TRIGGER
);
export const addExternalCollabTheme = createAction(
  ADD_EXTERNAL_COLLAB_THEME_ROUTINE.TRIGGER,
  function prepare(theme) {
    return {
      payload: {
        theme,
      },
    };
  }
);
export const updateExternalCollabTheme = createAction(
  UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE.TRIGGER,
  function prepare(theme) {
    return {
      payload: {
        theme,
      },
    };
  }
);
export const deleteExternalCollabTheme = createAction(
  DELETE_EXTERNAL_COLLAB_THEME_ROUTINE.TRIGGER,
  function prepare(id) {
    return {
      payload: {
        id,
      },
    };
  }
);
export const addExternalCollabLink = createAction(
  ADD_EXTERNAL_COLLAB_LINK_ROUTINE.TRIGGER,
  function prepare(link) {
    return {
      payload: {
        link,
      },
    };
  }
);
export const updateExternalCollabLink = createAction(
  UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE.TRIGGER,
  function prepare(link) {
    return {
      payload: {
        link,
      },
    };
  }
);
export const deleteExternalCollabLink = createAction(
  DELETE_EXTERNAL_COLLAB_LINK_ROUTINE.TRIGGER,
  function prepare(id) {
    return {
      payload: {
        id,
      },
    };
  }
);

export const getCollaborationComments = createAction(
  GET_COLLABORATION_COMMENTS_ROUTINE.TRIGGER,
  function prepare(channelId) {
    return {
      payload: {
        channelId,
      },
    };
  }
);

export const getCollaborationUsers = createAction(
  GET_COLLABORATION_USERS_ROUTINE.TRIGGER,
  function prepare() {
    return {
      payload: {},
    };
  }
);

export const getCollaborationUnreadsCount = createAction(
  GET_COLLABORATION_UNREADS_COUNT_ROUTINE.TRIGGER,
  function prepare() {
    return {
      payload: {},
    };
  }
);

export const markCollaborationChannelAsRead = createAction(
  MARK_COLLABORATION_CHANNEL_AS_READ,
  function prepare(channelId) {
    return {
      payload: { channelId },
    };
  }
);

export const addCollaborationComment = createAction(
  ADD_COLLABORATION_COMMENT_ROUTINE.TRIGGER,
  function prepare(channelId, body, metadata, done) {
    return {
      payload: { channelId, body, metadata, done },
    };
  }
);

export const editCollaborationComment = createAction(
  EDIT_COLLABORATION_COMMENT_ROUTINE.TRIGGER,
  function prepare(channelId, commentId, body, done) {
    return {
      payload: { channelId, commentId, body, done },
    };
  }
);

export const deleteCollaborationComment = createAction(
  DELETE_COLLABORATION_COMMENT_ROUTINE.TRIGGER,
  function prepare(channelId, commentId, done) {
    return {
      payload: { channelId, commentId, done },
    };
  }
);

export const newCollaborationCommentReceived = createAction(
  NEW_COLLABORATION_COMMENT_RECEIVED,
  function prepare(comment, userId) {
    return {
      payload: { comment, userId },
    };
  }
);

export const collaborationCommentEdited = createAction(
  COLLABORATION_COMMENT_EDITED,
  function prepare(comment) {
    return {
      payload: { comment },
    };
  }
);

export const newUnreadNotificationReceived = createAction(
  NEW_UNREAD_NOTIFICATION_RECEIVED,
  function prepare(channelId) {
    return {
      payload: { channelId },
    };
  }
);

export const handleStatusChanged = createAction(
  HANDLE_STATUS_CHANGED,
  function prepare(postType, post) {
    return {
      payload: { postType, post },
    };
  }
);

export const cleanStoreComments = createAction(
  CLEAN_STORE_COMMENTS,
  function prepare() {
    return {
      payload: {},
    };
  }
);
