export interface DropboxFile {
  bytes: number;
  icon: string;
  id: string;
  isDir: boolean;
  link: string;
  linkType: string;
  name: string;
  thumbnailLink: string;
}

// const options = { responseType: "blob" };
export function selectFileFromDropBox(): Promise<Array<DropboxFile>> {
  return new Promise((resolve) => {
    window.Dropbox.choose({
      success: (files) => resolve(files),
      extensions: ["images", "video"],
      linkType: "direct",
      multiselect: true,
    });
  });
}
