import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkedIcon } from "ui";
import isEqual from "lodash/isEqual";
import Disclaimer from "components/MediaLibrary/SourceMedia/Disclaimer/Disclaimer";
import {
  selectExistingHashtag,
  selectHashtagFeedView,
} from "actions/sourceMedia";
import {
  getHashtagFeedView,
  getSelectedHashtag,
  getSelectedIgAccount,
} from "selectors/hashtagFeedSelectors";
import {
  HASHTAG_FEED_VIEWS,
  DEFAULT_HASHTAG_FILTER,
} from "constants/MediaLibrary";
import { Select } from "ui/ecosystems/forms";
import { MediaFilesFilters } from "entities/media-files";
import { Flex } from "ui/commonStyled/layout";
import { getAccountsForPlatform } from "selectors/skedCoreSelectors";
import { useMediaFilesFilters } from "entities/media-files";
import { MediaFilesSelectedFilters } from "entities/media-files/componets/filters/media-files-filters";

const HashtagFeedActions = ({ filterState, setFilterState }) => {
  const dispatch = useDispatch();
  const selectedHashtag = useSelector(getSelectedHashtag);
  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const hashtagFeedView = useSelector(getHashtagFeedView);

  const accounts = useSelector((state) => getAccountsForPlatform(state, "IG"));

  const refreshMedia = (filterState) => {
    dispatch(
      selectExistingHashtag(
        selectedIgAccount._id,
        selectedHashtag.hashtag_id,
        filterState
      )
    );
  };

  const filterOnChange = (value) => {
    // NOTE: (Andrey) modify login to _id
    const newFilterState = {
      ...value,
      excludeAccounts: accounts
        .filter((account) => value.excludeAccounts.includes(account.login))
        .map((account) => account._id),
    };

    // NOTE: (Andrey) after rerender filter if it has value fired onChange, for avoid this check if old and new value is equal
    if (isEqual(filterState, newFilterState)) return;

    setFilterState(newFilterState);
    refreshMedia(newFilterState);
  };

  // NOTE: (Andrey) modify _id to login
  const filterValues = {
    ...filterState,
    excludeAccounts: accounts
      .filter((account) => filterState.excludeAccounts.includes(account._id))
      .map((account) => account.login),
  };

  const [selectedFeedView, setSelectedFeedView] = useState(hashtagFeedView);
  const [filterProps] = useMediaFilesFilters({
    values: filterValues,
    onChange: filterOnChange,
    onClear: () => filterOnChange(DEFAULT_HASHTAG_FILTER),
    tags: accounts.map((account) => account.login),
  });

  let viewOptions = [
    {
      value: HASHTAG_FEED_VIEWS.RECENT,
      label: `Most recent`,
    },
    {
      value: HASHTAG_FEED_VIEWS.TOP,
      label: `Top media`,
    },
  ];
  const handleFeedChange = (value) => {
    setSelectedFeedView(value);
    dispatch(selectHashtagFeedView(value, filterState));
  };

  return (
    <div>
      <Flex align="center" marginBottom={10}>
        <Flex align="center">
          <MediaFilesFilters
            {...filterProps}
            availableFilters={["excludeAccounts"]}
          />
          <MediaFilesSelectedFilters {...filterProps} />
        </Flex>
        <Flex align="center" marginLeft={"auto"}>
          <div data-testid="selectHashtagView">
            <Select
              options={viewOptions}
              value={selectedFeedView}
              headerTitle="Select hashtag feed view"
              onChange={handleFeedChange}
            />
          </div>
          <div>
            <SkedIcon
              icon="icon-refresh"
              size="18"
              className="tw-mr-4"
              onClick={() => refreshMedia(filterState)}
            />
          </div>
        </Flex>
      </Flex>
      <div className="tw-mb-4">
        <Disclaimer />
      </div>
    </div>
  );
};

export default HashtagFeedActions;
