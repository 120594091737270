import React, { useMemo, useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { TextInput } from "ui/ecosystems/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

export const AccountSearchInputParams = {
  debounceTime: 500,
};

interface AccountSearchInputProps {
  onSearch: (text: string) => void;
  placeholder?: string;
}

export const AccountSearchInput = ({
  onSearch,
  placeholder = "Type to Search",
  ...props
}: AccountSearchInputProps): JSX.Element => {
  const [text, setText] = useState("");
  const debounceSearch = useMemo(
    () => _.debounce(onSearch, AccountSearchInputParams.debounceTime),
    [onSearch]
  );
  return (
    <SearchInputContainer {...props}>
      <TextInput
        placeholder={placeholder}
        value={text}
        onChange={(value: string) => {
          setText(value);
          debounceSearch(value);
        }}
      />
      <SearchIcon icon={faSearch} />
    </SearchInputContainer>
  );
};

const SearchInputContainer = styled.div`
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 13px;
  right: 13px;
  color: #c4c4c4;
  pointer-events: none;
`;
