import {
  SHOW_MODAL,
  HIDE_MODAL,
  ADD_COLLECTION_ASSETS_ROUTINE,
  ADD_COLLECTION_ASSET_ROUTINE,
  DELETE_COLLECTION_ASSET_ROUTINE,
  UPDATE_COLLECTION_ASSET_ROUTINE,
  SAVE_POST_FORM_ROUTINE,
} from "constants/ActionTypes";
import _ from "lodash";

const initialState = {
  modals: [], // Treated as a STACK for stacking modals
  loading: false,
};

function modalReducer(state = initialState, action) {
  let modals = _.cloneDeep(state.modals);
  switch (action.type) {
    case SHOW_MODAL:
      /** avoid modal duplication */
      if (
        action.payload.unique &&
        modals.some((c) => c.modalType === action.payload.modalType)
      ) {
        return state;
      }
      modals.push({
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      });

      return {
        modals,
        loading: false,
      };
    case HIDE_MODAL:
      const { all, modalType } = action.payload;
      if (state.loading) {
        return state;
      }

      // Hide all
      if (all) {
        state.modals = [];
        return state;
      } else {
        // Hide the most recent modal
        let modals = _.cloneDeep(state.modals);
        if (modalType) {
          _.remove(modals, {
            modalType: modalType,
          });
        } else {
          modals.pop();
        }

        return {
          ...state,
          modals,
        };
      }
    case ADD_COLLECTION_ASSETS_ROUTINE.REQUEST:
    case ADD_COLLECTION_ASSET_ROUTINE.REQUEST:
    case DELETE_COLLECTION_ASSET_ROUTINE.REQUEST:
    case UPDATE_COLLECTION_ASSET_ROUTINE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COLLECTION_ASSET_ROUTINE.SUCCESS:
    case UPDATE_COLLECTION_ASSET_ROUTINE.FAILURE:
    case ADD_COLLECTION_ASSETS_ROUTINE.SUCCESS:
    case ADD_COLLECTION_ASSETS_ROUTINE.FAILURE:
    case ADD_COLLECTION_ASSET_ROUTINE.SUCCESS:
    case ADD_COLLECTION_ASSET_ROUTINE.FAILURE:
    case DELETE_COLLECTION_ASSET_ROUTINE.SUCCESS:
    case DELETE_COLLECTION_ASSET_ROUTINE.FAILURE:
    case SAVE_POST_FORM_ROUTINE.SUCCESS:
      return initialState;
    default:
      return state;
  }
}

export default modalReducer;
