import React, { MouseEvent } from "react";
import {
  BaseModal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "../giselle-modal";
import { Button, FormSize, FormThemeProvider } from "../ecosystems/forms";

interface DialogProps {
  show: boolean;
  className?: string;
  alert?: boolean;
  dialogClassName?: string;
  title?: React.ReactNode | undefined;
  message?: ((props: { cancel(): void }) => React.ReactNode) | React.ReactNode;
  confirmDisabled?: boolean;
  confirmText?: React.ReactNode;
  cancelText?: React.ReactNode;
  confirmType?: { alert: boolean };
  onConfirm(): void;
  onCancel(event: MouseEvent<HTMLButtonElement>): void;
}
export function ConfirmModal(props: DialogProps): JSX.Element {
  const {
    confirmDisabled = false,
    message = "Are you sure?",
    confirmText = "Confirm",
    cancelText = "Cancel",
    confirmType,
  } = props;

  return (
    <BaseModal
      show={props.show}
      onHide={props.onCancel}
      className={`${props.className} ${props.dialogClassName}`}
    >
      <ModalHeader title={props.title ?? message} onHide={props.onCancel} />

      <ModalContent>
        {props.title &&
          (typeof message === "function"
            ? message({ cancel: props.onCancel })
            : message)}
        <ModalActions variant="space-between">
          <FormThemeProvider size={FormSize.large}>
            <Button text primary onClick={props.onCancel}>
              {cancelText}
            </Button>{" "}
            <Button
              {...(confirmType && confirmType)}
              autoFocus
              primary
              onClick={props.onConfirm}
              disabled={confirmDisabled}
            >
              {confirmText}
            </Button>
          </FormThemeProvider>
        </ModalActions>
      </ModalContent>
    </BaseModal>
  );
}
