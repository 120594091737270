import styled from "styled-components";
import { ReactSelectStyled } from "./_react-select-styled";
import React from "react";

interface MultiSelectProps<T> {
  value: string[];
  options: Array<{ label: string; value: T }>;

  onChange(value: T[]): void;

  className?: string;
  placeholder?: string;
  disabled?: boolean;
  noOptionsMessage?: string;
}

function MultiSelectComponent<T>(props: MultiSelectProps<T>) {
  return (
    <ReactSelectStyled
      value={props.value}
      isMulti
      isDisabled={props.disabled}
      isSearchable={true}
      options={props.options}
      className={props.className}
      placeholder={props.placeholder}
      noOptionsMessage={() => {
        return props.noOptionsMessage || "No options";
      }}
      onChange={(items: any) => {
        if (items === null) {
          props.onChange([]);
        } else {
          props.onChange(items.map((item: { value: string }) => item.value));
        }
      }}
    />
  );
}

export const MultiSelect = styled(MultiSelectComponent)``;
