import {
  INIT_EXTERNAL_COLLAB_ROUTINE,
  ADD_EXTERNAL_COLLAB_THEME_ROUTINE,
  UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE,
  DELETE_EXTERNAL_COLLAB_THEME_ROUTINE,
  ADD_EXTERNAL_COLLAB_LINK_ROUTINE,
  UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE,
  DELETE_EXTERNAL_COLLAB_LINK_ROUTINE,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { normalizeEntity } from "reducers/reducerUtils";

import _ from "lodash";

const initialState = {
  themes: {
    byId: {},
    allIds: [],
  },
  links: {
    byId: {},
    allIds: [],
  },
};

const externalCollabReducer = createReducer(initialState, {
  [INIT_EXTERNAL_COLLAB_ROUTINE.SUCCESS]: (state, action) => {
    const { themes, links } = action.payload;
    state.themes = normalizeEntity(themes);
    state.links = normalizeEntity(links);

    return state;
  },
  [ADD_EXTERNAL_COLLAB_THEME_ROUTINE.SUCCESS]: (state, action) => {
    const { theme } = action.payload;
    state.themes.byId[theme._id] = theme;
    state.themes.allIds.push(theme._id);
    return state;
  },
  [UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE.SUCCESS]: (state, action) => {
    const { theme } = action.payload;
    state.themes.byId[theme._id] = theme;
    return state;
  },
  [DELETE_EXTERNAL_COLLAB_THEME_ROUTINE.SUCCESS]: (state, action) => {
    const { id } = action.payload;
    delete state.themes.byId[id];
    _.remove(state.themes.allIds, (themeId) => themeId === id);
    return state;
  },
  [ADD_EXTERNAL_COLLAB_LINK_ROUTINE.SUCCESS]: (state, action) => {
    const { link } = action.payload;
    state.links.byId[link._id] = link;
    state.links.allIds.push(link._id);
    return state;
  },
  [UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE.SUCCESS]: (state, action) => {
    const { link } = action.payload;
    state.links.byId[link._id] = link;
    return state;
  },
  [DELETE_EXTERNAL_COLLAB_LINK_ROUTINE.SUCCESS]: (state, action) => {
    const { id } = action.payload;
    delete state.links.byId[id];
    _.remove(state.links.allIds, (themeId) => themeId === id);
    return state;
  },
});

export default externalCollabReducer;
