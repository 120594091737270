import { FBPageWithPicture, LIOrganization } from "api/autocomplete";
import { Template } from "api/autocomplete/templates";

import {
  SuggestOptionTag,
  SuggestOptionTemplate,
  SuggestOptionFbPage,
  SuggestOptionLiOrg,
} from "./SuggestOption.type";

export const MakeSuggestOption = {
  fromTag(tag: { value: string }): SuggestOptionTag {
    return {
      type: "tag",
      value: tag.value,
    };
  },

  fromTemplate(template: Template): SuggestOptionTemplate {
    return {
      type: "template",
      ...template,
    };
  },

  fromFacebookPage(page: FBPageWithPicture): SuggestOptionFbPage {
    return {
      type: "fb-page",
      ...page,
    };
  },

  fromLinkedInOrg(org: LIOrganization): SuggestOptionLiOrg {
    return {
      type: "li-organization",
      ...org,
    };
  },
};
