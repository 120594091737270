import { FIELDS, FIELDS_DEFAULT } from "constants/PostFormFields";
import { get } from "lodash";
import { useEffect, useMemo } from "react";

import usePrevious from "utils/hooks/usePrevious";

export function useTikTok({
  sortedAccounts,
  platformFormValue,
  visibleFields,
  selectedPlatform,
  updateField,
}: {
  sortedAccounts: any[];
  platformFormValue: any;
  visibleFields: any;
  selectedPlatform: string;
  updateField: (field: string, value: boolean) => void;
}) {
  const { tiktokAccounts, oldTiktokAccounts } = useMemo(() => {
    const tiktokAccounts = sortedAccounts.filter(
      (account) => account.platformType === "TT"
    );

    return {
      tiktokAccounts,
      oldTiktokAccounts: tiktokAccounts?.filter(
        (account) => !account?.platformId
      ),
    };
  }, [sortedAccounts]);

  const ttIsNotification = useMemo(
    () =>
      get(
        platformFormValue,
        FIELDS.POST_IS_NOTIFICATION_ONLY,
        FIELDS_DEFAULT.IS_NOTIFICATION_POST_ONLY_DEFAULT
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types
    [platformFormValue?.postOptions?.isNotification]
  );

  const previousOldTikTokAccounts = usePrevious(oldTiktokAccounts);
  const previousTikTokAccounts = usePrevious(tiktokAccounts);

  //NOTE: when added old tiktok account, we should turn on notification toggle
  useEffect(() => {
    if (tiktokAccounts.length === 1 && oldTiktokAccounts.length === 1) {
      // if only one account and it's oldTT
      return updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, true);
    }

    // turn off notification toggle only when delete all old TT account
    if (
      // after delete all old TT accounts and has new TT account
      (previousOldTikTokAccounts &&
        previousOldTikTokAccounts.length > 0 &&
        oldTiktokAccounts.length === 0 &&
        tiktokAccounts.length > 0) ||
      // after delete all accounts and add first new TT account
      (previousTikTokAccounts &&
        previousTikTokAccounts.length === 0 &&
        oldTiktokAccounts.length === 0 &&
        tiktokAccounts.length === 1)
    ) {
      return updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, false);
    }

    if (oldTiktokAccounts.length > 0) {
      // false because by default posting for new TT account should work, old TT account correct handled on backend
      return updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTiktokAccounts.length, tiktokAccounts.length]);

  // May be this should be separate to some other place.
  const showUrlField = useMemo(() => {
    const baseShowUrlField = visibleFields?.includes(FIELDS.URL);

    if (tiktokAccounts?.length === 0 || selectedPlatform !== "TT") {
      return baseShowUrlField;
    }

    return baseShowUrlField && ttIsNotification;
  }, [
    visibleFields,
    tiktokAccounts?.length,
    selectedPlatform,
    ttIsNotification,
  ]);

  const hasTikTokPosting =
    visibleFields?.includes(FIELDS.TT_ALLOW_COMMENTS) &&
    (!showUrlField ||
      (selectedPlatform === "LINKED" && tiktokAccounts?.length));

  return {
    tiktokAccounts,
    oldTiktokAccounts,
    ttIsNotification,
    showUrlField,
    hasTikTokPosting,
  };
}
