import { setup } from "axios-cache-adapter";
import { CancelToken } from "axios";

export interface Template {
  _id: string;
  name: string;
  template: string;
}

const axiosCached = setup({
  cache: {
    maxAge: 15 * 60 * 1000,
  },
});

export async function getAllTemplates(cancelToken?: CancelToken) {
  const response = await axiosCached.get<{ postTemplates: Template[] }>(
    "/post-templates",
    { cancelToken }
  );

  return response.data.postTemplates;
}

export async function findPrefixTemplates(
  prefix?: string,
  cancelToken?: CancelToken
) {
  const templates = await getAllTemplates(cancelToken);
  if (!prefix) {
    return templates;
  }
  return templates.filter((template) =>
    template.name.toLocaleLowerCase().startsWith(prefix.toLocaleLowerCase())
  );
}

export async function removeTemplate(templateId: string) {
  await axiosCached.delete(`/post-templates/${templateId}`);
  // NOTE: invalidate getAllTemplates cache
  await (axiosCached as any).cache.clear();
}

export async function invalidate(): Promise<void> {
  await (axiosCached as any).cache.clear();
}
