import { useCallback, useEffect, useState } from "react";
import moment, { Moment, MomentInput } from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  useStorageCalendarView,
  useStorageIsDraftsOpen,
  useStorageIsFiltersOpen,
  useStorageIsWorkHours,
} from "libs/storage/adapters";
import { DATE_FORMAT } from "constants/common";
import { useStore } from "effector-react";
import { toast } from "react-toastify";
import _ from "lodash";
import { DragEndEvent } from "@dnd-kit/core";
import { MbscCalendarEvent, MbscEventClickEvent } from "@mobiscroll/react";
import { EventcalendarBase } from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";

import { $selectedAccountsIds, useSelectedAccounts } from "entities/accounts";
import { useTrackEvent } from "libs/analytics";
import { useOpenableState } from "libs/use-openable-state";

import { fetchDrafts, updatePageName } from "actions/postsView";
import { getCalendarPosts, getCalendarPostsIds } from "selectors/calendar";
import { getUser } from "selectors/commonSelectors";
import {
  getFilters,
  getPagination,
  getPostContentTypes,
  getPosts,
} from "selectors/postsPageSelectors";

import { openCreatePostModal, openEditPostModal } from "features/post";
import { calendarActions } from "features/calendar-new";
import { PostStateTypes } from "pages/calendar-new/components/modals/post-popover";
import { hasTwitter } from "utils/accounts";
import { AccountType } from "shared/types/accounts";
import { getNotes } from "selectors/notes";

import {
  fetchCalendarPosts,
  moveToDraft,
  scheduleDraftPost,
  updateDraft,
  updatePost,
} from "./calendar-model";

import {
  fetchNotes,
  notesActions,
  removeNote,
  updateNote,
} from "./notes-model";
import { CalendarPostType, NoteType, UseCalendarType } from "./types";

import {
  NEW_CAL_DRAFTS_HIDE,
  NEW_CAL_DRAFTS_SHOW,
  NEW_CAL_DRAG_DRAFTS,
  NEW_CAL_FILTERS_HIDE,
  NEW_CAL_FILTERS_SHOW,
  NEW_CAL_PERIOD_CHANGE,
  NEW_CAL_POST_CREATE,
  NEW_CAL_POST_EDIT,
  NEW_CAL_POST_TO_DRAFTS,
} from "constants/Events";

export const checkIfToday = (when: Moment, timezone: string): boolean => {
  return (
    moment(when).utc(true).startOf("day").unix() ===
    moment().tz(timezone).utc(true).startOf("day").unix()
  );
};

interface WeekStartEndProps {
  isWeeklyNotes?: boolean;
  firstDay?: Moment;
  lastDay?: Moment;
}

export const useCalendar = (): UseCalendarType => {
  const { clearPosts } = calendarActions;
  const { updateNoteEntity } = notesActions;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInited, setIsInited] = useState<boolean>(false);
  const [postsLoadOptions, setPostsLoadOptions] = useState({});
  const [draftsLoadOptions, setDraftsLoadOptions] = useState({});

  const [postAddAnchor, setPostAddAnchor] = useState<
    HTMLDivElement | undefined
  >();

  const user = useSelector(getUser);

  const [scheduledPosts, setScheduledPosts] = useState<CalendarPostType[]>([]);
  const [postDateToSchedule, setPostDateToSchedule] = useState<MomentInput>();
  const [calendarInstance, setCalendarInstance] = useState<EventcalendarBase>();
  const [maxNotes, setMaxNotes] = useState<number>(0);

  const [cursorCoordinates] = useState<{ y: number }>({
    y: 0,
  });

  const [isWorkHours, setIsWorkHours] = useStorageIsWorkHours(false);

  const weekStart = user.weekStart
    ? user.weekStart()
    : user?.preferences?.weekStart;

  const [view, setView] = useStorageCalendarView({
    schedule: {
      type: "week",
      timeCellStep: 15,
      timeLabelStep: 30,
      allDay: false,
    },
  });

  const [noteAnchor, setNoteAnchor] = useState<HTMLElement | null>(null);
  const [note, setNote] = useState<NoteType | null>(null);
  const [editNote, setEditNote] = useState<NoteType | null>(null);
  const [noteDragStarted, setNoteDragStarted] = useState<boolean>(false);

  const [timelineScale] = useState(15);

  const accounts = useSelectedAccounts();
  const selectedAccounts = useStore($selectedAccountsIds);

  const trackEvent = useTrackEvent();
  const postsIds = useSelector(getCalendarPostsIds);
  const posts = useSelector(getCalendarPosts);
  const allPosts = useSelector(getPosts);

  const draftPagination = useSelector(getPagination);
  const fetchedNotes = useSelector(getNotes);
  const selectedPostContentTypes = useSelector(getPostContentTypes);
  const { postStatusKey } = useSelector(getFilters);

  const [isFiltersOpen, setIsFiltersOpen] = useStorageIsFiltersOpen(true);
  const [isDraftsOpen, setIsDraftsOpen] = useStorageIsDraftsOpen(true);

  const filters = useOpenableState(isFiltersOpen);
  const drafts = useOpenableState(isDraftsOpen);

  const timeFormat =
    user?.preferences?.timePreference === "12h" ? "h:mm A" : "H:mm";

  const getNormalizedPost = useCallback(
    (post: CalendarPostType) => {
      const postAccount = accounts.find(
        (account) => account._id === post.accountId
      );

      const startTime = moment(
        post.when && !/invalid/.test(post?.when?.toLowerCase())
          ? new Date(post.when)
          : new Date()
      )
        .seconds(0)
        .utc(true)
        .tz(user.timezone, true)
        .utc();

      const color = postAccount?.color;

      return {
        _id: post._id,
        isVideo: post.isVideo || post?.meta?.isVideo,
        gifThumbnail: post.gifThumbnail,
        clientId: post.clientId,
        start: startTime,

        storyType: post.storyType,
        publishStoryManually: post.publishStoryManually,
        type: post.type,
        editable: !post.past,
        gmbTopicType: post.gmbTopicType,
        color: color || "#4F31FF",
        thumbnailUrl: post.thumbnailUrl,
        end: startTime,
        title: post.caption || post.firstcomment || `${post.postedBy}`,
        caption: post.caption,
        firstcomment: post.firstcomment,
        state: post.queued ? "Queued" : "Scheduled",
        accountId: post.accountId,
        postStatusKey: post.postStatusKey,
      };
    },
    [accounts, user.timezone]
  );

  const getWeekStartEnd = useCallback(
    (props?: WeekStartEndProps) => {
      let startPageDate = moment(
        props?.firstDay || calendarInstance?._calendarView?._firstPageDay
      );
      let endPageDate = moment(
        props?.lastDay || calendarInstance?._calendarView?._lastPageDay
      );

      const isDaily = view?.schedule?.type === "day";
      const untilOfTime = user.preferences.weekStart ? "isoWeek" : "week";

      if (isDaily) {
        // for one calendar day range we need to show notes for whole week
        if (props?.isWeeklyNotes) {
          startPageDate = moment(startPageDate).startOf(untilOfTime);
          endPageDate = moment(endPageDate).add(-1, "day").endOf(untilOfTime);
        } else {
          // for day view, we need to have the same end date as the start date,
          // but with just another time, up to 23:59
          endPageDate = startPageDate;
        }
      } else {
        // by default the Mobiscroll calendar returns isoWeek day
        // for the end of the week, and we need to get correct week end day in case
        // if the customer has another week start day then used in the Mobiscroll
        endPageDate.add(-1, "day").endOf(untilOfTime);
      }

      // TODO: confirm how we can detect selected dates range in mobiscroll lib
      const start = moment(startPageDate).utc(true).startOf("day").valueOf();

      const end = moment(endPageDate).utc(true).endOf("day").valueOf();

      return { start, end };
    },
    [
      calendarInstance?._calendarView?._firstPageDay,
      calendarInstance?._calendarView?._lastPageDay,
      user.preferences.weekStart,
      view?.schedule?.type,
    ]
  );

  const loadPosts = useCallback(
    async (forceLoad?: boolean) => {
      if (calendarInstance && selectedAccounts?.length) {
        const accountFilters = {};
        accounts.forEach((account) => {
          accountFilters[account._id] = {
            lastOrdinal: 0,
          };
        });
        const payload = {
          accountFilters: accountFilters,
          postContentTypes: selectedPostContentTypes.includes("*")
            ? []
            : selectedPostContentTypes,
          postStatusKey,
        };

        const { start, end } = getWeekStartEnd();

        if (start) {
          payload["start"] = start;
          payload["end"] = end;
          if (!_.isEqual(payload, postsLoadOptions) || !isInited || forceLoad) {
            setPostsLoadOptions(payload);
            dispatch(fetchCalendarPosts(payload));
          }
        }
      } else if (scheduledPosts?.length) {
        dispatch(clearPosts());
      }
    },
    [
      calendarInstance,
      selectedAccounts?.length,
      scheduledPosts?.length,
      accounts,
      selectedPostContentTypes,
      postStatusKey,
      getWeekStartEnd,
      postsLoadOptions,
      isInited,
      dispatch,
      clearPosts,
    ]
  );

  const loadDrafts = useCallback(
    async (
      usedPagination?: { currentPage: number; pageSize: number },
      forceLoad?: boolean
    ) => {
      if (calendarInstance && selectedAccounts?.length) {
        const pagination = usedPagination || draftPagination;
        const draftsProps = {
          currrentPage: pagination.currrentPage,
          pageSize: pagination.pageSize,
          selectedAccounts,
          postStatusKey,
          selectedPostContentTypes: selectedPostContentTypes.includes("*")
            ? []
            : selectedPostContentTypes,
        };

        if (
          (draftsProps.pageSize &&
            !_.isEqual(draftsProps, draftsLoadOptions)) ||
          forceLoad
        ) {
          setDraftsLoadOptions(draftsProps);
          await dispatch(
            fetchDrafts(
              pagination.currentPage,
              pagination.pageSize,
              selectedAccounts
            )
          );
        }
      }
    },
    [
      calendarInstance,
      selectedAccounts,
      draftPagination,
      postStatusKey,
      selectedPostContentTypes,
      draftsLoadOptions,
      dispatch,
      setDraftsLoadOptions,
    ]
  );

  const loadNotes = useCallback(() => {
    const { start, end } = getWeekStartEnd({ isWeeklyNotes: true });
    if (calendarInstance && selectedAccounts?.length) {
      dispatch(
        fetchNotes({
          accounts: selectedAccounts,
          start,
          end,
        })
      );
    }
  }, [calendarInstance, dispatch, getWeekStartEnd, selectedAccounts]);

  const onNoteDragNoteStart = () => {
    setNoteDragStarted(true);
  };

  const onNoteDragNoteEnd = (props: DragEndEvent) => {
    setNoteDragStarted(false);

    const target: any = props?.activatorEvent?.target;
    const dragElementOffset = target?.getBoundingClientRect();

    const pointX = dragElementOffset.left + dragElementOffset.width / 2;
    const pointY = dragElementOffset.top + dragElementOffset.height / 2;

    const dropZone = document.elementFromPoint(pointX, pointY);

    const date = dropZone?.getAttribute("data-date");

    if (date && date !== props.active?.data?.current?.date) {
      const draggedNote = fetchedNotes[props.active.id];
      const updatedDate = moment(date)
        .utc(true)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .format();
      dispatch(
        updateNote({
          note: {
            ...draggedNote,
            date: updatedDate,
          },
          isSilence: true,
        })
      );
      dispatch(
        updateNoteEntity({
          id: props.active.id,
          // @typescript-eslint/ban-ts-comment
          changes: { date: updatedDate },
        })
      );
    }
  };

  const onPostEdit = async (postId: string, postState?: PostStateTypes) => {
    setIsLoading(true);

    trackEvent({ eventName: NEW_CAL_POST_EDIT });

    const post = posts[postId];

    dispatch(
      openEditPostModal({
        post: {
          ...post,
          nextRunAt: postState !== "failed" && post.nextRunAt,
          when: post.start || post.when,
          failReason: post.failReason || post.userDisplayReason,
          postType: postState === "failed" ? "archived" : postState, //for post again
          lastRunAt: post.lastRunAt || post.start || post.when,
        },
        callback: () => {
          loadDrafts(undefined, true);
          loadPosts(true);
        },
        onCloseCallback: () => {},
      })
    );
    setIsLoading(false);
  };

  const onPostDoubleTap = () => {};

  const onEventClick = (data: MbscEventClickEvent) => {
    data.domEvent.stopPropagation();
  };

  const onPostToDraftClick = async (postId: string) => {
    const post = posts[postId];

    if (!post.past) {
      const request = await dispatch(moveToDraft(post));

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!request?.error) {
        trackEvent({ eventName: NEW_CAL_POST_TO_DRAFTS });
        await loadDrafts(undefined, true);
        removePostFromCalendar(postId);
      }
    } else if (post.isSuccess) {
      trackEvent({ eventName: "copy_to_draft_select" });
      const { ...restPost } = post;
      dispatch(
        openCreatePostModal({
          post: {
            ...restPost,
            postType: "draft",
            isSuccess: false,
          },
        })
      );
    } else {
      toast.error("An error occurred in sending post to drafts");
    }
  };

  const onDraftPostDeleted = async () => {
    await loadDrafts(undefined, true);
  };
  const onPostDeleted = async (postId = "") => {
    removePostFromCalendar(postId);
  };

  const revertPostTime = (props: {
    event: CalendarPostType;
    oldEvent: CalendarPostType;
  }) => {
    const postIndex = scheduledPosts?.findIndex(
      (item: CalendarPostType) => item._id === props.event._id
    );
    const post = scheduledPosts[postIndex];
    if (post) {
      post.start = props.oldEvent.start;
      post.end = props.oldEvent.end;
    }
    calendarInstance?.setEvents(scheduledPosts);
  };

  const updatePostSchedule = async (
    postId: string,
    postWhen: Date | Moment,
    post: CalendarPostType
  ) => {
    // https://app.bugsnag.com/sked-social/react-sked-client/errors/64cd309157ef810009ea9cb7?event_id=64ef7fd200bee4cacf090000&i=sk&m=fq
    if (!post) return false;
    if (moment(postWhen).isSame(moment(post.when))) return false;

    const when = moment(postWhen).format("YYYY-MM-DD HH:mm").toString();
    const updatedPost = {
      // spread the post object -- there are MANY fields
      // that could be updated here as well (e.g. gmbEvent etc)
      // @ts-ignore
      ...post,
      accountIds: post.accountIds?.length ? post.accountIds : [post.accountId],
      altText: post.altText || null,
      caption: post.caption || "",
      clientId: post.clientId,
      firstcomment: post.firstcomment || "",
      isVideo: post.isVideo || false,
      media: post.media || [],
      mediaFiles: post.mediaFiles || [],
      meta: post.meta || {},

      gmbTopicType: post.gmbTopicType,
      platforms: post.platforms || [],
      productTags: post.productTags || [],
      publishStoryManually: post.publishStoryManually || false,
      redirectUrl: post.redirectUrl || null,
      tags: post.tags || [],
      thumbnailUrl: post.thumbnailUrl || "",
      type: post.type || "",
      url: post.url || "",
      piBoards: post.piBoards?.length ? post.piBoards : [],
      platformType: post.platformType || null,
      queued: post.queued || false,
      storyUrl: post.storyUrl || null,
      storyLabel: post.storyLabel || null,
      storyStickerHeight: post.storyUrl ? post.storyStickerHeight || 0.8 : null,

      storyType: post.storyType,
      when: when || "",
    };

    return dispatch(updatePost({ postId, post: updatedPost }));
  };

  const onEventUpdated = async (props: {
    event: CalendarPostType;
    oldEvent: CalendarPostType;
    source: string;
    inst: {
      _rangeType: string;
    };
  }) => {
    const { event, oldEvent } = props;

    const when = moment(props.event.start).tz(user.timezone);
    const userToday = moment(new Date()).tz(user.timezone);

    // remove post in case if the draft post are moved to the past date/time
    if (moment(when).utc(true).isSameOrBefore(moment(userToday).utc(true))) {
      revertPostTime({ event, oldEvent });
      return;
    }

    const post = posts[event._id];
    const request = await updatePostSchedule(event._id, when, post);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (request?.error) {
      revertPostTime({ event, oldEvent });
    } else {
      // @typescript-eslint/ban-ts-comment
      trackEvent({
        eventName: `new-cal-drag-calendar-${props.inst._rangeType}`,
      });
      loadPosts(true);
    }
  };

  const onCellClick = async (props: MbscEventClickEvent) => {
    const { date, domEvent, source } = props;

    let when = moment(date);
    const today = moment(new Date());

    // Prevent trigger on disabled dates
    // Check local timezone according to the selected inside the user settings
    const isInFuture =
      when.utc(true).unix() >= moment().tz(user.timezone).utc(true).unix();
    const isToday = checkIfToday(when, user.timezone);

    if (
      (view.schedule && !isInFuture) ||
      (view.calendar && !isInFuture && !isToday)
    ) {
      return;
    }

    if (!isInFuture) {
      const userToday = today.tz(user.timezone);
      // Let's add a 5 min buffer so the starting date isn't immediately in the past.
      when = userToday.set("minutes", userToday.minutes() + 5);
    }

    if (source === "calendar" && !isToday) {
      when.set("hours", 9).set("minutes", 0); // default 9am for calendar mode
    }

    setPostAddAnchor(domEvent.currentTarget);
    setPostDateToSchedule(when);
  };

  const onPostAddClick = async () => {
    setPostDateToSchedule("");
    trackEvent({ eventName: NEW_CAL_POST_CREATE });
    await dispatch(
      openCreatePostModal({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        post: {
          nextRunAt: postDateToSchedule,
          postState: "SCHEDULED",
          when: postDateToSchedule,
        },
        accounts,
        callback: () => {
          loadPosts();
          loadDrafts(undefined, true);
        },
      })
    );
  };

  const removePostFromCalendar = (postId: string) => {
    const schedules = Array.from(
      scheduledPosts?.filter((item: CalendarPostType) => item._id !== postId)
    );
    setScheduledPosts(schedules);
    setTimeout(() => {
      calendarInstance?.setEvents(schedules);
    });
  };

  const checkIfNotAvailableTime = (props: {
    when: Moment;
    source: string;
    isToday: boolean;
  }) => {
    const isInFuture =
      moment(props.when).utc(true).unix() >=
      moment().tz(user.timezone).utc(true).unix();

    return (
      (!isInFuture && props.source !== "calendar") ||
      (props.source === "calendar" && !props.isToday && !isInFuture)
    );
  };

  const onEventCreate = async (props: {
    event: CalendarPostType;
    inst: EventcalendarBase;
    source: string;
  }) => {
    // validate twitter
    const assetsAdded = props.event.mediaFiles?.length;

    if (assetsAdded > 4) {
      const postAccounts = accounts.filter((account: AccountType) =>
        props.event.accountIds.includes(account._id)
      );
      if (hasTwitter(postAccounts)) {
        toast.error(
          "Twitter only supports up to 4 images in a carousel. The post are not scheduled."
        );
        return removePostFromCalendar(props.event._id);
      }
    }

    let when = moment(props.event.start).utc().tz(user.timezone);
    const isToday = checkIfToday(when, user.timezone);
    const userToday = moment(new Date()).tz(user.timezone);

    // reset the default time in the case when time in the past or we have calendar view
    if (props.source === "calendar") {
      when =
        !isToday ||
        moment(when).utc(true).isBefore(moment(when).utc(true).set("hours", 9))
          ? when.set("hours", 9).set("minutes", 0)
          : moment().add(5, "minutes");
    } else if (
      isToday &&
      // the user browser time is not the same as user account timezone
      moment(when).utc(true).isSameOrBefore(moment(userToday).utc(true))
    ) {
      // add extra minutes to avoid scheduling the post in the past
      when = userToday.add(5, "minutes");
    }

    const isNotAvailableTime = checkIfNotAvailableTime({
      when,
      source: props.source,
      isToday,
    });

    // remove post in case if the draft post are moved to the past date/time
    if (isNotAvailableTime) {
      removePostFromCalendar(props.event._id);
      return;
    }

    const whenFormatted = when.format("YYYY-MM-DD");
    const currentDayCell = props.inst._eventMap?.[whenFormatted]?.sort(
      (scheduledPost, nextScheduledPost) =>
        moment(scheduledPost?.start).unix() -
        moment(nextScheduledPost?.start).unix()
    );

    // check if cell has posts
    if (currentDayCell?.length) {
      const hasOneAccountPosts = currentDayCell?.filter(
        (scheduledPost: MbscCalendarEvent) =>
          props.event.accountIds?.includes(scheduledPost.accountId)
      );

      let newTime;
      // find last acceptable time
      while (!newTime) {
        const nearPost = hasOneAccountPosts?.find(
          (scheduledPost: MbscCalendarEvent) =>
            moment(scheduledPost.start)
              .tz(user.timezone)
              .utc(true)
              .isSameOrBefore(moment(when).utc(true)) &&
            moment(scheduledPost.start)
              .tz(user.timezone)
              .utc(true)
              .add(15, "minutes")
              .isAfter(moment(when).utc(true))
        );

        if (!nearPost) {
          newTime = when;
        } else {
          when.add(15, "minutes");
        }
      }
    }
    const draft = allPosts.find((post) => post._id === props.event._id);
    const post = draft.schedule;

    // fix issue for wrong post date after dropped from the drafts,
    // seems mobiscroll has some issue according to the selected timezone
    // @ts-ignore
    props.event.start = when.format(DATE_FORMAT);
    // @ts-ignore
    props.event.end = when.format(DATE_FORMAT);

    const updatedPost = await dispatch(
      updateDraft({
        ...post,
        caption: post.caption || "",
        firstcomment: post.firstcomment || "",
        postType: "schedule",
        when: when.format(DATE_FORMAT).toString(),
      })
    );

    // @ts-ignore
    if (!updatedPost?.error) {
      // if there is no posts under the cell check if this is calendar view
      const request = await dispatch(
        scheduleDraftPost({
          ...post,
          platforms: post.platforms || [],

          caption: post.caption || "",
          firstcomment: post.firstcomment || "",
          _id: post._id,
          when: when.format(DATE_FORMAT).toString(),
        })
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!request?.error) {
        await loadDrafts(undefined, true);
        await loadPosts(true);
      } else {
        removePostFromCalendar(props.event._id);
      }
    }

    trackEvent({ eventName: NEW_CAL_DRAG_DRAFTS });
    // @typescript-eslint/ban-ts-comment
    trackEvent({ eventName: `new-cal-drag-calendar-${props.inst._rangeType}` });
  };

  const onAddModalClose = () => {
    setPostDateToSchedule("");
  };

  const onPageLoaded = useCallback(
    (_, inst) => {
      const postsCount = scheduledPosts?.length;
      if (inst._calendarView.state.maxLabels !== postsCount) {
        inst._calendarView.setState({
          maxLabels: postsCount,
        });
      }
    },
    [scheduledPosts?.length]
  );

  const onPageLoading = useCallback(() => {
    loadPosts(true);
    loadNotes();
  }, [loadNotes, loadPosts]);

  const onPageChange = useCallback(
    (props) => {
      const isDaily = view?.schedule?.type === "day";
      const { start, end } = getWeekStartEnd({
        firstDay: props.firstDay,
        lastDay: isDaily ? props.firstDay : props.lastDay,
      });
      trackEvent({
        eventName: NEW_CAL_PERIOD_CHANGE,
        metadata: {
          startDay: moment(start).utc().format("yyyy-MM-DDTHH:mm"),
          endDay: moment(end).utc().format("yyyy-MM-DDTHH:mm"),
        },
        useServices: ["AMP"],
      });
    },
    [getWeekStartEnd, trackEvent, view?.schedule?.type]
  );

  const onInit = (_: MbscCalendarEvent, inst: EventcalendarBase) => {
    setCalendarInstance(inst);
  };

  const onNoteDelete = (noteId?: string) => {
    dispatch(removeNote({ noteId }));
  };

  const setWorkHours = (workHours: boolean, timeIncrease?: boolean) => {
    setIsWorkHours(workHours);

    setView({
      schedule: {
        ...view.schedule,
        startTime: workHours ? "06:00" : "00:00",
        endTime: workHours ? "21:00" : "24:00",
        timeCellStep: timeIncrease ? 60 : 30,
        timeLabelStep: timeIncrease ? 60 : 30,
      },
    });
  };

  const decreaseTimelineScale = () => {
    setWorkHours(false, true);
  };

  const increaseTimelineScale = () => {
    setWorkHours(false);
  };

  const toggleWorkHours = () => {
    setWorkHours(!isWorkHours, !isWorkHours);
  };

  useEffect(() => {
    dispatch(updatePageName("CALENDAR"));
  }, []);

  useEffect(() => {
    if (calendarInstance && isInited) {
      loadPosts();
      loadDrafts();
    }
  }, [postStatusKey]);

  useEffect(() => {
    if (calendarInstance && isInited) {
      loadPosts();
      loadDrafts();
    }
  }, [selectedPostContentTypes]);

  useEffect(() => {
    if (calendarInstance && isInited) {
      loadPosts(true);
      loadNotes();
      loadDrafts(undefined, true);
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (calendarInstance && !isInited) {
      loadPosts();
      loadNotes();
      loadDrafts();
      setIsInited(true);
    }
  }, [calendarInstance]);

  useEffect(() => {
    const mappedPosts = postsIds?.map((postId: string) => {
      const post = posts[postId];

      return getNormalizedPost(post);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setScheduledPosts(mappedPosts);

    calendarInstance?._calendarView?.setState({
      maxLabels: postsIds.length,
    });
  }, [postsIds]);

  useEffect(() => {
    trackEvent({
      eventName: filters.isOpened ? NEW_CAL_FILTERS_SHOW : NEW_CAL_FILTERS_HIDE,
    });
    setIsFiltersOpen(filters.isOpened);
  }, [filters.isOpened]);

  useEffect(() => {
    trackEvent({
      eventName: drafts.isOpened ? NEW_CAL_DRAFTS_SHOW : NEW_CAL_DRAFTS_HIDE,
    });
    setIsDraftsOpen(drafts.isOpened);
  }, [drafts.isOpened]);

  return {
    view,
    setView,

    scheduledPosts,
    calendarInstance,
    timeFormat,
    timelineScale,
    isWorkHours,
    decreaseTimelineScale,
    increaseTimelineScale,
    toggleWorkHours,
    setIsWorkHours,
    weekStart,
    draftPagination,
    loadPosts,
    loadDrafts,

    onPostEdit,
    onPostToDraftClick,
    onPostAddClick,
    onDraftPostDeleted,
    onPostDeleted,
    onAddModalClose,
    onNoteDelete,

    onInit,
    onPageLoaded,
    onPageLoading,
    onPageChange,
    onEventClick,
    onEventUpdated,
    onEventCreate,

    onCellClick,

    onPostDoubleTap,

    postAddAnchor,
    postDateToSchedule,

    note,
    setNote,
    editNote,
    setEditNote,
    noteAnchor,
    setNoteAnchor,

    onNoteDragNoteStart,
    onNoteDragNoteEnd,
    noteDragStarted,

    maxNotes,
    setMaxNotes,

    cursorCoordinates,

    isLoading,

    filters,
    drafts,
  };
};
