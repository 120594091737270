import { MediaFilters } from "shared/types/files";

export const PAGES = {
  COLLECTIONS: "COLLECTIONS",
  HASHTAGS: "HASHTAGS",
  HASHTAGS_SPLASH: "HASHTAGS_SPLASH",
  MENTIONED_IN: "MENTIONED_IN",
  TAGGED_IN: "TAGGED_IN",
  TAGGED_IN_SPLASH: "TAGGED_IN_SPLASH",
  ZAPIER: "ZAPIER",
  CHROME: "CHROME",
  STOCKS: "STOCKS",
  CREATOR: "CREATOR",
  CREATOR_SPLASH: "CREATOR_SPLASH",
};

export const HASHTAG_FEED_VIEWS = {
  RECENT: "RECENT",
  TOP: "TOP",
};

export const SOURCE_NAMES = {
  UPLOAD: "Upload",
  HASHTAG_FEED: "Instagram hashtag feed",
  TAGGED_IN_FEED: "Instagram tagged in feed",
  ZAPIER: "Zapier",
  CHROME: "Chrome Extension",
};

export const WEEKLY_HASHTAG_SEARCH_MAX = 30;

export const DEFAULT_HASHTAG_FILTER = {
  excludeAccounts: [],
};

export interface AllFilterDataProps {
  label: string;
  type: keyof MediaFilters;
  free: boolean;
}

export const ALL_FILTERS_DATA: AllFilterDataProps[] = [
  { label: "Name", type: "name", free: false },
  { label: "Description", type: "description", free: false },
  { label: "Source", type: "sourceName", free: false },
  { label: "Tags", type: "tags", free: false },
  { label: "Exclude accounts", type: "excludeAccounts", free: false },
  { label: "Starred", type: "favorite", free: false },
  { label: "File Type", type: "mediaType", free: false },
  { label: "Usage", type: "usage", free: true },
];

export const ALL_FILTERS_TYPE = ALL_FILTERS_DATA.map((filter) => filter.type);
