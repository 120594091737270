import React from "react";
import styled from "styled-components";

import { ReactZuck, ZuckStory } from "../react-zuck";
import CloseIcon from "./close.svg";

interface SkedStoriesProps {
  className?: string;
  stories: ZuckStory[] | null;
  allowHide: boolean;
  onAllowHide: () => void;
  onHide: () => void;
}

export function SkedStories({
  className,
  stories,
  allowHide,
  onAllowHide,
  onHide,
}: SkedStoriesProps) {
  const hasStories = stories && stories.length > 0;

  return (
    <Container className={className}>
      {hasStories && (
        <ReactZuck
          initialStories={stories!}
          onStoryViewerClosed={onAllowHide}
        />
      )}
      {allowHide && (
        <CloseButton type="button" onClick={onHide}>
          <CloseIcon />
        </CloseButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  color: #c4c4c4;
  width: 20px;
  height: 20px;
  margin-left: 7.5px;
  margin-top: 12.5px;
`;
