import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { getUser } from "selectors/commonSelectors";
import { ADD_USER_MODAL } from "features/collaboration/users-model";
import { useTrackEvent } from "libs/analytics";
import {
  HEADER_MENU_ADD_USER_CLICK,
  HEADER_ADD_USER_CLICK,
  UPGRADE_USERS_VIEWED,
} from "constants/Events";
import {
  Link,
  Trigger,
  DropdownMenuContent,
  DropdownText,
  DropdownBoldText,
  DropdownButton,
} from "./header";
import { Flex } from "ui/commonStyled/layout";
import { useOpenIntercomChat } from "libs/use-open-intercom-chat";
import UsersIcon from "./icons/usersIcon.svg";
import { MODAL_OPEN_FUNCTIONS } from "libs/modals/init";
import { showUpsellModal } from "actions/typed-modal";
import { Button } from "@dialogueconsulting/sked-ui";
import {
  isLegacyPlan,
  getDisplayFeatureName,
} from "../../features/upsell/plan-utils";
import { openSupportChat } from "libs/support";

function UnlimitedUsers() {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const openAddModal = () => {
    trackEvent({
      eventName: HEADER_ADD_USER_CLICK,
      useServices: ["AMP"],
    });
    MODAL_OPEN_FUNCTIONS[ADD_USER_MODAL](dispatch);
  };

  return (
    <div>
      <DropdownText>
        <div>
          <DropdownBoldText>Free</DropdownBoldText> Unlimited users
        </div>
        <Flex marginTop={10}>Add as many users as you want.</Flex>
      </DropdownText>
      <DropdownMenu.Item>
        <Flex>
          <DropdownButton
            data-testid="unlimited-add-user-button"
            onClick={openAddModal}
          >
            Add Users
          </DropdownButton>
        </Flex>
      </DropdownMenu.Item>
      <ManageUsers justify="center" data-testid="manage-users-link">
        <Link to="/dashboard/users">
          <DropdownMenu.Item>
            <UsersIcon />
            Manage users
          </DropdownMenu.Item>
        </Link>
      </ManageUsers>
    </div>
  );
}

function ContributorContent({ adminEmail }: { adminEmail: string }) {
  const openIntercomChat = useOpenIntercomChat();

  return (
    <div data-testid="contributor-can-not-add-user">
      <DropdownText>
        <div>
          <DropdownBoldText>Update permission to add users</DropdownBoldText>
        </div>
        <Flex
          marginTop={10}
        >{`Contact your admin: ${adminEmail} to add users.`}</Flex>
      </DropdownText>
      <Flex marginTop={10}>
        <DropdownButton
          data-testid="add-user-contributor-contact-support"
          className="btn"
          onClick={openIntercomChat}
        >
          Contact Support
        </DropdownButton>
      </Flex>
      <ManageUsers justify="center" data-testid="manage-users-link">
        <Link to="/dashboard/users">
          <UsersIcon />
          Manage users
        </Link>
      </ManageUsers>
    </div>
  );
}

function NeedUpgradePlan({
  upsell,
  upsellFundamental,
}: {
  upsell: boolean;
  upsellFundamental: boolean;
}) {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const contactSupportText = `I'd like to add more accounts. Can you please help me?`;

  return (
    <div data-testid="need-upgrade-plan-add-user">
      <DropdownText>
        <div>
          <DropdownBoldText>Upgrade required</DropdownBoldText>
        </div>
        <Flex marginTop={10}>
          All plans above Fundamentals includes unlimited users.
        </Flex>
      </DropdownText>

      {upsell ? (
        <UpgradePlan
          onClick={() => {
            upsellFundamental
              ? dispatch(showUpsellModal("upsell", "add-account-user"))
              : dispatch(showUpsellModal("upsell", "increase-account-quota"));
            trackEvent({
              eventName: UPGRADE_USERS_VIEWED,
              useServices: ["AMP", "IC", "AC", "HS"],
            });
          }}
          text="Upgrade Plan"
          endIcon="upsell-rocket-white-bg"
          size="md"
          useDefaultIconColor
        />
      ) : (
        <>
          <DropdownButton
            data-testid="add-user-upgrade-contact-support"
            className="btn"
            onClick={() => {
              openSupportChat(contactSupportText);
            }}
          >
            Contact Support
          </DropdownButton>
          <ManageUsers justify="center" data-testid="manage-users-link">
            <Link to="/dashboard/users">
              <UsersIcon />
              Manage users
            </Link>
          </ManageUsers>
        </>
      )}
    </div>
  );
}

const UpgradePlan = styled(Button)`
  width: 100% !important;
  margin: 12px auto 0;
`;

export const getOpportunityDataToUseAddUserModal = (user: any) => {
  const isAdmin = user && user.role === "admin";
  const canAddUser = isAdmin || user.preferences?.canManageUsers || false;
  const acceptUsersFeature: boolean =
    user &&
    user.plan &&
    !(user.plan.includes("freelancer") || user.plan.includes("fundamentals"));

  return { canAddUser, acceptUsersFeature };
};

export function AddUserMenu(): React.ReactElement {
  const user = useSelector(getUser);
  const {
    canAddUser,
    acceptUsersFeature,
  }: { canAddUser: boolean; acceptUsersFeature: boolean } =
    getOpportunityDataToUseAddUserModal(user);

  // NOTE user included self in the collaborators array
  const showFree: boolean =
    acceptUsersFeature && user.collaborators.length <= 1;
  const trackEvent = useTrackEvent();
  const onClickTrigger = () => {
    trackEvent({
      eventName: HEADER_MENU_ADD_USER_CLICK,
      useServices: ["AMP"],
    });
  };

  let dropdownAlignOffset = -10;

  if (!canAddUser || (canAddUser && !acceptUsersFeature)) {
    dropdownAlignOffset = -20;
  }

  const upsellFundamental = user?.plan?.includes("fundamentals");

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <Trigger data-testid="add-user-menu-trigger" onClick={onClickTrigger}>
          <span className="mr-2">+</span>
          Add users
          {showFree && " (FREE)"}
        </Trigger>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent
          asChild
          minWidth={315}
          align="end"
          sideOffset={8}
          alignOffset={dropdownAlignOffset}
        >
          <div>
            {canAddUser && acceptUsersFeature && <UnlimitedUsers />}
            {canAddUser && !acceptUsersFeature && (
              <NeedUpgradePlan
                upsell={!isLegacyPlan(user)}
                upsellFundamental={upsellFundamental}
              />
            )}
            {!canAddUser && <ContributorContent adminEmail={user.adminEmail} />}
          </div>
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

const ManageUsers = styled(Flex)`
  padding: 10px 10px 4px;
  margin: 10px 0 -2px;

  a {
    margin: 0;
    font-weight: 450;

    svg {
      margin-right: 12px;
      position: relative;
      top: 2px;

      width: 14px;
    }
  }
`;
