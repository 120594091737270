import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "reducers/rootReducer";
import thunk from "redux-thunk";
import rootSaga from "sagas/rootSaga";
import { syncUpSelectedCollection } from "features/media-library";
import { syncUpSelectedAccounts } from "entities/accounts/model/selected-accounts";
import { syncUpAccountCounts } from "entities/accounts/model/counts";
export let skedMiddlewares = [thunk];
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
export const sagaMiddleware = createSagaMiddleware();
skedMiddlewares.push(sagaMiddleware);

export const createStoreHelper = (preloadState = {}) => {
  return createStore(
    rootReducer,
    preloadState,
    composeEnhancers(applyMiddleware(...skedMiddlewares))
  );
};

export const Store = createStoreHelper();

syncUpSelectedCollection(Store);
syncUpSelectedAccounts(Store);
syncUpAccountCounts(Store);

sagaMiddleware.run(rootSaga);
