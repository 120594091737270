import React, { useCallback, useMemo, useState } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FormControl } from "ui";
import { FIELDS, GMB_TOPICS } from "constants/PostFormFields";
import DateTimePicker from "components/DateTimePicker/DateTimePicker";
import Moment from "moment-timezone";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";

const PostEventDates = ({
  topicType,
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  className = "",
  errors,
}) => {
  const forTopics = [GMB_TOPICS.OFFER.VALUE, GMB_TOPICS.EVENT.VALUE];
  if (!forTopics.includes(topicType)) {
    return "";
  }

  const user = useSelector(getUser);

  const { gmbEvent } = post;
  const [startDate, setStartDate] = useState(
    gmbEvent && gmbEvent.startDate
      ? Moment.tz(gmbEvent.startDate, user.timezone)
      : null
  );
  const [endDate, setEndDate] = useState(
    gmbEvent && gmbEvent.endDate
      ? Moment.tz(gmbEvent.endDate, user.timezone)
      : null
  );
  const startInThePast = startDate < new Moment();
  const endInThePast = endDate < new Moment();

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          {topicType === GMB_TOPICS.OFFER.VALUE ? "Offer" : "Event"} dates
          (required)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.GMB_START_DATE}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <div className="tw-flex">
        <EventDate
          className="tw-mb-4 tw-mr-4"
          date={startDate}
          onChange={(newDate) => {
            setStartDate(Moment.tz(newDate, user.timezone));
            onChange({ [FIELDS.GMB_START_DATE]: newDate });
          }}
          inThePast={startInThePast}
          field={FIELDS.GMB_START_DATE}
          disabled={disabled}
        >
          Start date
        </EventDate>
        <EventDate
          className=""
          date={endDate}
          onChange={(newDate) => {
            setEndDate(Moment.tz(newDate, user.timezone));
            onChange({ [FIELDS.GMB_END_DATE]: newDate });
          }}
          inThePast={endInThePast}
          field={FIELDS.GMB_END_DATE}
          disabled={disabled}
        >
          End date
        </EventDate>
      </div>
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.GMB_START_DATE, FIELDS.GMB_END_DATE]}
        errors={errors}
      />
    </div>
  );
};

const EventDate = ({
  date,
  field,
  children,
  platforms,
  selectedPlatform,
  onChange,
  inThePast,
  className,
}) => {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const scheduledClasses = "tw-border-sked-500 tw-bg-sked-200 tw-text-sked-500";
  const scheduledErrorClasses =
    "tw-border-red-500 tw-bg-red-100 tw-text-red-500";

  const timePreference = "12h";
  // const dateFormat = "D MMM hh:mma";
  const dateFormat = timePreference === "12h" ? "D MMM hh:mma" : "D MMM HH:mm";
  const disabled = false;
  const maxSelectableDate = moment().add(364, "days").endOf("day").toDate();

  return (
    <div className={className}>
      <DisplayBox
        className={`tw-relative tw-p-4 ${
          inThePast ? scheduledErrorClasses : scheduledClasses
        }`}
      >
        {children}:
        <span
          className="tw-underline tw-cursor-pointer tw-ml-4"
          onClick={() => !disabled && setDatePickerVisible(!datePickerVisible)}
        >
          {date ? date.format(dateFormat) : "Pick date"}
        </span>
        {datePickerVisible && (
          <div
            className="tw-absolute"
            style={{ transform: "translate(10%, -70%)", zIndex: 1000 }}
          >
            <DateTimePicker
              selectedDate={date}
              onChange={(newDateTime) => {
                setDatePickerVisible(false);
                onChange(newDateTime);
              }}
              timePreference={timePreference}
              onClose={() => setDatePickerVisible(false)}
              arrowSide="left"
              maxSelectableDate={maxSelectableDate}
            />
          </div>
        )}
      </DisplayBox>
    </div>
  );
};

const DisplayBox = ({ children, className = "" }) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-border tw-border-solid tw-rounded-lg tw-font-medium tw-px-4 ${className}`}
    >
      {children}
    </div>
  );
};

export default PostEventDates;
