import ngDeps from "ng-react-directives/ngr-injector";
import { put, call, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import Api from "api/externalApproval";
import collabApi from "api/collaboration";
import { handleError } from "utils/handleError";

import {
  BULK_CHANGE_COLLAB_STATE_ROUTINE,
  CHANGE_COLLAB_STATE_ROUTINE,
  INIT_EXTERNAL_COLLAB_ROUTINE,
  ADD_EXTERNAL_COLLAB_THEME_ROUTINE,
  UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE,
  DELETE_EXTERNAL_COLLAB_THEME_ROUTINE,
  ADD_EXTERNAL_COLLAB_LINK_ROUTINE,
  UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE,
  DELETE_EXTERNAL_COLLAB_LINK_ROUTINE,
  GET_COLLABORATION_COMMENTS_ROUTINE,
  GET_COLLABORATION_USERS_ROUTINE,
  GET_COLLABORATION_UNREADS_COUNT_ROUTINE,
  ADD_COLLABORATION_COMMENT_ROUTINE,
  EDIT_COLLABORATION_COMMENT_ROUTINE,
  DELETE_COLLABORATION_COMMENT_ROUTINE,
  COLLABORATION_COMMENTS_LOADING_ON,
  COLLABORATION_COMMENTS_LOADING_OFF,
} from "constants/ActionTypes";
import { reloadPosts as reloadPostsAction } from "actions/postsView";

const eaApi = new Api();

function* changeCollabState(action) {
  const { type } = action.type;
  const routine =
    type === BULK_CHANGE_COLLAB_STATE_ROUTINE.TRIGGER
      ? BULK_CHANGE_COLLAB_STATE_ROUTINE
      : CHANGE_COLLAB_STATE_ROUTINE;
  const { postIds, postType, key, callback } = action.payload;
  const { collaborationService } = ngDeps;

  yield put({ type: routine.REQUEST });

  // This was running on bulk change collab state before call (drafts | activities)
  // if (!post.schedule.channel || post.schedule.channel === "")
  //   post.schedule.channel = post._id;
  try {
    yield call(
      collaborationService.updatePostStatusKey,
      postIds,
      postType,
      key
    );
    yield put({
      type: routine.SUCCESS,
      payload: {
        postIds: postIds,
        postType: postType,
        postStatusKey: key,
      },
    });
    yield put(reloadPostsAction());

    toast.success("Post Status was successfully updated.");
  } catch (err) {
    console.error("Err updating post status", err);
    toast.error("Post Status was not updated.");

    yield put({
      type: routine.FAILURE,
      error: true,
      payload: err,
    });
  }

  callback && callback();
}

function* initExternalCollab(action) {
  yield put({
    type: INIT_EXTERNAL_COLLAB_ROUTINE.REQUEST,
    payload: {},
  });

  try {
    const themes = yield call(eaApi.getExternalCollabThemes);

    const links = yield call(eaApi.getExternalCollabLinks);

    // create object for use in table
    links.data.forEach((link) => {
      link.states = {
        review: link.showPostWithState,
        approve: link.acceptState,
        reject: link.rejectState,
      };
    });
    yield put({
      type: INIT_EXTERNAL_COLLAB_ROUTINE.SUCCESS,
      payload: { themes: themes.data, links: links.data },
    });
  } catch (err) {
    yield put({
      type: INIT_EXTERNAL_COLLAB_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    // in case it is permissions related, we can get a new token any time
    localStorage.removeItem("skedApiToken");
    handleError(err);
  }
}
function* addExternalCollabTheme(action) {
  let { theme } = action.payload;
  yield put({
    type: ADD_EXTERNAL_COLLAB_THEME_ROUTINE.REQUEST,
    payload: {
      theme,
    },
  });
  try {
    let themeResult = yield call(
      eaApi.createTheme,
      theme.name,
      theme.imageUrl,
      theme.companyName
    );
    theme = themeResult.data;
    yield put({
      type: ADD_EXTERNAL_COLLAB_THEME_ROUTINE.SUCCESS,
      payload: { theme },
    });
  } catch (err) {
    yield put({
      type: ADD_EXTERNAL_COLLAB_THEME_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}
function* updateExternalCollabTheme(action) {
  const { theme } = action.payload;
  yield put({
    type: UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE.REQUEST,
    payload: {
      theme,
    },
  });
  try {
    yield call(
      eaApi.updateTheme,
      theme._id,
      theme.name,
      theme.imageUrl,
      theme.companyName
    );
    yield put({
      type: UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE.SUCCESS,
      payload: { theme },
    });
  } catch (err) {
    yield put({
      type: UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}
function* deleteExternalCollabTheme(action) {
  const { id } = action.payload;
  yield put({
    type: DELETE_EXTERNAL_COLLAB_THEME_ROUTINE.REQUEST,
    payload: {
      id,
    },
  });

  try {
    yield call(eaApi.deleteTheme, id);
    yield put({
      type: DELETE_EXTERNAL_COLLAB_THEME_ROUTINE.SUCCESS,
      payload: { id },
    });
  } catch (err) {
    yield put({
      type: DELETE_EXTERNAL_COLLAB_THEME_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}
function* addExternalCollabLink(action) {
  let { link } = action.payload;
  yield put({
    type: ADD_EXTERNAL_COLLAB_LINK_ROUTINE.REQUEST,
    payload: {
      link,
    },
  });
  try {
    let result = yield call(eaApi.createLink, link);
    link = result.data;
    yield put({
      type: ADD_EXTERNAL_COLLAB_LINK_ROUTINE.SUCCESS,
      payload: { link },
    });
  } catch (err) {
    yield put({
      type: ADD_EXTERNAL_COLLAB_LINK_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}
function* updateExternalCollabLink(action) {
  let { link } = action.payload;
  yield put({
    type: UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE.REQUEST,
    payload: {
      link,
    },
  });
  try {
    let result = yield call(eaApi.updateLink, link);
    link = result.data;
    yield put({
      type: UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE.SUCCESS,
      payload: { link },
    });
  } catch (err) {
    yield put({
      type: UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}
function* deleteExternalCollabLink(action) {
  const { id } = action.payload;
  yield put({
    type: DELETE_EXTERNAL_COLLAB_LINK_ROUTINE.REQUEST,
    payload: {
      id,
    },
  });

  try {
    yield call(eaApi.deleteLink, id);
    yield put({
      type: DELETE_EXTERNAL_COLLAB_LINK_ROUTINE.SUCCESS,
      payload: { id },
    });
  } catch (err) {
    yield put({
      type: DELETE_EXTERNAL_COLLAB_LINK_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

function* getCollaborationComments(action) {
  const { channelId } = action.payload;
  yield put({
    type: COLLABORATION_COMMENTS_LOADING_ON,
  });

  try {
    const { data } = yield call(collabApi.getComments, channelId);
    yield put({
      type: GET_COLLABORATION_COMMENTS_ROUTINE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: GET_COLLABORATION_COMMENTS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    yield put({
      type: COLLABORATION_COMMENTS_LOADING_OFF,
    });
  }
}

function* getCollaborationUsers() {
  try {
    const { data } = yield call(collabApi.getCollaborators);
    yield put({
      type: GET_COLLABORATION_USERS_ROUTINE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: GET_COLLABORATION_USERS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}
function* getCollaborationUnreadsCount() {
  yield put({
    type: GET_COLLABORATION_UNREADS_COUNT_ROUTINE.REQUEST,
  });
  try {
    const { data } = yield call(collabApi.getUnreads);
    yield put({
      type: GET_COLLABORATION_UNREADS_COUNT_ROUTINE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: GET_COLLABORATION_UNREADS_COUNT_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

function* addCollaborationComment(action) {
  const { channelId, body, metadata, done } = action.payload;
  yield put({
    type: COLLABORATION_COMMENTS_LOADING_ON,
  });

  try {
    yield call(collabApi.addComment, channelId, {
      body,
      metadata,
    });
  } catch (err) {
    handleError(err);
  } finally {
    yield put({
      type: COLLABORATION_COMMENTS_LOADING_OFF,
    });

    done && done();
  }
}

function* editCollaborationComment(action) {
  const { channelId, commentId, body, done } = action.payload;
  yield put({
    type: COLLABORATION_COMMENTS_LOADING_ON,
  });

  try {
    yield call(collabApi.editComment, channelId, commentId, {
      body,
    });
  } catch (err) {
    handleError(err);
  } finally {
    yield put({
      type: COLLABORATION_COMMENTS_LOADING_OFF,
    });

    done && done();
  }
}

function* deleteCollaborationComment(action) {
  const { channelId, commentId, done } = action.payload;
  try {
    yield call(collabApi.deleteComment, channelId, commentId);
  } catch (err) {
    handleError(err);
  } finally {
    done && done();
  }
}

export default function* collaborationSagas() {
  yield takeEvery(BULK_CHANGE_COLLAB_STATE_ROUTINE.TRIGGER, changeCollabState);
  yield takeEvery(CHANGE_COLLAB_STATE_ROUTINE.TRIGGER, changeCollabState);
  yield takeEvery(INIT_EXTERNAL_COLLAB_ROUTINE.TRIGGER, initExternalCollab);
  yield takeEvery(
    ADD_EXTERNAL_COLLAB_THEME_ROUTINE.TRIGGER,
    addExternalCollabTheme
  );
  yield takeEvery(
    UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE.TRIGGER,
    updateExternalCollabTheme
  );
  yield takeEvery(
    DELETE_EXTERNAL_COLLAB_THEME_ROUTINE.TRIGGER,
    deleteExternalCollabTheme
  );
  yield takeEvery(
    ADD_EXTERNAL_COLLAB_LINK_ROUTINE.TRIGGER,
    addExternalCollabLink
  );
  yield takeEvery(
    UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE.TRIGGER,
    updateExternalCollabLink
  );
  yield takeEvery(
    DELETE_EXTERNAL_COLLAB_LINK_ROUTINE.TRIGGER,
    deleteExternalCollabLink
  );
  yield takeEvery(
    GET_COLLABORATION_COMMENTS_ROUTINE.TRIGGER,
    getCollaborationComments
  );
  yield takeEvery(
    GET_COLLABORATION_USERS_ROUTINE.TRIGGER,
    getCollaborationUsers
  );
  yield takeEvery(
    GET_COLLABORATION_UNREADS_COUNT_ROUTINE.TRIGGER,
    getCollaborationUnreadsCount
  );
  yield takeEvery(
    ADD_COLLABORATION_COMMENT_ROUTINE.TRIGGER,
    addCollaborationComment
  );
  yield takeEvery(
    EDIT_COLLABORATION_COMMENT_ROUTINE.TRIGGER,
    editCollaborationComment
  );
  yield takeEvery(
    DELETE_COLLABORATION_COMMENT_ROUTINE.TRIGGER,
    deleteCollaborationComment
  );
}
