import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";
import { logout } from "utils/accounts";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Trigger, DropdownMenuContent, SubMenu, Link, Logout } from "./header";
import styled from "styled-components";
import CaretIcon from "./icons/caretIcon.svg";
import UsersIcon from "./icons/usersIcon.svg";
import SettingsIcon from "./icons/settingsIcon.svg";
import HelpAndSupportIcon from "./icons/helpAndSupportIcon.svg";
import LogoutIcon from "./icons/logoutIcon.svg";
import { StyledSocialAccountIcon } from "./AddAccountMenu";

export function HeaderUserMenu(): React.ReactElement {
  const user = useSelector(getUser);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <Trigger
          fontWeight={500}
          data-testid="profile-menu"
          id="dasboard-profile-menu"
        >
          {user.userName}
          <CaretIcon />
        </Trigger>
      </DropdownMenu.Trigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          padding="0"
          minWidth={260}
          data-testid="profile-menu-links"
          align="end"
          sideOffset={8}
        >
          <SubMenu>
            <Link to="/dashboard/accounts" id="dasboard-manage-social-accounts">
              <DropdownMenu.Item>
                <IconWrap>
                  <StyledSocialAccountIcon />
                  Manage social accounts & groups
                </IconWrap>
              </DropdownMenu.Item>
            </Link>

            <LineBreak invisible />

            <Link to="/dashboard/users" id="dashboard-manage-users">
              <DropdownMenu.Item>
                <IconWrap>
                  <UsersIcon />
                  Manage users
                </IconWrap>
              </DropdownMenu.Item>
            </Link>

            <LineBreak />

            <Link to="/dashboard/profile" id="dashboard-settings">
              <DropdownMenu.Item>
                <IconWrap>
                  <SettingsIcon />
                  Settings
                </IconWrap>
              </DropdownMenu.Item>
            </Link>

            <LineBreak invisible />

            <Link to="/dashboard/contact" id="dashboard-help-and-support">
              <DropdownMenu.Item>
                <IconWrap>
                  <HelpAndSupportIcon />
                  Help &amp; Support
                </IconWrap>
              </DropdownMenu.Item>
            </Link>

            <LineBreak />

            <Logout
              data-testid="logout-button"
              onClick={logout}
              id="dashboard-logout"
            >
              <IconWrap>
                <LogoutIcon />
                Logout
              </IconWrap>
            </Logout>
          </SubMenu>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu.Root>
  );
}

const LineBreak = styled.div<{ invisible?: boolean }>`
  border: none;
  border-top: 1px solid
    ${({ invisible }) => (invisible ? "transparent" : "#F2F4F7")};
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 12px 0 0;
  }
`;

const DropdownMenuPortal = styled(DropdownMenu.Portal)`
  position: relative;
  top: -4px;
  left: 14px;
`;
