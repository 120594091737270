import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import styled from "styled-components";

interface Props<T> {
  listItems: T[];
  renderListItem?: (suggestion: T) => JSX.Element;
  onSelect(suggestion: T): void;
  className?: string;
}

export function TagsList<T>(props: Props<T>) {
  return (
    <ListContainer className={props.className}>
      <ListScroller style={{ maxHeight: 96, minHeight: 30 }}>
        {props.listItems.map((item, index) => (
          <Item key={index} onClick={() => props.onSelect(item)}>
            {props.renderListItem ? props.renderListItem(item) : item}
          </Item>
        ))}
      </ListScroller>
    </ListContainer>
  );
}

const ListContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 30px;
  height: auto;
  max-height: 96px;
  border-radius: 5px;
  overflow: hidden;
  background: white;
`;

const Item = styled.div`
  position: relative;
  min-height: 30px;
  border-radius: 5px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: #f6f5ff;
    border-color: #f6f5ff;
  }
`;

const ListScroller = styled(SimpleBar)`
  .simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #dedafe;
    border-radius: 7px;
    left: 2px;
    right: 8px;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
    transition: opacity 0s linear;
  }

  .simplebar-track.simplebar-vertical {
    width: 14px;
  }

  .simplebar-track.simplebar-horizontal {
    display: none;
  }
`;
