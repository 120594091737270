import { CollectionFile } from "shared/types/files";
import { FeedMedia } from "./types";
import { StockImage } from "api/media-library";

export const feedMediaToCollectionFile = (
  feedItem: FeedMedia
): CollectionFile => {
  return {
    _id: feedItem.id,
    thumbnail: feedItem.thumbnail || feedItem.media_url,
    downloadUrl: feedItem.media_url,
    meta: {
      isVideo: feedItem.media_type === "VIDEO",
      isImage: feedItem.media_type === "IMAGE",
    },
    storage: {
      url: feedItem.media_url,
    },
    description: feedItem.caption,
    asset_id: feedItem.id,
  } as CollectionFile;
};

export const stockImageToCollectionFile = (
  item: StockImage
): CollectionFile => {
  return {
    _id: String(item.id),
    thumbnail: item.src.tiny,
    downloadUrl: item.src.original,
    meta: {
      isVideo: false,
      isImage: true,
    },
    storage: {
      url: item.src.original,
    },
    description: item.alt,
    asset_id: String(item.id),
  } as CollectionFile;
};

export const convertFileToFeed = (file: CollectionFile) => ({
  id: file._id,
  media_url: file.downloadUrl,
  permalink: file.downloadUrl,
  caption: file.description,
  username: "unknow",
});

export const convertFileToStockImage = (file: CollectionFile) => ({
  id: file._id,
  alt: file.description,
  url: file.downloadUrl,
  src: {
    original: file.downloadUrl,
  },
});
