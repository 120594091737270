import React, { useEffect, useMemo, useState } from "react";
import { SkedButton } from "ui";
import { ExternalLink } from "ui/Link";
import moment from "moment";
import { DATE_FORMAT } from "constants/common";
import { LIBRARY_ACCEPT_HASHTAG_DISCLAIMER } from "constants/Events";
import ngDeps from "ng-react-directives/ngr-injector";
import { Alert, AlertType } from "ui/alert";

interface DisclaimerProps {
  onAccept?: () => void;
  sessionKey?: string;
  trackingEventName?: string;
  alertTitle?: string;
  alwaysShow?: boolean;
}

export function useDisclaimer({
  sessionKey = "feedDisclaimerAcceptedAt",
  alwaysShow = false,
  onAccept,
}: Pick<DisclaimerProps, "onAccept" | "sessionKey" | "alwaysShow">) {
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  const alreadyAccepted = useMemo(() => {
    const daysTillShowAgain = 30;
    let sessionAccepted = moment(sessionStorage.getItem(sessionKey));
    if (sessionAccepted.isValid()) {
      const expired = sessionAccepted
        .add(daysTillShowAgain, "days")
        .isBefore(moment());
      if (expired) {
        sessionStorage.removeItem(sessionKey);
        sessionAccepted = moment(null); //invalid date;
      }
    }

    return sessionAccepted.isValid() ? true : false;
  }, [sessionKey]);

  useEffect(() => {
    if (alwaysShow) {
      setDisclaimerOpen(true);
      return;
    }

    setDisclaimerOpen(true);
    setDisclaimerOpen(!alreadyAccepted);
    if (alreadyAccepted && onAccept) {
      // If session is stored, let's auto call the callback given
      onAccept();
    }
  }, [sessionKey, alwaysShow, onAccept, alreadyAccepted]);

  const onUpdate = () => {
    sessionStorage.setItem(sessionKey, moment().format(DATE_FORMAT));
    if (!alwaysShow) {
      setDisclaimerOpen(false);
    }
  };

  return {
    disclaimerOpen,
    onUpdate,
    alreadyAccepted,
  };
}

const Disclaimer = ({
  onAccept,
  sessionKey,
  trackingEventName = LIBRARY_ACCEPT_HASHTAG_DISCLAIMER,
  alertTitle = "Confirm rights before saving",
  alwaysShow = false,
}: DisclaimerProps) => {
  const { disclaimerOpen, onUpdate } = useDisclaimer({
    alwaysShow,
    onAccept,
    sessionKey,
  });

  const acceptDisclaimer = () => {
    const { eventService } = ngDeps;
    eventService.trackEvent({
      eventName: trackingEventName,
      useServices: ["AMP"],
    });

    onUpdate();

    if (onAccept) {
      onAccept();
    }
  };

  return (
    <>
      {disclaimerOpen && (
        <Alert
          type={AlertType.error}
          icon={true}
          title={alertTitle}
          isExpandable={true}
          defaultExpanded={true}
        >
          <p>
            If you save content that belongs to someone else, please ensure that
            you have obtained permission and/or have an appropriate license from
            them, and you provide attribution if appropriate.
          </p>
          <p>
            Per our{" "}
            <ExternalLink href="https://skedsocial.com/terms-of-service/">
              Terms of Service
            </ExternalLink>
            , you warrant that you have the necessary rights, licenses and
            consents when adding content to Sked.
          </p>
          <div css="margin-top: 10px;">
            <SkedButton
              onClick={acceptDisclaimer}
              type="alert"
              name="Accept & Close"
              size="small"
            />
          </div>
        </Alert>
      )}
    </>
  );
};

export default Disclaimer;
