import { createTable } from "libs/effector-normilaze";

import { AccountType } from "shared/types/accounts";
import { accountHasGroup, groupAccounts } from "./libs/accounts-groups";
import { getAccountPlatformType } from "./libs/account-platform";
import { sortAccounts } from "./libs/sort-accounts";

export const accountsTable = createTable<AccountType>();

export const $accounts = accountsTable.getAll();

export const $instagramAccounts = $accounts.map((accounts) =>
  accounts.filter((account) => getAccountPlatformType(account) === "IG")
);

export const $accountsByGroups = $accounts.map<
  Array<{ title: string; accounts: AccountType[] }>
>((accounts) => groupAccounts(accounts.filter(accountHasGroup)));

export const $accountsWithoutGroups = $accounts.map((accounts) =>
  sortAccounts(accounts.filter((account) => !accountHasGroup(account)))
);
