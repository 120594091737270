import { RequiredFacebookScopes } from "api/social-account-connect/facebook";
import { AccountType } from "shared/types/accounts";

export function filterValidInboxAccounts(
  accounts: AccountType[]
): AccountType[] {
  return accounts.filter((account) => {
    let requiredScopes: string[] = [];
    if (account.platformType === "FB" && !account.isGroup) {
      requiredScopes = RequiredFacebookScopes.inbox;
    } else if (account.platformType === undefined) {
      // showing inbox enabled IG account only needs "instagram_manage_messages"
      requiredScopes = ["instagram_manage_messages"];
    } else if (
      account.platformType === "LI" &&
      account.linkedinUrn &&
      account.linkedinUrn.includes("organization")
    ) {
      return true;
    } else if (account.platformType === "GMB") {
      return true;
    } else if (account.platformType === "TT" && account.platformId) {
      return true;
    } else {
      return false;
    }

    const scopes = account.session?.scopes ?? [];
    return requiredScopes.every((scope) => {
      return scopes.includes(scope);
    });
  });
}
