import scApi from "api/skedCore";
import { SKED_CORE_GET_ACCOUNTS_ROUTINE } from "constants/ActionTypes";
import { accountsTable } from "entities/accounts";
import { normalizeEntity } from "reducers/reducerUtils";
import { call, put, takeEvery } from "redux-saga/effects";
import { normalizeAccounts } from "utils/accounts";

const coreApi = new scApi();

export function* getAccounts(action) {
  yield put({ type: SKED_CORE_GET_ACCOUNTS_ROUTINE.REQUEST });
  try {
    const accountsResp = yield call(coreApi.getAccounts);
    const normalizedAccounts = normalizeAccounts(accountsResp.data);
    yield put({
      type: SKED_CORE_GET_ACCOUNTS_ROUTINE.SUCCESS,
      payload: { accounts: normalizedAccounts },
    });
    const { byId, allIds } = normalizeEntity(normalizedAccounts);
    // when get accounts by action (not first time, from angular) need to update effector accounts table
    yield call(accountsTable.replaceAll, { ids: allIds, byId });

    if (action?.payload?.callback) {
      yield call(action?.payload?.callback);
    }
  } catch (err) {
    yield put({
      type: SKED_CORE_GET_ACCOUNTS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });

    if (action?.payload?.callback) {
      yield call(action?.payload?.callback, err);
    }
  }
}

/// WATCHERS
///
export function* watchGetAccounts() {
  yield takeEvery(SKED_CORE_GET_ACCOUNTS_ROUTINE.TRIGGER, getAccounts);
}
