import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "libs/modals";
import { ADD_COLLABORATOR_MODAL } from ".";
import styled from "styled-components";
import { useState } from "react";
import { Icon, AvatarNew, Button } from "@dialogueconsulting/sked-ui";
import { toast } from "react-toastify";
import { getInstaUser } from "api/tags";

interface CollaboratorModalProps {
  beta?: boolean;
  title: string;
  notification: string;
  limit: number;
  onSubmit(tags: { [key: string]: any }[]): void;
  collaboratorIg: [];
  accountId: string;
}

export const CollaboratorModal: React.FC<CollaboratorModalProps> = ({
  beta,
  title,
  notification,
  limit,
  onSubmit,
  collaboratorIg,
  accountId,
}) => {
  const dispatch = useDispatch();
  function closeCollaboratorModal() {
    dispatch(closeModal({ type: ADD_COLLABORATOR_MODAL }));
  }
  const [isInputting, setIsInputting] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState(
    collaboratorIg && collaboratorIg.length > 0 ? collaboratorIg : []
  );
  const handleEnterKey = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key !== "Enter" || !(event.target instanceof HTMLInputElement)) {
      return;
    }

    const inputValue = event.target.value;
    if (inputValue === "") {
      return;
    }

    if (selectedCollaborator.find((user) => user.username === inputValue)) {
      toast.error("User Already Selected");
      return;
    }

    try {
      setIsValidating(true);
      const validation = await checkInsta(accountId, inputValue);

      setSelectedCollaborator([
        ...selectedCollaborator,
        {
          username: inputValue,
          profilePictureUrl: validation.profile_picture_url,
        },
      ]);
      event.target.value = "";
    } catch (err) {
      toast.error(
        "The user data was not found. The user either does not exist or is not a professional (Business/Creator) account."
      );
    } finally {
      setIsValidating(false);
    }
  };
  const handleCollaboratorRemoval = (key: string) => {
    setSelectedCollaborator(
      selectedCollaborator.filter((anItem) => anItem.username !== key)
    );
  };

  async function checkInsta(accountId: string, userInfo: string) {
    const { data } = await getInstaUser(accountId, userInfo);
    return data;
  }
  return (
    <div>
      <Modal
        data-testid="add-user-modal"
        show
        onHide={closeCollaboratorModal}
        animation={false}
        // @ts-ignore
        bsStyle="giselle"
        // @ts-ignore
        bsSize="lg"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={closeCollaboratorModal}
          >
            &times;
          </button>
          <h4 className="modal-title">
            {" "}
            <ModalTitle>
              <ModalTitleContent>{title}</ModalTitleContent>
              {beta && <ModalBeta>Beta</ModalBeta>}
            </ModalTitle>
          </h4>
        </div>
        <div className="modal-body">
          <Wrap>
            <ModalContent>
              <Notification>
                <Icon name="alert-circle" size={16} color="#4F31FF"></Icon>
                <NotificationContent>{notification}</NotificationContent>
              </Notification>
              <CollaboratorWarp>
                <ModalInputWarp limit={selectedCollaborator.length >= limit}>
                  <ModalInput
                    type="text"
                    placeholder={
                      selectedCollaborator.length >= limit
                        ? `Maximum ${limit} collaborators`
                        : isValidating
                        ? ""
                        : "Enter collaborator's username"
                    }
                    disabled={
                      selectedCollaborator.length >= limit || isValidating
                    }
                    onFocus={() => {
                      setIsInputting(true);
                    }}
                    onBlur={() => {
                      setIsInputting(false);
                    }}
                    onKeyDown={handleEnterKey}
                  ></ModalInput>
                  {isInputting && selectedCollaborator.length < limit && (
                    <ModalInputTip>↵ enter to confirm</ModalInputTip>
                  )}
                </ModalInputWarp>
                <SelectedCollaborator
                  selectedCollaborator={selectedCollaborator}
                  handleCollaboratorRemoval={handleCollaboratorRemoval}
                ></SelectedCollaborator>
              </CollaboratorWarp>
            </ModalContent>
            <ModalFooterButtons>
              <Button
                text="Cancel"
                hierarchy="secondary"
                size="md"
                color="blue"
                onClick={closeCollaboratorModal}
              ></Button>
              <Button
                disabled={isValidating}
                text="Continue"
                hierarchy="primary"
                size="md"
                color="blue"
                onClick={async () => {
                  onSubmit(selectedCollaborator);
                  closeCollaboratorModal();
                }}
              ></Button>
            </ModalFooterButtons>
          </Wrap>
        </div>
      </Modal>
    </div>
  );
};

export const SelectedCollaborator: React.FC<{
  selectedCollaborator: {
    [key: string]: any;
  }[];
  handleCollaboratorRemoval?: (name: string) => void;
}> = ({ selectedCollaborator, handleCollaboratorRemoval }) => {
  return (
    <Collaborators>
      {selectedCollaborator.map((item) => (
        <Collaborator key={item.username}>
          <AvatarNew
            src={item.profilePictureUrl || undefined}
            alt={item.username || ""}
            platform="instagram"
            alert={false}
          ></AvatarNew>
          <CollaboratorContent>{item.username}</CollaboratorContent>
          <CollaboratorAction
            target="_blank"
            href={`https://www.instagram.com/${item.username}/`}
            onClick={(e) => {
              if (!item.username) {
                e.preventDefault();
                toast.error("Instagram account not found");
              }
            }}
          >
            <Icon name="link-external-01" size={16} color="#4F31FF"></Icon>
          </CollaboratorAction>
          <CollaboratorAction
            onClick={() => {
              if (handleCollaboratorRemoval) {
                handleCollaboratorRemoval(item.username);
              }
            }}
          >
            <Icon name="x-close" size={16} color="#808080"></Icon>
          </CollaboratorAction>
        </Collaborator>
      ))}
    </Collaborators>
  );
};

export const SelectedCollaboratorTag: React.FC<{
  selectedCollaborator: [];
}> = ({ selectedCollaborator }) => {
  return (
    <Collaborators>
      {selectedCollaborator.map((item) => (
        <CollaboratorTag key={item.username}>
          <CollaboratorContentTag>@{item.username}</CollaboratorContentTag>
          <CollaboratorAction
            target="_blank"
            href={`https://www.instagram.com/${item.username}/`}
            onClick={(e) => {
              if (!item) {
                e.preventDefault();
                toast.error("Instagram account not found");
              }
            }}
          >
            <Icon name="link-external-01" size={16} color="#4F31FF"></Icon>
          </CollaboratorAction>
        </CollaboratorTag>
      ))}
    </Collaborators>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  background: #fff;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
const ModalFooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const ModalTitle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
const ModalTitleContent = styled.div`
  color: #0e1680;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;
const ModalBeta = styled.div`
  color: #808080;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;
const Notification = styled.div`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #edeaff;
`;
const NotificationContent = styled.div`
  color: #4f31ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const CollaboratorWarp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ModalInputWarp = styled.div<{ limit: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 8px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: ${({ limit }) => (limit ? "#F2F2F2" : "#fff")};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:focus-within {
    border: 1px solid #4f31ff;
  }
`;
const ModalInput = styled.input`
  width: 100%;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #333;

  background: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #808080;
  }
  &:focus {
    outline: none;
  }
`;
const ModalInputTip = styled.div`
  color: #808080;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  flex-shrink: 0;
`;

const Collaborators = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;
const Collaborator = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #edeaff;
  text-align: center;
`;
const CollaboratorTag = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: #ffff;
  text-align: center;
`;
const CollaboratorContent = styled.div`
  display: flex;
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
  &:hover {
    cursor: default;
  }
`;
const CollaboratorContentTag = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: default;
  }
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const CollaboratorAction = styled.a`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
