export type AccountPlatformType =
  | "IG"
  | "FB"
  | "TW"
  | "PI"
  | "GMB"
  | "YT"
  | "LI"
  | "SC"
  | "OT"
  | "TH"
  | "TT";

export type PlatformName =
  | "facebook"
  | "googlemybusiness"
  | "instagram"
  | "linkedin"
  | "pinterest"
  | "snapchat"
  | "tiktok"
  | "other"
  | "threads"
  | "twitter"
  | "youtube";

export const platformAccountNameMap: Record<AccountPlatformType, PlatformName> =
  {
    IG: "instagram",
    FB: "facebook",
    TW: "twitter",
    PI: "pinterest",
    TH: "threads",
    GMB: "googlemybusiness",
    YT: "youtube",
    LI: "linkedin",
    SC: "snapchat",
    TT: "tiktok",
    OT: "other",
  };

export enum PolicyEnforcementStatus {
  Ok = "Ok",
  Warning = "Warning",
  Blocked = "Blocked",
}

export type InsightsEmailSettings = {
  enabled?: boolean;
  emailAddresses?: string[];
};

export type AccountType = {
  _id: string;
  userId?: string;
  displayLogin?: string;
  login: string;
  profilePictureUrl?: string;
  color?: string;
  loginAlias?: string;
  changeDefaultThumb?: boolean;
  storyNotificationUsers: string[];
  platformType?: AccountPlatformType | undefined;
  platformId?: string;
  platformName?: string;
  thProfileData?: ThreadsProfile;
  queueSettings?: {
    enabled: boolean;
    schedules: {
      day: number;
      timeSlots: number[];
      _id: string;
    }[];
    notifyEmptyQueue: boolean;
    timezone: string;
  };
  queueEnabled: boolean;
  // FB only
  defaultPage?: {
    id: string;
  };
  // IG with sked link integration
  accountPublicKey?: string | null;

  // NOTE will be true for facebook group accounts
  isGroup?: boolean;
  igTokenAddedAt?: boolean;
  promptApiReconnect?: boolean;
  status:
    | "ok"
    | "insightsOnly"
    | "failed"
    | "checking"
    | "reviewing"
    | "waiting"
    | "deactivated"
    | "code"
    | "verifying"
    | "queued"
    | "notApproved"
    | "received";
  url?: {
    enabled: boolean;
  };
  createdAt?: string;
  sessionResynchedAt?: string;
  session?: {
    scopes?: string[];
    data_access_expires_at: number;
    expires_at: number;
    issued_at: number;
    refresh_token_expires_at: number;
  };

  failReason?: string;
  userDisplayReason?: string;
  legit: boolean;
  failShotURL?: string;
  loginType?: string;
  canVerifyEmail?: boolean;
  canVerifySMS?: boolean;
  twoFactorEnabled?: boolean;

  //TODO need to refactor into notificationSettings
  successNotifications?: string[];
  errorNotifications:
    | boolean
    | {
        enabled: boolean;
        emailAddresses?: string[];
      };
  weeklyInsightsEmail?: InsightsEmailSettings;
  monthlyInsightsEmail?: InsightsEmailSettings;
  utms?: AccountUtms;
  platformPageId?: string;
  linkedinUrn?: string;
  linkedinVanityName?: string;
  piData?: {
    username?: string;
  };
  gmbData?: {
    metadata?: {
      mapsUrl?: string;
      mapsUri?: string;
    };
    title?: string;
    storefrontAddress?: {
      addressLines?: string[];
      locality?: string;
      regionCode?: string;
      postalCode?: string;
    };
  };
  igData?: {
    website?: string;
  };
  notificationSettings?: {
    publish?: {
      enabled: boolean;
      notification: string[];
      email: string[];
    };
    postFailed?: {
      enabled: boolean;
      notification: string[];
      email: string[];
    };
    postSuccess?: {
      enabled: boolean;
      notification: string[];
      email: string[];
    };
  };
  policyEnforcementStatus?: PolicyEnforcementStatus;
  policyEnforcementInfo?: string;
  enableInsights?: boolean;
  userPlan?: string;
  featureToggles?: Record<string, boolean>;
};

type AccountUtms = {
  enabled: boolean;
  name?: string;
  source?: string;
  medium?: string;
};

export type AccountGroup = {
  title: string;
  accounts: AccountType[];
};
