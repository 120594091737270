import { skedApi } from "api/_skedApi";
import { AxiosError } from "axios";
import { CREATE_BUTTON, HOME_CAPTIVATES, NEW_IDEA } from "constants/Events";
import { useTrackEvent } from "libs/analytics";
import { useEffect, useState } from "react";

interface Captions {
  index: string;
  text: string;
}

export const useManualCaptivate = ({
  textareaRef,
  captionsNumber = 5,
}: {
  textareaRef: any;
  captionsNumber?: number;
}) => {
  const [placeholderText, setPlaceholderText] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const [includeEmoji, setIncludeEmoji] = useState(
    sessionStorage.getItem("includeEmojiCaptivate") !== "false" ? true : false
  );
  const [fetchingCaptions, setFetchingCaptions] = useState<boolean>(false);
  const [captions, setCaptions] = useState<Captions[] | []>([]);

  const [exceedUsage, setExceedUsage] = useState<string>("");
  const trackEvent = useTrackEvent();

  const fetchCaptions = async (prompt: string, includeEmoji: boolean) => {
    try {
      setFetchingCaptions(true);
      const response = await skedApi.post("/sked/openai/captions", {
        prompt,
        number_captions: captionsNumber,
        include_emoji: includeEmoji,
      });
      if (response?.data?.captions) {
        setCaptions(response.data.captions as Captions[]);
        trackEvent({
          eventName: HOME_CAPTIVATES,
          metadata: { type: "manual" },
          useServices: ["AMP"],
        });
      } else {
        setExceedUsage(
          "There was a problem getting captions from Captivate. Please try again shortly or let our support team know if the issue persists."
        );
      }
      setFetchingCaptions(false);
    } catch (error) {
      setExceedUsage((error as AxiosError)?.response?.data?.message);
      setFetchingCaptions(false);
    }
  };

  const hasCaptions = captions.length > 0;

  const placeholderList = [
    "Call to action to take part in a charity event for an animal rescue",
    "Promote a Zoo celebrating the birth of a baby elephant",
    "A luxury watch brand promoting a new collection inspired by the beauty of Paris",
    "Celebrate the engagement of a long time brand collaborator",
    "Black friday campaign for a restaurant serving BBQ meats",
    "An inspiring story about food served during the midsummer",
    "A holiday promotion for the most unique boutique hotel in town",
    "Story behind the most moving piece of art that you’ve seen at a museum",
    "Promotion of your athletic brand’s new line of activewear",
    "A local clothing boutique’s celebration of their 3rd anniversary since opening",
    "Hair care brand’s new temporary hair color product release",
    "Call to action to sign up for an herbalist’s email newsletter that focuses on recipes, education and local events",
    "Invite to join a makeup artist’s Instagram Live for Halloween makeup",
    "A day in the life of a landscape artist showing different hedge designs",
    "Show off the top 5 best airbnb’s in Nashville, Tennessee for bachelorette parties",
    "Inspire students to sign up for a local college fair",
  ];

  useEffect(() => {
    setPlaceholderText(
      placeholderList[Math.floor(Math.random() * placeholderList.length)]
    );
  }, []);

  const handleCreateButtonClick = async () => {
    if (userInput.trim() !== "") {
      await fetchCaptions(userInput.trim(), includeEmoji);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).hj("event", CREATE_BUTTON);
      trackEvent({
        eventName: CREATE_BUTTON,
        metadata: {
          "emoji on": includeEmoji,
        },
      });
    } else {
      textareaRef.current?.focus();
    }
  };

  const handleNewIdeaClick = () => {
    setCaptions([]);
    trackEvent({ eventName: NEW_IDEA });
  };

  return {
    exceedUsage,
    hasCaptions,
    placeholderText,
    setUserInput,
    userInput,
    fetchCaptions,
    includeEmoji,
    fetchingCaptions,
    setIncludeEmoji,
    handleCreateButtonClick,
    handleNewIdeaClick,
    captions,
  };
};
