import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedHashtagFeedCollection } from "selectors/hashtagFeedSelectors";
import { StockImage } from "api/media-library";
import SaveDropdown from "../SaveDropdown";
import styled from "styled-components";
import { addCollectionAssetsFromStock } from "actions/mediaLibrary";

const HEADER_TEXT = "Stock images";
const SUBTITLE_TEXT = "Find stock images to add to your library";

type Props = {
  selectedItems: StockImage[];
  onSubmit: () => void;
};

const StocksFeedHeader = ({ selectedItems, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const selectedHashtagFeedCollection: any = useSelector(
    getSelectedHashtagFeedCollection
  );

  const isDisabled =
    selectedItems.length === 0 || selectedHashtagFeedCollection === null;

  return (
    <Container>
      <TitlesContainer>
        <Title>
          <h3>{HEADER_TEXT}</h3>
          {selectedItems.length > 0 && (
            <Subtitle>{selectedItems.length} selected</Subtitle>
          )}
        </Title>
        <Subtitle>{SUBTITLE_TEXT}</Subtitle>
      </TitlesContainer>
      <SaveDropdown
        disabled={isDisabled}
        onSave={() => {
          dispatch(
            addCollectionAssetsFromStock({
              collectionId: selectedHashtagFeedCollection._id,
              stockItems: selectedItems,
              location: "library",
            })
          );
          onSubmit();
        }}
      />
    </Container>
  );
};

const TitlesContainer = styled.div``;
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  h3 {
    font-size: 2.25rem;
    font-weight: 800;
    margin: 0px 1rem 0px 0;
  }
`;
const Subtitle = styled.div`
  color: rgb(202, 202, 202);
`;

const Container = styled.div`
  display: flex;
  margin: 2rem 0;
`;

export default StocksFeedHeader;
