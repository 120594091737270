import React from "react";
import styled from "styled-components";
import { LiCommentMessage } from "types/conversationInbox";
import { NormalMessage, NormalMessageProps } from "./normal-message";
import { renderContentProps } from "./types";

type Props = Pick<renderContentProps, "originalMessage"> & NormalMessageProps;
export const LiComment = (props: Props): JSX.Element => {
  const liComment = props.originalMessage as LiCommentMessage | undefined;
  const imgUrl = liComment?.content?.[0]?.url;
  const message = props.message || (imgUrl && "Shared an image") || "";
  return (
    <>
      {imgUrl && (
        <ImagePreviewAttachment
          isLeft={props.isLeft}
          alt="Attachment Preview Image"
          src={imgUrl}
        />
      )}
      <NormalMessage {...props} message={message} />
    </>
  );
};

interface LeftProps {
  isLeft?: boolean;
}
const mediaBgColor = "#f8f8f8";
const ImagePreviewAttachment = styled.img<LeftProps>`
  width: 200px;
  height: 200px;
  background-color: ${mediaBgColor};
  flex-shrink: 0;
  object-fit: contain;
  margin-left: 15px;
  margin-right: 15px;
  ${(props) => (!props.isLeft ? "align-self: flex-end;" : undefined)}
`;
