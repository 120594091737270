import React, { useRef, useState } from "react";
// @ts-ignore
import { Picker } from "emoji-mart";
import styled from "styled-components";

import { useClickOutside } from "libs/use-click-outside";
import { useOpenableState } from "libs/use-openable-state";
// @ts-ignore
import Smile from "../icons/smile.svg";

const HEIGHT_OF_EMOJI_SELECTOR = 426.64;
interface EmojiPickerProps {
  className?: string;
  onSelect: (value: any) => void;
  leftAlign: boolean;
}

export function EmojiPickerComponent(props: EmojiPickerProps) {
  const emojiModal = useOpenableState();
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, emojiModal.close);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [bottomAligned, setBottomAligned] = useState(false);

  function onSelect(emoji: any) {
    props.onSelect(emoji);
  }

  const onMouseDown = () => {
    // check the distance between template button to bottom of the browser
    // if diff is less than height of the selector, bottom align it
    const bounding = buttonRef?.current?.getBoundingClientRect();
    if (
      (bounding?.bottom ?? 0) + HEIGHT_OF_EMOJI_SELECTOR >
      window.innerHeight + 50
    ) {
      // open invert
      setBottomAligned(true);
    }
    emojiModal.toggle();
  };

  return (
    <div ref={wrapperRef} className={props.className}>
      <TemplateButton
        type="button"
        isOpen={emojiModal.isOpened}
        onMouseDown={onMouseDown}
        ref={buttonRef}
      >
        <Smile />
      </TemplateButton>
      {emojiModal.isOpened && (
        <Picker
          onClick={onSelect}
          sheetSize={32}
          title="Sked"
          {...(bottomAligned ? { style: { top: "unset", bottom: 40 } } : {})}
        />
      )}
    </div>
  );
}

const TemplateButton = styled.button<{ isOpen: boolean }>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  appearance: none;
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid ${(props) => (props.isOpen ? "#4f31ff" : "#dedafe")};
  border-radius: 5px;
  background: ${(props) => (props.isOpen ? "#4f31ff" : "#F6F5FF")};
`;

export const EmojiPicker = styled(EmojiPickerComponent)`
  // emoji-mart package class name
  .emoji-mart {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1000;
    ${({ leftAlign }) =>
      leftAlign &&
      `
      left: 0;
    `}
  }
`;
