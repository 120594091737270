import React from "react";
import { PostAccount } from "components/Posts/Post/index";
import { Caption, DetailsTemplate } from "./post-details-template";

export function ThreadsDetails({ post, account, page }) {
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          <Caption post={post} />
        </>
      )}
    />
  );
}
