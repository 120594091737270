import React, { useState } from "react";
import styled from "styled-components";
import {
  CreatableSelect,
  CreatableSelectOnSelectValue,
} from "../components/creatable-select";
import { useTrackEvent } from "libs/analytics";
import { LIBRARY_SEARCH_INFLUENCER } from "constants/Events";
import { OptionProps, components } from "react-select";
import { InstagramUser } from "../types";

export function CreatorSelector({
  requestUser,
  recentUsers,
  onCreatorSelected,
  handleRemoveCreator,
}: {
  requestUser: (val: string) => Promise<InstagramUser | undefined>;
  recentUsers: InstagramUser[];
  onCreatorSelected: (user: InstagramUser) => void;
  handleRemoveCreator: (userId: string) => void;
}) {
  const trackEvent = useTrackEvent();
  const [username, setUsername] = useState("");
  const [customValue, setCustomValue] = useState("");

  const onChange = ({
    label,
    value,
    __isNew__,
  }: CreatableSelectOnSelectValue<string>) => {
    if (__isNew__) {
      setCustomValue(value);
    } else {
      const user = recentUsers.find((user) => user._id === value);

      if (user) {
        setCustomValue("");
        openUserContent(user);
        setUsername(label);
      }
    }
  };

  const onCustomValueChange = (val: string) => {
    setCustomValue("");
    setUsername(val);
  };

  const openUserContent = (user: InstagramUser) => {
    onCreatorSelected && onCreatorSelected(user);
    setCustomValue("");
    setUsername("");
  };

  const searchCreator = async () => {
    trackEvent({
      eventName: LIBRARY_SEARCH_INFLUENCER,
      useServices: ["AMP"],
      metadata: { location: "picker" },
    });
    try {
      const searchedUser = await requestUser(customValue);
      searchedUser && openUserContent(searchedUser);
    } catch (err) {
      console.warn(err);
    }
  };

  const options = React.useMemo(
    () =>
      recentUsers.map((user) => ({
        label: user.userName,
        value: user._id,
      })),
    [recentUsers]
  );

  const onDelete = (event: React.MouseEvent<HTMLElement>, userId: string) => {
    event.stopPropagation();
    handleRemoveCreator && handleRemoveCreator(userId);
  };

  return (
    <>
      <CreatableSelect
        name="creators"
        customValue={username}
        options={options}
        placeholder="Search for Influencer..."
        noOptionsMessage="No influencer added yet."
        onChange={onChange}
        onCustomValueChange={onCustomValueChange}
        value={null}
        components={{
          Option: function Option(props: OptionProps<InstagramUser[], false>) {
            const { value, label } = props.data;

            return (
              <components.Option {...props}>
                <CreatorOption data-testid="creator-option">
                  <CreatorName>{label}</CreatorName>
                  <CreatorRemove onClick={(e) => onDelete(e, value)}>
                    x
                  </CreatorRemove>
                </CreatorOption>
              </components.Option>
            );
          },
        }}
      />
      <Button disabled={!customValue} onClick={searchCreator}>
        + Add
      </Button>
    </>
  );
}

const Button = styled.button`
  background: #4f31ff;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  border: none;
  height: 38px;
  line-height: 38px;
  margin-left: 10px;
  width: 94px;

  &:disabled {
    background: #eee !important;
    color: #c4c4c4 !important;
  }
`;

const CreatorOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CreatorName = styled.div`
  font-size: 12px;
  color: #333333;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CreatorRemove = styled.div`
  font-size: 12px;
  color: #c4c4c4;
  cursor: pointer;
`;
