import { groupBy } from "lodash";
import { AccountType, AccountGroup } from "shared/types/accounts";
import { BRAND_DELIMITER } from "utils/accounts";
import { sortAccounts } from "./sort-accounts";

export function accountHasGroup(account: AccountType): boolean {
  const parts = account?.loginAlias?.split(BRAND_DELIMITER);

  return !!(parts && parts.length > 1);
}

export function getAccountGroupName(account: AccountType): string | null {
  if (accountHasGroup(account)) {
    return (account.loginAlias as string).split(BRAND_DELIMITER)[0].trim();
  } else {
    return null;
  }
}

export function getGroupedAccountName(account: AccountType): string {
  return (account.loginAlias as string).split(BRAND_DELIMITER)[1].trim();
}

export function groupAccounts(accounts: AccountType[]): AccountGroup[] {
  const byGroups = groupBy(accounts, (acc) => getAccountGroupName(acc));

  return Object.entries(byGroups).map(([title, accounts]) => ({
    title,
    accounts: sortAccounts(accounts),
  }));
}
