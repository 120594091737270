import React from "react";
import styled, { css } from "styled-components";

import { Button } from "./button";
import { SmallLoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";

type Props = React.ComponentProps<typeof Button> & {
  isSubmitting: boolean;
};

function SubmitButtonComponent({
  className,
  children,
  isSubmitting,
  disabled,
  ...props
}: Props) {
  return (
    <Button
      className={className}
      {...props}
      disabled={disabled || isSubmitting}
    >
      {isSubmitting && (
        <Loader>
          <SmallLoadingSpinner />
        </Loader>
      )}
      <Content isSubmitting={isSubmitting}>{children}</Content>
    </Button>
  );
}

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${SmallLoadingSpinner} {
    max-height: 80%;
  }
`;

const Content = styled.div<{ isSubmitting: boolean }>`
  ${(props) =>
    props.isSubmitting &&
    css`
      visibility: hidden;
    `}
`;

export const SubmitButton = styled(SubmitButtonComponent)`
  position: relative;
  ${Loader} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;
