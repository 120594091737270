import React from "react";
import PropTypes from "prop-types";

const EmptyContainer = ({ children }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full">
      <div className="tw-text-black tw-font-black tw-text-4xl">{children}</div>
    </div>
  );
};

EmptyContainer.propTypes = {
  children: PropTypes.node,
};

export default EmptyContainer;
