import { filterAccountsByPlatformType, splitAccounts } from "utils/accounts";
import { getEntityMap } from "./selectorUtils";
import { createSelector } from "@reduxjs/toolkit";
import { getUser } from "./commonSelectors";

const ACCOUNT_ENTITY = "accounts";

export const getAccountsById = (state) => state.entities[ACCOUNT_ENTITY].byId;
export const getAccountIds = (state) => state.entities[ACCOUNT_ENTITY].allIds;
export const getSelectedAccountIds = (state) =>
  state.entities[ACCOUNT_ENTITY].selectedIds;

export const getSelectedAccounts = createSelector(
  (state) => state.entities[ACCOUNT_ENTITY].allIds,
  (state) => state.entities[ACCOUNT_ENTITY].byId,
  getSelectedAccountIds,
  (allIds, byId, selectedIds) => {
    return getEntityMap(allIds, byId, selectedIds);
  }
);

export const getAccounts = createSelector(
  (state) => state.entities.accounts.byId,
  (byId) => Object.values(byId)
);

export const getAccountsGroups = createSelector(
  (state) => state.entities.accountGroups.entities,
  (entities) => Object.values(entities)
);

export const getAccountsByGroup = createSelector(
  getAccounts,
  getAccountsGroups,
  getUser,
  (accounts, accountGroups, user) => {
    // NOTE: (Andrey) only admin can see empty group, because not possible give access to empty group for collaborators
    const isAdmin = user && user.role === "admin";
    const { groupedAccounts, otherAccounts } = splitAccounts(
      accounts,
      isAdmin ? accountGroups : []
    );

    return {
      groupedAccounts,
      otherAccounts,
      allAccounts: accounts,
      accountGroups,
    };
  }
);

export const getAccountsCounts = (state) => state.entities.accounts.counts;

export const getAccountsForPlatform = (state, platform) => {
  const allAccounts = getAccounts(state);

  return allAccounts.filter(filterAccountsByPlatformType(platform));
};

export const getAccount = (state, accountId) => {
  const account = state.entities.accounts.byId[accountId];
  if (!account) {
    return null;
  }
  return account;
};

export const getIGTokenAccounts = (state) => state.entities.accounts.withToken;
