import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Button } from "./button";
import { InputWrapper } from "./_input-base-wrapper";

export function CopyToClipboardInput(props): JSX.Element {
  const [copied, setCopied] = useState(false);
  // for some reason  max-width: 60% works weird in grid
  const [size, setSize] = useState("60%");
  const rootElementRef = useRef();

  useEffect(() => {
    function handler() {
      setSize(`${rootElementRef.current.offsetWidth * 0.6}px`);
    }
    handler();
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);

  function handleClickCopy() {
    copyToClipboard(props.value);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }

  return (
    <CopyToClipboardInputRoot ref={rootElementRef} as="div">
      <ClipboardValue width={size}>{props.value}</ClipboardValue>
      <Button primary={copied} outline={!copied} onClick={handleClickCopy}>
        {copied ? "Copied" : "Copy Key"}
      </Button>
    </CopyToClipboardInputRoot>
  );
}

const ClipboardValue = styled.div<{ width: string }>`
  max-width: ${(props) => props.width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyToClipboardInputRoot = styled(InputWrapper).attrs({ as: "div" })`
  justify-content: space-between;
  padding-right: 4px;
  ${Button} {
    width: 84px;
    height: calc(var(--inputHeight) - 6px);
  }
`;

function copyToClipboard(text) {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
