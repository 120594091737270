import React, { useRef, MouseEvent } from "react";
import { components, ControlProps, MenuProps } from "react-select";

import { useClickOutside } from "libs/use-click-outside";

export const MultiSelectMenu = (props: {
  menuProps: MenuProps<any, any>;
  closeMenu: (event: MouseEvent) => void;
}): JSX.Element => {
  const { menuProps, closeMenu } = props;
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, closeMenu);

  return (
    <div data-testid="multiSelectMenu" ref={ref}>
      <components.Menu {...menuProps}>{menuProps.children}</components.Menu>
    </div>
  );
};

export const MultiSelectControlComponent = (props: {
  controlProps: ControlProps<any, false>;
  toggleMenu: (event: MouseEvent) => void;
}): JSX.Element => {
  return (
    <div onClick={props.toggleMenu}>
      <components.Control {...props.controlProps} />
    </div>
  );
};
