import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOpenableState } from "libs/use-openable-state";
import styled from "styled-components";
import { AccountSearchInput } from "./components/account-search-input";
import { SelectedAccountOverview } from "./components/selected-accounts-overview";
import { AccountType } from "shared/types/accounts";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface PropTypes {
  selectedAccounts: AccountType[];
  renderAccountPicker: () => ReactNode;
  onSearch: (text: string) => void;
}

export const AccountPickerWithSearch = ({
  selectedAccounts,
  renderAccountPicker,
  onSearch,
}: PropTypes): JSX.Element => {
  const collapsed = useOpenableState(true);

  return (
    <Wrapper>
      <SelectContainer onClick={collapsed.toggle}>
        {selectedAccounts.length === 0 ? (
          <DisplayLabel>Select Account(s)</DisplayLabel>
        ) : (
          <SelectedAccountOverview selectedAccounts={selectedAccounts} />
        )}
        <DropdownIcon icon={collapsed.isOpened ? faChevronUp : faChevronDown} />
      </SelectContainer>
      {collapsed.isOpened && (
        <>
          <AccountSearchInput onSearch={onSearch} />
          <AccountPickerContainer>
            {renderAccountPicker()}
          </AccountPickerContainer>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DisplayLabel = styled.div`
  padding-left: 12px;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  font-size: 10px;
  color: #4f31ff;
  cursor: pointer;
  padding: 5px;

  &.svg-inline--fa {
    width: 22px;
    height: 22px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
`;

const AccountPickerContainer = styled.div`
  margin-left: -2rem;
  flex: 1;
  flex-shrink: 1;
  min-height: 0;
  overflow: visible;
  display: flex;
  flex-direction: column;
`;
