import React from "react";
import styled from "styled-components";
import { AccountType } from "shared/types/accounts";
import { ExternalLink } from "ui/external-link";
import Tooltip from "react-bootstrap/lib/Tooltip";

import { getAccountLink } from "../../libs/get-account-link/get-account-link";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

const openLinkTooltip = (
  <Tooltip id="open-link-tooltip">Open account in new window</Tooltip>
);

function AccountLinkComponent(props: {
  account: AccountType;
  className?: string;
}) {
  const link = getAccountLink(props.account);

  if (!link) return null;

  return (
    <OverlayTrigger placement="top" overlay={openLinkTooltip}>
      <ExternalLink href={link} className={props.className} />
    </OverlayTrigger>
  );
}

export const AccountLink = styled(AccountLinkComponent)``;
