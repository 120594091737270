import React from "react";
import { PostAccount } from "components/Posts/Post/index";
import { Caption, DetailsTemplate, AltTextTags } from "./post-details-template";

export function TwitterDetails({ post, account, page }) {
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          <Caption post={post} />
          <AltTextTags post={post} />
        </>
      )}
    />
  );
}
