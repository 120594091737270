import React from "react";
import styled from "styled-components";

import { openSupportChat } from "libs/support";
import { Link } from "ui/Link";

import { AddLibraryButton, Center, List, SMSplash } from "./atoms";
import demo from "./sm-splash-chrome-ext-demo.jpg";

export function SMSplashChromeExt() {
  return (
    <SMSplash
      title="Save images from anywhere on the Internet"
      subtitle="No downloading and uploading required, save from your Chrome browser to Sked"
    >
      <Center>
        <AddLibraryButton />
      </Center>
      <Grid css="margin-top: calc(var(--spacing) * 3);">
        <div>
          <p>
            <b>Save content from the Internet, straight to Sked.</b>
          </p>
          <List css="margin-top: var(--spacing);">
            <li>
              <p>
                <b>
                  Add the Sked Media Library extension from the Chrome store
                </b>
              </p>
            </li>
            <li>
              <p>
                <b>Choose the collection to save to</b>
              </p>
              <p>Select which Library collection you want to save to</p>
            </li>
            <li>
              <p>
                <b>Save the image straight to your Library</b>
              </p>
            </li>
            <li>
              <p>
                <b>
                  Share it across Instagram, Facebook, Twitter, Pinterest and
                  LinkedIn
                </b>
              </p>
            </li>
          </List>
          <Center css="margin-top: calc(var(--spacing) * 2);">
            <AddLibraryButton />
          </Center>
          <Center css="margin-top: calc(var(--spacing) * 0.5);">
            <p>
              Get help from{" "}
              <Link as="button" onClick={() => openSupportChat()}>
                Sked Support
              </Link>
            </p>
          </Center>
        </div>
        <div css="justify-self: center">
          <img
            css="width: 30vw; height: auto;"
            src={demo}
            alt="Chrome Extension functionality demo"
          />
        </div>
      </Grid>
    </SMSplash>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: calc(var(--spacing) * 2);
`;
