import { TOGGLE_SIDEBAR_FILTERS, SHOW_MODAL } from "constants/ActionTypes";
import * as EVENTS from "constants/Events";
import ngDeps from "ng-react-directives/ngr-injector";
import { call } from "redux-saga/effects";

import { PAGES } from "constants/MediaLibrary";

export function* trackEventByType(action) {
  const { type } = action;
  const { eventService } = ngDeps;

  switch (type) {
    case TOGGLE_SIDEBAR_FILTERS:
      eventService.trackEvent({
        eventName: EVENTS.LIBRARY_TOGGLE_FILTERS,
        useServices: ["AMP"],
      });
      break;
    case SHOW_MODAL:
      trackModalAction(action);
      break;
  }
}

const trackModalAction = (action) => {
  const { modalType } = action.payload;
  const { eventService } = ngDeps;
  switch (modalType) {
    case "EDIT_COLLECTION_ASSET":
      eventService.trackEvent({
        eventName: EVENTS.LIBRARY_VIEW_ASSET_DETAIL,
        useServices: ["AMP"],
      });
      break;
  }
};

export const findEventSource = (page) => {
  switch (page) {
    case PAGES.TAGGED_IN:
    case PAGES.TAGGED_IN_SPLASH:
      return "tagged-in-media";
    case PAGES.STOCKS:
      return "stock-media";
    case PAGES.CREATOR:
    case PAGES.CREATOR_SPLASH:
      return "influencers-media";
    default:
      return "hashtag-media";
  }
};

const onErrorHandler = (err) => {
  console.error("Redux-Saga safe error handler:", err);
};

export const sagaErrorHandlerWrapper = ({
  errorHandler = onErrorHandler,
  saga,
}) =>
  function* (action) {
    try {
      yield call(saga, action);
    } catch (err) {
      yield call(errorHandler, err);
    }
  };
