const ignoredRoutineSet = new Set<string>();
export const addIgnoreLoadingRoutineName = (
  routineName: string | null
): void => {
  if (!routineName) {
    return;
  }
  ignoredRoutineSet.add(routineName);
};

export const shouldIgnoreLoadingRoutineName = (
  routineName: string | null
): boolean => {
  if (!routineName) {
    return false;
  }
  return ignoredRoutineSet.has(routineName);
};

export const resetAllIgnoredLoadingActions = (): void => {
  ignoredRoutineSet.clear();
};
