import React, { useMemo } from "react";

import { FIELDS } from "constants/PostFormFields";
import { PLATFORMS } from "constants/Platforms";

import { SwitchField } from "../components/SwitchField";

export const TiktokSwitchNotification = ({
  platforms,
  value,
  hasTikTokPosting,
  updateField,
  allDisabled,
  oldTiktokAccounts,
}: {
  platforms: any[];
  value: boolean;
  hasTikTokPosting: boolean;
  updateField: (field: string, value: any) => void;
  allDisabled: false;
  oldTiktokAccounts: any[];
}) => {
  const showSwitcher = useMemo(() => {
    const isNewTTAndOldTTOnly =
      oldTiktokAccounts.length > 0 &&
      hasTikTokPosting &&
      platforms.length === 1;

    return (
      !isNewTTAndOldTTOnly &&
      platforms.length > 0 &&
      platforms.some(({ TYPE }) => TYPE === PLATFORMS.TT.TYPE)
    );
  }, [hasTikTokPosting, oldTiktokAccounts.length, platforms]);

  if (!showSwitcher) {
    return <></>;
  }

  const onChange = () => {
    updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, !value);
  };

  return (
    <SwitchField
      defaultChecked={value}
      onChange={onChange}
      disabled={allDisabled || oldTiktokAccounts.length !== 0}
    />
  );
};
