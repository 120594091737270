import {
  SELECT_COLLECTION_ROUTINE,
  CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE,
  DELETE_COLLECTION_USER_ROUTINE,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { normalizeEntity } from "reducers/reducerUtils";
import _ from "lodash";

const initialState = {
  byId: {},
  allIds: [],
};

const collectionUsersReducer = createReducer(initialState, {
  [SELECT_COLLECTION_ROUTINE.SUCCESS]: (state, action) => {
    const { users } = action.payload;
    if (!users) {
      return state;
    }

    const { byId, allIds } = normalizeEntity(users);
    return {
      ...state,
      byId,
      allIds,
    };
  },
  [CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE.SUCCESS]: (state, action) => {
    const { call, collectionUser } = action.payload;

    state.byId[collectionUser._id] = collectionUser;
    if (call === "create") {
      state.allIds.push(collectionUser._id);
    }

    return state;
  },
  [DELETE_COLLECTION_USER_ROUTINE.SUCCESS]: (state, action) => {
    const { collectionUserId } = action.payload;
    delete state.byId[collectionUserId];
    _.remove(state.allIds, (id) => {
      return id === collectionUserId;
    });

    return state;
  },
});

export default collectionUsersReducer;
