import { ContentLabel } from "@workshop/types";
import { insightsApi } from "api/insightsApi";
import { groupAccounts } from "entities/accounts/libs/accounts-groups";
import moment from "moment";
import { AccountPlatformType, AccountType } from "shared/types/accounts";

const ContentLabelsCaptivateCacheKey = "ContentLabelsCaptivateCacheKey";
const cacheDays = 30 * 6;
interface ContentLabelsCaptivateCache {
  groupName: string;
  data: ContentLabel[];
  timestamp: string;
}

export interface AccountGroup {
  groupName: string;
  accounts: {
    _id: string;
    platformType?: AccountPlatformType;
    platformId?: string;
  }[];
}

class ContentLabelsCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private runningPromises = new Map<string, Promise<any>>();

  updateLabelCache({
    groupName,
    prevLabelName,
    label,
  }: {
    groupName: string;
    prevLabelName: string;
    label: LabelItem;
  }) {
    const labels = this.getContentLabelsCaptivateFromGroupCache(groupName);
    if (!labels) {
      return;
    }
    const foundIdx = labels.findIndex((c) => c.label === prevLabelName);
    if (foundIdx < 0) {
      return;
    }
    labels[foundIdx] = label;
    this.setContentLabelsCaptivateCache(groupName, labels);
  }

  async getContentLabelsCaptivate({ groupName, accounts }: AccountGroup) {
    if (!accounts.length) {
      return [];
    }
    const labels = this.getContentLabelsCaptivateFromGroupCache(groupName);
    if (labels) {
      return labels as LabelItem[];
    }

    return await this.withinSinglePromise(groupName, async () => {
      const result = await insightsApi.getContentLabelsOnboardingCaptivate({
        accounts,
      });
      this.setContentLabelsCaptivateCache(groupName, result as LabelItem[]);
      return result;
    });
  }

  async prefetchContentLabelsCaptivate(accounts: AccountType[]) {
    const groups = groupAccounts(accounts);
    for (const group of groups) {
      console.log(
        "Prefetching Labels for",
        group.title,
        group.accounts.map((c) => c._id)
      );
      const result = await this.getContentLabelsCaptivate({
        groupName: group.title,
        accounts: group.accounts,
      });
      console.log("Prefetching Labels done", group.title, result);
    }
  }

  async removeContentLabelsCaptivateFromGroupCache(
    groupName: string | undefined,
    label: LabelItem
  ) {
    if (!groupName) {
      return;
    }

    const groupCache = this.getContentLabelsCaptivateFromGroupCache(groupName);

    if (!groupCache) {
      return;
    }

    const newGroupCache = groupCache.filter(
      (cacheLabel) => cacheLabel.label !== label.label
    );

    this.setContentLabelsCaptivateCache(groupName, newGroupCache);
  }

  async addContentLabelsCaptivateToGroupCache(
    groupName: string | undefined,
    label: LabelItem
  ) {
    if (!groupName) {
      return;
    }

    const groupCache = this.getContentLabelsCaptivateFromGroupCache(groupName);

    if (!groupCache) {
      return;
    }

    const newGroupCache = [...groupCache, label];

    this.setContentLabelsCaptivateCache(groupName, newGroupCache);
  }

  private async withinSinglePromise<T>(
    groupName: string,
    func: () => Promise<T>
  ): Promise<T> {
    const runningPromise = this.runningPromises.get(groupName);
    if (runningPromise) {
      return await runningPromise;
    }

    try {
      const promise = func();
      this.runningPromises.set(groupName, promise);
      const result = await promise;
      return result;
    } finally {
      this.runningPromises.delete(groupName);
    }
  }

  private setContentLabelsCaptivateCache(groupName: string, data: LabelItem[]) {
    const cache = this.getContentLabelsCaptivateCache();
    cache[groupName] = {
      groupName,
      data,
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem(ContentLabelsCaptivateCacheKey, JSON.stringify(cache));
  }

  private getContentLabelsCaptivateCache() {
    const data = localStorage.getItem(ContentLabelsCaptivateCacheKey);
    if (!data) {
      return {};
    }
    return JSON.parse(data) as ContentLabelsCaptivateCache;
  }

  private getContentLabelsCaptivateFromGroupCache(
    groupName: string
  ): LabelItem[] | undefined {
    const cache = this.getContentLabelsCaptivateCache();
    const found = cache[groupName];
    if (!found) {
      return undefined;
    }

    if (moment(found.timestamp).add(cacheDays, "days").isBefore(moment())) {
      return undefined;
    }
    return found.data;
  }
}

export const contentLabelsCache = new ContentLabelsCache();

interface LabelItem {
  label?: string;
  description?: string;
  emoji?: string;
  color?: string;
}
