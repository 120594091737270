import styled from "styled-components";

export const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  margin: 0;
  color: #333333;
  text-align: center;
`;

export const List = styled.ul`
  font-size: 14px;
  line-height: 1.25;
  margin: 0;
  color: #454545;

  & > li {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
`;

export const Price = styled.p`
  font-weight: 900;
  font-size: 24px;
  line-height: 1.25;
  margin: 0;
  color: #333333;
  text-align: center;
`;

export const PriceSecondary = styled.p`
  font-weight: 900;
  font-size: 14px;
  line-height: 1.25;
  margin: 0;
  color: #333333;
  text-align: center;
`;

export const Spacing = styled.div`
  height: 20px;
`;
