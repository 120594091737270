import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  BULK_CHANGE_COLLAB_STATE_ROUTINE,
  CHANGE_COLLAB_STATE_ROUTINE,
  FETCH_ARCHIVED_POSTS_ROUTINE,
  FETCH_DRAFT_POSTS_ROUTINE,
  FETCH_UPCOMING_POSTS_ROUTINE,
  FILTER_COLLAB_STATUS,
  FILTER_POST_TYPE,
  HANDLE_STATUS_CHANGED,
  INIT_POSTS_PAGE_ROUTINE,
  RELOAD_POSTS_ROUTINE,
  REMOVE_POSTS_ROUTINE,
  SEND_POSTS_TO_DRAFT_ROUTINE,
  SEND_POSTS_TO_QUEUE_ROUTINE,
  TOGGLE_POST_CHECKBOX,
  TOGGLE_SELECT_ALL_POSTS,
  UPDATE_POSTS_PAGE_NAME,
} from "constants/ActionTypes";
import { POST_STATES } from "constants/PostsView";
import { standardizationPostData } from "utils/posts";

const initialState = {
  byId: {},
  allIds: [],
  selectedIds: [],
  edittingIds: [],
  allSelected: false,
  total: null,
  hasMore: false,
  filters: null,
  allLoaded: false, // for inf scroll
};

export const postsReducer = createReducer(initialState, {
  [UPDATE_POSTS_PAGE_NAME]: (state, action) => {
    const { page } = action.payload;
    state.page = page;
    if (!state.common) {
      state.common = {};
    }
    state.common.page = page;

    return state;
  },
  [INIT_POSTS_PAGE_ROUTINE.TRIGGER]: () => {
    return initialState;
  },
  [FILTER_COLLAB_STATUS]: () => {
    return initialState;
  },
  [FILTER_POST_TYPE]: () => {
    return initialState;
  },
  [RELOAD_POSTS_ROUTINE.TRIGGER]: () => {
    return initialState; // this causes the flicker, find a better way to do this.
    // return state;
  },
  [FETCH_UPCOMING_POSTS_ROUTINE.SUCCESS]: (state, action) => {
    const { posts, filters, hasMore } = action.payload;
    state.filters = filters;

    let newQueuedAccounts = {};

    if (posts.length) {
      state.filters.lastNextRunAt = posts[posts.length - 1].timestamp;
    }

    for (var i = posts.length - 1, post; i >= 0; i--) {
      post = posts[i];
      if (post.queued && !newQueuedAccounts[post.accountId]) {
        newQueuedAccounts[post.accountId] = true;
        state.filters.accountFilters[post.accountId] = {
          lastOrdinal: post.ordinal,
        };
      }
    }

    state.hasMore = hasMore;
    state.allLoaded = !(posts.length > 1);
    return fetchPostsSuccess(state, action);
  },
  [FETCH_DRAFT_POSTS_ROUTINE.SUCCESS]: (state, action) => {
    return fetchPostsSuccess(state, action);
  },
  [FETCH_ARCHIVED_POSTS_ROUTINE.SUCCESS]: (state, action) => {
    return fetchPostsSuccess(state, action);
  },
  [TOGGLE_SELECT_ALL_POSTS]: (state, action) => {
    state.allSelected = !state.allSelected;

    state.selectedIds = [];
    if (state.allSelected) {
      state.selectedIds = state.allIds;
    }

    return state;
  },
  [TOGGLE_POST_CHECKBOX]: (state, action) => {
    const { postId } = action.payload;

    if (state.selectedIds.includes(postId)) {
      _.remove(state.selectedIds, (selectedId) => selectedId === postId);
    } else {
      state.selectedIds.push(postId);
    }

    state.allSelected = false;
    if (
      state.allIds.length > 0 &&
      state.selectedIds.length === state.allIds.length
    ) {
      state.allSelected = true;
    }

    return state;
  },
  [CHANGE_COLLAB_STATE_ROUTINE.SUCCESS]: (state, action) =>
    updateCollabState(state, action),
  [BULK_CHANGE_COLLAB_STATE_ROUTINE.SUCCESS]: (state, action) => {
    state.selectedIds = [];
    state.allSelected = false;
    return updateCollabState(state, action);
  },
  [SEND_POSTS_TO_DRAFT_ROUTINE.SUCCESS]: (state, action) =>
    clearSelected(state, action),
  [SEND_POSTS_TO_QUEUE_ROUTINE.SUCCESS]: (state, action) =>
    clearSelected(state, action),
  [REMOVE_POSTS_ROUTINE.SUCCESS]: (state, action) =>
    clearSelected(state, action),
  [HANDLE_STATUS_CHANGED]: (state, { payload: { post, postType } }) => {
    const posts = _.cloneDeep(state.byId);
    const postRow = posts[post.postId];

    if (!postRow) return state;

    // TODO: Check how this affect on the post status filters
    // if is draft
    if (
      postType.toLowerCase() === POST_STATES.DRAFT.toLowerCase() &&
      postRow.schedule
    ) {
      postRow.schedule.postStatusKey = post.newStatus.key;
    } else {
      postRow.postStatusKey = post.newStatus.key;
    }
    return { ...state, byId: posts };
  },
});

const clearSelected = (state, action) => {
  state.selectedIds = [];
  state.allSelected = false;
  return state;
};

const updateCollabState = (state, action) => {
  const { postIds, postStatusKey } = action.payload;
  postIds.map((postId) => {
    // in some scenarios we do not have a full state here
    // eg when editing a post in calendar or planner - as that state is angularjs
    if (state.byId && state.byId[postId]) {
      if (state.byId[postId].schedule) {
        state.byId[postId].schedule.postStatusKey = postStatusKey;
      } else {
        state.byId[postId].postStatusKey = postStatusKey;
      }
    }
  });
  return state;
};

const fetchPostsSuccess = (state, action, clearState = true) => {
  state.allSelected = false;

  if (clearState) {
    state.byId = {};
    state.allIds = [];
    state.selectedIds = [];
    state.total = null;
    state.filters = null;
    state.allLoaded = false;
  }

  const { posts, total } = action.payload;
  if (!posts) {
    return state;
  }

  posts.map((post) => {
    state.byId[post._id] = post;
    if (!state.allIds.includes(post._id)) {
      state.allIds.push(post._id);
    }
  });

  if (total) {
    state.total = total;
  }

  return state;
};

const changeCollabState = (state, action) => {
  console.log("Change Collab state", state, action);
  let posts = Object.assign({}, state.byId);
  const { postIds, postStatusKey } = action.payload;
  _.map(postIds, (postId) => {
    if (posts[postId].schedule) {
      posts[postId].schedule.postStatusKey = postStatusKey;
    } else {
      posts[postId].postStatusKey = postStatusKey;
    }
  });

  return {
    ...state,
    byId: posts,
  };
};

const editPostUrl = (state, action) => {
  const { post } = action.payload;

  let posts = { ...state.byId };

  posts[post._id] = post;

  return {
    ...state,
    byId: posts,
  };
};

const sortPost = (state, action) => {
  const { fromIndex, toIndex } = action.payload;

  // Put the post into the posts array to prevent the post from jumping
  // around before the controller refreshes the data.
  let copyAllPosts = state.allIds.slice();

  // Remove post at index
  copyAllPosts.splice(toIndex, 0, copyAllPosts[fromIndex]);

  return {
    ...state,
    allPosts: copyAllPosts,
  };
};

const setNewPosts = (state, action) => {
  let byId = {};
  let allIds = [];

  _.map(action.posts, (post) => {
    byId[post._id] = post;
    allIds.push(post._id);
  });

  return {
    byId,
    allIds,
  };
};

const movePostsToPage = (state, action) => {
  if (!action.payload || !action.payload.selectedPostIds) {
    console.log("Invalid payload for action", action);
    return {
      ...state,
      allIds: [],
      byId: {},
    };
  }

  const { selectedPostIds } = action.payload;

  let byId = {};
  let allIds = [];
  _.map(state.allIds, (postId) => {
    if (selectedPostIds.includes(postId)) {
      return;
    }
    byId[postId] = state.byId[postId];
    allIds.push(postId);
  });

  return {
    ...state,
    byId,
    allIds,
  };
};

const removePostsFromPage = (state, action) => {
  if (!action.payload || !action.payload.removedPostIds) {
    console.log("Invalid payload for action", action);
    return {
      ...state,
    };
  }

  const { removedPostIds } = action.payload;

  let byId = {};
  let allIds = [];
  _.map(state.allIds, (postId) => {
    if (removedPostIds.includes(postId)) {
      return;
    }

    byId[postId] = state.byId[postId];
    allIds.push(postId);
  });

  return {
    ...state,
    byId,
    allIds,
  };
};

export function isVideoPost(post) {
  return post.type === "video" || post.isVideo;
}

export function isDocumentPost(post) {
  return post.type === "document" || post.isDocument;
}

export async function normalizeDraft(draft) {
  draft.checked = false;
  var post = draft.schedule;
  post._id = draft._id;
  post.accountIds = draft.accountIds;
  post.isVideo = isVideoPost(post);
  post.isDocument = isDocumentPost(post);
  post.unreadMessageCount = "";
  post.preview_enabled =
    post.platforms &&
    post.platforms.every(function (plf) {
      return !plf.platformType || plf.platformType == "instagram";
    });

  draft.schedule = await standardizationPostData(post);

  return draft;
}

export function partitionSelectedPostIds(posts, selectedPostIds) {
  let postIds = [];
  let qPostIds = [];

  _.map(posts, (post) => {
    if (!selectedPostIds.includes(post._id)) {
      return;
    }
    post.queued ? qPostIds.push(post._id) : postIds.push(post._id);
  });

  return { postIds, qPostIds };
}

export default postsReducer;
