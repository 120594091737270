import React from "react";
import { MultiMediaProps, UnsupportedMediaWrapper } from ".";
import { MessageBlock, MessageContainer } from "../../message/styled";

export const MediaLocation = (props: MultiMediaProps): JSX.Element => {
  const { media, left, setIsReady } = props;
  React.useEffect(() => {
    setIsReady();
  }, []);

  return (
    <MessageContainer left={left}>
      <MessageBlock {...props} removeMargin>
        <UnsupportedMediaWrapper>
          Shared a
          <a
            href={media.payload?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            location
          </a>
        </UnsupportedMediaWrapper>
      </MessageBlock>
    </MessageContainer>
  );
};
