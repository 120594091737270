import { createAction } from "@reduxjs/toolkit";
import { put, select, take, takeEvery } from "redux-saga/effects";

import { getPostById } from "api/post";
import { openEditPostModal } from "features/post";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";

export const opedPostModalById =
  createAction<{ postId: string }>("opedPostModalById");

function* openPostById({ payload: { postId } }) {
  try {
    const post = yield getPostById(postId);
    yield waitFor((state) => state.user);
    yield put(openEditPostModal({ post }));
  } catch (e) {
    console.error(e);
    // most common case - post is no longer a draft or has been deleted etc
    if (e?.response?.status === 404) {
      toast.error(
        `Sorry, the post or draft with ID ${postId} could not be found. It has been moved or deleted.`
      );
    } else if (e?.response?.data?.message) {
      // in case we return a
      toast.error(
        `Sorry, the post or draft with ID ${postId} could not be loaded. ${e.response.data.message}`
      );
    } else {
      toast.error(
        `Sorry, the post or draft with ID ${postId} could not be loaded.`
      );
    }
  }
}

export function* deepLinkPostSaga() {
  yield takeEvery(opedPostModalById.type, openPostById);
}

export function* waitFor(selector) {
  const data = yield select(selector);
  if (!isEmpty(data)) {
    return;
  }
  yield take("*");
  yield waitFor(selector);
}
