import React from "react";
import styled from "styled-components";

import { PlatformIcon } from "entities/accounts";

import { postPlatformsConfig } from "./../postPlatforms.config";

const PlatformUsage = ({
  platforms,
  field,
  selectedPlatform,
  className = "",
}) => {
  if (selectedPlatform !== "LINKED") {
    return <></>;
  }

  return (
    <Platforms className={className}>
      {platforms.map((platform) => {
        const fields = postPlatformsConfig[platform.TYPE].fields;
        if (!fields.includes(field)) {
          return "";
        }

        return (
          <PlatformIcon
            key={`${field}-${platform.TYPE}`}
            className="tw-mr-2"
            type={platform.NAME}
          />
        );
      })}
    </Platforms>
  );
};

const Platforms = styled.div`
  height: 19px;
  display: flex;
  align-items: center;

  ${PlatformIcon} {
    width: 15px;
    height: 15px;
  }
`;

export default PlatformUsage;
