import React, { useRef, KeyboardEvent, RefObject, ChangeEvent } from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import { InputWrapper } from "../_input-base-wrapper";

interface Props {
  required?: boolean;
  autoFocus?: boolean;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  onChange(value: string): void;
  error?: boolean;
  defaultValue?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
  as?: any;
  autocomplete?: string;
}

export const TextInput = styled(({ onChange, ...props }) => (
  <InputWrapper
    {...props}
    as="input"
    type="text"
    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
  />
))``;

export const NativeInput = styled((props) => (
  <InputWrapper as="input" {...props} />
))``;

export function MultiLineInput(props: Props): JSX.Element {
  const textAreaRef = useRef() as RefObject<any>;
  const submitButtonRef = useRef() as RefObject<HTMLButtonElement>;
  function handleKeyPress(e: KeyboardEvent) {
    if (e.keyCode == 13) {
      e.preventDefault();
      submitButtonRef.current?.click();
    }
  }

  return (
    <>
      <InputWrapper
        ref={textAreaRef}
        onKeyDown={handleKeyPress}
        as={TextareaAutosize}
        {...props}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          props.onChange(e.target.value.replace(/\r?\n|\r/g, ""))
        }
        css="resize: none"
      />
      <button css={"display: none"} type="submit" ref={submitButtonRef} />
    </>
  );
}
