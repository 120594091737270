import Pusher, { Channel } from "pusher-js";
import { skedApi, getToken, baseUrl } from "api/_skedApi";
import {
  newCollaborationCommentReceived,
  collaborationCommentEdited,
  newUnreadNotificationReceived,
  handleStatusChanged,
} from "actions/collaboration";
import { updatePostFormField } from "actions/postForm";
import { FIELDS } from "constants/PostFormFields";
import { PolicyEnforcementStatus } from "shared/types/accounts";

let PUSHER_API_KEY: string | null = null;
let PUSHER_CLUSTER: string | null = null;
if (process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "test") {
  PUSHER_API_KEY = "908d239ded2559d5ca34";
  PUSHER_CLUSTER = "eu";
} else if (process.env.NODE_ENV === "staging") {
  PUSHER_API_KEY = "5cce5f1404af15286506";
  PUSHER_CLUSTER = "mt1";
} else {
  // production
  PUSHER_API_KEY = "4545a9bbfd763f756e38";
  PUSHER_CLUSTER = "mt1";
}

export const PUSHER_EVENTS = {
  COMMENT_ADDED: "COMMENT_ADDED",
  COMMENT_EDITED: "COMMENT_EDITED",
  COMMENTS_READ: "COMMENTS_READ",
  STATUS_CHANGED: "STATUS_CHANGED",
  INBOX_MESSAGE_ADDED: "INBOX_MESSAGE_ADDED",
  INBOX_MESSAGE_DELETE_STATUS: "INBOX_MESSAGE_DELETE_STATUS",
  INBOX_MESSAGE_REMOVED: "INBOX_MESSAGE_REMOVED",
  INBOX_COLLABORATOR_TYPING: "INBOX_COLLABORATOR_TYPING",
  INBOX_MESSAGE_REACTION: "INBOX_MESSAGE_REACTION",
  ACCOUNT_BLOCKED: "EnforcementAccountBlocked",
  ACCOUNT_UNBLOCKED: "EnforcementAccountUnblocked",
  WARNING_RECEIVED: "EnforcementWarningReceived",
};

export class CollaborationApi {
  pusher?: Pusher;

  async subscribe(
    channel: string,
    forceFetchingToken?: boolean
  ): Promise<Channel> {
    if (!this.pusher) {
      const token = await getToken(forceFetchingToken);
      this.pusher = new Pusher(PUSHER_API_KEY!, {
        authEndpoint: `${baseUrl}/collaboration-channels/auth`,
        cluster: PUSHER_CLUSTER!,
        forceTLS: true,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
    return this.pusher.subscribe(channel);
  }

  async subscribeToCollabChannel(
    currentUserId: string,
    adminId: string,
    channelId: string,
    dispatch: DispatchStub
  ) {
    const channelName = `presence-post-${adminId}-channel-${channelId}`;
    const channel = await this.subscribe(channelName);
    // on new comment
    channel.bind(PUSHER_EVENTS.COMMENT_ADDED, (comment: Comment) => {
      console.log(PUSHER_EVENTS.COMMENT_ADDED, comment);
      // currentuserid is passed to track if should mark message as unread or not
      dispatch(newCollaborationCommentReceived(comment, currentUserId));
    });
    // on edited comment
    channel.bind(PUSHER_EVENTS.COMMENT_EDITED, (comment: Comment) => {
      console.log(PUSHER_EVENTS.COMMENT_EDITED, comment);
      dispatch(collaborationCommentEdited(comment));
    });
  }
  unsubscribeChannel(channelId: string): void {
    if (this.pusher) {
      return this.pusher.unsubscribe(channelId);
    }
  }
  unsubscribeFromCollabChannel(adminId: string, channelId: string) {
    if (this.pusher) {
      return this.pusher.unsubscribe(
        `presence-post-${adminId}-channel-${channelId}`
      );
    }
  }

  async subscribeToGeneralChannel(
    adminId: string,
    userId: string,
    dispatch: DispatchStub
  ) {
    const channelName = `presence-post-${adminId}`;
    const channel = await this.subscribe(channelName);
    channel.bind(
      PUSHER_EVENTS.COMMENT_ADDED,
      ({ channelId, createdBy }: NewGeneralCommentEvent) => {
        console.log(PUSHER_EVENTS.COMMENT_ADDED, channelId, createdBy);
        if (createdBy !== userId) {
          dispatch(newUnreadNotificationReceived(channelId));
        }
      }
    );
    channel.bind(
      PUSHER_EVENTS.STATUS_CHANGED,
      ({ post, postType }: PostStatusChangeEvent) => {
        console.log(PUSHER_EVENTS.STATUS_CHANGED, post, postType);
        dispatch(handleStatusChanged(postType, post));
        dispatch(
          updatePostFormField(post.postId, {
            [FIELDS.POST_STATUS]: post.newStatus.key,
          })
        );
      }
    );
  }

  unsubscribeFromGeneralChannel(adminId: string) {
    if (this.pusher) {
      return this.pusher.unsubscribe(`presence-post-${adminId}`);
    }
  }

  async getComments(channelId: string) {
    return skedApi.get(`/collaboration-channels/${channelId}/comments`);
  }

  async addComment(channelId: string, payload: CommentPayload) {
    return skedApi.post(
      `/collaboration-channels/${channelId}/comments`,
      payload
    );
  }

  async getUnreads() {
    return skedApi.get(`/collaboration-channels/unreads`);
  }

  async getCollaborators() {
    return skedApi.get(`/collaboration-channels/users`);
  }

  async editComment(
    channelId: string,
    commentId: string,
    payload: { body: string }
  ) {
    return skedApi.put(
      `/collaboration-channels/${channelId}/comments/${commentId}`,
      payload
    );
  }

  async deleteComment(channelId: string, commentId: string) {
    return skedApi.delete(
      `/collaboration-channels/${channelId}/comments/${commentId}`
    );
  }
}

const api = new CollaborationApi();

export default api;

// helper types
type DispatchStub = (method: unknown) => void;

type CommentPayload = {
  body: string;
  metadata: {
    post: {
      postId: string;
    };
    postType: string;
  };
};

type Comment = {
  mentionedUsers: string[];
  readBy: string[];
  type: string;
  _id: string;
  adminId: string;
  createdBy: string;
  createdAt: string;
  body: string;
  metadata: {
    post: {
      postId: string;
    };
    postType: string;
  };
};

type NewGeneralCommentEvent = {
  channelId: string;
  createdBy: string;
};

type PostStatusChangeEvent = {
  postType: string;
  post: {
    postId: string;
    message: string;
    channel: string;
    currentStatus: PostStatus;
    newStatus: PostStatus;
  };
};

type PostStatus = {
  index: number;
  label: string;
  isPublishingAllowed: boolean;
  color: string;
  key: string;
  isDefault: boolean;
};

export type PolicyEnforcementPayload = {
  accountId: string;
  message: string;
  status: PolicyEnforcementStatus;
};
