import * as React from "react";
import styled, { css } from "styled-components";

import { useOpenableState } from "libs/use-openable-state";

import { FormSize, formSizeStyles } from "./form-theme-provider";
import { Collapse } from "ui/collapse";

interface FormLineProps {
  label: string;
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export const FormLine = styled((props: FormLineProps) => {
  return (
    <FormLineRoot
      data-testid={props.dataTestId || "formLine"}
      className={props.className}
    >
      <Label>{props.label}</Label>
      {props.children}
    </FormLineRoot>
  );
})``;

export const Label = styled.label`
  color: #333;
  font-weight: 500;

  ${formSizeStyles(
    FormSize.small,
    css`
      font-size: 10px;
      line-height: 12px;
    `
  )}

  ${formSizeStyles(
    FormSize.medium,
    css`
      font-size: 12px;
      line-height: 14px;
    `
  )}

  ${formSizeStyles(
    FormSize.large,
    css`
      font-size: 14px;
      line-height: 17px;
    `
  )}
`;

const FormLineRoot = styled.div`
  ${Label} {
    margin-bottom: 8px;
  }
`;

export const FieldsGroup = styled.div`
  display: grid;
  grid-gap: 20px;

  ${formSizeStyles(
    FormSize.small,
    css`
      grid-gap: 8px;
    `
  )}

  ${formSizeStyles(
    FormSize.medium,
    css`
      grid-gap: 10px;
    `
  )}

  ${formSizeStyles(
    FormSize.large,
    css`
      grid-gap: 16px;
    `
  )}
`;

function InfoLabelComponent({
  label,
  renderInfo,
  children,
  defaultOpen = false,
  className,
  labelClassName,
}: {
  label: string;
  renderInfo: () => React.ReactNode; // should return static value, if we need dynamic component should be changed
  children?: React.ReactNode;
  defaultOpen?: boolean;
  className?: string;
  labelClassName?: string;
}) {
  const info = useOpenableState(defaultOpen);

  return (
    <InfoLabelComponentRoot className={className}>
      <Label className={labelClassName}>
        <div>{label}</div>
        <InfoToggler onClick={info.toggle}>
          {info.isOpened ? "Hide info" : "Show info"}
        </InfoToggler>
      </Label>
      <Collapse isOpened={info.isOpened}>
        {React.useMemo(
          () => (
            <Info>{renderInfo()}</Info>
          ),
          []
        )}
      </Collapse>
      <Content>{children}</Content>
    </InfoLabelComponentRoot>
  );
}

export const InfoLabel = styled(InfoLabelComponent)``;

export const InfoToggler = styled.div`
  text-decoration: underline;
  color: #707070;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
`;

const Info = styled.div`
  &,
  & p {
    font-size: 12px;
    line-height: 122.5%;
    color: #707070;
  }
`;

const Content = styled.div``;

const InfoLabelComponentRoot = styled.div`
  ${Label} {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
  }

  ${Info} {
    padding-top: 12px;
  }

  ${Content} {
    margin-top: 12px;
  }
`;
