import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { LoadingSpinner } from "ui";
import ToggleGroup from "ui/ToggleGroup/ToggleGroup";
import { PostFieldLabel } from "components/Posts/PostForm/PostFieldLabel/PostFieldLabel";
import AICaption from "components/AICaption/AICaption";
import { useTrackEvent } from "libs/analytics";
import { CAPTIVATE_TAB, COMMENTS_TAB } from "constants/Events";
import { useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";
import { isUserPlan } from "features/upsell/plan-utils";

interface Props {
  isSubmitting: boolean;
  isNewPost: boolean;
  isCollaborationAvailable: boolean;
  isPostFailedOrPosted: boolean;

  renderAccountsSelector(): React.ReactNode;

  renderMediaSelector(): React.ReactNode;

  renderContentLabel?(): React.ReactNode;

  renderFields(): React.ReactNode;

  renderPreview(): React.ReactNode;

  renderMetaInfo(): React.ReactNode;

  renderCollaboration(): React.ReactNode;

  renderActions(): React.ReactNode;

  isContentLabelFeatureEnabled: boolean;

  selectedContentLabel: Label[];

  formAccounts: any;

  selectedAccountGroupId: string;
}

interface defaultTabProps {
  isNewPost: boolean;
  isCollaborationAvailable: boolean;
  isPostFailedOrPosted: boolean;
  upsellTeamwork: boolean;
}

type TabView = "postPreview" | "postComments" | "aiCaption";

const getDefaultTab = (props: defaultTabProps) => {
  const {
    isCollaborationAvailable,
    isPostFailedOrPosted,
    isNewPost,
    upsellTeamwork,
  } = props;

  if (isNewPost) {
    return "aiCaption";
  }

  const isCaptionSelected =
    sessionStorage.getItem("aiCaptionClicked") === "true";

  if (
    isCollaborationAvailable &&
    !isPostFailedOrPosted &&
    !isCaptionSelected &&
    !upsellTeamwork
  ) {
    return "postComments";
  }

  if (!isPostFailedOrPosted && isCaptionSelected) {
    return "aiCaption";
  }

  return "postPreview";
};

export interface Label {
  value: string;
  label: string;
  isCampaign: boolean;
  emoji?: string;
  color?: string;
}

export function PostFormBaseTemplate(props: Props): JSX.Element {
  const user = useSelector(getUser);
  const defaultView = getDefaultTab({
    isCollaborationAvailable: props.isCollaborationAvailable,
    isNewPost: props.isNewPost,
    isPostFailedOrPosted: props.isPostFailedOrPosted,
    upsellTeamwork: isUserPlan(user, "sked-fundamentals"),
  });

  const [tabView, setTabView] = React.useState<TabView>(defaultView);

  const trackEvent = useTrackEvent();

  const toggleProps = () => {
    return {
      defaultValue: defaultView,
      leftItem: {
        value: "postPreview",
        label: "Post Preview",
        id: "preview-tab-title",
      },
      middleItem: {
        value: "postComments",
        label: "Comments",
        id: "comments-tab-title",
      },
      rightItem: {
        value: "aiCaption",
        label: "Captivate ✨",
        id: "captivate-tab-title",
      },
      onValueChange: (view: string) => {
        setTabView(view as TabView);

        if (view === "aiCaption") {
          trackEvent({ eventName: CAPTIVATE_TAB });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).hj("event", "captivate-click-tab");
          sessionStorage.setItem("aiCaptionClicked", "true");
        } else {
          sessionStorage.removeItem("aiCaptionClicked");
        }

        if (view === "postComments") {
          trackEvent({ eventName: COMMENTS_TAB });
        }
      },
    };
  };

  return (
    <PostFormTemplateRoot>
      <BlockOutsideClick />
      {props.isSubmitting && (
        <LoadingSpinner isSmall={true} isCentered={true} />
      )}
      <Body>
        <Fields>
          <FormFields>
            <FormLine label="Accounts">
              {props.renderAccountsSelector()}
            </FormLine>

            <FormLine label="Media">{props.renderMediaSelector()}</FormLine>
            {props?.renderContentLabel && (
              <FormLine label="Labels">{props.renderContentLabel()}</FormLine>
            )}
          </FormFields>
          {props.renderFields()}
        </Fields>

        <Preview>
          <PreviewHeader>{props.renderMetaInfo()}</PreviewHeader>
          <ToggleGroupStyled {...toggleProps()} />
          <PreviewBody>
            {tabView === "postPreview" && props.renderPreview()}
            {tabView === "postComments" && props.renderCollaboration()}
            {tabView === "aiCaption" && (
              <AICaption
                isContentLabelFeatureEnabled={
                  props.isContentLabelFeatureEnabled
                }
                contentLabels={props.selectedContentLabel}
                selectedAccountIds={props.formAccounts}
                selectedAccountGroupId={props.selectedAccountGroupId}
              />
            )}
          </PreviewBody>
        </Preview>
      </Body>

      <Actions>{props.renderActions()}</Actions>
    </PostFormTemplateRoot>
  );
}

const Fields = styled.div.attrs({
  className: "tw-flex tw-flex-col tw-flex-grow tw-mr-8",
})``;

const PreviewHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const Preview = styled.div.attrs({
  className:
    "tw-min-w-300px tw-max-w-300px xl:tw-min-w-400px xl:tw-max-w-400px sm:tw-hidden md:tw-block",
})``;

const Body = styled.div`
  display: flex;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const PostFormTemplateRoot = styled.div`
  pointer-events: visible;
  width: 100%;
  flex-direction: column;
  display: flex;

  ${Body} {
    margin-bottom: 10px;
  }
`;

// block mouse events style
const BlockOutsideClick = createGlobalStyle`
  #sidebar, .posts-sidebar {
    pointer-events: none;
  }
`;

// it should be replaced by import { FormLine } from "ui/ecosystems/forms";
// when it moved to general design system
interface FormLineProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

export const FormLine = styled((props: FormLineProps) => {
  return (
    <FormLineRoot className={props.className}>
      <PostFieldLabel>{props.label}</PostFieldLabel>
      {props.children}
    </FormLineRoot>
  );
})``;

const FormLineRoot = styled.div`
  ${PostFieldLabel} {
    margin-bottom: 5px;
  }
`;

export const FormFields = styled.div`
  ${FormLine}:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ToggleGroupStyled = styled(ToggleGroup)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 8px;
`;

const PreviewBody = styled.div`
  margin: 16px 0 10px 0;
`;
