import { shuffle } from "../utils/util";

const templateTagsWithCyrillicRegex =
  /^([#@])(\w+|[\u0400-\u04FF]+)(?:(?:\s+\1(\w+|[\u0400-\u04FF]+)\s*)*|\s*)$/;

export function shuffleTemplateTags(text: string): string {
  if (templateTagsWithCyrillicRegex.test(text)) {
    const hashtags = text.split(/\s+/);
    return shuffle(hashtags).join(" ");
  }
  return text;
}
