import axios from "axios";

export default class mediaLibrary {
  constructor() {}

  getAccounts = () => axios.get("/accounts");
}

const locationsCache = {};
export async function searchLocation({ query }) {
  if (!locationsCache[query]) {
    locationsCache[query] = axios.get("/location/query", {
      params: { query },
    });
  }
  const {
    data: { data },
  } = await locationsCache[query];
  return data;
}
