import styled, { css } from "styled-components";

interface ItemProps {
  disabled?: boolean;
  selected?: boolean;
}

export const Item = styled.div<ItemProps>`
  min-height: 29px;
  word-break: keep-all;
  white-space: nowrap;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: pointer;
  :hover {
    background-color: #f1f1f1;
  }
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.3;
      cursor: not-allowed;
    `}

  ${(props) => {
    return props.selected
      ? css`
          background-color: #4f31ff;
          color: #fff;

          &:hover {
            background-color: #4f31ff;
            color: #fff;
          }
        `
      : null;
  }}
`;
