import _ from "lodash";
import {
  ConversationFilterConfig,
  ConversationInboxState,
} from "reducers/conversationInboxReducer";
import { AccountType } from "shared/types/accounts";
import {
  Conversation,
  Message,
  ConversationsFilters,
  ConversationType,
  TypingCollaborator,
  MessageType,
} from "types/conversationInbox";
import { getAccount } from "./skedCoreSelectors";

interface MainState {
  conversationInbox: ConversationInboxState;
  entities: {
    accounts: {
      byId: Record<string, AccountType>;
    };
  };
}

export type WrappedInboxState = MainState;

export const getConversations = (state: any): Conversation[] => {
  return state.conversationInbox.conversations ?? [];
};

export const getConversation = (
  state: MainState,
  conversationId: string
): Conversation | null => {
  const conversations = state.conversationInbox?.conversations ?? [];
  if (conversationId === "first") {
    return conversations[0];
  }
  return conversations.find((c) => c.id === conversationId) || null;
};

export const getConversationReplyMessageType = (
  state: MainState,
  conversationId: string
): MessageType | null => {
  const conversation = getConversation(state, conversationId);
  const account = conversation?.account
    ? (getAccount(state, conversation.account) as AccountType)
    : undefined;

  if (conversation?.type && account) {
    const accountPlatform = account.platformType || "IG";
    switch (conversation.type) {
      case "MESSAGE":
        switch (accountPlatform) {
          case "IG":
            return MessageType.igMessage;
          case "FB":
            return MessageType.fbMessage;
        }
        break;
      case "QA":
        switch (accountPlatform) {
          case "GMB":
            return MessageType.gmbAnwser;
        }
        break;
      case "REVIEW":
        switch (accountPlatform) {
          case "GMB":
            return MessageType.gmbReviewReply;
        }
        break;
      case "POST":
      case "COMMENT":
      case "MENTION":
      case "MENTION_V2":
      case "TAGGED_POST":
        switch (accountPlatform) {
          case "IG":
            return MessageType.igComment;
          case "FB":
            return MessageType.fbComment;
          case "LI":
            return MessageType.liComment;
        }
        break;
      case "THREADED_COMMENT": {
        switch (accountPlatform) {
          case "IG":
            return MessageType.igThreadedComment;
          case "FB":
            return MessageType.fbThreadedComment;
          case "LI":
            return MessageType.liThreadedComment;
          case "TT":
            return MessageType.ttThreadedComment;
        }
        break;
      }
    }
  }

  const firstNonSysmtemMessage = (conversation?.messages || []).find(
    (message) =>
      ![
        MessageType.system,
        MessageType.typing,
        MessageType.internal,
        MessageType.messageCreating,
      ].includes(message.type) && !message.isInternal
  );

  const result = firstNonSysmtemMessage ? firstNonSysmtemMessage.type : null;

  return result;
};

export const getMessages = (
  state: MainState,
  conversationId: string
): Message[] => {
  const conversation = getConversation(state, conversationId);
  return conversation?.messages || [];
};

export const isMessagesFetched = (
  state: MainState,
  conversationId: string
): boolean => {
  const conversation = getConversation(state, conversationId);

  return !conversation || !!conversation.isMessagesFetched;
};
export const isMessageReactionProcessing = (
  state: MainState,
  conversationId: string,
  messageId: string
): boolean => {
  const messages = getMessages(state, conversationId);
  return !!messages.find((c) => c.id === messageId)?.isReationProcessing;
};

export const getIsMessageLoading = (
  state: MainState,
  conversationId: string
): boolean =>
  !!state.conversationInbox.conversations?.find((c) => c.id === conversationId)
    ?.isMessagesLoading;
export const getIsMessageFetchedError = (
  state: MainState,
  conversationId: string
): boolean =>
  !!state.conversationInbox.conversations?.find((c) => c.id === conversationId)
    ?.isLastMessagesFetchedError;

export const getMessagesHasNext = (
  state: any,
  conversationId: string
): boolean => {
  const conversation: Conversation | null = getConversation(
    state,
    conversationId
  );
  return conversation?.hasNext ?? false;
};

export const getMessagesNext = (
  state: any,
  conversationId: string
): string | null => {
  const conversation: Conversation | null = getConversation(
    state,
    conversationId
  );
  return conversation?.next ?? null;
};
export const getIsConversationCountersLoading = (state: MainState): boolean =>
  state.conversationInbox.isConversationCountersLoading;
export const getIsConversationLoading = (state: MainState): boolean =>
  !!(
    state.conversationInbox.isConversationLoading ||
    state.conversationInbox.isClosingAllConversationRequest
  );
export const getIsConversationClosing = (state: MainState): boolean =>
  !!state.conversationInbox.isClosingAllConversationRequest;

export const getIsConversationJustCleared = (state: MainState): boolean =>
  state.conversationInbox.isConversationJustCleared;

export const getConversationFilterConfig = (
  state: MainState
): ConversationFilterConfig => {
  return _.pick(state.conversationInbox, [
    "isStarred",
    "isMentioned",
    "assignedTo",
    "type",
    "status",
    "unassigned",
  ]) as ConversationFilterConfig;
};

export const getConversationNext = (state: any) => state.conversationInbox.next;
export const getConversationHasNext = (state: any) =>
  state.conversationInbox.hasNext;
export const getConversationStarred = (state: MainState): boolean =>
  state.conversationInbox.isStarred;
export const getConversationMentioned = (state: any) =>
  state.conversationInbox.isMentioned;
export const getConversationSort = (state: any) => state.conversationInbox.sort;

export const getSelectedConversationAccounts = (state: MainState): string[] =>
  state.conversationInbox.selectedAccounts ?? [];
export const getConversationAssignedTo = (state: MainState): string | null =>
  state.conversationInbox.assignedTo;
export const getConversationTypes = (state: MainState): ConversationType[] =>
  (state.conversationInbox.types || []).map((c) =>
    c === "COMMENT" ? "THREADED_COMMENT" : c
  );

export const getConversationStatus = (state: any) =>
  state.conversationInbox.status;
export const getConversationUnassigned = (state: MainState): boolean | null =>
  state.conversationInbox.unassigned;
export const getConversationsCounters = (
  state: MainState
): ConversationsFilters => ({
  all: state.conversationInbox.counter.all,
  unassigned: state.conversationInbox.counter.unassigned,
  assignedToMe: state.conversationInbox.counter.assignedToMe,
  mentioned: state.conversationInbox.counter.mentioned,
  starred: state.conversationInbox.counter.starred,
  closed: state.conversationInbox.counter.closed,
  unreadMessages: state.conversationInbox.counter.unreadMessages,
  unreadConversations: state.conversationInbox.counter.unreadConversations,
  taggedInPost: state.conversationInbox.counter.taggedInPost,
});

export const getTotalUnreadCount = (state: any) =>
  state.conversationInbox.conversations.reduce(
    (totalCount: number, conversation: Conversation) => {
      return totalCount + conversation.unreadCount;
    },
    0
  );

export const getInboxTypingCollaborators = (state: {
  conversationInbox: ConversationInboxState;
}): TypingCollaborator[] => state.conversationInbox.typingCollaborators || [];

export const getSelectedConversations = (state: MainState): string[] =>
  state.conversationInbox?.selectedConversations || [];

export const getAccountsByIds = (
  state: MainState,
  ids: string[]
): AccountType[] => {
  return ids.map((id) => state.entities.accounts.byId[id]).filter(Boolean);
};

export const getEditingMessage = (state: MainState): Message | null => {
  return state.conversationInbox.editingMessage;
};

export const getConversationAccountContext = (
  state: MainState,
  conversationId: string
) => {
  const conversation = getConversation(state, conversationId);
  return conversation?.account
    ? (getAccount(state, conversation.account) as AccountType)
    : undefined;
};
