import { skedApi } from "../_skedApi";
import { CollectionFile } from "shared/types/files";

import { MediaCollection } from "./types";

export async function getCollections(): Promise<MediaCollection[]> {
  const {
    data: { data },
  } = await skedApi.get(`/content-inbox/inbox?_limit=900&_sort=name`);
  return data;
}

export async function getCollectionMedia({
  collectionId,
  page: _page = 1,
  limit: _limit,
  sort: _sort = "-created",

  name,
  tags,
  description,
  sourceName,
  favorite,
  mediaType,
  usage,
}: {
  collectionId: string;
  page?: number;
  sort?: string;
  name?: string;
  description?: string;
  sourceName?: string;
  tags?: string[];
  favorite?: boolean;
  limit: number;
  mediaType?: "image" | "video";
  usage: "used" | "unused" | null;
}): Promise<{
  list: CollectionFile[];
  pagesCount: number;
}> {
  const {
    data: { data, pages },
  } = await skedApi.get(`/content-inbox/inbox/${collectionId}/assets`, {
    params: {
      _page,
      _sort,
      _limit,
      name,
      tags,
      description,
      sourceName,
      favorite,
      mediaType,
      usage,
    },
  });

  return { list: data, pagesCount: pages };
}

export async function getCollectionTags(
  collectionId: string
): Promise<string[]> {
  const { data } = await skedApi.get<Array<{ count: number; tag: string }>>(
    `/content-inbox/inbox/${collectionId}/tags`
  );
  return data.map((item) => item.tag);
}
