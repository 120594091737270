import { toast, ToastContent } from "react-toastify";

export const handleError = (err: any, fallback: string) => {
  console.error(err);
  if (err && err.response && err.response.data) {
    if (err.response.data.errors && err.response.data.errors.length > 0) {
      err.response.data.errors.forEach((error: ToastContent) => {
        toast.error(error);
      });
    } else if (
      err.response.data.errors &&
      typeof err.response.data.errors === "object" &&
      Object.keys(err.response.data.errors).length > 0
    ) {
      Object.values(err.response.data.errors).forEach((error: any) => {
        toast.error(error.message);
      });
    } else if (err.response.data.message) {
      // return message from the body of the response if present instead of generic
      toast.error(err.response.data.message);
    } else if (err.response.data.status === 404) {
      if (fallback) {
        toast.error(fallback);
      } else {
        toast.error("The requested resource was not found.");
      }
    } else if (err.response.data.error) {
      toast.error(err.response.data.error);
    } else {
      toast.error(err || fallback);
    }
  } else {
    // in case it returns 404
    if (err && err.error) {
      toast.error(err.error);
    } else if (fallback) {
      toast.error(fallback);
    } else {
      toast.error(err);
    }
  }
};
