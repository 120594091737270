import styled from "styled-components";
import * as React from "react";

import { TooltipWrapper } from "ui";

import { CopyToClipboardButton } from "ui/copy-to-clipboard-button";
import { SelectableFilesTable } from "entities/media-files";
import { CollectionFile } from "shared/types/files";
import { ClampTextWithTooltip } from "ui/clamped-text";

interface Props {
  files: CollectionFile[];
  selectedMediaSet: Map<string, CollectionFile>;
  onSelect(file: CollectionFile): void;
  onRemove(file: CollectionFile): void;
}

export function CollectionFilesTable(props: Props) {
  return (
    <SelectableFilesTable
      files={props.files}
      onSelect={(file) => props.onSelect(file)}
      onRemove={(file) => props.onRemove(file)}
      isFileSelected={(file) => props.selectedMediaSet.has(file._id)}
      renderHeader={(HeadCell) => (
        <>
          <HeadCell>Filename</HeadCell>
          <HeadCell>Description</HeadCell>
          <HeadCell>Size</HeadCell>
          <HeadCell>Tags</HeadCell>
        </>
      )}
      renderFile={(file, TableCell) => (
        <>
          <TableCell>
            <FileName>{file.name}</FileName>
          </TableCell>
          <TableCell>
            {file.description && (
              <DescriptionContent>
                <Description>{file.description}</Description>
                <div onClick={(e) => e.stopPropagation()}>
                  <CopyToClipboardButton text={file.description} />
                </div>
              </DescriptionContent>
            )}
          </TableCell>
          <TableCell>
            {file.meta.width &&
              file.meta.height &&
              `${file.meta.width}x${file.meta.height}`}
          </TableCell>
          <TableCell>
            <TooltipWrapper tooltip={file.tags.join(", ")}>
              <>{file.tags.length}</>
            </TooltipWrapper>
          </TableCell>
        </>
      )}
    />
  );
}

const FileName = styled(ClampTextWithTooltip)`
  max-width: 100px;
`;

const Description = styled(ClampTextWithTooltip)`
  max-width: 246px;
`;

const DescriptionContent = styled.div`
  display: flex;
  align-items: center;
  ${Description} {
    margin-right: 10px;
  }
`;
