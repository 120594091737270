import React, { useState } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FIELDS, GMB_TOPICS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";
import { Alert, AlertType } from "ui/alert";

const PostTopicType = ({
  topicType,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  className = "",
  errors,
}) => {
  const [selected, setSelected] = useState(topicType);

  let config = [
    {
      id: 1,
      value: GMB_TOPICS.STANDARD.VALUE,
      label: GMB_TOPICS.STANDARD.LABEL,
    },
    {
      value: GMB_TOPICS.OFFER.VALUE,
      label: GMB_TOPICS.OFFER.LABEL,
    },
    {
      value: GMB_TOPICS.EVENT.VALUE,
      label: GMB_TOPICS.EVENT.LABEL,
    },
    {
      value: GMB_TOPICS.ALERT.VALUE,
      label: GMB_TOPICS.ALERT.LABEL,
    },
  ];

  const handleTopicChange = (item) => {
    setSelected(item);
    onChange({ [FIELDS.GMB_TOPIC_TYPE]: item });
  };

  return (
    <div data-testid="gmbPostTopicTypeWrapper" className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Topic type (required)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.GMB_TOPIC_TYPE}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <p className="small">
        The topic type is the type of GMB post, and you can enter various
        additional information (such as coupon information in offers) based on
        the topic type.
      </p>
      <Select
        options={config}
        placeholder={"Select a topic…"}
        value={selected}
        defaultItem={config[0]}
        onChange={handleTopicChange}
        disabled={disabled}
      />
      {!topicType && (
        <Alert type={AlertType.error} icon={true}>
          Pick a topic type to enter other GMB values.
        </Alert>
      )}
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.GMB_TOPIC_TYPE]}
        errors={errors}
      />
    </div>
  );
};

export default PostTopicType;
