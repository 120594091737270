import { combineReducers } from "redux";

import { formReducer } from "./post-form.model";

const reducerKey = "@feature/post";

export function getPostFeatureReducer() {
  return {
    [reducerKey]: combineReducers({
      form: formReducer,
    }),
  };
}

export const postFormSelector = (state) => state[reducerKey].form;
