import React from "react";
import styled, { css } from "styled-components";

import { TextSuggestion, TextSuggestionHeight } from "ui/suggestions-popup";

import { SuggestOption } from "../../utils/autocomplete";

interface SuggestOptionViewProps {
  suggestion: SuggestOption;
  onRemove?: () => void;
  white?: boolean;
}

export function getSuggestionOptionViewHeight(suggestion: SuggestOption) {
  switch (suggestion.type) {
    case "tag":
    case "template":
      return TextSuggestionHeight;
    default:
      return SuggestOptionViewAvatarHeight;
  }
}

export default function SuggestOptionView({
  suggestion,
  onRemove,
  white,
}: SuggestOptionViewProps) {
  switch (suggestion.type) {
    case "tag":
      return (
        <TextSuggestion suggestion={suggestion.value} onRemove={onRemove} />
      );
    case "template":
      return (
        <TextSuggestion suggestion={suggestion.name} onRemove={onRemove} />
      );
    case "fb-page":
      return (
        <SuggestOptionViewAvatar
          avatar={{
            src: suggestion.picture,
            alt: `${suggestion.name} avatar`,
          }}
          text={suggestion.name}
          textSecondary={suggestion.id}
        />
      );
    case "li-organization":
      return (
        <SuggestOptionViewAvatar
          avatar={
            suggestion.picture
              ? {
                  src: suggestion.picture,
                  alt: `${suggestion.name} avatar`,
                }
              : undefined
          }
          text={suggestion.name}
          textSecondary={
            suggestion.urn
              ? suggestion.urn.includes("person")
                ? "Personal"
                : "Company"
              : ""
          }
          white={white}
        />
      );
    case "collaborator":
      return (
        <SuggestOptionViewAvatar
          avatar={
            suggestion.picture
              ? {
                  src: suggestion.picture,
                  alt: `${suggestion.name} avatar`,
                }
              : undefined
          }
          text={suggestion.name}
        />
      );
    default:
      return null;
  }
}

function SuggestOptionViewAvatar({
  avatar,
  text,
  textSecondary,
  white,
}: {
  avatar?: { src: string; alt: string };
  text: string;
  textSecondary?: string;
  white?: boolean;
}) {
  return (
    <Wrapper white={white}>
      {avatar ? (
        <Picture src={avatar.src} alt={avatar.alt} />
      ) : (
        <PicturePlaceholder />
      )}
      <div css="width: calc(100% - 32px - 4px)">
        <Text>{text}</Text>
        {textSecondary && (
          <SubtleText css="marginTop: 2px">{textSecondary}</SubtleText>
        )}
      </div>
    </Wrapper>
  );
}

const SuggestOptionViewAvatarHeight = 48;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${SuggestOptionViewAvatarHeight}px;
  padding: 0 8px;

  border-radius: 5px;
  border: 1px solid
    ${({ white }) =>
      white
        ? "var(--suggestion-border-purple-color)"
        : "var(--suggestion-border-color))"};
  box-sizing: border-box;

  color: ${({ white }) =>
    white
      ? "var(--suggestion-text-black-color)"
      : "var(--suggestion-text-color)"};
  background-color: ${({ white }) =>
    white
      ? "var(--suggestion-background-purple-color)"
      : "var(--suggestion-background-color)"};
`;

const Picture = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 4px;
  border-radius: 50%;
`;

const PicturePlaceholder = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 4px;
  border-radius: 50%;
  background: gray;
`;

const OverflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;
  color: inherit;
  ${OverflowEllipsis}
`;

const SubtleText = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 1.2;
  opacity: 0.8;
  color: inherit;
  ${OverflowEllipsis}
`;
