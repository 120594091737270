import React from "react";
import styled from "styled-components";
import ReactCSSTransitionReplace from "react-css-transition-replace";

interface CrossFadeReplaceProps {
  children: React.ReactNode;
  timeout?: number;
}

export function CrossFadeReplace({
  children,
  timeout = 300,
}: CrossFadeReplaceProps) {
  return (
    <Root timeout={timeout}>
      <ReactCSSTransitionReplace
        transitionName="cross-fade"
        transitionEnterTimeout={timeout}
        transitionLeaveTimeout={timeout}
      >
        {children}
      </ReactCSSTransitionReplace>
    </Root>
  );
}

const Root = styled.div<{ timeout: number }>`
  .cross-fade-leave {
    opacity: 1;
  }
  .cross-fade-leave.cross-fade-leave-active {
    opacity: 0;
    transition: opacity ${(props) => props.timeout}ms ease-in;
  }

  .cross-fade-enter {
    opacity: 0;
  }
  .cross-fade-enter.cross-fade-enter-active {
    opacity: 1;
    transition: opacity ${(props) => props.timeout}ms ease-in;
  }

  .cross-fade-height {
    transition: height ${(props) => props.timeout / 2}ms ease-in-out;
  }
`;
