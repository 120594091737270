import _ from "lodash";

export const normalizeEntity = (items, key = "_id") => {
  let byId = {};
  let allIds = [];

  if (_.isEmpty(items)) {
    return {
      byId,
      allIds,
    };
  }

  _.map(items, (item) => {
    if (!item[key]) {
      throw new Error(`Unable to normalize entity without ${key}`);
    }
    byId[item[key]] = item;
    allIds.push(item[key]);
  });

  return {
    byId,
    allIds,
  };
};

export const immutableUpdate = (list, index, obj) => {
  if (obj) {
    return [
      ...list.slice(0, index),
      {
        ...list[index],
        ...obj,
      },
      ...list.slice(index + 1),
    ];
  }
  return [...list.slice(0, index), ...list.slice(index + 1)];
};

export const immutableAdd = (list, index, obj) => {
  if (list[index] === undefined) {
    const newArr = [...list.slice(0)];

    newArr[index] = obj;
    return newArr;
  }

  return [
    ...list.slice(0, index),
    {
      ...obj,
    },
    ...list.slice(index),
  ];
};
