import { NimbleEmojiIndex } from "emoji-mart";
import buildEmojiRegex from "emoji-regex";
import { getData, getSanitizedData } from "emoji-mart/dist-es/utils";
import appleData from "emoji-mart/data/all.json";

const EMOJI_SET = "all";
// const EMOJI_SET = 1;
const SHEET_COLUMNS = 57,
  SHEET_ROWS = 57;

const nativeEmojiMap = {};

function initMap() {
  const emojiMap = new NimbleEmojiIndex(appleData).emojis;
  for (const key in emojiMap) {
    const emoji = emojiMap[key];
    if (emoji.hasOwnProperty("1")) {
      nativeEmojiMap[emoji["1"].native] = emoji["1"];
    } else {
      nativeEmojiMap[emoji.native] = emoji;
    }
  }
}

function getPosition(emoji) {
  const data = getData(emoji, emoji.skin, EMOJI_SET, appleData);
  const sheet_x = data.sheet_x != undefined ? data.sheet_x : data.k[0];
  const sheet_y = data.sheet_y != undefined ? data.sheet_y : data.k[1];
  return `-${sheet_x * 100}% -${sheet_y * 100}%`;
}

export function createEmoji(emoji) {
  const element = document.createElement("img");
  const attrs = {
    class: "emoji",
    src: "/assets/img/blank.gif",
    // 'data-emoji': emoji.id,
    "data-emoji-native": emoji.native,
    "data-emoji-name": emoji.name,
    alt: emoji.native,
  };
  for (const key in attrs) {
    element.setAttribute(key, attrs[key]);
  }
  element.style.backgroundImage =
    "url(https://unpkg.com/emoji-datasource-apple@5.0.1/img/apple/sheets-256/32.png)";
  element.style.backgroundSize = `${100 * SHEET_COLUMNS}% ${100 * SHEET_ROWS}%`;
  element.style.backgroundPosition = getPosition(emoji);
  return element;
}

function createEmojiHtml(emoji) {
  return createEmoji(emoji).outerHTML;
}

// const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])[\u1f3fb-\u1f3ff]/g;
const emojiRegex = buildEmojiRegex();
const toneModifierRegex = /\ud83c[\udffb-\udfff]/;

export function restoreEmoji(text) {
  if (!text) return "";
  return text.replace(emojiRegex, (nativeEmoji) => {
    let skin = 1;
    if (nativeEmoji.length > 2) {
      nativeEmoji = nativeEmoji.replace(toneModifierRegex, (modifier) => {
        skin = modifier.charCodeAt(1) - 0xdff9;
        return "";
      });
    }
    let mappedEmoji = nativeEmojiMap[nativeEmoji];
    if (!mappedEmoji) return nativeEmoji;
    const emoji = getSanitizedData(mappedEmoji, skin, EMOJI_SET, appleData);
    return createEmojiHtml(emoji);
  });
}

const colonsRegex = /:([^\s:]+):/g;

export function colonsToEmoji(text) {
  return text.replace(colonsRegex, (colonEmoji) =>
    createEmojiHtml(getSanitizedData(colonEmoji))
  );
}

initMap();
