import { UserFriendlyError } from "./UserFriendlyError";

type JsonRespOk<T> = { status: "ok"; data: T };
type JsonRespError = { status: "error"; message: string };
type JsonRespRedirect = { status: "redirect"; url: string };

export type JsonResp<T> = JsonRespOk<T> | JsonRespError;
export type JsonRespOrRedirect<T> =
  | JsonRespOk<T>
  | JsonRespRedirect
  | JsonRespError;

export function unwrapJsonResp<T>(res: JsonResp<T>): T {
  switch (res.status) {
    case "ok":
      return res.data;
    case "error":
      throw new UserFriendlyError(res.message || "Unexpected network error");
  }
}

export function unwrapJsonRespOrRedirect<T>(
  res: JsonRespOrRedirect<T>
): JsonRespOk<T> | JsonRespRedirect {
  switch (res.status) {
    case "ok":
      return res;
    case "error":
      throw new UserFriendlyError(res.message || "Unexpected network error");
    case "redirect":
      return res;
  }
}
