import { PAGES } from "constants/Pages";
import { NEW_POST_ID, POST_STATES, POST_TYPES } from "constants/PostsView";
import { ONLY_MANUAL_PLATFORMS } from "constants/Platforms";
import "@dialogueconsulting/sked-preview/dist/style.css";
import { SkedCorePostModel } from "@dialogueconsulting/sked-preview";
import _ from "lodash";
import moment from "moment";
import { getAccountPlatformType } from "entities/accounts";
import companySizeData from "constants/linkedinAudiences/companySize.json";
import jobFunctionsData from "constants/linkedinAudiences/jobFunctions.json";
import senioritiesData from "constants/linkedinAudiences/seniorities.json";
import senioritiesDataDeprecated from "constants/linkedinAudiences/senioritiesDeprecated.json";
import industriesData from "constants/linkedinAudiences/industries.json";
import {
  fetchLocationLabelById,
  fetchOrganizationLabelById,
} from "api/linkedinTargetAudience";
import { isDocumentPost, isVideoPost } from "reducers/entities/postsReducer";
import { getVideoPreviewImage } from "components/Posts/PostForm/components/VideoCoverSelector";

export const getPageVerbs = (page, post, user) => {
  let pageVerbs = {
    previewable: false,
    repostable: false,
    editable: false,
    urlEditable: false,
    queueable: false,
    draftable: false,
    showLegacyEdit: false,
    removeable: false,
    removeableFromQueue: false,
    notifiable: false,
    duplicatable: false,
  };

  pageVerbs.showLegacyEdit = user.isCollaborationEnabledForUser();

  switch (page) {
    case PAGES.UPCOMING:
      pageVerbs.editable = true;
      pageVerbs.queueable = true;
      pageVerbs.draftable = true;
      pageVerbs.removeable = true;
      pageVerbs.previewable = true;
      break;
    case PAGES.DRAFT:
      pageVerbs.queueable = true;
      pageVerbs.editable = true;
      pageVerbs.removeable = true;
      pageVerbs.previewable = true;
      break;
    case PAGES.ARCHIVE: {
      const isManual = isManualPost(post);
      const canPostAgain = isManual || !(post.isPosting || post.isQueued);
      const duplicatable =
        post.isSuccess || (!post.userDisplayReason && isManual);
      pageVerbs.repostable = !duplicatable && canPostAgain;
      pageVerbs.urlEditable = true;
      pageVerbs.draftable = canPostAgain;
      pageVerbs.removeableFromQueue = post.isQueued;
      pageVerbs.removeable = post.isCanceled || post.isSuccess;
      pageVerbs.notifiable = true;
      pageVerbs.duplicatable = duplicatable;
      break;
    }
  }

  return pageVerbs;
};

export const isManualPost = (post) =>
  post.publishStoryManually ||
  post?.postOptions?.isNotification ||
  ONLY_MANUAL_PLATFORMS.includes(post.platformType);

export const getPageEntity = (page) => {
  let pageEntity = null;
  switch (page) {
    case PAGES.UPCOMING:
      pageEntity = "post";
      break;
    case PAGES.DRAFT:
      pageEntity = "draft";
      break;
    case PAGES.ARCHIVE:
      pageEntity = "post";
      break;
  }
  return pageEntity;
};

export const getPlatformTypes = (post, accounts) => {
  const platforms = [];
  _.map(accounts, (account) => {
    const type = account.platformType || "IG";
    platforms.push(type);
  });
  return _.uniq(platforms);
};

export const getFormattedPostDate = (post, page, user) => {
  let time = null;
  switch (page) {
    case PAGES.UPCOMING:
      time = post.when;
      break;
    case PAGES.DRAFT:
      // Leave time null
      break;
    case PAGES.ARCHIVE:
      time = post.lastRunAt;
      break;
    default:
      throw new Error(`Invalid view type for post time ${page}`);
  }

  let dateFormat = "ddd D MMM, H:mm";
  if (user.preferences && user.preferences.timePreference === "12h") {
    dateFormat = "ddd D MMM, h:mm A";
  }

  if (time === null) {
    return "";
  }

  return moment(time).format(dateFormat);
};

/**
 *
 * @param {string} selectedPlatformType
 * @param {Object[]} accounts
 * @returns {Object}
 */
export function getTargetAccountForPostPreviewModel(
  accounts,
  selectedPlatformType
) {
  let account;
  if (selectedPlatformType === "LINKED") {
    // NOTE: when linked, return first IG if any, otherwise return first in the list
    account = accounts.find((acc) => getAccountPlatformType(acc) === "IG");
    if (!account) {
      account = accounts[0];
    }
  } else {
    // NOTE: while not linked, return the first account with the platform type
    account = accounts.find(
      (account) => getAccountPlatformType(account) === selectedPlatformType
    );
    if (!account) {
      account = accounts[0];
    }
  }
  return account;
}

export const getPostPreviewModel = (post, accounts, selectedPlatform) => {
  const _post = _.cloneDeep(post);
  const account = getTargetAccountForPostPreviewModel(
    accounts,
    selectedPlatform || _post.platformType || "IG"
  );
  if (_post.schedule) {
    _post.schedule.platformType = getAccountPlatformType(account);
  } else {
    _post.platformType = getAccountPlatformType(account);
  }
  // Delete post.platforms - this will mean that sked-preview will always use the platformType defined above.
  if (_post.platforms) {
    delete _post.platforms;
  }
  return {
    ...new SkedCorePostModel(_post, account),
    collaboratingIgUsers:
      _post?.collaboratingIgUsers?.length > 0
        ? [
            {
              username: account.login,
              profilePictureUrl: account.profilePictureUrl,
            },
            ..._post.collaboratingIgUsers,
          ]
        : null,
  };
};

export const getPostState = (post) => {
  switch (post.postType) {
    case "draft":
      return POST_STATES.DRAFT;
    case "queue":
      return POST_STATES.QUEUED;
    case "schedule":
      return POST_STATES.SCHEDULED;
    case "archive":
      return POST_STATES.ARCHIVED;
  }

  if (post.schedule) {
    return POST_STATES.DRAFT;
  }

  if (post.queued) {
    return POST_STATES.QUEUED;
  }

  if (post.nextRunAt) {
    return POST_STATES.SCHEDULED;
  }

  if (post.lastRunAt) {
    return POST_STATES.ARCHIVED;
  }

  // Maybe a normalized post was passed in.
  return POST_STATES.DRAFT;
};

export const getPostType = (post) => {
  // MediaFiles could be (null, [], [{},...])
  let mediaFiles = _.cloneDeep(post.mediaFiles) || [];

  // Annoyingly, if there is one asset it won't be in mediaFiles
  if (_.isEmpty(mediaFiles) && post.url) {
    mediaFiles.push({
      originalUrl: post.url,
      url: post.url,
      isVideo: post.isVideo,
      isDocument: post.isDocument,
    });
  }

  if (mediaFiles.length === 0) {
    return POST_TYPES.TEXT;
  }

  if (post.isStory) {
    return POST_TYPES.STORY;
  }

  if (mediaFiles.length === 1 && mediaFiles[0].isVideo === true) {
    return POST_TYPES.VIDEO;
  }
  if (
    (mediaFiles.length === 1 && mediaFiles[0].isDocument === true) ||
    (mediaFiles.length === 1 && mediaFiles[0].url.includes("pdf")) ||
    post.isDocument ||
    post.type === "document"
  ) {
    return POST_TYPES.DOCUMENT;
  }

  if (mediaFiles.length === 1 && mediaFiles[0].isVideo !== true) {
    return POST_TYPES.IMAGE;
  }

  return POST_TYPES.MULTI_MEDIA;
};

const defaultValue = {
  _id: NEW_POST_ID,
  type: "text",
  when: null,
  newPost: true,
  caption: "",
  firstcomment: "",
  redirectUrl: null,
  tags: [],
  productTags: [],
  mediaFiles: [],
  media: [],
  postStatusKey: null,
  unreadMessageCount: 0,
  preview_enabled: false,
  accountIds: [],
  accounts: [],
  piBoards: [],
  piTitle: null,
  piSectionId: null,
  liTargetEntities: [],
  labelIds: [],
  postOptions: {},
  videoCoverImageTimestamp: 0, // in ms
  videoCoverPreview: null,
  collaboratingIgUsers: [],
};

export const getNewPostObj = (initialData = {}) => {
  return {
    ...defaultValue,
    ...initialData,
  };
};

const AUDIENCE_DATA = {
  companySize: companySizeData,
  staffCountRanges: companySizeData,
  seniorities: senioritiesData,
  seniority: senioritiesDataDeprecated,
  jobFunctions: jobFunctionsData,
  industries: industriesData,
};

/**
 *
 * @param {Object} post
 * @returns {Promise<Object>} new post object (not modify)
 */
export const standardizationPostData = async (post) => {
  const newPost = _.cloneDeep(post);

  // NOTE: Old LI posts can has audiences on the old format
  // old variant liTargetEntities = [{"interfaceLocales": {"language": "en"}}, {"jobFunctions": ["urn:li:function:4"]}]
  // new variant liTargetEntities = {"interfaceLocales": {"language": "en"}, "jobFunctions": [{"id": "urn:li:function:4","label": "Business Development"}]}
  if (newPost?.liTargetEntities && Array.isArray(newPost?.liTargetEntities)) {
    newPost.liTargetEntities = await newPost.liTargetEntities.reduce(
      async (acc, audienceObj) => {
        const accResultObj = await acc;
        const audienceKey = Object.keys(audienceObj)[0];

        // ignore interfaceLocales (hidden property)
        if (audienceKey === "interfaceLocales") {
          return accResultObj;
        }

        accResultObj[audienceKey] = await Promise.all(
          audienceObj[audienceKey].map(async (audience) => {
            if (typeof audience === "string") {
              let label = "";
              switch (audienceKey) {
                case "geoLocations":
                  label = await fetchLocationLabelById(audience.split(":")[3]);
                  break;
                case "organizations": {
                  const organizationData = await fetchOrganizationLabelById(
                    audience
                  );
                  label =
                    organizationData?.results?.[audience]?.localizedName ||
                    `Unknown organization (please reselect)`;

                  break;
                }
                default:
                  label = (
                    AUDIENCE_DATA[audienceKey]?.find(
                      ({ id }) => id === audience
                    ) || {}
                  ).label;
                  break;
              }

              return { id: audience, label };
            }

            return audience;
          })
        );

        return accResultObj;
      },
      Promise.resolve({})
    );
  }

  return newPost;
};

export const standardizationUpcomingPosts = async (posts, existing) => {
  return posts.reduce(async (acc, newPost) => {
    const newPosts = await acc;
    const postIsAlreadyInStore = existing.some(
      (existingPost) => existingPost._id === newPost._id
    );

    newPost.isVideo = isVideoPost(newPost);
    newPost.isDocument = isDocumentPost(newPost);

    if (!postIsAlreadyInStore) {
      const standardizedPostData = await standardizationPostData(newPost);
      newPosts.push(standardizedPostData);
    }

    return newPosts;
  }, Promise.resolve([]));
};
