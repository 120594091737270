import React from "react";

import { Cover } from "./cover";
import { SmallLoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
}

export function LoadingCover({ isLoading, children }: Props) {
  return (
    <Cover in={isLoading} renderCoverContent={() => <SmallLoadingSpinner />}>
      {children}
    </Cover>
  );
}
