import { Post, PostStates } from "./types";
import {
  editDraft,
  editQueuedPost,
  editScheduledPost,
  moveDraftToSchedule,
  moveDraftToQueue,
  moveQueueToDraft,
  moveQueueToSchedule,
  moveScheduleToDraft,
  moveScheduledPostToQueue,
  createScheduledPost,
  createDraft,
  createQueuedPost,
  editDraftValidation,
} from "api/post";

export const savePost =
  (posts: Post | Post[], previousPost: Post) => async () => {
    // todo: when we put all posts to the store we should get previous from it
    // const previousPost = parsePost(previousPostData);
    if (Array.isArray(posts)) {
      const { editPost, newPosts }: { editPost?: Post; newPosts: Post[] } =
        posts.reduce(
          (acc: { editPost?: Post; newPosts: Post[] }, post) => {
            if (!post._id) {
              acc.newPosts.push(post);
            } else {
              acc.editPost = post;
            }

            return acc;
          },
          { editPost: undefined, newPosts: [] }
        );

      if (editPost) {
        await PostEditingMap[previousPost.state][editPost.state](
          editPost,
          previousPost
        );
      }

      await Promise.allSettled(
        newPosts.map((post) => {
          return PostCreatingMap[post.state](post);
        })
      );
    } else {
      await PostEditingMap[previousPost.state][posts.state](
        posts,
        previousPost
      );
    }
  };

const PostCreatingMap = {
  [PostStates.draft]: createDraft,
  [PostStates.schedule]: createScheduledPost,
  [PostStates.queue]: createQueuedPost,
};

const PostEditingMap = {
  [PostStates.draft]: {
    [PostStates.draft]: editDraft,
    [PostStates.schedule]: async (post: Post) => {
      await editDraft(post);
      await moveDraftToSchedule(post);
    },
    [PostStates.queue]: async (post: Post) => {
      await editDraft(post);
      await moveDraftToQueue(post._id);
    },
  },
  [PostStates.schedule]: {
    [PostStates.schedule]: editScheduledPost,
    [PostStates.draft]: async (post: Post) => {
      await editDraftValidation(post);
      const newId = await moveScheduleToDraft(post._id);
      await editDraft({ ...post, _id: newId }, { validate: false });
    },
    [PostStates.queue]: async (post: Post) => {
      const ids = await moveScheduledPostToQueue(post._id);
      await editQueuedPost({ ...post, _id: ids[0] });
    },
  },
  [PostStates.archived]: {
    [PostStates.schedule]: (post: Post, previousPost: Post) => {
      if (previousPost.isSuccess) {
        return PostCreatingMap[post.state](post);
      }

      return editScheduledPost(post);
    },
    [PostStates.draft]: async (post: Post, previousPost: Post) => {
      if (previousPost.isSuccess) {
        return PostCreatingMap[post.state](post);
      }

      await editDraftValidation(post);
      const newId = await moveScheduleToDraft(post._id);
      await editDraft({ ...post, _id: newId }, { validate: false });
    },
    [PostStates.queue]: async (post: Post, previousPost: Post) => {
      if (previousPost.isSuccess) {
        return PostCreatingMap[post.state](post);
      }

      const ids = await moveScheduledPostToQueue(post._id);
      await editQueuedPost({ ...post, _id: ids[0] });
    },
  },
  [PostStates.queue]: {
    [PostStates.queue]: editQueuedPost,
    [PostStates.draft]: async (post: Post) => {
      await editDraftValidation(post);
      const newId = await moveQueueToDraft(post._id!);
      await editDraft({ ...post, _id: newId }, { validate: false });
    },
    [PostStates.schedule]: moveQueueToSchedule,
  },
};
