import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { isFullLibrary } from "features/media-library";

import { getEntities } from "./selectorUtils";
import { getPageSize, getUser } from "./commonSelectors";

const COLLECTION_ENTITY = "collections";
const COLLECTION_ENTRY_ENTITY = "collectionEntries";
const COLLECTION_USER_ENTITY = "collectionUsers";
const INSTAGRAM_HASHTAGS = "instagramHashtags";

// View
export const getLibraryInitialized = (state) =>
  state.mediaLibraryView.initialized;
export const getSelectAll = (state) =>
  state.entities[COLLECTION_ENTRY_ENTITY].allSelected;
export const getMediaLibraryFilters = (state) => state.mediaLibraryView.filters;
export const getIsMediaLibraryFiltered = (state) => {
  const filters = state.mediaLibraryView.filters;
  if (filters.name) {
    return true;
  }
  if (filters.tags && filters.tags.length > 0) {
    return true;
  }
  if (filters.description) {
    return true;
  }
  if (filters.sourceName) {
    return true;
  }
  if (filters.favorite) {
    return true;
  }
  if (filters.mediaType) {
    return true;
  }
  if (filters.usage) {
    return true;
  }
};

export const getFiltersVisible = (state) =>
  state.mediaLibraryView.filtersVisible;
export const getPage = (state) => state.mediaLibraryView.page;
export const getLayout = (state) => state.mediaLibraryView.layout;

// Assets
export const getCollectionEntries = (state) =>
  getEntities(state, COLLECTION_ENTRY_ENTITY);
export const getCollectionAssets = (state) => getCollectionEntries(state);
export const getSelectedAssetIds = (state) =>
  state.entities[COLLECTION_ENTRY_ENTITY].selectedIds;

export const getSelectedAssets = createSelector(
  (state) => state.entities[COLLECTION_ENTRY_ENTITY].byId,
  getSelectedAssetIds,
  (byId, ids) => ids.map((id) => byId[id]).filter(Boolean)
);

export const getTotalAssets = (state) =>
  state.entities[COLLECTION_ENTRY_ENTITY].total;

// Users
export const getCollectionUsers = (state) =>
  getEntities(state, COLLECTION_USER_ENTITY);

// Collections
export const getIsLoadingCollections = (state) =>
  state.entities[COLLECTION_ENTITY].loading;
export const getCollections = (state) => {
  const user = getUser(state);
  const collections = _.sortBy(getEntities(state, COLLECTION_ENTITY), ["name"]);
  if (isFullLibrary(user)) {
    return collections;
  } else {
    return collections.filter((collection) => collection.isSystemInbox);
  }
};

export const getSelectedCollections = (state) =>
  state.entities.collections.selectedIds;
export const getSelectedCollection = (state) => {
  const selectedIds = getSelectedCollections(state);
  const selectedCollections = getEntities(
    state,
    COLLECTION_ENTITY,
    selectedIds
  );

  // For now collections would only ever have one collection selected
  return selectedCollections.length > 0 ? selectedCollections[0] : null;
};
export const getCollectionsError = (state) =>
  state.entities[COLLECTION_ENTITY].error;

export const getLibraryUsage = (state) => state.mediaLibraryView.libraryUsage;

// Tags
export const getTags = (state) => state.entities.collections.tags;

export const getMediaLibraryPageSize = (state) => {
  const user = getUser(state);
  if (isFullLibrary(user)) {
    return getPageSize(state) ?? 25;
  } else {
    return 20;
  }
};
