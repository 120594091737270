import React from "react";
import styled, { css } from "styled-components";

import ArrowIcon from "./arrow-icon.svg";
import { makePages } from "./make-pages";

function Pagination(props: {
  currentPage: number;
  onChange(page: number): void;
  pagesCount: number;
  className?: string;
}) {
  if (props.pagesCount < 2) return null;

  const pages = makePages(props.currentPage, props.pagesCount);

  return (
    <SmallPaginationRoot className={props.className}>
      <ArrowButton
        prev
        onClick={() => props.onChange(props.currentPage - 1)}
        style={{ visibility: props.currentPage === 1 ? "hidden" : "visible" }}
      />
      <Pages>
        {pages.map((page, index) =>
          !page.placeholder ? (
            <Page
              key={page}
              isCurrent={page === props.currentPage}
              onClick={() => props.onChange(page)}
            >
              {page}
            </Page>
          ) : (
            <Page key={`placeholder-${index}`}>...</Page>
          )
        )}
      </Pages>
      <ArrowButton
        onClick={() => props.onChange(props.currentPage + 1)}
        style={{
          visibility:
            props.currentPage === props.pagesCount ? "hidden" : "visible",
        }}
      />
    </SmallPaginationRoot>
  );
}

export const SmallPagination = styled(Pagination)``;

const ArrowButton = styled(ArrowIcon)`
  ${(props) =>
    props.prev &&
    css`
      transform: rotate(180deg);
    `}
  ${(props) =>
    props.hide &&
    css`
      visibility: hidden;
    `}
`;

const Page = styled.span<{ isCurrent: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 17px;
  height: 17px;

  font-size: 12px;
  line-height: 14px;

  ${(props) =>
    props.isCurrent &&
    css`
      background: #4f31ff;
      color: #fff;
      border-radius: 3px;
      margin: 0 4px;
    `}
`;

const Pages = styled.div`
  display: flex;
`;

const SmallPaginationRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > :first-child {
    margin-right: 64px;
  }

  & > :last-child {
    margin-left: 64px;
  }
`;
