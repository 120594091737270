import React from "react";
import styled from "styled-components";

import { InputWrapper } from "../_input-base-wrapper";

export const TextArea = styled((props) => (
  <InputWrapper as="textarea" {...props} />
))`
  resize: vertical;
  height: 80px;
`;
