import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  PostFieldLabel,
  PostFieldCounter,
  PlatformUsage,
} from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import { useDebouncedCallback } from "use-debounce";
import { TextInput } from "ui/ecosystems/forms/inputs";

const PinterestTitle = ({
  url,
  post,
  field,
  checkSetting = false,
  selectedPlatform,
  selectedAccounts,
  platforms,
  title,
  className = "",
  onChange,
  disabled = false,
}) => {
  const [value, setValue] = useState(post.piTitle ?? "");

  const bounce = useDebouncedCallback((value) => {
    let title = post["piTitle"];
    if (value != title) {
      onChange(value);
    }
  }, 200);

  return (
    <div className={className}>
      <div className="tw-flex tw-flex-col tw-mb-4">
        <div className="tw-flex tw-items-center">
          <PostFieldLabel className="tw-mb-2 tw-mr-2">{title}</PostFieldLabel>
          <PlatformUsage
            platforms={platforms}
            field={FIELDS.PI_TITLE}
            selectedPlatform={selectedPlatform}
          />
        </div>
        <TextInput
          value={value}
          disabled={disabled}
          placeholder="The title of your pin"
          onChange={(value) => {
            setValue(value);
            bounce(value);
          }}
        />
        <PostFieldCounter
          count={value.length}
          max={100}
          className="tw-flex-grow tw-text-right tw-mt-2 tw-mr-8"
        >
          Characters
        </PostFieldCounter>
      </div>
    </div>
  );
};

export default PinterestTitle;
