import React from "react";
import styled from "styled-components";
import { SkedCheckbox } from "ui";
import { Status } from "./edit-user";

type AllowPostStatusProps = {
  allowStatus: Status;
  handlePostStatusChange: (allowStatus: Status) => void;
};

const AllowPostStatus = ({
  allowStatus,
  handlePostStatusChange,
}: AllowPostStatusProps): React.ReactElement => {
  return (
    <div className="checkbox-input tw-pb-4" key={allowStatus.label}>
      <Checkbox
        // New collaborator should set all available [sc-32447]
        checked={allowStatus.selected}
        onClick={() => {
          handlePostStatusChange(allowStatus);
        }}
        disabled={allowStatus.isDefault}
      >
        {allowStatus.label}{" "}
        {allowStatus.isPublishingAllowed && (
          <WillPublish data-testid="willPublish">
            (Will publish when scheduled)
          </WillPublish>
        )}{" "}
        {allowStatus.isDefault && (
          <DefaultStatus data-testid="defaultStatus">
            (Default status)
          </DefaultStatus>
        )}
      </Checkbox>
    </div>
  );
};

const AllowPostStatusList = ({
  postStatusList,
  setPostStatusList,
}: {
  postStatusList: Status[];
  setPostStatusList: (status: Status[]) => void;
}): React.ReactElement => {
  const handlePostStatusChange = (status: Status) => {
    setPostStatusList(
      postStatusList.map((item) => {
        return {
          ...item,
          selected: item === status ? !status.selected : item.selected,
        };
      })
    );
  };

  return (
    <>
      {postStatusList.map((allowStatus) => (
        <AllowPostStatus
          key={allowStatus.label}
          allowStatus={allowStatus}
          handlePostStatusChange={handlePostStatusChange}
        />
      ))}
    </>
  );
};

export default AllowPostStatusList;

const Checkbox = styled(SkedCheckbox)`
  display: block;
`;

const DefaultStatus = styled.span`
  margin-left: 5px;
`;

const WillPublish = styled(DefaultStatus)`
  color: #66b913;
`;
