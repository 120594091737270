import React from "react";
import {
  Caption,
  DetailsTemplate,
  PostDetail,
  PostLink,
  Tag,
  Tags,
  TagTooltip,
  AltTextTags,
} from "./post-details-template";
import { ExternalLink } from "ui/Link";
import { PostAccount, PostNotificationMeta } from "components/Posts/Post/index";
import { PAGES } from "constants/Pages";
import { getPlatformLabel } from "utils/accounts";
import { Alert, AlertType } from "ui/alert";

export function OtherDetails({
  post,
  account,
  page,
  accountHasPostingAvailable = false,
}) {
  const platformLabel = getPlatformLabel(account.platformType);

  let firstSentence = `This post ${
    page === PAGES.ARCHIVE ? "was" : "is"
  } set to post manually, as we only support manual posting for ${platformLabel}`;
  if (accountHasPostingAvailable) {
    firstSentence = `This post ${
      page === PAGES.ARCHIVE ? "was" : "is"
    } set to post manually`;
  }
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          {page !== PAGES.ARCHIVE && (
            <Alert
              type={AlertType.info}
              icon={true}
              title={"Set to manually publish"}
              isExpandable
              defaultExpanded
            >
              {firstSentence}.{" "}
              <ExternalLink href="https://help.skedsocial.com/en/articles/3969654-how-does-manual-story-publishing-work">
                See how manual publishing works.
              </ExternalLink>
            </Alert>
          )}

          <Caption post={post} label={"Publishing notes or caption"} />

          {Array.isArray(post.pushNotificationMeta) && (
            <PostNotificationMeta post={post} />
          )}
          <Tags>
            <AltTextTags post={post} />
          </Tags>
        </>
      )}
    />
  );
}
