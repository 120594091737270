import React, { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

import { signupReminder } from "api/signup";
import QuokkaImage from "./assets/quokka.svg";
import XImage from "./assets/x.svg";

declare global {
  interface Window {
    Intercom: any;
    _hsq: any;
    Appcues: any;
    //TODO: add correct user object description
    __user: any;
    FrontifyFinder: any;
  }
}

export const ModalOnExit = () => {
  const [show, setShow] = useState(false);
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  //TODO: replace global user object
  const email = window.__user ? window.__user.email : "";
  const [typedEmail, setTypedEmail] = useState("");
  const handleCancel = async () => {
    await setShow(true);
    setShow(false);
  };

  const handleRemindMe = async () => {
    const data = await signupReminder(typedEmail || email);
    if (data.data) {
      setSucces(data.data);
      setTimeout(() => handleCancel(), 2000);
    } else {
      setError(data.msg);
    }
  };
  const handleEmail = (event: any) => {
    setTypedEmail(event.target.value);
  };

  const handleIntercome = () => {
    window.Intercom("showNewMessage");
  };

  return (
    <div id="modalOnExit" style={{ display: show ? "block" : "none" }}>
      <ModalWrapper>
        <ModalContainer>
          <Quokka />
          <Header>
            <Title>Need a minute?</Title>
          </Header>
          {success && <Body>{success}</Body>}
          {!success && (
            <Body>
              If you’re not ready to say yes to Sked, we can send you an email
              reminder to
              {email ? (
                <mark>{email}</mark>
              ) : (
                <Email
                  type="email"
                  onChange={handleEmail}
                  placeholder={"myemail@schedugram.com"}
                />
              )}
              in <mark>3 days</mark>.
            </Body>
          )}
          {error && (
            <Body>
              <Error>{error}</Error>
            </Body>
          )}
          <Footer>
            <Close onClick={handleCancel}>Close</Close>
            <RemindMe onClick={handleRemindMe}>Remind me!</RemindMe>
          </Footer>
          <BottomActions>
            <Webinar
              href="https://skedsocial.com/webinar"
              target="_blank"
              rel="noopener noreferrer"
            >
              ☎️ Join us for a free webinar
            </Webinar>
            <BottomButton onClick={handleIntercome}>
              💬 Ask a question to our 24hr support
            </BottomButton>
            <ButtonClose onClick={handleCancel}>
              <XImage />
            </ButtonClose>
          </BottomActions>
        </ModalContainer>
      </ModalWrapper>
    </div>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;
const Email = styled.input`
  width: 100%;
  border: 1px solid !important;

  &::placeholder {
    color: #bdbdbd !important;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  width: 364px;
  border-radius: 10px;
  background: white;
`;

const Header = styled.header`
  border: none;
  text-align: center;
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 15px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const Error = styled.div`
  color: red;
`;
const Body = styled.div`
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #333333;
  padding: 0 30px;
  padding-bottom: 10px;

  a,
  mark {
    color: #4f31ff !important;
    background: transparent;
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #c4c4c4;
`;

const DefButton = styled(Button)`
  font-size: 16px;
  outline: none;
  appearance: none;
  border: none;

  &:active,
  &:hover,
  &:focus {
    outline: none !important;
    appearance: none;
    background: transparent !important;
    box-shadow: none;
  }
`;

const Close = styled(DefButton)`
  min-width: auto;
  height: 59px;
  width: 182px;
  border-radius: 10px;
  color: #fa4f38;
  font-weight: 500;
  line-height: 19px;
  background: transparent;
`;

const RemindMe = styled(Close)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid #c4c4c4;
  color: #319cff;
`;

const BottomActions = styled.div`
  position: absolute;
  display: flex;
  top: 100%;
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
`;

const BottomButton = styled(DefButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 363px;
  height: 48px;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  background: #333333;
  border-radius: 20px;
  margin-top: 8px;
  font-weight: normal;
  border: none;
  padding: 0;

  &:active,
  &:hover,
  &:focus {
    color: white !important;
    background: #333333 !important;
  }
`;

const Webinar = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 363px;
  height: 48px;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  background: #333333;
  border-radius: 20px;
  margin-top: 8px;

  &:active,
  &:hover,
  &:focus {
    color: white !important;
    text-decoration: none;
  }
`;

const ButtonClose = styled.div`
  width: 62px;
  height: 62px;
  background: #333333;
  border-radius: 50%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active,
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const Quokka = styled(QuokkaImage)`
  position: absolute;
  bottom: 100%;
  margin-bottom: -40px;
  z-index: -1;
  left: 50%;
  margin-left: -32%;
`;
