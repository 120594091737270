import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { initHashtagFeed } from "actions/sourceMedia";
import {
  getActiveHashtags,
  getExpiredHashtags,
  getInvalidIgAccount,
  getSelectedHashtagId,
  getSelectedIgAccount,
} from "selectors/hashtagFeedSelectors";
import { getRoutinesLoading } from "selectors/commonSelectors";
import { getAccountsForPlatform } from "selectors/skedCoreSelectors";
import {
  SKED_CORE_GET_ACCOUNTS_ROUTINE,
  SELECT_IG_ACCOUNT_ROUTINE,
  HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
} from "constants/ActionTypes";
import { useTrackEvent } from "libs/analytics";
import { LIBRARY_SEARCH_HASHTAG } from "constants/Events";

import ActiveHashtags from "./ActiveHashtags";
import ExpiredHashtags from "./ExpiredHashtags";
import SelectFeedAccount from "./../SelectFeedAccount";

export function useHashtags() {
  const activeHashtags = useSelector(getActiveHashtags);
  const expiredHashtags = useSelector(getExpiredHashtags);
  const invalidIgAccount = useSelector(getInvalidIgAccount);
  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const selectedHashtagId = useSelector(getSelectedHashtagId);
  const selectedHashtag = activeHashtags.find(
    (hashtag) => hashtag.hashtag_id === selectedHashtagId
  );
  const loadingAccounts = useSelector((state) =>
    getRoutinesLoading(state, [SKED_CORE_GET_ACCOUNTS_ROUTINE])
  );
  const loadingHashtags = useSelector((state) =>
    getRoutinesLoading(state, [
      SELECT_IG_ACCOUNT_ROUTINE,
      HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
    ])
  );

  return {
    activeHashtags,
    expiredHashtags,
    invalidIgAccount,
    selectedIgAccount,
    loadingAccounts,
    loadingHashtags,
    selectedHashtag,
  };
}

const HashtagSidebar = () => {
  const dispatch = useDispatch();
  const {
    activeHashtags,
    expiredHashtags,
    invalidIgAccount,
    selectedIgAccount,
    loadingAccounts,
    loadingHashtags,
  } = useHashtags();

  const accounts = useSelector((state) => getAccountsForPlatform(state, "IG"));

  const handleSearch = (item) => {
    dispatch(initHashtagFeed(item._id));
  };

  if (loadingAccounts) {
    return null;
  }

  return (
    <div
      data-testid="hashtagSidebarContent"
      className="HashtagSidebar-Content tw-pb-8"
    >
      <div className="tw-mx-8 tw-mb-4">
        <SelectFeedAccount onClick={handleSearch} accounts={accounts} />
      </div>
      {!loadingHashtags && selectedIgAccount && !invalidIgAccount && (
        <div>
          <ActiveHashtags
            account={selectedIgAccount}
            hashtags={activeHashtags}
            selectedIgAccount={selectedIgAccount}
          />
          <ExpiredHashtags
            account={selectedIgAccount}
            expiredHashtags={expiredHashtags}
            activeHashtags={activeHashtags}
          />
        </div>
      )}
    </div>
  );
};

export default HashtagSidebar;
