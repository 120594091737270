import { AccountType } from "shared/types/accounts";

import { accountHasGroup, getGroupedAccountName } from "./accounts-groups";
import { getAccountPlatformType } from "./account-platform";

export function getAccountDisplayName(
  account: AccountType,
  { withPrefix }: { withPrefix: boolean } = { withPrefix: false }
): string {
  if (accountHasGroup(account)) {
    return getGroupedAccountName(account);
  } else if (account?.loginAlias) {
    return account.loginAlias;
  } else {
    return getAccountNativeName(account, { withPrefix });
  }
}

export function getAccountNativeName(
  account?: AccountType,
  { withPrefix }: { withPrefix: boolean } = { withPrefix: false }
): string {
  // TODO: might make sense to display @ for twitter and some other providers too
  if (withPrefix && ["IG", "TT"].includes(getAccountPlatformType(account))) {
    return `@${account?.login}`;
  }

  return account?.login || "";
}

export function getAccountShortName(account: AccountType): string {
  return getAccountDisplayName(account)
    .split(" ")
    .filter(Boolean)
    .slice(0, 2)
    .map((word) => word[0].toUpperCase())
    .filter((letter) => /[a-zA-Z]/.test(letter))
    .join("");
}
