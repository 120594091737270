import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

import {
  HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE,
  LOAD_MORE_IG_ASSETS_ROUTINE,
} from "constants/ActionTypes";
import { DEFAULT_HASHTAG_FILTER, PAGES } from "constants/MediaLibrary";
import FeedHeader from "components/MediaLibrary/SourceMedia/FeedHeader";
import HashtagFeedActions from "components/MediaLibrary/SourceMedia/HashtagFeed/HashtagFeedActions";
import FeedGrid from "components/MediaLibrary/SourceMedia/FeedGrid/FeedGrid";
import EmptyContainer from "components/MediaLibrary/EmptyContainer/EmptyContainer";

import { LoadingSpinner, SkedButton } from "ui";
import {
  getSelectedIgAccount,
  getSelectedHashtag,
  getInvalidIgAccount,
  getAllMediaLoaded,
  getFeedMedia,
} from "selectors/hashtagFeedSelectors";
import { loadMoreIgAssets } from "actions/sourceMedia";
import { getLoading, getRoutinesLoading } from "selectors/commonSelectors";

export function useHashtagFeed() {
  const feedMedia = useSelector((state) => getFeedMedia(state, PAGES.HASHTAGS));
  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const invalidIgAccount = useSelector(getInvalidIgAccount);
  const selectedHashtag = useSelector(getSelectedHashtag);
  const loading = useSelector(getLoading);
  const loadingInitialMedia = useSelector((state) =>
    getRoutinesLoading(state, [HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE])
  );

  const [filterState, setFilterState] = useState(DEFAULT_HASHTAG_FILTER);

  // NOTE: (Andrey) reset filter state when selected hashtag
  useEffect(() => {
    selectedIgAccount &&
      setFilterState({
        ...DEFAULT_HASHTAG_FILTER,
        excludeAccounts: [selectedIgAccount._id],
      });
  }, [selectedIgAccount]);

  return {
    feedMedia,
    selectedIgAccount,
    invalidIgAccount,
    selectedHashtag,
    loading,
    loadingInitialMedia,
    filterState,
    setFilterState,
  };
}

const HashtagFeed = () => {
  const {
    feedMedia,
    selectedIgAccount,
    invalidIgAccount,
    selectedHashtag,
    loadingInitialMedia,
    filterState,
    setFilterState,
  } = useHashtagFeed();

  const headerText = !_.isEmpty(selectedHashtag)
    ? `#${selectedHashtag.name}`
    : "Unknown";

  return (
    <div className="tw-flex tw-w-full tw-overflow-auto">
      <div className="MediaLibrary-Content tw-flex-grow tw-relative tw-px-8">
        {loadingInitialMedia && <LoadingSpinner />}
        {selectedIgAccount && selectedHashtag && !loadingInitialMedia && (
          <>
            <FeedHeader headerText={headerText} />
            <HashtagFeedActions
              filterState={filterState}
              setFilterState={setFilterState}
            />
            <FeedGrid feedMedia={feedMedia} />
            <LoadMore filterState={filterState} />
            <Footer />
          </>
        )}
        {selectedIgAccount && invalidIgAccount && !loadingInitialMedia && (
          <EmptyContainer>
            <div className="tw-text-red-400 tw-text-center">
              <div>
                The selected account @{selectedIgAccount.login} does not have
                the correct credentials.
              </div>
              <div>Please reconnect Sked Insights on the accounts page.</div>
            </div>
          </EmptyContainer>
        )}
        {selectedIgAccount &&
          !invalidIgAccount &&
          !selectedHashtag &&
          !loadingInitialMedia && (
            <EmptyContainer>Please select a hashtag.</EmptyContainer>
          )}
      </div>
    </div>
  );
};

const LoadMore = ({ filterState }) => {
  const dispatch = useDispatch();
  const isLoadingMore = useSelector((state) =>
    getRoutinesLoading(state, [LOAD_MORE_IG_ASSETS_ROUTINE])
  );
  const allMediaLoaded = useSelector((state) =>
    getAllMediaLoaded(state, PAGES.HASHTAGS)
  );

  const tooltipJsx = (
    <Tooltip id="all-media-loaded">
      Showing all posts from within the last 24 hours.
    </Tooltip>
  );

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-4">
      {allMediaLoaded && (
        <OverlayTrigger placement="bottom" overlay={tooltipJsx}>
          <div className="tw-mb-4 tw-text-gray-400 tw-cursor-default">
            All posts have been loaded
          </div>
        </OverlayTrigger>
      )}
      <SkedButton
        type="primary-outline"
        name="Load More"
        onClick={() => {
          if (!isLoadingMore) {
            dispatch(loadMoreIgAssets(filterState));
          }
        }}
        loading={isLoadingMore}
        disabled={isLoadingMore}
      />
    </div>
  );
};

const Footer = () => {
  return <div className="tw-mb-200px">&nbsp;</div>;
};

export default HashtagFeed;
