import React from "react";
import PropTypes from "prop-types";
import { ExternalLink } from "ui/Link";
import _ from "lodash";
import { Alert, AlertType } from "ui/alert";
import Linkify from "react-linkify";
import moment from "moment";
import { isManualPost } from "utils/posts";

function PostStatus({ post }) {
  let title = "Successfully published.";
  let body = "Successfully published.";
  let type = AlertType.success;
  let defaultExpanded = true;

  if (!post.isSuccess) {
    body = "";
    type = AlertType.error;
    title = "Failed to publish";
    // Failed
    const userDisplayIsObj = _.isEqual({}, post.userDisplayReason);
    const failReasonIsObj = _.isEqual({}, post.failReason);
    const userDisplayReason = userDisplayIsObj ? "" : post.userDisplayReason;
    const failReason = failReasonIsObj ? "" : post.failReason;
    const isManual = isManualPost(post);

    if (userDisplayReason || failReason || post.failedAt) {
      if (userDisplayReason) {
        // userDisplayReason can contain links to help articles
        // if we identify a link, wrap it in ExternalLink to allow the user to click on it
        body = <Linkify component={ExternalLink}>{userDisplayReason}</Linkify>;
      } else if (
        (userDisplayReason === "" || userDisplayReason === null) &&
        failReason
      ) {
        body = failReason;
        if (_.isObjectLike(body)) {
          body = body.message ? body.message : "Unable to get failed reason";
        }
      }
    }

    // Posting Now
    if (post.isPosting) {
      type = AlertType.warning;
      title = "Posting now…";
      body = "We are currently posting the post.";
    }

    // WTF? 107 line of this file the same condition.... and we can delete it
    // Waiting for device but notification channel
    if (post.isQueued && isManual) {
      type = AlertType.info;
      title = "Sent for manual publishing";
      body = (
        <p>
          We have sent this post to users for publishing. See the publishing
          information below for more.
        </p>
      );
    }
    // Waiting for device but NOT a notification channel
    if (post.isQueued && !isManual) {
      const minutes = moment(post.timestamp || post.lastRunAt).minutes();
      const hourOrHalf = !minutes || +minutes === 30;

      type = AlertType.warning;
      title = "Queued to publish";
      body = hourOrHalf ? (
        <p>
          Waiting for a device to become available for publishing. Want your
          post to get published faster? Avoid scheduling posts on the hour or
          half hour.{" "}
          <ExternalLink
            href={
              "https://help.skedsocial.com/en/articles/3811738-how-does-sked-social-s-instagram-scheduling-work"
            }
          >
            Read more
          </ExternalLink>{" "}
          about how we publish for you using real phones.
        </p>
      ) : (
        <p>
          Waiting for a device to become available for publishing. This is
          because{" "}
          <ExternalLink
            href={
              "https://help.skedsocial.com/en/articles/3811738-how-does-sked-social-s-instagram-scheduling-work"
            }
          >
            we publish for you using real phones
          </ExternalLink>
          , and at peak times there can sometimes be a small delay. If you
          experience a lengthy delay please contact our team.
        </p>
      );
    }
    // NOTE WTF
    if (post.isQueued && isManual) {
      type = AlertType.info;
      title = "Sent to notification users";
      body =
        "Sent to users for publication but nobody has marked it as successful yet.";
      defaultExpanded = false;
    }

    // Cancelled
    if (post.isCanceled && !post.isPosting && !post.isQueued) {
      title = "Cancelled";
      type = AlertType.info;
      body = "The post was cancelled before publishing.";
      defaultExpanded = false;
    }
  } else if (post.isPotentialError) {
    type = AlertType.warning;
    title = "Potential error";
    body = (
      <ExternalLink href="https://help.skedsocial.com/en/articles/3811734-what-is-a-potential-error-for-an-instagram-post">
        This post may not have been posted successfully. Click for more info.
      </ExternalLink>
    );
  }

  if (!body) return null;

  // return post successful alerts differently as they do not expand
  // do not render body for these
  if (type === AlertType.success) {
    return <Alert type={type} icon={type} title={title} isExpandable={false} />;
  }
  return (
    <Alert
      type={type}
      title={title}
      icon={type}
      isExpandable={true}
      defaultExpanded={defaultExpanded}
    >
      {body}
    </Alert>
  );
}

PostStatus.propTypes = {
  post: PropTypes.object,
};

export default PostStatus;
