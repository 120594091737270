import React, { useMemo } from "react";
import { ThemeProvider, useTheme, css } from "styled-components";

export enum FormSize {
  default = "medium",
  large = "large",
  medium = "medium",
  small = "small",
}

export function FormThemeProvider(props: {
  size: FormSize;
  children: React.ReactNode;
}) {
  const currentTheme = useTheme();

  const formTheme = useMemo(
    () => ({
      ...currentTheme,
      form: {
        size: props.size ?? FormSize.default,
      },
    }),
    [currentTheme, props.size]
  );

  return <ThemeProvider theme={formTheme}>{props.children}</ThemeProvider>;
}

function checkFormSize(props, size: FormSize) {
  return (
    (props.theme.form?.size === size && !props.size) ||
    props.size === size ||
    props[size]
  );
}

export function formSizeStyles(size: FormSize, code) {
  return css`
    ${(props) =>
      checkFormSize(props, size) &&
      css`
        ${code}
      `};
  `;
}

export function formSizeAttributes(size: FormSize, props, attrs) {
  return checkFormSize(props, size) ? attrs : {};
}
