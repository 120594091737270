import { CSSProperties } from "react";

interface stylesProps {
  minHeight?: string;
  isSearchable?: boolean;
}

export const getStyles = ({ minHeight, isSearchable }: stylesProps) => ({
  control: (base: CSSProperties) => ({
    ...base,
    minHeight: minHeight || "50px",
    display: "flex",
    alignItems: "center",
  }),
  option: (base: CSSProperties) => ({
    ...base,
  }),
  singleValue: (base: CSSProperties) => ({
    ...base,
    minHeight: minHeight || "50px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: isSearchable ? "6px" : 0,
  }),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    minHeight: "30px",
    padding: "4px",
  }),
  multiValue: (base: CSSProperties) => ({
    ...base,
    minHeight: "38px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F6F5FF",
    borderRadius: "5px",
    border: "1px solid #DEE7FF",
  }),
  multiValueRemove: (base: CSSProperties) => ({
    ...base,
    minHeight: "38px",
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    zIndex: 50,
  }),
  group: (base: CSSProperties) => ({
    ...base,
    borderBottom: "1px solid #e5e5e5",
  }),
});
