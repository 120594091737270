import React from "react";
import {
  Conversation,
  Message,
  MessageType,
  MetaReaction,
} from "types/conversationInbox";
import { SMUMessage } from "../../message/smu-message";
import { SystemMessage } from "../../message/system-message";
import { ReactionsChangeData } from "../message-reactions";
import { AccountType } from "shared/types/accounts";
import { Collaborator, User } from "features/user";
import { getAccountDisplayName } from "entities/accounts";
import { ServerMessageInfoProps } from "../../message/types";
import { AccountMessage } from "../../message/account-message";

export interface MessageLayoutBaseProps {
  messages: Message[];
  senderId: string | undefined;
  conversationId: string;
  account: AccountType;
  user: User;
  collaborators: Collaborator[];
  onReactionsChange(data: ReactionsChangeData): void;
  forceLeft?: boolean;
  selectedMessagedId?: string;
  onSelectedMessageId?: (id: string) => void;
  conversation?: Conversation | null | undefined;
}

export interface AnyMessageRenderProps extends MessageLayoutBaseProps {
  smuOpaque?: boolean;
}

export const renderAnyMessage = (
  message: Message,
  propContext: AnyMessageRenderProps
): JSX.Element | null => {
  if (message?.type === "system") {
    return renderSystemMessage(message);
  }

  if (message?.sender?.platform === "SK") {
    return renderAccountMessage(message, propContext);
  }

  return renderSMUMessage(message, propContext);
};

export const renderSystemMessage = (message: Message): JSX.Element => {
  return (
    <SystemMessage
      key={message.id}
      text={message.text}
      createdAt={message.createdAt}
    />
  );
};

export const renderSMUMessage = (
  message: Message,
  propContext: AnyMessageRenderProps
): JSX.Element => {
  const serverMessageProps = getServerMessageProps(message, propContext);
  const { conversationId, senderId, onReactionsChange } = propContext;
  return (
    <SMUMessage
      {...serverMessageProps}
      key={message.id}
      conversationId={conversationId}
      interlocutorId={senderId || ""}
      onReactionsChange={onReactionsChange}
      opaque={propContext.smuOpaque}
      selected={propContext.selectedMessagedId === message.id}
      onSelected={propContext.onSelectedMessageId}
    />
  );
};

export const renderAccountMessage = (
  message: Message,
  propContext: MessageLayoutBaseProps
): JSX.Element => {
  const serverMessageProps = getServerMessageProps(message, propContext);
  const {
    senderId,
    conversationId,
    account,
    user,
    collaborators,
    onReactionsChange,
  } = propContext;
  const accountName = getAccountDisplayName(account);
  const getUserName = (message: Message) =>
    message?.sender?.name || (account ? accountName : "-");
  return (
    <AccountMessage
      {...serverMessageProps}
      forceLeft={propContext.forceLeft}
      conversationId={conversationId}
      userName={getUserName(message)}
      isTeamMessage={message.isInternal}
      key={message.id}
      isCurrentUser={message?.sender === null}
      collaborators={collaborators}
      interlocutorId={senderId || ""}
      status={message?.status}
      onReactionsChange={onReactionsChange}
      shouldDisplaySender={
        message.sentFromSked ||
        message.isInternal ||
        (!!message.sender?.platformId &&
          !!user?.adminId &&
          message.sender?.platformId !== user?.adminId)
      }
    />
  );
};

export function getServerMessageProps(
  message: Message,
  props: MessageLayoutBaseProps
) {
  return mapServerMessageProps({
    message,
    accountId: props.account?._id || "",
    smuInterlocutorId: props.senderId || "",
    conversationId: props.conversationId,
    conversation: props.conversation,
  });
}

export function resolveMessageReactions(message: Message, accountId = "") {
  if (message.type === MessageType.ttThreadedComment) {
    return message.liked
      ? [
          {
            id: message.id,
            reactee: accountId,
            reaction: MetaReaction.Love,
            createdAt: new Date().toString(),
          },
        ]
      : [];
  }

  return message.reactions || [];
}

function mapServerMessageProps({
  message,
  accountId,
  smuInterlocutorId,
  conversationId,
  conversation,
}: {
  message: Message;
  accountId: string;
  smuInterlocutorId: string;
  conversationId: string;
  conversation?: Conversation | null | undefined;
}): ServerMessageInfoProps {
  return {
    message: message.text,
    src: message.sender?.image,
    timestamp: new Date(message.createdAt),
    name: message.sender?.name || "",
    type: message.type,
    story: message.story,
    attachments: message.attachments,
    sender: message.sender?.id ? { id: message.sender.id } : undefined,
    replyTo: message.replyTo
      ? mapServerMessageProps({
          message: message.replyTo,
          accountId,
          smuInterlocutorId,
          conversationId,
        })
      : undefined,
    reactions: {
      reactions: resolveMessageReactions(message, accountId),
      skedAccountId: accountId,
      smuInterlocutorId: smuInterlocutorId,
    },
    messageId: message.id,
    attachment: message.attachment,
    originalMessage: message,
    conversationId,
    conversation,
  };
}
