export const AppConfig = {
  Namespace: "sked",
  SkedStories: {
    Token: "PdtTUdgy2UoapXGe0E5FPQtt",
    StartWith: isStagingOrDev() ? "story-groups" : "story-groups-production",
  },
  ZIndex: {
    Dropdown: 100,
    Notification: 10,
    Drawer: 2000,
  },
};

export function isStagingOrDev(): boolean {
  return (
    process.env.NODE_ENV === "test" ||
    process.env.NODE_ENV === "staging" ||
    process.env.NODE_ENV === "dev"
  );
}
