import { MediaUrlInfo, PostMediaFile } from "shared/types/post";
import { FIELDS } from "constants/PostFormFields";

/** sometimes post.mediaFiles is empty and media is stored in post form itself,
 * this will happen when create a scheduled post with single image then edit that post again
 * This function is to unify the way to extract media urls from post
 */
export function extractMediaUrls(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: Record<string, any> | undefined
): MediaUrlInfo[] {
  if (!post) {
    return [];
  }

  const mediaFiles = post[FIELDS.MEDIA] as PostMediaFile[];
  if (mediaFiles && mediaFiles.length > 0) {
    return mediaFiles;
  }

  if (post.url && post.meta) {
    return [
      {
        url: post.url,
        meta: post.meta,
        isVideo: post.isVideo || post.meta?.isVideo,
        type: post.meta?.type,
        thumbnailUrl: post.thumbnailUrl,
      },
    ];
  }
  return [];
}
