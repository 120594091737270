import React, { useState, useEffect, useMemo } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { FormSize, FormThemeProvider, MultiSelect } from "ui/ecosystems/forms";

const PinterestBoards = ({
  post,
  selectedPlatform,
  platforms,
  account,
  disabled = false,
  onChange,
  errors,
  className = "",
}) => {
  const [selected, setSelected] = useState(post[FIELDS.PI_BOARDS]);

  let accountBoards = _.orderBy(
    account.boards || [],
    [(board) => board.name.toLowerCase()],
    ["asc"]
  );
  const boards = accountBoards.map((board) => ({
    id: board.id,
    value: board.id,
    label: board.name,
  }));

  useEffect(() => {
    if (post[FIELDS.PI_BOARDS]) {
      setSelected(
        boards.filter((board) => post[FIELDS.PI_BOARDS].includes(board.id))
      );
    }
  }, [post[FIELDS.PI_BOARDS]]);

  const handleCategoryChange = (boardIds) => {
    const clear = boardIds === null || boardIds.length === 0;
    if (clear) {
      setSelected([]);
      onChange([]);
      return;
    }
    setSelected(boards.find((option) => option.value === boardIds));
    onChange(boardIds);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Board{selected && selected.length > 1 ? "s" : ""} for{" "}
          {account.loginAlias || account.login}
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.PI_BOARDS}
          selectedPlatform={selectedPlatform}
        />
      </div>
      {boards && boards.length > 0 && (
        <FormThemeProvider size={FormSize.large}>
          <MultiSelect
            value={selected}
            onChange={handleCategoryChange}
            options={boards}
            disabled={disabled}
          />
        </FormThemeProvider>
      )}
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.PI_BOARDS]}
        errors={errors}
      />
    </div>
  );
};

export default PinterestBoards;
