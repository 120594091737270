import React, { useState } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FIELDS, IG_STORY_TYPES } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";

const StoryType = ({
  post,
  onChange,
  errors = {},
  className = "",
  platforms,
  selectedPlatform,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(post[FIELDS.STORY_TYPE] || "story");

  let config = [
    {
      label: IG_STORY_TYPES.STORY.LABEL,
      value: IG_STORY_TYPES.STORY.VALUE,
    },
    {
      label: IG_STORY_TYPES.REEL.LABEL,
      value: IG_STORY_TYPES.REEL.VALUE,
    },
  ];

  const handleStoryTypeChange = (item) => {
    setSelected(item);
    onChange(item);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4 tw-mt-2">
          Type
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.STORY_TYPE}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <Select
        options={config}
        value={selected}
        onChange={handleStoryTypeChange}
        disabled={disabled}
      />
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.STORY_TYPE]}
        errors={errors}
      />
    </div>
  );
};

export default StoryType;
