import { UpdateUserPricingPlanAction } from "actions/user-pricing-plan";
import { User } from "features/user";
import { isCollaborationEnabledForPlan } from "libs/account-permission-check";

export const updateUserPricingPlanReducer = (
  state: User,
  action: UpdateUserPricingPlanAction
): User => {
  return {
    ...state,
    plan: action.payload.plan,
    subscription: action.payload.subscription,
    isCollaborationEnabled:
      state.isCollaborationEnabled ||
      isCollaborationEnabledForPlan(action.payload.plan),
  };
};
