import { Country, HolidaysCalendar, Holiday } from "shared/types/calendar";

export const getCountries = (state): Country[] => {
  return state.calendarFilter.countries;
};

export const getHolidaysCalendars = (state): HolidaysCalendar[] => {
  return state.calendarFilter.holidaysCalendars;
};

export const getCountriesHolidays = (state): Holiday[] => {
  return state.calendarFilter.holidays;
};
