import { useState, useMemo } from "react";
import { createGate, useGate, useStore } from "effector-react";
import { createEffect, forward, restore } from "effector";
import { useStorageStoriesShown } from "libs/storage/adapters";

import { getStories } from "api/storyblock";

import { createZuckStory, ZuckStory } from "../react-zuck";

const SkedStoriesGate = createGate();

const getStoriesFx = createEffect(getStories);

const $stories = restore(
  getStoriesFx.doneData.map((stories) => stories.map(createZuckStory)),
  null
);

forward({
  from: SkedStoriesGate.open,
  to: getStoriesFx,
});

export function useSkedStories(): [
  {
    stories: ZuckStory[] | null;
    allowHide: boolean;
    onAllowHide: () => void;
    onHide: () => void;
  },
  boolean
] {
  useGate(SkedStoriesGate);

  //NOTE - migration from sessionStorage to localStorage
  const oldSessionStorageKey = "sked-stories-shown";
  const oldSessionStorageValue = sessionStorage.getItem(oldSessionStorageKey);

  if (oldSessionStorageKey) {
    sessionStorage.removeItem(oldSessionStorageKey);
  }

  const [shown, setShown] = useStorageStoriesShown(
    oldSessionStorageValue ? !!oldSessionStorageValue : true
  );
  const [allowHide, setAllowHide] = useState(false);

  const stories = useStore($stories);

  const hasStories = Boolean(stories && stories.length > 0);

  const skedStoriesProps = useMemo(
    () => ({
      stories,
      allowHide,
      onAllowHide: () => setAllowHide(true),
      onHide: () => setShown(false),
    }),
    [stories, allowHide]
  );

  const skedStoriesShown = hasStories && shown;

  return [skedStoriesProps, skedStoriesShown];
}
