import React from "react";
import styled from "styled-components";
import { FIELDS } from "constants/PostFormFields";
import { ExternalLink } from "ui/Link";
import { QuestionIconWithTooltip } from "ui";
import StoryLabel from "components/Posts/PostForm/StoryFields/StoryLabel";
import StoryStickerHeight from "components/Posts/PostForm/StoryFields/StoryStickerHeight";
import PostUrl from "../PostUrl/PostUrl";
import { StyledStoryType } from "./StyledStoryType";
import { PublishManually } from "./PublishManually";
import { NotesForManualPublish } from "./NotesForManualPublish";
import { flexColumnMb, flexColumn } from "./single-post-form";

const linkStickerTooltip = () => {
  return (
    <span>
      Link sticker URL{" "}
      <ExternalLink
        href={`https://help.skedsocial.com/en/articles/6385234-scheduling-instagram-stories-with-link-stickers`}
      >
        <QuestionIconWithTooltip
          className={`tw-inline`}
          tooltip={`Read more about link stickers and how they work`}
        />
      </ExternalLink>
    </span>
  );
};

export function StoryFields({
  postFormValue,
  errors,
  platforms,
  updateField,
  platformPost,
  selectedPlatform,
  allDisabled,
  visibleFields,
}) {
  const isStoryTypeForSticker = (postFormValue) => {
    return (
      (postFormValue[FIELDS.IS_STORY] ||
        postFormValue[FIELDS.STORY_TYPE] === "story" ||
        !postFormValue[FIELDS.STORY_TYPE]) &&
      !(
        postFormValue[FIELDS.STORY_TYPE] === "reel" ||
        postFormValue[FIELDS.STORY_TYPE] === "igtv"
      )
    );
  };
  return (
    <>
      {/* Story type */}
      {visibleFields.includes(FIELDS.STORY_TYPE) && (
        <StyledStoryType
          errors={errors}
          platforms={platforms}
          updateField={updateField}
          platformPost={platformPost}
          selectedPlatform={selectedPlatform}
        />
      )}
      {/* Story URL - show only if user has preference enabled */}
      {isStoryTypeForSticker(postFormValue) && (
        <PostUrl
          url={platformPost.storyUrl}
          selectedPlatform={selectedPlatform}
          platforms={platforms}
          className="tw-mb-4"
          title={linkStickerTooltip}
          onChange={(newUrl) => updateField(FIELDS.STORY_URL, newUrl)}
        />
      )}
      {/* Story URL - show only if user has preference enabled */}
      {isStoryTypeForSticker(postFormValue) && (
        <StoryLabelStyled
          post={platformPost}
          selectedPlatform={selectedPlatform}
          platforms={platforms}
          className="tw-mb-4"
          title={`Link sticker label (optional, beta)`}
          onChange={(label) => updateField(FIELDS.STORY_LABEL, label)}
        />
      )}
      {/* Story sticker height */}
      {isStoryTypeForSticker(postFormValue) && (
        <StoryStickerHeightStyled
          post={platformPost}
          selectedPlatform={selectedPlatform}
          platforms={platforms}
          className="tw-mb-4"
          title={`Link sticker height from top (beta)`}
          onChange={(height) =>
            updateField(FIELDS.STORY_STICKER_HEIGHT, height)
          }
        />
      )}

      {/* Publish manually */}
      <PublishManually
        postFormValue={postFormValue}
        platforms={platforms}
        updateField={updateField}
        selectedPlatform={selectedPlatform}
        allDisabled={allDisabled}
      />

      {/* Notes for manual publish */}
      {postFormValue[FIELDS.PUBLISH_STORY_MANUALLY] && (
        <NotesForManualPublish
          postFormValue={postFormValue}
          errors={errors}
          updateField={updateField}
          allDisabled={allDisabled}
        />
      )}
    </>
  );
}
const StoryLabelStyled = styled(StoryLabel)`
  ${flexColumnMb};
`;
const StoryStickerHeightStyled = styled(StoryStickerHeight)`
  ${flexColumn};
  min-width: 100px;
`;
