import React from "react";
import styled from "styled-components";

import TrashIcon from "assets/icons/trash.svg";

import DropIcon from "./icon.svg";
import { Button } from "../button";
import { FormSize } from "../form-theme-provider";
import { useDropzone } from "react-dropzone";

interface Props {
  value?: string;
  onChange(value: File): void;
  onRemove(): void;
  className?: string;
  disabled?: boolean;
}

export function PictureInputComponent(props: Props) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/gif, image/jpeg",
    multiple: false,
    async onDropAccepted(files) {
      const selectedFile = files[0];
      props.onChange(selectedFile);
    },
    disabled: props.disabled,
  });

  return (
    <PictureInputRoot className={props.className}>
      {props.value ? (
        <Preview src={props.value} />
      ) : (
        <DropZone {...getRootProps()}>
          <DropIcon />
          <Button type="button" outline size={FormSize.small}>
            Select File
            <input
              data-testid="pictureFileInput"
              type="file"
              {...getInputProps()}
            />
          </Button>
        </DropZone>
      )}
      <RemoveButton
        onClick={!props.disabled && props.onRemove}
        css={!props.value && "visibility: hidden"}
      />
    </PictureInputRoot>
  );
}

export const PictureInput = styled(PictureInputComponent)``;

const Preview = styled.img`
  width: 153px;
  height: 153px;
  object-fit: cover;
`;

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 153px;
  height: 153px;
  background: #f8f8f8;

  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;

  ${Button} {
    margin-top: 16px;
  }
`;

const RemoveButton = styled(TrashIcon)`
  cursor: pointer;
  path {
    fill: #ff3c3c;
  }
`;

const PictureInputRoot = styled.div`
  display: flex;

  ${RemoveButton} {
    margin-left: 10px;
  }
`;
