import React from "react";
import styled, { css } from "styled-components";

const PostFieldLabelComponent = ({
  children,
  className = "",
  ...other
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <PostFieldContainer
      className={`tw-font-bold tw-text-2xl ${className}`}
      {...other}
    >
      {children}
    </PostFieldContainer>
  );
};

export const PostFieldLabel = styled(PostFieldLabelComponent)``;
export default PostFieldLabel;

const PostFieldContainer = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;
