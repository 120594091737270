import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  FormData,
  NgPost,
  parseFormDataToPost,
  parseNgDataToPost,
} from "../format-post";
import { put, putResolve, select, take } from "redux-saga/effects";
import { startPostForm } from "actions/postForm";
import { validateFull } from "components/Posts/PostForm/postFormValidator";
import {
  SAVE_POST_FORM_ROUTINE,
  UPDATE_POST_FORM_VALIDATION,
} from "constants/ActionTypes";
import { AccountType } from "shared/types/accounts";

import { closePostForm, isFormSubmitting } from "../post-form.model";
import { Post } from "../types";
import { savePost } from "../post.model";
import { editingPostNeedUploadCover, getUpdateThumbnail } from "../utils";

export const startPostEditing = createAction("START_POST_EDITING");

export function* PostEditing(post: NgPost, { onSubmit }): Generator<FormData> {
  // @ts-ignore
  const allAccounts = yield select(allAccountsSelector);

  // @ts-ignore
  const accounts = yield select(
    getAccountsByIds(
      (post.accountIds || []).length ? post.accountIds : [post.accountId]
    )
  );

  // @ts-ignore
  yield put(
    startPostForm(
      {
        ...post,
        accountIds: accounts.map((account) => account._id),
      },
      accounts
    )
  );

  while (true) {
    // @ts-ignore
    const { payload: formData } = yield take(closePostForm.type);
    if (!formData) {
      break;
    }

    // @ts-ignore
    const overlapSchedule = yield select(
      (state) => state.forms.postForms.byId[post._id]?.overlapSchedule
    );

    const [errors, warnings] = validateFull(
      formData.newPostState,
      formData.post,
      formData.platformFields,
      formData.platforms,
      overlapSchedule
    );

    if (errors && Object.keys(errors).length > 0) {
      // @ts-ignore
      yield put({
        type: UPDATE_POST_FORM_VALIDATION,
        payload: { postId: post["_id"], errors, warnings },
      });
      // @ts-ignore
      yield put({
        type: SAVE_POST_FORM_ROUTINE.FAILURE,
        error: false,
        payload: "",
      });
    } else {
      if (formData) {
        try {
          // @ts-ignore
          yield put(isFormSubmitting.actions.startFormSubmitting());

          // If there's a selected video cover image, upload to S3 and replace thumbnailUrl with the returned URL
          const convertVideoCover = async (newPosts, previousPost) => {
            const posts = Array.isArray(newPosts) ? newPosts : [newPosts];

            const promises = posts.map(async (post) => {
              const { videoCoverPreview, ...postWithoutCoverPreview } = post;

              if (editingPostNeedUploadCover(post, previousPost)) {
                return {
                  ...postWithoutCoverPreview,
                  thumbnailUrl: await getUpdateThumbnail(post),
                };
              }

              return Promise.resolve(postWithoutCoverPreview);
            });

            const results = await Promise.all(promises);
            return results.length > 1 ? results : results[0];
          };

          const previousPost = parseNgDataToPost(
            post,
            allAccounts,
            formData.originalPostState
          );

          const newPost = yield convertVideoCover(
            parseFormDataToPost(formData, allAccounts),
            previousPost
          );

          yield onSubmit(newPost, previousPost);
          toast.success("Posts submitted successfully");
          return formData;
        } catch (error) {
          // @ts-ignore
          yield put({
            type: SAVE_POST_FORM_ROUTINE.FAILURE,
            error: true,
            payload: error?.response?.data?.error || error,
          });
          toast.success(error?.response?.data?.error || error);
        } finally {
          yield put(isFormSubmitting.actions.finishFormSubmitting());
        }
      }
    }
  }
}

export function* editPost(post: NgPost, onSubmit) {
  const accounts = yield select(
    getAccountsByIds(post.accountIds || [post.accountId])
  );

  return yield PostEditing(
    {
      ...post,
      // remove all deleted accounts
      accountIds: accounts.map((account) => account._id),
    },
    {
      onSubmit: (newPost: Post, previousPost: Post) => {
        if (typeof onSubmit === "function") {
          return onSubmit?.(newPost);
        } else {
          return putResolve(savePost(newPost, previousPost));
        }
      },
    }
  );
}

function getAccountsByIds(ids: string[]): (state: unknown) => AccountType[] {
  // @ts-ignore
  return (state: any) =>
    ids.map((id) => state.entities.accounts.byId[id]).filter(Boolean);
}

function allAccountsSelector(state) {
  return state.entities.accounts.byId;
}
