export * from "./MediaLibraryTrackEvents";

// GENERAL
export const HEADER_MENU_ADD_USER_CLICK = "clicked-user-top";
export const HEADER_ADD_USER_CLICK = "add-user-button-top";
export const HEADER_MENU_ADD_ACCOUNT_CLICK = "clicked-account-top";
export const HEADER_ADD_ACCOUNT_CLICK = "add-account-button-top";
export const HEADER_SUPPORT_CLICK = "contact-support-button-top";

// INBOX
export const INBOX_INTERNAL_NOTE = "inbox-internal-note";
export const INBOX_MESSAGE_SENT = "inbox-message-sent";
export const INBOX_QUICK_REPLY = "inbox-quick-reply";
export const INBOX_PROFILE_LINK_CLICK = "inbox-profile-link-click";
export const INBOX_CLOSE_ALL = "inbox-close-all";
export const INBOX_ASSIGN_USER = "inbox-assign-user";
export const INBOX_ACCOUNT_SELECTED = "inbox-account-selected";
export const INBOX_CONVERSATION_STARRED = "inbox-conversation-starred";
export const INBOX_CONVERSATION_REOPENED = "inbox-conversation-reopened";
export const INBOX_CONVERSATION_CLOSE = "inbox-close-conversation";
export const INBOX_VIEW_SELECTED = "inbox-view-selected";
export const INBOX_MENU_CLICKED = "inbox-menu-clicked";
export const INBOX_COPY_CAPTIVATE_REVIEW = "copy-captivate-review";
export const INBOX_TAGGED_IN_CREATE = "inbox-tagged-in-create";
export const INBOX_TAGGED_IN_LIBRARY = "inbox-tagged-in-library";
export const INBOX_CHIPS_SELECTED = "inbox-chips-selected";

// POST
export const POST_STATUS_FILTER = "post-status-filter";
export const POST_TYPE_FILTER = "post-type-filter";
export const POST_CONTENT_TYPE_FILTER = "post-content-type-filter";
export const COPY_TO_DRAFT_SELECT = "copy_to_draft_select";
export const COPY_TO_DRAFT_ACTION = "copy_to_draft_action";
export const COPY_TO_DRAFT_ADD_ACCOUNT = "copy_to_draft_add_account";
export const CLICKED_PREVIEW_POST_MODAL = "clicked-preview-post-modal";
export const CREATE_SINGLE_POST = "create-single-post";
export const UNLINK_TOGGLE_TW = "unlink-toggle-tw";
export const CREATE_POST_YT = "create-post-yt";
export const CREATE_POST_LI = "create-post-linkedIn";

// USER PAGE
export const USER_PAGE_NAVIGATION = "user-page-navigation";
export const USER_PAGE_NOTIFICATION_EXPAND_TEXT =
  "user-page-notification-expand-text";
export const USER_PAGE_NOTIFICATION_EXPAND_CARET =
  "user-page-notification-expand-caret";
export const USER_PAGE_NOTIFICATION_HIDE = "user-page-notification-hide";
export const USER_PAGE_NOTIFICATION_HELP = "user-page-notification-help";

// PLANNER
export const PLANNER_QUEUE_MESSAGE_EXPAND = "planner-queue-message-expand";
export const PLANNER_QUEUE_HIDDEN = "planner-queue-hidden";
export const PLANNER_QUEUE_SETUP = "planner-queue-setup";

// NEW CALENDAR
export const NEW_CAL_DELETE_DRAFT = "new_cal_delete_draft";
export const NEW_CAL_DRAG_DRAFTS = "new-cal-drag-drafts";
export const NEW_CAL_WORK_DAY_VIEW = "new_cal_work_day_view";
export const NEW_CAL_ZOOM_IN = "new_cal_zoom_in";
export const NEW_CAL_ZOOM_OUT = "new_cal_zoom_out";
export const NEW_CAL_ZOOM_FILTER_HOVER = "new_cal_zoom_filter_hover";
export const NEW_CAL_DRAFT_EDIT = "new-cal-draft-edit";
export const NEW_CAL_PERIOD_TODAY = "new-cal-period-today";
export const NEW_CAL_PERIOD_CHANGE = "new-cal-period-change";
export const NEW_CAL_SELECTED_POST_STATUS = "new-cal-selected-post-status";
export const NEW_CAL_POST_AGAIN = "new_cal_post_again";
export const NEW_CAL_POST_AGAIN_ACTION = "new_cal_post_again_action";
export const NEW_CAL_POST_DETAILS = "new-cal-post-details";
export const NEW_CAL_POST_EDIT = "new-cal-post-edit";
export const NEW_CAL_POST_TO_DRAFTS = "new-cal-post-to-drafts";
export const NEW_CAL_POST_DELETE = "new-cal-post-delete";
export const NEW_CAL_POST_CREATE = "new-cal-post-create";
export const NEW_CAL_CLICK_POST_STATUS = "new-cal-click-post-status";
export const NEW_CAL_FILTERS_SHOW = "new-cal-filters-show";
export const NEW_CAL_FILTERS_HIDE = "new-cal-filters-hide";
export const NEW_CAL_FILTERS_ACCOUNTS = "new-cal-filters-accounts";
export const NEW_CAL_FILTERS_EVENTS = "new-cal-filters-events";
export const NEW_CAL_DRAFTS_SHOW = "new-cal-drafts-show";
export const NEW_CAL_DRAFTS_HIDE = "new-cal-drafts-hide";
export const CALENDAR_NOTE_DELETED = "calendar_note_deleted";
export const CALENDAR_NOTE_EDITED = "calendar_note_edited";
export const CALENDAR_NOTE_CREATED = "calendar_note_created";
export const ICS_IMPORTED = "ics-imported";

// Media Library
export const SELECTED_MEDIA_FROM_LIBRARY = "selected-media-from-library";
export const CREATE_POST_FROM_LIBRARY_SCREEN =
  "create-post-from-library-screen";
export const FRONTIFY_WIDGET_CLICKED = "frontify-widget-clicked";

// SKED CAPTION
export const SELECTED_ASSET_DESC = "selected-asset-description";
export const HASHTAG_RECOMMENDATION_OPENED = "hashtag-recommendation-opened";
export const HASHTAG_RECOMMENDATION_SELECTED = "recommended-hashtag-selected";

// COLLABORATION
export const COLLABORATION_VIEW_LINKS = "collaboration-view-links";
export const COLLABORATION_CREATE_LINKS = "collaboration-create-link";
export const COLLABORATION_CREATE_EXT_LINK = "create_ext_link";
export const ADD_USER_CLICKED = "add-user-clicked";

export const GROUP_SELECT_POST_CREATE = "group_select_post_create";
export const PHOTO_EDITOR_EXPORT = "photo-editor-export";
export const VIEWED_SKED_STORY = "viewed-sked-story";
export const REQUESTED_TO_PAUSE_ACCOUNT = "Requested to pause account";
export const REQUESTED_TO_CANCEL_ACCOUNT = "requested-cancel-account";

// COMMENTS
export const COMMENTS_TAB = "comments-click-tab";

// AI CAPTION
export const CAPTIVATE_TAB = "Captivate-click-tab";
export const CREATE_BUTTON = "Captivate-create";
export const COPY_IDEA = "Captivate-copy-idea";
export const NEW_IDEA = "Captivate-new-idea";
export const UPDATE_TO_CAPTIVATE_CREATE = "Update-to-captivate-create	";
export const UPDATE_TO_CAPTIVATE_COPY_IDEA = "Update to captivate-copy-idea	";

// Insights Add Pdf reports modal

// When a user sees the PDF upsell modal
export const PDF_UPSELL_MODAL = "pdf-upsell-modal";
// When a user downloads a sample PDF report
export const SAMPLE_REPORT_DOWNLOAD = "sample-report-download";
//  When a user clicks the contact us to add PDF reports button:
export const PDF_CONTACT_BUTTON = "pdf-contact-button";

// Setting
export const CANCEL_PLAN_CLICK = "cancellation-click";

// SKED HOME
export const HOME_GROUP_CREATE = "home-group-create";
export const HOME_GROUP_SAVE = "group-save";
export const HOME_ACCOUNTS = "home-accounts";
export const HOME_INSIGHTS_CLICK = "home-insights-click";
export const HOME_INBOX_CLICK = "home-inbox-click";
export const HOME_INBOX_UGC = "home-inbox-ugc";
export const HOME_POSTS = "home-posts";
export const HOME_CAPTIVATES = "home-captivate";

export const UPGRADE_CONFIRM_VIEWED = "Upgrade-confirm-viewed";
export const UPGRADE_SUCCESS = "Upgrade-success";
export const UPGRADE_FAILED = "Upgrade-failed";
export const UPGRADE_LABELS_PLANNING_VIEWED = "Upgrade-labels-planning-viewed";
export const UPGRADE_TEAMWORK_VIEWED = "Upgrade-teamwork-viewed";
export const UPGRADE_PLAN_VIEWED = "Upgrade-plan-viewed";
export const UPGRADE_APPROVAL_VIEWED = "Upgrade-approval-viewed";
export const UPGRADE_PAID_INSIGHTS_VIEWED = "Upgrade-paid-insights-viewed";

export const UPGRADE_PDF_REPORT_VIEWED = "Upgrade-pdf-report-viewed";
export const UPGRADE_COMPETITOR_VIEWED = "Upgrade-competitor-viewed";

export const UPGRADE_EXCEL_REPORTS_VIEWED = "Upgrade-excel-reports-viewed";
export const UPGRADE_COMPARISON_VIEWED = "Upgrade-comparison-viewed";
export const UPGRADE_LABELS_REPORTING = "Upgrade-labels-reporting";
export const UPGRADE_LIBRARY_VIEWED = "Upgrade-library-viewed";
export const UPGRADE_USERS_VIEWED = "Upgrade-users-viewed";
export const UPGRADE_ESSENTIALS_VIEWED = "Upgrade-essentials-viewed";
export const UPGRADE_FRONTIFY_VIEWED = "Upgrade-frontify-viewed";
export const UPGRADE_REPORT_BUILDER_VIEWED = "Upgrade-report-builder-viewed";

export const CREATE_POST_LINKEDIN_PDF = "create-post-linkedin-pdf";
export const COVER_IMAGE_SELECTED = "cover-image-selected";
export const CONTACT_SUPPORT_CONNECTING_ACCOUNT =
  "Contact-Support-Connecting-Account";
