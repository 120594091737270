import { useEffect, useState } from "react";

export function useIsWindowDragActive() {
  const [isDrag, setIsDrag] = useState(false);

  useEffect(() => {
    let count = 0;

    function handleDragEnter() {
      count++;
      setIsDrag(true);
    }

    function handleDragLeave() {
      count--;
      if (count === 0) {
        setIsDrag(false);
      }
    }

    function handleDrop() {
      count = 0;
      setIsDrag(false);
    }

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("drop", handleDrop);
    document.addEventListener("dragleave", handleDragLeave);

    return () => {
      document.removeEventListener("dragover", handleDragEnter);
      document.removeEventListener("drop", handleDrop);
      document.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  return isDrag;
}
