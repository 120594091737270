import { call, takeEvery, put, select } from "redux-saga/effects";
import moment from "moment";
import { DATE_FORMAT } from "constants/common";
import { filterAccountsByPlatformType } from "utils/accounts";
import { CHECK_INSTAGRAM_POST_OVERLAPPING } from "constants/ActionTypes";
import { checkOverlappingPosts } from "api/post";
import { handleError } from "utils/handleError";
import { NEW_POST_ID } from "constants/PostsView";
import { getUser } from "selectors/commonSelectors";
interface OverlappingResponseData {
  accountName: string;
  overlappingPostId: string;
  nextRunAt: Date;
}

interface OverlappingPayload {
  payload: {
    postId: string;
    accounts: any[];
    isNow: boolean;
    when: string;
    done: () => Promise<void>;
  };
}

export function* checkInstagramPostOverlapping({
  payload,
}: OverlappingPayload): Generator {
  const { postId, accounts, isNow, when, done } = payload;

  if (!(isNow || when)) {
    done && done();

    return;
  }

  const instagramAccounts = accounts.filter(filterAccountsByPlatformType("IG"));

  if (!instagramAccounts.length) {
    yield put(
      CHECK_INSTAGRAM_POST_OVERLAPPING.success({
        postId,
        data: {
          accountName: null,
          overlappingPostId: null,
          nextRunAt: null,
        },
      })
    );
    done && done();

    return;
  }

  try {
    yield put(CHECK_INSTAGRAM_POST_OVERLAPPING.request());
    const user = yield select(getUser);
    const data: OverlappingResponseData = yield call(checkOverlappingPosts, {
      accountIds: instagramAccounts.map(({ _id }) => _id),
      date: moment(isNow ? moment(new Date()).tz(user.timezone) : when).format(
        DATE_FORMAT
      ),
      postId: postId !== NEW_POST_ID ? postId : undefined,
    });
    yield put(CHECK_INSTAGRAM_POST_OVERLAPPING.success({ postId, data }));
  } catch (err) {
    yield put(CHECK_INSTAGRAM_POST_OVERLAPPING.failure());
    handleError(err, "Instagram available time check failed");
  } finally {
    done && done();
  }
}

export function* postFormSagas(): Generator {
  yield takeEvery(
    CHECK_INSTAGRAM_POST_OVERLAPPING.TRIGGER,
    checkInstagramPostOverlapping
  );
}
