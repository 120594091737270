import React from "react";
import { ExternalLink } from "ui/Link";
import {
  AltTextTags,
  Caption,
  DetailsTemplate,
  PostDetail,
  PostLink,
  Tag,
  Tags,
  TagTooltip,
} from "./post-details-template";
import { PostAccount, PostNotificationMeta } from "components/Posts/Post/index";
import { FIELDS } from "constants/PostFormFields";
import { PAGES } from "constants/Pages";
import { Alert, AlertType } from "ui/alert";
import { SelectedCollaboratorTag } from "components/Posts/PostForm/components/CollaboratorsModal";

export function InstagramDetails({ post, account, page }) {
  const getStoryLabel = function (type) {
    switch (type) {
      case "igtv":
        return "IGTV";
      case "reel":
        return "Instagram Reel";
      case "story":
      default:
        return "story";
    }
  };

  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          {/* Publish Manually */}
          {post.publishStoryManually && page !== PAGES.ARCHIVE && (
            <Alert
              type={AlertType.info}
              icon={true}
              title={"Set to manually publish"}
              isExpandable
              defaultExpanded
            >
              This{" "}
              {post[FIELDS.STORY_TYPE]
                ? getStoryLabel(post[FIELDS.STORY_TYPE])
                : "story"}{" "}
              {page === PAGES.ARCHIVE ? "was" : "is"} set to post manually.{" "}
              <ExternalLink href="https://help.skedsocial.com/en/articles/3969654-how-does-manual-story-publishing-work">
                See how manual publishing works.
              </ExternalLink>
            </Alert>
          )}
          <Caption post={post} />

          {Array.isArray(post.pushNotificationMeta) && (
            <PostNotificationMeta post={post} />
          )}

          {post.firstcomment && (
            <PostDetail label="First comment">{post.firstcomment}</PostDetail>
          )}
          {post.storyUrl && (
            <PostDetail label="Story sticker URL">
              <PostLink
                url={post.storyUrl}
                errorMessage="Enable to load preview for invalid story URL"
              />
            </PostDetail>
          )}
          {post.storyLabel && (
            <Tag label="Sticker label">{post.storyLabel}</Tag>
          )}
          <InstagramCollaboratorTag post={post} />
          <Tags>
            {post.location?.name && (
              <Tag label="Location">{post.location.name}</Tag>
            )}
            <InstagramTags post={post} />
            <AltTextTags post={post} />
          </Tags>
        </>
      )}
    />
  );
}

export function InstagramCollaboratorTag({ post }) {
  return post.collaboratingIgUsers?.length > 0 ? (
    <PostDetail label="Collaborator(s)">
      <SelectedCollaboratorTag
        selectedCollaborator={post.collaboratingIgUsers}
      ></SelectedCollaboratorTag>
    </PostDetail>
  ) : null;
}

export function InstagramTags({ post }) {
  return (
    <>
      {post.tags?.length > 0 && (
        <Tag label="User tags">
          <TagTooltip tags={post.tags}>{post.tags.length}</TagTooltip>
        </Tag>
      )}
      {post.productTags?.length > 0 && (
        <Tag label="Product tags">
          <TagTooltip tags={post.productTags}>
            {post.productTags.length}
          </TagTooltip>
        </Tag>
      )}
    </>
  );
}
