import React, { useState } from "react";
import PropTypes from "prop-types";
import { SkedIcon } from "ui";

const HashtagListWrapper = ({ children, defaultIsOpen, title }) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const icon = isOpen ? "icon-down-close" : "icon-left-close";
  return (
    <div>
      <div
        className="tw-font-black tw-ml-6 tw-cursor-pointer tw-flex"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="tw-mr-4">{title}</div>
        <div>
          <SkedIcon icon={icon} />
        </div>
      </div>
      {isOpen && (
        <div>
          <div className="tw-flex tw-flex-grow tw-justify-end tw-text-gray-400 tw-pb-2 tw-pr-4">
            Expires:
          </div>
          <div className="tw-mb-2">{children}</div>
        </div>
      )}
    </div>
  );
};

HashtagListWrapper.propTypes = {
  children: PropTypes.node,
  defaultIsOpen: PropTypes.bool,
  title: PropTypes.string,
};

export default HashtagListWrapper;
