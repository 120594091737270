import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Icon, Tooltip } from "@dialogueconsulting/sked-ui";

const GlobalStyles = createGlobalStyle`
  .tooltip-inner {
    font-family: Inter, Helvetica, Arial, sans-serif;
    padding: 8px 10px;
    font-weight: 500;
    width: 310px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
    border-radius: 5px;
  }
`;

export const Header = ({ description }: { description?: string }) => (
  <>
    <Title marginBottom={!!description}>
      Captivate
      <GlobalStyles />
      <Tooltip
        text="What is your post about? (5-10 words), don't forget the emojis!"
        textSize="12px"
        container={
          document.querySelector(".modal-giselle.modal-dialog") as HTMLElement
        }
      >
        <div>
          <Icon name="alert-circle" color="#333333" size={16} />
        </div>
      </Tooltip>
    </Title>
    {description && <Description>{description}</Description>}
  </>
);

export const Title = styled.p<{ marginBottom?: boolean }>`
  line-height: initial;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 7px;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "8px" : "auto")};

  span {
    color: var(--neutral-n-500, #808080);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 325;
    line-height: 16px; /* 133.333% */
  }
`;

const Description = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
`;
