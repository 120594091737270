import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import CreatableSelect from "react-select/creatable";

import { ReactSelectStyled } from "../_react-select-styled";

interface Props {
  value: string[];
  onChange(value: string[]): void;
  onBlur?(): void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  limit: number;
  autoFocus?: boolean;
}

const components = {
  DropdownIndicator: null,
};

const MultiEmailInputComponent = ({
  onChange,
  value,
  limit,
  placeholder,
  className,
  disabled,
  autoFocus,
  onBlur,
}: Props) => {
  const [inputValue, onInputChange] = useState("");

  const reactSelectValue = useMemo(
    () => value.map((value) => ({ value, label: value })),
    [value]
  );

  function addEmail(email) {
    if (!value.includes(email)) {
      onChange([...value, email]);
    }
  }

  function handleKeyDown(event) {
    if (!validateEmail(inputValue)) return;
    switch (event.key) {
      case "Enter":
      case "Tab": {
        onInputChange("");
        addEmail(inputValue);
        event.preventDefault();
      }
    }
  }

  function handleBlur() {
    if (validateEmail(inputValue)) {
      addEmail(inputValue);
    }

    onBlur && onBlur();
  }

  return (
    <>
      <MultiEmailInputStyled
        autoFocus={autoFocus}
        inputValue={inputValue}
        onInputChange={onInputChange}
        value={reactSelectValue}
        blocked={!!limit && value.length >= limit}
        isDisabled={disabled}
        className={className}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onChange={(items: any) => {
          if (items === null) {
            onChange([]);
          } else {
            onChange(items.map((item: { value: string }) => item.value));
          }
        }}
      />
    </>
  );
};

export const MultiEmailInput = styled(MultiEmailInputComponent)``;

const MultiEmailInputStyled = styled(ReactSelectStyled).attrs({
  as: CreatableSelect,
  components,
  isMulti: true,
  menuIsOpen: false,
})`
  ${(props) =>
    props.blocked &&
    css`
      .select__input {
        display: none !important;
      }
    `}
`;

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
