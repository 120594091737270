import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useStoragePostStatusKey } from "libs/storage/adapters";

import {
  getPage,
  getRoutinesLoading,
  getUser,
} from "selectors/commonSelectors";

import {
  BULK_CHANGE_COLLAB_STATE_ROUTINE,
  CHANGE_COLLAB_STATE_ROUTINE,
} from "constants/ActionTypes";
import { CollaborationPostStatus } from "features/user";

import { filterCollabStatus } from "actions/postsView";
import { getFilters } from "selectors/postsPageSelectors";
import { useTrackEvent } from "libs/analytics";

import {
  Checkbox,
  FormSize,
  FormThemeProvider,
  Select,
} from "ui/ecosystems/forms";
import { TooltipWrapper } from "ui/TooltipWrapper";
import { OptionStatus, PostFilterValueContainer } from "ui/ecosystems/dropdown";
import { POST_STATUS_FILTER } from "constants/Events";

const allOption = {
  label: "All post statuses",
  value: "*",
};

export const PostStatusFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const { postStatusKey } = useSelector(getFilters);
  const user = useSelector(getUser);

  const trackEvent = useTrackEvent();
  const page = useSelector(getPage);

  const isLoadingRoutines = useSelector((state) =>
    getRoutinesLoading(state, [
      BULK_CHANGE_COLLAB_STATE_ROUTINE,
      CHANGE_COLLAB_STATE_ROUTINE,
    ])
  );

  const [isFilterInitiated, setIsFilterInitiated] = useState<boolean>(false);

  const userCollabSettings: [CollaborationPostStatus] =
    user.getPostStatusList();

  const options = useMemo(() => {
    return userCollabSettings.map((setting) => {
      return {
        label: setting.label,
        value: setting.key,
        color: setting.color,
      };
    });
  }, [userCollabSettings]);

  const [currentSelectedStatus, setCurrentSelectedStatus] =
    useStoragePostStatusKey(
      postStatusKey?.length
        ? postStatusKey.map((key: string) =>
            options.find((item) => item.value === key)
          )
        : [allOption, ...options]
    );

  const getSelected = (selected: any[], event: any) => {
    if (selected !== null && selected.length > 0) {
      if (selected[selected.length - 1].value === allOption.value) {
        return [allOption, ...options];
      }
      let result = [];
      if (selected.length === options.length) {
        if (selected.some((item: any) => item.value === allOption.value)) {
          result = selected.filter(
            (option: any) => option.value !== allOption.value
          );
        } else if (event.action === "select-option") {
          result = [allOption, ...options];
        }
        return result;
      }
    }

    return selected;
  };

  const onStatusChange = async (selected: any[], event: any) => {
    const updatedValue: any[] = getSelected(selected, event);
    if (page) {
      trackEvent({
        eventName: POST_STATUS_FILTER,
        metadata: { page: page.toLocaleLowerCase() },
      });
    }
    setCurrentSelectedStatus(updatedValue);
    dispatch(
      filterCollabStatus(
        updatedValue
          ?.map((item: any) => item.value)
          .filter((item: string) => item !== "*")
      )
    );
  };

  const renderOption = (props: any): JSX.Element => {
    // @ts-ignore
    return <StatusDropdownOption {...props} />;
  };

  const popoverOption = (props: any): JSX.Element => {
    if (props.value === "*") {
      return <></>;
    }

    return (
      <PopoverOption key={props.value}>
        <OptionStatus {...props} />
      </PopoverOption>
    );
  };

  const ValueContainer = (props: any) => {
    const isSelected = currentSelectedStatus?.length;
    const isAllSelected = isSelected > options.length || !isSelected;

    const placeHolderText = isAllSelected
      ? "All post statuses selected"
      : `${currentSelectedStatus?.length} post ${
          isSelected > 1 ? "statuses" : "status"
        } selected...`;

    return (
      <PostFilterValueContainer
        valueContainerProps={props}
        selectedOptions={currentSelectedStatus}
        placeholder={placeHolderText}
        selectedPlaceholder={placeHolderText}
        popoverOption={popoverOption}
      />
    );
  };

  // back compatibility, if the post status will be updated from the posts list
  useEffect(() => {
    if (
      isFilterInitiated &&
      (currentSelectedStatus?.length < postStatusKey?.length ||
        (postStatusKey && !postStatusKey.length))
    ) {
      const shouldSelectAll = options.length === postStatusKey?.length;

      setCurrentSelectedStatus(
        shouldSelectAll
          ? [allOption, ...options]
          : options.filter((option: any) =>
              postStatusKey.includes(option.value)
            )
      );
    }
  }, [postStatusKey]);

  useEffect(() => {
    const filteredValue = currentSelectedStatus
      ? currentSelectedStatus?.filter((status: { value: string }) =>
          options.find((item) => item.value === status.value)
        )
      : [];
    const shouldSelectAll =
      options.length === filteredValue?.length || !filteredValue?.length;

    dispatch(
      filterCollabStatus(
        filteredValue
          ?.map((item: any) => item.value)
          ?.filter((item: string) => item !== "*")
      )
    );

    setCurrentSelectedStatus(
      shouldSelectAll ? [allOption, ...options] : filteredValue
    );

    // previous post status key appears next after initialization and
    // makes wrong status for the filter on first page load

    setTimeout(() => setIsFilterInitiated(true), 0);
  }, []);

  return (
    <FormThemeProvider size={FormSize.large}>
      <StatusChangeSelect
        isSearchable={false}
        isMulti={true}
        hideSelectedOptions={false}
        components={{ ValueContainer }}
        value={currentSelectedStatus}
        // @ts-ignore
        options={[allOption, ...options]}
        disabled={isLoadingRoutines}
        renderOption={renderOption}
        onChange={onStatusChange}
        useNative={true}
        noOptionsMessage={"No other statuses available"}
        menuPlacement={"auto"}
      />
    </FormThemeProvider>
  );
};

const StatusChangeSelect = styled(Select)`
  min-width: 250px;
  line-height: 38px;
  z-index: 12;

  .select__control--menu-is-open {
    .value-popover {
      display: none;
    }
  }
`;

interface ItemProps {
  isSelected: boolean;
  data: {
    color: string;
    label: string;
    disabledTooltip?: boolean;
  };
}

const StatusDropdownOption = (props: ItemProps) => {
  const {
    isSelected,
    data: { color, label, disabledTooltip },
  } = props;

  return (
    <TooltipWrapper tooltip={disabledTooltip}>
      <OptionWrapper isSelected={isSelected}>
        <Checkbox checked={isSelected} size={FormSize.large} />
        <Label>
          <OptionStatus color={color} label={label} />
        </Label>
      </OptionWrapper>
    </TooltipWrapper>
  );
};

const OptionWrapper = styled.div<{ isSelected?: boolean }>`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  grid-auto-columns: min-content;
  color: ${({ isSelected }) => (isSelected ? "black" : "#666666")};
`;

const PopoverOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  grid-auto-columns: min-content;
  color: white;
  line-height: 22px;
`;

const Label = styled.div`
  display: flex;
`;
