import React from "react";
import { renderContentProps } from "./types";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { NormalMessage, NormalMessageProps } from "./normal-message";

type Props = Pick<renderContentProps, "attachment"> & NormalMessageProps;

export const FbComment = (props: Props): JSX.Element => {
  return (
    <>
      <AttachmentView attachment={props.attachment} isLeft={props.isLeft} />
      <NormalMessage {...props} />
    </>
  );
};

const AttachmentView = ({
  attachment,
  isLeft,
}: Pick<renderContentProps, "attachment" | "isLeft">) => {
  if (!attachment) {
    return null;
  }
  if (
    attachment.media?.source &&
    (attachment.type.includes("video") ||
      attachment.type.includes("animated_image"))
  ) {
    return (
      <VideoContainer isLeft={isLeft} data-testid="attachment-video">
        <ReactPlayer
          url={attachment.media?.source}
          width="200px"
          height="200px"
          controls
        />
      </VideoContainer>
    );
  }
  if (attachment.media?.image?.src) {
    return (
      <ImagePreviewAttachment
        isLeft={isLeft}
        alt="Attachment Preview Image"
        src={attachment.media?.image?.src}
      />
    );
  }
  return null;
};

interface LeftProps {
  isLeft?: boolean;
}

const mediaBgColor = "#f8f8f8";
const VideoContainer = styled.div<LeftProps>`
  margin-left: 15px;
  margin-right: 15px;
  width: 200px;
  height: 200px;
  ${(props) => (!props.isLeft ? "align-self: flex-end;" : undefined)}
`;

const ImagePreviewAttachment = styled.img<LeftProps>`
  width: 200px;
  height: 200px;
  background-color: ${mediaBgColor};
  flex-shrink: 0;
  object-fit: contain;
  margin-left: 15px;
  margin-right: 15px;
  ${(props) => (!props.isLeft ? "align-self: flex-end;" : undefined)}
`;
