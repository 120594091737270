import React from "react";
import styled from "styled-components";
import { useFormatMessageDateTime } from "../components/date-label";

interface SystemMessageProps {
  text: string;
  createdAt: string | Date;
}

export function SystemMessage(props: SystemMessageProps): JSX.Element {
  const date = useFormatMessageDateTime(new Date(props.createdAt));

  return (
    <Container>
      <MessageText>{props.text}</MessageText>
      <DateLabel>{`${date}`}</DateLabel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  color: #c4c4c4;
  font-size: 12px;
  font-style: italic;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled.span`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 5px;
`;
const DateLabel = styled.span``;
