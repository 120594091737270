import React from "react";
import styled, { css } from "styled-components";
import ReactPlayer from "react-player";

import { openSupportChat } from "libs/support";
import { ExternalLink, Link } from "ui/Link";
import { Button, FormSize } from "ui/ecosystems/forms";

import { Center, SMSplash } from "./atoms";

const Resources = {
  Zapier: "https://zapier.com",
  HelpArticle:
    "https://help.skedsocial.com/en/articles/4035553-creating-a-workflow-on-zapier",
  VideoGuide: {
    Src: "https://vimeo.com/826079746",
    AspectRatio: 1.778,
  },
};

export function Zapier() {
  return (
    <SMSplash
      title="Zapier Custom Workflow for Library"
      subtitle="Zapier is a no-code automation tool that connects your favorite apps to Sked Library."
    >
      <Grid css="margin-top: calc(var(--spacing) * 3);">
        <div>
          <p>
            <b>
              Here’s a couple of examples of what you can do with a Sked-Zapier
              Workflow:
            </b>
          </p>
          <BulletList css="margin-top: var(--spacing);">
            <li>
              <p>
                Work with a photographer? They can upload their images to places
                like Google Drive or Dropbox, and they’ll be brought over to
                your Library automatically.
              </p>
            </li>
            <li>
              <p>
                Have a client that emails you images? Set up a zap between your
                Gmail & Sked.
              </p>
            </li>
          </BulletList>
          <Center css="margin-top: calc(var(--spacing) * 2);">
            <GoToZapier />
          </Center>
        </div>
        <div css="justify-self: center">
          <Center>
            <ReactPlayerContainer>
              <ReactPlayer
                width="100%"
                height="100%"
                url={Resources.VideoGuide.Src}
                controls
              />
            </ReactPlayerContainer>
          </Center>
          <Center css="margin-top: calc(var(--spacing) * 0.5);">
            <p css="text-align: center;">
              <b>Get your Sked Library Zapped in 5 minutes</b>
            </p>
          </Center>
        </div>
      </Grid>
      <Divider />
      <div>
        <h1 css="margin: 0; font-weight: 900; font-size: 24px; line-height: 1.31; color: #333333;">
          Here’s how it works!
        </h1>
        <NumberedList css="margin-top: var(--spacing);">
          <li>
            <p>In Zapier, hit ‘Make a Zap’</p>
          </li>
          <li>
            <p>Name your Zap</p>
          </li>
          <li>
            <p>Set a trigger (this is what kicks off the Zap)</p>
          </li>
          <li>
            <p>
              Set an action (this is the event that takes place, e.g. moving a
              file from Drive to Library)
            </p>
          </li>
          <li>
            <p>Test and review or continue (see if your Zap is working)</p>
          </li>
          <li>
            <p>And now you’re connected!</p>
          </li>
        </NumberedList>
      </div>
      <Grid css="margin-top: calc(var(--spacing) * 2);">
        <div css="line-height: 1.91">
          <p>
            Connect your Sked Library to Zapier through the button link. For
            more help read our{" "}
            <ExternalLink trusted href={Resources.HelpArticle}>
              help desk article
            </ExternalLink>{" "}
            or reach out to support to get some help with setup.
          </p>
          <Center css="margin-top: calc(var(--spacing) * 2);">
            <GoToZapier />
          </Center>
        </div>
      </Grid>
    </SMSplash>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: calc(var(--spacing) * 2);
`;

const Divider = styled.hr`
  height: 0;
  width: 100%;
  border-bottom: 1px solid #dedafe;
  margin: calc(var(--spacing) * 2) 0;
`;

const ListCss = css`
  margin: 0;
  padding-left: calc(var(--spacing) * 2);

  li:not(:first-child) {
    margin-top: calc(var(--spacing) * 0.6);
  }
`;

const NumberedList = styled.ol`
  ${ListCss}
`;

const BulletList = styled.ul`
  ${ListCss}
`;

const GoToZapier = styled(function GoToZapier({
  className,
}: {
  className?: string;
}) {
  return (
    <div className={className}>
      <Button
        as="a"
        primary
        size={FormSize.large}
        css="width: 250px;"
        href={Resources.Zapier}
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to Zapier
      </Button>
      <div css="margin-top: calc(var(--spacing) * 0.5); font-size: 14px; line-height: 1.22">
        <p>
          Get help from{" "}
          <Link as="button" onClick={() => openSupportChat()}>
            Sked Support
          </Link>
        </p>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReactPlayerContainer = styled.div`
  width: 25vw;
  height: calc(25vw / ${Resources.VideoGuide.AspectRatio});

  @media (min-width: 1600px) {
    width: 30vw;
    height: calc(30vw / ${Resources.VideoGuide.AspectRatio});
  }

  @media (min-width: 1900px) {
    width: 33vw;
    height: calc(33vw / ${Resources.VideoGuide.AspectRatio});
  }
`;
