import React, { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Microlink from "@microlink/react";
import {
  PostFieldLabel,
  PostFieldCounter,
  PlatformUsage,
} from "components/Posts/PostForm";
import { FormControl } from "ui";
import { ExternalLink } from "ui/Link";
import { FIELDS } from "constants/PostFormFields";
import { isUrl } from "utils/strings";
import _ from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { TextInput } from "ui/ecosystems/forms/inputs";
import { AlertType, Alert } from "ui/alert";

const TextInputStyled = styled(TextInput)`
  ${(props) =>
    props.disabled &&
    `
    background: #ebebe4;
  `}
`;

// https://microlink.io/docs/sdk/getting-started/styling
const MicrolinkWrap = styled.div`
  .microlink_card__iframe {
    iframe {
      max-width: 100%;
    }
  }
`;

const PostUrl = ({
  url,
  post,
  checkSetting = false,
  selectedPlatform,
  selectedAccounts,
  platforms,
  title,
  className = "",
  onChange,
  disabled = false,
}) => {
  const [value, setValue] = useState("");

  useMemo(() => {
    setValue(url || "");
  }, [url]);

  const bounce = useDebouncedCallback((value) => {
    onChange(value);
  }, 500);

  const validUrl = useMemo(() => isUrl(url), [url]);

  const skedLinkUsedForIgs = useMemo(() => {
    // Always on
    if (!checkSetting) {
      return true;
    }

    // Only check for IG
    const hasIG = _.filter(platforms, ["TYPE", "IG"]).length > 0;
    if (!hasIG) {
      return true;
    }

    let settingOn = true;
    selectedAccounts.map((account) => {
      const isIG =
        account.platformType === undefined || account.platformType === "IG";
      // show the prompt with information about Sked Link if user is not using it in their bio
      if (
        isIG &&
        account.igData &&
        account.igData.website &&
        !account.igData.website.includes("sked.link")
      ) {
        settingOn = false;
      }
    });
    return settingOn;
  }, [selectedAccounts]);

  return (
    <div className={className}>
      <div className="tw-flex tw-flex-col tw-mb-4">
        <PostFieldLabel className="tw-flex tw-items-center tw-mb-2">
          <div className="tw-mr-2">
            {typeof title === "function" ? title() : title}
          </div>
          <PlatformUsage
            platforms={platforms}
            field={FIELDS.URL}
            selectedPlatform={selectedPlatform}
          />
        </PostFieldLabel>
        {/* Show URL prompt */}
        {!skedLinkUsedForIgs && (
          <Alert type={AlertType.info} icon={true}>
            <b>Add a post URL for Instagram:</b> Use our link in bio tool to
            link followers to the right place.{" "}
            <a href="/sked-link-sso" target="_blank">
              Get started now
            </a>{" "}
            or find out more about{" "}
            <ExternalLink href="https://help.skedsocial.com/en/articles/3811771-getting-started-with-sked-link">
              Sked's link in bio tool
            </ExternalLink>
            .
          </Alert>
        )}
        {/* Show field for all platforms */}
        <>
          <TextInputStyled
            value={value}
            disabled={disabled}
            onChange={(value) => {
              const newVal = value.trim();
              setValue(newVal);
              bounce(newVal);
            }}
            placeholder="https://"
          />
          {url && validUrl && (
            <MicrolinkWrap className="tw-my-4">
              <Microlink
                url={url}
                className="tw-m-0"
                apiKey={"JgUARf2HjedOQkin6wQs2Ll11upPpWG5gJ1NTX9c"}
              />
            </MicrolinkWrap>
          )}
        </>
      </div>
    </div>
  );
};

export default PostUrl;
