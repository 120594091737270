import styled, { css } from "styled-components";
import icon from "./video.image.svg";

export const SmallMediaFilePreview = styled.div<{
  src: string;
  isVideo: boolean;
}>`
  width: var(--itemHeight);
  height: var(--itemHeight);
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;

  position: relative;
  ${(props) =>
    props.isVideo &&
    css`
      :after {
        content: "";
        background-image: url(${icon});
        background-position: center;
        width: 14px;
        height: 10px;
        position: absolute;
        top: 4px;
        right: 3px;
      }
    `}
`;
