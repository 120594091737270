import * as React from "react";
import styled from "styled-components";
import GridMediaCard from "components/MediaLibrary/GridMediaCard/GridMediaCard";
import { CollectionFile } from "shared/types/files";
import { MediaCollection } from "api/media-library";

interface Props {
  files: CollectionFile[];
  selectedMediaSet: Map<string, CollectionFile>;
  onSelect(file: CollectionFile): void;
  onRemove(file: CollectionFile): void;
  selectedCollection: MediaCollection | null;
}

export function CollectionFilesList(props: Props) {
  const { files, onSelect, onRemove, selectedMediaSet, selectedCollection } =
    props;

  const firstSelectedExternalMedia = React.useMemo(() => {
    // if a collection is selected, will not show the tooltip
    // return null will skip the calculation
    return selectedCollection
      ? null
      : Array.from(selectedMediaSet.values()).find(
          (item) => item.picker !== "library"
        );
  }, [selectedCollection, selectedMediaSet]);

  return (
    <List data-testid="collectionFilesList">
      {files.map((file, key) => {
        const isFileSelected = selectedMediaSet.has(file._id);
        const showCollectionTooltip =
          isFileSelected && firstSelectedExternalMedia?._id === file._id;

        if (!file.thumbnail && !file.downloadUrl) {
          return <></>;
        }

        return (
          <StyledGridMediaCard
            key={file._id}
            order={key + 1}
            assetUrl={file.thumbnail || file.downloadUrl}
            canFavorite={false}
            selected={isFileSelected}
            isVideo={file.meta.isVideo}
            videoPreviewUrl={file.gifThumbnail}
            showCollectionTooltip={showCollectionTooltip}
            onCardClick={() =>
              isFileSelected ? onRemove(file) : onSelect(file)
            }
          />
        );
      })}
    </List>
  );
}
const List = styled.div`
  max-height: 475px;
  overflow-y: auto;
  --grid-gap: 8px;
  --cell-size: calc(
    (
        var(--modal-width) - 8 * 3px - var(--form-h-padding) * 2 -
          var(--grid-gap) * 4
      ) / 5
  );
  min-height: calc(var(--cell-size) * 2 + var(--grid-gap));

  display: grid;
  grid-template-columns: repeat(5, var(--cell-size));
  grid-auto-rows: var(--cell-size);
  grid-gap: var(--grid-gap);
  align-items: start;
`;

const StyledGridMediaCard = styled(GridMediaCard)`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;
