export const BUTTON_TYPES = {
  PRIMARY: "primary",
  PRIMARY_OUTLINE: "primary_outline",
  ALERT: "alert",
  ALERT_OUTLINE: "alert_outline",
  GHOST: "ghost",
  SIMPLE: "simple",
};

export const BULK_ACTIONS = {
  QUEUE: "queue",
  DRAFT: "draft",
  TRASH: "trash",
  SHUFFLE_QUEUE: "shuffle",
};
