// The value is the value that is found on a post body
export const FIELDS = {
  ALT_TEXT: "altText",
  MEDIA: "mediaFiles",
  CAPTION: "caption",
  FIRST_COMMENT: "firstcomment",
  URL: "redirectUrl",
  STORY_URL: "storyUrl",
  STORY_LABEL: "storyLabel",
  STORY_STICKER_HEIGHT: "storyStickerHeight",
  LOCATION_TAG: "location",
  COLLABORATOR_TAG: "collaboratingIgUsers",
  USER_TAGS: "tags",
  PRODUCT_TAGS: "productTags",
  POST_STATUS: "postStatusKey",
  IS_STORY: "isStory",
  PUBLISH_STORY_MANUALLY: "publishStoryManually",
  STORY_TYPE: "storyType",
  SCHEDULED_FOR: "scheduledFor",
  WHEN: "when",
  IS_NOW: "isNow",
  POST_STATE: "postState", // This isn't on the actual post body but does keep track of the state, ie (draft, queue, scheduled)
  TYPE: "type",
  ACCOUNTS: "accounts",
  //GMB SPECIFIC FIELDS
  GMB_TOPIC_TYPE: "gmbTopicType",
  GMB_CTA: "gmbCallToAction.actionType",
  GMB_TITLE: "gmbEvent.title",
  GMB_START_DATE: "gmbEvent.startDate",
  GMB_END_DATE: "gmbEvent.endDate",
  GMB_TERMS: "gmbOffer.termsConditions",
  GMB_COUPON_CODE: "gmbOffer.couponCode",
  GMB_EVENT: "gmbEvent",
  GMB_OFFER: "gmbOffer",
  YT_TITLE: "ytTitle",
  YT_CATEGORY: "ytCategoryId",
  YT_TAGS: "ytTags",
  YT_PRIVACY: "ytPrivacy",
  PI_BOARDS: "piBoards",
  PI_SECTION_ID: "piSectionId",
  PI_TITLE: "piTitle",
  LI_TARGET_ENTITIES: "liTargetEntities",
  POST_IS_NOTIFICATION_ONLY: "postOptions.isNotification",
  TT_ALLOW_COMMENTS: "postOptions.allowComments",
  TT_ALLOW_STITCH: "postOptions.allowStitch",
  TT_ALLOW_DUET: "postOptions.allowDuet",
  TT_TITLE: "postOptions.title",
  TT_AUTO_ADD_MUSIC: "postOptions.autoAddMusic",
  TT_PRIVACY_LEVEL: "postOptions.privacyLevel",
  TT_PHOTO_COVER_INDEX: "postOptions.photoCoverIndex",
  TH_REPLY_CONTROL: "postOptions.replyControl",
  CONTENT_LABELS: "labelIds",
  VIDEO_COVER_IMAGE_TIMESTAMP: "videoCoverImageTimestamp",
  VIDEO_COVER_PREVIEW: "videoCoverPreview",
  VIDEO_COVER_ORIGINAL: "videoCoverOriginal",
  THUMBNAILURL: "thumbnailUrl",
};

export const FIELDS_DEFAULT = {
  IS_NOTIFICATION_POST_ONLY_DEFAULT: false,
  TT_ALLOW_COMMENTS: true,
  TT_ALLOW_STITCH: true,
  TT_ALLOW_DUET: true,
  TH_REPLY_CONTROL: "everyone",
  TT_AUTO_ADD_MUSIC: true,
  TT_PRIVACY_LEVEL: "PUBLIC_TO_EVERYONE",
  TT_PHOTO_COVER_INDEX: 0,
};

// Key is the value that we pass back to the server
export const IG_STORY_TYPES = {
  STORY: {
    VALUE: "story",
    LABEL: "Story",
  },
  REEL: {
    VALUE: "reel",
    LABEL: "Reel (via Notification)",
  },
};

export const GMB_TOPICS = {
  STANDARD: {
    VALUE: "STANDARD",
    LABEL: "Standard",
  },
  OFFER: {
    VALUE: "OFFER",
    LABEL: "Offer",
  },
  EVENT: {
    VALUE: "EVENT",
    LABEL: "Event",
  },
  ALERT: {
    VALUE: "ALERT",
    LABEL: "Alert",
  },
};
export const GMB_CTAS = {
  BOOK: {
    VALUE: "BOOK",
    LABEL: "Book now",
  },
  ORDER: {
    VALUE: "ORDER",
    LABEL: "Order",
  },
  SHOP: {
    VALUE: "SHOP",
    LABEL: "Shop now / browse catalog",
  },
  LEARN_MORE: {
    VALUE: "LEARN_MORE",
    LABEL: "Learn more",
  },
  SIGN_UP: {
    VALUE: "SIGN_UP",
    LABEL: "Sign up",
  },
  CALL: {
    VALUE: "CALL",
    LABEL: "Call",
  },
};
