import { css } from "styled-components";

export function aspectRatio(ratio: number) {
  return css`
    @supports (aspect-ratio: 19/2) {
      aspect-ratio: ${ratio};
    }

    @supports not (aspect-ratio: ${ratio}) {
      :before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
      }
      :after {
        content: "";
        display: table;
        clear: both;
      }
    }
  `;
}
