import CreatableSelect from "react-select/creatable";
import React from "react";
import styled from "styled-components";
import { splitAccounts } from "utils/accounts";
import noop from "lodash/noop";

// TODO: SHOULD_DELETE_ON_3.50

/** NOTE: using this hack because TS will complain with this error:
 * `The types returned by 'render()' are incompatible between these types.`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CreatableSelectUnTyped = CreatableSelect as any;

interface Props {
  accounts: GroupableAccount[];
  groupName: string;
  onGroupNameChange(value: string): void;
}

interface SelectOption {
  value: string;
  label: string;
}

export const GroupName = ({
  groupName,
  onGroupNameChange,
  accounts,
}: Props) => {
  const handleChange = (selectedOption: SelectOption) => {
    onGroupNameChange(selectedOption?.value);
  };
  return (
    <CreatableSelectUnTyped
      styles={{
        container: (base: React.CSSProperties) => ({ ...base, width: "100%" }),
        menuList: (base: React.CSSProperties) => ({ ...base, fontSize: 12 }),
        control: (base: React.CSSProperties, state: { isFocused: boolean }) => {
          const stateOnFocus = state.isFocused && {
            border: "1px solid #4f31ff",
          };
          return {
            ...base,
            outline: "none",
            boxShadow: "none",
            ...stateOnFocus,
            "&:hover": {
              ...stateOnFocus,
            },
          };
        },
      }}
      customValue={groupName}
      onCustomValueChange={onGroupNameChange}
      name="groupName"
      options={getAllGroupNames(accounts).map((c) => ({ value: c, label: c }))}
      placeholder="Group name (i.e. Your Brand, Nike.)"
      onChange={handleChange}
      value={null}
      noOptionsMessage={() => "No group created yet."}
      components={{
        Input: CustomInput,
        SingleValue: function SingleValue() {
          return <></>;
        },
        Placeholder: function Placeholder() {
          return <></>;
        },
      }}
    />
  );
};

interface CustomInputProps {
  selectProps: {
    customValue: string;
    onCustomValueChange: (value: string) => void;
    placeholder: string;
  };
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const BACKSPACE_KEY_CODE = 8;
const ENTER_KEY_CODE = 13;

/** NOTE: why do we need to create this custom input?
 * Because we want to allow user freely change the group name. But this is not supported by react-select library
 *  https://github.com/JedWatson/react-select/issues/1868
 */
export const CustomInput = (props: CustomInputProps) => {
  const handleKeyInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === BACKSPACE_KEY_CODE) {
      e.stopPropagation();
    }
    if (e.keyCode === ENTER_KEY_CODE) {
      e.preventDefault();
    }
  };
  return (
    <GroupInput
      disabled={props.disabled || undefined}
      value={props.selectProps.customValue || ""}
      placeholder={props.selectProps.placeholder}
      onFocus={props.onFocus || noop}
      onBlur={props.onBlur || noop}
      onKeyDown={handleKeyInput}
      onKeyPress={handleKeyInput}
      onChange={(e) => {
        props.selectProps.onCustomValueChange(e.target.value);
        props.onChange && props.onChange(e);
      }}
    />
  );
};

interface GroupableAccount {
  loginAlias?: string;
}

function getAllGroupNames(accounts: GroupableAccount[]) {
  const { groupedAccounts } = splitAccounts(accounts);
  return groupedAccounts.map((c) => c.title);
}

const GroupInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  width: 100%;
`;
