import React from "react";
import styled from "styled-components";

export function AssetUsedIn(props: { usedIn: any[] }) {
  return (
    <AssetUse>
      <Title>Asset Use</Title>
      <List>
        <ListItem>
          <TitleCell>Account</TitleCell>
          <TitleCell>Status</TitleCell>
          <TitleCell>View on Sked</TitleCell>
        </ListItem>
        {props.usedIn.map((item: any) => (
          <ListItem key={item.id}>
            <Cell>
              <Logo src={item.profileImage} alt="Account Logo" />
              {item.account_name}
            </Cell>
            <Cell>{item.post_status}</Cell>
            <Cell>
              <a href="#">Link</a>
            </Cell>
          </ListItem>
        ))}
      </List>
    </AssetUse>
  );
}

const List = styled.div``;
const Logo = styled.img`
  border-radius: 50%;
  margin-right: 10px;
`;

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 16px;
  line-height: 19px;
`;
const AssetUse = styled.div`
  margin-top: 20px;
`;
const Title = styled.div`
  font-weight: 700;
`;
const TitleCell = styled.div`
  color: #454545;
`;
