import { HEADER_SUPPORT_CLICK } from "constants/Events";
import { useTrackEvent } from "libs/analytics";

export function useOpenIntercomChat(intercomMessage?: string) {
  const trackEvent = useTrackEvent();
  const openHelp = (): void => {
    console.log("open help");
    trackEvent({
      eventName: HEADER_SUPPORT_CLICK,
      useServices: ["AMP"],
    });
    if (intercomMessage) {
      window.Intercom("showNewMessage", intercomMessage);
    } else {
      window.Intercom("showNewMessage");
    }
  };

  return openHelp;
}
