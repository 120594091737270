import React from "react";
import EyeWithCross from "./eye-with-cross.svg";

export function Eye({
  className,
  crossed,
}: {
  className?: string;
  crossed: boolean;
}) {
  // TODO: animate eye-cross apperance?
  return (
    <EyeWithCross
      className={className}
      crossed={crossed}
      css={`
        .eye-cross {
          opacity: ${(props: { crossed: boolean }) =>
            props.crossed ? "1" : "0"};
        }
      `}
    />
  );
}
