import React from "react";
import { useSelector } from "react-redux";
import {
  PostTopicType,
  PostCallToAction,
  PostTopicTitle,
  PostEventDates,
  PostCoupon,
  PostTerms,
} from "./";
import { getFormErrors } from "selectors/postFormSelectors";
import { FIELDS } from "constants/PostFormFields";

const GmbFields = ({
  post,
  selectedPlatform,
  platforms,
  onChange,
  className = "",
  disabled = false,
}) => {
  const topicType = post[FIELDS.GMB_TOPIC_TYPE] || null;
  const errors = useSelector((state) => getFormErrors(state, post._id));
  return (
    <div className={className}>
      <PostTopicType
        className="tw-mb-4"
        topicType={topicType}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
      <PostCallToAction
        className="tw-mb-4"
        topicType={topicType}
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
      <PostTopicTitle
        className="tw-mb-4"
        topicType={topicType}
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
      <PostEventDates
        className="tw-mb-4"
        topicType={topicType}
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
      <PostCoupon
        className="tw-mb-4"
        topicType={topicType}
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={onChange}
        disabled={disabled}
      />
      <PostTerms
        className="tw-mb-4"
        topicType={topicType}
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default GmbFields;
