import styled from "styled-components";
import { InputWrapper } from "ui/ecosystems/forms/_input-base-wrapper";

export const Selector = styled(InputWrapper)<{ isOpened: boolean }>`
  position: relative;
  padding-right: 30px;
  :before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 16px;

    width: 7px;
    height: 7px;
    border-radius: 0 0 2px 0;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #c4c4c4;
  }
`;
