import { SuggestOption } from "./SuggestOption.type";

export function getDisplayText(option: SuggestOption) {
  switch (option.type) {
    case "tag":
      return option.value;
    case "template":
    case "fb-page":
    case "li-organization":
    case "collaborator":
      return option.name;
  }
}

export function getInsertText(option: SuggestOption) {
  switch (option.type) {
    case "tag":
      return option.value;
    case "template":
      return option.template;
    case "fb-page":
      return `[${option.id}](${option.name})`;
    case "li-organization":
      return `[${option.urn}](${option.name})`;
    case "collaborator":
      return option.name;
    default:
      return "";
  }
}
