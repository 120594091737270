import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { Dropdown, SelectAbleItem } from "ui/ecosystems/dropdown";
import { Button } from "ui/ecosystems/forms";
import {
  CheckBoxFilter,
  SelectFilter,
  TagsFilter,
  TextFilter,
} from "ui/ecosystems/filters";

import { MediaFilters, MediaSource } from "shared/types/files";
// TODO: most probably should not export from features/media-library here
// need to discuss with Ilya Agarkov to find out the best aproach
import { LibraryPayWall } from "features/media-library/components/library-pay-wall";
import { useCurrentUser } from "entities/current-user";
import { ALL_FILTERS_DATA, ALL_FILTERS_TYPE } from "constants/MediaLibrary";

interface useMediaFilesFiltersProps {
  values: MediaFilters;
  tags: string[];

  onChange(value: any): void;

  onClear(): void;
}

type FilterType = keyof MediaFilters;

export interface MediaFilesFiltersProps {
  values: MediaFilters;
  tags: string[];
  availableFilters?: typeof ALL_FILTERS_TYPE;
  filtersList: (keyof MediaFilters)[];

  onAdd(name: FilterType): void;

  onChange(filterName: FilterType, value: unknown): void;

  onRemove(filterName: FilterType, value: unknown): void;

  onClear(): void;
}

export function MediaFilesSelectedFilters(props: MediaFilesFiltersProps) {
  // filtersList - active/selected filters
  const { filtersList, onChange, onRemove, onClear } = props;
  const user = useCurrentUser();
  const usageOptions =
    user.role === "admin" ? usageAdminOptions : usageCollaboratorOptions;

  return (
    <>
      {filtersList.map((filter) => {
        const filterProps = {
          key: filter,
          value: props.values[filter],
          onChange: (value: unknown) => onChange(filter, value),
          onRemove: (value: unknown) => onRemove(filter, value),
        };
        switch (filter) {
          case "name":
            return (
              <TextFilter
                {...filterProps}
                label="Name"
                placeholder="Asset name"
              />
            );
          case "description":
            return (
              <TextFilter
                {...filterProps}
                label="Description"
                placeholder="Asset description"
              />
            );
          case "sourceName":
            return (
              <SelectFilter
                {...filterProps}
                options={sourceNameOptions}
                label="Source"
                placeholder="Select Source "
              />
            );
          case "tags":
            return (
              <TagsFilter
                {...filterProps}
                label="Tags"
                placeholder="Select Tags"
                tags={props.tags}
              />
            );
          case "excludeAccounts":
            return (
              <TagsFilter
                {...filterProps}
                label="Exclude Accounts"
                placeholder="Select Accounts"
                tags={props.tags}
              />
            );
          case "favorite":
            return <CheckBoxFilter {...filterProps} label="Starred" />;
          case "mediaType":
            return (
              <SelectFilter
                {...filterProps}
                options={fileTypeOptions}
                label="File type"
                placeholder="Select File Type "
              />
            );
          case "usage":
            return (
              <SelectFilter
                {...filterProps}
                options={usageOptions}
                label="Usage"
                formatLabel={(value: string) =>
                  `Used: ${
                    usageOptions?.find((item) => item.value === value)?.label
                  }`
                }
                placeholder="Select if used"
              />
            );
          default:
            return null;
        }
      })}

      {filtersList.length > 0 && (
        <Button data-testid="filterClearAllButton" text onClick={onClear}>
          Clear all
        </Button>
      )}
    </>
  );
}

export function MediaFilesFilters(props: MediaFilesFiltersProps) {
  // filtersList - active/selected filters
  const { filtersList, onAdd, availableFilters = ALL_FILTERS_TYPE } = props;

  const filters = useMemo(
    () =>
      ALL_FILTERS_DATA.filter((filter) => {
        return availableFilters.includes(filter.type);
      }),
    [availableFilters]
  );

  return (
    <FiltersRoot>
      {filtersList.length < filters.length && (
        <Dropdown
          renderTrigger={({ openAbleState }) => (
            <AddButton
              data-testid="media-library-filters-add-button"
              primary={openAbleState.isOpened}
              outline={!openAbleState.isOpened}
              onClick={openAbleState.open}
            >
              + Filters
            </AddButton>
          )}
        >
          {filters
            .filter((filter) => !filtersList.includes(filter.type))
            .map((filter) => (
              <LibraryPayWall
                shouldBeNotDisabled={filter.free}
                key={filter.type}
              >
                {({ isDisabled }) => (
                  <SelectAbleItem
                    data-testid={`media-library-filters-${filter.type}`}
                    onClick={() => onAdd(filter.type)}
                    disabled={isDisabled}
                  >
                    {filter.label}
                  </SelectAbleItem>
                )}
              </LibraryPayWall>
            ))}
        </Dropdown>
      )}
    </FiltersRoot>
  );
}

export function useMediaFilesFilters(
  props: useMediaFilesFiltersProps
): [Omit<MediaFilesFiltersProps, "availableFilters">, { clear(): void }] {
  const [filtersList, { add, remove, clear }] = useFiltersList(props.values);

  function handleChange(filterName: FilterType, value: unknown) {
    props.onChange({
      ...props.values,
      [filterName]: value,
    });
  }

  function handleRemove(filterName: FilterType, value: unknown) {
    handleChange(filterName, value);
    remove(filterName);
  }

  function handleClear() {
    props.onClear();
    clear();
  }

  return [
    {
      values: props.values,
      tags: props.tags,
      filtersList,
      onAdd: add,
      onChange: handleChange,
      onRemove: handleRemove,
      onClear: handleClear,
    },
    { clear: handleClear },
  ];
}

function useFiltersList(presettedFilters: MediaFilters): [
  FilterType[],
  {
    add(value: FilterType): void;
    remove(value: FilterType): void;
    clear(): void;
  }
] {
  const [filters, setFilters] = useState<FilterType[]>(() => {
    if (!presettedFilters) {
      return [];
    }

    const defaultFilters: FilterType[] = [];

    for (const key in presettedFilters) {
      if (
        (!/sort|tags|excludeAccounts/.test(key) && presettedFilters[key]) ||
        (key === "tags" && presettedFilters[key].length) ||
        (key === "excludeAccounts" && presettedFilters[key].length)
      ) {
        defaultFilters.push(key as FilterType);
      }
    }
    return defaultFilters;
  });

  function add(name: FilterType) {
    if (!filters.includes(name)) {
      setFilters([...filters, name]);
    }
  }

  function remove(name: FilterType) {
    setFilters(filters.filter((filter) => filter !== name));
  }

  function clear() {
    setFilters([]);
  }

  return [filters, { add, remove, clear }];
}

const AddButton = styled(Button)`
  height: 27px;
  padding: 0 10px;
`;

const FiltersRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-right: 8px;
  }
`;

const sourceNameOptions = [
  {
    label: "Uploaded to library",
    value: MediaSource.upload,
  },
  {
    label: "Instagram feed (unknown)",
    value: MediaSource.feed,
  },
  {
    label: "Instagram Hashtag",
    value: MediaSource.instagramHashTags,
  },
  {
    label: "Instagram Tagged in",
    value: MediaSource.instagramTagged,
  },
  {
    label: "Zapier",
    value: MediaSource.zapier,
  },
  {
    label: "Chrome extension",
    value: MediaSource.chromeExtension,
  },
];

export const fileTypeOptions = [
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Document/PDF",
    value: "document",
  },
];

export const usageAdminOptions = [
  {
    label: "Used",
    value: "used",
  },
  {
    label: "Unused",
    value: "unused",
  },
  {
    label: "All",
    value: null,
  },
];

export const usageCollaboratorOptions = [
  {
    label: "Used (all accounts)",
    value: "used",
  },
  {
    label: "Unused (all accounts)",
    value: "unused",
  },
  {
    label: "Used (my accounts)",
    value: "used_own",
  },
  {
    label: "Unused (my accounts)",
    value: "unused_own",
  },
  {
    label: "All",
    value: null,
  },
];
