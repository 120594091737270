import { useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";
import { RequiredFacebookScopes } from "api/social-account-connect/facebook";
import { AccountType } from "shared/types/accounts";

import {
  getAccountPlatformType,
  isAccountFacebookPage,
} from "./account-platform";
import { ONLY_MANUAL_PLATFORMS } from "constants/Platforms";
import {
  isAdsFeatureOn,
  isAdsPermissionRequired,
} from "libs/account-permission-check";
import { PlatformNameToPlatformTypeMap } from "features/post/format-post";

export interface AccountStatusType {
  type:
    | "Paused"
    | "Posting"
    | "Insights"
    | "Queue"
    | "Sked Link"
    | "Inbox"
    | "Ads";
  icon: "on" | "off" | "pause" | "questionmark";
  tooltip?: string;
}

export function getAccountStatuses(account: AccountType): AccountStatusType[] {
  const platformType = getAccountPlatformType(account);
  let getter = GetAccountStatuses[platformType];
  // non FB or IG accounts
  if (!getter) {
    getter = GetAccountStatuses["__other__"];
  }

  const isTikTokAccount = platformType === PlatformNameToPlatformTypeMap.tiktok;
  const isManualTT = isTikTokAccount && !account.platformId;
  const isThreadsAccount =
    platformType === PlatformNameToPlatformTypeMap.threads;
  const isManualTH = isThreadsAccount && !account.platformId;

  // override for "notification" account types
  if (
    ONLY_MANUAL_PLATFORMS.includes(platformType) ||
    isManualTT ||
    isManualTH
  ) {
    getter = GetAccountStatuses["__notification__"];
  }
  return getter(account);
}

export function isAccountPostingAndInsightsEnabled(
  account: AccountType
): boolean {
  const platformType = getAccountPlatformType(account);
  if (isAccountFacebookPage(account)) {
    return getIsFbPostingOn(account) && getFBInsightsStatusOn(account);
  } else if (platformType === "IG") {
    return account.status === "ok" && getIGInsightsStatusOn(account);
  } else {
    return account.status === "ok";
  }
}

/** TODO: duplicated code with public/components/app.js file  */
export function isLinkedInNewPermissionRequired(acc: AccountType) {
  if (!acc) {
    return false;
  }

  const requiredLiScopes = [
    "w_organization_social_feed",
    "r_organization_social_feed",
  ];
  const isLinkedInCompanyProfile = (acc.linkedinUrn || "").includes(
    "organization"
  );
  if (
    acc.platformType !== "LI" ||
    !isLinkedInCompanyProfile ||
    isPausedAccount(acc)
  ) {
    return false;
  }

  const scopes = acc.session?.scopes || [];
  return requiredLiScopes.some((scope) => !scopes.includes(scope));
}

export function isTiktokNewPermissionRequired(acc: AccountType) {
  if (!acc) {
    return false;
  }

  if (
    acc.platformType !== "TT" ||
    !acc.platformId || // old TT notification channel has no platformId
    isPausedAccount(acc)
  ) {
    return false;
  }

  const requiredTTScopes = ["user.info.stats"];

  // @ts-ignore
  const scopes = acc.session?.scope || "";
  return !requiredTTScopes.every((scope) => scopes.includes(scope));
}

export function doesAccountNeedApiReconnect(account: AccountType): boolean {
  const platformType = getAccountPlatformType(account);
  if (isAccountFacebookPage(account)) {
    return !(
      getIsFbPostingOn(account) &&
      getFBInsightsStatusOn(account) &&
      getFBInboxStatusOn(account)
    );
  } else if (platformType === "IG") {
    return !(getIGInsightsStatusOn(account) && getIGInboxStatusOn(account));
  } else {
    return false;
  }
}

const getIsFbPostingOn = (account: AccountType) => {
  let isFbPostingOn =
    account.status === "ok" &&
    RequiredFacebookScopes.posting.every((scope) => {
      return account.session?.scopes?.includes(scope);
    });
  // if it's a group it should have publish_to_groups
  if (account.isGroup) {
    // @ts-ignore
    isFbPostingOn =
      account.status === "ok" &&
      account.session?.scopes?.includes("publish_to_groups");
  }
  return isFbPostingOn;
};
const getFBInsightsStatusOn = (account: AccountType) => {
  return (
    account.status === "ok" &&
    RequiredFacebookScopes.insights.every((scope) => {
      return account.session?.scopes?.includes(scope);
    })
  );
};
const getFBInboxCommentsStatusOn = (account: AccountType) => {
  return (
    account.status === "ok" &&
    ["pages_manage_engagement"].every((scope) => {
      return account.session?.scopes?.includes(scope);
    })
  );
};

const getFBInboxStatusOn = (account: AccountType) => {
  return (
    account.status === "ok" &&
    RequiredFacebookScopes.inbox.every((scope) => {
      return account.session?.scopes?.includes(scope);
    })
  );
};

const getIGInsightsStatusOn = (account: AccountType) => {
  return !!(account.igTokenAddedAt && !account.promptApiReconnect);
};

const getIGInboxStatusOn = (account: AccountType) => {
  // showing inbox enabled IG account only needs "instagram_manage_messages"
  return ["instagram_manage_messages"].every((scope) => {
    return account.session?.scopes?.includes(scope);
  });
};

const getIGInboxCommentsStatusOn = (account: AccountType) => {
  // showing inbox enabled IG account only needs "instagram_manage_messages"
  return ["instagram_manage_comments"].every((scope) => {
    return account.session?.scopes?.includes(scope);
  });
};

const GetAccountStatuses = {
  FB: checkPausedEnhancer((account: AccountType): AccountStatusType[] => {
    const isFbPostingOn = getIsFbPostingOn(account);
    const isFbInsightsOn = getFBInsightsStatusOn(account);
    const isFBInboxOn = getFBInboxStatusOn(account);
    const isFacebookPage = isAccountFacebookPage(account);
    const user = useSelector(getUser);

    const statuses: AccountStatusType[] = [
      {
        type: "Posting",
        icon: isFbPostingOn ? "on" : "off",
      },
    ];
    if (isFacebookPage) {
      statuses.push({
        type: "Insights",
        icon: isFbInsightsOn ? "on" : "off",
      });
    }
    statuses.push({
      type: "Queue",
      icon: account.queueEnabled ? "on" : "questionmark",
      tooltip: account.queueEnabled
        ? "The queue is set up and active for this account. (Optional)"
        : "You have not set up the queue for this account yet. Check out 'queue' on the right! (Optional)",
    });

    const showInboxComments = true;

    if (isFacebookPage && showInboxComments) {
      const isFBInboxCommentsOn = getFBInboxCommentsStatusOn(account);

      statuses.push({
        type: "Inbox",
        icon: isFBInboxCommentsOn ? "on" : "questionmark",
        tooltip: isFBInboxCommentsOn
          ? "Sked Inbox is setup for this account"
          : "Please click the Reconnect Inbox/Account button to update permissions for Inbox Comments",
      });
    } else if (isFacebookPage) {
      statuses.push({
        type: "Inbox",
        icon: isFBInboxOn ? "on" : "off",
        tooltip: isFBInboxOn
          ? "Sked Inbox is setup for this account"
          : "Please click the Reconnect Inbox/Account button to update permissions for Inbox",
      });
    }

    if (isAdsFeatureOn(account, user)) {
      const isAdsPermissionExisted = !isAdsPermissionRequired(account, user);
      statuses.push({
        type: "Ads",
        icon: isAdsPermissionExisted ? "on" : "off",
        tooltip: isAdsPermissionExisted
          ? "Meta Ads is setup for this account"
          : "Account is disconnected from Meta Ads. Reconnect to update your permissions.",
      });
    }
    return statuses;
  }),
  IG: checkPausedEnhancer((account: AccountType): AccountStatusType[] => {
    const isIGInboxOn = getIGInboxStatusOn(account);
    const user = useSelector(getUser);

    const statuses: AccountStatusType[] = [
      {
        type: "Posting",
        icon: account.status === "ok" ? "on" : "off",
      },
      {
        type: "Insights",
        icon: getIGInsightsStatusOn(account) ? "on" : "off",
      },
      {
        type: "Queue",
        icon: account.queueEnabled ? "on" : "questionmark",
        tooltip: account.queueEnabled
          ? "The queue is set up and active for this account. (Optional)"
          : "You have not set up the queue for this account yet. Check out 'queue' on the right! (Optional)",
      },
      {
        type: "Sked Link",
        icon:
          account?.igData?.website &&
          account?.igData?.website.includes("sked.link")
            ? "on"
            : "questionmark",
        tooltip:
          account?.igData?.website &&
          account?.igData?.website.includes("sked.link")
            ? "Sked Link is set up for this account. (Optional)"
            : "You have not set up Sked Link for this account yet. Head to 'link in bio' in the menu! (Optional)",
      },
    ];

    const showInboxComments = true;

    if (showInboxComments) {
      const isIGInboxCommentsOn = getIGInboxCommentsStatusOn(account);

      statuses.push({
        type: "Inbox",
        icon: isIGInboxCommentsOn ? "on" : "questionmark",
        tooltip: isIGInboxCommentsOn
          ? "Sked Inbox is set up for this account"
          : "Please click the Reconnect Insights/Inbox button to update permissions for  Inbox Comments",
      });
    } else {
      statuses.push({
        type: "Inbox",
        icon: isIGInboxOn ? "on" : "off",
        tooltip: isIGInboxOn
          ? "Sked Inbox is set up for this account"
          : "Please click the Reconnect Insights/Inbox button to update permissions for Inbox",
      });
    }

    return statuses;
  }),
  TH: checkPausedEnhancer((account: AccountType): AccountStatusType[] => {
    return [
      {
        type: "Posting",
        icon: account.status === "ok" ? "on" : "off",
        //tooltip: "Posting will be available soon for Threads accounts.",
      },
      {
        type: "Insights",
        //icon: !isThreadsNewPermissionRequired(account) ? "on" : "off",
        icon: "off",
        tooltip: "Insights are not available for Threads accounts.",
      },
      {
        type: "Queue",
        icon: account.queueEnabled ? "on" : "questionmark",
        tooltip: account.queueEnabled
          ? "The queue is set up and active for this account. (Optional)"
          : "You have not set up the queue for this account yet. Check out 'queue' on the right! (Optional)",
      },
    ];
  }),
  TT: checkPausedEnhancer((account: AccountType): AccountStatusType[] => {
    return [
      {
        type: "Posting",
        icon: account.status === "ok" ? "on" : "off",
      },
      {
        type: "Insights",
        icon: !isTiktokNewPermissionRequired(account) ? "on" : "off",
      },
      {
        type: "Queue",
        icon: account.queueEnabled ? "on" : "questionmark",
        tooltip: account.queueEnabled
          ? "The queue is set up and active for this account. (Optional)"
          : "You have not set up the queue for this account yet. Check out 'queue' on the right! (Optional)",
      },
    ];
  }),
  // Notification accounts don't publish automatically so only show queue option
  __notification__: checkPausedEnhancer(
    (account: AccountType): AccountStatusType[] => {
      return [
        {
          type: "Queue",
          icon: account.queueEnabled ? "on" : "questionmark",
          tooltip: account.queueEnabled
            ? "The queue is set up and active for this account. (Optional)"
            : "You have not set up the queue for this account yet. Check out 'queue' on the right! (Optional)",
        },
      ];
    }
  ),
  __other__: checkPausedEnhancer(
    (account: AccountType): AccountStatusType[] => {
      return [
        {
          type: "Posting",
          icon: account.status === "ok" ? "on" : "off",
        },
        {
          type: "Queue",
          icon: account.queueEnabled ? "on" : "questionmark",
          tooltip: account.queueEnabled
            ? "The queue is set up and active for this account. (Optional)"
            : "You have not set up the queue for this account yet. Check out 'queue' on the right! (Optional)",
        },
      ];
    }
  ),
};

function checkPausedEnhancer(
  getter: (account: AccountType) => AccountStatusType[]
): (account: AccountType) => AccountStatusType[] {
  return (account: AccountType): AccountStatusType[] => {
    if (isPausedAccount(account)) {
      return [{ type: "Paused", icon: "pause" }];
    } else {
      return getter(account);
    }
  };
}

export function isPausedAccount(account: AccountType): boolean {
  return (
    account?.status === "failed" &&
    Boolean(account?.failReason?.match(/you set this account to be paused/i))
  );
}
