import {
  INIT_GLOBALS,
  INIT_INBOX_PAGE_ROUTINE,
  TOGGLE_OPEN_INBOX_ITEM_ROUTINE,
  SKED_CORE_GET_ACCOUNTS_ROUTINE,
  REMOVE_INBOX_ITEMS_ROUTINE,
  TAG_INBOX_ITEMS_ROUTINE,
  TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE,
  ASSIGN_INBOX_ITEMS_TO_USER_ROUTINE,
  FAVORITE_INBOX_ITEMS_ROUTINE,
  COMMENT_ON_INBOX_ITEM_ROUTINE,
  ACTION_INBOX_ITEM_ROUTINE,
  TOGGLE_READ_INBOX_ITEMS_ROUTINE,
  TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE,
} from "constants/ActionTypes";
import { put, select, call, takeEvery, all, fork } from "redux-saga/effects";
import { createRoutine } from "redux-saga-routines";
import { getAccount } from "selectors/skedCoreSelectors";
import { hideModal } from "actions/modal";
import moment from "moment";
import ngDeps from "ng-react-directives/ngr-injector";
import { readInboxItems, toggleInboxItemComments } from "actions/inbox";
import _ from "lodash";

export function* initInboxPageSaga(action) {
  const { $rootScope } = ngDeps;
  yield put({
    type: INIT_GLOBALS,
    payload: {
      user: $rootScope.user,
    },
  });
  yield put({ type: SKED_CORE_GET_ACCOUNTS_ROUTINE.TRIGGER });
}

export function* toggleInboxItemCommentsSaga(action) {
  const { item, open } = action.payload;

  yield put({
    type: TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    yield put({
      type: TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE.SUCCESS,
      payload: {
        item,
        open,
      },
    });
  } catch (e) {
    yield put({
      type: TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE.FAILED,
      error: true,
      payload: e,
    });
  }
}
export function* toggleOpenInboxItemSaga(action) {
  const { item, withComments, autoFocusReply } = action.payload;

  yield put({
    type: TOGGLE_OPEN_INBOX_ITEM_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    if (item && !item.read) {
      yield put(readInboxItems([item]));
    }

    if (withComments) {
      yield put(toggleInboxItemComments(item));
    }

    yield put({
      type: TOGGLE_OPEN_INBOX_ITEM_ROUTINE.SUCCESS,
      payload: {
        item,
        autoFocusReply,
      },
    });
  } catch (e) {
    yield put({
      type: TOGGLE_OPEN_INBOX_ITEM_ROUTINE.FAILED,
      error: true,
      payload: e,
    });
  }
}
export function* removeInboxItemsSaga(action) {
  const { items } = action.payload;

  yield put({
    type: REMOVE_INBOX_ITEMS_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    for (var item of items) {
      // API call to delete
    }
    yield put({
      type: REMOVE_INBOX_ITEMS_ROUTINE.SUCCESS,
      payload: {
        items: items,
      },
    });
  } catch (e) {
    yield put({
      type: REMOVE_INBOX_ITEMS_ROUTINE.FAILED,
      error: true,
      payload: e,
    });
  }
}
export function* tagInboxItemsSaga(action) {}
export function* toggleArchiveInboxItemsSaga(action) {
  const { items, archived } = action.payload;

  yield put({
    type: TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    yield put({
      type: TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.SUCCESS,
      payload: {
        items: items,
        archived: archived,
      },
    });
  } catch (e) {
    yield put({
      type: TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.FAILED,
      error: true,
      payload: e,
    });
  }
}
export function* assignInboxItemToUserSaga(action) {}
export function* favoriteInboxItemsSaga(action) {
  const { items } = action.payload;

  yield put({
    type: FAVORITE_INBOX_ITEMS_ROUTINE.REQUEST,
    payload: action.payload,
  });

  for (var item of items) {
    try {
      yield put({
        type: FAVORITE_INBOX_ITEMS_ROUTINE.SUCCESS,
        payload: {
          id: item._id,
          favorited: !item.favorited,
        },
      });
    } catch (e) {
      yield put({
        type: FAVORITE_INBOX_ITEMS_ROUTINE.FAILED,
        error: true,
        payload: e,
      });
    }
  }
}
export function* commentOnInboxItemSaga(action) {}
export function* actionInboxItemSaga(action) {}
export function* toggleReadInboxItemsSaga(action) {
  const { items, read } = action.payload;

  yield put({
    type: TOGGLE_READ_INBOX_ITEMS_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    yield put({
      type: TOGGLE_READ_INBOX_ITEMS_ROUTINE.SUCCESS,
      payload: {
        items: items,
        read: read,
      },
    });
  } catch (e) {
    yield put({
      type: TOGGLE_READ_INBOX_ITEMS_ROUTINE.FAILED,
      error: true,
      payload: e,
    });
  }
}

/// WATCHERS
export const inboxSagas = [
  takeEvery(INIT_INBOX_PAGE_ROUTINE.TRIGGER, initInboxPageSaga),
  takeEvery(TOGGLE_OPEN_INBOX_ITEM_ROUTINE.TRIGGER, toggleOpenInboxItemSaga),
  takeEvery(
    TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE.TRIGGER,
    toggleInboxItemCommentsSaga
  ),

  takeEvery(REMOVE_INBOX_ITEMS_ROUTINE.TRIGGER, removeInboxItemsSaga),
  takeEvery(TAG_INBOX_ITEMS_ROUTINE.TRIGGER, tagInboxItemsSaga),
  takeEvery(
    TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.TRIGGER,
    toggleArchiveInboxItemsSaga
  ),
  takeEvery(
    ASSIGN_INBOX_ITEMS_TO_USER_ROUTINE.TRIGGER,
    assignInboxItemToUserSaga
  ),
  takeEvery(FAVORITE_INBOX_ITEMS_ROUTINE.TRIGGER, favoriteInboxItemsSaga),
  takeEvery(COMMENT_ON_INBOX_ITEM_ROUTINE.TRIGGER, commentOnInboxItemSaga),
  takeEvery(ACTION_INBOX_ITEM_ROUTINE.TRIGGER, actionInboxItemSaga),
  takeEvery(TOGGLE_READ_INBOX_ITEMS_ROUTINE.TRIGGER, toggleReadInboxItemsSaga),
];
