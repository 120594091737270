import React from "react";
import styled, { css } from "styled-components";
import ReactSwitch from "react-switch";

import {
  FormSize,
  formSizeAttributes,
  formSizeStyles,
} from "../form-theme-provider";

type Props = {
  children?: React.ReactNode;
  className?: string;
  onChange?: (value: boolean) => void;
  checked: boolean;
  disabled?: boolean;
};

function SwitchComponent(props: Props) {
  function handleChange() {
    props.onChange?.(!props.checked);
  }

  return (
    <SwitchComponentRoot className={props.className}>
      <ReactSwitchStyled
        checked={props.checked}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <Label>{props.children}</Label>
    </SwitchComponentRoot>
  );
}

export const Switch = styled(SwitchComponent)``;

const ReactSwitchStyled = styled(ReactSwitch).attrs((props) => ({
  width: 27,
  height: 16,
  ...formSizeAttributes(FormSize.small, props, {
    width: 24,
    height: 13,
  }),
  ...formSizeAttributes(FormSize.medium, props, {
    width: 27,
    height: 16,
  }),
  ...formSizeAttributes(FormSize.large, props, {
    width: 37,
    height: 22,
  }),
  checkedIcon: false,
  uncheckedIcon: false,
  onColor: "#4F31FF",
}))``;

const Label = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  ${formSizeStyles(
    FormSize.small,
    css`
      font-size: 11px;
      line-height: 11px;
    `
  )}

  ${formSizeStyles(
    FormSize.medium,
    css`
      font-size: 12px;
      line-height: 14px;
    `
  )}
`;

const SwitchComponentRoot = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 0;

  ${Label} {
    margin-left: 7px;
  }
`;
