import axios from "axios";
import { logout } from "utils/accounts";
import Cookies from "js-cookie";
import Bugsnag from "@bugsnag/js";

window.axios = axios;

export const getCSRFToken = async (force = false) => {
  const CSRFToken = Cookies.get("XSRF-TOKEN");

  if (CSRFToken && !force) return CSRFToken;

  try {
    const res = await axios("/csrf-token");

    if (res.data && res.data.token) {
      // NOTE: (Andrey) add CSRF token to cookie without domain to avoid add dot before domain
      Cookies.set("XSRF-TOKEN", res.data.token, {
        path: "/",
      });

      return res.data.token;
    }
  } catch (error) {
    console.log("Get CSRF Token Error", error);
    throw error;
  }
};

export const initAPI = () => {
  const CSRF_TOKEN_MAX_RETRY = 3;
  let csrfTokenRetry = 0;

  // Remove deprecated global CSRF token and tokens with dot, for now we use separated token for each hostname without dot (domain)
  Cookies.remove("XSRF-TOKEN", { domain: ".skedsocial.com" });
  Cookies.remove("XSRF-TOKEN", { domain: ".app.skedsocial.com" });
  Cookies.remove("XSRF-TOKEN", { domain: ".staging.skedsocial.com" });

  getCSRFToken();
  axios.interceptors.response.use(null, async function (error) {
    // NOTE: (Andrey) catch bad CSRF token errors
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.csrfReset === 1
    ) {
      // NOTE: (Andrey) If token is wrong try get new one only 3 times (avoid infinity getting token)
      if (csrfTokenRetry >= CSRF_TOKEN_MAX_RETRY) {
        csrfTokenRetry = 0;

        return Promise.reject(error);
      }

      // NOTE: (Andrey) Try force get new token
      await getCSRFToken(true);
      csrfTokenRetry++;

      // NOTE: (Andrey) retry request with new token
      return axios(error.config);
    }

    // NOTE: (Andrey) catch unauthorized and logout, go to login page
    if (error?.response?.status === 401) {
      logout();
    }

    if (error?.response?.status >= 400) {
      if (Bugsnag) {
        Bugsnag.notify(error);
      }
    }

    return Promise.reject(error);
  });
};
