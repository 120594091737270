import React, { useState } from "react";
import { PlatformUsage, PostFieldLabel } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";

const StoryStickerHeight = ({
  post,
  title,
  onChange,
  errors = {},
  className = "",
  platforms,
  selectedPlatform,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(
    (post && post[FIELDS.STORY_STICKER_HEIGHT]) || 0.8
  );

  const config = [
    {
      label: "10%",
      value: 0.1,
    },
    {
      label: "20%",
      value: 0.2,
    },
    {
      label: "30%",
      value: 0.3,
    },
    {
      label: "40%",
      value: 0.4,
    },
    {
      label: "50%",
      value: 0.5,
    },
    {
      label: "60%",
      value: 0.6,
    },
    {
      label: "70%",
      value: 0.7,
    },
    {
      label: "80%",
      value: 0.8,
    },
  ];

  const handleStoryHeightChange = (item) => {
    setSelected(item);
    onChange(item);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4 tw-mt-2">
          {title}
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.STORY_STICKER_HEIGHT}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <Select
        options={config}
        value={selected}
        onChange={handleStoryHeightChange}
        disabled={disabled}
      />
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.STORY_STICKER_HEIGHT]}
        errors={errors}
      />
    </div>
  );
};

export default StoryStickerHeight;
