import styled from "styled-components";

export const Flex = styled.div<{
  direction?: "column" | "row";
  justify?: "flex-start" | "flex-end" | "center" | "space-between";
  align?: "flex-start" | "flex-end" | "center";
  gap?: number;
  marginTop?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  width?: string;
}>`
  display: flex;
  width: ${({ width = "auto" }) => width};
  flex-direction: ${({ direction = "row" }) => direction};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  gap: ${({ gap = 7 }) => `${gap}px`};
  margin-top: ${({ marginTop = 0 }) =>
    marginTop === "auto" ? "auto" : `${marginTop}px`};
  margin-bottom: ${({ marginBottom = 0 }) =>
    marginBottom === "auto" ? "auto" : `${marginBottom}px`};
  margin-left: ${({ marginLeft = 0 }) =>
    marginLeft === "auto" ? "auto" : `${marginLeft}px`};
  margin-right: ${({ marginRight = 0 }) =>
    marginRight === "auto" ? "auto" : `${marginRight}px`};
`;

export const Relative = styled.div`
  position: relative;
`;
