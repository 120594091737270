import React, { useRef, useState, useEffect, useMemo } from "react";
import { DATE_FORMAT } from "constants/common";
import styled from "styled-components";
import { SkedButton, SkedIcon, FormControl } from "ui";
import { DeprecatedLink } from "ui/Link";
import Moment from "moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./DateTimePicker.less";
import ngDeps from "ng-react-directives/ngr-injector";

const DateTimePicker = ({
  showTriangle = true,
  showControls = true,
  blockPastDates = true,
  blockFutureDates = false,
  className = "",
  selectedDate,
  timePreference = "12h",
  onChange,
  onClose,
  arrowSide = "right",
  maxSelectableDate,
}) => {
  const { $rootScope } = ngDeps;
  const timezone = $rootScope.user.timezone;
  const defaultDate = useMemo(() => {
    return selectedDate ? selectedDate : new Moment().format(DATE_FORMAT);
  }, []);
  const [dateTime, setDateTime] = useState(defaultDate);
  const [date, setDate] = useState(defaultDate);
  // always pass in time as 24h time. The format is handled
  const [time, setTime] = useState(new Moment(defaultDate).format("HH:mm"));
  const node = useRef();

  const handleSetDateTime = () => {
    let datePart = new Moment(date).format("YYYY-MM-DD");
    // sometimes picker returns single digit hours/minutes and moment.format fails. THis hack fixes it
    const formattedTime = time
      .split(":")
      .map((field) => field.padStart(2, "0"))
      .join(":");
    const newDate = `${datePart} ${formattedTime}`;
    onChange ? onChange(newDate) : null;
  };

  useEffect(() => {
    // fire onchange event if controls are disabled
    if (!showControls) handleSetDateTime();
  }, [date, time]);

  const disabledDays = {};
  const today = new Moment().tz(timezone).toDate();
  if (blockPastDates) disabledDays.before = today;
  if (blockFutureDates) disabledDays.after = today;
  if (maxSelectableDate) disabledDays.after = maxSelectableDate;

  return (
    <div ref={node} className={`tw-relative ${className}`}>
      <div className="tw-flex tw-min-w-400px tw-text-sked-500 tw-border tw-border-solid tw-border-gray-500 tw-rounded-lg tw-overflow-hidden tw-shadow-lg">
        <DayPicker
          className={`tw-p-4 tw-bg-sked-200`}
          initialMonth={new Moment(date).toDate()}
          selectedDays={[new Moment(date).toDate()]}
          disabledDays={[disabledDays]}
          onDayClick={(day) => {
            // cancel date selection if date is blocked
            const isBefore = new Moment(day).isBefore(
              new Moment().tz(timezone).format("YYYY-MM-DD")
            );
            const isAfter = new Moment(day).isAfter(
              new Moment().tz(timezone).format("YYYY-MM-DD")
            );
            if ((isBefore && blockPastDates) || (isAfter && blockFutureDates)) {
              return;
            }
            if (
              maxSelectableDate &&
              new Moment(day).isAfter(maxSelectableDate)
            ) {
              return;
            }

            setDate(day);
          }}
          modifiersStyles={{
            selected: {
              color: "white",
              background: "#4F31FF",
              borderRadius: "5px",
            },
            caption: {
              color: "#b3b3b3",
            },
          }}
        />
        <div className="tw-flex tw-flex-col tw-pt-8 tw-px-4 tw-pb-4 tw-text-center tw-w-full tw-bg-white">
          <div className="tw-text-xl tw-text-gray-700">Posting Time</div>
          <TimePicker
            initialDateTime={new Moment(dateTime)}
            timePreference={timePreference}
            onChange={(newTime) => setTime(newTime)}
            className="tw-w-full tw-justify-center tw-flex-grow"
          />
          {showControls && (
            <div className="tw-flex tw-items-center tw-justify-center tw-pb-4 tw-px-4">
              <DeprecatedLink
                type="neutral"
                onClick={() => (onClose ? onClose() : null)}
              >
                Cancel
              </DeprecatedLink>
              <div className="tw-flex-grow tw-flex tw-justify-end">
                <SkedButton
                  size="small"
                  type="primary"
                  className="tw-bg-white"
                  onClick={() => handleSetDateTime()}
                >
                  Set
                </SkedButton>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Outer Triangle (border) */}
      {showTriangle && (
        <>
          <div
            className="tw-absolute"
            style={{
              bottom: "-19px",
              [arrowSide]: "75px",
              zIndex: "1001",
              width: 0,
              height: 0,
              borderLeft: "20px solid transparent",
              borderRight: "20px solid transparent",
              borderTop: `20px solid #b3b3b3`,
            }}
          >
            &nbsp;
          </div>
          {/* Inner triangle (fill) */}
          <div
            className="tw-absolute"
            style={{
              bottom: "-17px",
              [arrowSide]: "76px",
              zIndex: "1002",
              width: 0,
              height: 0,
              borderLeft: "19px solid transparent",
              borderRight: "19px solid transparent",
              borderTop: `19px solid ${
                arrowSide == "right" ? "white" : "#F6F5FF"
              }`,
            }}
          >
            &nbsp;
          </div>
        </>
      )}
    </div>
  );
};

const TimePickerComponent = ({
  initialDateTime,
  timePreference,
  className = "",
  onChange,
  minutesPickerStep = 1,
}) => {
  let using12h = timePreference === "12h";
  const [hour, setHour] = useState(initialDateTime.format("HH"));
  const [minute, setMinute] = useState(initialDateTime.format("mm"));
  const [meridian, setMeridian] = useState(initialDateTime.format("A"));

  // allow maxHr to be 24, at 24 the code below resets the time to 00
  const maxHr = using12h ? 12 : 24;

  const handleTimeChange = (
    newHour,
    newMinute,
    changeMeridian = false,
    oldHour,
    oldMinute
  ) => {
    if (newHour === 24 && oldHour === 23) {
      // if the hour changing to is 24 from 23, swap to 00
      // otherwise when you click up from 23 it will skip to 01
      newHour = 0;
    } else if (newHour === 24) {
      // user has gone from 00 'down' so this should be 23
      newHour = 23;
    }
    if (newMinute === 60 && oldMinute === 60 - minutesPickerStep) {
      // if the minute is changing to 60 from 59 or 55 (depending on minutesPickerStep), reset to 00
      newMinute = 0;
    } else if (newMinute === 60) {
      // the user is doing from 00 'down' -> should go to 59 or 55 (depending on minutesPickerStep)
      newMinute = 60 - minutesPickerStep;
    }
    if (newHour !== hour) {
      setHour(newHour);
    }

    if (newMinute !== minute) {
      setMinute(newMinute);
    }

    let newMeridian = meridian;
    if (changeMeridian) {
      newMeridian = newMeridian === "PM" ? "AM" : "PM";
      setMeridian(newMeridian);
    }

    let hour24 = parseInt(newHour);
    if (using12h && newHour < 12 && newMeridian === "PM") {
      hour24 += 12;
    } else if (using12h && newHour >= 12 && newMeridian === "AM") {
      hour24 -= 12;
    }
    // shim this because Moment expects the hour to always be 2 digits
    // bug is seen on Safari otherwise
    if (hour24 < 10) {
      hour24 = "0" + hour24;
    }
    const time = `${hour24}:${newMinute}:00`;

    onChange ? onChange(time) : null;
  };

  return (
    <div className={`tw-flex tw-items-center ${className}`}>
      <TimeToggle
        value={hour}
        displayValue={using12h ? (hour > 12 ? hour % 12 : hour) : hour}
        min={using12h ? 1 : 0}
        max={maxHr}
        onChange={(newValue, overflow) =>
          handleTimeChange(newValue, minute, overflow, hour, minute)
        }
        className="tw-mr-4"
      />
      :
      <TimeToggle
        value={minute}
        min={0}
        max={60}
        step={minutesPickerStep}
        className="tw-ml-4"
        onChange={(newValue) =>
          handleTimeChange(hour, newValue, false, hour, minute)
        }
      />
      {using12h && (
        <Meridian
          className={`tw-ml-4 tw-p-4 tw-rounded-lg tw-bg-sked-200 tw-cursor-pointer`}
          onClick={() => handleTimeChange(hour, minute, true)}
        >
          {meridian}
        </Meridian>
      )}
    </div>
  );
};

const Meridian = styled.div`
  width: 44px;
  font-family: monospace;
  color: #555;
  text-align: center;
  user-select: none;
`;

export const TimePicker = styled(TimePickerComponent)``;

const TimeToggle = ({
  value,
  displayValue = null,
  min,
  max,
  step = 1,
  className = "",
  onChange,
}) => {
  if (displayValue === null) {
    displayValue = value;
  }

  displayValue = displayValue.toString();
  if (displayValue.length === 1) {
    displayValue = `0${displayValue}`;
  }

  const handleUp = () => {
    let newVal = parseInt(value) + step;

    let changeMeridian = false;
    if (newVal === max || newVal === min) {
      changeMeridian = true;
    }

    if (newVal > max) {
      newVal %= max;
    }

    onChange ? onChange(newVal, changeMeridian) : null;
  };

  const handleDown = () => {
    let newVal = parseInt(value) - step;
    let changeMeridian = false;
    if (newVal < min) {
      newVal = max;
    }
    // current value is maximum (12, will not happen in 24h time) and user hits down
    // 12 -> 11 should change meridian (12pm -> 11am)
    // 01 -> 12 should NOT change meridian (midnight ->
    // but 01 -> 12 should not
    if (value === max && newVal === 11) {
      changeMeridian = true;
    }
    // was 1, going to 12 - should not change meridian

    onChange ? onChange(newVal, changeMeridian) : null;
  };

  const handleChange = (e) => {
    let val = e.target.value;
    try {
      if (val !== "") {
        val = parseInt(val);
        if (val > max || val < min || Number.isNaN(val)) {
          return;
        }
      }

      onChange ? onChange(val) : null;
    } catch (err) {
      //noop
    }
  };

  return (
    <div className={`tw-flex tw-flex-col tw-text-center ${className}`}>
      <div>
        <SkedIcon
          icon="icon-up-close"
          color="purple"
          size="20"
          className="tw-font-bold"
          onClick={() => handleUp(displayValue)}
        />
      </div>
      <div className="tw-my-2">
        <FormControl
          type="text"
          value={displayValue}
          className="TimeToggle tw-w-40px tw-p-0 tw-text-center tw-appearance-none"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div>
        <SkedIcon
          icon="icon-down-close"
          color="purple"
          size="20"
          className="tw-font-bold"
          onClick={() => handleDown(displayValue)}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
