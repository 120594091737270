export const NEW_POST_ID = "NEW_POST";

export const LAYOUT_OPTIONS = {
  LIST: "list",
  TABLE: "table",
};

export const POST_TYPES = {
  IMAGE: "image",
  PHOTO: "photo",
  REEL: "reel",
  VIDEO: "video",
  MULTI_MEDIA: "multiMedia",
  TEXT: "text",
  DOCUMENT: "document",
  STORY: "story",
};

export const POST_STATES = {
  SCHEDULED: "SCHEDULED",
  QUEUED: "QUEUED",
  DRAFT: "DRAFT",
  ARCHIVED: "ARCHIVED",
};
