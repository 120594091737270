import { skedApi } from "./_skedApi";
import axios from "axios";

export async function getAccountsStatistic(accountIds: string[]) {
  const data = { accountIds };
  const response = await skedApi.post("/account-statistics/read", data);

  return response.data;
}

export async function getArchivedPostsCount(accountIds: string[]) {
  const params = { accountIds, page: 1 };
  const response = await axios.get("/schedules/inactive", { params });
  const nonImportedArchivedPosts = (response.data.schedules || []).filter(
    ({ isImported }: { isImported: boolean }) => !isImported
  );
  return nonImportedArchivedPosts.length;
}
