import React from "react";
import styled from "styled-components";

type ModalActionsVariant =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between";

export const ModalActions = styled(function ModalActions({
  className,
  children,
}: {
  className?: string;
  variant: ModalActionsVariant;
  children: React.ReactNode;
}) {
  return <div className={className}>{children}</div>;
})`
  display: flex;
  justify-content: ${(props) => props.variant};
`;
