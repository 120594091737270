import React from "react";
import styled from "styled-components";
import { TooltipWrapper } from "ui";
import { HideUntilReady, MultiMediaProps, UnsupportedMediaWrapper } from ".";

export const MediaShare = (props: MultiMediaProps): JSX.Element => {
  const { media, onError, setIsReady, isReady } = props;
  return (
    <MediaShareWrapper data-testid="multimedia-share">
      <HideUntilReady isReady={isReady}>
        <img
          src={media.payload.url}
          width="100%"
          onError={onError}
          onLoad={setIsReady}
        />
      </HideUntilReady>
      <MediaShareMessage>
        <UnsupportedMediaWrapper>
          Shared an attachment
          <TooltipWrapper
            tooltip="We’re unable to share the source of this attachment."
            placement="bottom"
          >
            <span>?</span>
          </TooltipWrapper>
        </UnsupportedMediaWrapper>
      </MediaShareMessage>
    </MediaShareWrapper>
  );
};

const MediaShareWrapper = styled.div``;
const MediaShareMessage = styled.div`
  background: #eeeeee;
  padding: 8px 12px;
  width: auto;
  overflow-wrap: break-word;
  white-space: pre-line;
`;
