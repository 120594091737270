import React from "react";
import styled, { css } from "styled-components";

import { ColorPicker as RawColorPicker } from "ui/color-picker";

interface Props {
  className?: string;
  value: string;
  onChange(value: string): void;
  label: string;
}

function ColorPickerComponent(props: Props) {
  return (
    <ColorPickerRoot className={props.className}>
      <RawColorPicker value={props.value} onChange={props.onChange}>
        <Value type="button" value={props.value} />
      </RawColorPicker>
      <Label>{props.label}</Label>
    </ColorPickerRoot>
  );
}

const Value = styled.button<{ value: string }>`
  position: relative;
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.value};
  border-radius: 50%;
  border: none;
  padding: 0;

  ${(props) =>
    !props.value &&
    css`
      background: rgb(204, 204, 204);
      position: relative;
      overflow: hidden;
      :after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to left top,
          transparent 47.75%,
          #a7283c 49.5%,
          #a7283c 50.5%,
          transparent 52.25%
        );
      }
    `}
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #707070;
`;

const ColorPickerRoot = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${Value} {
    margin-right: 12px;
  }
`;

export const ColorPicker = styled(ColorPickerComponent)``;
