import React, { useMemo, useCallback } from "react";

import {
  PostFieldLabel,
  PostFieldCounter,
  PlatformUsage,
} from "components/Posts/PostForm";
import { countCharacter } from "utils/strings";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { FIELDS } from "constants/PostFormFields";
import { useDebouncedCallback } from "use-debounce";
import { SkedCaption, useSkedCaptionAutocomplete } from "ui/SkedCaption";

const PostFirstComment = ({
  post,
  selectedPlatform,
  platforms,
  onChange,
  className = "",
  errors,
  disabled = false,
  hashtagCount,
}) => {
  const skedCaptionAutocomplete = useSkedCaptionAutocomplete(selectedPlatform);
  const firstcomment = useMemo(() => post.firstcomment || "", [post]);
  const mentionCount = countCharacter(firstcomment, "@");

  const bounce = useDebouncedCallback((value) => {
    if (value !== firstcomment) {
      onChange(value);
    }
  }, 500);

  return (
    <div data-testid="postFirstComment" className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-flex tw-items-center tw-mb-2">
          <div className="tw-mr-2"> First Comment</div>
          <PlatformUsage
            platforms={platforms}
            field={FIELDS.FIRST_COMMENT}
            selectedPlatform={selectedPlatform}
          />
        </PostFieldLabel>
      </div>
      <SkedCaption
        initialValue={firstcomment}
        disabled={disabled}
        onChange={bounce}
        suggestions={true}
        upcomingEditPopup={true}
        hideDescriptions={true}
        autocomplete={skedCaptionAutocomplete}
      />
      <div className="tw-flex">
        <PostFieldCounter
          count={firstcomment.length}
          max={2200}
          className="tw-flex-grow tw-text-right tw-mt-2 tw-mr-8"
        >
          Characters
        </PostFieldCounter>
        <PostFieldCounter
          count={hashtagCount}
          max={30}
          className="tw-text-right tw-mt-2"
        >
          Hashtags
        </PostFieldCounter>
        {mentionCount > 0 && (
          <PostFieldCounter
            count={mentionCount}
            className="tw-text-right tw-mt-2 tw-ml-8"
          >
            Mentions
          </PostFieldCounter>
        )}
      </div>
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.FIRST_COMMENT]}
        errors={errors}
      />
    </div>
  );
};

export default PostFirstComment;
