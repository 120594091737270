import _ from "lodash";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { changePageSize } from "actions/pagination";
import { getPageSize } from "selectors/commonSelectors";

const PageSizes = ({ onChange, pageSizeOptions }) => {
  const dispatch = useDispatch();
  const currentPageSize = useSelector(getPageSize);

  const handlePageSizeChange = (e, pageSize) => {
    e.preventDefault();
    dispatch(changePageSize(pageSize));
    onChange(pageSize);
  };

  return (
    <PageSizesWrapper>
      <ul className="pagination pagination-sm">
        <li className="info">
          <a onClick={(e) => e.preventDefault} aria-label="Page Size">
            <span aria-hidden="true">Page Size</span>
          </a>
        </li>
        {_.map(pageSizeOptions, (pageSize) => {
          return (
            <li
              key={`page-size-${pageSize}`}
              className={`${currentPageSize === pageSize ? "active" : ""}`}
            >
              <a onClick={(e) => handlePageSizeChange(e, pageSize)}>
                {pageSize}
              </a>
            </li>
          );
        })}
      </ul>
    </PageSizesWrapper>
  );
};

PageSizes.propTypes = {
  onChange: PropTypes.func,
  pageSize: PropTypes.number,
};

const PageSizesWrapper = styled.div`
  //NOTE: (Andrey) get spaces for intercom button.
  margin-right: 50px;
`;
export default PageSizes;
