import React, { useState } from "react";
import { PlatformUsage, PostFieldLabel } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";

const PinterestBoardSection = ({
  post,
  selectedPlatform,
  platforms,
  account,
  disabled = false,
  onChange,
  errors,
  className = "",
}) => {
  const [selected, setSelected] = useState(post[FIELDS.PI_SECTION_ID]);

  let board = null;
  let sections = [];
  if (
    account &&
    account.boards &&
    post[FIELDS.PI_BOARDS] &&
    post[FIELDS.PI_BOARDS].length === 1
  ) {
    board = account.boards.find((board) => {
      return board.id === post[FIELDS.PI_BOARDS][0];
    });
    if (board && board.sections) {
      sections = board.sections;
    }
  }
  let categories = [];
  sections.forEach((section) => {
    categories.push({
      id: section.id,
      value: section.id,
      label: section.title || section.name, // title for v3, name for v5
    });
  });

  const handleCategoryChange = (item) => {
    console.log("Selected", item);
    setSelected(item);
    onChange(item);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Board section (optional)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.PI_SECTION_ID}
          selectedPlatform={selectedPlatform}
        />
      </div>
      {categories && categories.length > 0 && (
        <Select
          placeholder={"Select section…"}
          options={categories}
          value={selected}
          defaultItem={categories[0]}
          onChange={handleCategoryChange}
          isClearable={true}
          disabled={disabled}
        />
      )}
      {categories && categories.length === 0 && (
        <div className={"tw-text-xl"}>
          No sections available, or multiple boards selected.
        </div>
      )}
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.PI_SECTION_ID]}
        errors={errors}
      />
    </div>
  );
};

export default PinterestBoardSection;
