import axios from "axios";
import { skedApi } from "api/_skedApi";
import _ from "lodash";

export default class MediaLibraryLegacyApi {
  baseUrl: string;

  constructor() {
    this.baseUrl = `/content-inbox`;
  }

  // --------- COLLECTIONS ---------- //
  getCollections = () =>
    skedApi.get(`/content-inbox/inbox?_limit=900&_sort=name`);

  createCollection = (name, ext_auth) =>
    skedApi.post(`/content-inbox/inbox`, {
      name,
      ext_auth,
    });

  deleteCollection = (id) => skedApi.delete(`/content-inbox/inbox/${id}`);

  updateCollection = (id, update) =>
    skedApi.put(`/content-inbox/inbox/${id}`, update);

  getLibraryUsage = () => skedApi.get(`/content-inbox/entry-stats`);

  // --------- COLLECTION TAGS ---------- //
  getTags = (collectionId) =>
    skedApi.get(`/content-inbox/inbox/${collectionId}/tags`);

  // --------- ENTRIES (Assets) ---------- //
  getEntries = (
    collectionId: string,
    page = 1,
    pageSize = 25,
    tags = [],
    name = ``,
    description = ``,
    favorite = false,
    mediaType = null,
    sourceName = ``,
    usage = null,
    withDownloadUrl = true,
    sort = "-created"
  ) => {
    const queryParts = [
      `_page=${page}`,
      `_limit=${pageSize}`,
      `_sort=${encodeURIComponent(sort)}`,
      `withDownloadUrl=${withDownloadUrl}`,
    ];
    if (tags.length > 0) {
      _.forEach(tags, (tag) => {
        queryParts.push(`tags[]=${encodeURIComponent(tag.value)}`);
      });
    }

    if (name.length > 0) {
      queryParts.push(`name=${encodeURIComponent(name)}`);
    }
    if (description.length > 0) {
      queryParts.push(`description=${encodeURIComponent(description)}`);
    }
    if (sourceName.length > 0) {
      queryParts.push(`sourceName=${encodeURIComponent(sourceName)}`);
    }
    if (usage) {
      queryParts.push(`usage=${encodeURIComponent(usage)}`);
    }
    // if false, do not pass it in  as we will then show all assets
    if (favorite) {
      queryParts.push(`favorite=true`);
    }

    if (mediaType) {
      queryParts.push(`mediaType=${mediaType}`);
    }

    const queryString = queryParts.join(`&`);
    return skedApi.get(
      `/content-inbox/inbox/${collectionId}/assets?${queryString}`
    );
  };

  addEntryFromUrl = (collectionId: string, body: object) => {
    return skedApi.post(`/content-inbox/inbox/${collectionId}/upload`, body);
  };

  addEntry = (collectionId: string, file: any, meta: object) => {
    const formData = this.convertToFormData(file, meta);
    return skedApi.post(
      `/content-inbox/inbox/${collectionId}/upload`,
      formData,
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }
    );
  };

  getEntry = (assetId: string) => skedApi.get(`/content-inbox/${assetId}`);

  downloadEntry = async (assetId: string) => {
    const file = await skedApi.get(`/content-inbox/${assetId}/download`);
    console.log("file", file);
    return await axios.get(file.data.url, {
      responseType: "blob",
    });
  };

  updateEntry = (
    assetId,
    name,
    description,
    tags,
    file,
    source,
    sourceNotes,
    favorite
  ) => {
    const putObject = {
      name,
      description,
      tags,
      source,
      favorite,
    };
    if (favorite !== undefined) {
      putObject.favorite = favorite;
    }
    const putUrl = `/content-inbox/${assetId}`;
    if (!file) return skedApi.put(putUrl, putObject);
    const formData = this.convertToFormData(file, putObject);

    return skedApi.put(putUrl, formData, {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    });
  };

  moveEntry = (assetId, collectionId) =>
    skedApi.put(`/content-inbox/${assetId}`, {
      inbox_id: collectionId,
    });

  updateEntryMeta = (assetId, payload) =>
    skedApi.put(`/content-inbox/${assetId}`, payload);

  deleteEntry = (assetId) => skedApi.delete(`/content-inbox/${assetId}`);

  // --------- USERS (AUTH) ---------- //
  // TODO review the use of this and port to API
  getCollectionAuth = (collectionId) =>
    skedApi.get(`/content-inbox/inbox/${collectionId}/auth`);

  createCollectionAuthUser = (collectionId, payload) =>
    skedApi.post(`/content-inbox/inbox/${collectionId}/auth`, payload);

  getCollectionAuthUser = (collectionId, authId) =>
    skedApi.get(`/content-inbox/inbox/${collectionId}/auth/${authId}`);

  updateCollectionAuthUser = (collectionId, authId, payload) =>
    skedApi.put(`/content-inbox/inbox/${collectionId}/auth/${authId}`, payload);

  deleteCollectionAuthUser = (collectionId, authId, payload) =>
    skedApi.delete(
      `/content-inbox/inbox/${collectionId}/auth/${authId}`,
      payload
    );

  // --------- HELPERS ---------- //
  convertToFormData = (file, meta) => {
    const formData = new FormData();
    formData.append(`files[]`, file);
    Object.keys(meta).forEach((k) => {
      if (_.isArray(meta[k])) {
        _.forEach(meta[k], (arrayItem) => {
          formData.append(`${k}[]`, arrayItem);
        });
        return;
      }
      if (typeof meta[k] === "object") {
        Object.keys(meta[k]).forEach((k2) => {
          if (meta[k][k2] !== null) {
            formData.append(`${k}.${k2}`, meta[k][k2] || null);
          }
        });
        return;
      }
      formData.append(k, meta[k]);
    });
    return formData;
  };
}
