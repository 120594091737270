import { FIELDS, FIELDS_DEFAULT } from "constants/PostFormFields";
import { get } from "lodash";
import { useEffect, useMemo } from "react";

import usePrevious from "utils/hooks/usePrevious";

export function useThreadsFields({
  sortedAccounts,
  platformFormValue,
  visibleFields,
  selectedPlatform,
  updateField,
}: {
  sortedAccounts: any[];
  platformFormValue: any;
  visibleFields: any;
  selectedPlatform: string;
  updateField: (field: string, value: boolean) => void;
}) {
  const { threadsAccounts, oldThreadsAccounts } = useMemo(() => {
    const threadsAccounts = sortedAccounts.filter(
      (account) => account.platformType === "TH"
    );

    return {
      threadsAccounts,
      oldThreadsAccounts: threadsAccounts?.filter(
        (account) => !account?.platformId
      ),
    };
  }, [sortedAccounts]);

  const thIsNotification = useMemo(
    () =>
      get(
        platformFormValue,
        FIELDS.POST_IS_NOTIFICATION_ONLY,
        FIELDS_DEFAULT.IS_NOTIFICATION_POST_ONLY_DEFAULT
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types
    [platformFormValue?.postOptions?.isNotification]
  );

  const previousoldThreadsAccounts = usePrevious(oldThreadsAccounts);
  const previousthreadsAccounts = usePrevious(threadsAccounts);

  //NOTE: when added old threads account, we should turn on notification toggle
  useEffect(() => {
    if (threadsAccounts.length === 1 && oldThreadsAccounts.length === 1) {
      // if only one account and it's oldTH
      return updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, true);
    }

    // turn off notification toggle only when deleted all old TH account
    if (
      // after delete all old TH accounts and has new TH account
      (previousoldThreadsAccounts &&
        previousoldThreadsAccounts.length > 0 &&
        oldThreadsAccounts.length === 0 &&
        threadsAccounts.length > 0) ||
      // after delete all accounts and add first new TH account
      (previousthreadsAccounts &&
        previousthreadsAccounts.length === 0 &&
        oldThreadsAccounts.length === 0 &&
        threadsAccounts.length === 1)
    ) {
      return updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, false);
    }

    if (oldThreadsAccounts.length > 0) {
      // false because by default posting for new TH account should work, old TH account correct handled on backend
      return updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldThreadsAccounts.length, threadsAccounts.length]);

  const hasThreadsPosting = threadsAccounts.some(
    (x) => x.platformId !== null && x.platformId !== undefined
  );

  return {
    threadsAccounts,
    oldThreadsAccounts,
    thIsNotification,
    hasThreadsPosting,
  };
}
