import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDefaultStatusKey } from "utils/user";
import {
  getFormAccounts,
  getFormErrors,
  getFormPost,
  getFormResults,
  getFormWarnings,
  getOriginalPostState,
  getPlatformFields,
  getPlatformPost,
  getPlatforms as getPlatformsSelector,
  getPostState as getPostStateSelector,
  getSelectedPlatform,
  getVisibleFields,
} from "selectors/postFormSelectors";

import {
  closePostForm,
  startPostForm,
  updatePostFormField,
} from "actions/postForm";
import { filterCollabStatus } from "actions/postsView";

import { postFormSelector } from "features/post";
import { NEW_POST_ID, POST_STATES } from "constants/PostsView";
import { getUser } from "selectors/commonSelectors";
import { filterPostContentType } from "actions/postsView";
import {
  postsContentAllOption,
  postsContentOptions,
} from "constants/PostsFliters";
import { useTrackEvent } from "libs/analytics";
import { CREATE_POST_LINKEDIN_PDF } from "constants/Events";

const getAccountIds = (accountIds, accountId) => {
  if (accountIds && accountIds.length) {
    return accountIds;
  }

  if (accountId) {
    return [accountId];
  }

  return [];
};

export function usePostForm(post, { onSuccess, onSave }) {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.entities.accounts.byId);
  const user = useSelector(getUser);
  const trackEvent = useTrackEvent();

  const { isSubmitting } = useSelector(postFormSelector);
  // account that is selected in planner. If user wants to schedule/queue several posts
  // from planner he can do it only to 1 account
  const { plannerAccount } = post;

  useEffect(() => {
    const { accountIds, ...postData } = post;

    const accountsData = (
      (accountIds || []).length ? accountIds : [postData.accountId]
    )
      .map((id) => accounts[id])
      .filter(Boolean);
    dispatch(startPostForm(postData, accountsData));
  }, []);

  const postFormValue = useSelector((state) => getFormPost(state, post._id));
  const validationErrors = useSelector((state) =>
    getFormErrors(state, post._id)
  );
  const warnings = useSelector((state) => getFormWarnings(state, post._id));

  // This indicates what kind of post it will be (draft, queue, schedule)
  const postState = useSelector((state) =>
    getPostStateSelector(state, post._id)
  );
  // Objects representing which platforms to use given the accounts
  const platforms = useSelector((state) =>
    getPlatformsSelector(state, post._id)
  );

  const originalPostState = useSelector((state) =>
    getOriginalPostState(state, post._id)
  );
  // If unlinking a cluster post we will keep track of the fields that deviate.
  const platformFields = useSelector((state) =>
    getPlatformFields(state, post._id)
  );

  // Current state of accounts on the form
  const formAccounts = useSelector((state) => getFormAccounts(state, post._id));

  // Helper to update a single field for the selected platform.
  const updateField = (field, value) => updateFields({ [field]: value });

  const updateFields = (newValueMap) => {
    dispatch(updatePostFormField(postFormValue._id, newValueMap, true));
  };

  const postHasAvailableStatus = useMemo(
    () => {
      if (user.isPostStatusEnabled) {
        if (user.role !== "collaborator" || !postFormValue?.postStatusKey) {
          return true;
        }

        return (
          user.preferences?.canChangeStatusTo?.includes(
            postFormValue?.postStatusKey
          ) || postFormValue?.postStatusKey === getUserDefaultStatusKey(user)
        );
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postFormValue?.postStatusKey]
  );

  const postAccountsCanBeUpdated = useMemo(() => {
    const oldValueAccountIds = getAccountIds(post?.accountIds, post?.accountId);
    const newValueAccountIds = getAccountIds(
      postFormValue?.accountIds,
      postFormValue?.accountId
    );

    return (
      !postHasAvailableStatus &&
      JSON.stringify(oldValueAccountIds) !== JSON.stringify(newValueAccountIds)
    );
  }, [
    post?.accountId,
    post?.accountIds,
    postFormValue?.accountId,
    postFormValue?.accountIds,
    postHasAvailableStatus,
  ]);

  const errors = useMemo(() => {
    const errors = { ...validationErrors };

    if (postAccountsCanBeUpdated) {
      if (!errors.type) {
        errors.type = { errors: [] };
      } else if (!errors.type.errors) {
        errors.type = { ...errors.type, errors: [] };
      }

      errors.type.errors.push(
        "You are unable to add an account to a post with an approval status you don't have access to. Change the approval status or remove the newly added account."
      );
    }

    return errors;
  }, [validationErrors, postAccountsCanBeUpdated]);

  const saveDisabled = useMemo(() => {
    if (postState === POST_STATES.ARCHIVED) return true;

    if (postAccountsCanBeUpdated) {
      return true;
    }

    if (!errors) return false;

    let errorKeys = Object.keys(errors);

    if (errorKeys.length === 1 && errorKeys[0] === "SAVE_ERROR") {
      return false;
    } else if (errorKeys.length > 0) {
      return true;
    }

    return false;
  }, [errors, postAccountsCanBeUpdated, postState]);

  const onSubmit = () => {
    if (
      postFormValue?._id === "NEW_POST" &&
      postFormValue?.type === "document"
    ) {
      trackEvent({
        eventName: CREATE_POST_LINKEDIN_PDF,
        metadata: { pages: postFormValue?.meta?.pages },
        useServices: ["AMP", "AC"],
      });
    }

    onSave({
      originalPostState:
        post.originalPostType === "draft"
          ? post.originalPostType
          : originalPostState,
      newPostState: postState,
      post: postFormValue,
      platformFields: platformFields,
      platforms: platforms,
      plannerAccount: plannerAccount,
    });
  };

  // Clean up redux when form is unmounted / page navigates
  useEffect(() => {
    return () => dispatch(closePostForm(post._id));
  }, []);

  // When the saga has saved the post, let's call the callback and close the form.
  const formResults = useSelector((state) => getFormResults(state, post._id));
  useEffect(() => {
    if (formResults) {
      onSuccess?.(formResults);
      dispatch(closePostForm(post._id));
    }
  }, [formResults]);

  const platformPost = useSelector((state) => getPlatformPost(state, post._id));

  // We show different tabs in case user wants to split off a platform from the cluster post.
  // If only one platform is present then we just show that platform icon, but if there
  // are multiple platforms then we also show a 'link' platform which represents the shared
  // data across all platforms.
  const selectedPlatform = useSelector((state) =>
    getSelectedPlatform(state, post._id)
  );

  // What fields are visible for the selected platform?
  const visibleFields = useSelector((state) =>
    getVisibleFields(state, post._id)
  );

  const channelId = post?.schedule?.channel || post?.channel || post._id;

  return {
    post,
    postFormValue,
    errors,
    warnings,
    postState,
    platforms,
    originalPostState,
    platformFields,
    formAccounts,
    updateFields,
    updateField,
    saveDisabled,
    onSubmit,
    platformPost,
    selectedPlatform,
    visibleFields,
    channelId,
    isSubmitting,
  };
}
