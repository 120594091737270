import ngDeps from "ng-react-directives/ngr-injector";
import moment from "moment";

export const dateInThePast = (date) => {
  const { $rootScope } = ngDeps;
  const nowTime = moment.utc(
    moment(new Date()).tz($rootScope.user.timezone).format(),
    "YYYY-MM-DD[T]HH:mm[Z]"
  );
  const newTime = moment.utc(moment(date).format(), "YYYY-MM-DD[T]HH:mm[Z]");
  return nowTime.isAfter(newTime);
};
