interface ImageFile extends File {
  imageThumbUrl: string;
}

export function resizeImage(
  file: ImageFile,
  maxWidth = 300,
  maxHeight = 500
): any {
  const mime = "image/jpeg";
  const canvas = document.createElement("canvas");

  // @ts-ignore
  const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
  const img = new Image();

  canvas.width = maxWidth;
  canvas.height = maxHeight;

  ctx.clearRect(0, 0, maxWidth, maxHeight);
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, maxWidth, maxHeight);

  img.onload = function () {
    const aspectRatio = img.height / img.width;
    let resizedWidth = maxWidth;
    let resizedHeight = resizedWidth * aspectRatio;

    if (resizedHeight > maxHeight) {
      resizedHeight = maxHeight;
      resizedWidth = resizedHeight * (img.width / img.height);
    }
    const centerHeight =
      resizedHeight < maxHeight ? maxHeight / 2 - resizedHeight / 2 : 0;
    const centerWidth =
      resizedWidth < maxWidth ? maxHeight / 2 - resizedWidth / 2 : 0;
    console.log(img.width / img.height, resizedWidth / resizedHeight);
    ctx.translate(centerWidth, centerHeight);

    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height, // size of image
      0,
      0,
      resizedWidth,
      resizedHeight
    );

    file.imageThumbUrl = canvas.toDataURL(mime);
  };

  img.src = URL.createObjectURL(file);
}

export const checkIfHeic = (value?: string) =>
  typeof value === "string" && /heic|heif/.test(value.toLocaleLowerCase());
