import { setup } from "axios-cache-adapter";
import { uniqBy } from "lodash";
import { CancelToken } from "axios";

const LIMIT = 15;

interface Tag {
  type: "hashtag" | "mention";
  value: string;
}

const axiosCachedHashTags = setup({
  cache: {
    maxAge: 15 * 60 * 1000,
  },
});

export async function findAllHashtags(cancelToken?: CancelToken) {
  const response = await axiosCachedHashTags.request<Tag[]>({
    method: "get",
    url: `/tags/hashtags`,
    params: { limit: LIMIT },
    cancelToken,
  });

  return uniqBy(response.data, (t) => t.value);
}

export async function findPrefixHashtags(
  prefix: string,
  cancelToken?: CancelToken
) {
  if (!prefix) {
    return await findAllHashtags(cancelToken);
  }
  const response = await axiosCachedHashTags.request<Tag[]>({
    method: "get",
    url: `/tags/hashtags/${window.encodeURIComponent(prefix)}`,
    params: { limit: LIMIT },
    cancelToken,
  });

  return uniqBy(response.data, (t) => t.value);
}

export async function removeHashTag(tag: string) {
  await axiosCachedHashTags.delete(
    `/tags/hashtags/${window.encodeURIComponent(tag)}`
  );
  // NOTE: invalidate hashtags cache
  await (axiosCachedHashTags as any).cache.clear();
}

const axiosCachedMentions = setup({
  cache: {
    maxAge: 15 * 60 * 1000,
  },
});

export async function findAllMentions(cancelToken?: CancelToken) {
  const response = await axiosCachedMentions.request<Tag[]>({
    method: "get",
    url: `/tags/mentions`,
    params: { limit: LIMIT },
    cancelToken,
  });

  return uniqBy(response.data, (t) => t.value);
}

export async function findPrefixMentions(
  prefix: string,
  cancelToken?: CancelToken
) {
  if (!prefix) {
    return await findAllMentions(cancelToken);
  }
  const response = await axiosCachedMentions.request<Tag[]>({
    method: "get",
    url: `/tags/mentions/${window.encodeURIComponent(prefix)}`,
    params: { limit: LIMIT },
    cancelToken,
  });

  return uniqBy(response.data, (t) => t.value);
}

export async function removeMention(tag: string) {
  await axiosCachedMentions.delete(
    `/tags/mentions/${window.encodeURIComponent(tag)}`
  );
  // NOTE: invalidate hashtags cache
  await (axiosCachedMentions as any).cache.clear();
}
