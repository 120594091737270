import {
  START_POST_FORM_ROUTINE,
  CLOSE_POST_FORM,
  UPDATE_POST_FORM_FIELD,
  SAVE_POST_FORM_ROUTINE,
  SELECT_POST_FORM_PLATFORM,
  TOGGLE_POST_FORM_PLATFORM_LINK,
  UPDATE_POST_FORM_VALIDATION,
  CHECK_INSTAGRAM_POST_OVERLAPPING,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { getPostState, getPostType } from "utils/posts";
import { getPlatforms } from "utils/accounts";
import { FIELDS } from "constants/PostFormFields";
import { POST_TYPES } from "constants/PostsView";
import { postPlatformsConfig } from "components/Posts/PostForm/postPlatforms.config";
import { validate } from "components/Posts/PostForm/postFormValidator";
import _ from "lodash";

const initialFormState = {
  overlapSchedule: {
    accountName: null,
    overlappingPostId: null,
    nextRunAt: null,
  },
  originalPost: {},
  originalPostState: "",
  postState: "",
  post: {},
  platformFields: {},
  accounts: [],
  platforms: [],
  view: {
    selectedPlatform: "LINKED",
    visibleFields: [],
  },
  validation: {
    errors: {},
    warnings: {},
  },
  results: null,
};

const initialState = {
  byId: {},
  allIds: [],
};

const postFormsReducer = createReducer(initialState, {
  [START_POST_FORM_ROUTINE.SUCCESS]: (state, action) => {
    const { post, accounts } = action.payload;
    const denormalizedPost = post.schedule ? post.schedule : post;
    const platforms = getPlatforms(accounts);
    const originalPostState = getPostState(post);
    const visibleFields = getVisibleFields("LINKED", platforms);

    // if (originalPostState === "ARCHIVED") {
    //   // this avoids a 'date in the past' error when you are
    //   // "posting again" from archives page
    //   post.when = null;
    // }
    let newForm = {
      ...initialFormState,
      originalPost: denormalizedPost,
      originalPostState: originalPostState,
      post: buildInitialPost(post, accounts),
      postState: post.postState ? post.postState : originalPostState,
      accounts: accounts,
      platforms: platforms,
      view: {
        ...initialFormState.view,
        visibleFields: visibleFields,
      },
    };

    // Validate form after update
    const [errors, warnings] = validate(
      newForm.postState,
      newForm.post,
      newForm.platformFields,
      newForm.platforms,
      newForm.overlapSchedule
    );

    newForm.validation = {
      errors,
      warnings,
    };

    state.byId[post._id] = newForm;
    state.allIds.push(post._id);
    return state;
  },
  [CLOSE_POST_FORM]: (state, action) => {
    const { postId } = action.payload;
    if (postId && state.allIds.includes(postId)) {
      _.remove(state.allIds, (selectedId) => selectedId === postId);
      delete state.byId[postId];
    }
    return state;
  },
  [UPDATE_POST_FORM_VALIDATION]: (state, action) => {
    const { postId, errors, warnings } = action.payload;
    state.byId[postId].validation = { errors, warnings };
    return state;
  },
  [SAVE_POST_FORM_ROUTINE.SUCCESS]: (state, action) => {
    const { postId, originalPost, newPosts } = action.payload;
    const post = state.byId[postId];
    if (post) {
      post.results = { originalPost, newPosts };
    }
    return state;
  },
  [SAVE_POST_FORM_ROUTINE.FAILURE]: (state, action) => {
    const { payload } = action;
    const form = getFirstForm(state);
    const id = form.post._id;
    const errorMessage = payload.message ? payload.message : payload;
    if (errorMessage) {
      form.validation.errors["SAVE_ERROR"] = {
        errors: [errorMessage],
      };
      state.byId[id] = form;
    }
    return state;
  },
  [SELECT_POST_FORM_PLATFORM]: (state, action) => {
    const { platformType } = action.payload;
    const form = getFirstForm(state);
    const id = form.post._id;

    const platforms = form.platforms;
    if (platformType !== null) {
      let fields = [];
      if (platformType === "LINKED") {
        platforms.map((p) => {
          fields = fields.concat(postPlatformsConfig[p.TYPE].fields);
        });
      } else if (postPlatformsConfig[platformType]) {
        fields = postPlatformsConfig[platformType].fields;
      }
      fields = _.uniq(fields);
      form.view.visibleFields = fields;
      form.view.selectedPlatform = platformType;
    }
    state.byId[id] = form;
    return state;
  },
  [TOGGLE_POST_FORM_PLATFORM_LINK]: (state) => {
    const form = getFirstForm(state);
    const id = form.post._id;

    const currentFields = form.platformFields[form.view.selectedPlatform];
    let newPlatformFields = { ...form.platformFields };
    if (currentFields && currentFields.unlinked) {
      delete newPlatformFields[form.view.selectedPlatform];
    } else {
      const unlinkedPlatformDefaultValue = form.view.visibleFields.reduce(
        (acum, field) => {
          _.set(acum, field, _.get(form.post, field));

          return acum;
        },
        { unlinked: true }
      );

      newPlatformFields[form.view.selectedPlatform] =
        unlinkedPlatformDefaultValue;
    }
    form.platformFields = newPlatformFields;
    state.byId[id] = form;
    return state;
  },
  [UPDATE_POST_FORM_FIELD]: (state, action) => {
    const { postId, values } = action.payload;
    const fields = Object.keys(values);

    if (!fields || fields.length === 0) {
      console.log("Invalid call to updateField");
      return state;
    }

    let form = state.byId[postId];

    if (!form) return state;
    const formPost = { ...form.post };
    let newPlatformFields = { ...form.platformFields };

    fields.map((fieldString) => {
      const fieldParts = fieldString.split(".");
      const field = fieldParts[fieldParts.length - 1];
      const value = values[fieldString];

      if (field === FIELDS.POST_STATE) {
        form.postState = value;
        return;
      } else if (field === FIELDS.ACCOUNTS) {
        form.platforms = getPlatforms(value);
        form.accounts = value;
        formPost.accounts = value;
        formPost.accountIds = _.map(value, "_id");
        if (formPost.accounts.length === 0) {
          // if there are not selected account reset value to initial
          form.view = initialFormState.view;
        }
        return;
      } else if (field === FIELDS.MEDIA) {
        let mediaFiles = value;
        formPost.mediaFiles = mediaFiles;
        formPost.media = mediaFiles;

        // Remove media file fields so that preview knows it's a text post;
        if (mediaFiles.length === 0) {
          delete formPost.fileName;
          delete formPost.isVideo;
          delete formPost.origUrl;
          delete formPost.thumbnailUrl;
          delete formPost.url;
        }

        if (mediaFiles.length > 0) {
          // Need to set the top level of the post since it's used all over
          formPost["altText"] = mediaFiles[0].altText;
          formPost["url"] = mediaFiles[0].url;
          formPost["meta"] = mediaFiles[0].meta;
          formPost["fileName"] = mediaFiles[0].fileName;
          formPost["thumbnailUrl"] = mediaFiles[0].thumbnailUrl;
          formPost["isVideo"] = mediaFiles[0].isVideo;

          if (!formPost["origUrl"]) {
            formPost["origUrl"] = mediaFiles[0].originalUrl;
            formPost["originalUrl"] = mediaFiles[0].originalUrl;
          }

          if (!formPost["originalUrl"]) {
            formPost["origUrl"] = mediaFiles[0].originalUrl;
            formPost["originalUrl"] = mediaFiles[0].originalUrl;
          }

          // Single asset posts don't have a media files unfortunately.
          if (mediaFiles.length === 1) {
            formPost["altText"] = mediaFiles[0].altText;
            formPost["origUrl"] = mediaFiles[0].originalUrl;
            formPost["originalUrl"] = mediaFiles[0].originalUrl;
          }
        }

        formPost["type"] = getPostType(formPost);
        return;
      }

      // fields that should be applied to all platforms, not only selected one
      const allPlatformsFields = [
        FIELDS.WHEN,
        FIELDS.IS_NOW,
        FIELDS.POST_STATUS,
      ];

      const isUnlinkedClusterPost =
        form.view.selectedPlatform !== "LINKED" && form.platforms.length > 1;
      const isAllPlatformField = allPlatformsFields.includes(field);
      // Cluster post
      if (isUnlinkedClusterPost && !isAllPlatformField) {
        let selectedPlatform = form.view.selectedPlatform;
        if (!newPlatformFields[selectedPlatform]) {
          newPlatformFields[selectedPlatform] = {};
        }

        _.set(newPlatformFields[selectedPlatform], fieldString, value);
      } else {
        _.set(formPost, fieldString, value);
      }

      // Need to set fields that may not have been set prior
      if (field === FIELDS.IS_STORY && value === false) {
        formPost[FIELDS.CAPTION] = formPost[FIELDS.CAPTION] || "";
        formPost[FIELDS.FIRST_COMMENT] = formPost[FIELDS.FIRST_COMMENT] || "";
        formPost[FIELDS.USER_TAGS] = formPost[FIELDS.USER_TAGS] || [];
        formPost[FIELDS.PRODUCT_TAGS] = formPost[FIELDS.PRODUCT_TAGS] || [];
      }

      // May need to do a type update (i.e. story toggle)
      formPost["type"] = getPostType(formPost);
    });

    form.platformFields = newPlatformFields;
    form.post = formPost;

    // Validate form after update
    const [errors, warnings] = validate(
      form.postState,
      form.post,
      form.platformFields,
      form.platforms,
      form.overlapSchedule
    );

    form.validation = {
      errors,
      warnings,
    };

    state.byId[postId] = form;
    return state;
  },
  [CHECK_INSTAGRAM_POST_OVERLAPPING.SUCCESS]: (
    state,
    { payload: { postId, data } }
  ) => {
    state.byId[postId].overlapSchedule = data;

    const form = state.byId[postId];

    const [errors, warnings] = validate(
      form.postState,
      form.post,
      form.platformFields,
      form.platforms,
      form.overlapSchedule
    );

    form.validation = {
      errors,
      warnings,
    };

    state.byId[postId] = form;

    return state;
  },
});

const buildInitialPost = (post, accounts) => {
  const normalizedPost = post && post.schedule ? post.schedule : post;
  const accountIds = _.map(accounts, "_id");

  let postCopy = {
    ...normalizedPost,
    isStory: normalizedPost.type === POST_TYPES.STORY,
    accounts: accounts,
    accountIds: accountIds,
  };

  // because with drafts, post.schedule will not contain the ID
  if (!postCopy._id && post._id) {
    postCopy._id = post._id;
  }

  delete postCopy.postState;

  if (
    normalizedPost.when &&
    typeof normalizedPost.when === "string" &&
    normalizedPost.when.toLowerCase() === "invalid date"
  ) {
    postCopy.when = null;
  }

  // We use both of these but the previewer relies on originalUrl
  if (postCopy.origUrl && !postCopy.originalUrl) {
    postCopy["originalUrl"] = postCopy.origUrl;
  }

  // Some story posts don't set original url
  if (!postCopy.origUrl && !postCopy.originalUrl && postCopy.url) {
    postCopy["originalUrl"] = postCopy.url;
    postCopy["origUrl"] = postCopy.url;
  }

  if (_.isEmpty(postCopy.mediaFiles)) {
    // There is a bug where a story that has a single video doesn't set isVideo
    if (postCopy.isStory && postCopy.originalUrl.includes(".mp4")) {
      postCopy.isVideo = true;
    }
  }

  return postCopy;
};

const getVisibleFields = (selectedPlatform, platforms) => {
  if (selectedPlatform === null) {
    return [];
  }
  let fields = [];
  if (selectedPlatform === "LINKED") {
    platforms.map((platform) => {
      let platformFields = postPlatformsConfig[platform.TYPE];
      if (platformFields) {
        fields = fields.concat(platformFields.fields);
      }
    });
  } else {
    fields = postPlatformsConfig[selectedPlatform].fields;
  }
  fields = _.uniq(fields);
  return fields;
};

const getFirstForm = (state) => {
  const id = state.allIds[0];
  return state.byId[id];
};

export default postFormsReducer;
