import axios from "axios";

import { Post, PostType } from "features/post/types";
import { CalendarPostType } from "features/calendar-new/types";

const initialData = {
  page: 1,
  pageSize: 1000,
  "accountIds[]": [],
};

export async function fetchCalendar(data: any): Promise<{
  data: { posts: PostType[] };
}> {
  return await axios.post("/calendar/posts", {
    ...initialData,
    ...data,
  });
}

export async function updateScheduledPost(
  postId: string,
  post: CalendarPostType
): Promise<{
  data: any;
}> {
  return await axios.put(`/schedules/${postId}`, post);
}
export async function updateDraftPost(post: CalendarPostType): Promise<{
  data: any;
}> {
  return await axios.put(`/drafts/${post._id}`, {
    _id: post._id,
    accountIds: post.accountIds,
    schedule: post,
  });
}

export async function scheduleDraft(post: Post): Promise<{
  data: any;
}> {
  const data = {
    _id: post._id,
    // how this works previously??
    accountIds: post.accountIds,
    schedule: post,
    when: post.when,
  };

  return await axios.post(`/drafts/${post._id}/schedule`, data);
}

export async function deleteDraft(postId: string): Promise<{
  data: any;
}> {
  return await axios.post(`/drafts/bulk/delete`, { postIds: [postId] });
}

export async function removePastQueue(postId: string): Promise<{
  data: any;
}> {
  return await axios.delete(`/schedules/${postId}`);
}

export async function removeScheduledQueue(postId: string): Promise<{
  data: any;
}> {
  return await axios.delete(`/queue/${postId}`);
}

export async function removeSchedule(
  postId: string,
  isActive: boolean
): Promise<{
  data: any;
}> {
  return await axios.delete(
    `/schedules/${isActive ? "active" : "inactive"}/${postId}`
  );
}

export async function sendCalendarFeedback(data: {
  feedback: string;
}): Promise<{
  data: any;
}> {
  return await axios.post(`/user/calendarFeedback `, data);
}
