import { ChangeEvent, KeyboardEvent, RefObject } from "react";
import styled, { css } from "styled-components";

import { FormSize, formSizeStyles } from "./form-theme-provider";

interface Props {
  isInvalid?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  secondary?: boolean;
  middle?: boolean;
  as?: any;
  ref?: RefObject<any>;
  onKeyDown?: (e: KeyboardEvent) => void;

  type?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const focusCss = css`
  border-color: #3c5bcc;
  outline: none;
`;

export const placeholderCss = css`
  color: var(--placeholderColor);
`;

export const Placeholder = styled.div`
  ${placeholderCss}
`;

export const InputWrapperBaseCss = css`
  border: 1px solid #cacaca;
  --inputHeight: 38px;
  --focusColor: #4f31ff;
  font-size: 10px;
  line-height: 12px;
  box-sizing: border-box;
  height: var(--inputHeight);

  :focus {
    border-color: var(--focusColor);
    outline: none;
  }

  ${formSizeStyles(
    FormSize.small,
    css`
      --inputHeight: 32px;
      font-size: 12px;
      line-height: 14px;
    `
  )}

  ${formSizeStyles(
    FormSize.medium,
    css`
      --inputHeight: 38px;
      font-size: 12px;
      line-height: 14px;
    `
  )}

  ${formSizeStyles(
    FormSize.large,
    css`
      --inputHeight: 49px;
      font-size: 14px;
      line-height: 17px;
    `
  )}
`;

export const InputWrapper = styled.div<Props>`
  ${InputWrapperBaseCss};
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 9px 14px 9px 10px;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 5px;
`;
