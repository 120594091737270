import { put, take, takeEvery } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { closePostForm } from "features/post";
import { savePostForm } from "../../actions/postForm";
import { SAVE_POST_FORM_ROUTINE } from "../../constants/ActionTypes";
import { registerModal } from "libs/modals";
import { AddInstagramSuggestion } from "./components/add-instagram-account-suggestion";

export const addInstagramSuggestionModal = registerModal(
  "PLANNER_PAGE_ADD_INSTAGRAM_SUGGESTION",
  AddInstagramSuggestion
);

export const plannerPageScheduleClusterPost = createAction(
  "plannerPageScheduleClusterPost"
);

function* scheduleClusterPostSaga({ payload: { initialPost, scheduledPost } }) {
  yield put(savePostForm(scheduledPost));
  yield take(SAVE_POST_FORM_ROUTINE.SUCCESS);
  yield put(closePostForm(initialPost));
}

export function* planerPageSaga() {
  yield takeEvery(plannerPageScheduleClusterPost.type, scheduleClusterPostSaga);
}
