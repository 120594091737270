import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";
import { SkedButton, TextInput } from "ui";

import { getFormPost } from "selectors/postFormSelectors";
// @ts-ignore
import { getMedia } from "components/Posts/Post/PostMedia/PostMedia";
import { NgMediaFile, NgPost } from "features/post/format-post";

interface AltTextProps {
  post: NgPost;
  onClose: void;

  onSubmit(value: NgMediaFile[]): void;

  toggleAddAlt(): void;

  handleImageAltUpdate(value: string | unknown): void;
}

function AltTextModalComponent({
  toggleAddAlt,
  onSubmit,
  post,
  handleImageAltUpdate,
}: AltTextProps) {
  const [mediaFiles, setMediaFiles] = useState<NgMediaFile[]>([]);
  const currentPost = useSelector((state) => getFormPost(state, post._id));

  useEffect(() => {
    const media = getMedia(currentPost);
    setMediaFiles(media);
  }, [currentPost]);

  function handleSubmit() {
    if (mediaFiles.length === 1) {
      handleImageAltUpdate(mediaFiles[0].altText);
    }
    onSubmit(mediaFiles);
    toggleAddAlt();
  }

  function handleChange(value: string, url: string) {
    const newMedia = mediaFiles.map((item) => {
      if (item.url === url) {
        return {
          ...item,
          altText: value,
        };
      }
      return item;
    });
    setMediaFiles(newMedia);
  }

  function handleClear() {
    const newMedia = mediaFiles.map((item) => {
      return {
        ...item,
        altText: "",
      };
    });
    setMediaFiles(newMedia);
  }

  return (
    <ModalContainer
      show
      animation={true}
      onHide={toggleAddAlt}
      // @ts-ignore
      bsStyle="giselle add-alt-modal"
      bsSize="sm"
      data-testid="altTextModal"
    >
      <ModalStyle />
      <Modal.Header closeButton>
        <Modal.Title>Add/Edit alt text</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alternative>
          <p>
            Adding alternative text allows better accessibility. Our alt text
            support on <strong>Instagram</strong> is in beta, and it is fully
            supported for LinkedIn, Twitter, Facebook and Pinterest.
          </p>
          <p>
            Your alt text should be a (very) short description of what is in the
            image, like 'a sunflower in a sunny field'. It's not a repeat of the
            caption.
          </p>
          <p>Alt text is supported only on images.</p>
        </Alternative>
        {mediaFiles.length > 1 &&
          mediaFiles
            .filter((item) => !item.isVideo)
            .map((media) => (
              <MediaContainer key={media.thumbnailUrl}>
                <PreviewContainer>
                  <Preview src={media.thumbnailUrl} alt="" />
                </PreviewContainer>
                {/* Alt text isn't meant to be big, Pinterest fails > 500 chars */}
                <TextInput
                  maxLength={350}
                  value={media.altText}
                  placeholder="Write alt text..."
                  onChange={(value) => handleChange(value, media.url)}
                />
              </MediaContainer>
            ))}
        {mediaFiles.length === 1 &&
          mediaFiles
            .filter((item) => !item.isVideo)
            .map((media) => (
              <MediaContainer key={media.thumbnailUrl}>
                <PreviewContainer>
                  <Preview src={media.thumbnailUrl} alt="" />
                </PreviewContainer>
                {/* Alt text isn't meant to be big, Pinterest fails > 500 chars */}
                <TextInput
                  maxLength={350}
                  value={media.altText}
                  placeholder="Write alt text..."
                  onChange={(value) => handleChange(value, media.url)}
                />
              </MediaContainer>
            ))}
      </Modal.Body>
      <Modal.Footer>
        <Clear className="btn link" onClick={handleClear}>
          Clear All
        </Clear>
        {/* @ts-ignore */}
        <Save onClick={handleSubmit} type="primary" size="medium">
          Save
        </Save>
      </Modal.Footer>
    </ModalContainer>
  );
}

export const AltTextModal = styled(AltTextModalComponent)``;

const ModalContainer = styled(Modal)``;

const Clear = styled.button`
  margin-left: 0;
  padding-left: 0;
  color: #fa4f38 !important;
`;

const Alternative = styled.div`
  margin: 0;
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.025em;
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  min-width: 74px;
  min-height: 74px;
  margin-right: 28px;
`;

const Preview = styled.img`
  max-width: 74px;
  max-height: 74px;
`;

const Save = styled(SkedButton)`
  button {
    width: 159px;
    height: 51px;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.025em;
  }
`;

// block mouse events style
const ModalStyle = createGlobalStyle`
  .user-delete:before {
    content: "";
    position: fixed;
    top: -100%;
    left: -100%;
    right: -100%;
    height: calc(100vh + 230px);
    background: rgba(0, 0, 0, 0.6);
  }
`;
