import {
  createEvent,
  createStore,
  Store,
  Event,
  combine,
  Effect,
  Unit,
  forward,
} from "effector";
import { normalizeAccounts } from "utils/accounts";

function getIdKeyDefault(item) {
  return item._id;
}

const isTableSymbol = Symbol();
const getIdKeySymbol = Symbol();

type Key = string;

interface TableStore<T> {
  ids: Key[];
  byId: {
    [key: string]: T;
  };
}

type IdsStore = Store<string[]> | Store<number[]> | Store<Set<string>>;

interface Table<T> {
  [isTableSymbol]: true;
  [getIdKeySymbol]: (item: T) => Key;
  addItem: Event<T>;
  addItems: Event<T[]>;
  updateItem: Event<Partial<T>>;
  replaceItem: Event<T>;
  replaceAll: Event<T>;
  removeItem: Event<Key>;

  getById: (
    idStore: Store<string | null> | Store<string> | Store<null> | string | null
  ) => Store<T | null>;
  getByIds: (idStore: IdsStore) => Store<T[]>;
  filter: (idStore: IdsStore, filter: (item: T) => boolean) => Store<string[]>;
  getAll(): Store<T[]>;

  $ids: Store<Key[]>;
  $byId: Store<{
    [key: string]: T;
  }>;
}

interface TableParams<T> {
  getIdKey?: (item: T) => string;
}

export function createTable<T extends { _id: Key }>(): Table<T>;
export function createTable<T>(options: {
  getIdKey?: (item: T) => Key;
}): Table<T>;
export function createTable<T>(options: TableParams<T> = {}): Table<T> {
  const getIdKey = options?.getIdKey ?? getIdKeyDefault;

  const $table = createStore<TableStore<T>>({
    ids: [],
    byId: {},
  });

  const addItem = createEvent<T>();
  const addItems = createEvent<T[]>();
  const updateItem = createEvent<Partial<T>>();
  const replaceItem = createEvent<T>();
  const replaceAll =
    createEvent<{ ids: string[]; byId: { [key: string]: T } }>();
  const removeItem = createEvent<Key>();

  $table
    .on(addItem, ({ ids, byId }, item) => {
      const itemId = getIdKey(item);
      const newIds = byId[itemId] ? ids : [...ids, itemId];
      return {
        byId: {
          ...byId,
          [getIdKey(item)]: normalizeAccounts([item])[0],
        },
        ids: newIds,
      };
    })
    .on(addItems, ({ ids, byId }, items) => {
      const newById = { ...byId };
      const newIds = [...ids];

      normalizeAccounts(items).forEach((item) => {
        const id = getIdKey(item);
        if (!newById[id]) {
          newIds.push(id);
        }
        newById[id] = item;
      });

      return {
        byId: newById,
        ids: newIds,
      };
    })
    .on(updateItem, ({ ids, byId }, item) => {
      return {
        byId: {
          ...byId,
          [getIdKey(item)]: {
            ...byId[getIdKey(item)],
            ...item,
          },
        },
        ids,
      };
    })
    .on(replaceAll, (_, newData) => {
      return {
        byId: newData.byId,
        ids: newData.ids,
      };
    })
    .on(replaceItem, ({ ids, byId }, item) => {
      return {
        byId: {
          ...byId,
          [getIdKey(item)]: item,
        },
        ids,
      };
    })
    .on(removeItem, (state, itemId) => {
      const {
        byId: { [itemId]: deletedItem, ...newById },
        ids,
      } = state;

      return {
        byId: newById,
        ids: ids.filter((id) => id !== itemId),
      };
    });

  function getById($id: Store<string>) {
    return combine({ table: $table, id: $id }, ({ table, id }) => {
      if (!id) return null;
      return table.byId[id] ?? null;
    });
  }

  function getByIds($ids: IdsStore) {
    return combine({ table: $table, ids: $ids }, ({ table, ids }) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [...ids].map((id) => table.byId[id]).filter(Boolean)
    );
  }

  function getAll() {
    return getByIds($table.map(({ ids }) => ids));
  }

  function filter($ids: IdsStore, filter: (item: T) => boolean) {
    return getByIds($ids).map((items) => items.filter(filter).map(getIdKey));
  }

  return {
    [isTableSymbol]: true,
    [getIdKeySymbol]: getIdKey,
    addItem,
    addItems,
    updateItem,
    replaceItem,
    replaceAll,
    removeItem,
    getById,
    getByIds,
    getAll,
    filter,

    $ids: $table.map(({ ids }) => ids),
    $byId: $table.map(({ byId }) => byId),
  };
}

export function isTable(object: unknown) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return object?.[isTableSymbol] === true;
}

export function fet<T, E>({
  table,
  effect,
  target,
}: {
  table: Table<T>;
  effect: Effect<E, T[]>;
  target: Unit<Key[]>;
}) {
  forward({
    from: effect.doneData,
    to: table.addItems,
  });

  forward({
    from: effect.doneData.map((data) =>
      data.map((item) => table[getIdKeySymbol](item))
    ),
    to: target,
  });
}
