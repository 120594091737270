import React, { useMemo, useCallback, useState, useEffect } from "react";
import {
  PostFieldLabel,
  PostFieldCounter,
  PlatformUsage,
} from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import { useDebouncedCallback } from "use-debounce";
import { TextInput } from "ui/ecosystems/forms/inputs";
import { Alert, AlertType } from "ui/alert";
import { ExternalLink } from "ui/Link";

const YouTubeTitle = ({
  url,
  post,
  field,
  checkSetting = false,
  selectedPlatform,
  selectedAccounts,
  platforms,
  title,
  className = "",
  onChange,
  disabled = false,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (post["ytTitle"]) {
      setValue(post["ytTitle"]);
    }
  }, []);

  const bounce = useDebouncedCallback((value) => {
    let title = post["ytTitle"];
    if (value != title) {
      onChange(value);
    }
  }, 200);

  return (
    <div className={className}>
      <div className="tw-flex tw-flex-col tw-mb-4">
        <div className="tw-flex tw-items-center">
          <PostFieldLabel className="tw-mb-2 tw-mr-2">{title}</PostFieldLabel>
          <PlatformUsage
            platforms={platforms}
            field={FIELDS.YT_TITLE}
            selectedPlatform={selectedPlatform}
          />
        </div>
        <Alert
          type={AlertType.info}
          isExpandable={true}
          title={"Post a YouTube #short?"}
        >
          If your video meets{" "}
          <ExternalLink
            href={`https://help.skedsocial.com/en/articles/6497825-post-youtube-shorts-with-sked`}
          >
            the requirements
          </ExternalLink>
          , add #shorts to your video title or caption to post this video as a
          YouTube short.{" "}
          <ExternalLink
            href={`https://help.skedsocial.com/en/articles/6497825-post-youtube-shorts-with-sked`}
          >
            Read more
          </ExternalLink>
          .
        </Alert>
        <TextInput
          value={value}
          disabled={disabled}
          placeholder="The title of your video (required)"
          onChange={(value) => {
            const newVal = value;
            setValue(newVal);
            bounce(newVal);
          }}
        />
        <PostFieldCounter
          count={value.length}
          max={100}
          className="tw-flex-grow tw-text-right tw-mt-2 tw-mr-8"
        >
          Characters
        </PostFieldCounter>
      </div>
    </div>
  );
};

export default YouTubeTitle;
