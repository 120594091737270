import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import _, { throttle } from "lodash";
import { Manager, Popper, Reference } from "react-popper";
import util from "./utils/util";
import { EmojiPicker } from "./components/emoji-picker";
import { SuggestionsPopup } from "ui/suggestions-popup";
import { createEmoji, restoreEmoji } from "./services/emojiService";
import { TemplatesSelector } from "./components/templates-selector";
import {
  DescriptionsList,
  LibraryDescriptionPicker,
} from "./components/library-descriptions-picker";
import { TooltipWrapper } from "ui";
import { HashtagRecommend } from "./components/tag-recommendation/hashtag-recommend";
import Hash from "./icons/hash.svg";
import { getInsertText } from "./utils/autocomplete";
import { SuggestOptionView } from "./components/suggest-option-view";
import {
  getLocalStorageMentionAutocompleteLi,
  setLocalStorageMentionAutocompleteLi,
} from "libs/storage/adapters";
import { removeZeroWidthSpace } from "utils/text";
import { Icon, Tooltip } from "@dialogueconsulting/sked-ui";

const TAGS_BLOCK = ["p", "div", "pre", "form"];

const tagTypes = {
  hashtag: 1,
  mention: 2,
  template: 3,
};

export default class SkedCaption extends React.Component {
  constructor(props) {
    super(props);
    this.changeTimer = null;
    this.lastPrefix = null;
    this.tagsHovered = false;
    this.prevent_blur = false;
    this.state = {
      activeTagIndex: 0,
      suggestedTags: {
        type: tagTypes.hashtag,
        tags: [],
      },
      tags: [],
      focused: false,
      caretPositionNodeIdx: 0,
      caretPositionOffset: 0,
      hideTagSuggestions: true,
      descriptionPickerState: false,
      suggestionsDisabledForever: false,
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.sectionBlur = this.sectionBlur.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.addTag = this.addTag.bind(this);
    this.toggleTagSuggest = this.toggleTagSuggest.bind(this);
    this.handleLightBulbClick = this.handleLightBulbClick.bind(this);
    this.insertEmoji = this.insertEmoji.bind(this);
    this.selectTag = this.selectTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.insertTags = this.insertTags.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.toggleDescriptionPicker = this.toggleDescriptionPicker.bind(this);
    this.insertDescription = this.insertDescription.bind(this);
    this.requestTags = throttle(this.requestTags.bind(this), 300);
    this.isLinkedIn = this.props.selectedPlatform === "LI";
    this.disableMention = this.props.disableMention;
  }

  restoreContent(val) {
    let html = restoreEmoji(val);
    html = html.replace(/(?:\r\n|\r|\n)/g, "<br>");
    return html;
  }

  updateInitialValue(val) {
    this.editor.innerHTML = this.restoreContent(val);
  }

  componentDidMount() {
    const { initialValue, baseCaption, ref } = this.props;
    if (initialValue && this.editor) {
      this.editor.innerHTML = this.restoreContent(initialValue);
      if (this.editor.innerHTML) {
        const matches =
          this.editor.innerHTML.toLowerCase().match(/\#[A-Za-z0-9\-\.\_]+/gi) ||
          [];
        this.setState({
          tags: matches.map((tag) => tag.replace(/\^#/, "")),
        });
      }
    }

    // Allow an initial base caption to be passed in.
    if (this.editor && baseCaption) {
      let myHTML = this.editor.innerHTML.replace(
        /\<basecaption.+\<\/basecaption\>/g,
        ""
      );
      const newBase = restoreEmoji(baseCaption);
      myHTML =
        '<basecaption contenteditable="false">' +
        newBase +
        "</basecaption>" +
        myHTML;
      this.editor.innerHTML = myHTML;
    }

    const selection = document.createRange();
    selection.setStart(this.editor, 0);
    selection.setEnd(this.editor, 0);
    this.selection = [selection];
    this.observer = new MutationObserver(this.handleChange);
    this.observer.observe(this.editor, {
      childList: true,
      characterData: true,
      subtree: true,
    });
    document.body.addEventListener("mousedown", this.handleMouseDown);
    if (ref) {
      ref(React.forwardRef(this));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.baseCaption === nextProps.baseCaption) return;
    restoreEmoji(this.props.baseCaption);
    let myHTML = this.editor.innerHTML.replace(
      /\<basecaption.+\<\/basecaption\>/g,
      ""
    );

    if (nextProps.baseCaption) {
      const newBase = restoreEmoji(nextProps.baseCaption);
      myHTML =
        '<basecaption contenteditable="false">' +
        newBase +
        "</basecaption>" +
        myHTML;
    }
    this.editor.innerHTML = myHTML;
  }

  componentWillUnmount() {
    document.body.removeEventListener("mousedown", this.handleMouseDown);
    clearTimeout(this.changeTimer);
    this.observer.disconnect();
  }

  handleMouseDown() {
    if (this.hasFocus) {
      this.selection = util.saveSelection();
    }
  }

  handleMouseUp(ev) {
    if (!this.props.baseCaption) return;
    this.getCaretPosition(this.editor);
    this.checkContentEdit(ev);
  }

  handleChange() {
    if (!this.ignoreChange) {
      this.ignoreChange = false;
      this.suggestTags();
    }
    this.notifyChange();
  }

  handlePaste(event) {
    const clipboardData = event.clipboardData || window.clipboardData;
    let text = clipboardData.getData("text/plain");

    if (text.length) {
      // strip zero width space characters
      text = removeZeroWidthSpace(text);
      const html = this.restoreContent(text);
      //TODO restore emoji
      document.execCommand("insertHTML", false, html);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleDrop(event) {
    const { dataTransfer } = event;
    if (dataTransfer) {
      const text = dataTransfer.getData("text/plain");
      const html = this.restoreContent(text);
      const el = document.createElement("span");
      el.innerHTML = html;
      this.insertHtml(el);
    }
    event.preventDefault();
  }

  handleDelete = () => {
    this.editor.innerHTML = "";
  };

  setSuggestedTags = (
    tags,
    tagType,
    hasMoreThanOnelinkedInAccount,
    selectedLinkedInAccountType
  ) => {
    tags = _.uniqBy([...tags], this.isLinkedIn ? "urn" : undefined);

    if (
      (this.isLinkedIn && hasMoreThanOnelinkedInAccount) ||
      selectedLinkedInAccountType === "personal"
    ) {
      tags = tags.filter((tag) => !tag.urn.includes("person"));
    }

    this.setState({
      suggestedTags: {
        type: tagType,
        tags,
      },
    });
  };

  requestTags(tagType, tagsReq, searchText) {
    this.cancelPendingRequest();
    this.pendingRequest = axios.CancelToken.source();
    const tagsFromStorage = (
      this.isLinkedIn ? getLocalStorageMentionAutocompleteLi() || [] : []
    ).filter((mention) => {
      return mention[this.isLinkedIn ? "name" : "name"]
        ? mention[this.isLinkedIn ? "name" : "name"]
            .toLowerCase()
            .includes(searchText.toLowerCase())
        : false;
    });
    if (tagsFromStorage.length) {
      this.setSuggestedTags(
        tagsFromStorage,
        tagType,
        this.props.hasMoreThanOnelinkedInAccount,
        this.props.selectedLinkedInAccountType
      );
    }
    tagsReq(this.pendingRequest.token)
      ?.then((tags = []) => {
        this.setSuggestedTags(
          [...tags, ...tagsFromStorage],
          tagType,
          this.props.hasMoreThanOnelinkedInAccount,
          this.props.selectedLinkedInAccountType
        );
      })
      .catch((error) => {
        if (!error.__CANCEL__) {
          throw error;
        }
      });
  }

  cancelPendingRequest() {
    if (this.pendingRequest) {
      this.pendingRequest.cancel();
    }
  }

  getPrefix() {
    const selection = util.getSelection(),
      wordRegex = /[0-9a-zA-Z_#@\/]/;

    for (let i = selection.startIndex - 1; i > -1; i--) {
      const chr = selection.text[i];
      if (!chr || !wordRegex.test(chr)) this.clearTags();
      switch (chr) {
        case "#":
        case "@":
          // NOTE: ignore @[123](asd) syntax
          if (selection.text[i + 1] !== "[") {
            const prefix = selection.text.slice(i + 1, selection.startIndex);
            return {
              type: chr === "#" ? tagTypes.hashtag : tagTypes.mention,
              text: prefix,
            };
          }
          break;
        case "/":
          if (selection.text[i - 1] === "/") {
            return {
              type: tagTypes.template,
              text: selection.text.slice(i + 1, selection.startIndex),
            };
          }
          break;
      }
    }
  }

  suggestTags(key = "") {
    if (this.disableMention) {
      return false;
    }

    this.clearTags();
    const prefix = this.getPrefix();
    if (prefix) {
      const text = prefix.text + key.trim();
      this.lastPrefix = prefix.type;
      switch (prefix.type) {
        case tagTypes.hashtag:
          return this.requestTags(
            tagTypes.hashtag,
            (ct) => this.props.autocomplete?.hashtag?.suggest(text, ct),
            text
          );
        case tagTypes.mention:
          if (!this.props.hashtagOnly) {
            return this.requestTags(
              tagTypes.mention,
              (ct) =>
                this.props.autocomplete?.mention?.suggest(
                  text,
                  ct,
                  this.props.selectedLinkedUrn
                ),
              text
            );
          }
          break;
        case tagTypes.template:
          return this.requestTags(
            tagTypes.template,
            (ct) => this.props.autocomplete?.template?.suggest(text, ct),
            text
          );
      }
    }
  }

  clearTags() {
    this.cancelPendingRequest();
    this.tagsHovered = false;
    this.setState({
      suggestedTags: { type: tagTypes.hashtag, tags: [] },
      activeTagIndex: 0,
    });
  }

  handleKeyPress(event) {
    const { suggestions, selectedPlatform } = this.props;
    const { key } = event;
    const { lastKey } = this;

    if (this.props.onKeyPress) {
      this.props.onKeyPress();
    }

    if (!suggestions) {
      this.clearTags();
    } else if (key === "#") {
      this.lastPrefix = tagTypes.hashtag;
      this.requestTags(
        tagTypes.hashtag,
        (ct) => this.props.autocomplete?.hashtag?.suggest("", ct),
        ""
      );
    } else if (key === "@" && !this.props.hashtagOnly) {
      this.lastPrefix = tagTypes.mention;
      this.requestTags(
        tagTypes.mention,
        (ct) => {
          return this.props.autocomplete?.mention?.suggest("", ct);
        },
        ""
      );
    } else if (key === " " && this.lastPrefix === tagTypes.hashtag) {
      this.notifyChange();
    } else {
      if (key === "/" && lastKey === "/") {
        this.lastPrefix = tagTypes.template;
        this.requestTags(
          tagTypes.template,
          (ct) => this.props.autocomplete?.template?.suggest("", ct),
          ""
        );
      } else if (key !== " " && key !== "Enter") {
        //selection remains at the last position
        this.suggestTags(key);
      }
    }

    this.lastKey = key;
  }

  handleEnterButton(event) {
    let canInsert = false;
    try {
      canInsert = document.execCommand("insertBrOnReturn", false, true);
    } catch (err) {}

    if (canInsert) return true;
    if (window.getSelection) {
      event.stopPropagation();
      event.preventDefault();

      var selection = window.getSelection(),
        range = selection.getRangeAt(0),
        br = document.createElement("br"),
        textNode = document.createTextNode("\u200B"); //Passing " " directly will not end up being shown correctly
      range.deleteContents(); //required or not?
      range.insertNode(br);
      range.collapse(false);
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      // range.selectNodeContents(textNode);

      selection.removeAllRanges();
      selection.addRange(range);
      return false;
    }
    return true;
  }

  checkContentEdit(event) {
    if (!this.props.baseCaption) return;
    const keyCodes = [36, 37, 38, 8];
    const caretPosition = this.getCaretPosition(this.editor);
    if (caretPosition.caretOffset === 0) return this.jumpAfterCaption(event);
    if (caretPosition.caretOffset === 1 && keyCodes.includes(event.which))
      return this.jumpAfterCaption(event);
  }

  jumpAfterCaption(event) {
    const { childNodes } = this.editor,
      range = document.createRange(),
      sel = window.getSelection();
    if (event) event.preventDefault();
    if (!childNodes.length) return;
    range.setStartAfter(childNodes[0]);
    range.setEndAfter(childNodes[0]);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    this.editor.focus();
  }

  handleKeyDown(event) {
    const { activeTagIndex, suggestedTags } = this.state;
    const { selectedPlatform } = this.props;

    if (this.props.onKeyDown) {
      this.props.onKeyDown();
    }

    if (event.which === 13 && suggestedTags.tags.length === 0) {
      return this.handleEnterButton(event);
    }

    if (suggestedTags.tags.length === 0) {
      return this.checkContentEdit(event);
    }

    let newTagIndex = activeTagIndex;
    switch (event.which) {
      case 13: // enter
        if (suggestedTags.tags.length > 0) {
          this.ignoreChange = true;
          return this.selectTag(
            suggestedTags.type,
            suggestedTags.tags[activeTagIndex],
            event
          );
        }
        break;
      case 38: // up arrow
        if (--newTagIndex < 0) {
          newTagIndex = suggestedTags.tags.length - 1;
        }
        break;
      case 40: // down arrow
        if (++newTagIndex >= suggestedTags.tags.length) {
          newTagIndex = 0;
        }
        break;
      case 27: //ESC
        return this.clearTags();
      case 8: //Backspace
      case 46: //DEL
        // just continue call because char not deleted here -> next one is handleChange
        this.ignoreChange = false;

        return;
      case 32: //space && chars
      default:
        this.checkContentEdit(event);
        return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.setState({ activeTagIndex: newTagIndex });
  }

  selectTag(tagType, tag, event) {
    let textToInsert = getInsertText(tag);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.selection) {
      util.restoreSelection(this.selection);
    }
    const prefix = this.getPrefix();
    let removePrefix;
    if (prefix) {
      if (prefix.type === tagTypes.template) {
        removePrefix = `//${prefix.text}`;
      } else {
        removePrefix = `${prefix.text}`;
      }
    }
    this.ignoreChange = true;
    //TODO <tag>
    this.isLinkedIn && setLocalStorageMentionAutocompleteLi(tag);
    this.insertHtml(textToInsert, removePrefix);
    this.clearTags();
  }

  isRemovable(type) {
    switch (type) {
      case tagTypes.hashtag:
        return Boolean(this.props.autocomplete.hashtag.remove);
      case tagTypes.mention:
        return Boolean(this.props.autocomplete.mention.remove);
      default:
        return false;
    }
  }

  async removeTag(type, tag) {
    switch (type) {
      case tagTypes.hashtag:
        await this.props.autocomplete.hashtag.remove(tag);
        break;
      case tagTypes.mention:
        await this.props.autocomplete.mention.remove(tag);
        break;
    }
    this.setState(
      (state) => ({
        ...state,
        suggestedTags: {
          type: state.suggestedTags.type,
          tags: state.suggestedTags.tags.filter((t) => t !== tag),
        },
      }),
      () => {
        this.notifyChange();
      }
    );
  }

  handleFocus() {
    this.selection = null;
    this.hasFocus = true;

    this.setState({
      focused: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  handleBlur(event) {
    this.hasFocus = false;
    if (this.state.suggestedTags.tags.length > 0 && this.tagsHovered) {
      event.preventDefault();
    } else {
      this.clearTags();
    }
    this.notifyChange();
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  getCaret(el) {
    if (el.selectionStart) {
      return el.selectionStart;
    } else if (document.selection) {
      el.focus();

      var r = document.selection.createRange();
      if (r == null) {
        return 0;
      }

      var re = el.createTextRange(),
        rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint("EndToStart", re);

      return rc.text.length;
    }
    return 0;
  }

  notifyChange() {
    this.updateTags();
    if (this.props.onChange) {
      this.props.onChange(this.normaliseValue());
    }
  }

  normaliseValue = () => {
    const { childNodes } = this.editor;
    const lines = [];
    let line = [];
    const flush = () => {
      lines.push(line.join(""));
      line = [];
    };

    const sanitizeNode = (node) => {
      let isBlock;
      if (node.nodeName === "BASECAPTION") return;
      const { childNodes } = node;

      switch (node.nodeType) {
        case Node.TEXT_NODE:
          line.push(node.nodeValue);
          break;
        case Node.ELEMENT_NODE: {
          const tagName = node.tagName.toLowerCase();
          isBlock = TAGS_BLOCK.indexOf(tagName) !== -1;
          if (isBlock && line.length) flush();

          switch (tagName) {
            case "img":
              const emoji = node.getAttribute("data-emoji-native");
              if (emoji) {
                line.push(emoji);
              }
              return;
            case "br":
              flush();
              break;
          }
          break;
        }
      }

      for (let i = 0; i < childNodes.length; i++) {
        sanitizeNode(childNodes[i]);
      }

      if (isBlock && line.length) flush();
    };

    for (let i = 0; i < childNodes.length; i++) {
      sanitizeNode(childNodes[i]);
    }

    if (line.length) flush();
    return lines.join("\n");
  };

  insertEmoji(emoji) {
    // emojis are creating html nodes inside of contentEditable
    // so you need to find out which node to insert that emoji at
    // by traversing through childNodes
    this.insertHtml(createEmoji(emoji));
    this.setState({
      focused: true,
      hideTagSuggestions: true,
      caretPositionOffset: 0,
    });
  }

  insertTags(tags) {
    this.ignoreChange = true;
    this.insertHtml(` ${tags.join(" ")}`);
  }

  insertTemplate = (template) => {
    this.ignoreChange = true;
    this.insertHtml(`${template} `);
  };

  insertHtml = (html, removePrefix) => {
    if (this.selection) {
      util.restoreSelection(this.selection);
    }
    try {
      util.replaceSelection(html, removePrefix);
    } catch (e) {
      //
    }
    this.editor.focus();
  };

  updateTags() {
    let tags = [];
    if (
      this.editor &&
      this.editor.innerHTML &&
      this.editor.innerHTML.toLowerCase().match(/\#[A-Za-z0-9\-\.\_]+/gi)
    )
      tags = this.editor.innerHTML
        .toLowerCase()
        .match(/\#[A-Za-z0-9\-\.\_]+/gi)
        .map((tag) => tag.replace(/\^#/, ""));
    this.setState({ tags });
    return tags;
  }

  bindDataTestingId = () => {
    const testId = this.props["data-testid"];
    if (testId) return { "data-testid": testId };
    return {};
  };

  renderInput() {
    const { placeholder, disabled, baseCaption, oneLine, className } =
      this.props;
    const classNames = `emoji-wysiwyg-editor form-control ${
      baseCaption ? " base-caption" : ""
    } ${oneLine ? "one-line" : ""} ${className || ""}`;

    const ref = (ref) => {
      this.editor = ref;
    };
    if (disabled) {
      return (
        <div
          className={`${classNames} disabled`}
          ref={ref}
          placeholder={placeholder}
          data-testid="sked-caption-disabled-input"
        />
      );
    }

    return (
      <div
        contentEditable
        className={classNames}
        ref={ref}
        onKeyPress={this.handleKeyPress}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        onMouseDown={this.handleFocus}
        onMouseUp={this.handleMouseUp}
        onBlur={this.handleBlur}
        onPaste={this.handlePaste}
        onDrop={this.handleDrop}
        placeholder={placeholder}
        data-gramm_editor="false"
        id="caption-input"
        data-testid="editableDiv"
        {...this.bindDataTestingId()}
      />
    );
  }

  toggleTagSuggest() {
    this.setState({
      hideTagSuggestions: !this.state.hideTagSuggestions,
    });
  }

  handleLightBulbClick() {
    if (!this.state.focused) {
      // this isn't great code but it opens the hash tag recommendations
      // when the text area is not focused...
      this.editor.focus();
      this.setState({
        focused: true,
        hideTagSuggestions: false,
      });
      setTimeout(() => {
        this.setState({
          hideTagSuggestions: false,
        });
      }, 300);
    } else {
      this.toggleTagSuggest();
    }
  }

  /** it's not using any more, as this issue https://linear.app/sked-social/issue/SKE-1134/remove-hashtag-recommendation-button-from-create-post-modal
   * but still leave it here in case hash tag suggesion service is back
   */
  renderHashtagSuggest() {
    const tags = this.state.tags || [];
    const { disabled, hideTagSuggest } = this.props;
    if (disabled || hideTagSuggest) return null;
    const shown =
        tags.length > 0 && this.state.focused && !this.state.hideTagSuggestions,
      toggleEnabled = tags.length > 0 && tags.length < 30;

    let button = (
      <TooltipWrapper
        tooltip={
          !toggleEnabled
            ? "Add hashtags to get recommendations"
            : "Hashtag recommendations"
        }
        placement={"top"}
        key="tooltip-wrapper"
      >
        <SkedHashAnimation isFocused={this.state.focused} />
        <RecommendedTagsButton
          onMouseDown={this.prevDefault}
          type="button"
          className={`tag-suggest-toggle ${shown ? "active" : ""} ${
            toggleEnabled ? "enabled" : ""
          }`}
          onClick={this.handleLightBulbClick}
          disabled={!toggleEnabled}
        >
          <Hash />
        </RecommendedTagsButton>
      </TooltipWrapper>
    );
    let hashRec = shown ? (
      <HashtagRecommend
        hashtags={tags}
        loadingText="Getting Suggestions..."
        itemClick={this.addTag}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseOut}
        key="hash-tag-recommend"
      />
    ) : null;
    return [button, hashRec];
  }

  addTag(info) {
    this.insertTags([`#${info.hashtag}`]);
    this.tagsHovered = true;
  }

  sectionBlur() {
    var caretPosition = this.getCaretPosition(this.editor);
    if (!caretPosition || this.tagsHovered) return;

    setTimeout(() => {
      this.setState({
        focused: false,
        caretPositionNodeIdx: caretPosition.nodeIdx,
        caretPositionOffset: caretPosition.caretOffset,
      });
    }, 300);
  }

  getCaretPosition(el) {
    let caretOffset = 0,
      nodeIdx = 0,
      startOffset = 0,
      sel = window.getSelection();
    if (typeof window.getSelection !== "undefined" && sel.rangeCount > 0) {
      var range = sel.getRangeAt(0);
      var selected = range.toString().length;
      var preCaretRange = range.cloneRange();
      startOffset = range.startOffset;
      preCaretRange.selectNodeContents(el);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length - selected;
      while (el.childNodes[nodeIdx] && caretOffset > -1) {
        caretOffset -=
          el.childNodes[nodeIdx].nodeName === "#text"
            ? el.childNodes[nodeIdx].length
            : 0;
        nodeIdx++;
      }
      nodeIdx = nodeIdx > 0 ? nodeIdx - 1 : 0; // adjust for 0 index;
    }
    return { nodeIdx: nodeIdx, caretOffset: startOffset };
  }

  prevDefault(e) {
    e.preventDefault();
  }

  handleMouseEnter() {
    this.tagsHovered = true;
  }

  handleMouseOut() {
    this.tagsHovered = false;
  }

  toggleDescriptionPicker() {
    this.setState((state) => ({
      descriptionPickerState: !state.descriptionPickerState,
    }));
  }

  insertDescription(description) {
    this.insertHtml(`${description} `);
  }

  renderMutationLabel = (
    selectedLinkedInAccountType,
    hasMoreThanOnelinkedInAccount
  ) => {
    return (
      <MentionLabel>
        Mention a LinkedIn <strong>Company Page</strong>{" "}
        {selectedLinkedInAccountType === "company" &&
          !hasMoreThanOnelinkedInAccount && (
            <span>
              or <strong>Personal Profile</strong>
            </span>
          )}{" "}
        by typing the name as it appears in the URL (no spaces, include dashes)
      </MentionLabel>
    );
  };

  disableSuggestionsForever = () =>
    this.setState({ suggestionsDisabledForever: true });

  render() {
    const { suggestedTags, focused, suggestionsDisabledForever } = this.state;
    const {
      disabled,
      suggestions,
      hideTemplates,
      hideTagSuggest,
      hideDescriptions,
      hideMutationLabel,
      leftButton,
      selectedPlatform,
      renderBeforeInput,
      inputContainerClassName,
      onRootElementClick,
      onEditorContainerClick,
      commentWrapperCssPosition,
      hasMoreThanOnelinkedInAccount,
      selectedLinkedInAccountType,
      disableMention,
      selectedLinkedUrn,
    } = this.props;

    return (
      <SkedCaptionRoot className="emoji-input" onClick={onRootElementClick}>
        <Manager>
          <div className="comment-input">
            <CommentWrapper cssPosition={commentWrapperCssPosition}>
              <table className="preview-container">
                <tbody>
                  <tr>
                    <Reference>
                      {({ ref }) => (
                        <td
                          onBlur={this.sectionBlur}
                          ref={ref}
                          className={inputContainerClassName}
                          onClick={onEditorContainerClick}
                        >
                          {renderBeforeInput && renderBeforeInput()}
                          {this.renderInput()}
                        </td>
                      )}
                    </Reference>
                  </tr>
                </tbody>
              </table>
              {!disabled && (
                <Buttons
                  leftButton={leftButton}
                  className="sked-caption-buttons"
                >
                  {!hideDescriptions && (
                    <LibraryDescriptionPicker
                      isOpen={this.state.descriptionPickerState}
                      toggleDescriptionPicker={this.toggleDescriptionPicker}
                    />
                  )}

                  {!hideTemplates && (
                    <TemplatesSelector onSelect={this.insertTemplate} />
                  )}
                  <EmojiPicker
                    onSelect={this.insertEmoji}
                    leftAlign={leftButton}
                  />
                </Buttons>
              )}
            </CommentWrapper>

            {focused &&
              !disabled &&
              !suggestionsDisabledForever &&
              suggestedTags.type === 2 &&
              disableMention && (
                <MentionLabel>
                  Click on the social icons on the left to mention other social
                  media users on each platform
                </MentionLabel>
              )}

            {focused &&
              !disabled &&
              !suggestionsDisabledForever &&
              suggestions &&
              suggestedTags.tags.length > 0 &&
              !disableMention && (
                <>
                  {this.isLinkedIn &&
                    !hideMutationLabel &&
                    this.renderMutationLabel(
                      selectedLinkedInAccountType,
                      hasMoreThanOnelinkedInAccount
                    )}
                  <Popper placement="top-start">
                    {({ ref, style, placement, arrowProps }) => (
                      <TagSelectorWrapper
                        ref={ref}
                        style={style}
                        data-placement={placement}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseOut}
                      >
                        {suggestedTags.type === tagTypes.hashtag && (
                          <TagSelectorHeader>
                            <div>Used Hashtags</div>
                            <div onClick={this.disableSuggestionsForever}>
                              <u>Hide</u>
                            </div>
                          </TagSelectorHeader>
                        )}
                        <SuggestionsPopup
                          onSelect={(suggestion) => {
                            this.selectTag(suggestedTags.type, suggestion);
                          }}
                          suggestions={suggestedTags.tags}
                          renderSuggestion={({ suggestion }) => (
                            <SuggestOptionView
                              suggestion={suggestion}
                              onRemove={
                                this.isRemovable(suggestedTags.type)
                                  ? () => {
                                      this.removeTag(
                                        suggestedTags.type,
                                        suggestion
                                      );
                                    }
                                  : undefined
                              }
                            />
                          )}
                          suggestionHeader={
                            // ig and fb will use the header later
                            suggestedTags.tags.length > 0 && this.isLinkedIn
                              ? () => {
                                  return (
                                    <SearchAccountHeader className="sticky">
                                      Search Accounts{" "}
                                      <Tooltip
                                        text={
                                          hasMoreThanOnelinkedInAccount
                                            ? "A personal profile cannot be mentioned when more than one LinkedIn account is selected."
                                            : selectedLinkedInAccountType ===
                                              "personal"
                                            ? "A Personal Profile cannot be mentioned by a Personal Profile."
                                            : selectedLinkedInAccountType ===
                                              "company"
                                            ? "Only followers of the company page can be mentioned."
                                            : ""
                                        }
                                        contentWidth="288px"
                                        container={document.querySelector(
                                          '[data-testid="suggestions"]'
                                        )}
                                        textAlign="center"
                                        textSize="12px"
                                      >
                                        <div>
                                          <Icon
                                            name="info-circle"
                                            color="#808080"
                                            size={16}
                                          />
                                        </div>
                                      </Tooltip>
                                    </SearchAccountHeader>
                                  );
                                }
                              : undefined
                          }
                        />
                      </TagSelectorWrapper>
                    )}
                  </Popper>
                </>
              )}

            {this.state.descriptionPickerState && (
              <DescriptionsList
                onSelect={this.insertDescription}
                captionValue={this.props.initialValue}
              />
            )}
          </div>
        </Manager>
      </SkedCaptionRoot>
    );
  }
}

// TODO: describe all props
SkedCaption.propTypes = {
  autocomplete: PropTypes.shape({
    hashtag: PropTypes.shape({
      suggest: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }),
    mention: PropTypes.shape({
      suggest: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }),
  }),
  leftButton: PropTypes.bool,
  selectedPlatform: PropTypes.string,
  disableMention: PropTypes.bool,
};

const SearchAccountHeader = styled.div`
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 8px 4px 8px;
  position: absolute;
  width: 100%;
  background: white;
  z-index: 1;
`;

const CommentWrapper = styled.div`
  position: ${(p) => (p.cssPosition ? p.cssPosition : "relative")};
`;

const TagSelectorWrapper = styled.div`
  width: 231px;
  z-index: 999;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const TagSelectorHeader = styled.div`
  font-style: italic;
  font-size: 1.2rem;
  color: #333333;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 5px 9px;

  u {
    cursor: pointer;
  }
`;

const MentionLabel = styled.div`
  font-size: 10px;
  font-style: italic;
  padding: 8px 8px 8px 0;
  background: #fff;
  border-radius: 8px;
`;

const RecommendedTagsButton = styled.button`
  width: 24px;
  height: 24px;
  background: #f6f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid #dedafe;

  svg {
    fill: #9886ff;
    z-index: 1;
  }

  &.active {
    background-color: #4f31ff;
    border: 1px solid #4f31ff;

    svg {
      fill: #fff;
    }
  }

  &.enabled:not(.active) {
    overflow: hidden;
    background: white;

    &:before {
      position: absolute;
      content: "";
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #4f31ff;
      animation: shiny-btn1 2s linear 1;
    }
  }

  &:disabled {
    background-color: #eeeeee;
    box-shadow: none !important;
    border: none;

    svg {
      fill: #fff;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 10px 10px 0;

  & > div:not(:first-child) {
    margin-left: 4px;
  }

  ${({ leftButton }) =>
    leftButton &&
    `
    left: 0;
    padding: 0 0 10px 10px;
  `}
`;

const SkedCaptionRoot = styled.section`
  // just fix bootstrap form-control z-index(used in bulk upload)
  .form-control {
    z-index: auto !important;
  }
`;

const SkedHashAnimation = createGlobalStyle`
  ${(props) =>
    props.isFocused &&
    `
    body > [role="dialog"]{
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1050 !important;
    }
  `}

  @-webkit-keyframes shiny-btn1 {
    0% {
      -webkit-transform: scale(0) rotate(-45deg);
      opacity: 0;
    }
    30% {
      -webkit-transform: scale(0) rotate(-45deg);
      opacity: 0.5;
    }
    31% {
      -webkit-transform: scale(4) rotate(-45deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(50) rotate(-45deg);
      opacity: 0;
    }
  }
`;
