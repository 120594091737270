import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FileToUpload } from "api/media-library";
import { checkIfHeic } from "utils/image";

function UploadingMediaPreviewComponent({
  className,
  file,
}: {
  className?: string;
  file: FileToUpload;
}) {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const isHeic = checkIfHeic(file.name);

  useEffect(() => {
    let objectUrl: string;
    if (file.contentType === "blob" && file.type === "image") {
      if (file.thumbnail) {
        setPreviewUrl(file.thumbnail);
      } else {
        objectUrl = URL.createObjectURL(file.blob);
        setPreviewUrl(objectUrl);
      }
    } else if (file.contentType === "url" && file.type === "image") {
      setPreviewUrl(file.thumbnail ?? file.url);
    } else if (file.contentType === "getter" && file.type === "image") {
      file.getImage().then((imgBlog) => {
        objectUrl = URL.createObjectURL(imgBlog);
        setPreviewUrl(objectUrl);
      });
    } else {
      setPreviewUrl(undefined);
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [file]);

  if (previewUrl && !isHeic) {
    return <img className={className} src={previewUrl} />;
  } else {
    return <div className={className}></div>;
  }
}

export const UploadingMediaPreview = styled(UploadingMediaPreviewComponent)`
  display: inline-block;
  width: 60px;
  height: 60px;
  object-fit: contain;
`;
