//staging
let googleApiKey =
  "981158975333-vohjljfs1jsn9sim09n4op4u5t0qpm40.apps.googleusercontent.com";
let googleAppId = "981158975333";

if (!(process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "dev")) {
  // prod
  googleApiKey =
    "370773354305-phvrijjkhv4vf3gvcqd582q8p48mdj8t.apps.googleusercontent.com";
  googleAppId = "370773354305";
}

const fetchDriveDoc = (link: string, oauthToken: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", link);
    xhr.responseType = "arraybuffer";
    xhr.setRequestHeader("Authorization", `Bearer ${oauthToken}`);
    xhr.onload = (res) => {
      const blob = new Blob([res.currentTarget?.response]);
      resolve(blob);
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    xhr.send();
  });
};

type GDriveFile = {
  name: string;
  type: "video" | "image";
  getImage(): Promise<Blob>;
};

export async function selectFileFromGoogleDrive(): Promise<GDriveFile[]> {
  return new Promise((resolve, reject) => {
    window.gapi.load("picker");
    window.gapi.client.load("drive", "v2");
    window.gapi.auth.authorize(
      {
        client_id: googleApiKey,
        scope: ["https://www.googleapis.com/auth/drive.readonly"],
        immediate: false,
      },
      (authResult: { access_token: any }) => {
        if (!authResult.access_token) {
          return;
        }
        const google = window.google;
        const oauthToken = authResult.access_token;
        const viewFiles = new google.picker.DocsView(google.picker.ViewId.DOCS)
          .setParent("root")
          .setMimeTypes(
            "image/heic,image/png,image/jpeg,image/jpg,image/heif,video/mp4,application/pdf"
          )
          .setIncludeFolders(true);
        const picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(googleAppId)
          .setOAuthToken(oauthToken)
          .addView(viewFiles)
          .addView(new google.picker.DocsUploadView())
          .setCallback(async ({ docs }: any) => {
            if (!docs) {
              return;
            }
            const downloads = docs.map((doc: any) => ({
              getImage: () =>
                fetchDriveDoc(
                  `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`,
                  oauthToken
                ),
              name: doc.name,
              type: doc.type === "video" ? "video" : "image",
            }));

            resolve(downloads);
          })
          .build();
        picker.setVisible(true);
      }
    );
  });
}
