import React from "react";
import styled from "styled-components";
import {
  BaseModal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "ui/giselle-modal";
import { useDispatch } from "react-redux";
import { addInstagramSuggestionModal } from "../__redux-model";
import { Link } from "libs/ng-react-router-bridge";
import ReactPlayer from "react-player";
import { Button, FormSize } from "ui/ecosystems/forms";

export function AddInstagramSuggestion(props: {
  isHidden: boolean;
}): React.ReactNode {
  const dispatch = useDispatch();

  function closeModal() {
    dispatch(addInstagramSuggestionModal.close);
  }

  return (
    <Modal show={!props.isHidden} onHide={closeModal}>
      <ModalHeader
        title="Add an Instagram account to use the Planner!"
        subtitle="Make your grid pop with our drag ‘n’ drop Instagram grid planner. Add an Instagram account to get planning!"
      />
      <Content>
        <ReactPlayer url="https://vimeo.com/817218469" controls />
      </Content>
      <ModalActions variant="center">
        <Link to="/dashboard/accounts">
          <Button size={FormSize.large} primary onClick={closeModal}>
            Take me to the Social Accounts page
          </Button>
        </Link>
      </ModalActions>
    </Modal>
  );
}

const Content = styled(ModalContent)`
  display: flex;
  justify-content: center;
`;

const Modal = styled(BaseModal)`
  ${ModalActions} {
    padding-bottom: 55px;
  }
`;
