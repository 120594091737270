import React from "react";
import styled from "styled-components";

import { openSupportChat } from "libs/support";
import { Link } from "ui/Link";

import { AddLibraryButton, Center, List, SMSplash } from "./atoms";
import demo from "./sm-splash-tagged-in-demo.jpg";

export function SMSplashTaggedIn() {
  return (
    <SMSplash
      title="Saved your “Tagged in” posts"
      subtitle="See everywhere you’ve been tagged, save it, and share it"
    >
      <Center>
        <AddLibraryButton />
      </Center>
      <Grid css="margin-top: calc(var(--spacing) * 2);">
        <SMSplashTaggedInContent />
        <div css="justify-self: center">
          <img
            css="width: 30vw; height: auto;"
            src={demo}
            alt="Tagged In functionality demo"
          />
        </div>
      </Grid>
    </SMSplash>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: calc(var(--spacing) * 2);
`;

export function SMSplashTaggedInContent() {
  return (
    <div>
      <p>
        <b>
          Tagged in Post is how you find and celebrate the most dedicated
          followers and customers in your audience. Here’s how it works:
        </b>
      </p>
      <List css="margin-top: var(--spacing);">
        <li>
          <p>
            <b>Select an account you manage</b>
          </p>
          <p>You can track up to 30 hashtags at a time</p>
        </li>
        <li>
          <p>
            <b>Choose posts to save</b>
          </p>
          <p>
            The original creators' info is automatically saved so you can pass
            on the credit
          </p>
        </li>
        <li>
          <p>
            <b>Add it to your Library</b>
          </p>
        </li>
        <li>
          <p>
            <b>
              Share it across Instagram, Facebook, Twitter, Pinterest and
              LinkedIn
            </b>
          </p>
        </li>
      </List>
      <Center css="margin-top: calc(var(--spacing) * 2);">
        <AddLibraryButton />
      </Center>
      <Center css="margin-top: calc(var(--spacing) * 0.5);">
        <p>
          Get help from{" "}
          <Link as="button" onClick={() => openSupportChat()}>
            Sked Support
          </Link>
        </p>
      </Center>
    </div>
  );
}
