import React, { useRef, MouseEvent } from "react";
import { Overlay } from "react-bootstrap";
import styled from "styled-components";

import { useOpenableState } from "libs/use-openable-state";

import { SketchPickerWithPresets } from "./sketch-picker-with-presets";

export function ColorPicker({
  value,
  onChange,
  children,
}: {
  value: string;
  onChange(newValue: string): void;
  children: React.ReactElement;
}): JSX.Element {
  const triggerRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isOpened, close, toggle } = useOpenableState();

  const preventClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClick = (event: MouseEvent) => {
    preventClick(event);
    toggle();
  };

  return (
    <ColorPickerRoot ref={containerRef}>
      {React.cloneElement(children, { ref: triggerRef, onClick: handleClick })}
      <Overlay
        rootClose={true}
        target={() => triggerRef.current}
        container={containerRef.current}
        show={isOpened}
        placement="bottom"
        onHide={close}
      >
        <div
          style={{
            position: "absolute",
            marginTop: 3,
            zIndex: 1,
            minWidth: "180px",
          }}
        >
          <SketchPickerWithPresets
            value={value}
            onChange={onChange}
            width={300}
          />
        </div>
      </Overlay>
    </ColorPickerRoot>
  );
}

const ColorPickerRoot = styled.div`
  position: relative;
`;
