/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

//constants
import { FIELDS } from "constants/PostFormFields";
import { PLATFORMS } from "constants/Platforms";
import { CHARACTER_LIMITS } from "constants/CharacterLimits";

//helpers
import { sortAccounts } from "utils/accounts";
import { countCharacter } from "utils/strings";

//components
import { Toggle, TooltipWrapper } from "ui";

//actions
import { togglePlatformLink, updatePostFormField } from "actions/postForm";

//selectors
import { getLoading, getUser } from "selectors/commonSelectors";

import CaptionField from "components/Posts/PostForm/PostCaption/CaptionField";
import { useTrackEvent } from "libs/analytics";

import { AltTextModal } from "../modals/alt-text";
import GmbFields from "../GmbFields/GmbFields";
import YtFields from "../YouTubeFields/YouTubeFields";
import PinterestFields from "../PinterestFields/PinterestFields";
import { PlatformUsage, PostFirstComment, PostTags, PostUrl } from "../index";
import { PlatformFieldsOverlay } from "./PlatformFieldsOverlay";
import { UNLINK_TOGGLE_TW } from "constants/Events";
import { TiktokSwitchNotification } from "../TiktokFields/TiktokSwitchNotification";
import { ThreadsSwitchNotification } from "../threadsFields/ThreadsSwitchNotification";
import { TiktokSwitchAllows } from "../TiktokFields/TiktokSwitchAllows";
import { PostAlerts } from "./PostAlerts";
import { useVideoCover, VideoCoverSelector } from "./VideoCoverSelector";
import { getLinkedInAccountDetail } from "./single-post-form";
import ThreadsReplyControl from "../threadsFields/ThreadsReplyControl";

export function ClusterPostForm({
  post,
  postFormValue,
  errors,
  platforms,
  platformFields,
  formAccounts,
  updateFields,
  updateField,
  platformPost,
  selectedPlatform,
  visibleFields,
  isSummary,
  hasPostTags,
  hasTikTokPosting,
  oldTiktokAccounts,
  ttIsNotification,
  hasThreadsPosting,
  oldThreadsAccounts,
  thIsNotification,
  showUrlField,
}) {
  const dispatch = useDispatch();
  const [openAddAlt, setOpenAddAlt] = useState(false);

  const isLoading = useSelector(getLoading);
  // Warning: If we are using this for creat single post we need to make sure user is initialized properly.
  const trackEvent = useTrackEvent();

  const fieldsRef = useRef(null);
  const user = useSelector(getUser);
  const sortedAccounts = sortAccounts(Object.values(formAccounts || []));

  const allDisabled = sortedAccounts.length === 0 || isLoading || post.past;

  const disabledWhenNotUnlinked =
    selectedPlatform !== "LINKED" &&
    (!platformFields[selectedPlatform] ||
      platformFields[selectedPlatform].unlinked !== true);

  const platformFormValue = useMemo(
    () => ({
      ...postFormValue,
      ...(selectedPlatform !== "LINKED" && platformFields[selectedPlatform]),
      platformType: selectedPlatform,
    }),
    [platformFields, postFormValue, selectedPlatform]
  );

  const {
    showVideoSelector,
    isLoading: isLoadingVideo,
    videoMedia,
    videoUrl,
    thumbnails,
  } = useVideoCover({
    selectedPlatform,
    platforms: platforms,
    platformPost,
  });

  if (!platformPost) {
    return "";
  }
  let captionHashtagCount = countCharacter(postFormValue?.caption ?? "", "#");
  let firstCommentHashtagCount = countCharacter(
    postFormValue?.firstcomment ?? "",
    "#"
  );
  const hasInsta = sortedAccounts.find(
    (account) => account.platformName === "instagram"
  );

  if (hasInsta) {
    captionHashtagCount = firstCommentHashtagCount =
      captionHashtagCount + firstCommentHashtagCount;
  }

  const hasYT = platforms.some((platform) => platform.TYPE === "YT");

  const captionMaxLength = getCaptionLengthLimit(
    platformFormValue,
    platforms.filter(
      (platform) => platformFields[platform.TYPE]?.unlinked !== true
    )
  );

  function handleMediaUpdate(mediaFiles) {
    updateField(FIELDS.MEDIA, mediaFiles);
  }

  function handleImageAltUpdate(value) {
    updateField(FIELDS.ALT_TEXT, value);
  }

  function handleOpenAddAlt() {
    setOpenAddAlt(!openAddAlt);
  }

  const onCaptionFieldChange = (val) => {
    updateField(FIELDS.CAPTION, val);
  };

  const isTwitter = selectedPlatform === "TW";

  const { hasMoreThanOnelinkedInAccount, selectedLinkedInAccountType } =
    getLinkedInAccountDetail(postFormValue.accounts);

  // @codeme Break this up into smaller components
  return (
    <PostFormProvider id={platformFormValue._id} disabled={allDisabled}>
      {isTwitter ? (
        <TwitterClusterForm
          post={platformPost}
          unlinked={platformFields?.TW?.unlinked}
          toggleUnlinked={() => {
            trackEvent({ eventName: UNLINK_TOGGLE_TW });
            dispatch(togglePlatformLink("TW"));
          }}
          errors={errors}
          platforms={platforms}
        />
      ) : (
        <>
          {/* Platform post disconnection toggle */}
          {selectedPlatform !== "LINKED" && (
            <div className="tw-flex tw-flex-col tw-mb-8">
              <div className="tw-flex tw-items-center tw-justify-start tw-mb-4">
                <TooltipWrapper
                  tooltip={`Custom changes for ${PLATFORMS[selectedPlatform].LABEL} will apply to all ${PLATFORMS[selectedPlatform].LABEL} accounts that you have selected.`}
                >
                  <div className="tw-text-xl tw-mr-4 tw-font-medium">
                    Customise {PLATFORMS[selectedPlatform].LABEL} post
                  </div>
                  <Toggle
                    key={`unlink-toggle-${selectedPlatform}`}
                    defaultChecked={Boolean(
                      platformFields[selectedPlatform] &&
                        platformFields[selectedPlatform].unlinked === true
                    )}
                    icons={{
                      unchecked: null,
                    }}
                    className="active-purple"
                    onChange={() => {
                      trackEvent({
                        eventName: `unlink-toggle-${selectedPlatform}`,
                      });
                      dispatch(togglePlatformLink(selectedPlatform));
                    }}
                  />
                </TooltipWrapper>
              </div>
            </div>
          )}

          {/* Wrapper for platform fields */}
          <div
            ref={fieldsRef}
            className="tw-relative"
            key={`fields-${selectedPlatform}-${
              !platformFields[selectedPlatform] ||
              platformFields[selectedPlatform].unlinked !== true
                ? "default"
                : "custom"
            }`}
          >
            {/* Overlay for a linked platform */}
            {disabledWhenNotUnlinked && (
              <PlatformFieldsOverlay fieldsRef={fieldsRef} />
            )}

            <PostAlerts
              postFormValue={platformFormValue}
              oldTiktokAccounts={oldTiktokAccounts}
              platforms={platforms}
              selectedPlatform={selectedPlatform}
              ttIsNotification={ttIsNotification}
              isThreadsPostingEnabled={user.preferences?.enableThreadsPosting}
              oldThreadsAccounts={oldThreadsAccounts}
              thIsNotification={thIsNotification}
            />

            <div>
              {/* Story Toggle - disabled version for when viewing with linked */}
              {/* This is so that customers can 'see' that we support these posts */}
              {selectedPlatform === "LINKED" &&
                platforms.length > 1 &&
                platforms.some((platform) => platform.TYPE === "IG") && (
                  <div className="tw-inline-block tw-mb-4">
                    <TooltipWrapper
                      placement={"top"}
                      tooltip={
                        "To post an Instagram story or send a notification, remove non-Instagram or notification channels."
                      }
                    >
                      <div className="tw-flex tw-items-center tw-justify-start">
                        <div className="tw-text-xl tw-mr-4 tw-font-medium tw-text-gray-700">
                          Switch to Instagram Story or Notification Post
                        </div>
                        <Toggle
                          key={`story-toggle-${selectedPlatform}`}
                          defaultChecked={
                            postFormValue[FIELDS.IS_STORY] || false
                          }
                          icons={{
                            unchecked: null,
                          }}
                          className="active-purple"
                          disabled={true}
                        />
                      </div>
                    </TooltipWrapper>
                  </div>
                )}
              {selectedPlatform === "TT" && (
                <TiktokSwitchNotification
                  platforms={platforms}
                  value={ttIsNotification}
                  hasTikTokPosting={hasTikTokPosting}
                  updateField={updateField}
                  allDisabled={allDisabled || disabledWhenNotUnlinked}
                  oldTiktokAccounts={oldTiktokAccounts}
                />
              )}
              {selectedPlatform === "TH" && (
                <ThreadsSwitchNotification
                  platforms={platforms}
                  value={thIsNotification}
                  hasThreadsPosting={hasThreadsPosting}
                  updateField={updateField}
                  allDisabled={allDisabled || disabledWhenNotUnlinked}
                  oldThreadsAccounts={oldThreadsAccounts}
                />
              )}
              {showVideoSelector && (
                <VideoCoverSelector
                  disabled={
                    selectedPlatform !== "LINKED" &&
                    platformFields?.[selectedPlatform]?.unlinked !== true
                  }
                  videoUrl={videoUrl}
                  isLoading={isLoadingVideo}
                  videoMedia={videoMedia}
                  thumbnails={thumbnails}
                  updateField={updateField}
                  platforms={platforms}
                  selectedPlatform={selectedPlatform}
                  thumbnailUrl={platformPost?.thumbnailUrl}
                  videoCoverPreview={platformPost?.videoCoverPreview}
                  originalCover={platformPost?.videoCoverOriginal}
                  videoCoverImageTimestamp={
                    platformPost?.videoCoverImageTimestamp
                  }
                />
              )}
              {/* Caption */}
              <CaptionField
                key={`caption-${selectedPlatform}`}
                className="tw-flex tw-flex-col tw-mb-4"
                label={
                  <>
                    <div className="tw-mr-2">Caption</div>

                    {hasYT && ["LINKED", "YT"].includes(selectedPlatform) && (
                      <div className="tw-mr-2">(YouTube video description)</div>
                    )}
                    <PlatformUsage
                      platforms={platforms}
                      field={FIELDS.CAPTION}
                      selectedPlatform={selectedPlatform}
                    />
                  </>
                }
                selectedPlatform={selectedPlatform}
                value={platformFormValue.caption ?? ""}
                onChange={onCaptionFieldChange}
                maxLength={captionMaxLength}
                hashtagCount={captionHashtagCount}
                errors={errors}
                disabled={allDisabled || disabledWhenNotUnlinked}
                platforms={platforms}
                hasMoreThanOnelinkedInAccount={hasMoreThanOnelinkedInAccount}
                selectedLinkedInAccountType={selectedLinkedInAccountType}
                disableMention={selectedPlatform === "LINKED"}
              />

              {/* First Comment */}
              {visibleFields.includes(FIELDS.FIRST_COMMENT) && (
                <PostFirstComment
                  key={`first-comment-${selectedPlatform}`}
                  post={platformPost}
                  platformFields={platformFields}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  className="tw-flex tw-flex-col tw-mb-4"
                  onChange={(val) => updateField(FIELDS.FIRST_COMMENT, val)}
                  hashtagCount={firstCommentHashtagCount}
                  errors={errors}
                  disabled={allDisabled || disabledWhenNotUnlinked}
                />
              )}

              {/* URL */}
              {showUrlField && (
                <PostUrl
                  url={platformFormValue.redirectUrl}
                  checkSetting={true}
                  post={platformFormValue}
                  selectedPlatform={selectedPlatform}
                  selectedAccounts={formAccounts}
                  platforms={platforms}
                  className="tw-mb-4"
                  title="URL"
                  onChange={(newUrl) => updateField(FIELDS.URL, newUrl)}
                  disabled={allDisabled || disabledWhenNotUnlinked}
                />
              )}

              {/* TT other fields */}
              {hasTikTokPosting && (
                <TiktokSwitchAllows
                  postFormValue={platformFormValue}
                  updateField={updateField}
                  allDisabled={allDisabled || disabledWhenNotUnlinked}
                  platforms={platforms}
                  selectedPlatform={selectedPlatform}
                  errors={errors}
                />
              )}
              {/* Threads fields */}
              {hasThreadsPosting && (
                <ThreadsReplyControl
                  key={`thReplyControlId-${selectedPlatform}`}
                  title={`Reply Control`}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  selectedAccounts={formAccounts}
                  onChange={(val) => updateField(FIELDS.TH_REPLY_CONTROL, val)}
                  errors={errors}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}
              {/* Tags */}
              {hasPostTags && (
                <PostTags
                  hasTikTokPosting={hasTikTokPosting}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  visibleFields={visibleFields}
                  className="tw-flex tw-mb-4"
                  onChange={updateField}
                  handleOpenAddAlt={handleOpenAddAlt}
                  isSummary={isSummary}
                />
              )}
            </div>
            {/* GMB Fields */}
            {visibleFields.includes(FIELDS.GMB_TOPIC_TYPE) && (
              <GmbFields
                onChange={updateFields}
                post={{
                  ...platformFormValue,
                  ...platformFields[selectedPlatform],
                }}
                selectedPlatform={selectedPlatform}
                platforms={platforms}
                className="tw-mt-12 tw-flex-col"
                disabled={allDisabled || disabledWhenNotUnlinked}
              />
            )}
            {/* YT Fields */}
            {hasYT && visibleFields.includes(FIELDS.YT_TITLE) && (
              <YtFields
                onChange={updateField}
                selectedAccounts={formAccounts}
                post={{
                  ...platformFormValue,
                  ...platformFields[selectedPlatform],
                }}
                selectedPlatform={selectedPlatform}
                platforms={platforms}
                className="tw-mt-12"
                disabled={allDisabled || disabledWhenNotUnlinked}
              />
            )}
            {/* Pinterest fields */}
            {visibleFields.includes(FIELDS.PI_BOARDS) && (
              <PinterestFields
                onChange={updateField}
                selectedAccounts={formAccounts}
                post={{
                  ...platformFormValue,
                  ...platformFields[selectedPlatform],
                }}
                selectedPlatform={selectedPlatform}
                platforms={platforms}
                className="tw-mt-12"
                disabled={allDisabled || disabledWhenNotUnlinked}
              />
            )}
          </div>

          {openAddAlt && (
            <AltTextModal
              post={platformFormValue}
              toggleAddAlt={handleOpenAddAlt}
              handleImageAltUpdate={handleImageAltUpdate}
              onSubmit={handleMediaUpdate}
            />
          )}
        </>
      )}
    </PostFormProvider>
  );
}

function getCaptionLengthLimit(post, platforms) {
  const defaultCharLimit = CHARACTER_LIMITS["FB"];
  // if post is unlinked 👉 get individual char limit
  if (post?.unlinked)
    return CHARACTER_LIMITS[post.platformType] || defaultCharLimit;
  // if not 👉 get minimum required
  else {
    const limits = platforms.map(
      (p) => CHARACTER_LIMITS[p.TYPE] || defaultCharLimit
    );

    return limits.length === 0 ? defaultCharLimit : Math.min(...limits);
  }
}

function TwitterClusterForm(props) {
  const { post: postFormValue, platforms } = props;
  const captionField = useFormField("caption");
  const redirectUrlField = useFormField("redirectUrl");

  return (
    <div>
      <UnlinkToggle
        label="Customise Twitter post"
        value={props.unlinked}
        onChange={props.toggleUnlinked}
      />

      <CaptionField
        className="tw-flex tw-flex-col tw-mb-4"
        label="Caption"
        value={props.post.caption ?? ""}
        onChange={captionField.input.onChange}
        maxLength={CHARACTER_LIMITS.TW}
        errors={props.errors}
        disabled={!props.unlinked}
        platforms={platforms}
      />

      <PostUrl
        url={props.post.redirectUrl}
        checkSetting={true}
        post={postFormValue}
        className="tw-mb-4"
        title="URL"
        onChange={redirectUrlField.input.onChange}
        disabled={!props.unlinked}
      />
    </div>
  );
}

// only used for Twitter currently (?) - fix tooltip label to refer to Twitter
function UnlinkToggle(props) {
  return (
    <div className="tw-flex tw-flex-col tw-mb-8">
      <div className="tw-flex tw-items-center tw-justify-start tw-mb-4">
        <TooltipWrapper
          tooltip={`Custom changes for Twitter will apply to all Twitter accounts that you have selected.`}
        >
          <div className="tw-text-xl tw-mr-4 tw-font-medium">{props.label}</div>
          <Toggle
            defaultChecked={props.value}
            icons={{
              unchecked: null,
            }}
            className="active-purple"
            onChange={props.onChange}
          />
        </TooltipWrapper>
      </div>
    </div>
  );
}

// TODO move it to libs
const PostFormContext = React.createContext(null);

function PostFormProvider(props) {
  const dispatch = useDispatch();

  const change = useCallback(
    (fieldName, value) => {
      dispatch(
        updatePostFormField(props.id, {
          [fieldName]: value,
        })
      );
    },
    [dispatch, props.id]
  );

  const formContext = useMemo(
    () => ({
      change,
      formDisabled: props.disabled,
    }),
    [change, props.disabled]
  );

  return (
    <PostFormContext.Provider value={formContext}>
      {props.children}
    </PostFormContext.Provider>
  );
}

function useFormField(fieldName) {
  const formContext = useContext(PostFormContext);

  return {
    input: {
      value: "",
      onChange(value) {
        formContext.change(fieldName, value);
      },
    },
  };
}
