import axios from "axios";
// @ts-ignore
import ICAL from "ical.js";
import moment from "moment";
import { Country, Holiday, HolidaysCalendar } from "shared/types/calendar";

export const fetchCountriesList = async (): Promise<Country[]> => {
  const result = await axios.get("/calendar/countries");

  return result.data;
};

export const fetchCountryHolidays = async ({
  name,
  iso,
}: Country): Promise<HolidaysCalendar> => {
  const result = await axios.post("/calendars/import-country-holidays", {
    countryName: name,
    iso,
  });

  return result.data;
};

export const fetchHolidayCalendars = async (): Promise<HolidaysCalendar[]> => {
  const result = await axios.get("/calendars");
  return result.data;
};

export const removeCountryHolidays = async (
  calendarId: string
): Promise<void> => {
  await axios.delete(`/calendars/${calendarId}`);
};

export const importHolidaysFromUrl = async ({
  calendarName,
  url,
}: {
  calendarName: string;
  url: string;
}): Promise<void> => {
  return await axios.post("/calendars/import", {
    calendarName,
    url,
  });
};

export const fetchHolidaysFromApi = async (
  countryIso: string
): Promise<Holiday[]> => {
  const result = await axios.get("/calendar/holidays", {
    params: { countryIso },
  });

  return parseIcsEvents(result.data.ics, true);
};

export const fetchFromUrl = async (
  url: string,
  withName?: boolean
): Promise<Holiday[]> => {
  const result = await axios.get<string>("/calendar/import-url", {
    params: { url },
  });

  return parseIcsEvents(result.data, false, withName);
};

function parseCalendarName(headers: [string[]]) {
  const header = headers.find((item: string[]) => item[0] === "x-wr-calname");
  return header?.[header?.length - 1];
}

function parseIcsEvents(ics: string, normalise?: boolean, withName?: boolean) {
  let parsedData;
  try {
    parsedData = ICAL.parse(ics);
  } catch (err) {
    throw new Error("Failed to parse ICS");
  }

  const events = parsedData[2].reduce((events: any, event: any) => {
    if (event[0] !== "vevent") return events;

    const newEvent = event[1].reduce(function (newEvent: any, item: any) {
      const value = item[3];

      switch (item[0]) {
        case "location":
          newEvent.location = value;
          break;
        case "summary":
          newEvent.title = normalise ? normaliseSummary(value) : value;
          break;
        case "dtstart":
          newEvent.start = normalise ? moment.utc(value) : moment(value);
          break;
        case "rrule":
          newEvent.rrule = value;
          break;
        case "recurrence-id":
          newEvent.recurrenceId = value;
          break;
      }
      return newEvent;
    }, {});

    if (newEvent.title && newEvent.start) {
      if (newEvent.recurrenceId && !newEvent.rrule) return events;

      newEvent.rendering = "background";
      events.push(newEvent);
    }
    return events;
  }, []);

  if (withName) {
    const calendarName = parseCalendarName(parsedData[1]);
    return { name: calendarName, events };
  }

  return events;
}

function normaliseSummary(text: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");
  return doc.documentElement.textContent;
}
