import CollaborationAvatar from "components/Collaboration/ChatUtils/CollaborationAvatar";
import { SkedIcon } from "ui";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";

const COMMENT_TYPES = {
  USER_WRITTEN: "USER_WRITTEN",
  SYSTEM_GENERATED: "SYSTEM_GENERATED",
  DELETED: "DELETED",
};

/**
 * comment - comment instance that will be displayed
 * collaborators - list of available collaborators, used for mentions rendering
 * currentUser - current user, used for styles
 * onEdit/onDelete - comment actions
 */
function CollaborationMessage({
  comment,
  collaborators,
  currentUser,
  onDelete,
  onEdit,
  isBlured,
}) {
  let sender = collaborators.find((col) => col._id == comment.createdBy);

  if (!sender) {
    const usernames = (comment?.metadata?.username || "").split(" ");

    sender = {
      firstName: _.capitalize(usernames[0] || ""),
      lastName: _.capitalize(usernames[1] || ""),
    };
  }
  const isOwnMessage = currentUser.userId === comment.createdBy;

  const getCommentComponent = () => {
    switch (comment.type) {
      case COMMENT_TYPES.USER_WRITTEN:
        return (
          <UserWrittenComment
            comment={comment}
            collaborators={collaborators}
            sender={sender}
            currentUser={currentUser}
            onDelete={onDelete}
            onEdit={onEdit}
            isOwnMessage={isOwnMessage}
          />
        );
      case COMMENT_TYPES.SYSTEM_GENERATED:
        return (
          <SystemGeneratedComment
            comment={comment}
            sender={sender}
            clockFormat={currentUser.clockFormat()}
            postStatuses={currentUser.getPostStatusList()}
          />
        );
      case COMMENT_TYPES.DELETED:
        return <DeletedComment sender={sender} />;
    }
  };

  return (
    <MessageContainer isOwnMessage={isOwnMessage} isBlured={isBlured}>
      {getCommentComponent()}
    </MessageContainer>
  );
}

const MessageContainer = styled.div`
  /* widget width is 400px
   * minus own and container padding, minus scroll width => 320px
   * 40px is avatar width + margin
   */
  padding-left: 24px;
  --message-width: calc(320px - 2 * 40px);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  opacity: ${({ isBlured }) => (isBlured ? 0.4 : 1)};
  :hover {
    .actions {
      display: ${({ isOwnMessage }) => (isOwnMessage ? "flex" : "none")};
    }
  }
`;

/**
 * comment - comment instance that will be displayed
 * collaborators - list of available collaborators, used for mentions rendering
 * sender - sender instance for rendering name and avatar
 * currentUser - current user, used for styles
 * onEdit/onDelete - comment actions
 */
function UserWrittenComment({
  comment,
  collaborators,
  sender,
  currentUser,
  onEdit,
  onDelete,
  isOwnMessage,
}) {
  const [hoveredEl, setHoveredEl] = useState(null);
  const isUnread = !comment.readBy.includes(currentUser.userId);

  // parses mentions and replaces them with styled span
  function renderMessage(message) {
    if (!message || !currentUser) return null;
    const regex = /@\[\w*\]/gm;
    const formattedMessage = message.replace(regex, (id) => {
      const cleanId = id.replace(/@|\[|\]/gm, "");
      const user = collaborators.find(
        (collaborator) => collaborator._id === cleanId
      );
      const mention = `<span style="color: #4F31FF">@${
        user?.username || "unknown"
      }</span>`;
      return mention;
    });
    return formattedMessage;
  }
  return (
    <>
      <AvatarAndMessageText
        isOwnMessage={isOwnMessage}
        isUnread={isUnread && !isOwnMessage}
      >
        {onEdit && onDelete && (
          <Actions className="actions">
            <div
              onMouseEnter={() => setHoveredEl("pencil")}
              onMouseLeave={() => setHoveredEl(null)}
              style={{ marginRight: "8px" }}
            >
              <SkedIcon
                icon={"pencil"}
                color={hoveredEl === "pencil" ? "purple" : "gray"}
                size="14"
                onClick={() => onEdit(comment)}
              />
            </div>
            <div
              onMouseEnter={() => setHoveredEl("trash")}
              onMouseLeave={() => setHoveredEl(null)}
            >
              <SkedIcon
                icon={"trash"}
                color={hoveredEl === "trash" ? "red" : "gray"}
                size="14"
                onClick={() => onDelete(comment)}
              />
            </div>
          </Actions>
        )}
        <CollaborationAvatar user={sender} isOwnMessage={isOwnMessage} />
        <MessageText
          isOwnMessage={isOwnMessage}
          dangerouslySetInnerHTML={{ __html: renderMessage(comment.body) }}
        />
      </AvatarAndMessageText>
      <NameAndDate>
        {sender.firstName} {sender.lastName} at{" "}
        {moment(comment.createdAt).format(
          `DD MMM, ${currentUser.clockFormat() || "HH:mm"}`
        )}
        {comment.editedAt && " (edited)"}
      </NameAndDate>
    </>
  );
}

const Actions = styled.div`
  display: none;
  position: absolute;
  left: -14px;
`;

const AvatarAndMessageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isOwnMessage }) =>
    isOwnMessage ? "flex-end" : "flex-start"};
  font-size: 12px;
  position: relative;

  ${({ isUnread }) =>
    isUnread &&
    `
      ::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #FA4F38;
        position: absolute;
        left: -20px;
      }
  `}
`;

const MessageText = styled.div`
  background-color: ${({ isOwnMessage }) =>
    !isOwnMessage ? "#F0F0F0" : "#F4F3FF"};
  order: ${({ isOwnMessage }) => (isOwnMessage ? 1 : 2)};
  margin: 0;
  flex: 1;
  padding: 12px;
  border-radius: ${({ isOwnMessage }) =>
    isOwnMessage ? "15px 15px 0px 15px" : "15px 15px 15px 0px"};
  line-height: 16px;
  font-size: 14px;
  color: #333;
  max-width: var(--message-width);
  border: none;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  white-space: pre-wrap;
  word-break: break-word;
`;

const NameAndDate = styled.p`
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #999;
  font-size: 12px;
  margin: 5px 0 0 0;
  line-height: 14px;
`;

/**
 * comment - comment instance that will be displayed
 * sender - sender instance for rendering name
 * postStatuses - comment has status ids, to get full info it requires this list
 */
function SystemGeneratedComment({
  comment: { metadata, body, createdAt },
  sender,
  clockFormat,
  postStatuses,
}) {
  const [oldStatus, setOldStatus] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  useEffect(() => {
    setOldStatus(
      postStatuses.find((status) => status.key === metadata.oldStatus)
    );
    setNewStatus(
      postStatuses.find((status) => status.key === metadata.newStatus)
    );
  }, []);

  if (!newStatus) {
    return null;
  }

  return (
    <StatusContainer>
      <ChangeStatusAlert color={newStatus.color}>
        {/* metadata.username in case of external review, sender name in case of general status change */}
        <div className="header">
          {metadata.username || `${sender.firstName} ${sender.lastName}`}{" "}
          Changed status {/* render oldStatus only if it exists */}
          {oldStatus ? (
            <>
              from <b>{oldStatus.label}</b>
            </>
          ) : (
            ""
          )}{" "}
          to <b>{newStatus.label}</b>
        </div>
        {body && (
          <ChangeStatusCollaboratorComment>
            <p className="title">Comment:</p>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          </ChangeStatusCollaboratorComment>
        )}
      </ChangeStatusAlert>
      <NameAndDate>
        {moment(createdAt).format(`DD MMM, ${clockFormat || "HH:mm"}`)}
      </NameAndDate>
    </StatusContainer>
  );
}

const StatusContainer = styled.div`
  position: relative;
  max-width: var(--message-width);
  margin: 0 auto;
  margin-top: 10px;
`;

const ChangeStatusAlert = styled.div`
  ::before {
    content: "";
    width: 30px;
    height: 30px;
    background: ${({ color }) => color};
    border: 10px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  position: relative;
  flex: 1;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #333333;
  align-self: center;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  .header {
    padding: 15px 30px 10px;
  }
`;

const ChangeStatusCollaboratorComment = styled.div`
  background: rgba(250, 79, 56, 0.12);
  padding: 15px 30px 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  word-break: break-word;
  p,
  div {
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    background: transparent;
    border: none;
    text-align: left;
    white-space: pre-line;
    &.header {
      margin-bottom: 6px;
    }
    &.body {
      font-style: italic;
      margin-bottom: 0;
    }
  }
`;

/**
 * sender - sender instance for rendering name
 */
function DeletedComment({ sender }) {
  return (
    <DeleteAlert>
      {sender.firstName} {sender.lastName} deleted a comment
    </DeleteAlert>
  );
}

const DeleteAlert = styled.p`
  text-align: center;
  font-style: italic;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  margin: 12px 0;
`;

export default CollaborationMessage;
