function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

export function getMediaFileDuration(mediaFile) {
  // files selected from library don't have duration filed in root of meta object
  const meta = mediaFile.meta;
  return meta?.duration || round(meta?.format?.duration, 1);
}

export function getRatio(mediaFile) {
  if (mediaFile.size && mediaFile.size.width && mediaFile.size.height) {
    return round(mediaFile.size.width / mediaFile.size.height, 2);
  } else if (mediaFile.meta && mediaFile.meta.width && mediaFile.meta.height) {
    return round(mediaFile.meta.width / mediaFile.meta.height, 2);
  } else {
    return 0.0;
  }
}
