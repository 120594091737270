import axios from "axios";
import type { ListItemWithKey } from "../components/Posts/PostForm/LinkedinTargetAudience/LinkedinTargetAudience";
import {
  getLocalStorageLiAudiences,
  setLocalStorageLiAudiences,
} from "libs/storage/adapters";

const getLocalValue = (id: string) => {
  const localStorageData = getLocalStorageLiAudiences();
  const localLiAudiences: { id: string; data: any }[] = localStorageData
    ? JSON.parse(localStorageData)
    : [];
  const localLiAudience = localLiAudiences.find(
    (liAudience: { id: string }) => liAudience.id === id
  );

  return localLiAudience ? localLiAudience.data : null;
};

const fetchLocationLabelById = async (id: string) => {
  const localValue = getLocalValue(id);

  if (localValue) {
    return localValue;
  }

  try {
    const { data } = await axios.request({
      method: "get",
      url: "/linkedin/getLocationLabel",
      params: {
        id,
      },
    });
    const label = data?.defaultLocalizedName?.value || id;
    setLocalStorageLiAudiences({ id, data: label });

    return label;
  } catch (error) {
    // avoid error, just return id as label
    return id;
  }
};

const fetchLocations = async (entity: string, query: string) => {
  const { data } = await axios.request({
    method: "get",
    url: "/linkedin/get_geoLocations",
    params: {
      query,
    },
  });

  return data.map((location: { entity: string; displayText: string }) => ({
    fieldKey: entity,
    id: location.entity,
    label: location.displayText,
  })) as ListItemWithKey[];
};

const fetchOrganizationLabelById = async (id: string) => {
  const localValue = getLocalValue(id);

  if (localValue) {
    return localValue;
  }

  const { data } = await axios.request({
    method: "get",
    url: "/linkedin/getOrganizationLabel",
    params: {
      id,
    },
  });
  setLocalStorageLiAudiences({ id, data });

  return data;
};

export { fetchLocations, fetchOrganizationLabelById, fetchLocationLabelById };
