// We import lodash in webpack when compiling but will fail in testing. Better to include it explicitly
import _ from "lodash";

export const getEntityMap = (entities, entityIds, specificIds = null) => {
  let entityMap = [];
  if (!entities) {
    return [];
  }
  _.map(entities, (id) => {
    if (
      specificIds === null ||
      (specificIds !== null && specificIds.includes(id))
    ) {
      return entityMap.push(entityIds[id]);
    }
  });
  return entityMap;
};

export const getEntities = (state, entity, specificIds = null) => {
  let entities = [];
  let stateEntities = state.entities[entity];
  if (!stateEntities) {
    return [];
  }
  _.map(stateEntities.allIds, (id) => {
    if (
      specificIds === null ||
      (specificIds !== null && specificIds.includes(id))
    ) {
      return entities.push(stateEntities.byId[id]);
    }
  });
  return entities;
};

export const getEntitiesByPage = (state, entity, page, specificIds = null) => {
  let entities = [];
  let stateEntities = state.entities[entity][page];
  if (!stateEntities) {
    return [];
  }
  _.map(stateEntities.allIds, (id) => {
    const entity = stateEntities.byId[id];
    if (
      entity &&
      (specificIds === null ||
        (specificIds !== null && specificIds.includes(id)))
    ) {
      return entities.push(entity);
    }
  });
  return entities;
};
export const getGenericEntities = (
  state,
  stateEntities,
  specificIds = null
) => {
  let entities = [];
  if (!stateEntities) {
    return [];
  }
  _.map(stateEntities.allIds, (id) => {
    if (
      specificIds === null ||
      (specificIds !== null && specificIds.includes(id))
    ) {
      return entities.push(stateEntities.byId[id]);
    }
  });
  return entities;
};
export const getInboxEntities = (state, entity, specificIds = null) => {
  let entities = [];
  let stateEntities = state.entities.inboxes[entity];
  if (!stateEntities) {
    return [];
  }
  _.map(stateEntities.allIds, (id) => {
    if (
      specificIds === null ||
      (specificIds !== null && specificIds.includes(id))
    ) {
      return entities.push(stateEntities.byId[id]);
    }
  });
  return entities;
};
