export const CHARACTER_LIMITS = {
  IG: 2200,
  YT: 5500,
  LI: 3000,
  FB: 63206,
  GMB: 1500,
  PI: 500,
  TW: 280,
  TT: 2200,
  TH: 500,
  SC: 10000,
  OT: 10000,
};
