import { createEffect } from "effector";
import { toast } from "react-toastify";
import { useStore } from "effector-react";

import { changePlan } from "api/plan-change";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

export const changePlanFx = createEffect(
  async ({
    planId,
    ugcLibraryAddon,
    onComplete,
  }: {
    planId: string;
    ugcLibraryAddon: boolean;
    onComplete(): void;
  }) => {
    await changePlan({ planId, ugcLibraryAddon });
    onComplete();
  }
);

changePlanFx.done.watch(() => {
  if (
    window.confirm(
      "You plan was successfully changed and will take effect after you refresh the dashboard. Want to refresh now?"
    )
  ) {
    localStorage.removeItem("skedApiToken");
    location.reload();
  } else {
    toast.success(
      "Your new plan will take effect as soon as you refresh the dashboard"
    );
  }
});

changePlanFx.failData.watch((err) => {
  toast.error(getUserFriendlyErrorMessage(err, "Could not update your plan"));
});

export function useChangePlan() {
  const isChangingPlan = useStore(changePlanFx.pending);
  return { isChangingPlan, changePlan: changePlanFx };
}
