import React from "react";
import { PostAccount } from "components/Posts/Post/index";
import { Caption, DetailsTemplate, PostDetail } from "./post-details-template";
import type { AccountType } from "shared/types/accounts";
import type { Post } from "features/post/types";

export function TikTokDetails({
  post,
  account,
  page,
}: {
  post: Post;
  account: AccountType;
  page: string;
}) {
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          {post.postOptions?.title && (
            <PostDetail label="Title">
              <div>{post.postOptions.title}</div>
            </PostDetail>
          )}
          <Caption post={post} />
        </>
      )}
    />
  );
}
