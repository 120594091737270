import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import ngDeps from "ng-react-directives/ngr-injector";
import { closeModal } from "libs/modals";
import { handleError } from "utils/handleError";
import { SkedButton, LoadingSpinner } from "ui";

import {
  ADD_USER_AVATAR_MODAL,
  deleteUserAvatar,
  updateUserAvatar,
  getUserStatus,
} from "../../users-model";

export const UpdateAvatar = ({ user }: any) => {
  const dispatch = useDispatch();
  const userStatus = useSelector(getUserStatus);
  const [isLoading, setIsLoading] = useState(userStatus === "loading");

  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mediaService,
  } = ngDeps;

  const [avatar, setAvatar] = useState(user.profileImage);
  const disabled = !avatar;

  async function handleDeleteAvatar() {
    await dispatch(deleteUserAvatar({ email: user.email, id: user._id }));
    setAvatar("");
  }

  const submit = async () => {
    await dispatch(
      updateUserAvatar({ email: user.email, url: avatar, id: user._id })
    );
    dispatch(closeModal({ type: ADD_USER_AVATAR_MODAL }));
  };

  function closeAddModal() {
    dispatch(closeModal({ type: ADD_USER_AVATAR_MODAL }));
  }

  const handleAvatar = (event: any) => {
    setIsLoading(true);
    mediaService.upload([...event.target.files], {
      ulr: "media",
      imageUploadFailure: (err: any) => {
        setIsLoading(false);
        handleError(err);
      },
      onMedia: function (mediaFiles: { url: any }[]) {
        const url = mediaFiles[0].url;
        setAvatar(url);
        setIsLoading(false);
      },
      isStory: false,
    });
  };

  return (
    <div>
      <Modal
        show
        onHide={closeAddModal}
        animation={false}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bsStyle="giselle"
        bsSize="small"
      >
        {isLoading && <LoadingSpinner />}
        <form name="inviteForm" className="add-user">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              modal-close
              onClick={closeAddModal}
            >
              &times;
            </button>
            <h4 className="modal-title">Select Picture to upload</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <p>Upload Image</p>
                <label className="input-container">
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={handleAvatar}
                  />
                  <span className="icon glyphicon glyphicon-upload"></span>
                </label>

                {avatar && (
                  <div className="del-img">
                    <div className="imag-c">
                      {avatar && (
                        <img
                          className="uploadedImg"
                          src={avatar}
                          alt="Avatar"
                        />
                      )}
                    </div>
                    <DeleteButton
                      className="delete-action"
                      onClick={handleDeleteAvatar}
                    >
                      <span className="right-icon glyphicon glyphicon-trash"></span>
                      &nbsp;Delete Current Profile Image
                    </DeleteButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn-link" onClick={closeAddModal}>
              Cancel
            </button>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <Submit type="primary" disabled={disabled} onClick={submit}>
              Submit
            </Submit>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const Submit = styled(SkedButton)`
  button {
    font-weight: normal !important;
  }
`;
const DeleteButton = styled.div`
  cursor: pointer;
  color: red;
  text-decoration: none;
  margin-left: 10px;
`;
