import { MessageType } from "types/conversationInbox";

interface GetCustomMessageProps {
  type: MessageType;
  isInvalidUrl: boolean;
}

export const getCustomMessage = ({
  type,
  isInvalidUrl,
}: GetCustomMessageProps): string | null => {
  if ([MessageType.igStoryMention, MessageType.igStoryReply].includes(type)) {
    return isInvalidUrl ? "" : "Preview expires in 24 hours";
  }

  return null;
};
