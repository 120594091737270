import axios, { CancelToken, AxiosError } from "axios";

export interface LIOrganization {
  urn: string;
  name: string;
  picture?: string;
}

export async function lookupLinkedInOrganization(
  q: string,
  cancelToken?: CancelToken
) {
  if (!q) {
    return null;
  }

  try {
    const response = await axios.request<LIOrganization>({
      method: "get",
      url: "/mentions/lookup/li-organization",
      cancelToken,
      params: { q },
    });

    return response.data;
  } catch (error) {
    if (!error.__CANCEL__) {
      throw error;
    }
    return null;
  }
}

export async function lookupLinkedInOrganizationOrPeople(
  q: string,
  cancelToken?: CancelToken,
  selectedLinkedUrn?: string
) {
  if (!q) {
    return null;
  }

  try {
    const response = await axios.request<LIOrganization>({
      method: "get",
      url: "/mentions/lookup/li-organization-or-people",
      cancelToken,
      params: {
        q,
        ...(selectedLinkedUrn && { accountOrganizationUrn: selectedLinkedUrn }),
      },
    });

    return response.data;
  } catch (error) {
    if (!error.__CANCEL__) {
      throw error;
    }
    return null;
  }
}

(window as any).lookupLinkedInOrganization = lookupLinkedInOrganization;
