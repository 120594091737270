import React from "react";

const PostFieldCounter = ({
  children,
  count,
  max,
  className = "",
  selectedPlatform,
}) => {
  let noMax = false;

  let textColor = { color: "#b3b3b3" };
  if (count > max) {
    textColor = { color: "#EF4444" };
  }

  if (selectedPlatform === "LI") {
    noMax = true;
    textColor = { color: "#4F31FF" };
  }

  return (
    <div style={textColor} className={`tw-text-xl tw-italic ${className}`}>
      {children}: {count ? count.toLocaleString() : "0"}
      {max && !noMax ? `/${max.toLocaleString()}` : ""}
    </div>
  );
};

export default PostFieldCounter;
