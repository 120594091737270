import {
  LOAD_COLLECTIONS_ROUTINE,
  SELECT_COLLECTION_ROUTINE,
  ADD_COLLECTION_ROUTINE,
  ADD_COLLECTION_ASSET_ROUTINE,
  DELETE_COLLECTION_ROUTINE,
  DELETE_COLLECTION_ASSET_ROUTINE,
  UPDATE_COLLECTION_ROUTINE,
  RELOAD_ASSET_TAGS_ROUTINE,
  BULK_MOVE_ASSETS_ROUTINE,
  SELECT_EXISTING_HASHTAG_ROUTINE,
  CHANGE_LIBRARY_PAGE,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { normalizeEntity } from "reducers/reducerUtils";
import _ from "lodash";

const initialState = {
  loading: false,
  byId: {},
  allIds: [],
  selectedIds: [],
  tags: [],
  error: false,
};

const loadCollections = (state, action) => {
  const { collections } = action.payload;
  if (!collections) {
    return state;
  }

  const { byId, allIds } = normalizeEntity(collections);
  return {
    ...state,
    byId,
    allIds,
    error: false,
    loading: false,
  };
};

const collectionsReducer = createReducer(initialState, {
  [LOAD_COLLECTIONS_ROUTINE.REQUEST]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [LOAD_COLLECTIONS_ROUTINE.SUCCESS]: loadCollections,
  [LOAD_COLLECTIONS_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      error: true,
      loading: false,
    };
  },
  [SELECT_EXISTING_HASHTAG_ROUTINE.TRIGGER]: (state, action) =>
    initCollectionSidebar(state, action),
  [CHANGE_LIBRARY_PAGE]: (state, action) =>
    initCollectionSidebar(state, action),
  [SELECT_COLLECTION_ROUTINE.REQUEST]: (state, action) => {
    const { id } = action.payload;
    if (!id) {
      return state;
    }
    return {
      ...state,
      selectedIds: [id],
    };
  },
  [RELOAD_ASSET_TAGS_ROUTINE.SUCCESS]: (state, action) =>
    setTags(state, action),
  [SELECT_COLLECTION_ROUTINE.SUCCESS]: (state, action) =>
    setTags(state, action),
  [ADD_COLLECTION_ROUTINE.SUCCESS]: (state, action) =>
    addCollection(state, action),
  [DELETE_COLLECTION_ROUTINE.SUCCESS]: (state, action) => {
    const { id } = action.payload;
    _.remove(state.allIds, (collectionId) => collectionId === id);
    _.remove(state.selectedIds, (selectedId) => selectedId === id);
    delete state.byId[id];
  },
  [UPDATE_COLLECTION_ROUTINE.SUCCESS]: (state, action) => {
    const { collection } = action.payload;
    state.byId[collection._id] = collection;
    return state;
  },
  [DELETE_COLLECTION_ASSET_ROUTINE.SUCCESS]: (state, action) => {
    if (state.selectedIds.length > 1) {
      return;
    }
    const id = state.selectedIds[0];
    state.byId[id].asset_count -= 1;
    return state;
  },
  [ADD_COLLECTION_ASSET_ROUTINE.SUCCESS]: (state, action) => {
    const id = action.payload.collectionId || state.selectedIds[0];
    const { assetsAdded } = action.payload;
    state.byId[id].asset_count += assetsAdded;
    return state;
  },
  [BULK_MOVE_ASSETS_ROUTINE.SUCCESS]: (state, action) => {
    const { assets, toCollectionId, fromCollectionId } = action.payload;
    if (state.byId[toCollectionId]) {
      state.byId[toCollectionId].asset_count += assets.length;
    }
    if (state.byId[fromCollectionId]) {
      state.byId[fromCollectionId].asset_count -= assets.length;
    }
    return state;
  },
});

const initCollectionSidebar = (state, action) => {
  return {
    ...state,
    selectedIds: [],
    tags: [],
  };
};

const addCollection = (state, action) => {
  let { collection } = action.payload;
  collection.asset_count = 0;
  state.byId[collection._id] = collection;
  state.allIds.push(collection._id);
  return state;
};

const setTags = (state, action) => {
  let { tags } = action.payload;
  const sortedTags = _.sortBy(tags, ["value"]);
  return {
    ...state,
    tags: sortedTags,
  };
};

export const formatTags = (tags) => {
  let formattedTags = [];

  _.forEach(tags, (tag) => {
    formattedTags.push({
      count: tag.count,
      value: tag.tag,
      label: tag.tag,
    });
  });
  return formattedTags;
};

export default collectionsReducer;
