import React from "react";
import styled from "styled-components";
import { Toggle } from "ui";

export function SwitchField({
  label = "Switch to Notification Post",
  labelIsAfter = false,
  defaultChecked = false,
  onChange = () => {},
  disabled = false,
}) {
  return (
    <SwitchContainer>
      {!labelIsAfter && <SwitchDescription>{label}</SwitchDescription>}
      <Toggle
        checked={defaultChecked}
        icons={{
          unchecked: null,
        }}
        className="active-purple"
        onChange={onChange}
        disabled={disabled}
      />
      {labelIsAfter && <SwitchDescription after>{label}</SwitchDescription>}
    </SwitchContainer>
  );
}
const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SwitchDescription = styled.div`
  margin-right: ${(props) => (props.after ? 0 : "1rem")};
  margin-left: ${(props) => (props.after ? "1rem" : 0)};
  font-size: 1.25rem;
  font-weight: 500;
`;
