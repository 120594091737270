import React from "react";

import { PostMediaFile } from "shared/types/post";

import { MediaPicker } from "./media-picker";

export function MediaForm(props: {
  onSelect(files: PostMediaFile[]): void;
  limit: number;
  onClose(): void;
  selectedAccountIds: string[];
}) {
  return <MediaPicker {...props} />;
}
