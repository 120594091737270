import React from "react";
import PropTypes from "prop-types";
import SkedIcon from "ui/SkedIcon/SkedIcon";

import { getPlatformLabel } from "utils/accounts";
import { ONLY_MANUAL_PLATFORMS } from "constants/Platforms";
import { findManualPlatform } from "utils/accounts";
import { PlatformNameToPlatformTypeMap } from "features/post/format-post";

import "./PostLinkToPost.less";

function PostLinkToPost({ post }) {
  return (
    <div className="PostLinkToPost">
      {post.platformPermalink && (
        <a
          href={post.platformPermalink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SkedIcon
            icon="icon-crop-2"
            tooltip={`View post on ${getPlatformLabel(post.platformType)}`}
            placement="top"
          />
        </a>
      )}
      {!post.platformPermalink && post.metrics && post.metrics.url && (
        <a href={post.metrics.url} target="_blank" rel="noopener noreferrer">
          <SkedIcon
            icon="icon-crop-2"
            tooltip="View post on Instagram"
            placement="top"
          />
        </a>
      )}
      {/* Posts to FB/TW/etc before 14 July will need to use this method. */}
      {/* After 14 July we have postPermalink to store the permalink. */}
      {!post.platformPermalink &&
        post.platformPostId &&
        post.platformType === "FB" && (
          <a
            href={`https://facebook.com/${post.platformPostId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SkedIcon
              icon="icon-crop-2"
              tooltip="View post on Facebook"
              placement="top"
            />
          </a>
        )}
      {!post.platformPermalink &&
        post.platformPostId &&
        post.platformType === "TW" && (
          <a
            href={`https://twitter.com/${post.accountName}/status/${post.platformPostId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SkedIcon
              icon="icon-crop-2"
              tooltip="View post on Twitter"
              placement="top"
            />
          </a>
        )}
    </div>
  );
}

export const generatePostLinkToPostObject = (post, platforms) => {
  let obj = {
    url: "",
    platformLabel: "",
  };
  const hasManualTTPlatform = findManualPlatform(platforms, "TT");
  const hasManualTHPlatform = findManualPlatform(platforms, "TH");
  const hasNotification =
    post.platformType &&
    (hasManualTTPlatform ||
      hasManualTHPlatform ||
      ONLY_MANUAL_PLATFORMS.includes(post.platformType));

  const hasTT = platforms?.some(
    (platform) => platform.platformType === PlatformNameToPlatformTypeMap.tiktok
  );

  if (post.platformPermalink) {
    obj.url = post.platformPermalink;
    obj.platformLabel = "on " + getPlatformLabel(post.platformType);
  } else if (
    !post.platformPermalink &&
    post.platformPostId &&
    post.platformType === "FB"
  ) {
    obj.url = `https://facebook.com/${post.platformPostId}`;
    obj.platformLabel = "on Facebook";
  } else if (
    !post.platformPermalink &&
    post.platformPostId &&
    post.platformType === "TW"
  ) {
    obj.url = `https://twitter.com/${post.accountName}/status/${post.platformPostId}`;
    obj.platformLabel = "on Twitter";
  } else if (hasNotification) {
    obj.url = post.url;
    obj.platformLabel = "original media file";
  } else if (
    !post.platformPermalink &&
    (post?.metrics?.url || post?.origUrl) &&
    !hasTT
  ) {
    obj.url = post?.metrics?.url || post.origUrl;
    obj.platformLabel = "on Instagram";
  }

  return {
    url: obj.url,
    platformLabel: obj.platformLabel,
  };
};

PostLinkToPost.propTypes = {
  post: PropTypes.object,
};

export default PostLinkToPost;
