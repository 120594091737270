import React, { useRef, useEffect, useMemo } from "react";
import styled from "styled-components";

import { AlertType, Alert } from "ui/alert";
import { useScrollableParent } from "libs/use-scrollable-parent";

const PostFieldErrorComponent = ({
  className = "",
  fields,
  errors = {},
  warnings = {},
}) => {
  const errorRef = useRef();

  const fieldErrors = useMemo(() => {
    return fields
      .filter((field) => errors?.[field]?.errors?.length > 0 && field)
      .flatMap((field) => errors[field].errors);
  }, [errors, fields]);

  const fieldWarnings = useMemo(() => {
    return warnings
      ? fields
          .filter((field) => warnings?.[field]?.warnings?.length > 0 && field)
          .flatMap((field) => warnings[field].warnings)
      : [];
  }, [warnings, fields]);

  const scrollableParent = useScrollableParent(errorRef.current);

  useEffect(() => {
    if (fieldErrors?.length > 0) {
      const errorOffset = errorRef.current?.getBoundingClientRect().top || 0;

      if (errorOffset < 0) {
        scrollableParent.scrollTo({
          top: Math.abs(scrollableParent.scrollTop - Math.abs(errorOffset)),
          behavior: "smooth",
        });
      }
    }
  }, [fieldErrors]);

  return (
    <ErrorList data-testid="postErrorList" className={className} ref={errorRef}>
      {fieldErrors?.length > 0 && (
        <Alert type={AlertType.error}>
          {fieldErrors.map((err, index) => (
            <div key={`field-err-${index}`}>
              <div>{err?.error ?? err}</div>
            </div>
          ))}
        </Alert>
      )}
      {fieldWarnings?.length > 0 && (
        <Alert type={AlertType.warning}>
          {fieldWarnings.map((warning, index) => (
            <div key={`field-warning-${index}`}>
              <div>{warning}</div>
            </div>
          ))}
        </Alert>
      )}
    </ErrorList>
  );
};

export const ErrorList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const PostFieldError = styled(PostFieldErrorComponent)``;

export default PostFieldError;
