import axios, { AxiosRequestConfig, CancelToken } from "axios";

export interface FBPageWithPicture {
  id: string;
  name: string;
  picture: string;
}

export async function suggestFacebookPages(
  q: string,
  cancelToken?: CancelToken
) {
  const reqConfig: AxiosRequestConfig = {
    method: "get",
    url: "/mentions/suggest/fb-pages",
    params: { q },
    cancelToken,
  };
  // NOTE: this lets engineers inject fb user token for debugging this functionality locally
  // as this endpoint only works with user token selected from database in production
  if (process.env.REACT_APP_X_SKED_USER_TOKEN_FOR_FB_PAGES_SEARCH) {
    reqConfig.headers = {
      "x-sked-user-token-for-fb-pages-search":
        process.env.REACT_APP_X_SKED_USER_TOKEN_FOR_FB_PAGES_SEARCH,
    };
  }
  const response = await axios.request<FBPageWithPicture[]>(reqConfig);

  return response.data;
}
