import {
  HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE,
  HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
  INIT_HASHTAG_FEED_ROUTINE,
  INIT_CREATOR_FEED_ROUTINE,
  INIT_MENTIONED_FEED_ROUTINE,
  INIT_TAGGED_IN_FEED_ROUTINE,
  LOAD_MORE_IG_ASSETS_ROUTINE,
  TOGGLE_FEED_MEDIA,
  SELECT_NEW_HASHTAG_ROUTINE,
  SELECT_IG_ACCOUNT_ROUTINE,
  SELECT_EXISTING_HASHTAG_ROUTINE,
  SELECT_HASHTAG_FEED_COLLECTION,
  SELECT_HASHTAG_FEED_VIEW_ROUTINE,
  SELECT_RECENT_SEARCH_CREATORS,
  SEARCH_CREATOR,
} from "constants/ActionTypes";

import { createAction } from "@reduxjs/toolkit";

export const initHashtagFeed = createAction(
  INIT_HASHTAG_FEED_ROUTINE.TRIGGER,
  function prepare(accountId) {
    return {
      payload: {
        accountId,
      },
    };
  }
);

export const initCreatorFeed = createAction(
  INIT_CREATOR_FEED_ROUTINE.TRIGGER,
  function prepare({ user, accountId }) {
    return {
      payload: {
        accountId,
        user,
      },
    };
  }
);

export const initMentionedFeed = createAction(
  INIT_MENTIONED_FEED_ROUTINE.TRIGGER,
  function prepare(accountId) {
    return {
      payload: {
        accountId,
      },
    };
  }
);

export const initTaggedInFeed = createAction(
  INIT_TAGGED_IN_FEED_ROUTINE.TRIGGER,
  function prepare(accountId) {
    return {
      payload: {
        accountId,
      },
    };
  }
);

export const selectIgAccount = createAction(
  SELECT_IG_ACCOUNT_ROUTINE.TRIGGER,
  function prepare(accountId) {
    return {
      payload: {
        accountId,
      },
    };
  }
);

export const selectExistingHashtag = createAction(
  SELECT_EXISTING_HASHTAG_ROUTINE.TRIGGER,
  function prepare(accountId, hashtagId, filterState) {
    return {
      payload: {
        accountId,
        hashtagId,
        filterState,
      },
    };
  }
);

export const getHashtagFeedMedia = createAction(
  HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.TRIGGER,
  function prepare(accountId, hashtagId, filterState) {
    return {
      payload: {
        accountId,
        hashtagId,
        filterState,
      },
    };
  }
);

export const getRecentHashtags = createAction(
  HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.TRIGGER,
  function prepare(accountId, token) {
    return {
      payload: {
        accountId,
        token,
      },
    };
  }
);

export const toggleFeedMedia = createAction(
  TOGGLE_FEED_MEDIA,
  function prepare(selectedItem) {
    return {
      payload: {
        selectedItem,
      },
    };
  }
);

export const searchNewHashtag = createAction(
  SELECT_NEW_HASHTAG_ROUTINE.TRIGGER,
  function prepare(accountId, hashtag, location) {
    return {
      payload: {
        accountId,
        hashtag,
        location,
      },
    };
  }
);

export const selectHashtagFeedCollection = createAction(
  SELECT_HASHTAG_FEED_COLLECTION,
  function prepare(id) {
    return {
      payload: {
        id,
      },
    };
  }
);

export const selectHashtagFeedView = createAction(
  SELECT_HASHTAG_FEED_VIEW_ROUTINE.TRIGGER,
  function prepare(view, filterState) {
    return {
      payload: {
        view,
        filterState,
      },
    };
  }
);

export const loadMoreIgAssets = createAction(
  LOAD_MORE_IG_ASSETS_ROUTINE.TRIGGER,
  function prepare(filterState) {
    return {
      payload: {
        filterState,
      },
    };
  }
);

export const selectRecentlySearchedCreators = createAction(
  SELECT_RECENT_SEARCH_CREATORS.TRIGGER,
  function prepare(selectedIgAccountId, done) {
    return {
      payload: {
        selectedIgAccountId,
        done,
      },
    };
  }
);

export const searchCreator = createAction(
  SEARCH_CREATOR.TRIGGER,
  function prepare(selectedIgAccountId, searchQuery, done) {
    return {
      payload: {
        selectedIgAccountId,
        searchQuery,
        done,
      },
    };
  }
);
