import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";

import { getLoading } from "selectors/commonSelectors";
import { LoadingSpinner, Pagination } from "ui";
import { ExternalLink } from "ui/Link";

import { getStockImages, StockImage } from "api/media-library";
import { Alert, AlertType } from "ui/alert";
import { useTrackEvent } from "libs/analytics";
import { LIBRARY_SEARCH_STOCK } from "constants/Events";

import StockImagesGrid from "./StockImagesGrid";
import StocksFeedHeader from "./StocksFeedHeader";

export function useStockImages({
  page = 1,
  limit = 20,
  searchResult = null,
  location = "library",
}: {
  page?: number;
  limit?: number;
  searchResult: string | null;
  location?: "library" | "picker";
}) {
  const trackEvent = useTrackEvent();
  // total images found on pexel
  const [totalItems, setTotalItems] = useState(0);
  // loader
  const [isLoading, setIsLoading] = useState(true);
  const isUploadingAsset = useSelector(getLoading);

  // images for this page
  const [images, setImages] = useState<StockImage[]>([]);

  // when pagination/search changes make a request for new images
  useEffect(() => {
    requestStockImages();
  }, [page, limit, searchResult]);

  const requestStockImages = () => {
    setIsLoading(true);
    getStockImages(searchResult, page, limit)
      .then(({ data, total }) => {
        setTotalItems(total);
        setImages(data);

        trackEvent({
          eventName: LIBRARY_SEARCH_STOCK,
          useServices: ["AMP"],
          metadata: { location },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const hasImages = images?.length > 0;

  return {
    isLoading: isLoading || isUploadingAsset,
    hasImages,
    images,
    totalItems,
    requestStockImages,
  };
}

const StocksFeed = (): JSX.Element => {
  // searchInputText gets updated on each keystroke, searchResult is debounced
  const [searchInputText, setSearchInputText] = useState("");
  // images that are selected by user
  const [selectedImages, setSelectedImages] = useState<StockImage[]>([]);
  // pagination and search
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchResult, setSearchResult] = useState<string | null>(null);

  const { isLoading, hasImages, totalItems, images, requestStockImages } =
    useStockImages({ page, limit, searchResult });

  const handlePageChange = (page: number, size: number) => {
    setPage(page);
    setLimit(size);
  };

  // to prevent spamming the api using debounced callback for search input
  const searchStocks = useDebouncedCallback((value) => {
    setPage(1);
    setSelectedImages([]);
    setSearchResult(value);
  }, 600);

  const onSearch = ({ target }: BaseSyntheticEvent) => {
    const { value } = target;
    setSearchInputText(value);
    searchStocks(value);
  };

  return (
    <Page>
      <StocksFeedHeader
        selectedItems={selectedImages}
        onSubmit={() => {
          setSelectedImages([]);
        }}
      />
      <SearchInput
        type="text"
        placeholder="Search for assets…"
        value={searchInputText}
        onChange={onSearch}
      />

      <Alert
        type={AlertType.info}
        icon={true}
        title={"Check the Pexels license"}
      >
        <span>
          Our stock photos are provided by{" "}
          <ExternalLink href="https://www.pexels.com">Pexels</ExternalLink> and
          are subject to the{" "}
          <ExternalLink href="https://www.pexels.com/license/">
            Pexels license
          </ExternalLink>
          , which includes commercial use or modification and does not require
          attribution. Please ensure that your use of these assets complies with
          this license.
        </span>
      </Alert>

      <Content>
        {isLoading ? <LoadingSpinner /> : null}
        {!hasImages && !isLoading ? (
          <EmptyState
            query={searchResult!}
            clearSearch={() => {
              setSearchResult(null);
              setSearchInputText("");
              requestStockImages();
            }}
          />
        ) : (
          <>
            <StockImagesGrid
              images={images}
              onChange={(images) => setSelectedImages(images)}
              selectedImages={selectedImages}
            />
            <Pagination
              className="tw-mx-8"
              numItems={totalItems}
              currentPage={page}
              defaultPageSize={20}
              pageSize={limit}
              pageSizeOptions={[20, 40, 60, 80, 100]}
              onChange={(page: number, size: number) =>
                handlePageChange(page, size)
              }
            />
          </>
        )}
      </Content>
    </Page>
  );
};

type EmptyStateProps = {
  query: string;
  clearSearch: () => void;
};

const EmptyState = ({ query, clearSearch }: EmptyStateProps) => {
  return (
    <EmptyStateWrap>
      <Quirky src="/assets/img/quirkys/quirky-search.svg" alt="quirky" />
      <p>No images match your search term &quot;{query}&quot;</p>
      <span>
        Search again or view our{" "}
        <Highlighted onClick={clearSearch}>curated assets</Highlighted>
      </span>
    </EmptyStateWrap>
  );
};

const Page = styled.div`
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow: auto;
`;
const SearchInput = styled(FormControl)`
  margin: 0;
  width: 45%;
`;

const Content = styled.div`
  margin-top: 3rem;
  margin-bottom: 100px;
`;

const EmptyStateWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  p {
    font-weight: 800;
    margin-bottom: 4px;
  }
`;

const Highlighted = styled.span`
  color: #4f31ff;
  cursor: pointer;
`;

const Quirky = styled.img`
  width: 18rem;
  margin: 3rem 0 2rem;
`;

export default StocksFeed;
