import React from "react";

import { TooltipWrapper } from "ui/TooltipWrapper";
import { useCurrentUser } from "entities/current-user";
import { isFullLibrary } from "features/media-library";

interface LibraryPayWallRenderProps {
  isDisabled: boolean;
}

interface LibraryPayWallProps {
  children(props: LibraryPayWallRenderProps): React.ReactElement;
  tooltipText?: string;
  shouldBeNotDisabled?: boolean;
}

export function LibraryPayWall({
  children,
  tooltipText = "To use this feature, please upgrade your library",
  shouldBeNotDisabled = false,
}: LibraryPayWallProps): React.ReactElement {
  const user = useCurrentUser();

  const isDisabled = isFullLibrary(user);

  if (shouldBeNotDisabled || isDisabled) {
    return children({ isDisabled: false });
  }

  return (
    <TooltipWrapper placement="bottom" tooltip={tooltipText}>
      {children({ isDisabled: true })}
    </TooltipWrapper>
  );
}
