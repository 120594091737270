import currency from "currency.js";

import { Plan, PlanEstimate } from "api/plan-change";
import { PlanChangeIntention } from "../PlanChangeIntention.type";

type SupportedCurrencies = "usd" | "eur" | "gbp" | "aud";

const SYMBOLS: { [k in SupportedCurrencies]: string } = {
  usd: "$",
  eur: "€",
  gbp: "£",
  aud: "$",
};

const UGC_LIBRARY_ADDON_PRICE_BASE: { [k in SupportedCurrencies]: number } = {
  usd: 1500,
  aud: 2200,
  gbp: 1100,
  eur: 1200,
};

export interface Price {
  amount_cents: number;
  currency_code: string;
}

export function formatPriceShort({
  amount_cents,
  currency_code,
}: Price): string {
  return `${currency_code.toLocaleUpperCase()} ${currency(amount_cents, {
    fromCents: true,
    symbol: "",
    separator: "",
  }).format()}`;
}

function toCurrencyWithSymbol(
  amount_cents: number,
  currency_code: string,
  skipDecimals: boolean
): currency {
  if (currency_code.toLocaleLowerCase() in SYMBOLS) {
    const reallySkipDecimals = skipDecimals && amount_cents % 100 === 0;
    return currency(reallySkipDecimals ? amount_cents / 100 : amount_cents, {
      fromCents: !reallySkipDecimals,
      symbol: SYMBOLS[currency_code.toLocaleLowerCase()],
      precision: reallySkipDecimals ? 0 : 2,
      separator: "",
    });
  } else {
    throw Error(`Currency '${currency_code}' is not supported`);
  }
}

export function formatPriceLong(
  { amount_cents, currency_code }: Price,
  { skipDecimals }: { skipDecimals: boolean } = { skipDecimals: false }
): string {
  return `${toCurrencyWithSymbol(
    amount_cents,
    currency_code,
    skipDecimals
  ).format()} ${currency_code.toLocaleUpperCase()}`;
}

function getPlanDisplayPriceBase({
  plan,
  user_currency_code,
}: {
  plan: Plan;
  user_currency_code: string;
}): Price {
  if (plan.displayPrice) {
    return {
      amount_cents: plan.displayPrice * 100,
      currency_code: user_currency_code,
    };
  } else if (plan.price[user_currency_code.toLocaleLowerCase()]) {
    return {
      amount_cents: plan.price[user_currency_code.toLocaleLowerCase()] * 100,
      currency_code: user_currency_code,
    };
  } else {
    return { amount_cents: plan.price.usd * 100, currency_code: "usd" };
  }
}

export function getPlanDisplayPrice({
  plan,
  user_currency_code,
}: {
  plan: Plan;
  user_currency_code: string;
}): {
  monthly: Price;
  annual: {
    actual_charge: Price;
    per_month: Price;
  };
} {
  const base = getPlanDisplayPriceBase({ plan, user_currency_code });
  return {
    monthly: base,
    annual: {
      actual_charge: PriceUtils.multiply(base, 10),
      per_month: PriceUtils.round(
        PriceUtils.divide(PriceUtils.multiply(base, 10), 12)
      ),
    },
  };
}

export function getUgcLibraryAddonPrice(
  isAnnual: boolean,
  user_currency_code: string
): Price {
  if (user_currency_code.toLocaleLowerCase() in UGC_LIBRARY_ADDON_PRICE_BASE) {
    const base = {
      amount_cents:
        UGC_LIBRARY_ADDON_PRICE_BASE[user_currency_code.toLocaleLowerCase()],
      currency_code: user_currency_code.toLocaleLowerCase(),
    };
    return isAnnual ? PriceUtils.multiply(base, 10) : base;
  } else {
    throw Error(`Currency '${user_currency_code}' is not supported`);
  }
}

const PriceUtils = {
  sum(p1: Price, p2: Price): Price {
    if (
      p1.currency_code.toLocaleLowerCase() !==
      p2.currency_code.toLocaleLowerCase()
    ) {
      throw Error(
        `can not sum prices in different currencies: ${p1.currency_code}, ${p2.currency_code}`
      );
    }
    return {
      amount_cents: p1.amount_cents + p2.amount_cents,
      currency_code: p1.currency_code,
    };
  },
  multiply(p: Price, times: number): Price {
    return {
      amount_cents: p.amount_cents * times,
      currency_code: p.currency_code,
    };
  },
  divide(p: Price, times: number): Price {
    return {
      amount_cents: p.amount_cents / times,
      currency_code: p.currency_code,
    };
  },
  round(p: Price): Price {
    return {
      amount_cents: Math.round(p.amount_cents / 100) * 100,
      currency_code: p.currency_code,
    };
  },
};

export function getTotalFromIntention(
  intention: Pick<PlanChangeIntention, "plan" | "ugcLibraryAddon" | "isAnnual">,
  user_currency_code: string
): Price {
  const pricing = getPlanDisplayPrice({
    plan: intention.plan,
    user_currency_code,
  });
  const plan_price = intention.isAnnual
    ? pricing.annual.actual_charge
    : pricing.monthly;

  if (intention.ugcLibraryAddon) {
    return PriceUtils.sum(
      plan_price,
      getUgcLibraryAddonPrice(intention.isAnnual, user_currency_code)
    );
  } else {
    return plan_price;
  }
}

export function getAmountDueFromEstimate(estimate: PlanEstimate): Price | void {
  if (estimate.next_invoice_estimate) {
    return {
      amount_cents: estimate.next_invoice_estimate.amount_due,
      currency_code: estimate.next_invoice_estimate.currency_code,
    };
  }
  if (estimate.invoice_estimate) {
    return {
      amount_cents: estimate.invoice_estimate.amount_due,
      currency_code: estimate.invoice_estimate.currency_code,
    };
  }
}
