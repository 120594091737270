import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { POST_TYPES } from "constants/PostsView";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { PostFieldCounter } from "components/Posts/PostForm";
import { useOpenableState } from "libs/use-openable-state";
import { MediaFilesErrors, MediaFilesWarning, Post } from "features/post";

import { Button } from "ui/ecosystems/forms";
import { Link } from "ui/Link";
import { getAccountPlatformType } from "entities/accounts";
import { AccountType } from "shared/types/accounts";

import { PostMediaFile } from "../../../media-library/types";
import { MediaModal } from "../../../media-library";

import { PostSelectedAsset } from "./post-selected-asset";
import { WarningsErrors } from "./assets-errors-warnings";

const MAX_MEDIA_FILES = 10;

export interface PostAssetProps extends PostMediaFile {
  name?: string;
  altText?: string;
  downloadUrl?: string;
  gifThumbnail?: string;
  favorite?: boolean;
}

interface PostSelectedAssetsProperties {
  post: Post;
  className?: string;
  errors: any[];
  warnings: any[] | unknown;
  disabled?: boolean;
  selectedAccountIds: string[];
}

interface DragProvidedProps {
  innerRef: any;
  droppableProps: any;
  placeholder: any;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getTypeFromUrl = (url?: string) => {
  return (
    url &&
    (/.mp4|.mov|.wmv|.avi|.flv|.avchd|.webm|.mpeg|.mpg|.m4v|.m4p|.qt|.swf/gi.test(
      url
    )
      ? "video"
      : /.pdf/gi.test(url)
      ? "document"
      : "image")
  );
};

const getPostType = (postType: string, storyPostType?: string | undefined) => {
  if (storyPostType) {
    switch (storyPostType) {
      case "igtv":
        return "IGTV";
      case "reel":
        return "Instagram Reel";
      case "story":
      default:
        return "Story";
    }
  }

  switch (postType) {
    case POST_TYPES.IMAGE:
      return "Single image";
    case POST_TYPES.VIDEO:
      return "Video";
    case POST_TYPES.MULTI_MEDIA:
      return "Carousel";
    case POST_TYPES.PHOTO:
      return "Single Image";
    case POST_TYPES.DOCUMENT:
      return "Document/PDF";
    case POST_TYPES.TEXT:
    default:
      return "Text";
  }
};

export const PostSelectedAssets = (props: PostSelectedAssetsProperties) => {
  const {
    post,
    className = "",
    onChange,
    errors,
    warnings,
    disabled,
    selectedAccountIds,
  } = props;
  const MediaLibraryModal = useOpenableState();

  const mediaFilesWarnings: MediaFilesWarning =
    warnings?.[FIELDS.MEDIA]?.warnings ?? {};

  const mediaFilesErrors: MediaFilesErrors =
    errors?.[FIELDS.MEDIA]?.errors ?? {};

  const [mediaFiles, setMediaFiles] = useState<PostAssetProps[]>([]);
  const [postType, setPostType] = useState<string>("");

  useEffect(() => {
    let postMediaFiles: PostAssetProps[] = [];

    if (post.type && post.type !== POST_TYPES.TEXT) {
      if (post.mediaFiles?.length > 0) {
        postMediaFiles = [...post.mediaFiles];
      }

      if (!post.mediaFiles?.length) {
        const originalUrl = post.originalUrl || post.origUrl;
        const type =
          post.type !== "story" && originalUrl
            ? post.type
            : getTypeFromUrl(originalUrl);

        postMediaFiles = [
          {
            _id: post._id,
            altText: post.altText,
            fileName: post.fileName,
            isVideo: post.isVideo,
            name: post.fileName,
            originalUrl: post.originalUrl || post.origUrl,
            thumbnailUrl: post.thumbnailUrl || "",
            type,
            url: post.url,
            meta: post.meta || {},
          },
        ];
      }
    }

    setMediaFiles(postMediaFiles);
    setPostType(getPostType(post.type, post[FIELDS.STORY_TYPE]) || "");
  }, [post]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      mediaFiles,
      result.source.index,
      result.destination.index
    );

    setMediaFiles(items);
    // @ts-ignore
    onChange(items);
  };

  function handleRemove(index: number) {
    mediaFiles.splice(index, 1);
    onChange(mediaFiles);
  }

  const handleEdit = (file: PostAssetProps, index: number) => {
    const newMediaFiles = [...mediaFiles];
    newMediaFiles[index] = file;
    onChange(newMediaFiles);
  };

  const handleSelectAssets = (newMediaFiles) => {
    const filteredAssets = newMediaFiles.filter(
      (asset) => mediaFiles.findIndex((media) => media.url === asset.url) === -1
    );
    onChange([...mediaFiles, ...filteredAssets]);

    MediaLibraryModal.close();
  };

  return (
    <PostSelectedAssetsRoot className={className}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided: DragProvidedProps) => (
            <MediaContainer>
              <DragContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {mediaFiles.map((item, index) => {
                  return (
                    <Draggable
                      key={item?._id + (item.url || index)} // mediaFile has no _id, use url instead
                      draggableId={item.url || index} // mediaFile has no _id, use url instead
                      index={index}
                    >
                      {(draggableProps: any) => (
                        <PostSelectedAsset
                          draggableProps={draggableProps}
                          asset={item}
                          onRemove={() => handleRemove(index)}
                          onEdit={(file) => handleEdit(file, index)}
                          disabled={disabled}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </DragContainer>
              {mediaFiles.length === 0 ? (
                <EmptyContainer css="flex-grow: 1">
                  {isTextLinkPostAllowed(post.accounts as AccountType[]) && (
                    <p>
                      <span>Post is currently a text/link post. </span>
                    </p>
                  )}
                  <p>
                    <Link
                      data-testid="postAddMedia"
                      as="button"
                      className="add-media-button"
                      onClick={MediaLibraryModal.open}
                      disabled={post.accounts?.length === 0}
                    >
                      Add media
                    </Link>
                  </p>
                  {hasInstagramAccount(post.accounts as AccountType[]) &&
                    !isInstagramOnlyPost(post.accounts as AccountType[]) && (
                      <p>
                        <span>
                          All videos under 90 seconds will be posted to
                          Instagram as Reels.{" "}
                        </span>
                      </p>
                    )}
                </EmptyContainer>
              ) : !disabled ? (
                <ButtonStyled
                  data-testid="postAddMedia"
                  outline
                  onClick={MediaLibraryModal.open}
                  disabled={
                    mediaFiles.length >= MAX_MEDIA_FILES ||
                    post.accounts?.length === 0
                  }
                >
                  +
                </ButtonStyled>
              ) : null}
            </MediaContainer>
          )}
        </Droppable>
      </DragDropContext>

      <AssetsDetails>
        {postType && <PostType>{postType} post</PostType>}
        <PostFieldCounter count={mediaFiles.length} max={MAX_MEDIA_FILES}>
          Media
        </PostFieldCounter>
      </AssetsDetails>

      {/* TODO FIELDS.TYPE error mesesages for hotfix, need to refactor as FIELDS.MEDIA, please see WarningsErrors */}
      <PostFieldErrorStyled
        fields={[FIELDS.TYPE]}
        errors={errors}
        warnings={warnings}
      />

      <WarningsErrors
        warnings={mediaFilesWarnings}
        errors={mediaFilesErrors}
        files={mediaFiles}
      />

      <MediaModal
        show={MediaLibraryModal.isOpened}
        onHide={MediaLibraryModal.close}
        limit={10 - mediaFiles.length}
        onSelect={handleSelectAssets}
        selectedAccountIds={selectedAccountIds}
      />
    </PostSelectedAssetsRoot>
  );
};

const DisabledAddButton = css`
  background-color: #e2e2e2 !important;
  border-color: #e2e2e2 !important;
  color: #999 !important;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  background: white;
  width: 52px;
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;

  &:disabled {
    ${DisabledAddButton}
    &:hover {
      ${DisabledAddButton};
      cursor: not-allowed;
    }
  }

  &:hover {
    --textColor: #fff;
    background: #4f31ff;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  flex-direction: column;

  & > p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
    color: #808080;
  }

  .add-media-button {
    font-style: normal;
    margin-top: 4px;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  padding: 8px;
  border: 1px solid #cacaca;
  border-radius: 5px;
`;

const DragContainer = styled.div`
  position: relative;
  display: flex;

  flex-wrap: wrap;
  height: max-content;
  grid-gap: 10px;
  background: transparent;
`;

const PostFieldErrorStyled = styled(PostFieldError)`
  margin-top: 10px;
`;

const AssetsDetails = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  grid-gap: 20px;
`;

const PostType = styled.div`
  font-style: italic;
  font-size: 12.5px;
  color: #b3b3b3;
`;

const PostSelectedAssetsRoot = styled.div`
  ${PostFieldError} {
    margin-top: 10px;
  }
`;

function isInstagramOnlyPost(accounts?: AccountType[]): boolean {
  if (!accounts) {
    return false;
  }
  return accounts.every((acc) => getAccountPlatformType(acc) === "IG");
}

function isLinkedinOnlyPost(accounts?: AccountType[]): boolean {
  if (!accounts) {
    return false;
  }
  return accounts.every((acc) => getAccountPlatformType(acc) === "LI");
}

function hasInstagramAccount(accounts?: AccountType[]): boolean {
  if (!accounts) {
    return false;
  }
  return accounts.some((acc) => getAccountPlatformType(acc) === "IG");
}

function isTextLinkPostAllowed(accounts?: AccountType[]): boolean {
  if (!accounts) {
    return false;
  }
  return accounts.every((acc) =>
    ["FB", "LI", "TW"].includes(getAccountPlatformType(acc))
  );
}
