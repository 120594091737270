import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GridMediaCard from "components/MediaLibrary/GridMediaCard/GridMediaCard";
import { StockImage } from "api/media-library";
import _ from "lodash";
import { SkedIcon } from "../../../../ui";

type Props = {
  images: StockImage[];
  selectedImages: StockImage[];
  onChange: (images: StockImage[]) => void;
};

const StockImagesGrid = ({ images, selectedImages, onChange }: Props) => {
  const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);

  useEffect(() => {
    setSelectedCardIds(selectedImages.map((image) => image.id));
  }, [selectedImages]);

  const toggleSelection = (selectedId: number) => {
    const newIdsArray = [...selectedCardIds];
    if (selectedCardIds.includes(selectedId)) {
      _.pull(newIdsArray, selectedId);
    } else {
      newIdsArray.push(selectedId);
    }
    setSelectedCardIds(newIdsArray);
    onChange(images.filter((image) => newIdsArray.includes(image.id)));
  };
  const getHoverProps = (media: object) => {
    return (
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-pt-4 tw-pl-4 tw-pr-4 tw-pb-2 tw-h-full tw-items-center tw-cursor-default">
          <div className="tw-flex tw-flex-wrap">
            <div className="tw-flex tw-text-lg">By {media.photographer}</div>
          </div>
          <div className="tw-flex-grow tw-flex tw-justify-end tw-font-black">
            <div className="tw-mr-4 tw-leading-none">
              <a href={media.url} target="_blank" rel="noopener noreferrer">
                <SkedIcon
                  icon="icon-crop-2"
                  color="purple"
                  size="14"
                  tooltip="View on Pexels"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Grid>
      {images.map((image) => {
        const isSelected = selectedCardIds.includes(image.id);
        return (
          <GridMediaCard
            key={image.id}
            id={image.id}
            assetUrl={image.src.medium}
            selected={isSelected}
            selecting={selectedCardIds.length > 0}
            canFavorite={false}
            hoverProps={getHoverProps(image)}
            onCardClick={() => toggleSelection(image.id)}
          />
        );
      })}
    </Grid>
  );
};

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default StockImagesGrid;
