import React from "react";
import { Modal, ModalProps } from "react-bootstrap";
import styled from "styled-components";

import { ModalHeader } from "./modal-header";
import { ModalContent } from "./modal-content";

export const BaseModal = styled(({ className, ...restProps }: ModalProps) => (
  <Modal dialogClassName={className} {...restProps} />
))`
  max-width: 927px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 5px;

  & > .modal-content {
    --dialog-background-color: #fff;
    background-color: var(--dialog-background-color);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-x: auto;
    max-height: 92vh;

    ${ModalHeader} {
      margin: 32px 32px 0px;
    }

    ${ModalContent} {
      margin: 32px;
    }

    ${ModalHeader} + ${ModalContent} {
      margin-top: 20px;
    }
  }
`;
