import * as React from "react";
import styled, { css } from "styled-components";

import {
  HeadCell,
  Row,
  Table,
  TableCell,
  TableHead,
  TBody,
} from "ui/simple-table";
import { Checkbox } from "ui/ecosystems/forms";
import { SmallMediaFilePreview } from "../small-media-file-preview";
import { CollectionFile } from "../../../../shared/types/files";

interface Props {
  files: CollectionFile[];
  renderHeader(HeadCell: React.FC): React.ReactNode;
  renderFile(file: CollectionFile, Cell: React.FC): React.ReactNode;
  isFileSelected(file: CollectionFile): boolean;
  onSelect(file: CollectionFile): void;
  onRemove(file: CollectionFile): void;
}

export function SelectableFilesTable(props: Props) {
  return (
    <Table>
      <TableHead>
        <HeadCell />
        <HeadCell />
        {props.renderHeader(HeadCell)}
      </TableHead>
      <TBody>
        {props.files.map((file) => {
          const isFileSelected = props.isFileSelected(file);

          function handleClick() {
            isFileSelected ? props.onRemove(file) : props.onSelect(file);
          }

          return (
            <File
              key={file._id}
              isSelected={isFileSelected}
              onClick={handleClick}
            >
              <TableCell css="width: 20px">
                <span onClick={(e) => e.stopPropagation()}>
                  <Checkbox checked={isFileSelected} onChange={handleClick} />
                </span>
              </TableCell>
              <TableCell css=" width: 55px">
                <Media src={file.thumbnail} isVideo={file.meta.isVideo} />
              </TableCell>
              {props.renderFile(file, TableCell)}
            </File>
          );
        })}
      </TBody>
    </Table>
  );
}

export const Media = styled(SmallMediaFilePreview)`
  width: 55px;
  height: 55px;
`;

export const File = styled(Row)<{ isSelected: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: #f6f5ff;
    `}
`;
