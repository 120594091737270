export interface CanvaFile {
  designId: string;
  designTitle: string;
  exportUrl: string;
}

export async function selectFileFromCanva(type?: string): Promise<CanvaFile> {
  if (!window.CanvaApi) {
    window.CanvaApi = await window.Canva.DesignButton.initialize({
      apiKey: "3c1Qg1f4spDDzMxKj-ew8ygL",
    });
  }

  return new Promise((resolve, reject) => {
    window.CanvaApi.createDesign({
      design: {
        type: type || "SocialMedia",
      },
      editor: {
        publishLabel: "Export to Sked",
        fileType: "jpeg",
      },
      onDesignPublish: (file) => resolve(file),
      // onDesignPublish: async (result: any) => {
      //   resolve(result)
      //   const downloads = fetchBlobs([
      //     {
      //       link: result.exportUrl,
      //       name: result.designTitle,
      //     },
      //   ]);
      //   try {
      //     const blobs = await Promise.all(downloads);
      //     resolve(blobs);
      //   } catch (e) {
      //     console.error(e);
      //     reject({
      //       error: "Failed to download files from Canva",
      //     });
      //   }
      // },
    });
  });
}
