import React from "react";
import { AccountAvatar } from "entities/accounts";
import { useDispatch, useSelector } from "react-redux";
import { ConversationAvatar } from "../../conversation-avatar";

import { MessageStatus } from "types/conversationInbox";
import {
  BlockContainer,
  MessageContainer,
  MessageHiddenNote,
  RetryContainer,
  RootContainer,
} from "./styled";
import { renderContent } from "./message-types/message-content-render";
import { ServerMessageInfoProps } from "./types";
import { ReplyToBlock } from "./reply-to-block";
import { retryCreateMessage } from "actions/conversationInbox";
import { SkedIcon } from "ui";
import { DateLabel } from "../components/date-label";
import { ReactionsChangeData } from "../components/message-reactions";
import { Collaborator } from "features/user";
import {
  getConversationAccountContext,
  WrappedInboxState,
} from "selectors/conversationInboxSelectors";

export interface AccountMessageProps extends ServerMessageInfoProps {
  userName: string;
  isTeamMessage?: boolean;
  isCurrentUser: boolean;
  collaborators: Collaborator[];
  interlocutorId: string;
  status?: MessageStatus;
  shouldDisplaySender: boolean;
  forceLeft?: boolean;
  onReactionsChange?: (data: ReactionsChangeData) => void;
}

export function AccountMessage({
  conversationId,
  message = "",
  timestamp,
  userName,
  src,
  isTeamMessage = false,
  collaborators,
  story,
  attachments,
  type,
  name,
  replyTo,
  interlocutorId,
  status,
  reactions,
  messageId,
  onReactionsChange,
  attachment,
  shouldDisplaySender,
  originalMessage,
  forceLeft,
  conversation,
}: AccountMessageProps): JSX.Element {
  const dispatch = useDispatch();
  const newMessage = isTeamMessage
    ? getTeamMessage({ message, collaborators })
    : message;

  const onRetry = () => {
    dispatch(
      retryCreateMessage(conversationId, isTeamMessage, messageId, message)
    );
  };

  const convAccount = useSelector((state: WrappedInboxState) =>
    getConversationAccountContext(state, conversationId)
  );

  forceLeft = !!forceLeft;
  const avatar = isTeamMessage ? (
    <ConversationAvatar src={src} name={userName} size={29} showInitial />
  ) : (
    <AccountAvatar size="small" />
  );
  return (
    <RootContainer left={forceLeft}>
      <MessageContainer
        left={forceLeft}
        opaque={!!originalMessage?.deleted || !!originalMessage?.hidden}
      >
        {forceLeft && avatar}
        <BlockContainer>
          {replyTo && (
            <ReplyToBlock
              message={replyTo.message}
              name={replyTo.name}
              type={replyTo.type}
              story={replyTo.story}
              attachments={replyTo.attachments}
              isLeft={false}
              isCurrentSMUMessage={false}
              isReplyToSMUMessage={replyTo.sender?.id == interlocutorId}
              messageId={replyTo.messageId}
              originalMessage={replyTo.originalMessage}
              conversationId={conversationId}
            />
          )}
          {renderContent({
            type,
            name,
            story,
            attachments,
            message: newMessage,
            isTeamMessage,
            isLeft: forceLeft,
            isRepliedMessage: false,
            reactions,
            onReactionsChange,
            messageId,
            attachment,
            originalMessage,
            conversationId,
            status,
            conversation,
            accountContext: convAccount,
          })}
        </BlockContainer>
        {!forceLeft && avatar}
      </MessageContainer>
      <DateLabel
        forceLeft={forceLeft}
        type={type}
        userName={userName}
        timestamp={timestamp}
        shouldDisplaySender={shouldDisplaySender}
      />
      {originalMessage?.hidden && (
        <MessageHiddenNote>Message has been hidden</MessageHiddenNote>
      )}
      {status === MessageStatus.failed && (
        <RetryContainer onLeft={forceLeft}>
          <SkedIcon icon="info-light" color="red" />
          Failed to deliver message.
          <span onClick={onRetry}>Retry</span>
        </RetryContainer>
      )}
    </RootContainer>
  );
}

interface TeamMessageProps {
  message: string;
  collaborators: Collaborator[];
}

const getTeamMessage = ({
  message,
  collaborators,
}: TeamMessageProps): JSX.Element => {
  // eslint-disable-next-line no-useless-escape
  const regex = /@[\[]\w+[\]]/gm;
  const newMessage = message.replace(regex, (text: string) => {
    const collaborator = collaborators.find((col: Collaborator) =>
      text.includes(col._id)
    );

    const name = collaborator
      ? `${collaborator.firstName}${
          collaborator.lastName ? `_${collaborator.lastName}` : ""
        }`
      : "unknown";
    const mention = `<span style="color: #4F31FF">@${name}</span>`;
    return mention;
  });

  return <div dangerouslySetInnerHTML={{ __html: newMessage }} />;
};
