import { attach, createEffect, createEvent, restore, sample } from "effector";
import { useStore } from "effector-react";
import { Store as ReduxStore } from "redux";
import {
  setLocalStorageHomeSelectedGroupName,
  setLocalStorageSelectedAccounts,
} from "libs/storage/adapters";
import { persist } from "libs/effector-storage";

import { $accounts } from "entities/accounts";
import { AccountType } from "shared/types/accounts";
import { postsCreatedOrUpdated } from "features/post";
import { CalendarPostType } from "features/calendar-new/types";

import { updateSelectedAccounts } from "actions/commonActions";
import { accountsTable } from "../table";
import {
  getAccountNameParts,
  getFirstGroupOrAmountOfAccountsIds,
  sortAccounts,
} from "utils/accounts";
import { accountHasGroup } from "../libs/accounts-groups";

export const accountsSelected = createEvent<string[]>();
export const setSelectedAccounts = createEvent<string[]>();
export const $selectedAccountsIds = restore<string[]>(accountsSelected, []).on(
  setSelectedAccounts,
  (_, accountsIds) => accountsIds
);
export const $selectedAccounts = accountsTable.getByIds($selectedAccountsIds);

const selectedAccountsFx = createEffect((props: any) => {
  const accountsToAdd = [];
  const accountsIdsToAdd = <string[]>[];
  if (props?.post?.newPosts?.length) {
    props.post.newPosts.forEach((post: CalendarPostType) => {
      if (post.accountIds?.length) {
        post.accountIds.forEach((accountId) => {
          if (!props.data.selectedAccountsIds.includes(accountId)) {
            const newAcc = props.data.accounts.find(
              (account: any) => account._id === accountId
            );
            accountsToAdd.push(newAcc);
            accountsIdsToAdd.push(accountId);
          }
        });
      }
    });

    props.data.selectedAccountsIds = [
      ...props.data.selectedAccountsIds,
      ...accountsIdsToAdd,
    ];

    accountsSelected(props.data.selectedAccountsIds);

    setLocalStorageSelectedAccounts(
      JSON.stringify(props.data.selectedAccountsIds)
    );

    // NOTE: (Andrey) select first new added group on Sked Home page.
    if (accountsIdsToAdd.length) {
      const firstAccountWithGroupToAdd = props.data.accounts.find(
        (account: AccountType) =>
          accountsIdsToAdd.includes(account._id) && accountHasGroup(account)
      );

      if (firstAccountWithGroupToAdd) {
        const [groupName] = getAccountNameParts(firstAccountWithGroupToAdd);
        groupName && setLocalStorageHomeSelectedGroupName(groupName);
      }
    }
  }
});

const selectedAccountsWithParamsFx = attach({
  effect: selectedAccountsFx,
  source: {
    selectedAccounts: $selectedAccounts,
    accounts: $accounts,
    selectedAccountsIds: $selectedAccountsIds,
  },
  mapParams: (params: any, data) => {
    return { ...params, data };
  },
});

sample({
  clock: postsCreatedOrUpdated,
  target: selectedAccountsWithParamsFx,
});

sample({
  clock: [$accounts],
  source: { accounts: $accounts, selectedAccounts: $selectedAccountsIds },
  fn: ({ selectedAccounts, accounts }) => {
    if (!selectedAccounts?.length) {
      return getFirstGroupOrAmountOfAccountsIds(
        sortAccounts(Object.values(accounts)),
        5
      );
    }

    return selectedAccounts.filter(
      (accountId) =>
        accounts.find((account) => account._id === accountId) && accountId
    );
  },
  target: $selectedAccountsIds,
});

export function syncUpSelectedAccounts(Store: ReduxStore<unknown>): any {
  $selectedAccountsIds.watch((accountsIds) => {
    Store.dispatch(updateSelectedAccounts(accountsIds));
  });
}

persist({
  key: "selectedAccounts",
  store: $selectedAccountsIds,
  serialize: (set) => JSON.stringify([...set]),
  deserialize: (array) => JSON.parse(array || "[]"),
});

export function useSelectedAccounts(): AccountType[] {
  return useStore($selectedAccounts);
}
