import React, { useCallback, useEffect, useState } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FormControl } from "ui";
import { FIELDS, GMB_TOPICS } from "constants/PostFormFields";
import { useDebouncedCallback } from "use-debounce";
import { TextInput } from "ui/ecosystems/forms/inputs";

const PostCoupon = ({
  topicType,
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  className = "",
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (post["gmbOffer"] && post["gmbOffer"]["couponCode"]) {
      setValue(post["gmbOffer"]["couponCode"]);
    }
  }, []);
  const bounce = useDebouncedCallback((value) => {
    onChange({ [FIELDS.GMB_COUPON_CODE]: value });
  }, 500);

  const forTopics = [GMB_TOPICS.OFFER.VALUE];
  if (!forTopics.includes(topicType)) {
    return "";
  }

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Coupon code (optional)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.GMB_TOPIC_TYPE}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <TextInput
        value={value}
        placeholder="SALE10"
        onChange={(value) => {
          const newVal = value;
          setValue(newVal);
          bounce(newVal);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default PostCoupon;
