import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

import { PostMediaFile } from "shared/types/post";

import { MediaForm } from "../media-picker";

export function MediaModal(props: {
  onSelect(files: PostMediaFile[]): void;
  limit: number;
  show: boolean;
  onHide(): void;
  selectedAccountIds: string[];
}) {
  return (
    <ModalRoot show={props.show} onHide={props.onHide}>
      <MediaForm
        onSelect={props.onSelect}
        limit={props.limit}
        onClose={props.onHide}
        selectedAccountIds={props.selectedAccountIds}
      />
    </ModalRoot>
  );
}

const ModalRoot = styled(({ className, ...props }) => (
  <Modal dialogClassName={className} {...props} />
))`
  --modal-width: 806px;
  width: var(--modal-width);
  max-width: 90%;
  box-sizing: border-box;
  border-radius: 5px;
`;
