export function copyToClipboard(text: string) {
  const span = document.createElement("span");
  span.textContent = text;
  document.body.appendChild(span);
  const selection = document.getSelection();
  const range = document.createRange();
  range.selectNode(span);
  selection?.removeAllRanges();
  selection?.addRange(range);
  document.execCommand("copy");
  selection?.removeAllRanges();
  document.body.removeChild(span);
}
