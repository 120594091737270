import emojiRegex from "emoji-regex";

const ZERO_WIDTH_SPACE_REGEX = /[\u200B-\u200D\uFEFF]/g;
const regex = emojiRegex();

export const removeZeroWidthSpace = (text = "") => {
  if (!text) {
    return;
  }
  const strHasEmoji = text.match(regex);

  if (!strHasEmoji) {
    return text.replace(ZERO_WIDTH_SPACE_REGEX, "");
  }

  return [...text.matchAll(regex)]
    .map((match) => ({ emojiStr: match[0], index: match.index }))
    .reduce((acumStr, emoji, index, array) => {
      if (index === 0 && emoji.index !== 0) {
        acumStr += text
          .substring(0, emoji.index)
          .replace(ZERO_WIDTH_SPACE_REGEX, "");
      }

      acumStr += emoji.emojiStr;
      const nextEmoji = array[index + 1];
      const startIndex = emoji.index + emoji.emojiStr.length;
      const endIndex = nextEmoji ? nextEmoji.index : text.length;
      acumStr += text
        .substring(startIndex, endIndex)
        .replace(ZERO_WIDTH_SPACE_REGEX, "");

      return acumStr;
    }, "");
};
