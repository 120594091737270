import { createReducer } from "@reduxjs/toolkit";
import {
  ADD_CONTENT_LABEL_ROUTINE,
  GET_CONTENT_LABELS_ROUTINE,
  REMOVE_CONTENT_LABEL_ROUTINE,
  SET_WORKSHOP_SELECTED_CONTENT_LABEL,
  SET_WORKSHOP_SELECTED_GROUP,
  UPDATE_CONTENT_LABEL_ROUTINE,
  UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE,
  GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE,
} from "constants/ActionTypes";
import { immutableUpdate } from "./reducerUtils";

const initialState = {
  isLoading: false,
  contentLabels: null,
  selectedGroup: null,
  selectedContentLabel: null,
  loadingTopicLabelIds: [],
};

const workshopReducer = createReducer(initialState, {
  [SET_WORKSHOP_SELECTED_GROUP.TRIGGER]: (state, action) => {
    return {
      ...state,
      selectedGroup: action.payload.group,
    };
  },

  [SET_WORKSHOP_SELECTED_CONTENT_LABEL.TRIGGER]: (state, action) => {
    return {
      ...state,
      selectedContentLabel: action.payload.contentLabel,
    };
  },
  [GET_CONTENT_LABELS_ROUTINE.TRIGGER]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [GET_CONTENT_LABELS_ROUTINE.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      contentLabels: action.payload.data,
    };
  },
  [GET_CONTENT_LABELS_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      isLoading: false,
      contentLabels: [],
    };
  },
  [GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.TRIGGER]: (state, action) => {
    const { labelId } = action.payload;
    const contentLabelIndex = state.contentLabels?.findIndex(
      (item) => item._id === labelId
    );

    if (contentLabelIndex < 0) {
      return state;
    }
    const newContentLabel = state.contentLabels[contentLabelIndex];

    return {
      ...state,
      loadingTopicLabelIds: [...state.loadingTopicLabelIds, labelId],
      contentLabels: immutableUpdate(state.contentLabels, contentLabelIndex, {
        ...newContentLabel,
        isLoadingGeneratedTopic: true,
      }),
    };
  },
  [GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.SUCCESS]: (state, action) => {
    const { labelId, generatedTopic } = action.payload;
    const contentLabelIndex = state.contentLabels?.findIndex(
      (item) => item._id === labelId
    );

    if (contentLabelIndex < 0) {
      return state;
    }
    const newContentLabel = state.contentLabels[contentLabelIndex];

    return {
      ...state,
      loadingTopicLabelIds: state.loadingTopicLabelIds.filter(
        (id) => id !== labelId
      ),
      contentLabels: immutableUpdate(state.contentLabels, contentLabelIndex, {
        ...newContentLabel,
        generatedTopic,
        isLoadingGeneratedTopic: false,
      }),
    };
  },
  [GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.FAILURE]: (state, action) => {
    const { labelId } = action.payload;
    const contentLabelIndex = state.contentLabels?.findIndex(
      (item) => item._id === labelId
    );

    if (contentLabelIndex < 0) {
      return state;
    }
    const newContentLabel = state.contentLabels[contentLabelIndex];

    return {
      ...state,
      loadingTopicLabelIds: state.loadingTopicLabelIds.filter(
        (id) => id !== labelId
      ),
      contentLabels: immutableUpdate(state.contentLabels, contentLabelIndex, {
        ...newContentLabel,
        isLoadingGeneratedTopic: false,
      }),
    };
  },
  [ADD_CONTENT_LABEL_ROUTINE.TRIGGER]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [ADD_CONTENT_LABEL_ROUTINE.SUCCESS]: (state, action) => {
    const newLabel = action.payload;
    return {
      ...state,
      loadingTopicLabelIds: [...state.loadingTopicLabelIds, newLabel?._id],
      isLoading: false,
      selectedGroup: {
        ...state.selectedGroup,
        id: newLabel?.groupId,
        _id: newLabel?.groupId,
        groupId: newLabel?.groupId,
        adminId: newLabel?.adminId,
      },
      contentLabels: [
        {
          ...newLabel,
          isLoadingGeneratedTopic: true,
        },
        ...state.contentLabels,
      ],
    };
  },
  [ADD_CONTENT_LABEL_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [UPDATE_CONTENT_LABEL_ROUTINE.TRIGGER]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [UPDATE_CONTENT_LABEL_ROUTINE.SUCCESS]: (state, action) => {
    const { refreshTopic, ...contentLabel } = action.payload;

    const contentLabelIndex = state.contentLabels?.findIndex(
      (item) => item._id === contentLabel._id
    );

    if (contentLabelIndex < 0) {
      return state;
    }

    return {
      ...state,
      isLoading: false,
      loadingTopicLabelIds: refreshTopic
        ? [...state.loadingTopicLabelIds, contentLabel?._id]
        : state.loadingTopicLabelIds,
      contentLabels: immutableUpdate(state.contentLabels, contentLabelIndex, {
        ...contentLabel,
        isLoadingGeneratedTopic: refreshTopic,
      }),
    };
  },
  [UPDATE_CONTENT_LABEL_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE.SUCCESS]: (state, action) => {
    const { id, generatedTopic } = action.payload;

    const updatedContentLabels = state.contentLabels.map((contentLabel) =>
      contentLabel._id === id
        ? { ...contentLabel, generatedTopic }
        : contentLabel
    );

    return {
      ...state,
      contentLabels: updatedContentLabels,
    };
  },
  [REMOVE_CONTENT_LABEL_ROUTINE.TRIGGER]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [REMOVE_CONTENT_LABEL_ROUTINE.SUCCESS]: (state, action) => {
    const { id } = action.payload;
    const contentLabelIndex = state.contentLabels?.findIndex(
      (item) => item._id === id
    );

    if (contentLabelIndex < 0) {
      return state;
    }

    return {
      ...state,
      isLoading: false,
      contentLabels: state.contentLabels?.filter((item) => item.id !== id),
    };
  },
  [REMOVE_CONTENT_LABEL_ROUTINE.FAILURE]: (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  },
});

export default workshopReducer;
