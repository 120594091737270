import React from "react";
import styled from "styled-components";
import { Post, NotificationPerson } from "shared/types/post";
import { Alert, AlertType } from "ui/alert";

interface Props {
  post: Post;
}

interface PersonProps {
  person: NotificationPerson;
}

const PostNotificationMeta = (props: Props) => {
  const { pushNotificationMeta } = props.post;
  if (!pushNotificationMeta || !Array.isArray(pushNotificationMeta)) {
    return null;
  }
  // if no information in here then don't show anything!
  if (pushNotificationMeta.length < 1) {
    return null;
  }
  return (
    <Alert
      type={AlertType.info}
      title={"Post reminder information"}
      icon={true}
      isExpandable={true}
      defaultExpanded={false}
    >
      <ul>
        {pushNotificationMeta.map((person: NotificationPerson) => {
          return (
            <NotifiedPerson
              key={`person-${person._id}-${person.notified}`}
              person={person}
            />
          );
        })}
      </ul>
    </Alert>
  );
};

const NotifiedPerson = (props: PersonProps) => {
  const { person } = props;
  let status = "Unknown status";
  if (person.notified === "mobile") {
    if (person.opened) {
      status = "Opened push notification.";
    } else if (person.delivered) {
      status = "Delivered notification to phone.";
    }
  }
  if (person.published) {
    status = "Marked as published.";
  }

  return (
    <PersonList>
      <PersonName>
        {person.name} (via {person.notified})
      </PersonName>
      <br />
      <NotificationStatus>{status}</NotificationStatus>
    </PersonList>
  );
};

const PersonList = styled.li`
  font-size: 14px;
  line-height: 20px;
`;

const PersonName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
const NotificationStatus = styled.span`
  font-size: 14px;
  line-height: 16px;
`;

export default PostNotificationMeta;
