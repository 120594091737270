import React from "react";
import PropTypes from "prop-types";
import { SkedIcon } from "ui";
import { useSelector, useDispatch } from "react-redux";
import { toggleFeedMedia } from "actions/sourceMedia";
import { getSelectedFeedIds } from "selectors/hashtagFeedSelectors";
import { showViewHashtagFeedItemModal } from "actions/modal";
import "./FeedGrid.less";
import GridMediaCard from "components/MediaLibrary/GridMediaCard/GridMediaCard";
import moment from "moment";
import NumberFormat from "react-number-format";

const FeedGrid = ({ feedMedia }) => {
  const dispatch = useDispatch();
  const selectedFeedIds = useSelector(getSelectedFeedIds);

  const getHoverProps = (media) => {
    return (
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-pt-4 tw-pl-4 tw-pr-4 tw-pb-2 tw-h-full tw-items-center tw-cursor-default">
          <div className="tw-flex tw-flex-wrap">
            <div className="tw-flex">
              <SkedIcon
                icon="icon-heart"
                size="14"
                className="tw-cursor-default"
              />
              <span className="tw-ml-3 tw-mr-4 tw-text-xl tw-leading-tight">
                <NumberFormat
                  value={media.like_count}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </span>
            </div>
            <div className="tw-flex">
              <SkedIcon
                icon="icon-ig-comment"
                size="14"
                className="tw-cursor-default"
              />
              <span className="tw-ml-3 tw-mr-4 tw-text-xl tw-leading-tight">
                <NumberFormat
                  value={media.comments_count}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </span>
            </div>
          </div>
          <div className="tw-flex-grow tw-flex tw-justify-end tw-font-black">
            <div className="tw-mr-4 tw-leading-none">
              {media.permalink ? (
                <a
                  href={media.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SkedIcon
                    icon="icon-crop-2"
                    color="purple"
                    size="14"
                    tooltip="View on Instagram"
                  />
                </a>
              ) : (
                <a
                  href={media.media_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SkedIcon
                    icon="icon-click"
                    color="purple"
                    size="14"
                    tooltip="View original file"
                  />
                </a>
              )}
            </div>
            <div className="tw-leading-none">
              <SkedIcon
                icon="icon-eye"
                size="14"
                color="purple"
                className="tw-cursor-pointer"
                tooltip="View post details"
                onClick={() => dispatch(showViewHashtagFeedItemModal(media))}
              />
            </div>
          </div>
        </div>
        {media.timestamp && (
          <div className="tw-flex tw-items-center tw-text-lg tw-pl-4">
            {moment(media.timestamp).fromNow()}
          </div>
        )}
      </div>
    );
  };
  const handleCardClick = (media) => {
    dispatch(toggleFeedMedia(media));
  };

  return (
    <div className="tw-flex tw-flex-wrap">
      {feedMedia.map((media) => {
        const isSelected = selectedFeedIds.includes(media.id);
        const isVideo = media.media_type === "VIDEO";

        if (!media.media_url) {
          return <></>;
        }

        return (
          <GridMediaCard
            key={media.id}
            id={media.id}
            assetUrl={media.thumbnail || media.media_url}
            selected={isSelected}
            selecting={selectedFeedIds.length > 0}
            hoverProps={getHoverProps(media)}
            canFavorite={false}
            onCardClick={() => handleCardClick(media)}
            isVideo={isVideo}
            videoPreviewUrl={media.thumbnail || media.media_url}
          />
        );
      })}
    </div>
  );
};

FeedGrid.propTypes = {
  feedMedia: PropTypes.array,
};

export default FeedGrid;
