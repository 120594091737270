interface User {
  email: string;
  firstName: string;
  lastName: string;
  profileImage?: string | null;
  username?: string;
  _id: string;
}

export default function replaceUserTags(
  message: string,
  users: User[]
): string {
  return message.trim().replace(/@(.*?)(?=,)/gm, (username: string) => {
    const user = users.find((user) => "@" + user.username === username);
    if (!user) return username;
    return `@[${user._id}]`;
  });
}
