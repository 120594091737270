import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";
import { getUser } from "selectors/commonSelectors";
import { TooltipWrapper } from "ui";
import { Post } from "features/post";
import { User } from "features/user";
import moment from "moment";

const PostBy = ({ post }: PostProp) => {
  const { createdBy, updatedBy } = post;
  const user: User | null = useSelector(getUser);

  return createdBy && user ? (
    <TooltipWrapper
      placement="top"
      tooltip={<TooltipContents post={post} user={user}></TooltipContents>}
    >
      <Initials className="tw-border-gray-500 tw-p-2 tw-text-gray-400 tw-text-lg">
        {updatedBy ? getInitial(user, updatedBy) : getInitial(user, createdBy)}
      </Initials>
    </TooltipWrapper>
  ) : (
    <LegacyPostBy by={post.postedBy}></LegacyPostBy>
  );
};

/**
 * helper component for rendering tooltip insides
 */
const TooltipContents = ({
  user,
  post: { createdAt, createdBy, updatedAt, updatedBy },
}: PostAndUserProps) => {
  const Row = (by: string, at: string, edited = false) => {
    const formatedTime = moment
      // @ts-ignore
      .tz(at, user.timezone)
      .format("hh:mm a ddd DD MMM");
    const modifier = getUserById(user, by);
    const modifierName = modifier
      ? `${modifier?.firstName} ${modifier?.lastName}`
      : "Unknown user";
    const hasName = modifierName.trim().length > 0;
    return (
      <TooltipRowWrapper>
        <Initials className="tw-border-gray-500 tw-p-2 tw-text-gray-400 tw-text-lg">
          {getInitial(user, by)}
        </Initials>
        <TooltipRowText>
          {edited ? "Last edited" : "Created"} {formatedTime} by{" "}
          {hasName ? modifierName : "Unknown/deleted collaborator"}
        </TooltipRowText>
      </TooltipRowWrapper>
    );
  };
  return (
    <>
      {updatedBy && Row(updatedBy, updatedAt!, true)}
      {Row(createdBy!, createdAt!)}
    </>
  );
};

/**
 * styled divs
 */
const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
  font-weight: 500;
  width: 20px;
  height: 20px;
  cursor: default;
`;

const TooltipRowText = styled.div`
  line-height: 14px;
`;

const TooltipRowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  margin: 0 14px;
  :not(:first-child) {
    border-top: 1px solid #242424;
    ${TooltipRowText} {
      color: #a0a0a0;
    }
    ${Initials} {
      color: #a0a0a0 !important;
      border-color: #a0a0a0 !important;
    }
  }
  ${Initials} {
    margin-right: 6px;
  }
`;

// helper types
type PostProp = {
  post: Post;
};

type PostAndUserProps = {
  post: Post;
  user: User;
};

// helper methods
const getUserById = (user: User, id: string) => {
  // in some cases (when the user is a collaborator user?)
  // user.collaborators is undefined - in this case return null
  // and we will display as 'unknown user'
  return user.userId === id
    ? user
    : user.collaborators &&
      Array.isArray(user.collaborators) &&
      user.collaborators.length > 0
    ? user.collaborators.find((c) => c._id === id)
    : null;
};

const getInitial = (user: User, id: string) => {
  const foundUser = getUserById(user, id);
  if (!foundUser) return "U"; // U as Unknown
  return foundUser.firstName ? foundUser.firstName[0] : "";
};

/**
 * left for legacy posts that only have postedBy prop
 */
type LegacyPostByProps = { by?: string; tooltip?: string };
const LegacyPostBy = ({ by, tooltip }: LegacyPostByProps) => {
  if (!by) {
    return null;
  }

  const parts = by.split(" ");
  if (parts.length === 0) {
    return null;
  }

  const first_char = parts[0].charAt(0);
  const second_char = parts[1] ? parts[1].charAt(0) : null;

  let initials = first_char;
  if (second_char) {
    initials = `${first_char}`;
  }

  if (by === "Imported from IG") {
    initials = "IG";
  }

  return (
    <TooltipWrapper placement="top" tooltip={tooltip || by}>
      <Initials className="tw-border-gray-500 tw-p-2 tw-text-gray-400 tw-text-lg">
        {initials}
      </Initials>
    </TooltipWrapper>
  );
};

export default PostBy;
