import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import { LoadingSpinner } from "ui";
import {
  HashtagRecommendation,
  markTagPositive,
  markTagNegative,
  getRecommendedTags,
} from "api/post";
import { TagRecommendation } from "./tag-recommendation";
import { TagsList } from "../tags-list";

import InfoIcon from "../../icons/info.svg";
import { useTrackEvent } from "libs/analytics";
import {
  HASHTAG_RECOMMENDATION_OPENED,
  HASHTAG_RECOMMENDATION_SELECTED,
} from "constants/Events";

interface HashtagRecommendProps {
  className?: string;
  hashtags: string[];
  loadingText?: string;
  itemClick(value: HashtagRecommendation): void;
  onMouseEnter(): void;
  onMouseLeave(): void;
}

function HashtagRecommendComponent(props: HashtagRecommendProps) {
  const trackEvent = useTrackEvent();
  const { hashtags, itemClick } = props;
  const [recommendations, setRecommendations] = useState<
    HashtagRecommendation[]
  >([]);
  const [disliked, setDisliked] = useState<string[]>([]);
  const [loading, setLoading] = useState(hashtags.length > 0);

  const updateRecommendations = useDebouncedCallback(
    async function updateRecommendations(
      request: () => Promise<HashtagRecommendation[]>
    ) {
      try {
        setLoading(true);
        setRecommendations(await request());
        setLoading(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          setLoading(false);
          console.error("Could not get hashtag recommentations", e);
        } else {
          console.error("axios.cancel", e.message);
        }
      }
    },
    500
  );

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    updateRecommendations(() =>
      getRecommendedTags(
        { hashtags: hashtags.map((tag: string) => tag.replace(/^#+/, "")) },
        cancelSource.token
      )
    );
    return () => {
      cancelSource.cancel("request was cancelled");
      updateRecommendations.cancel();
    };
  }, [updateRecommendations, JSON.stringify(hashtags)]);

  useEffect(() => {
    trackEvent({ eventName: HASHTAG_RECOMMENDATION_OPENED });
  }, []);

  function handleClick(rec: HashtagRecommendation) {
    trackEvent({ eventName: HASHTAG_RECOMMENDATION_SELECTED });
    markTagPositive({
      hashtags: hashtags.map((tag: string) => tag.replace(/^#+/, "")),
      relevantHashtag: rec.hashtag,
    });
    itemClick(rec);
  }

  function handleDislike(rec: HashtagRecommendation) {
    setDisliked((lastDisliked) => [...lastDisliked, `#${rec.hashtag}`]);
    updateRecommendations(() =>
      markTagNegative({
        hashtags: hashtags.map((tag: string) => tag.replace(/^#+/, "")),
        irrelevantHashtag: rec.hashtag,
      })
    );
  }

  const isLoading = loading && recommendations.length === 0;
  const isEmpty = !loading && recommendations.length === 0;
  const filteredRecommendations = recommendations
    .filter((rec) => !props.hashtags.includes(`#${rec.hashtag}`))
    .filter((rec) => !disliked.includes(`#${rec.hashtag}`));

  return (
    <Recommended
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={props.className}
    >
      <RecommendedTitle>
        Recommended Hashtags:
        <InfoWrapper>
          <Tooltip>
            Recommeded based on previously used and popular hashtags
          </Tooltip>
          <Info />
        </InfoWrapper>
      </RecommendedTitle>
      {isLoading && (
        <LoadingContainer>
          {props.loadingText}
          <LoadingSpinnerStyled isSmall={true} />
        </LoadingContainer>
      )}
      {isEmpty && (
        <EmptyRecommendation>No Recommendations Found.</EmptyRecommendation>
      )}
      {!isEmpty && !isLoading && (
        <>
          <SuggestionsPopup>
            <TagsList
              onSelect={handleClick}
              listItems={filteredRecommendations}
              renderListItem={(rec: HashtagRecommendation) => (
                <TagRecommendation
                  rec={rec}
                  onDislike={() => handleDislike(rec)}
                />
              )}
            />
          </SuggestionsPopup>
          <SpinnerWrapper>
            {loading && <LoadingSpinnerStyled isSmall={true} />}
          </SpinnerWrapper>
        </>
      )}
    </Recommended>
  );
}

export const HashtagRecommend = styled(HashtagRecommendComponent)``;

const LoadingSpinnerStyled = styled(LoadingSpinner)``;

const Recommended = styled.div`
  background: white;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  position: absolute;
  width: 220px;
  height: 135px;
  top: 100%;
  right: 0;
`;

const RecommendedTitle = styled.div`
  padding: 13px 16px 10px;
  font-size: 12px;
  line-height: 14px;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  font-weight: 500;
`;

const Tooltip = styled.div`
  position: absolute;
  left: 57px;
  top: -54px;
  width: 160px;
  padding: 8px;
  border-radius: 5px;
  background: #333333;
  color: white;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  &:before {
    content: "";
    position: absolute;
    right: 33px;
    bottom: -4px;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    background: #333333;
  }
`;

const InfoWrapper = styled.div`
  display: inline;
  margin-left: 10px;
  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const Info = styled(InfoIcon)`
  position: relative;
`;

const SuggestionsPopup = styled.div`
  position: relative;
  height: 96px;
  overflow: hidden;
`;

const EmptyRecommendation = styled.div`
  padding: 3px 10px 10px 10px;
  color: #960000;
  font-size: 10px;
`;

const LoadingContainer = styled.div`
  position: relative;
  height: 100%;
  width: 50px;
  margin: 0 auto;
  pointer-events: none;
`;

const SpinnerWrapper = styled(LoadingContainer)`
  bottom: 40px;
  height: 50px;
`;
