import React, { useLayoutEffect, useState, RefObject } from "react";
import styled from "styled-components";

// TODO: check why we need this at all?
export const PlatformFieldsOverlay = ({
  fieldsRef,
}: {
  fieldsRef: RefObject<HTMLDivElement>;
}): JSX.Element => {
  const { current: currentElement } = fieldsRef || {};
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const updateSizes = () => {
    if (
      currentElement &&
      currentElement.clientHeight &&
      currentElement.clientWidth
    ) {
      setHeight(currentElement.clientHeight + 20);
      setWidth(currentElement.clientWidth + 20);
    }
  };

  useLayoutEffect(() => {
    updateSizes();
    window.addEventListener("resize", updateSizes);
    return () => window.removeEventListener("resize", updateSizes);
  }, [fieldsRef]);

  if (!currentElement) {
    return <></>;
  }

  return (
    <Container height={height + 10} width={width}>
      &nbsp;
    </Container>
  );
};

const Container = styled.div<{ height: number; width: number }>`
  position: absolute;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  left: -10px;
  right: -10px;
  top: -10px;
  z-index: 1000;
  opacity: 0.5;
  background: rgb(220 220 220);
`;
