import React, { useState } from "react";
import { SketchPicker } from "react-color";

import styled from "styled-components";

export const SketchPickerWithPresets = styled(function SketchPickerWithPresets({
  value,
  onChange,
  className,
  width = 200,
}: {
  value: string;
  onChange(newColor: string): void;
  className?: string;
  width?: number;
}) {
  const [selectedColor, setSelectedColor] = useState(value);
  const presetColors = [
    "#FA4F38", // red
    "#68D391", // green
    "#F6E05E", // yellow
    "#ED8936", // orange
    "#63B3ED", // blue
    "#667EEA", // indigo
    "#4F31FF", // purple
    "#0E1680", // dark blue
    //second row
    "#FC8181", // light red
    "#C6F6D5", // light green
    "#FEFCBF", // light yellow
    "#FEEBC8", // light orange
    "#BEE3F8", // light blue
    "#C3DAFE", // light indigo
    "#c0ccff", // light purple
    "#4A5568", // gray
  ];

  return (
    <SketchPicker
      className={className}
      disableAlpha={true}
      onChange={(color) => setSelectedColor(color.hex)}
      onChangeComplete={(color) => onChange(color.hex)}
      color={selectedColor}
      presetColors={presetColors}
      width={String(width)}
    />
  );
})``;
