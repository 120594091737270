import React from "react";
import "./ErrorBoundary.less";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  // You can also log the error to an error reporting service
  // logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className={`tw-w-full ${
            this.props.className ? this.props.className : ""
          }`}
        >
          {!this.props.errorMessage && (
            <>
              <h1>Something went wrong. Please refresh and try again.</h1>
              <div>If the error persists please reach out to support.</div>
            </>
          )}
          {this.props.errorMessage && <>{this.props.errorMessage}</>}
        </div>
      );
    }

    return this.props.children;
  }
}
