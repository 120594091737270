import { useState } from "react";
import { ServerMessageInfoProps } from "./types";

// Note: not ideal for detecting map urls, but currently FB treats
//       all urls as fallbac attachments
const mapServices = [
  "https://goo.gl/maps",
  "https://www.google.com/maps",
  "https://www.bing.com/maps",
];
const isMapUrl = (url: string | JSX.Element) => {
  if (typeof url !== "string") {
    return false;
  }

  return mapServices.some((service) => url.includes(service));
};

type Props = {
  message: string | JSX.Element;
} & Pick<ServerMessageInfoProps, "story" | "attachments">;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCommonMessageState = ({
  message,
  story,
  attachments = [],
}: Props) => {
  const [isReady, setReady] = useState(false);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);
  const onError = () => setIsInvalidUrl(true);
  const setIsReady = () => setReady(true);
  const isMediaURL = !!story?.url || attachments.length > 0;
  const isMessageMapUrl = isMapUrl(message);

  return {
    isInvalidUrl,
    isReady,
    onError,
    setIsReady,
    isMediaURL,
    isMessageMapUrl,
  };
};
