import { ContentLabel } from "@workshop/types";
import { skedApi } from "./_skedApi";

export async function getContentLabels(
  groupId: string
): Promise<{ data: ContentLabel[] }> {
  return await skedApi.get("/labels", { params: { groupId } });
}
export async function getAllContentLabels(): Promise<{ data: ContentLabel[] }> {
  return await skedApi.get("/labels");
}

export async function createContentLabel(
  label: ContentLabel
): Promise<{ data: ContentLabel }> {
  return await skedApi.post("/labels", label);
}

export async function updateContentLabel(
  labelId: string,
  label: Partial<ContentLabel>
): Promise<{ data: ContentLabel }> {
  return await skedApi.put(`/labels/${labelId}`, label);
}

export async function removeContentLabel(
  labelId: string
): Promise<{ data: ContentLabel }> {
  return await skedApi.delete(`/labels/${labelId}`);
}
