import {
  FeatureName,
  localStorageFeatureToggle,
} from "utils/feature-toggle-utils";
import { SkedPlanCodeName } from "../types";

export const possibleSkedPlanCodeNames: SkedPlanCodeName[] = [
  "sked-fundamentals",
  "sked-essentials",
  "sked-professional",
  "sked-enterprise",
];

export const planBaseMaxAccounts = {
  "sked-fundamentals": 1,
  "sked-essentials": 3,
  "sked-professional": 6,
  "sked-enterprise": 10,
} as const;

export const fundamentalsFeatures = [
  "Unlimited posts",
  "1 account",
  "1 user",
  "Visual Instagram planner",
  "Basic 30 day analytics",
  "AI caption writing",
];

export const essentialsFeatures = [
  "Unlimited users",
  "Team collaboration",
  "Product tagging",
  "AI brand summary",
  "Social inbox",
  "Onboarding support",
];
export const professionalFeatures = localStorageFeatureToggle.get(
  FeatureName.ReportBuilder
)
  ? [
      "Content approvals portal",
      "Unlimited shared calendars",
      "Content pillar and campaign tagging",
      "Competitor tracking and insights",
      "PDF and Excel reports",
      "Customized reporting periods",
    ]
  : [
      "Content approvals portal",
      "Unlimited shared calendars",
      "Content pillar and campaign tagging",
      "Competitor tracking and insights",
      "PDF and Excel reports",
      "Customized reporting periods",
    ];

export const enterpriseFeatures = localStorageFeatureToggle.get(
  FeatureName.ReportBuilder
)
  ? [
      "Content pillar and campaign insights",
      "Dedicated customer success manager",
      "Year-on-year or custom comparison reports",
      "Ads analytics",
      "Report Builder",
      "Early access to beta features",
    ]
  : [
      "Content pillar and campaign insights",
      "Dedicated customer success manager",
      "Year-on-year or custom comparison reports",
      "Report builder [coming soon]",
      "Early access to beta features",
      "Ads reporting",
    ];
