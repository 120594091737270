import React from "react";
import styled from "styled-components";

import { Button, FormSize } from "ui/ecosystems/forms";
import { useUserCurrency } from "entities/current-user";

import { Title, Spacing, List, Price, PriceSecondary } from "./atoms";
import {
  formatPriceLong,
  getTotalFromIntention,
} from "../../libs/plan-price-helpers";
import { PlanChangeIntention } from "../../PlanChangeIntention.type";

export function UpgradeToEssentials({
  className,
  intention,
  onUpgrade,
}: {
  className?: string;
  intention?: PlanChangeIntention;
  onUpgrade(): void;
}) {
  const user_currency_code = useUserCurrency();

  return (
    <Root className={className}>
      <div>
        <Title>Upgrade to Essentials</Title>
        <Spacing />
        <List>
          <li>Library (including UGC tools and storage)</li>
          <li>IG competitor reports</li>
          <li>2 years of historical insights data (currently 90 days)</li>
          <li>Unlimited users</li>
          <li>Additional social accounts</li>
          <li>Product tagging</li>
        </List>
      </div>
      <div css="display: flex; flex-direction: column; align-items: center;">
        <Spacing />
        <Price>
          {intention
            ? formatPriceLong(
                getTotalFromIntention(intention, user_currency_code),
                {
                  skipDecimals: true,
                }
              )
            : "$-- USD"}
          /{intention?.isAnnual ? "year" : "mo"}
        </Price>
        <PriceSecondary>&nbsp;</PriceSecondary>
        <Spacing />
        <Button
          size={FormSize.large}
          primary
          disabled={!intention}
          onClick={onUpgrade}
        >
          Upgrade to Essentials
        </Button>
      </div>
    </Root>
  );
}

const Root = styled.div`
  padding: 20px 25px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;
