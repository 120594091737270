type UserWithPlan = { plan?: string; ugcLibraryAddon?: boolean };

export function isFullLibrary(user?: UserWithPlan) {
  if (!user || !user.plan) {
    return false;
  }

  if (user.ugcLibraryAddon) {
    return true;
  }

  return [
    "essentials",
    "marketer",
    "professional",
    "agency",
    "enterprise",
    "custom",
  ].some((plan) => user.plan?.includes(plan));
}

export function isLibraryRestricted(user: UserWithPlan) {
  return !isFullLibrary(user);
}
