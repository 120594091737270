import React from "react";
import styled from "styled-components";
import { components } from "react-select";

interface LabelWrapperProps {
  selectedOptionsNumber?: number;
}

interface PostFilterValueTypes {
  valueContainerProps?: any;
  selectedOptions: any[];
  placeholder: string;
  selectedPlaceholder: string;
  popoverOption?: (option: any) => void;
}

export const PostFilterValueContainer = (
  props: PostFilterValueTypes
): JSX.Element => {
  const {
    valueContainerProps,
    selectedOptions,
    placeholder,
    selectedPlaceholder,
    popoverOption: customPopoverOption,
  } = props;

  const selectedOptionsNumber = selectedOptions?.length;
  const hasValue = Boolean(selectedOptionsNumber);

  const popoverOption = (option: { label: string }) => {
    if (customPopoverOption) {
      return customPopoverOption(option);
    }
    return (
      <MultiOptionLabel key={option.label}>{option.label}</MultiOptionLabel>
    );
  };

  return (
    <ValueContainerStyled {...valueContainerProps}>
      {hasValue && (
        <LabelWrapper selectedOptionsNumber={selectedOptionsNumber}>
          {selectedPlaceholder}
          <PopoverWrapper className="value-popover">
            {selectedOptions.map((option: any) => popoverOption(option))}
          </PopoverWrapper>
        </LabelWrapper>
      )}
      {!hasValue && <LabelWrapper>{placeholder}</LabelWrapper>}

      {valueContainerProps.children?.length &&
        valueContainerProps.children[valueContainerProps.children.length - 1]}
    </ValueContainerStyled>
  );
};

const ValueContainerStyled = styled(components.ValueContainer)`
  overflow: visible !important;
`;

const PopoverWrapper = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -50%;
  width: max-content;
  min-width: 70%;
  max-width: 240px;
  padding: 10px;
  background-color: #454545;
  border-radius: 3px;
  z-index: 1;

  &:before {
    display: block;
    content: "";
    background-color: inherit;
    position: absolute;
    top: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, 0) rotate(45deg);
  }
`;

const LabelWrapper = styled.div<LabelWrapperProps>`
  position: relative;
  margin-left: 10px;
  color: ${({ selectedOptionsNumber }) =>
    selectedOptionsNumber ? "ingerit" : "#666666"};
  &:hover > div {
    display: block;
  }
`;

const MultiOptionLabel = styled.div`
  height: 27px;
  line-height: 27px;
  color: #ffffff;
`;
