import * as React from "react";
import styled from "styled-components";
import { Collapse as ReactCollapse, UnmountClosed } from "react-collapse";

export function Collapse(props: {
  isOpened: boolean;
  children: React.ReactNode;
  unmountClosed?: boolean;
  className?: string;
}) {
  const CollapseComponent = props.unmountClosed ? UnmountClosed : ReactCollapse;

  return (
    <CollapseRoot className={props.className}>
      <CollapseComponent isOpened={props.isOpened} checkTimeout={200}>
        {props.children}
      </CollapseComponent>
    </CollapseRoot>
  );
}

const CollapseRoot = styled.div`
  .ReactCollapse--collapse {
    transition: height 300ms;
  }
`;
