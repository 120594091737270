import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import SkedIcon from "ui/SkedIcon/SkedIcon";
import { POST_TYPES } from "constants/PostsView";
import { PAGES } from "constants/Pages";
import { FIELDS } from "constants/PostFormFields";

import NoMediaIcon from "assets/icons/no-media.svg";

import "./PostMedia.less";

function PostMedia({ post, page, accounts }) {
  let isInstagram = false;
  if (accounts && accounts.length > 0) {
    isInstagram = accounts.some((account) => {
      return account.platformType === "IG" || !account.platformType;
    });
  }
  const [mediaIndex, setMediaIndex] = useState(0);
  if (post.type === POST_TYPES.TEXT) {
    return (
      <div className="PostMedia">
        <div className="PostMedia-TextOnly">
          <span className="PostMedia-TextOnlySvg">
            <NoMediaIcon />
          </span>
          <span className="PostMedia-TextOnlyText">Text Post</span>
        </div>
        {page === PAGES.UPCOMING && <PostStatusTag post={post} />}
      </div>
    );
  }

  const media = getMedia(post);

  if (!media) {
    return null;
  }

  const mediaObj = media[mediaIndex];
  if (!mediaObj) return null;
  // const mediaSrc = mediaObj.thumbnailUrl || mediaObj.url;
  return (
    <div className="PostMedia">
      <MediaMiddle
        post={post}
        mediaObj={mediaObj}
        mediaLength={media.length}
        mediaIndex={mediaIndex}
        setMediaIndex={setMediaIndex}
        useThumbnail={true}
        isInstagram={isInstagram}
        page={page}
      />
      <MediaBottom
        post={post}
        mediaLength={media.length}
        mediaIndex={mediaIndex}
        setMediaIndex={setMediaIndex}
      />
    </div>
  );
}

PostMedia.propTypes = {
  post: PropTypes.object,
  page: PropTypes.string,
};

function MediaMiddle(props) {
  const {
    post,
    isInstagram,
    mediaObj,
    mediaLength,
    mediaIndex,
    setMediaIndex,
    useThumbnail,
    page,
  } = props;
  return (
    <div className="PostMedia-Middle">
      {(useThumbnail || mediaObj.isVideo) && (
        <a href={mediaObj.url} target="_blank" rel="noopener noreferrer">
          <img src={mediaObj.thumbnailUrl} alt="Thumbnail of post" />
        </a>
      )}
      {!useThumbnail && !mediaObj.isVideo && (
        <img
          src={`${mediaObj.url}?nocache=true`}
          alt="Image of post"
          crossOrigin="anonymous"
        />
      )}
      {mediaObj.isVideo && (
        <a href={mediaObj.url} target="_blank" rel="noopener noreferrer">
          <PostTypeIcon post={post} isInstagram={isInstagram} />
        </a>
      )}
      {!mediaObj.isVideo && <PostTypeIcon post={post} />}
      {page === PAGES.UPCOMING && <PostStatusTag post={post} />}
      {mediaLength > 1 && (
        <button
          className="PostMedia-CarouselButton PostMedia-CarouselButton_left"
          type="button"
          onClick={() => setMediaIndex(mediaIndex - 1)}
          disabled={mediaIndex === 0}
        >
          <div className="PostMedia-CarouselOverlay" />
          <SkedIcon
            className="PostMedia-Chevron"
            icon="chevronLeft"
            size="24"
            color="white"
          />
        </button>
      )}

      {mediaLength > 1 && (
        <button
          className="PostMedia-CarouselButton PostMedia-CarouselButton_right"
          type="button"
          onClick={() => setMediaIndex(mediaIndex + 1)}
          disabled={mediaIndex === mediaLength - 1}
        >
          <div className="PostMedia-CarouselOverlay" />
          <SkedIcon
            className="PostMedia-Chevron"
            icon="chevronRight"
            size="24"
            color="white"
          />
        </button>
      )}
    </div>
  );
}

function MediaBottom({ post, mediaLength, mediaIndex, setMediaIndex }) {
  return (
    <div className="PostMedia-Bottom">
      {mediaLength > 1 && (
        <>
          <div className="PostMedia-Dots">
            {_.times(mediaLength, (index) => {
              return (
                <span key={`dots-${post._id}-${index}`}>
                  <a onClick={() => setMediaIndex(index)}>
                    {index === mediaIndex ? "●" : "○"}
                  </a>
                  {(index + 1) % 10 === 0 ? <br /> : ""}
                </span>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

function PostTypeIcon({ post, isInstagram }) {
  let iconName = null;
  let tooltip = "";
  let size = "20";

  switch (post.type) {
    case POST_TYPES.MULTI_MEDIA:
      iconName = "icon-post-carousel";
      tooltip = "Carousel";
      break;
    case POST_TYPES.VIDEO:
      // now show reel not video icon because no more videos
      iconName = isInstagram ? "icon-post-reel" : "icon-post-video";
      tooltip = isInstagram ? "Video/Reel" : "Video";
      break;
    case POST_TYPES.DOCUMENT:
      iconName = "icon-post-document";
      tooltip = "Document/PDF";
      break;
    case POST_TYPES.STORY:
      if (post[FIELDS.STORY_TYPE]) {
        switch (post[FIELDS.STORY_TYPE]) {
          case "igtv":
            iconName = "icon-post-igtv";
            tooltip = "IGTV";
            break;
          case "reel":
            iconName = "icon-post-reel";
            tooltip = "Video/Reel";
            break;
          case "story":
          default:
            iconName = "igStory";
            tooltip = "Story";
            // Giselle wants story larger
            size = "30";
            break;
        }
      } else {
        iconName = "icon-post-story";
        tooltip = "Story";
        // Giselle wants story larger
        size = "30";
      }
      break;
    default:
      return "";
  }
  return (
    <SkedIcon
      icon={iconName}
      tooltip={tooltip}
      placement="top"
      className="PostMedia-MediaTypeIcon icon-with-drop-shadow"
      size={size}
    />
  );
}

function PostStatusTag({ post }) {
  const queued = post.queued === true;

  return (
    <div className="PostMedia-PostStatusTag">
      <div className="PostMedia-MediaStatus">
        {!queued && (
          <div className="PostMedia-MediaScheduled">
            <span className="PostMedia-StatusFirstLetter">S</span>
            <span className="PostMedia-StatusFullText">Scheduled</span>
          </div>
        )}
        {queued && (
          <div className="PostMedia-MediaQueued">
            <span className="PostMedia-StatusFirstLetter">Q</span>
            <span className="PostMedia-StatusFullText">Queued</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function getMedia(post) {
  const { originalUrl, thumbnailUrl, url, altText, _id } = post;
  const filesCount = post.mediaFiles && post.mediaFiles.length;
  const newAltText =
    (filesCount === 1 && post.mediaFiles[0]?.altText) || altText;

  switch (post.type) {
    case POST_TYPES.MULTI_MEDIA:
    case POST_TYPES.STORY:
      return filesCount
        ? post.mediaFiles
        : [
            {
              _id,
              altText: newAltText,
              originalUrl,
              thumbnailUrl,
              url,
              isVideo: post.isVideo,
              meta: post.meta || {},
            },
          ];
    case POST_TYPES.PHOTO:
    case POST_TYPES.IMAGE:
    case POST_TYPES.VIDEO:
    case POST_TYPES.DOCUMENT:
      return [
        {
          _id,
          altText: newAltText,
          originalUrl,
          thumbnailUrl,
          url,
          isVideo: post.isVideo,
          meta: post.meta || {},
        },
      ];
    default:
      console.error(`Unhandled post type ${post.type}`);
  }
}

export default PostMedia;
