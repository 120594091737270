import React from "react";
import styled from "styled-components";

export function InlineFormLineComponent(props: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <Label>{props.label}</Label>
      <Field>{props.children}</Field>
    </>
  );
}

export const InlineFormFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

export const InlineFormLine = styled(InlineFormLineComponent)``;

const Label = styled.div`
  font-weight: 500;
  color: #707070;
  font-size: 12px;
`;

const Field = styled.div``;

const InlineFormLineRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
