import React from "react";
import Select from "react-select";
import { SkedIcon } from "ui";
import { useDispatch } from "react-redux";
import { showConfirmModal } from "actions/modal";
import {
  reloadPosts,
  removePost,
  sendPostToDraft,
  sendPostToQueue,
} from "actions/postsView";
import { PAGES } from "constants/Pages";
import ngDeps from "ng-react-directives/ngr-injector";
import { getFormattedPostDate, getPageEntity, getPageVerbs } from "utils/posts";
import { POST_EVENTS } from "constants/PostEvents";
import { openEditPostModal } from "features/post";

const PostActionsDropdown = ({ user, page, post, accounts }) => {
  const dispatch = useDispatch();
  const pageEntity = getPageEntity(page);

  const styles = {
    control: (base, state) => {
      return {
        ...base,
        minHeight: "40px",
        minWidth: "200px",
        width: `${8 * state.getValue()[0].value.length + 50}px`,
        display: "flex",
        alignItems: "center",
        borderColor: "#eee",
      };
    },
    option: (base, { isFocused }) => ({
      ...base,
      cursor: "pointer",
      backgroundColor: isFocused ? "#F6F5FF" : "transparent",
    }),
    singleValue: (base) => ({
      ...base,
      minHeight: "40px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: "500",
    }),
    valueContainer: (base) => ({
      ...base,
      minHeight: "30px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      margin: 0,
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      borderColor: "pink",
    }),
  };

  const showEditPost = () => {
    dispatch(openEditPostModal({ post }));
  };

  //showSendToDraft
  const showSendToDraft = (post) => {
    const { eventService } = ngDeps;
    eventService.trackEvent({ eventName: POST_EVENTS.DROPDOWN_DRAFT });
    dispatch(
      showConfirmModal({
        title: "Move post to draft?",
        message: `Are you sure you want to move this post to drafts?`,
        confirmType: "primary",
        confirmText: "Move to Draft",
        handleConfirm: () => dispatch(sendPostToDraft(post)),
      })
    );
  };

  //showSendToQueue
  const showSendToQueue = (post) => {
    const { eventService } = ngDeps;
    eventService.trackEvent({ eventName: POST_EVENTS.DROPDOWN_QUEUE });
    dispatch(
      showConfirmModal({
        title: `Send ${pageEntity} to queue`,
        message: `Are you sure you want to send this ${pageEntity} to queue?`,
        confirmType: "primary",
        confirmText: "Send to Queue",
        handleConfirm: () => dispatch(sendPostToQueue(post)),
      })
    );
  };

  //showRemoveFromQueue
  const showRemovePostFromQueueModal = (post) => {
    const { eventService } = ngDeps;
    eventService.trackEvent({ eventName: POST_EVENTS.DROPDOWN_DELETE_QUEUE });
    const ctaText = "Remove Post";
    const title = "Remove post From posting queue?";
    const messageBody = (
      <div>
        <p>
          Are you sure you want to remove this post? Removing a post deletes it
          from our database, which means you will not see it in your archive
          anymore – this is how we try and stop the post from posting.
        </p>
        <p>
          This action is permanent, and cannot be undone. If you need to keep a
          copy of the post, please make a copy first.
        </p>
        <p>
          Note that if you remove posts we will be unable to see any past
          history of this post either. If when you click the remove button, the
          post is already posting, it may still post - however if it is queued
          to be posted (waiting for a device to become available), it will not
          post.
        </p>
        <p>
          We recommend checking your Instagram profile a few minutes after
          removing the post to make sure.
        </p>
      </div>
    );

    dispatch(
      showConfirmModal({
        title: title,
        message: messageBody,
        confirmType: "danger",
        confirmText: ctaText,
        handleConfirm: () => dispatch(removePost(post)),
      })
    );
  };

  //showRemovePost
  const showRemovePostModal = (post) => {
    const { eventService } = ngDeps;
    eventService.trackEvent({ eventName: POST_EVENTS.DROPDOWN_DELETE });
    let title = "Remove post?";
    let messageBody = `Are you sure you want to remove this ${pageEntity}?`;
    let ctaText = "Remove Post";
    switch (page) {
      case PAGES.DRAFT:
        title = "Remove draft?";
        ctaText = "Remove Draft";
        break;
      case PAGES.ARCHIVE:
        title = "Permanently remove post?";
        messageBody = (
          <div>
            <div className="tw-mb-4">
              Are you sure you want to remove this post? This will not remove
              the post from your profile, removing will delete it from only your
              Sked archive.
            </div>
            <div>
              This action is permanent, and cannot be undone. If you need to
              keep a copy of the post, please make a copy first.
            </div>
          </div>
        );
        break;
    }

    dispatch(
      showConfirmModal({
        title: title,
        message: messageBody,
        confirmType: "alert_outline",
        cancelType: "primary_outline",
        confirmText: ctaText,
        handleConfirm: () => dispatch(removePost(post)),
      })
    );
  };

  const { $rootScope } = ngDeps;
  const pageVerbs = getPageVerbs(page, post, user, $rootScope);
  const options = [];

  if (
    page === PAGES.DRAFT ||
    (page === PAGES.UPCOMING && post.queued === true)
  ) {
    options.push({
      value: "Schedule",
      inputValue: "Schedule",
      isSearchable: false,
      label: (
        <div className="tw-flex tw-text-purple-500">
          <SkedIcon icon="icon-pencil" color="purple" />
          <div className="tw-ml-4">Schedule</div>
        </div>
      ),
      onChange: () => showEditPost(post),
    });
  }

  if (pageVerbs.draftable) {
    options.push({
      value: "Draft",
      inputValue: "Draft",
      isSearchable: false,
      label: (
        <div className="tw-flex tw-text-purple-400">
          <SkedIcon icon="icon-send-draft" />
          <div className="tw-ml-4">Send To Draft</div>
        </div>
      ),
      onChange: () => showSendToDraft(post),
    });
  }

  if (pageVerbs.queueable && !(post.queued === true)) {
    options.push({
      value: "Queue",
      inputValue: "Queue",
      isSearchable: false,
      label: (
        <div className="tw-flex tw-text-purple-400">
          <SkedIcon icon="icon-queue" />
          <div className="tw-ml-4">Send To Queue</div>
        </div>
      ),
      onChange: () => showSendToQueue(post),
    });
  }

  if (pageVerbs.removeable) {
    options.push({
      value: "Delete",
      inputValue: "Delete",
      isSearchable: false,
      label: (
        <div className="tw-flex tw-text-red-500">
          <SkedIcon icon="trash" color="red" />
          <div className="tw-ml-4">Delete</div>
        </div>
      ),
      onChange: () => showRemovePostModal(post),
    });
  }

  if (pageVerbs.removeableFromQueue) {
    options.push({
      value: "Delete",
      inputValue: "Delete",
      isSearchable: false,
      label: (
        <div className="tw-flex tw-text-red-500">
          <SkedIcon icon="trash" color="red" />
          <div className="tw-ml-4">Remove from queue</div>
        </div>
      ),
      onChange: () => showRemovePostFromQueueModal(post),
    });
  }

  let displayValue = "";
  const when = getFormattedPostDate(post, page, user);
  switch (page) {
    case PAGES.UPCOMING:
      // const dateToUse = post.queued ? post.when : post.scheduledFor;
      displayValue = post.queued ? "Queued for " : "Scheduled for ";
      displayValue += when;
      break;
    case PAGES.DRAFT:
      displayValue = "In Draft";
      break;
    case PAGES.ARCHIVE:
      const userDisplayIsObj = _.isEqual({}, post.userDisplayReason);
      const failReasonIsObj = _.isEqual({}, post.failReason);
      const userDisplayReason = userDisplayIsObj ? "" : post.userDisplayReason;
      const failReason = failReasonIsObj ? "" : post.failReason;
      if (post.isCanceled) {
        displayValue = "Cancelled at ";
      } else if (post.isQueued) {
        displayValue = "Queued at ";
      } else if (post.isPosting) {
        displayValue = "Sent at ";
      } else if (userDisplayReason || failReason || post.failedAt) {
        displayValue = "Failed at ";
      } else {
        // success
        displayValue = "Posted at ";
      }
      displayValue += when;
      break;
  }

  const defaultValue = {
    value: displayValue,
    label: displayValue,
  };

  return (
    <div data-testid="postActionsDropdown">
      <Select
        autosize={true}
        defaultValue={defaultValue}
        value={defaultValue}
        closeMenuOnSelect={true}
        isMulti={false}
        isSearchable={false}
        options={options}
        styles={styles}
        onChange={(item) => {
          item.onChange(post);
        }}
      />
    </div>
  );
};

export default PostActionsDropdown;
