import { UserFriendlyError } from "api/UserFriendlyError";

const MAX_FB_PAGE_ITERATION = 15;

export interface FBAccount {
  platformUserId: string;
  first_name?: string;
  last_name?: string;
}

export interface FBPage {
  id: string;
  name: string;
  access_token: string;
  instagram_business_account?: { id: string };
}

export interface FBPageExtended extends FBPage {
  name_with_location_descriptor: string;
  category: unknown;
  category_list: unknown;
  tasks: unknown;
}

export interface InstaAccount {
  id: string;
  ig_id: string;
  username: string;
  name: string;
  followers_count: number;
  profile_picture_url: string;
  website?: string;
}

export interface FBPageWithInsta {
  page: FBPage;
  ig?: InstaAccount;
}

export interface FBGroup {
  id: string;
  name: string;
}

function isFBSdkOk(): boolean {
  return (window as any).FB && (window as any).FB.login;
}

export async function fbLogin(scopes: string[]): Promise<fb.AuthResponse> {
  console.log("Requesting scopes: " + scopes);
  return new Promise((resolve, reject) => {
    if (!isFBSdkOk()) {
      return reject(
        new UserFriendlyError(
          "It looks like the Facebook pixel is not loaded, so we can't connect to Instagram Insights. Please check if you have an ad blocker enabled."
        )
      );
    }
    window.FB.login(
      (response) => {
        if (!response.authResponse) {
          console.log("User cancelled login or did not fully authorize.");
          reject(
            new UserFriendlyError(
              "No Instagram or Facebook account added: you cancelled the login or did not fully authorise our access."
            )
          );
        } else {
          resolve(response.authResponse);
        }
      },
      {
        scope: scopes.join(", "),
        return_scopes: true,
        auth_type: "rerequest",
      }
    );
  });
}

export async function fbReadMe(auth: fb.AuthResponse) {
  return new Promise<FBAccount>((resolve, reject) => {
    if (!isFBSdkOk()) {
      return reject(
        new UserFriendlyError(
          "It looks like the Facebook pixel is not loaded, so we can't connect to Instagram Insights. Please check if you have an ad blocker enabled."
        )
      );
    }
    window.FB.api("/me", { accessToken: auth.accessToken }, (response: any) => {
      if (!response || response.error) {
        reject(response?.error);
      }
      const account: FBAccount = {
        platformUserId: response.id,
      };
      if (response.name) {
        var [first_name, last_name] = response.name.split(" ");
        account.first_name = first_name;
        account.last_name = last_name;
      }
      resolve(account);
    });
  });
}

const readFbPagePromise = (
  accessToken: string,
  pageUrl: string,
  useShortPages: boolean
) =>
  new Promise((resolve) => {
    window.FB.api(
      pageUrl,
      { accessToken, limit: useShortPages ? 15 : 100 },
      (response: any) => {
        console.log(`${pageUrl} response`, response);
        return resolve(response);
      }
    );
  });

const fbReadPagesCore = async (
  auth: fb.AuthResponse,
  pageUrl: string,
  useShortPages?: boolean
): Promise<any[]> => {
  if (!isFBSdkOk()) {
    throw new UserFriendlyError(
      "It looks like the Facebook pixel is not loaded, so we can't connect to Instagram Insights. Please check if you have an ad blocker enabled."
    );
  }

  let nextPageUrl = pageUrl;
  let result: Array<any> = [];

  for (
    let i = 0;
    i < (useShortPages ? MAX_FB_PAGE_ITERATION * 2 : MAX_FB_PAGE_ITERATION);
    i++
  ) {
    const response: any = await readFbPagePromise(
      auth.accessToken,
      nextPageUrl,
      useShortPages || false
    );

    if (!response || response.error) {
      throw new Error(response?.error);
    }

    result = [...result, ...response.data];
    if (response.paging?.next) {
      nextPageUrl = response.paging.next;
    } else {
      break;
    }
  }
  return result;
};

export async function fbReadPages(
  auth: fb.AuthResponse,
  useShortPages: boolean
): Promise<FBPage[]> {
  return await fbReadPagesCore(
    auth,
    "/me/accounts?fields=id,name,access_token,instagram_business_account",
    useShortPages
  );
}

export async function fbReadPagesExtended(
  auth: fb.AuthResponse,
  useShortPages: boolean
): Promise<FBPageExtended[]> {
  return await fbReadPagesCore(
    auth,
    "/me/accounts?fields=id,name,name_with_location_descriptor,access_token,category,category_list,tasks",
    useShortPages
  );
}

export async function fbReadInstaBizAccount(
  page: FBPage,
  auth: fb.AuthResponse
): Promise<FBPageWithInsta> {
  return new Promise(async (resolve, reject) => {
    if (!isFBSdkOk()) {
      return reject(
        new UserFriendlyError(
          "It looks like the Facebook pixel is not loaded, so we can't connect to Instagram Insights. Please check if you have an ad blocker enabled."
        )
      );
    }
    if (!page.instagram_business_account) {
      return resolve({ page });
    }
    window.FB.api(
      `/${
        page.instagram_business_account!.id
      }?fields=id,ig_id,followers_count,profile_picture_url,name,username,website`,
      { accessToken: auth.accessToken },
      (response: any) => {
        if (!response || response.error) {
          reject(response?.error);
        }
        console.log("fbData Becomes igAccountData", response);
        // NOTE: this is the data we collect
        resolve({ page, ig: response });
      }
    );
  });
}

export async function fbReadGroups(auth: fb.AuthResponse): Promise<FBGroup[]> {
  return new Promise((resolve, reject) => {
    if (!isFBSdkOk()) {
      return reject(
        new UserFriendlyError(
          "It looks like the Facebook pixel is not loaded, so we can't connect to Instagram Insights. Please check if you have an ad blocker enabled."
        )
      );
    }
    window.FB.api(
      "/me/groups?admin_only=true",
      { accessToken: auth.accessToken, limit: 100 },
      (response: any) => {
        if (!response || response.error) {
          reject(response?.error);
        }
        resolve(response.data);
      }
    );
  });
}
