import React from "react";
import styled, { css } from "styled-components";

import { useOpenableState } from "libs/use-openable-state";
import WarningAlertIcon from "./alert-circle.svg";
import PencilLineIcon from "./pencil-line.svg";
import ArrowIcon from "ui/ecosystems/dropdown/atoms/arrow.svg";
import { Collapse } from "ui/collapse";
import { Flex } from "ui/commonStyled/layout";
import { Button } from "ui/ecosystems/forms";

interface Props {
  renderTitle(): React.ReactNode;
  renderContent(): React.ReactNode;
  defaultOpenState?: boolean;
  hasDisconnectedAccount?: boolean;
  canAddAccounts: boolean;
  editAccountGroup?: () => void;
  isEditAccountGroup?: boolean;
}

type DropDownIconProps = {
  isOpened: boolean;
};

export function AccountGroupTemplateComponent({
  renderTitle,
  renderContent,
  editAccountGroup,
  defaultOpenState = true,
  hasDisconnectedAccount = false,
  canAddAccounts,
  isEditAccountGroup = false,
}: Props): JSX.Element {
  const collapsed = useOpenableState(defaultOpenState);
  const handleEditAccountGroup = (e: any) => {
    e.stopPropagation();
    editAccountGroup && editAccountGroup();
  };

  return (
    <AccountGroupTemplateRoot
      active={isEditAccountGroup}
      data-testid="groupAccounts"
    >
      <Title onClick={collapsed.toggle} isOpened={collapsed.isOpened}>
        {renderTitle()}
        {hasDisconnectedAccount && (
          <DisconnectWarningIcon data-testid="groupDisconnectWarningIcon" />
        )}
        <Flex align="center" marginLeft="auto" gap={0}>
          {editAccountGroup && (
            <EditGroupButton
              text
              disabled={!canAddAccounts}
              onClick={handleEditAccountGroup}
              data-testid="editAccountGroupButton"
            >
              <StyledPencilLineIcon
                active={isEditAccountGroup}
                disabled={!canAddAccounts}
              />
            </EditGroupButton>
          )}
          <DropDownIcon />
        </Flex>
      </Title>
      <Collapse isOpened={collapsed.isOpened}>
        <Content>{renderContent()}</Content>
      </Collapse>
    </AccountGroupTemplateRoot>
  );
}

export const AccountGroupTemplate = styled(AccountGroupTemplateComponent)``;

const EditGroupButton = styled(Button)`
  padding: 10px 0 10px 10px;

  &:disabled:not([data-skedui]) {
    background-color: transparent !important;
    border-color: transparent !important;
    cursor: not-allowed !important;
    opacity: 0.8;
  }

  &:not(:disabled):hover {
    filter: unset;
  }
`;
const StyledPencilLineIcon = styled(PencilLineIcon)<{ active: boolean }>`
  color: ${({ active }) => (active ? "#4f31ff" : "#999999")};
  width: 16px;
  height: 16px;
  margin-left: 8px;

  &:hover {
    color: ${({ disabled }) => (disabled ? "#999999" : "#4f31ff")};
  }
`;

const DropDownIcon = styled(ArrowIcon)`
  height: 6px;
  width: 11px;
  .icon {
    fill: #999999;
  }
  transition: transform 100ms;
`;

const DisconnectWarningIcon = styled(WarningAlertIcon)`
  color: #fa4f38;
  margin-left: 8px;
  flex-shrink: 0;
`;

const Title = styled.div<DropDownIconProps>`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  background: #f6f5ff;
  cursor: pointer;
  user-select: none;

  ${DropDownIcon} {
    margin-right: 16px;
    margin-left: 16px;
  }

  ${(props: DropDownIconProps) =>
    !props.isOpened &&
    css`
      ${DropDownIcon} {
        transform: rotate(90deg);
      }
    `};
`;

const Content = styled.div``;

const AccountGroupTemplateRoot = styled.div<{ active: boolean }>`
  background: #fbfbff;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ active }) => (active ? "#4f31ff" : "#dedafe")};
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
`;
