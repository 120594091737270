import { skedInboxApi } from "./_skedInboxApi";
import { ReactionType } from "types/conversationInbox";

export async function setMessageReaction({
  messageId,
  reaction,
}: {
  messageId: string;
  reaction: string;
}): Promise<{
  data: ReactionType;
}> {
  return await skedInboxApi.post(`/messages/${messageId}/reactions`, {
    reaction,
  });
}

export async function deleteMessageReaction({
  messageId,
  reactionId,
}: {
  messageId: string;
  reactionId: string;
}): Promise<null> {
  return await skedInboxApi.delete(
    `/messages/${messageId}/reactions/${reactionId}`
  );
}
