import React from "react";
import { Attachment } from "types/conversationInbox";
import { renderContentProps } from "../message/message-types/types";
import {
  BlockContainer,
  MentionLabel,
  MultiMediaBackground,
  MultiMediaContainer,
} from "../message/styled";
import { MultiMedia, UnsupportedMedia } from "./multi-media";
import { MessageReaction } from "./message-reactions";
import { getCustomMessage } from "./custom-message";
import { useCommonMessageState } from "../message/hooks";

type MessageMediaProps = renderContentProps & {
  media: Attachment;
  portraitMedia?: boolean;
};

export const MessageMedia = (props: MessageMediaProps): JSX.Element => {
  const {
    isLeft,
    reactions,
    onReactionsChange,
    messageId,
    media,
    isRepliedMessage,
    isTeamMessage,
    type,
    story,
    attachments,
    message,
    portraitMedia,
  } = props;

  const { isInvalidUrl, isReady, onError, setIsReady, isMediaURL } =
    useCommonMessageState({ story, attachments, message });

  const shouldRenderReactions = !!reactions && !!onReactionsChange;

  const reactionsJsx = shouldRenderReactions && (
    <BlockContainer>
      <MessageReaction
        isLeft={!!isLeft}
        messageId={messageId}
        reactions={reactions}
        onReactionsChange={onReactionsChange}
      />
    </BlockContainer>
  );
  return (
    <>
      {isMediaURL && (
        <MentionLabel left={isLeft}>
          {getCustomMessage({ type, isInvalidUrl })}
        </MentionLabel>
      )}
      <MultiMediaContainer left={isLeft} data-testid="message-media">
        {!isLeft && reactionsJsx}
        <BlockContainer>
          {isMediaURL && (
            <MultiMediaBackground
              portraitMedia={portraitMedia}
              left={isLeft}
              isReady={isReady}
              mediaType={media.type}
            >
              <MultiMedia
                portraitMedia={portraitMedia}
                media={media}
                onError={onError}
                showInvalid={isInvalidUrl}
                isReady={isReady}
                setIsReady={setIsReady}
                left={isLeft}
                isTeamMessage={isTeamMessage}
                isRepliedMessage={isRepliedMessage}
              />
            </MultiMediaBackground>
          )}
        </BlockContainer>
        {isLeft && reactionsJsx}
      </MultiMediaContainer>
    </>
  );
};

export const MessageAttachments = (
  props: renderContentProps
): JSX.Element | null => {
  const {
    attachments = [],
    isLeft,
    isRepliedMessage,
    isTeamMessage,
    story,
    message,
  } = props;
  const { isMediaURL, isMessageMapUrl } = useCommonMessageState({
    story,
    attachments,
    message,
  });

  if (isMessageMapUrl || !isMediaURL) {
    return null;
  }

  // for unspported media
  if (attachments.length < 1) {
    return (
      <UnsupportedMedia
        left={isLeft}
        isRepliedMessage={isRepliedMessage}
        isTeamMessage={isTeamMessage}
      />
    );
  }

  return (
    <>
      {attachments.map((attachment: Attachment, key) => (
        <MessageMedia key={key} {...props} media={attachment} />
      ))}
    </>
  );
};
