import { createReducer } from "@reduxjs/toolkit";

import {
  INIT_POSTS_PAGE_ROUTINE,
  TOGGLE_EXPANDED,
  FILTER_COLLAB_STATUS,
  FILTER_POST_TYPE,
  FILTER_POST_CONTENT_TYPE,
} from "constants/ActionTypes";
import { PAGES } from "constants/Pages";

const initialState = {
  page: null,
  initialized: false,
  expanded: false,
  filters: {
    postStatusKey: null,
    postType: "all",
    postContentTypes: [],
  },
};

const postsViewReducer = createReducer(initialState, {
  [INIT_POSTS_PAGE_ROUTINE.TRIGGER]: (state, action) => {
    const { page } = action.payload;
    state.page = page;
    state.initialized = false;

    const hasFilters = PAGES.PLANNER !== page;
    const hasPostStatusKeys =
      PAGES.DRAFT === page ||
      PAGES.UPCOMING === page ||
      PAGES.CALENDAR === page;

    state.filters = hasFilters
      ? {
          postContentTypes: state.filters.postContentTypes,
          postStatusKey: hasPostStatusKeys ? state.filters.postStatusKey : null,
        }
      : initialState.filters;
    return state;
  },
  [INIT_POSTS_PAGE_ROUTINE.SUCCESS]: (state) => {
    state.initialized = true;
    return state;
  },
  [FILTER_COLLAB_STATUS]: (state, action) => {
    const { postStatusKey } = action.payload;
    state.filters.postStatusKey = postStatusKey;
    return state;
  },
  [FILTER_POST_TYPE]: (state, action) => {
    const { postType } = action.payload;
    state.filters.postType = postType;
    return state;
  },
  [FILTER_POST_CONTENT_TYPE]: (state, action) => {
    const { postContentTypes } = action.payload;

    return {
      ...state,
      filters: { ...state.filters, postContentTypes: postContentTypes },
    };
  },
  [TOGGLE_EXPANDED]: (state) => {
    state.expanded = !state.expanded;
    return state;
  },
});

const oldReducer = (state = initialState, action) => {
  const { page } = state;
  const controller = getController();

  switch (action.type) {
    case SHOW_REPOST:
      controller.editPost(action.post);
      return clearCheckboxes(state);
    case SHOW_EDIT_POST:
      switch (page) {
        case PAGES.UPCOMING:
          controller.editPost(action.post);
          break;
        case PAGES.DRAFT:
          controller.editDraft(action.post);
          break;
      }
      return clearCheckboxes(state);
    case SHOW_SEND_TO_DRAFT:
      controller.showDraft(action.post);
      return clearCheckboxes(state);
    case SHOW_SEND_TO_QUEUE:
      controller.showSendToQueue(action.post);
      return clearCheckboxes(state);
    case TOGGLE_COLLABORATE_WIDGET:
      controller.toggleCollaborateWidget(action.index, action.post);
      controller.$apply();
      return state;
    case EDIT_POST_URL:
      controller.showEditLinkModal(action.post);
      return state;
    case SHOW_REMOVE_POST:
      switch (page) {
        case PAGES.UPCOMING:
          controller.showCancelPost(action.post);
          break;
        case PAGES.DRAFT:
          controller.showRemove(action.post);
          break;
        case PAGES.ARCHIVE:
          controller.showRemoveModal(action.index);
          break;
      }
      return clearCheckboxes(state);
    case SHOW_REMOVE_FROM_QUEUE:
      controller.showRemoveQueueModal(action.index);
      return clearCheckboxes(state);
  }
};

export default postsViewReducer;
