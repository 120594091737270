import React, { useMemo } from "react";
import styled from "styled-components";

import { LoadingSpinner } from "ui";
import SkedIcon from "ui/SkedIcon/SkedIcon";
import { BUTTON_TYPES } from "constants/SkedButton";

import "./SkedButton.less";

interface SkedButtonProps {
  name?: string;
  onClick: () => void;
  style?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  title?: string;
  icon?: string;
  type?: string;
  size?: string;
  children?: React.ReactNode;
  "data-testid"?: string;
}
/**
 * @deprecated - use Button from "ui/ecosystem/forms"
 */
const SkedButton = ({
  name,
  onClick,
  disabled,
  className = "",
  title = "",
  icon,
  type,
  size = "medium",
  children,
  loading = false,
  "data-testid": dataTestId,
}: SkedButtonProps) => {
  const buttonType = useMemo(() => {
    switch (type) {
      case BUTTON_TYPES.ALERT:
        return "SkedButton-Btn SkedButton_alertFilled btn";
      case BUTTON_TYPES.ALERT_OUTLINE:
        return "SkedButton-Btn SkedButton_alertOutline btn";
      case BUTTON_TYPES.PRIMARY:
        return "SkedButton-Btn SkedButton_primary btn primary";
      case BUTTON_TYPES.GHOST:
        return "SkedButton-Btn SkedButton_ghost btn";
      case BUTTON_TYPES.SIMPLE:
        return "SkedButton-Btn SkedButton_simple btn";
      case BUTTON_TYPES.PRIMARY_OUTLINE:
      default:
        return "SkedButton-Btn SkedButton_primaryOutline btn primary_outline";
    }
  }, [type]);

  const buttonSize = useMemo(() => {
    switch (size) {
      case "small":
        return "SkedButton-Size_small";
      case "large": //todo
        return "SkedButton-Size_large";
      default:
      case "medium":
        return "SkedButton-Size_medium";
    }
  }, [size]);
  const buttonText = children ? children : name;

  return (
    <div data-testid={dataTestId} className={`SkedButton ${className}`}>
      <button
        disabled={disabled}
        type="button"
        onClick={onClick}
        className={`${buttonType} ${buttonSize}`}
        title={title}
      >
        {icon && <SkedIcon className="SkedButton-Icon" icon={icon} />}
        {icon && !!buttonText && " "}
        {buttonText}
        {loading && <LoadingSpinner isSmall={true} />}
      </button>
    </div>
  );
};

export default SkedButton;
