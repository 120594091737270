import _ from "lodash";
import React from "react";

const NORMAL_STYLE = "normal";

export class RichTextMessageHandler {
  private phases: IPhase[];
  constructor(phases: IPhase[]) {
    this.phases = phases;
  }
  public render(text: string): JSX.Element {
    const spans = this.parse(text);
    return this.getComponentFromSpans(spans);
  }
  parse(text: string) {
    try {
      let spans: StyledTextSpan[] = [{ style: NORMAL_STYLE, text }];
      this.phases.forEach((phase) => {
        spans = _.flatMap(spans, (span) => {
          if (span.style === NORMAL_STYLE) {
            return phase.run(span);
          }
          return [span];
        });
      });

      return spans;
    } catch (e) {
      console.warn(e);
    }
    return [{ style: NORMAL_STYLE, text }];
  }

  private getComponentFromSpans(spans: StyledTextSpan[]): JSX.Element {
    return (
      <span>
        {spans.map((c, idx) => {
          const foundPhase = this.phases.find((p) => p.style === c.style);
          if (foundPhase) {
            return foundPhase.render(c);
          }
          return <span key={idx}>{c.text}</span>;
        })}
      </span>
    );
  }
}

export const renderAccountNameBold = (
  message: string,
  accountName: string | null
) => {
  if (!accountName || !message) {
    return message;
  }
  const phase1 = new BoldOnIncludePhase(`@${accountName}`);
  const phase2 = new BoldOnIncludePhase(accountName);
  const richTextMessage = new RichTextMessageHandler([phase1, phase2]);
  return richTextMessage.render(message);
};

interface IPhase {
  style: string;
  run(span: StyledTextSpan): StyledTextSpan[];
  render(span: StyledTextSpan): JSX.Element;
}

interface StyledTextSpan {
  style: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export class BoldOnIncludePhase implements IPhase {
  private name: string;
  constructor(name: string) {
    this.name = name;
  }
  style = "bold-on-include";
  run(span: StyledTextSpan): StyledTextSpan[] {
    const hashtagRegex = new RegExp(`(${escapeRegExp(this.name)})`, "g");
    const matches = regexMatchAll(span.text, hashtagRegex);
    if (matches.length <= 0) {
      return [span];
    }

    return buildSpansWithMatchedRegex(span.text, matches, (m) => ({
      style: this.style,
      text: `${m[1]}`,
    }));
  }
  render(span: StyledTextSpan): JSX.Element {
    return (
      <span>
        <b>{span.text}</b>
      </span>
    );
  }
}

function regexMatchAll(text: string, regex: RegExp) {
  let matched;
  const result: RegExpExecArray[] = [];
  do {
    matched = regex.exec(text);
    if (matched) {
      result.push(matched);
    }
  } while (matched);
  return result;
}

function buildSpansWithMatchedRegex(
  text: string,
  matches: RegExpExecArray[],
  makeSpan: (match: RegExpExecArray) => StyledTextSpan
): StyledTextSpan[] {
  const spans: StyledTextSpan[] = [];
  for (let idx = 0; idx < matches.length; idx++) {
    const match = matches[idx];
    const prevMatch = idx > 0 ? matches[idx - 1] : undefined;
    spans.push({
      style: "normal",
      text: text.substring(
        prevMatch ? prevMatch.index + prevMatch[0].length : 0,
        match.index
      ),
    });
    spans.push(makeSpan(match));
    if (idx >= matches.length - 1) {
      spans.push({
        style: "normal",
        text: text.substring(match.index + match[0].length, text.length),
      });
    }
  }

  return spans.filter((c) => !!c.text);
}

function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
