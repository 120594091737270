import ExternalLinkIcon from "./external-link-icon.svg";
import React from "react";

export function ExternalLink(props: { href: string; className?: string }) {
  return (
    <a
      className={props.className}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ExternalLinkIcon />
    </a>
  );
}
