import { isAccountFacebookPage } from "entities/accounts";
import { User } from "features/user";
import type { AccountType } from "shared/types/accounts";

export function isAdsFeatureOn(account: AccountType, user: User) {
  return (
    isAccountFacebookPage(account) && user?.plan?.includes("sked-enterprise")
  );
}

export function isAdsPermissionRequired(account: AccountType, user: User) {
  const scope = "ads_read";
  return (
    isAdsFeatureOn(account, user) && !account.session?.scopes?.includes(scope)
  );
}

export function getRequiredAdsPermissionAccounts(
  accounts: AccountType[] | undefined,
  accountsToUpdate:
    | { facebook?: AccountType[]; instagram?: AccountType[] }
    | undefined,
  user: User
) {
  return (accounts || []).filter((acc) => {
    if (accountsToUpdate?.facebook?.some((a) => a._id === acc._id)) {
      return false;
    }
    return isAdsPermissionRequired(acc, user);
  });
}

export function isCollaborationEnabledForPlan(plan: string) {
  return [
    "sked-professional",
    "sked-enterprise",
    "sked-custom",
    "sked-essentials",
  ].some((c) => (plan || "").includes(c));
}
