import React from "react";
import { renderContentProps } from "./types";
import {
  Attachment,
  InboxConstants,
  MessageStatus,
  MessageType,
} from "types/conversationInbox";
import {
  MessageBlock,
  MessageContainer,
  resolveMessageBackground,
} from "../styled";
import {
  MessageReaction,
  ReactionsChangeData,
} from "../../components/message-reactions";
import Microlink from "@microlink/react";
import styled from "styled-components";
import { UnsupportedMedia } from "../../components/multi-media";
import {
  MessageMoreOptions,
  GMBMessageMoreOptions,
} from "../../components/message-more-options";
import { urlRegex } from "../../components/post-caption-handler";
import InboxPreferences from "@inbox/pages/conversation-page/inbox-preferences";
import { ReactionsContext } from "../types";
import { renderAccountNameBold } from "./rich-text-message";
import { platformAccountNameMap } from "shared/types/accounts";

export const allowReactionsMessageTypes = [
  MessageType.igMessage,
  MessageType.ttThreadedComment,
];

export const isUnsupportedAttachment = (
  message: renderContentProps["message"],
  attachments: Attachment[]
): boolean => {
  return (
    (attachments || []).length < 1 &&
    typeof message === "string" &&
    message.length <= 0
  );
};

export const NormalMessage = ({
  conversationId,
  type,
  message,
  isLeft,
  isTeamMessage,
  isRepliedMessage,
  attachments = [],
  messageId,
  reactions,
  originalMessage,
  afterPlaceholder,
  onReactionsChange,
  status,
  accountContext,
}: NormalMessageProps): JSX.Element | null => {
  if (originalMessage?.deleted) {
    message = "Message has been deleted.";
  }

  if (message === undefined) {
    return null;
  }

  // for unspported attachment types, do not show the message text
  if (isUnsupportedAttachment(message, attachments)) {
    return (
      <UnsupportedMedia
        isRepliedMessage={isRepliedMessage}
        isTeamMessage={isTeamMessage}
        left={isLeft}
      />
    );
  }

  if (attachments.length > 0 && !message) {
    return null;
  }

  const reactionsJsx = InboxPreferences.shouldEnableReaction(
    type,
    reactions,
    onReactionsChange
  ) && (
    <MessageReaction
      isLeft={!!isLeft}
      messageId={messageId}
      reactions={reactions as ReactionsContext}
      onReactionsChange={
        onReactionsChange as (data: ReactionsChangeData) => void
      }
    />
  );

  let link = null;

  const containsUrl = typeof message === "string" && urlRegex.test(message);
  if (containsUrl) {
    const regexMatchArray = (message as string).match(urlRegex) || [];
    link = regexMatchArray[0];
    const [textBefore, textAfter] = (message as string).split(link || "");

    message = (
      <>
        {textBefore}
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
        {textAfter}
      </>
    );
  } else if (
    accountContext &&
    typeof message === "string" &&
    (type === MessageType.igMentionV2 || type === MessageType.igMentionInPostV2)
  ) {
    message = renderAccountNameBold(message, accountContext.login);
  }

  const messageBackgroundStyle = resolveMessageBackground({
    isRepliedMessage,
    isTeamMessage,
    isFailed: status === MessageStatus.failed,
    left: isLeft,
  });

  const messageOptions = resolveMessageOptions({
    type,
    originalMessage,
    isTeamMessage,
    conversationId,
    isLeft,
    accountContext,
  });

  const { optionsBefore, optionsAfter } = resolveMessageOptionsPosition(
    type,
    messageOptions
  );

  return (
    <MessageContainer left={isLeft}>
      {!isLeft && reactionsJsx}
      {optionsBefore}
      <MessageBlockWrapper left={isLeft}>
        <MessageBlock
          style={{ background: messageBackgroundStyle }}
          left={isLeft}
          isTeamMessage={isTeamMessage}
          isRepliedMessage={isRepliedMessage}
          italic={!!originalMessage?.deleted}
        >
          {message}
        </MessageBlock>
        {containsUrl && link && (
          <MicrolinkWrapper>
            <Microlink
              url={link}
              className="tw-m-0 tw-w-full"
              apiKey={InboxConstants.microlinkApiKey}
            />
          </MicrolinkWrapper>
        )}
      </MessageBlockWrapper>
      {isLeft && reactionsJsx}
      {optionsAfter}
      {afterPlaceholder}
    </MessageContainer>
  );
};

function resolveMessageOptionsPosition(
  messageType: MessageType,
  element: React.ReactNode
) {
  if (
    messageType === MessageType.gmbReviewReply ||
    messageType === MessageType.fbComment
  ) {
    return { optionsBefore: element, optionsAfter: null };
  }
  return { optionsBefore: null, optionsAfter: element };
}

function resolveMessageOptions({
  type,
  originalMessage,
  isTeamMessage,
  conversationId,
  isLeft,
  accountContext,
}: Pick<
  NormalMessageProps,
  | "type"
  | "originalMessage"
  | "isTeamMessage"
  | "conversationId"
  | "isLeft"
  | "accountContext"
>) {
  if (
    !originalMessage ||
    !InboxPreferences.shouldRenderMessageOptions(originalMessage, isTeamMessage)
  ) {
    return <></>;
  }

  if ([MessageType.gmbReviewReply, MessageType.gmbAnwser].includes(type)) {
    return (
      <GMBMessageMoreOptions
        conversationId={conversationId}
        message={originalMessage}
        position={type === MessageType.gmbReviewReply ? "left" : "right"}
        label={type === MessageType.gmbReviewReply ? "review" : "question"}
      />
    );
  }
  const platformName =
    platformAccountNameMap[accountContext?.platformType || "IG"];
  const isTtComment = type === MessageType.ttThreadedComment;
  if (isTtComment) {
    const supportDeleteAction = originalMessage.sender?.platform === "SK";
    return (
      <MessageMoreOptions
        conversationId={conversationId}
        messageId={originalMessage?.id}
        messageHidden={!!originalMessage?.hidden}
        isHiddenProcessing={!!originalMessage?.isHiddenProcessing}
        supportHiddenAction={!!isLeft}
        supportDeleteAction={supportDeleteAction}
        isSending={originalMessage?.status === MessageStatus.pending}
        platform={platformName}
      />
    );
  }

  const isFbComment =
    type === MessageType.fbComment || type === MessageType.fbThreadedComment;

  if (type === MessageType.igThreadedComment) {
    return (
      <MessageMoreOptions
        conversationId={conversationId}
        messageId={originalMessage?.id}
        messageHidden={!!originalMessage?.hidden}
        isHiddenProcessing={!!originalMessage?.isHiddenProcessing}
        supportHiddenAction={!!isLeft}
        isSending={originalMessage?.status === MessageStatus.pending}
        platform={platformName}
      />
    );
  }
  if (isFbComment) {
    return (
      <MessageMoreOptions
        conversationId={conversationId}
        messageId={originalMessage?.id}
        messageHidden={!!originalMessage?.hidden}
        isHiddenProcessing={!!originalMessage?.isHiddenProcessing}
        supportHiddenAction={type === MessageType.fbThreadedComment}
        isSending={originalMessage?.status === MessageStatus.pending}
        platform={platformName}
      />
    );
  }

  return <></>;
}

export type NormalMessageProps = Pick<
  renderContentProps,
  | "type"
  | "message"
  | "isLeft"
  | "isTeamMessage"
  | "isRepliedMessage"
  | "attachments"
  | "messageId"
  | "reactions"
  | "onReactionsChange"
  | "originalMessage"
  | "conversationId"
  | "selected"
  | "onSelected"
  | "status"
  | "accountContext"
> & { afterPlaceholder?: JSX.Element | false };

const MessageBlockWrapper = styled.div<{ left: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.left ? "flex-start" : "flex-end")};
  a {
    margin: 12px 12px 0 12px !important;
  }
`;

const MicrolinkWrapper = styled.div`
  width: 500px;
  margin: 12px 12px 0 12px;
`;
