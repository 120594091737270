import React from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Link as LinkComponent } from "libs/ng-react-router-bridge";
import { SkedStories, useSkedStories } from "features/stories";
import { PlatformIcon } from "entities/accounts/components/plaform-icon/plaform-icon";
import { HeaderUserMenu } from "./HeaderUserMenu";
import { AddUserMenu } from "./AddUserMenu";
import { AddAccountMenu } from "./AddAccountMenu";
import { useTrackEvent } from "libs/analytics";
import { HEADER_ADD_ACCOUNT_CLICK } from "constants/Events";
import { Flex } from "ui/commonStyled/layout";
import { Button } from "@dialogueconsulting/sked-ui";

import HomeIcon from "./icons/menu/home_large.svg";
import PlannerIcon from "./icons/menu/planner_large.svg";
import CalendarIcon from "./icons/menu/calendar_large.svg";
import TemplateIcon from "./icons/menu/template_large.svg";
import DraftsIcon from "./icons/menu/drafts_large.svg";
import BulkUploadIcon from "./icons/menu/bulk-upload_large.svg";
import UpcomingIcon from "./icons/menu/upcoming_large.svg";
import ArchiveIcon from "./icons/menu/archive_large.svg";
import LibraryIcon from "./icons/menu/library_large.svg";
import InboxIcon from "./icons/menu/inbox_large.svg";
import InsightsIcon from "./icons/menu/insights_large.svg";
import WorkshopIcon from "pages/component/menu/icons/workshop-icon.svg";
interface HeaderProps {
  className?: string;
  pageTitle?: string;
  subTitle?: string;
  customLinkInTitle?: "report_inbox_bug";
  icon?: JSX.Element;

  logout(): void;
  openSocialAccountConnectDialog(): void;
}

function HeaderComponent(props: HeaderProps) {
  const [skedStoriesProps, skedStoriesShown] = useSkedStories();
  const trackEvent = useTrackEvent();

  const openSocialAccountConnectDialogWithEvent = (): void => {
    trackEvent({
      eventName: HEADER_ADD_ACCOUNT_CLICK,
      useServices: ["AMP"],
    });
    props.openSocialAccountConnectDialog();
  };

  const titleProps = getRenderIconProps(props.pageTitle);

  return (
    <HeaderContainer className={props.className}>
      <HeaderHeightDefinition withStories={skedStoriesShown} />

      <Title data-testid="header-title" {...titleProps}>
        {props.pageTitle && renderIcon(props.pageTitle)}{" "}
        <span>{props.pageTitle}</span>
        {props.subTitle && (
          <>
            <TitleDivider />
            <SubTitlte>{props.subTitle}</SubTitlte>
          </>
        )}
      </Title>
      <CSSTransition
        in={skedStoriesShown}
        timeout={ANIMATION_MS}
        classNames="fade"
        mountOnEnter
        unmountOnExit
      >
        <Stories {...skedStoriesProps} />
      </CSSTransition>

      <Flex>
        <AddAccountMenu
          openSocialAccountConnectDialog={
            openSocialAccountConnectDialogWithEvent
          }
        />
        <AddUserMenu />
        <HeaderUserMenu />
      </Flex>
    </HeaderContainer>
  );
}

const HeaderHeightDefinition = createGlobalStyle<{ withStories: boolean }>`
  body {
    [data-radix-popper-content-wrapper] {
      z-index: 55 !important;
    }
    --header-height: ${(props) => (props.withStories ? "100px" : "50px")};
    @media (min-width: 1980px) {
      --header-height: ${(props) => (props.withStories ? "100px" : "60px")};
    }
  }
`;

function renderIcon(title: string) {
  switch (title) {
    case "Sked Home":
      return <HomeIcon />;
    case "Calendar":
      return <CalendarIcon />;
    case "Feed Planner":
      return <PlannerIcon />;
    case "Template Manager":
      return <TemplateIcon />;
    case "Drafts":
      return <DraftsIcon />;
    case "Upload":
      return <BulkUploadIcon />;
    case "Upcoming Posts":
      return <UpcomingIcon />;
    case "Archive":
      return <ArchiveIcon />;
    case "Library":
      return <LibraryIcon />;
    case "Inbox":
      return <InboxIcon />;
    case "Insights":
      return <InsightsIcon />;
    case "Workshop":
      return <WorkshopIcon />;
    default:
      return null;
  }
}

function getRenderIconProps(title?: string) {
  switch (title) {
    case "Workshop":
      return { fill: false };
    default:
      return { fill: true };
  }
}

const ANIMATION_MS = 500;

export const DropdownMenuContent = styled(DropdownMenu.Content)<{
  padding?: string;
  minWidth?: number;
}>`
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: ${({ padding = "14px" }): string => padding};
  ${({ minWidth }) =>
    minWidth
      ? css`
          min-width: ${minWidth}px;
        `
      : ""};

  :focus-visible {
    outline: unset;
  }
`;
export const Header = styled(HeaderComponent)``;

export const DropdownButton = styled.button`
  width: 100%;
  background: #0e1680;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  padding: 0 20px;
  border-radius: 5px;
  outline: none;
  display: inline-block;
  min-width: 0;
  height: 38px;
  line-height: 38px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin-top: 12px;

  &:hover {
    background-color: #3c43b4;
    color: #ffffff;
  }

  &:focus {
    color: #ffffff;
    outline: none;
  }
`;

export const DropdownBoldText = styled.span`
  font-weight: 700;
`;

export const DropdownText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #0e1680;
  max-width: 350px;
`;

export const Trigger = styled.div<{ fontWeight?: number }>`
  cursor: pointer;
  font-weight: ${({ fontWeight = 300 }) => fontWeight};
  font-size: 14px;
  line-height: 17px;
  color: #0e1680;
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  padding: 7px 12px 7px 15px;
  gap: 6px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f6f5ff;
  }

  svg {
    transition: transform 0.3s;
    height: 5px;
    width: 10px;
  }

  &[data-state="open"] {
    background: #dedafe;

    svg {
      transform: rotate(180deg);
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  height: var(--header-height);
  padding: 0 21px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  background: #ffffff;
  user-select: none;
  transition: height ${ANIMATION_MS * 1.5}ms;
`;

const Title = styled.div<{ fill: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  column-gap: 20px;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #0e1680;

  svg {
    stroke: #0e1680;
    ${(props) => props.fill && `fill: #0e1680;`}
  }
`;

const TitleDivider = styled.div`
  height: 24px;
  width: 1px;
  background: #0e1680;
`;

const SubTitlte = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const Link = styled(LinkComponent)`
  display: block;
  white-space: nowrap;
  color: #0e1680;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px;

  &:first-child {
    margin-top: 0;
  }

  &.active,
  &:hover {
    color: #0e1680;
    text-decoration: none;
  }
`;

export const Logout = styled.div`
  cursor: pointer;
  color: #0e1680;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .icon {
    margin-right: 12px;
  }

  &:hover {
    color: #0e1680;
    background: #fcfaff;
  }
  &:active {
    background: #edeaff;
  }
`;

export const SubMenu = styled.div`
  ${Link} {
    line-height: 24px;

    .icon {
      margin-right: 10px;
    }
    :nth-child(1) {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    :hover {
      background: #fcfaff;
    }
    :active {
      background: #edeaff;
    }
  }
`;

const Stories = styled(SkedStories)`
  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_MS}ms;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity ${ANIMATION_MS}ms;
  }

  &.fade-exit-done {
    display: none;
  }
`;

export const StyledPlatform = styled(PlatformIcon)<{ size: number }>`
  width: ${({ size }) => `${size}px`};
`;

export const IncreaseQuotaButton = styled(Button)`
  margin: 12px auto 0;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-weight: 600;
  width: 100% !important;
`;
