import styled from "styled-components";

export const Title = styled.h2`
  font-size: 18px;
  line-height: 22px;
  color: #333433;
  font-weight: 600;
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #c4c4c4;
    margin-left: 20px;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  .select__control {
    --inputHeight: 18px;
    min-width: 172px;

    div[class*="-indicatorContainer"] {
      padding: 5px;
    }
  }

  div[class*="dropdown__DropdownRoot"] {
    margin: 0 0 0 10px;

    button {
      height: 38px;
      line-height: 38px;
      font-size: 10px;
      margin-right: 10px;
    }
  }

  div[class*="-control"] {
    min-width: 240px;
    min-height: 38px;
  }
`;

export const FilterBar = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const ToolbarLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ToolbarRight = styled.div`
  display: flex;
`;

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
`;

export const EmptyAlertWrapper = styled.div<{ empty?: boolean }>`
  width: 436px;
  min-height: 215px;
  background: ${(props) => (props.empty ? "#ffffff" : "#f6f5ff")};
  border-radius: 10px;
  padding: 28px;
  margin: 100px auto;

  h3 {
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  }
`;
