import React from "react";
import styled from "styled-components";

import { HashtagRecommendation } from "api/post";

import RemoveIcon from "../../icons/remove.svg";

interface TagRecommendationProps {
  rec: HashtagRecommendation;
  className?: string;
  onDislike(): void;
}

function getColor(engagement: number) {
  switch (true) {
    case engagement >= 6.6:
      return "rgba(102, 185, 19, 0.4)"; // "great"
    case engagement >= 3.3:
      return "#F0F8E7"; // "good
    default:
      return "transparent";
  }
}

function TagRecommendationComponent(props: TagRecommendationProps) {
  const { rec, onDislike } = props;
  const engagement =
    (Math.round((rec.metrics.engagement + 0.00001) * 10000) / 10000) * 100;

  function handleClick(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  function dislikeHandle(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    onDislike();
  }

  function isGreen(val: number) {
    const pop = rec.metrics.popularity,
      pval = pop * 20;
    return pval >= val;
  }

  return (
    <TagOption className={props.className}>
      <div>
        <HashTag color={getColor(engagement)}>#{rec.hashtag}</HashTag>
      </div>
      <Actions onMouseDown={dislikeHandle} onClick={handleClick}>
        <Chart>
          {[20, 40, 60, 80, 100].map((val) => (
            <Bar height={val} isActive={isGreen(val)} key={val} />
          ))}
        </Chart>
        <TagOptionRemove>
          <RemoveIcon />
        </TagOptionRemove>
      </Actions>
    </TagOption>
  );
}

export const TagRecommendation = styled(
  TagRecommendationComponent
)<TagRecommendationProps>``;

const TagOptionRemove = styled.div`
  cursor: pointer;
  padding: 5px 5px 4px;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const TagOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
  height: 30px;
  &:hover {
    svg {
      fill: #fa4f38;
    }
  }
`;
const HashTag = styled.div`
  display: inline;
  padding: 4px 8px;
  border-radius: 5px;
  color: #000000;
  background: ${(props) => props.color};
`;

const Bar = styled.div<{ height: number; isActive?: boolean }>`
  display: inline-block;
  width: 2px;
  margin-left: 1px;
  border-radius: 10px;
  vertical-align: text-bottom;
  height: ${(props) => props.height || 0}%;
  background-color: ${(props) => (props.isActive ? "#4F31FF" : "#AEA1FF")};
`;

const Chart = styled.div`
  overflow: hidden;
  display: flex;
  align-items: baseline;
  padding-right: 4px;
  height: 11px;
`;
