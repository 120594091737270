import { createApi, createStore } from "effector";

export const $selectedMediaSet = createStore<Set<string>>(new Set());
export const selectedMediaSetApi = createApi($selectedMediaSet, {
  add: (set, newId: string) => {
    return new Set([...set, newId]);
  },
  addAtIndex: (set, { id, index }) => {
    const newSet = [...set];
    newSet.splice(index, 0, id);
    return new Set(newSet);
  },
  remove: (set, removedId: string) => {
    set.delete(removedId);
    return new Set(set);
  },
  clear: () => new Set(),
});
