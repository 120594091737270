/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkedPreview } from "@dialogueconsulting/sked-preview";
import { getCollaborationComments } from "actions/collaboration";
import {
  checkInstagramPostOverlapping,
  updatePostFormField,
} from "actions/postForm";
import { getPage, getUser } from "selectors/commonSelectors";

import StatusChange from "components/Collaboration/StatusChange/StatusChange";
import { FIELDS } from "constants/PostFormFields";
import { PostFormActions } from "components/Posts/PostForm";
import PlatformTabs from "components/Posts/PostForm/PlatformTabs/PlatformTabs";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { PostBy, PostType } from "components/Posts/Post";
import { generatePostLinkToPostObject } from "components/Posts/Post/PostLinkToPost/PostLinkToPost";
import CollaborationChat from "components/Collaboration/CollaborationChat";
import { PostSelectedAssets } from "features/post/components/post-selected-assets/post-selected-assets";
import { CollaborationIsNotEnabled } from "components/Collaboration/CollaborationIsNotEnabled";
import { Post } from "shared/types/post";
import SkedButton from "ui/SkedButton/SkedButton";
import {
  filterAccountsByPlatformType,
  findManualPlatform,
  getStoryAccountLabel,
} from "utils/accounts";
import { PostFormBaseTemplate } from "./post-form-base-template";
import { TooltipWrapper } from "ui";
import { Alert, AlertType } from "ui/alert";
import { useTrackEvent } from "libs/analytics";
import { AccountType } from "shared/types/accounts";
import { NEW_POST_ID, POST_STATES } from "constants/PostsView";
import {
  COPY_TO_DRAFT_ACTION,
  NEW_CAL_POST_AGAIN_ACTION,
  UPGRADE_APPROVAL_VIEWED,
} from "constants/Events";
import { ONLY_MANUAL_PLATFORMS } from "constants/Platforms";
import styled from "styled-components";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import type { Label } from "./post-form-base-template";
import { useContentLabelSection } from "./content-label-section";
import { showUpsellModal } from "actions/typed-modal";
import {
  ApprovalTooltipElement,
  openSupportForEnableApprovals,
} from "pages/home/utils";
import { isUpsellRequiredForApproval } from "utils/user";
interface Props {
  post: unknown;
  isSubmitting: boolean;
  isSummary: boolean;
  isCollaborationAvailable: boolean;
  renderFields: () => React.ReactNode;
  renderAccountsSelector: () => React.ReactNode;
  renderMediaSelector?: () => React.ReactNode;
  renderPreview?: () => React.ReactNode;
  renderMetaInfo?: () => React.ReactNode;
  renderCollaboration?: () => React.ReactNode;
  renderActions?: () => React.ReactNode;
  children?: JSX.Element;
  isContentLabelFeatureEnabled: boolean;
  contentLabelOptions: Label[];
  selectedContentLabel: Label[];
  isFetchingAccountGroups: boolean;
  selectedAccountGroupId: string;
}

// @ts-ignore
export const PostFormContext = React.createContext<{
  post: unknown;
  postFormValue: Post;
  updateField(name: string, value: unknown): void;
  selectedAccountIds: string[];
  platforms: unknown[];
}>();

export function PostFormTemplate({ ...props }: Props) {
  const {
    allDisabled,
    updateField,
    updateFields,
    selectedAccountIds,
    postFormValue,
    platforms,
    selectPlatform,
    errors,
    warnings,
    post,
    postModel,
    channelId,
    postState,
    platformPost,
    originalPostState,
    onCancel,
    onSubmit,
    saveDisabled,
    isSubmitting,
    isSummary,
    formPostState,
  } = useContext(PostFormContext);

  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const trackEvent = useTrackEvent();
  const page = useSelector(getPage);

  const arePostStatusesEnabled = user.isPostStatusEnabledForUser();

  const collaborationAvailable = user.isCollaborationEnabledForUser();
  const openIntercom = () => {
    window.Intercom("showNewMessage");
  };
  const instagramAccounts: AccountType[] = useMemo(
    () =>
      postFormValue?.accounts?.filter(filterAccountsByPlatformType("IG")) || [],
    [postFormValue?.accounts]
  );

  let canQueuePosts = true;
  let isInstagram = false;
  if (
    postFormValue &&
    postFormValue.accounts &&
    postFormValue.accounts.length > 0
  ) {
    canQueuePosts = postFormValue.accounts.every(function (account) {
      return !!account.queueEnabled;
    });
    isInstagram = instagramAccounts.length > 0;
  }

  useEffect(() => {
    dispatch(
      checkInstagramPostOverlapping({
        postId: postFormValue._id,
        accounts: postFormValue.accounts,
        isNow: postFormValue.isNow,
        when: postFormValue.when,
      })
    );
  }, [postFormValue.isNow, postFormValue.when, instagramAccounts?.length]);

  const { renderLabels } = useContentLabelSection({
    ...props,
    isSummary,
    postFormValue,
  });

  const { url: postLinkToPostUrl, platformLabel: postLinkToPostPlatformLabel } =
    generatePostLinkToPostObject(post, postFormValue.accounts);

  const onStatusChange = async (newPostStatusKey: string) => {
    await dispatch(
      updatePostFormField(postFormValue._id, {
        [FIELDS.POST_STATUS]: newPostStatusKey,
      })
    );
    if (newPostStatusKey === postFormValue.postStatusKey) {
      return;
    }
    if (collaborationAvailable) {
      const channelId =
        platformPost?.schedule?.channel ||
        postFormValue?.channel ||
        postFormValue._id;
      // Server require some time to update the post status and only then we can get updated info in the new request
      setTimeout(
        async () => await dispatch(getCollaborationComments(channelId)),
        1000
      );
    }
  };

  const handlePostSave = () => {
    //if archived post posted again from calendar page
    if (post.originalPostState) {
      trackEvent({ eventName: NEW_CAL_POST_AGAIN_ACTION });
    }

    //if archived post copied to drafts from archive page
    if (
      !post.failedAt &&
      (!post.originalPostState || originalPostState !== "DRAFT") &&
      post._id !== NEW_POST_ID &&
      postState === "DRAFT"
    ) {
      trackEvent({
        eventName: COPY_TO_DRAFT_ACTION,
        metadata: { postSavedAs: postState },
      });
    }

    onSubmit();
  };

  const handleTrackEvent = (eventName: string) => {
    if (page) {
      trackEvent({ eventName, metadata: { page: page.toLocaleLowerCase() } });
    }
  };

  const upsellForApproval = isUpsellRequiredForApproval(user);

  let tooltipMessage: React.ReactNode = "";
  if (upsellForApproval) {
    tooltipMessage = (
      <div style={{ maxWidth: 245 }}>
        Create workflows to automate reviews and approvals. Click to upgrade for
        this feature.
      </div>
    );
  } else if (!arePostStatusesEnabled) {
    tooltipMessage = ApprovalTooltipElement;
  }

  return (
    <PostFormBaseTemplate
      formAccounts={postFormValue.accountIds}
      selectedAccountGroupId={props.selectedAccountGroupId}
      isSubmitting={isSubmitting}
      isNewPost={post.newPost}
      isPostFailedOrPosted={
        (post.postType === "archived" && !post.isSuccess) ||
        post.isSuccess ||
        post.past
      }
      isCollaborationAvailable={collaborationAvailable}
      renderAccountsSelector={props.renderAccountsSelector}
      renderMediaSelector={() => (
        <PostSelectedAssets
          post={postFormValue}
          className="tw-flex tw-flex-col tw-mb-4"
          onChange={(mediaFiles) => updateField(FIELDS.MEDIA, mediaFiles)}
          errors={errors}
          warnings={warnings}
          disabled={allDisabled}
          selectedAccountIds={selectedAccountIds}
        />
      )}
      renderContentLabel={renderLabels}
      selectedContentLabel={props.selectedContentLabel}
      isContentLabelFeatureEnabled={props.isContentLabelFeatureEnabled}
      renderFields={() => (
        <>
          {selectedAccountIds.length > 0 && (
            <div className="tw-relative tw-flex-grow tw-border tw-border-solid tw-border-sked-300 tw-rounded-r-lg tw-rounded-bl-lg tw-p-4 tw-ml-40px">
              <div
                className="tw-flex tw-flex-col tw-absolute tw-h-full tw-w-40px tw-z-20"
                style={{ left: "-40px", top: "-1px" }}
              >
                <PlatformTabs platforms={platforms} onChange={selectPlatform} />
              </div>
              {props.children}
            </div>
          )}
          {/* General errors */}

          {isSummary ? (
            <div className="tw-ml-40px tw-mt-4">
              <Alert type={AlertType.success}>
                Successfully Published.{" "}
                {postLinkToPostUrl && postLinkToPostPlatformLabel && (
                  <a
                    href={postLinkToPostUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View {postLinkToPostPlatformLabel}.
                  </a>
                )}
              </Alert>
            </div>
          ) : (
            <PostFieldError
              className="tw-ml-40px tw-mt-4"
              fields={[FIELDS.POST_STATE, "SAVE_ERROR"]}
              errors={errors}
            />
          )}

          {postState === POST_STATES.ARCHIVED &&
            post.failReason &&
            !Object.keys(errors).length && (
              <Alert type={AlertType.error}>{post.failReason}</Alert>
            )}
        </>
      )}
      renderMetaInfo={() => (
        <>
          <div className="tw-mr-4 tw-flex tw-items-center">
            <PostBy post={postFormValue} />
          </div>
          <PostType
            type={
              postFormValue.isStory && postFormValue.storyType
                ? postFormValue.storyType
                : postFormValue.type
            }
            isInstagram={isInstagram}
          />
        </>
      )}
      renderPreview={() => (
        <PostPreviewTab
          platformPost={platformPost}
          postModel={postModel}
          postFormValue={postFormValue}
          platforms={platforms}
          trackEvent={handleTrackEvent}
        />
      )}
      renderCollaboration={() =>
        collaborationAvailable ? (
          post.newPost ? (
            <EmptyState>
              <img
                src="/assets/img/comments-empty-state.png"
                alt="empty-comment"
                width="100%"
              />
              <p>Add comments after creating</p>
              <span>
                You can add comments to your post or draft after it's created.
              </span>
            </EmptyState>
          ) : (
            <CollaborationChat
              channelId={channelId}
              postState={postState}
              postId={post._id}
              user={user}
              disabled={isSummary}
            />
          )
        ) : (
          <CollaborationIsNotEnabled />
        )
      }
      renderActions={() => (
        <>
          {/* Cancel */}
          <SkedButton type="simple" onClick={onCancel}>
            Cancel
          </SkedButton>

          {/* All other actions */}
          <div className="tw-flex-grow tw-flex tw-items-center tw-justify-end">
            {/* Collab */}

            {/* New posts don't set post status, the existing component does
          a save on change, and there isn't a post to save to yet. */}
            <TooltipWrapper tooltip={tooltipMessage}>
              <div
                data-testid="postCreateStatusChange"
                className="tw-mr-4"
                onClick={() => {
                  if (isUpsellRequiredForApproval(user)) {
                    trackEvent({
                      eventName: UPGRADE_APPROVAL_VIEWED,
                      useServices: ["AMP", "IC", "AC", "HS"],
                    });
                    dispatch(showUpsellModal("upsell", "approvals"));
                  } else if (!arePostStatusesEnabled) {
                    openSupportForEnableApprovals();
                  }
                }}
              >
                <StatusChange
                  post={platformPost}
                  disabled={
                    upsellForApproval
                      ? false
                      : !arePostStatusesEnabled ||
                        (user.role !== "admin" &&
                          user.preferences?.canChangeStatusTo?.length === 0) ||
                        allDisabled
                  }
                  onChange={onStatusChange}
                  originalPostState={originalPostState}
                  selectedStatusKey={
                    !arePostStatusesEnabled ? "readytopublish" : undefined
                  }
                  additionalOptions={
                    !arePostStatusesEnabled
                      ? [
                          {
                            label: "Ready to publish",
                            value: "readytopublish",
                            color: "#67b85c",
                          },
                        ]
                      : undefined
                  }
                />
              </div>
            </TooltipWrapper>
            {/* Action */}
            <div className="sm:tw-mr-0 md:tw-mr-4">
              <PostFormActions
                post={postFormValue}
                initialPostState={formPostState}
                samePostState={originalPostState === postState}
                timePreference={user.preferences.timePreference}
                onChange={updateFields}
                disabled={allDisabled}
                canQueuePosts={canQueuePosts}
                isSummary={isSummary}
              />
            </div>

            {isSummary ? (
              <SkedButton type="primary" onClick={onCancel}>
                Close
              </SkedButton>
            ) : (
              <SkedButton
                data-testid="postSaveButton"
                type="primary"
                disabled={allDisabled || saveDisabled}
                onClick={handlePostSave}
                title={
                  Object.keys(errors)?.length > 0
                    ? "Post cannot be saved as you still have some errors to fix!"
                    : ""
                }
              >
                Save
              </SkedButton>
            )}
          </div>
        </>
      )}
    />
  );
}

const PostPreviewTab = ({
  postModel,
  postFormValue,
  platformPost,
  platforms,
  trackEvent,
}) => {
  const hasManualTTPlatform = findManualPlatform(platforms, "TT");
  const hasManualTHPlatform = findManualPlatform(platforms, "TH");
  const { videoCoverPreview } = platformPost;

  return (
    <ErrorBoundary>
      {postModel && (
        <div data-testid="skedPreview">
          <SkedPreview
            videoCoverPreview={videoCoverPreview}
            post={postModel}
            trackEvent={trackEvent}
            env="schedugram-client"
          />
        </div>
      )}
      {(postFormValue[FIELDS.STORY_TYPE] &&
        postFormValue[FIELDS.STORY_TYPE] !== "story") ||
        ((hasManualTTPlatform ||
          hasManualTHPlatform ||
          ONLY_MANUAL_PLATFORMS.includes(postModel?.platform)) && (
          <div className={`alert alert-info small`}>
            We currently don’t support{" "}
            {getStoryAccountLabel(
              postFormValue[FIELDS.STORY_TYPE],
              platforms,
              postModel?.platform
            )}{" "}
            auto-posting, but the post will be in upcoming posts and you will be
            notified to publish it manually.
          </div>
        ))}
      {!postModel && (
        <div className=" tw-flex tw-items-center tw-justify-end tw-h-300px tw-text-2xl tw-text-sked-500">
          <img
            src="/assets/img/quirky/quirky-glasses.svg"
            alt="quirky"
            className="tw-max-w-full tw-max-h-full"
          />
        </div>
      )}
    </ErrorBoundary>
  );
};

const EmptyState = styled.div`
  width: 280px;
  margin: 60px auto 0;

  img {
    margin-bottom: 12px;
  }

  span,
  p {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    display: block;
    margin-left: 10px;
  }

  p {
    font-weight: 500;
    margin-bottom: 12px;
  }

  span {
    font-weight: 300;
  }
`;
