import {
  INIT_POSTS_PAGE_ROUTINE,
  TOGGLE_POST_CHECKBOX,
  FETCH_POSTS_ROUTINE,
  UPDATE_ACCOUNT_FILTER,
  RELOAD_POSTS_ROUTINE,
  REMOVE_POST_ROUTINE,
  REMOVE_POST_FROM_QUEUE_ROUTINE,
  SEND_POSTS_TO_QUEUE_ROUTINE,
  SEND_POST_TO_QUEUE_ROUTINE,
  SAVE_POST_ROUTINE,
  SEND_POSTS_TO_DRAFT_ROUTINE,
  SEND_POST_TO_DRAFT_ROUTINE,
  SORT_POST_ROUTINE,
  EDIT_POST_URL_ROUTINE,
  SHUFFLE_QUEUE_ROUTINE,
  SHOW_EDIT_POST,
  REMOVE_POSTS_ROUTINE,
  LOAD_MORE_POSTS_ROUTINE,
  TOGGLE_SELECT_ALL_POSTS,
  TOGGLE_COLLABORATE_WIDGET,
  TOGGLE_EXPANDED,
  FILTER_COLLAB_STATUS,
  FILTER_POST_TYPE,
  FILTER_POST_CONTENT_TYPE,
  CHANGE_POSTS_PAGINATION,
  SEND_POST_NOTIFICATION_ROUTINE,
  FETCH_DRAFT_POSTS_ROUTINE,
  UPDATE_POSTS_PAGE_NAME,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

// Posts - General
export const initPostsPage = createAction(
  INIT_POSTS_PAGE_ROUTINE.TRIGGER,
  function prepare(page, selectedAccountIds = null) {
    return {
      payload: {
        page,
        selectedAccountIds,
      },
    };
  }
);
// Posts - General
export const updatePageName = createAction(
  UPDATE_POSTS_PAGE_NAME,
  function prepare(page) {
    return {
      payload: {
        page,
      },
    };
  }
);

export const filterCollabStatus = createAction(
  FILTER_COLLAB_STATUS,
  function prepare(postStatusKey) {
    return {
      payload: {
        postStatusKey,
      },
    };
  }
);

export const filterPostType = createAction(
  FILTER_POST_TYPE,
  function prepare(postType) {
    return {
      payload: {
        postType,
      },
    };
  }
);

export const filterPostContentType = createAction(
  FILTER_POST_CONTENT_TYPE,
  function prepare(postContentTypes) {
    return {
      payload: {
        postContentTypes,
      },
    };
  }
);

export const changePostsPagination = createAction(
  CHANGE_POSTS_PAGINATION.TRIGGER,
  function prepare(page, pageSize) {
    return {
      payload: {
        page,
        pageSize,
      },
    };
  }
);

// Single Post
export const togglePostCheckbox = createAction(
  TOGGLE_POST_CHECKBOX,
  function prepare(postId) {
    return {
      payload: {
        postId,
      },
    };
  }
);

export const updateAccounts = createAction(UPDATE_ACCOUNT_FILTER);

export const fetchPosts = createAction(
  FETCH_POSTS_ROUTINE.TRIGGER,
  function prepare(currentPage, pageSize, selectedAccounts) {
    return {
      payload: {
        currentPage,
        pageSize,
        selectedAccounts,
      },
    };
  }
);

export const sendPostNotification = createAction(
  SEND_POST_NOTIFICATION_ROUTINE.TRIGGER,
  function prepare(postId) {
    return {
      payload: {
        postId,
      },
    };
  }
);

export const reloadPosts = createAction(RELOAD_POSTS_ROUTINE.TRIGGER);

export const showEditPost = createAction(
  SHOW_EDIT_POST,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

export const removePosts = createAction(
  REMOVE_POSTS_ROUTINE.TRIGGER,
  function prepare(postIds) {
    return {
      payload: {
        postIds,
      },
    };
  }
);

export const removePost = createAction(
  REMOVE_POST_ROUTINE.TRIGGER,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

export const removePostFromQueue = createAction(
  REMOVE_POST_FROM_QUEUE_ROUTINE.TRIGGER,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

export const sendPostsToQueue = createAction(
  SEND_POSTS_TO_QUEUE_ROUTINE.TRIGGER,
  function prepare(selectedPostIds) {
    return {
      payload: {
        selectedPostIds,
      },
    };
  }
);

export const sendPostToQueue = createAction(
  SEND_POST_TO_QUEUE_ROUTINE.TRIGGER,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

export const savePost = createAction(
  SAVE_POST_ROUTINE.TRIGGER,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

export const sendPostToDraft = createAction(
  SEND_POST_TO_DRAFT_ROUTINE.TRIGGER,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

export const sendPostsToDraft = createAction(
  SEND_POSTS_TO_DRAFT_ROUTINE.TRIGGER,
  function prepare(selectedPostIds) {
    return {
      payload: {
        selectedPostIds,
      },
    };
  }
);

export const loadMorePosts = createAction(LOAD_MORE_POSTS_ROUTINE.TRIGGER);

export const sortPost = createAction(
  SORT_POST_ROUTINE.TRIGGER,
  function prepare(fromIndex, toIndex) {
    return {
      payload: {
        fromIndex,
        toIndex,
      },
    };
  }
);

export const editPostUrl = createAction(
  EDIT_POST_URL_ROUTINE.TRIGGER,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

// Posts Action Bar
export const toggleSelectAll = createAction(TOGGLE_SELECT_ALL_POSTS);

export const toggleExpanded = createAction(TOGGLE_EXPANDED);

export const shuffleQueue = createAction(
  SHUFFLE_QUEUE_ROUTINE.TRIGGER,
  function prepare(accountIds, form) {
    return {
      payload: {
        accountIds,
        form,
      },
    };
  }
);

export const toggleCollaborateWidget = createAction(
  TOGGLE_COLLABORATE_WIDGET,
  function prepare(post) {
    return {
      payload: {
        post,
      },
    };
  }
);

//Calendar drafts panel
export const fetchDrafts = createAction(
  FETCH_DRAFT_POSTS_ROUTINE.TRIGGER,
  function prepare(currentPage, pageSize, selectedAccounts) {
    return {
      payload: {
        currentPage,
        pageSize,
        selectedAccounts,
      },
    };
  }
);
