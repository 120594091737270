import React from "react";
import { TooltipWrapper } from "../TooltipWrapper";
import styled from "styled-components";
import QuestionMarkIcon from "./QuestionMarkIcon.svg";

interface QuestionIconWithTooltipProps {
  placement?: string;
  className?: string;
  tooltip: string | React.ReactNode;
}

export default function QuestionIconWithTooltip(
  props: QuestionIconWithTooltipProps
) {
  return (
    <TooltipWrapper tooltip={props.tooltip} className={props.className}>
      <StyledQuestionMarkIcon />
    </TooltipWrapper>
  );
}
const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  width: 16px;
  height: 16px;
`;
