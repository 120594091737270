import React, { HTMLAttributes } from "react";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import styled from "styled-components";

import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@dialogueconsulting/sked-ui";

import UserIcon from "assets/icons/user.svg";
import TrashIcon from "assets/icons/trash.svg";
import PencilIcon from "assets/icons/pencil.svg";
import RetryIcon from "assets/icons/retry.svg";
import IgStoryIcon from "assets/icons/ig-story.svg";
import IgReelIcon from "assets/icons/ig-reel.svg";
import IGTVIcon from "assets/icons/igtv.svg";
import VideoIcon from "assets/icons/video.svg";
import IgStoryGradientIcon from "assets/icons/ig-story-grad.svg";
import LibStorage from "assets/icons/lib-storage.svg";
import ChromeIcon from "assets/icons/chrome.svg";
import StockIcon from "assets/icons/stock.svg";
import LightningIcon from "assets/icons/lightning.svg";
import SendPlane from "assets/icons/send-plane.svg";
import CopyIcon from "assets/icons/copy.svg";
import BusinessIcon from "assets/icons/business.svg";
import BurgerIcon from "assets/icons/category-list-view.svg";
import DraftIcon from "assets/icons/calendar/draft.svg";

import CrossIcon from "assets/icons/cross.svg";
import InfoIcon from "assets/icons/info.svg";
import InfoLightIcon from "assets/icons/info-light.svg";
import WarningIcon from "assets/icons/warning.svg";
import SuccessIcon from "assets/icons/success.svg";
import ExclamRoundIcon from "assets/icons/exclam-round.svg";
import HorizontalDividerCheckIcon from "assets/icons/horizontal-divider-check.svg";

import ItemSave from "assets/icons/item-save.svg";
import ItemSaveWhiteOutline from "assets/icons/item-save-white-outline.svg";
import ItemEdit from "assets/icons/item-edit.svg";
import ItemRemove from "assets/icons/item-remove.svg";
import TickCircleOutline from "assets/icons/tick-circle-outline.svg";
import RightToBracket from "assets/icons/right-to-bracket.svg";
import XCircle from "assets/icons/x-circle.svg";

import "styles/icons.less";
import "./SkedIcon.less";

export interface SkedIconProps {
  icon: string;
  size?: string | number;
  color?: string;
  className?: string;
  placement?: "top" | "bottom" | "left" | "right";
  tooltip?: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  style?: HTMLAttributes<HTMLSpanElement>;
  ariaLabel?: string;
  testId?: string;
}

const SkedIcon = (props: SkedIconProps) => {
  const {
    icon,
    size,
    color,
    className,
    tooltip,
    placement = "top",
    onClick,
    disabled = false,
    style = {},
    ariaLabel,
  } = props;

  const Svg = getSvg(icon);

  let modifiers = className !== undefined ? className : "";
  if (!size) {
    modifiers += ` SkedIcon_size_14`;
  }

  if (size) {
    modifiers += ` SkedIcon_size_${size}`;
  }

  if (color && !disabled) {
    modifiers += ` SkedIcon_color_${color}`;
  }

  if (disabled) {
    modifiers += ` SkedIcon_color_gray`;
  }

  // need to figure out a better way to do this.
  if (icon !== "igStoryGradient") {
    modifiers += " SkedIcon_color_default";
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  let iconJsx = (
    <span
      onClick={handleClick}
      className={`SkedIcon icon ${icon} ${modifiers}`}
      aria-label={ariaLabel}
      {...(props.testId ? { "data-testid": props.testId } : {})}
    />
  );

  // Rely on the default (global) css found in icons.less
  if (Svg) {
    iconJsx = (
      <span
        style={style}
        onClick={handleClick}
        className={`SkedIcon icon ${modifiers}`}
        aria-label={ariaLabel}
        {...(props.testId ? { "data-testid": props.testId } : {})}
      >
        {Svg}
      </span>
    );
  }

  if (tooltip && placement) {
    const tooltipJsx = <Tooltip id={icon}>{tooltip}</Tooltip>;

    return (
      <OverlayTrigger placement={placement} overlay={tooltipJsx}>
        {iconJsx}
      </OverlayTrigger>
    );
  }
  return iconJsx;
};

SkedIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
};

const getSvg = (icon: string) => {
  switch (icon) {
    case "user":
      return <UserIcon />;
    case "draft":
      return <DraftIcon />;
    case "video":
      return <VideoIcon />;
    case "trash":
      return <TrashIcon />;
    case "pencil":
      return <PencilIcon />;
    case "retry":
      return <RetryIcon />;
    case "igStory":
      return <IgStoryIcon />;
    case "igReel":
      return <IgReelIcon />;
    case "igtv":
      return <IGTVIcon />;
    case "igStoryGradient":
      return <IgStoryGradientIcon />;
    case "chromeIcon":
      return <ChromeIcon />;
    case "stockIcon":
      return <StockIcon />;
    case "libStorage":
      return <LibStorage />;
    case "chevronLeft":
      return <FontAwesomeIcon icon={faChevronLeft} />;
    case "chevronRight":
      return <FontAwesomeIcon icon={faChevronRight} />;
    case "chevronDown":
      return <FontAwesomeIcon icon={faChevronDown} />;
    case "chevronUp":
      return <FontAwesomeIcon icon={faChevronUp} />;
    case "lightning":
      return <LightningIcon />;
    case "send-plane":
      return <SendPlane />;
    case "copy":
      return <CopyIcon />;
    case "warning":
      return <WarningIcon />;
    case "warningNew":
      return <WarningIcon />;
    case "success":
      return <SuccessIcon />;
    case "info":
      return <InfoIcon />;
    case "info-light":
      return <InfoLightIcon />;
    case "error":
      return <CrossIcon />;
    case "business":
      return <BusinessIcon />;
    case "burger":
      return <BurgerIcon />;
    case "exclam-round":
      return <ExclamRoundIcon />;
    case "horizontal-check":
      return <HorizontalDividerCheckIcon />;
    case "check":
      return <FontAwesomeIcon icon={faCheck} />;
    case "close":
      return <FontAwesomeIcon icon={faTimes} />;
    case "item-save":
      return <ItemSave />;
    case "item-save-white-outline":
      return <ItemSaveWhiteOutline />;
    case "item-edit":
      return <ItemEdit />;
    case "item-remove":
      return <ItemRemove />;
    case "tick-circle-outline":
      return <TickCircleOutline />;
    case "right-to-bracket":
      return <RightToBracket />;
    case "x-circle":
      return <XCircle />;
    case "icon-post-document":
      return <SkedUiIconStyled name="file-06" />;
    default:
      return null;
  }
};

const SkedUiIconStyled = styled((props) => <Icon {...props} />)`
  width: 20px !important;
  height: 20px !important;

  path[stroke] {
    stroke: #b3b3b3;
    fill: white;
  }
`;

export default SkedIcon;
