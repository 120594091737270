import { loadUrlAsBlob } from "libs/files";

import { skedApi } from "../_skedApi";
import { FileToUpload, UploadingStatus } from "./types";
import { transcodeVideoByUrl } from "./transcode-video-by-url";
import { uploadFileToS3 } from "./upload-file-to-s3";
import { CollectionFile } from "shared/types/files";

export async function uploadFileToCollection({
  collectionId,
  file,
}: {
  file:
    | { url: string; name: string }
    | { transcodingJobId: number; name: string };
  collectionId: string;
}) {
  if ("transcodingJobId" in file) {
    return await uploadFromTranscodingJob({ collectionId, file });
  } else {
    return await uploadFromS3({ collectionId, file });
  }
}

async function uploadFromTranscodingJob({
  collectionId,
  file,
}: {
  collectionId: string;
  file: { transcodingJobId: number; name: string };
}) {
  const { data } = await skedApi.post<CollectionFile>(
    `/content-inbox/inbox/${collectionId}/upload/from-transcoding-job`,
    {
      transcoding: {
        jobId: file.transcodingJobId,
        originalFilename: file.name,
      },
    }
  );
  return data;
}

async function uploadFromS3({
  collectionId,
  file,
}: {
  file: { url: string; name: string };
  collectionId: string;
}) {
  const { data } = await skedApi.post<CollectionFile>(
    `/content-inbox/inbox/${collectionId}/upload/from-s3`,
    {
      s3: {
        url: file.url,
        originalFilename: file.name,
      },
    }
  );
  return data;
}

interface FileUploadParams {
  onProgress(options: { value: number; status: UploadingStatus }): void;
}

export async function uploadFile(
  file: FileToUpload,
  { onProgress }: FileUploadParams
): Promise<{ url: string } | { transcodingJobId: number }> {
  if (file.type === "image" || file.type === "document") {
    const blob = await blobFromFileToUpload(file, {
      onProgress: (value) => {
        onProgress({ value: value / 2, status: UploadingStatus.uploading });
      },
    });

    const uploadedFileUrl = await uploadFileToS3(
      { blob, fileName: file.name },
      {
        onProgress(progress) {
          onProgress({
            value: file.contentType === "url" ? progress / 2 : progress,
            status: UploadingStatus.uploading,
          });
        },
      }
    );

    onProgress({
      value: 100,
      status: UploadingStatus.uploading,
    });
    return { url: uploadedFileUrl };
  } else {
    // video upload
    if (file.contentType === "blob" || file.contentType === "getter") {
      const blob = await blobFromFileToUpload(file, {
        onProgress: () => undefined,
      });
      const uploadedFileUrl = await uploadFileToS3(
        { blob, fileName: file.name },
        {
          onProgress(value) {
            onProgress({ value, status: UploadingStatus.uploading });
          },
        }
      );
      const transcodingJobId = await transcodeVideoByUrl(uploadedFileUrl, {
        onProgress: (value) =>
          onProgress({ value, status: UploadingStatus.transcoding }),
      });
      onProgress({ value: 100, status: UploadingStatus.transcoding });
      return { transcodingJobId };
    } else {
      const transcodingJobId = await transcodeVideoByUrl(file.url, {
        onProgress: (value) =>
          onProgress({ value, status: UploadingStatus.transcoding }),
      });
      onProgress({ value: 100, status: UploadingStatus.transcoding });
      return { transcodingJobId };
    }
  }
}

async function blobFromFileToUpload(
  file: FileToUpload,
  { onProgress }: { onProgress: (val: number) => void }
): Promise<Blob> {
  switch (file.contentType) {
    case "blob":
      return file.blob;
    case "getter":
      return await file.getImage();
    case "url":
      return await loadUrlAsBlob(file.url, { onProgress });
  }
}
