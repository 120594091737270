import React from "react";
import PropTypes from "prop-types";
import "./PostDetails.less";

import { InstagramDetails } from "./instagram-details";
import { OtherDetails } from "./other-details";
import { GoogleMyBusinessDetails } from "./google-my-business-details";
import { ClusterPostDetails } from "./cluster-post-details";
import { TwitterDetails } from "./twitter-details";
import { LinkedInDetails } from "./linkedIn-details";
import { FacebookDetails } from "./facebook-details";
import { YouTubeDetails } from "./youtube-details";
import { TikTokDetails } from "./tiktok-details";
import { PinterestDetails } from "./pinterest-details";
import { ThreadsDetails } from "./threads-details";

import { getPlatformName } from "utils/accounts";
import { get } from "lodash";
import { FIELDS, FIELDS_DEFAULT } from "constants/PostFormFields";

function PostDetails({ post, accounts, page }) {
  if (accounts.length > 1) {
    return <ClusterPostDetails post={post} accounts={accounts} page={page} />;
  } else if (accounts.length === 1) {
    const platformName =
      accounts[0].platformName || getPlatformName(accounts[0].plaformType);
    switch (platformName) {
      case "instagram":
        return (
          <InstagramDetails post={post} account={accounts[0]} page={page} />
        );
      case "linkedin":
        return (
          <LinkedInDetails post={post} account={accounts[0]} page={page} />
        );
      case "facebook":
        return (
          <FacebookDetails post={post} account={accounts[0]} page={page} />
        );
      case "twitter":
        return <TwitterDetails post={post} account={accounts[0]} page={page} />;
      case "googlemybusiness":
        return (
          <GoogleMyBusinessDetails
            post={post}
            account={accounts[0]}
            page={page}
          />
        );
      case "youtube":
        return <YouTubeDetails post={post} account={accounts[0]} page={page} />;
      case "tiktok": {
        const isNotification = get(
          post,
          FIELDS.POST_IS_NOTIFICATION_ONLY,
          FIELDS_DEFAULT.IS_NOTIFICATION_POST_ONLY_DEFAULT
        );

        const isOldTTAccount = !accounts[0].platformId;

        if (isNotification || isOldTTAccount) {
          return <OtherDetails post={post} account={accounts[0]} page={page} />;
        }

        return <TikTokDetails post={post} account={accounts[0]} page={page} />;
      }
      case "threads": {
        const isNotificationPost = get(
          post,
          FIELDS.POST_IS_NOTIFICATION_ONLY,
          FIELDS_DEFAULT.IS_NOTIFICATION_POST_ONLY_DEFAULT
        );

        const isOldNotificationAccount = !accounts[0].platformId;

        if (isNotificationPost || isOldNotificationAccount) {
          return (
            <OtherDetails
              post={post}
              account={accounts[0]}
              page={page}
              accountHasPostingAvailable={true}
            />
          );
        }

        return <ThreadsDetails post={post} account={accounts[0]} page={page} />;
      }
      case "pinterest":
        return (
          <PinterestDetails post={post} account={accounts[0]} page={page} />
        );
      case "snapchat":
      case "other":
        return <OtherDetails post={post} account={accounts[0]} page={page} />;
      default:
        return null;
    }
  } else {
    console.error("No accounts for post", post._id, post, accounts);
    return null;
  }
}

PostDetails.propTypes = {
  post: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  page: PropTypes.string,
  user: PropTypes.object,
  index: PropTypes.number,
};

export default PostDetails;
