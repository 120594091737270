import React from "react";
import styled, { css, createGlobalStyle } from "styled-components";

import GridMediaCard from "components/MediaLibrary/GridMediaCard/GridMediaCard";
import { PostMediaEditing } from "features/post-media-file-editing";

import { ConfirmRequired } from "ui/confirm-required";
import { SkedIcon } from "ui";

import { PostAssetProps } from "./post-selected-assets";
import { PostMediaFile } from "shared/types/post";

interface PostMediaEditingProps {
  onEdit(asset: PostAssetProps): void;
}

interface PostSelectedAssetProps {
  asset: PostAssetProps;
  className?: string;
  onEdit(file: PostMediaFile): void;
  onRemove(): void;
  draggableProps: {
    innerRef: React.ReactNode;
    dragHandleProps: any;
    draggableProps: any;
  };
  disabled?: boolean;
}

export const PostSelectedAsset = (props: PostSelectedAssetProps) => {
  const {
    asset,
    className = "",
    onEdit,
    onRemove,
    draggableProps,
    disabled,
  } = props;

  return (
    <AssetContainer
      className={className}
      ref={draggableProps.innerRef}
      {...draggableProps.dragHandleProps}
      {...draggableProps.draggableProps}
      disabled={disabled}
    >
      <AssetMediaCard
        key={asset.url} // mediaFile has no _id, use url instead
        assetUrl={asset.thumbnailUrl || asset.downloadUrl}
        isVideo={asset.isVideo}
        videoPreviewUrl={asset.gifThumbnail}
        favorited={asset.favorite}
        canFavorite={false}
        canSelect={false}
        disabled={disabled}
      />
      <Actions>
        {((asset.type && !asset.type.includes("video")) || !asset.isVideo) && (
          <PostMediaEditing onChange={onEdit}>
            {({ onEdit }: PostMediaEditingProps) => {
              const isImageEditable = !["heif", "heic"].includes(
                asset?.meta?.type || ""
              );

              return (
                <EditButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    /**
                     * We can't use HEIC/HEIF files for editing, due to restriction from the editor SDK.
                     */
                    if (!isImageEditable) {
                      return;
                    }
                    onEdit(asset);
                  }}
                  disabled={!isImageEditable}
                >
                  <SkedIcon
                    icon="icon-pencil"
                    color="purple"
                    tooltip={
                      isImageEditable
                        ? "Open image editor"
                        : "To edit, convert this image from a HEIC file."
                    }
                    placement="top"
                  />
                </EditButton>
              );
            }}
          </PostMediaEditing>
        )}
        <ConfirmRequiredStyled />
        <ConfirmRequired
          dialogClassName="confirm-remove-post-media"
          title="Remove Post Media"
          onConfirm={onRemove}
          confirmText="Remove"
          cancelText="Close"
          message="Are you sure you want to remove this media from the post?"
        >
          {({ onClick }) => (
            <RemoveButton onClick={onClick}>
              <SkedIcon
                icon="trash"
                color="red"
                tooltip={
                  asset.type === "video" ? "Remove video" : "Remove image"
                }
                placement="bottom"
              />
            </RemoveButton>
          )}
        </ConfirmRequired>
      </Actions>
    </AssetContainer>
  );
};

const AssetMediaCard = styled<any>(GridMediaCard)`
  display: inline-block;
  height: 52px;
  width: 52px;
  margin: 0;

  ${(props) =>
    props.disabled &&
    `
  .tw-cursor-pointer {
    cursor: not-allowed !important;
  }
  `}
`;

const ConfirmRequiredStyled = createGlobalStyle`
  .confirm-remove-post-media {
    max-width: 500px;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 32px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  z-index: 1;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border: 1px solid #dcdcdc;
  overflow: hidden;
`;
const StyledButton = css`
  display: flex;
  width: 30px;
  height: 100%;
  min-height: 25px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0;
  background: transparent;
`;
const RemoveButton = styled.button`
  ${StyledButton}
  &:hover {
    background-color: #ffdede;
  }
`;
const EditButton = styled.button`
  ${StyledButton}
  &:hover {
    background-color: #dee7ff;
  }
`;

const AssetContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  position: relative;
  ${Actions} {
    display: none;
  }

  ${(props) =>
    !props.disabled &&
    `
  :hover {
    ${Actions} {
      display: flex;
    }
  }

  `}
`;
