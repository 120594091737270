export const messageDateFormat24H = "d MMM, HH:mm";

const messageDateFormat12H = "d MMM, hh:mm aaa";

export const getDateFormat = (preferences: {
  timePreference: string;
}): string => {
  return preferences.timePreference === "12h"
    ? messageDateFormat12H
    : messageDateFormat24H;
};
