import React from "react";
import { MessageType } from "types/conversationInbox";
import { FbComment } from "./fb-comment";
import { FbMessage } from "./fb-message";
import { IgMessage } from "./ig-message";
import { IgStoryMention } from "./ig-story-mention";
import { IgStoryReply } from "./ig-story-reply";
import { LiComment } from "./li-comment";
import { NormalMessage } from "./normal-message";
import { TtComment } from "./tt-comment";
import { renderContentProps } from "./types";

export const renderContent = (props: renderContentProps): JSX.Element => {
  /** message is deleted, we just want render simple message */
  if (props.originalMessage?.deleted) {
    return <NormalMessage {...props} />;
  }

  switch (props.type) {
    case MessageType.igStoryMention: {
      return <IgStoryMention {...props} />;
    }

    case MessageType.igStoryReply: {
      return <IgStoryReply {...props} />;
    }

    case MessageType.fbMessage: {
      return <FbMessage {...props} />;
    }

    case MessageType.igMessage: {
      return <IgMessage {...props} />;
    }
    case MessageType.fbThreadedComment:
    case MessageType.fbComment: {
      return <FbComment {...props} />;
    }
    case MessageType.liThreadedComment:
    case MessageType.liComment:
      return <LiComment {...props} />;

    case MessageType.ttThreadedComment:
      return <TtComment {...props} />;
    default:
      return <NormalMessage {...props} />;
  }
};
