import React from "react";
import styled, { css } from "styled-components";
import SkedIcon from "../SkedIcon/SkedIcon";
import { Collapse } from "../collapse";
import { useOpenableState } from "libs/use-openable-state";

export enum Size {
  small = "small",
  large = "large",
}

export enum Type {
  success = "success",
  error = "error",
  info = "info",
  warning = "warning",
  warningNew = "warningNew",
}

interface Props {
  className?: string;
  title?: string;
  size?: Size;
  type?: Type;
  children: React.ReactNode; // children for the expanding section if it is expanding!
  isExpandable?: boolean;
  defaultExpanded?: boolean; // whether the expandable alert should default open (true) or closed
  icon?: boolean; // whether to show an icon or not (defaults true)
  dataTestId?: string;
}

function AlertComponent({
  className,
  children,
  size = Size.small,
  type = Type.info,
  title,
  icon = true,
  isExpandable = false,
  defaultExpanded = false,
  dataTestId,
}: Props) {
  const alert = useOpenableState(defaultExpanded);

  // "complex" alert boxes have a title and also children (body)
  if (title && children) {
    return (
      <ComplexAlertRoot
        data-testid={dataTestId}
        className={className}
        size={size}
        type={type}
      >
        <HeaderRoot onClick={alert.toggle}>
          <ComplexTitleRoot>
            {icon && (
              <IconWrapper>
                <StyledIcon icon={type} size={"18"} color={type} />
              </IconWrapper>
            )}
            <ComplexTitleText>{title}</ComplexTitleText>
          </ComplexTitleRoot>
          {isExpandable && (
            <ChevronIcon
              icon={alert.isOpened ? "chevronUp" : "chevronDown"}
              color={type}
            />
          )}
        </HeaderRoot>
        {/*Body: */}
        {isExpandable ? (
          <CollapseRoot isOpened={alert.isOpened}>
            <AlertBody>{children}</AlertBody>
          </CollapseRoot>
        ) : (
          <AlertBody>{children}</AlertBody>
        )}
      </ComplexAlertRoot>
    );
  }
  // just got a title - show title text formatted in similar style
  if (title && !children) {
    return (
      <ComplexAlertRoot
        data-testid={dataTestId}
        className={className}
        size={size}
        type={type}
      >
        <HeaderRoot>
          <ComplexTitleRoot>
            {icon && (
              <IconWrapper>
                <StyledIcon icon={type} size={"18"} color={type} />
              </IconWrapper>
            )}
            <ComplexTitleText>{title}</ComplexTitleText>
          </ComplexTitleRoot>
        </HeaderRoot>
      </ComplexAlertRoot>
    );
  }
  // "simple" alert boxes do not have a title and instead just a body
  return (
    <AlertRoot
      data-testid={dataTestId}
      className={className}
      size={size}
      type={type}
    >
      {icon && (
        <IconWrapper>
          <StyledIcon icon={type} size={"18"} color={type} />
        </IconWrapper>
      )}
      <AlertTitle>{children}</AlertTitle>
    </AlertRoot>
  );
}

const StyledIcon = styled(SkedIcon)``;

const IconWrapper = styled.div`
  padding-right: 8px;
  align-items: flex-start;
  display: flex;
`;
const HeaderRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 7px;
`;
const ChevronIcon = styled(SkedIcon)`
  margin-right: 15px;
`;
const CollapseRoot = styled(Collapse)``;

const AlertBody = styled.div`
  background-color: var(--expandedColor) !important;
  color: #333333;
  padding: 10px 16px;
`;

const ComplexTitleRoot = styled.div`
  display: flex;
`;

export const Action = styled.a`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  display: inline-block;
  cursor: pointer;
  text-decoration: underline;

  &:disabled,
  &:disabled:hover {
    background: transparent !important;
    color: #b3b3b3 !important;
    text-decoration: none;
  }
`;

const AlertRoot = styled.div<{ size: Size; type: Type }>`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  border: none;
  font-weight: 300;
  border-radius: 5px;
  margin: 0 0 10px 0;
  ${(props) => props.expandable === true && css``};

  ${(props) =>
    props.size === Size.small &&
    css`
      padding: 10px 7px;
      font-size: 14px;
      line-height: 17px;
      p {
        font-size: 14px;
        line-height: 17px;
      }
    `};

  ${(props) =>
    props.size === Size.large &&
    css`
      padding: 19px 23px;
      font-size: 16px;
      line-height: 17px;
    `}

  ${(props) =>
    props.type === Type.info &&
    css`
      --alertColor: #4f31ff;
      --expandedColor: #fbfbff;
      background: #f6f5ff;
    `}

  ${(props) =>
    props.type === Type.success &&
    css`
      --alertColor: #66b913;
      --expandedColor: #fafff5;
      background: #f0f8e7;
    `}

  ${(props) =>
    props.type === Type.error &&
    css`
      --alertColor: #fa4f38;
      --expandedColor: #fff8f6;
      background: #fef1ef;
    `}

  ${(props) =>
    props.type === Type.warning &&
    css`
      --alertColor: #de6716;
      --expandedColor: #fefbf9;
      background: #fdf4ee;
    `}

  ${(props) =>
    props.type === Type.warningNew &&
    css`
      --alertColor: #d97706;
      --expandedColor: #fefbf9;
      background: var(--yellow-y-50, #fffbeb);
    `}

  color: var(--alertColor);

  a,
  ${Action} {
    color: var(--alertColor);
    text-decoration: underline;
  }
  ${StyledIcon} {
    color: var(--alertColor);
    cursor: auto;
    > g,
    path {
      fill: var(--alertColor);
    }
  }
`;

const AlertTitle = styled.div`
  font-weight: 500;
`;

const ComplexAlertRoot = styled(AlertRoot)`
  margin: 0 0 10px 0;
  padding: 0;
  flex-direction: column;
`;

export const ComplexTitleText = styled(AlertTitle)`
  display: flex;
`;

export const Title = styled.div`
  margin-top: 0;
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.015em;
`;

export const Alert = styled(AlertComponent)``;
