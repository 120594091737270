import React, { useState, useEffect } from "react";
import { TextInput } from "ui";
import styled from "styled-components";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { useStorageDeletedRecentUser } from "libs/storage/adapters";

import AccountPickerDropdown from "components/AccountPicker/AccountPickerDropdown";

import { initCreatorFeed, searchCreator } from "actions/sourceMedia";
import { getAccountsForPlatform } from "selectors/skedCoreSelectors";
import { selectIgAccount } from "actions/sourceMedia";
import { handleError } from "utils/handleError";
import {
  getRecentSearchCreators,
  getSelectedCreator,
  getSelectedIgAccount,
} from "selectors/hashtagFeedSelectors";
import { Button, FormSize, FormThemeProvider } from "ui/ecosystems/forms";
import { useTrackEvent } from "libs/analytics";
import { LIBRARY_SEARCH_INFLUENCER } from "constants/Events";
import { getLoading, getRoutinesLoading } from "selectors/commonSelectors";
import {
  SEARCH_CREATOR,
  SELECT_RECENT_SEARCH_CREATORS,
} from "constants/ActionTypes";
import { InstagramUser } from "features/media-library/components/media-picker/types";

const NO_ACCOUNTS_MESSAGE = "Connect instagram account first";

export function useSearchIgCreators() {
  const dispatch = useDispatch();
  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const recentCreators: InstagramUser[] = useSelector(getRecentSearchCreators);
  const selectedCreator = useSelector(getSelectedCreator);
  const [deletedCreatorIds, setDeletedCreatorIds] =
    useStorageDeletedRecentUser(null);

  const filteredCreators = React.useMemo(
    () =>
      recentCreators.filter((user) => !deletedCreatorIds?.includes(user._id)),
    [recentCreators, deletedCreatorIds]
  );

  const loading = useSelector(getLoading);
  const loadingCreators = useSelector((state) =>
    getRoutinesLoading(state, [SELECT_RECENT_SEARCH_CREATORS, SEARCH_CREATOR])
  );

  const promisifyAction = (action, args) => {
    return new Promise((resolve) => {
      dispatch(action(...args, resolve));
    });
  };

  const requestCreator = async (searchQuery: string) => {
    let searchedCreator: InstagramUser | undefined;

    if (searchQuery && selectedIgAccount?._id) {
      try {
        searchedCreator = (await promisifyAction(searchCreator, [
          selectedIgAccount._id,
          searchQuery,
        ])) as InstagramUser | undefined;

        if (!searchedCreator) {
          throw new Error("Can not get user result");
        }

        const updatedCreators = _.cloneDeep(recentCreators);
        updatedCreators.pop();
        updatedCreators.unshift(searchedCreator);

        //remove newly added id from deletedUserIds if any
        if (deletedCreatorIds) {
          setDeletedCreatorIds(
            deletedCreatorIds?.filter((id) => id !== searchedCreator?._id)
          );
        }
      } catch (error) {
        handleError(
          error,
          `Could not get information for ${searchQuery} - this user does not exist, or is not a Business or Creator account.`
        );
      }
    }

    return searchedCreator;
  };

  const handleRemoveCreator = (userId: string) => {
    setDeletedCreatorIds(
      deletedCreatorIds ? [...deletedCreatorIds, userId] : [userId]
    );
  };

  return {
    isLoading: loading || loadingCreators,
    recentCreators: filteredCreators,
    selectedCreator,
    requestCreator,
    handleRemoveCreator,
  };
}

function CreatorSearchForm() {
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const { requestCreator, isLoading, recentCreators, handleRemoveCreator } =
    useSearchIgCreators();

  const [searchInputText, setSearchInputText] = useState("");
  const [highlightedCreatorId, setHighlightedCreatorId] = useState<string>();

  const openCreatorContent = (user: InstagramUser) => {
    setHighlightedCreatorId(user._id);
    dispatch(initCreatorFeed({ user, accountId: selectedIgAccount._id }));
  };

  const handleSearchCreator = useDebouncedCallback(async () => {
    trackEvent({
      eventName: LIBRARY_SEARCH_INFLUENCER,
      useServices: ["AMP"],
      metadata: { location: "library" },
    });
    const searchedCreator = await requestCreator(searchInputText);
    setSearchInputText("");
    searchedCreator && openCreatorContent(searchedCreator);
  }, 800);

  return (
    <FormWrapper>
      <FormThemeProvider size={FormSize.large}>
        <TextInput
          placeholder="Search creator"
          disabled={!selectedIgAccount}
          value={selectedIgAccount ? searchInputText : NO_ACCOUNTS_MESSAGE}
          onChange={(value: string) => {
            setSearchInputText(value);
          }}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
              handleSearchCreator();
            }
          }}
        />
        <SearchCreatorButton primary onClick={handleSearchCreator}>
          Search
        </SearchCreatorButton>
      </FormThemeProvider>
      {isLoading ? (
        <Loader>Loading…</Loader>
      ) : (
        <Users>
          {recentCreators.map((user) => (
            <User
              key={user._id}
              onClick={() => {
                openCreatorContent(user);
              }}
              selected={highlightedCreatorId === user._id}
            >
              <img
                src={user.data?.profile_picture_url}
                onError={(e) => {
                  // @ts-ignore
                  e.target.onError = null;
                  // @ts-ignore
                  e.target.src = `https://imggen.skedsocial.com/api/v3/${user.userName
                    .charAt(0)
                    .toUpperCase()}.jpg?target=profile`;
                }}
                alt={`Avatar for ${user.userName}`}
              />
              <p>{user.userName}</p>
              <RemoveUser
                selected={highlightedCreatorId === user._id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveCreator(user._id);
                }}
              >
                x
              </RemoveUser>
            </User>
          ))}
        </Users>
      )}
    </FormWrapper>
  );
}

const UserSelector = (): JSX.Element => {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => getAccountsForPlatform(state, "IG"));
  const selectedIgAccount = useSelector(getSelectedIgAccount);

  useEffect(() => {
    if (!selectedIgAccount && accounts?.length > 0) {
      dispatch(selectIgAccount(accounts[0]._id));
    }

    if (selectedIgAccount) {
      dispatch(initCreatorFeed({ accountId: selectedIgAccount._id }));
    }
  }, [selectedIgAccount]);

  const selectInstaAccount = (instaAccount: InstagramUser) => {
    dispatch(selectIgAccount(instaAccount._id));
  };

  return (
    <Container>
      <div css={{ marginTop: "5px", marginBottom: "10px" }}>
        <div css={{ marginBottom: "5px" }}>Select an Instagram account:</div>
        <AccountPickerDropdown
          accounts={accounts}
          onChange={selectInstaAccount}
          selectedAccountIds={selectedIgAccount ? [selectedIgAccount._id] : []}
        />
        <CreatorSearchForm />
      </div>
    </Container>
  );
};

const Users = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;
const Loader = styled.p`
  margin: 1rem 0;
`;

const RemoveUser = styled.span<{ selected: boolean }>`
  color: ${(props) => (props.selected ? "white" : "red")};
  font-size: 16px;
  margin-left: auto;
  display: block;
  padding-right: 15px;
`;

const User = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 -2rem;
  padding: 1rem;
  cursor: pointer;
  p {
    margin: 0;
    color: ${({ selected }) => (selected ? "#fff" : "#333")};
  }
  background-color: ${({ selected }) =>
    selected ? "rgb(102, 98, 255)" : "inital"};
  :hover {
    background-color: rgb(102, 98, 255);
    p {
      color: #fff;
    }
    ${RemoveUser} {
      color: white;
    }
  }
  img {
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }
`;
const Container = styled.div`
  margin: 1rem 2rem;
`;

const SearchCreatorButton = styled(Button)`
  margin-left: auto;
  margin-top: 10px;
  height: 44px;
  font-size: 12px;
`;

const FormWrapper = styled.div`
  margin-top: 10px;
`;

export default UserSelector;
