import React from "react";
import styled from "styled-components";

import { openSupportChat } from "libs/support";
import { ModalActions, ModalContent, ModalHeader } from "ui/giselle-modal";
import { FormSize, SubmitButton } from "ui/ecosystems/forms";
import { Link } from "ui/Link";
import { Plan } from "api/plan-change";
import { PlatformIcon } from "entities/accounts";
import { LoadingCover } from "ui/cover";
import { useCurrentUser, useUserCurrency } from "entities/current-user";

import { Dots } from "./dots";
import { usePlans } from "../models/plans.model";
import { Alert } from "../../../ui";
import { AlertType } from "../../../ui/alert";

interface PlanSelectionProps {
  selectedPlan: { planId: string; plan: Plan; isAnnual: boolean };
  isLoadingEstimate: boolean;
  onHide: () => void;
  className?: string;

  onSelectPlan(p: { planId: string; plan: Plan; isAnnual: boolean }): void;

  onLoadEstimate(p: { planId: string; plan: Plan; isAnnual: boolean }): void;
}

const NUMBER_OF_SKELETON_PLANS = 4;

export const PlanSelection = ({
  selectedPlan,
  onSelectPlan,
  onLoadEstimate,
  isLoadingEstimate,
  onHide,
  className,
}: PlanSelectionProps) => {
  const { plans, isLoading: isLoadingPlans } = usePlans();

  const user = useCurrentUser();

  const handleSelect = (plan: Plan, annual: boolean) => {
    if (plan.tooManyAccounts) {
      alert(
        "Sorry, you can't change to this plan as you have too many accounts."
      );
      return;
    }
    // NOTE: block selection of monthly tile of the current plan (still can select annual tile)
    if (plan.currentPlan && !annual) {
      return;
    }
    // NOTE: block selection of both monthly and annual tiles of the current plan when user is already on annual
    if (plan.currentPlan && !plan.isMonthly) {
      return;
    }
    onSelectPlan({
      planId: annual ? plan.primaryCbId.annual : plan.primaryCbId.monthly,
      plan,
      isAnnual: annual,
    });
  };

  const isTrial =
    !user?.subscription.subscription ||
    user?.subscription.subscription.status === "in_trial";

  return (
    <Root className={className}>
      <ModalHeader
        title="Choose a plan"
        subtitle={
          isTrial
            ? "Choose the plan you'd like to use on your trial. You can change plans at any time during the trial on the profile settings page."
            : "Choose the plan you'd like to change to. We'll confirm the details of the change (including any price changes if applicable) in the next step."
        }
      />
      <SyledModalContent>
        {user.pricingVersion && user.pricingVersion === "012023" ? (
          <Alert
            type={AlertType.info}
            title={`We're currently upgrading our self-service plan upgrade system.`}
            defaultExpanded={true}
          >
            <p>
              In the meantime, please{" "}
              <Link onClick={() => openSupportChat()}>
                contact support via live chat
              </Link>{" "}
              and we will change plans for you right away.
            </p>
          </Alert>
        ) : (
          <LoadingCover isLoading={isLoadingPlans}>
            <Table>
              <Row>
                <FirstColumn>
                  <Cell>Plan breakdown</Cell>
                  <Cell>
                    <Icon type="instagram" />
                    IG accounts
                  </Cell>
                  <Cell>
                    Other social accounts
                    <IconContainer>
                      <Icon type="facebook" />
                      <Icon type="googlemybusiness" />
                      <Icon type="pinterest" />
                      <Icon type="youtube" />
                      <Icon type="twitter" />
                      <Icon type="linkedin" />
                    </IconContainer>
                  </Cell>
                  <Cell>Monthly price</Cell>
                </FirstColumn>

                {plans
                  ? plans.map((plan: Plan, index: number) => (
                      <MonthlyPlanCol
                        key={index}
                        plan={plan}
                        isSelected={(plan) =>
                          selectedPlan.plan._id === plan._id &&
                          !selectedPlan.isAnnual
                        }
                        isDisabled={(plan) => plan.tooManyAccounts}
                        onSelect={(plan) => handleSelect(plan, false)}
                      />
                    ))
                  : Array(NUMBER_OF_SKELETON_PLANS)
                      .fill(undefined)
                      .map((_, index) => (
                        <MonthlyPlanColSkeleton key={index} />
                      ))}
              </Row>

              <Row>
                <FirstColumn></FirstColumn>
                <AnnualHeader
                  plansCount={plans ? plans.length : NUMBER_OF_SKELETON_PLANS}
                >
                  <DecorLine />
                  <span>
                    Or change to annual billing and get 2 months free!
                  </span>
                  <DecorLine />
                </AnnualHeader>
              </Row>

              <Row>
                <FirstColumn>
                  <Cell>
                    Annual price
                    <br />
                    <span>Monthly equivalent</span>
                  </Cell>
                </FirstColumn>

                {plans
                  ? plans.map((plan, index) => (
                      <AnnualPlanCol
                        key={index}
                        plan={plan}
                        isCurrent={(plan) =>
                          Boolean(plan.currentPlan && !plan.isMonthly)
                        }
                        isSelected={(plan) =>
                          selectedPlan.plan._id === plan._id &&
                          selectedPlan.isAnnual
                        }
                        isDisabled={(plan) => plan.tooManyAccounts}
                        onSelect={(plan) => handleSelect(plan, true)}
                      />
                    ))
                  : Array(NUMBER_OF_SKELETON_PLANS)
                      .fill(undefined)
                      .map((_, index) => <AnnualPlanColSkeleton key={index} />)}
              </Row>
              <Description>
                *Remove social accounts to downgrade plan
              </Description>
            </Table>
          </LoadingCover>
        )}

        <CustomParagraph>
          Your Currency is set to{" "}
          {user.currency
            ? user.currency.toUpperCase()
            : "unknown currency - please contact support"}
          . To change to another currency (USD, AUD, GBP and EURO),{" "}
          <Link as="button" onClick={() => openSupportChat()}>
            contact support
          </Link>
          .
        </CustomParagraph>

        <p>
          If you need to add additional accounts to your plan as an ‘add on’
          please{" "}
          <Link as="button" onClick={() => openSupportChat()}>
            contact support
          </Link>
          .
        </p>

        <ModalActions variant="space-between">
          <Link
            as="button"
            color="neutral"
            onClick={onHide}
            css="font-weight: 500"
          >
            Close
          </Link>
          {user.pricingVersion && user.pricingVersion === "012023" ? (
            <Link as="button" onClick={() => openSupportChat()}>
              Contact support
            </Link>
          ) : (
            <SubmitButton
              disabled={!selectedPlan.plan._id}
              isSubmitting={isLoadingEstimate}
              primary
              size={FormSize.large}
              onClick={() => {
                if (selectedPlan.planId) {
                  onLoadEstimate(selectedPlan);
                }
              }}
            >
              Next
            </SubmitButton>
          )}
        </ModalActions>
        <Dots css="margin: auto" total={2} active={0} />
      </SyledModalContent>
    </Root>
  );
};

const Root = styled.div`
  min-width: 600px;
`;

const SyledModalContent = styled(ModalContent)`
  & > p {
    font-size: 14px;
  }
`;

const CustomParagraph = styled.p`
  padding-top: 16px;
`;

const Table = styled.div`
  padding-top: 23px;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
`;

const Row = styled.div`
  display: flex;

  &:nth-child(even) {
    display: flex;
    align-items: center;
    height: 66px;
  }
`;

const Description = styled.div`
  padding-top: 16px;
  font-size: 12px;
  font-style: italic;
`;

const TableColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 168px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;

  &.current {
    background-color: #f6f5ff;
    border-color: #dedafe;

    & > div:first-child {
      border-color: #dedafe;
    }
  }

  &.current.with-label {
    &:before {
      content: "Current Plan";
      position: absolute;
      bottom: 99%;
      left: -1px;
      width: calc(100% + 2px);
      height: 23px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #4f31ff;
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      line-height: 23px;
    }
  }

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:not(.current) {
    &.selected {
      border-color: #4f31ff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }
  }

  &.disabled {
    background-color: #f8f8f8;
    border-color: #f8f8f8;
    cursor: not-allowed;

    & > div:first-child {
      border-color: #eeeeee;
    }
  }
`;

export const HeaderCell = styled.div`
  text-align: center;
  padding-top: 15px;
  padding-bottom: 18px;
  font-weight: 700;
  border-bottom: 1px solid #c4c4c4;
`;

const Cell = styled.div`
  padding: 20px;

  & > span {
    font-size: 12px;
  }

  & > .monthPrice {
    font-size: 12px;
    font-weight: 500;
  }

  & > img {
    margin-right: 9px;
  }
`;

const AnnualHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: ${(props: { plansCount: number }) => props.plansCount * (168 + 10)}px;

  & > span {
    padding: 0 19px;
  }
`;

const DecorLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #c4c4c4;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled(PlatformIcon)`
  width: 22px;
`;

function MonthlyPlanCol({
  plan,
  isDisabled,
  isSelected,
  onSelect,
}: {
  plan: Plan;
  isDisabled(plan: Plan): boolean;
  isSelected(plan: Plan): boolean;
  onSelect(plan: Plan): void;
}) {
  const currency = useUserCurrency();

  return (
    <TableColumn
      className={
        (plan.currentPlan ? "current with-label" : "") +
        (isSelected(plan) ? "selected" : "") +
        (isDisabled(plan) ? "disabled" : "")
      }
      onClick={() => onSelect(plan)}
    >
      <HeaderCell>
        {plan.displayName + (plan.tooManyAccounts ? "*" : "")}
      </HeaderCell>
      <Cell>{plan.igIncluded}</Cell>
      <Cell>{plan.otherIncluded}</Cell>
      <Cell>{`${plan.displayPrice || plan.price.usd} ${currency}`}</Cell>
    </TableColumn>
  );
}

function MonthlyPlanColSkeleton() {
  const currency = useUserCurrency();

  return (
    <TableColumn className="disabled">
      <HeaderCell>Waiting...</HeaderCell>
      <Cell>-</Cell>
      <Cell>-</Cell>
      <Cell>-- {currency}</Cell>
    </TableColumn>
  );
}

function AnnualPlanCol({
  plan,
  isCurrent,
  isDisabled,
  isSelected,
  onSelect,
}: {
  plan: Plan;
  isCurrent(plan: Plan): boolean;
  isDisabled(plan: Plan): boolean;
  isSelected(plan: Plan): boolean;
  onSelect(plan: Plan): void;
}) {
  const currency = useUserCurrency();

  return (
    <TableColumn
      className={
        (isCurrent(plan) ? "current" : "") +
        (isDisabled(plan) ? "disabled" : "") +
        (isSelected(plan) ? "selected" : "")
      }
      onClick={() => onSelect(plan)}
    >
      <Cell>
        {plan.displayPrice
          ? (plan.displayPrice * 10).toFixed()
          : plan.price.usd
          ? (plan.price.usd * 10).toFixed()
          : "Custom"}{" "}
        {currency}
        <br />
        <span className="monthPrice">
          {plan.displayPrice
            ? ((plan.displayPrice * 10) / 12).toFixed()
            : plan.price.usd
            ? ((plan.price.usd * 10) / 12).toFixed()
            : "Custom"}{" "}
          {currency} / mo
        </span>
      </Cell>
    </TableColumn>
  );
}

function AnnualPlanColSkeleton() {
  const currency = useUserCurrency();

  return (
    <TableColumn className="disabled">
      <Cell>
        -- {currency}
        <br />
        <span className="monthPrice">-- {currency} / mo</span>
      </Cell>
    </TableColumn>
  );
}
