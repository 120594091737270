import React from "react";
import PropTypes from "prop-types";
import SkedIcon from "ui/SkedIcon/SkedIcon";
import "./PostLink.less";

const PostLink = ({ action, icon, actionText, showText, notifyCount }) => {
  let classModifiers = "";
  let iconJsx = <SkedIcon icon={icon} size="20" />;

  switch (icon) {
    case "trash":
      classModifiers = "PostLink_alert";
      break;
  }

  return (
    <a className={`PostLink ${classModifiers}`} onClick={action}>
      <span className="PostLink-Icon">
        {iconJsx}
        {notifyCount > 0 && (
          <span className="PostLink-NotifyCount">{notifyCount}</span>
        )}
      </span>

      {showText && <span className="PostLink-Text">&nbsp;{actionText}</span>}
    </a>
  );
};

PostLink.propTypes = {
  action: PropTypes.func,
  icon: PropTypes.string,
  actionText: PropTypes.string,
  showText: PropTypes.bool,
  notifyCount: PropTypes.any,
};

export default PostLink;
