import {
  UPDATE_ACCOUNT,
  UPDATE_SELECTED_ACCOUNTS,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";
import { AccountType } from "shared/types/accounts";

export const updateSelectedAccounts = createAction(
  UPDATE_SELECTED_ACCOUNTS,
  function prepare(selectedAccountIds) {
    return {
      payload: {
        selectedAccountIds,
      },
    };
  }
);

export type updateAccountAction = {
  payload: {
    accountId: string;
    account: Partial<AccountType>;
  };
};

export const updateAccount = createAction(
  UPDATE_ACCOUNT,
  function prepare(accountId, account) {
    return {
      payload: {
        accountId,
        account,
      },
    };
  }
);
