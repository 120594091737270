type File = {
  name: string;
  thumbnail?: string;
  type: "image" | "video" | "document";
};

export type FileWithBlob = File & {
  contentType: "blob";
  blob: Blob;
};

export type FileWithGetter = File & {
  contentType: "getter";
  getImage(): Promise<Blob>;
};

export type FileWithURL = File & {
  contentType: "url";
  url: string;
  headers?: {
    [key: string]: string;
  };
  tag?: string[];
  description?: string;
  title?: string;
  extension?: string;
};

export type FileToUpload = FileWithBlob | FileWithGetter | FileWithURL;

export enum UploadingStatus {
  pending = "pending",
  uploading = "uploading",
  transcoding = "transcoding",
  success = "success",
  error = "error",
}

export interface StockImage {
  id: number;
  alt: string;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  photographer_id: number;
  avg_color: string;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
  liked: boolean;
}

export interface MediaCollection {
  id: string;
  _id: string;
  name: string;
  preferences?: {
    color: string;
  };
  asset_count: number;
  isSystemInbox?: boolean;
  active?: boolean;
  created?: string;
}
