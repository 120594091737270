import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  addUser,
  deleteAvatar,
  deleteUser as deleteUserRequest,
  getAllUsers,
  getUser as getUserRequest,
  updateAvatar,
  updateUser as updateUserRequest,
  updateUserPreferences as updateUserPreferencesRequest,
} from "api/users";
import { registerModal } from "libs/modals";
import { handleError } from "utils/handleError";
import { getFormattedDate } from "utils/accounts";
import ngDeps from "ng-react-directives/ngr-injector";

import { AddUserModal } from "./components/add-user";
import { EditUserModal } from "./components/edit-user";
import { InvitationUserModal } from "./components/invitation-user";
import { UpdateAvatar } from "./components/update-avatar";
import { DeleteUserModal } from "./components/delete-user";
import { MODAL_NAMES } from "constants/Modals";

// types
export interface UserState {
  invited_status?: string;
  twoFactorEnabled?: boolean;
  invited_format?: string;
  company: string;
  email: string;
  firstName: string;
  invited_at: string;
  inviter: string;
  inviter_id: string;
  lastName: string;
  profileImage: string;
  status: string;
  token: string;
  _id: string;
}

export interface UserListTypes {
  users: UserState[];
}

export const fetchUsers = createAsyncThunk("users/fetchUsers", getAllUsers);
export const addNewUser = createAsyncThunk("users/addNewUser", addUser);
export const fetchUser = createAsyncThunk("users/fetchUser", getUserRequest);
export const updateUser = createAsyncThunk(
  "users/updateUser",
  updateUserRequest
);
export const updateUserPreferences = createAsyncThunk(
  "users/updateUserPreferences",
  updateUserPreferencesRequest
);
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  deleteUserRequest
);
export const deleteUserAvatar = createAsyncThunk(
  "users/deleteUserAvatar",
  deleteAvatar
);
export const updateUserAvatar = createAsyncThunk(
  "users/updateUserAvatar",
  updateAvatar
);

// reducer

export const selectAllUsers = (state: { users: UserListTypes }) =>
  state.users.users;
export const getUsersStatus = (state: { users: UserState }) =>
  state.users.status;
export const getManageUsersPermissionStatus = (state: { users: any }) =>
  state.users.hasManageUsersPermission;
export const getUserStatus = (state: { users: any }) => state.users.userStatus;
export const getUserUpdateStatus = (state: { users: any }) =>
  state.users.userUpdateStatus;
export const getUser = (state: any) => state.users.user;
export const getAdminUser = (state: any) => state.user;

const initialState = {
  user: {},
  users: [],
  status: "",
  userStatus: "",
  userUpdateStatus: "",
  error: null,
  hasManageUsersPermission: false,
};

const inviteIcons = {
  Accepted: "/assets/img/accepted.svg",
  "Pending Invite": "/assets/img/user-pending.svg",
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // omit existing reducers here
    userSelected: (state) => {
      state.userStatus = "";
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      // this is probably not  a HUGELY elegant solution but
      // it will work for now. Backend throws 400 if no permission
      // or will return hasManageUserPermission
      if (
        !action.payload ||
        !Array.isArray(action.payload) ||
        action.payload.data?.error
      ) {
        state.status = "failed";
        if (
          action.payload?.hasManageUserPermission === false ||
          action.payload?.status === 403
        ) {
          console.error(
            "This user does not have permission to change other users."
          );
          state.hasManageUsersPermission = false;
        } else {
          handleError(
            { response: action.payload },
            "There was an error getting users for your account."
          );
        }
      } else {
        state.status = "succeeded";
        state.hasManageUsersPermission = true;
        const { $rootScope } = ngDeps;
        const timezone = $rootScope.user.timezone;
        state.users = state.users.concat(
          action.payload.map((item: UserState) => {
            const invited_format = getFormattedDate(timezone, item.invited_at);
            const invited_status = inviteIcons[item.status];
            return {
              ...item,
              invited_format,
              invited_status,
            };
          })
        );
      }
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = "failed";
      if (
        action.payload?.hasManageUserPermission === false ||
        action.payload?.status === 403
      ) {
        state.hasManageUsersPermission = false;
      } else {
        handleError(
          action.payload?.data?.error,
          "There was an error getting users for your account"
        );
      }
    },
    [addNewUser.pending]: (state) => {
      state.status = "loading";
    },
    [addNewUser.rejected]: (state) => {
      state.status = "";
    },
    [addNewUser.fulfilled]: (state, action) => {
      if (action.payload?.data?.error) {
        // format a bit weirdly because of how handleError() expects it
        handleError(
          { response: action.payload },
          "There was an error adding the user. Contact support for further assistance adding this email."
        );
        state.status = "";
      } else {
        state.users = [];
        state.status = "";
        toast.success(action.payload.data);
      }
    },
    [deleteUserAvatar.pending]: (state) => {
      state.userStatus = "loading";
    },
    [deleteUserAvatar.fulfilled]: (state, action) => {
      state.userStatus = "succeeded";
      toast.success("The profile picture was deleted.");
      const {
        meta: {
          arg: { id },
        },
      } = action;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.users = state.users.map((user: UserState) => {
        return {
          ...user,
          profileImage: id === user._id ? "" : user.profileImage,
        };
      });
    },
    [deleteUserAvatar.rejected]: (state, action) => {
      state.userStatus = "";
      handleError(
        action.payload?.data?.error,
        "There was an error deleting the avatar for this user."
      );
    },
    [updateUserAvatar.pending]: (state) => {
      state.userStatus = "loading";
    },
    [updateUserAvatar.fulfilled]: (state, action) => {
      if (!action.payload || action.payload.data?.status === "error") {
        if (
          action.payload.hasManageUserPermission === false ||
          action.payload.status === 403
        ) {
          console.error(
            "This user does not have permission to change other users."
          );
          state.hasManageUsersPermission = false;
        } else {
          handleError(
            { response: action.payload },
            "There was an error updating the user avatar."
          );
        }
      } else {
        state.userStatus = "succeeded";
        const {
          meta: {
            arg: { id, url },
          },
        } = action;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.users = state.users.map((user: UserState) => {
          return {
            ...user,
            profileImage: id === user._id ? url : user.profileImage,
          };
        });
        toast.success("A profile picture was added successfully.");
      }
    },
    [updateUserAvatar.rejected]: (state) => {
      state.userStatus = "";
      // handleError("Profile picture update is failed.");
    },
    [fetchUser.pending]: (state) => {
      state.userStatus = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      if (!action.payload || action.payload.data?.status === "error") {
        if (
          action.payload.hasManageUserPermission === false ||
          action.payload.status === 403
        ) {
          console.error(
            "This user does not have permission to change other users."
          );
          state.hasManageUsersPermission = false;
        } else {
          handleError(
            { response: action.payload },
            "There was an error getting data for this user."
          );
        }
      } else {
        state.userStatus = "succeeded";
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.user = action.payload;
      }
    },
    [fetchUser.rejected]: (state) => {
      state.userStatus = "";
      // handleError("Profile picture update is failed.");
    },
    [deleteUser.fulfilled]: (state, action) => {
      if (!action.payload || action.payload.data?.status === "error") {
        if (
          action.payload.hasManageUserPermission === false ||
          action.payload.status === 403
        ) {
          console.error(
            "This user does not have permission to change other users."
          );
          state.hasManageUsersPermission = false;
        } else {
          handleError(
            { response: action.payload },
            "There was an error deleting the user"
          );
        }
      } else {
        const {
          meta: {
            arg: { email },
          },
        } = action;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.users = state.users.filter(
          (user: UserState) => user.email !== email
        );
        toast.success("User removed.");
      }
    },
    [deleteUser.rejected]: (state) => {
      state.userStatus = "";
      handleError("User remove is failed.");
    },

    [updateUser.pending]: (state) => {
      state.userUpdateStatus = "loading";
    },
    [updateUser.fulfilled]: (state, action) => {
      if (!action.payload || action.payload.data?.status === "error") {
        if (
          action.payload.hasManageUserPermission === false ||
          action.payload.status === 403
        ) {
          console.error(
            "This user does not have permission to change other users."
          );
          state.hasManageUsersPermission = false;
        } else {
          handleError(
            { response: action.payload },
            "There was an error updating the user"
          );
        }
      } else {
        state.userUpdateStatus = "succeeded";
        toast.success("User profile information was updated successfully.");
      }
    },
    [updateUser.rejected]: (state) => {
      state.userUpdateStatus = "";
    },

    [updateUserPreferences.pending]: (state) => {
      state.userUpdateStatus = "loading";
    },
    [updateUserPreferences.fulfilled]: (state, action) => {
      if (!action.payload || action.payload.data?.status === "error") {
        if (
          action.payload.hasManageUserPermission === false ||
          action.payload.status === 403
        ) {
          console.error(
            "This user does not have permission to change other users."
          );
          state.hasManageUsersPermission = false;
        } else {
          handleError(
            { response: action.payload },
            "There was an error updating the user preferences"
          );
        }
      } else {
        state.userUpdateStatus = "succeeded";
        toast.success("User preferences updated successfully.");
      }
    },
    [updateUserPreferences.rejected]: (state, action) => {
      state.userUpdateStatus = "";
      handleError({ response: action?.payload }, "Profile update is failed.");
    },
  },
});

// ADD USER MODEL
export const ADD_USER_MODAL = MODAL_NAMES.ADD_USER_MODAL;
registerModal(ADD_USER_MODAL, AddUserModal);

// EDIT USER MODEL
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";
registerModal(EDIT_USER_MODAL, EditUserModal);

// INVITATION USER MODEL
export const INVITATION_USER_MODAL = "INVITATION_USER_MODAL";
registerModal(INVITATION_USER_MODAL, InvitationUserModal);

// Delete USER MODEL
export const DELETE_USER_MODAL = "DELETE_USER_MODAL";
registerModal(DELETE_USER_MODAL, DeleteUserModal);

// ADD USER AVATAR MODEL
export const ADD_USER_AVATAR_MODAL = "ADD_USER_AVATAR_MODAL";
registerModal(ADD_USER_AVATAR_MODAL, UpdateAvatar);

export const usersReducer = usersSlice.reducer;
export const { userSelected } = usersSlice.actions;
