import styled from "styled-components";

const Cell = styled.div`
  padding: 10px 10px 12px;
`;

export const TableCell = styled(Cell).attrs({
  as: "td",
})`
  border-bottom: 1px solid #f4f4f4;
`;

export const Row = styled.tr`
  padding: 10px 0 12px;
  font-size: 14px;
  line-height: 17px;
  color: #333;
`;

export const HeadCell = styled(Cell).attrs({
  as: "th",
})``;

export const TableHead = styled.thead`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000;
`;

export const TBody = styled.tbody``;

export const Table = styled.table`
  width: 100%;
`;
