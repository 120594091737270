import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { handleError } from "utils/handleError";
import {
  fetchNotes as fetchAllNotes,
  createNote as createNoteItem,
  updateNote as updateNoteItem,
  removeNote as removeNoteItem,
} from "api/notes";

import { FetchNotesType, InitialNotesStateType, NoteType } from "./types";

const initialState = {
  isLoading: false,
  ids: [],
  entities: {},
} as InitialNotesStateType;

export const fetchNotes = createAsyncThunk<string, FetchNotesType>(
  "notes/fetchNotes",
  async (data: FetchNotesType, { rejectWithValue }: any) => {
    try {
      const result = await fetchAllNotes(data);

      return result?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createNote = createAsyncThunk<string, any>(
  "notes/createNote",
  async (data, { rejectWithValue }) => {
    const { note } = data;

    try {
      const result = await createNoteItem(note);
      return result?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateNote = createAsyncThunk<
  { note: NoteType; isSilence?: boolean },
  any
>("notes/updateNote", async (data, { rejectWithValue }) => {
  const { note, isSilence } = data;

  try {
    const result = await updateNoteItem(note);

    return { note: result?.data, isSilence };
  } catch (err) {
    // @ts-ignore
    return rejectWithValue(err.response.data);
  }
});

export const removeNote = createAsyncThunk<string, any>(
  "notes/removeNote",
  async (data, { rejectWithValue }) => {
    const { noteId } = data;

    try {
      await removeNoteItem(noteId);
      return noteId;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err.response.data);
    }
  }
);

const noteAdapter = createEntityAdapter({
  // @ts-ignore
  sortComparer: (a: NoteType, b: NoteType) => a?._id?.localeCompare(b?._id),
  selectId: (item: NoteType) => item._id || "",
});

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    updateNoteEntity: (state, action) => {
      noteAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchNotes.fulfilled,
      (state: InitialNotesStateType, action: { payload: NoteType[] }) => {
        noteAdapter.setAll(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(fetchNotes.pending, (state: InitialNotesStateType) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchNotes.rejected,
      (state: InitialNotesStateType, action: { payload: any }) => {
        handleError(
          action.payload,
          "Notes weren't loaded. There was an error."
        );
        state.isLoading = false;
      }
    );

    builder.addCase(
      updateNote.fulfilled,
      (state: InitialNotesStateType, action: { payload: any }) => {
        if (!action.payload.isSilence) {
          toast.success("Note updated successfully");
        }
        const { note } = action.payload;
        noteAdapter.updateOne(state, {
          id: note._id,
          changes: action.payload.note,
        });
      }
    );

    builder.addCase(
      updateNote.rejected,
      (_: InitialNotesStateType, action: { payload: string }) => {
        handleError(action.payload, "There was an error");
      }
    );
    builder.addCase(removeNote.pending, (state: InitialNotesStateType) => {
      state.isLoading = true;
    });
    builder.addCase(
      removeNote.fulfilled,
      (state: InitialNotesStateType, action: { payload: string }) => {
        toast.success("Note removed successfully");
        noteAdapter.removeOne(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      removeNote.rejected,
      (state: InitialNotesStateType, { payload }: { payload: any }) => {
        state.isLoading = false;
        toast.error(payload?.response?.error || "There was an error");
      }
    );

    builder.addCase(createNote.pending, (state: InitialNotesStateType) => {
      state.isLoading = true;
    });
    builder.addCase(
      createNote.fulfilled,
      (state: InitialNotesStateType, action: { payload: NoteType }) => {
        toast.success("Note added successfully");
        noteAdapter.addOne(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      createNote.rejected,
      (state: InitialNotesStateType, action: any) => {
        state.isLoading = false;
        toast.error(action?.error?.message || "There was an error");
      }
    );
  },
});

export const notesReducer = notesSlice.reducer;
export const notesActions = notesSlice.actions;
