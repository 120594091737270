import React, { ReactNode, useMemo, useState, MouseEvent, useRef } from "react";
import styled from "styled-components";

import { SelectAbleItem } from "ui/ecosystems/dropdown";

import {
  MultiSelectControlComponent,
  MultiSelectMenu,
} from "../../dropdown/molecules/multi-select-menu/multi-select-menu";
import { FormSize } from "../form-theme-provider";
import { ReactSelectStyled } from "../_react-select-styled";

interface Props<T, O extends { value: T; label?: ReactNode }> {
  id?: string;
  options: Array<O>;
  value?: T;
  isSearchable?: boolean;
  isMulti?: boolean;
  showPlaceholderOnFocus?: boolean;
  onChange(value: T | T[], event?: any): void;
  useNative?: boolean;
  menuIsOpen?: boolean;

  className?: string;
  components?: any;
  placeholder?: string;
  renderOption?: (option: O | unknown) => ReactNode;
  renderLabel?: (option: O) => ReactNode;
  renderValueContainer?: (option: O) => ReactNode;
  renderMenu?: (option: O) => ReactNode;
  hideSelectedOptions?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  menuPlacement?: string;
  noOptionsMessage?: string;
  isClearable?: boolean;
  size?: FormSize;
  onMenuOpen?: () => void;
  isReadonly?: boolean;
}

interface SelectState {
  // aliased for consumers
  focus(): void;
  blur(): void;
  state: {
    menuIsOpen: boolean;
  };
}

export const ReactSelectComponentStyles = {
  placeholder: (provided) => ({
    ...provided,
    position: "static",
    transform: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    position: "static",
    transform: "none",
    maxWidth: "100%",
    overflowWrap: "break-word",
  }),
  option: (provided) => ({
    ...provided,
    overflowWrap: "break-word",
  }),
  control: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: "100%",
    zIndex: 11,
  }),
};

function SelectComponent<T, D extends { value: T; label?: React.ReactNode }>(
  props: Props<T, D>
) {
  const { renderOption, useNative } = props;

  const [isMenuClosed, setIsMenuClosed] = useState<boolean>(false);
  const selectState = useRef<SelectState>();

  const optionsMap = useMemo(() => {
    const map = new Map<T, { value: T; label?: React.ReactNode }>();
    props.options?.forEach((option) => {
      map.set(option.value, option);
    });
    return map;
  }, [props.options]);

  const closeMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsMenuClosed(false);
    selectState?.current?.blur();
  };

  const toggleMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    selectState?.current?.blur();
    setIsMenuClosed(true);
    if (!selectState?.current?.state.menuIsOpen) {
      selectState?.current?.focus();
      setIsMenuClosed(false);
    }
  };

  const components = useMemo(() => {
    const comp: any = {
      ...props.components,
    };

    if (renderOption) {
      comp.Option = (optionProps: { innerProps: unknown; data: D }) => {
        return (
          <SelectAbleItem {...optionProps.innerProps}>
            {renderOption?.(useNative ? optionProps : optionProps.data)}
          </SelectAbleItem>
        );
      };
    }
    if (props.renderLabel) {
      comp.SingleValue = () => {
        if (
          props.value !== null &&
          props.value !== undefined &&
          props.renderLabel
        ) {
          return props?.renderLabel(optionsMap.get(props.value));
        }
        return null;
      };
    }

    if (props.isMulti) {
      comp.Menu = function renderMenu(menuProps: any) {
        return <MultiSelectMenu menuProps={menuProps} closeMenu={closeMenu} />;
      };
      comp.Control = function renderControl(menuProps: any) {
        return (
          <MultiSelectControlComponent
            controlProps={menuProps}
            toggleMenu={toggleMenu}
          />
        );
      };
    }

    return comp;
  }, [props, renderOption, useNative, optionsMap]);

  const handleOnChange = (selected: any) => {
    props.onChange(selected?.value || null);
  };

  const handleOnChangeMultiple = (selected = [], event: any) => {
    props.onChange(
      props.useNative ? selected : selected?.map((option: any) => option.value),
      event
    );
  };

  let value = props.useNative ? props.value : optionsMap.get(props.value);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = () => {
    props?.onMenuOpen?.();
    setIsMenuOpen(true);
  };
  const onMenuClose = () => setIsMenuOpen(false);

  if (props.showPlaceholderOnFocus && isMenuOpen) {
    value = [];
  }

  return (
    <ReactSelectStyled
      autosize
      inputId={props.id}
      isSearchable={props.isSearchable || false}
      isMulti={props.isMulti}
      ref={selectState}
      menuIsOpen={
        props.isReadonly ? false : props.isMulti ? isMenuClosed : undefined
      }
      closeMenuOnSelect={!props.isMulti}
      className={props.className}
      options={props.options}
      hideSelectedOptions={props.hideSelectedOptions}
      components={components}
      value={value}
      // because otherwise isClearable will return item = null
      onChange={props.isMulti ? handleOnChangeMultiple : handleOnChange}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      defaultMenuIsOpen={props.autoFocus}
      isDisabled={props.disabled}
      menuPlacement={props.menuPlacement || "bottom"}
      size={props.size || FormSize.default}
      styles={ReactSelectComponentStyles}
      noOptionsMessage={() => {
        return props.noOptionsMessage || "No options";
      }}
      isClearable={props.isClearable || false}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
}

export const Select = styled(SelectComponent)``;
