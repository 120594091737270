import Creatable from "react-select/creatable";
import React from "react";
import { CustomInput } from "features/account-managing/components/display-name-form/group-name";

/** NOTE: using this hack because TS will complain with this error:
 * `The types returned by 'render()' are incompatible between these types.`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CreatableSelectUnTyped = Creatable as any;

interface CreatableSelectOptions<T> {
  label: string;
  value: T;
}

export interface CreatableSelectOnSelectValue<T>
  extends CreatableSelectOptions<T> {
  __isNew__: boolean;
}

interface CreatableSelectProps<T> {
  name: string;
  placeholder: string;
  noOptionsMessage: string;
  value: T | null;
  customValue: T | null;
  onCustomValueChange: (val: string) => void;
  options: CreatableSelectOptions<T>[];
  onChange: (props: CreatableSelectOnSelectValue<T>) => void;
  components?: any;
}

export function CreatableSelect<T>({
  name,
  options,
  placeholder,
  value,
  customValue,
  noOptionsMessage,
  onChange,
  onCustomValueChange,
  components,
}: CreatableSelectProps<T>) {
  const styles = {
    container: (base: React.CSSProperties) => ({
      ...base,
      width: "100%",
    }),
    menuList: (base: React.CSSProperties) => ({ ...base, fontSize: 12 }),
    control: (base: React.CSSProperties, state: { isFocused: boolean }) => {
      const stateOnFocus = state.isFocused && {
        border: "1px solid #4f31ff",
      };
      return {
        ...base,
        outline: "none",
        boxShadow: "none",
        ...stateOnFocus,
        "&:hover": {
          ...stateOnFocus,
        },
      };
    },
  };

  return (
    <CreatableSelectUnTyped
      name={name}
      styles={styles}
      customValue={customValue}
      onCustomValueChange={onCustomValueChange}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={value}
      noOptionsMessage={() => noOptionsMessage}
      components={{
        Input: CustomInput,
        SingleValue: function SingleValue() {
          return <></>;
        },
        Placeholder: function Placeholder() {
          return <></>;
        },
        ...components,
      }}
    />
  );
}
