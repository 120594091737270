window.$ = window.jquery = window.jQuery = require('jquery');
window.classie = require('./../sign-up/js/jquery.main.js');

// CSS
require('./../css/bootstrap.css');
require('./../sign-up/css/jquery.fancybox.min.css');
require('./../sign-up/css/main.css');
require('./../sign-up/css/angular-timezone-selector.css');

require('./webpack.vendors.js');

require('./../sign-up/sign-up.js');
