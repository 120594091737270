import {
  SKED_CORE_GET_ACCOUNTS_ROUTINE,
  UPDATE_ACCOUNTS_COUNT,
} from "constants/ActionTypes";

import { createAction } from "@reduxjs/toolkit";

export const getAccounts = createAction(
  SKED_CORE_GET_ACCOUNTS_ROUTINE.TRIGGER,
  function prepare(callback = () => ({})) {
    return {
      payload: {
        callback,
      },
    };
  }
);

export const setAccountsCounts = createAction(
  UPDATE_ACCOUNTS_COUNT,
  function prepare(counts) {
    return {
      payload: {
        counts,
      },
    };
  }
);
