import { combineReducers } from "redux";
import { getReducer as getModalsReducer } from "libs/modals";
import { paginationReducer } from "./paginationReducer";
import { userReducer } from "./userReducer";
import { pageReducer } from "./pageReducer";
import { loadingReducer } from "./loadingReducer";
import postsViewReducer from "./postsViewReducer";
import mediaLibraryViewReducer from "./mediaLibraryViewReducer";
import externalCollabReducer from "./entities/externalCollabReducer";
import collaborationReducer from "./entities/collaborationReducer";

// import postsReducer from "./entities/postsReducer";
import accountsReducer from "./entities/accountsReducer";
import feedMediaReducer from "./entities/hashtagFeedMediaReducer";
import hashtagsReducer from "./entities/hashtagFeedRecentHashtagReducer";

import collectionsReducer from "./entities/collectionsReducer";
import modalReducer from "./modalReducer";
import collectionEntriesReducer from "./entities/collectionEntriesReducer";
import collectionUsersReducer from "./entities/collectionUsersReducer";
import postsReducer from "./entities/postsReducer";
import tagTemplatesReducer from "./entities/tagTemplatesReducer";

// Forms
import postFormsReducer from "./forms/postFormsReducer";

//Content Inbox
import inboxItemsReducer from "./entities/inboxItemsReducer";
import inboxViewReducer from "./inboxViewReducer";
import { getPostFeatureReducer } from "features/post";
import { usersReducer } from "features/collaboration/users-model";
import { calendarReducer } from "features/calendar-new";
import { notesReducer } from "features/calendar-new/notes-model";
//CalendarHolidays
import { calendarFilterReducer } from "features/calendar-new/components/calendar-filter";

//Conversation Inbox
import conversationInboxReducer from "./conversationInboxReducer";

import { accountGroupsReducer } from "../features/account-groups-form/accountsGroups.model";
import { accountsStatisticsReducer } from "../pages/home/accountsStatistics.model";
import workshopReducer from "./workshopReducer";

export default combineReducers({
  common: combineReducers({
    page: pageReducer,
    loading: loadingReducer,
  }),
  user: userReducer,
  users: usersReducer,

  postsView: postsViewReducer,

  entities: combineReducers({
    // posts: postsReducer,
    accounts: accountsReducer,
    accountGroups: accountGroupsReducer,
    accountsStatistics: accountsStatisticsReducer,
    collections: collectionsReducer,
    collectionEntries: collectionEntriesReducer,
    collectionUsers: collectionUsersReducer,
    feedMedia: feedMediaReducer,
    inboxItems: inboxItemsReducer,
    recentHashtags: hashtagsReducer,
    posts: postsReducer,
    calendarPosts: calendarReducer,
    notes: notesReducer,
    externalCollab: externalCollabReducer,
    collaboration: collaborationReducer,
    tagTemplates: tagTemplatesReducer,
  }),
  forms: combineReducers({
    postForms: postFormsReducer,
  }),

  mediaLibraryView: mediaLibraryViewReducer,
  inboxView: inboxViewReducer,
  pagination: paginationReducer,
  modal: modalReducer,
  ...getModalsReducer(),
  ...getPostFeatureReducer(),
  calendarFilter: calendarFilterReducer,
  conversationInbox: conversationInboxReducer,
  workshop: workshopReducer,
});
