import React, { MouseEvent } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getDescriptions } from "selectors/postFormSelectors";
import { TooltipWrapper } from "ui";
import DescriptionIcon from "../../icons/library.svg";

interface LibraryDescriptionPickerProps {
  toggleDescriptionPicker: () => void;
  isOpen: boolean;
}

export const LibraryDescriptionPicker = (
  props: LibraryDescriptionPickerProps
) => {
  const descriptionsLength = useSelector(getDescriptions).length;

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (descriptionsLength) {
      props.toggleDescriptionPicker();
    }
  };

  return (
    <>
      <TooltipWrapper tooltip={"Descriptions"}>
        <DescriptionButton
          isActive={props.isOpen}
          onClick={handleClick}
          className={!descriptionsLength ? "disabled" : ""}
        >
          <DescriptionIcon />
        </DescriptionButton>
      </TooltipWrapper>
    </>
  );
};

const DescriptionButton = styled.button<{ isActive: boolean }>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid ${(props) => (props.isActive ? "#4f31ff" : "#dedafe")};
  border-radius: 5px;
  background: ${(props) => (props.isActive ? "#4f31ff" : "#F6F5FF")};
  svg {
    fill: ${(props) => (props.isActive ? "#fff" : "#9886FF")};
  }

  &.disabled {
    background-color: #c5c5c5;
    box-shadow: none;
    border: none;
    cursor: default;
    svg {
      fill: #fff;
    }
  }
`;
