import { createTable } from "libs/effector-normilaze";
import { createEffect } from "effector";

import {
  FileToUpload,
  uploadFile,
  uploadFileToCollection,
  UploadingStatus,
} from "api/media-library";
import { CollectionFile } from "shared/types/files";
import { FileWithURL } from "api/media-library/types";
import { skedApi } from "api/_skedApi";

export type { FileToUpload } from "api/media-library";

export interface UploadingFile {
  id: string;
  progress: {
    value: number;
    status: UploadingStatus;
  };
  file: FileToUpload;
  error?: any;
}

export type UploadingMode = "parallel" | "subsequent";

export const uploadingFileTable = createTable<UploadingFile>({
  getIdKey: (item) => item.id,
});

const doSingleUpload = createEffect(
  async ({
    collectionId,
    upload,
    onFileUploaded,
  }: {
    collectionId: string;
    upload: { id: string; file: FileToUpload };
    onFileUploaded(uploaded: CollectionFile): void;
  }) => {
    try {
      const intermediate = await uploadFile(upload.file, {
        onProgress({ value, status }) {
          uploadingFileTable.updateItem({
            id: upload.id,
            progress: {
              value,
              status,
            },
          });
        },
      });
      const uploaded = await uploadFileToCollection({
        collectionId,
        file: { ...intermediate, name: upload.file.name },
      });
      if ((upload.file as FileWithURL).contentType === "url") {
        const fileWithURL = upload.file as FileWithURL;
        if (
          fileWithURL.tag
            ? fileWithURL.tag.length > 0
            : false || fileWithURL.description
        ) {
          const putObject = {
            description: fileWithURL.description,
            tags: fileWithURL.tag,
          };
          uploaded.description = fileWithURL.description
            ? fileWithURL.description
            : uploaded.description;
          uploaded.tags =
            fileWithURL.tag && fileWithURL.tag.length > 0
              ? fileWithURL.tag
              : uploaded.tags;
          const putUrl = `/content-inbox/${uploaded.asset_id}`;
          await skedApi.put(putUrl, putObject);
        }
      }
      uploadingFileTable.updateItem({
        id: upload.id,
        progress: { value: 0, status: UploadingStatus.success },
      });
      onFileUploaded(uploaded);
    } catch (err) {
      // this is very not elegant but if axios returns an error message in the body we should print it
      if (
        err &&
        // @ts-ignore
        err.response &&
        // @ts-ignore
        err.response &&
        // @ts-ignore
        err.response.data &&
        // @ts-ignore
        err.response.data.message
      ) {
        // @ts-ignore
        err = err.response.data.message;
      }
      uploadingFileTable.updateItem({
        id: upload.id,
        progress: { value: 0, status: UploadingStatus.error },
        error: err as Error,
      });
    }
  }
);

export const uploadFilesFx = createEffect(
  ({
    collectionId,
    files,
    onFileUploaded,
    uploadingMode,
  }: {
    collectionId: string;
    files: FileToUpload[];
    onFileUploaded(uploaded: CollectionFile): void;
    uploadingMode: UploadingMode;
  }) => {
    const uploads = files.map((file) => ({
      file,
      id: getUniqId(),
    }));

    for (const upload of uploads) {
      uploadingFileTable.addItem({
        id: upload.id,
        progress: {
          value: 0,
          status: UploadingStatus.pending,
        },
        file: upload.file,
      });
    }

    if (uploadingMode === "parallel") {
      for (const upload of uploads) {
        doSingleUpload({ collectionId, upload, onFileUploaded });
      }
    } else {
      setImmediate(async () => {
        for (const upload of uploads) {
          await doSingleUpload({ collectionId, upload, onFileUploaded });
        }
      });
    }

    return uploads.map((file) => file.id);
  }
);

let _lastId = 0;
const getUniqId = () => `${_lastId++}`;

export function isUploadComplete(upload: UploadingFile): boolean {
  return (
    upload.progress.status === UploadingStatus.success ||
    upload.progress.status === UploadingStatus.error
  );
}

export type UploadingProgress = "Uploading" | "Transcoding" | null;

export function computeUploadingProgress(
  uploads: UploadingFile[]
): UploadingProgress {
  const incompleteUploads = uploads.filter(
    (upload) => !isUploadComplete(upload)
  );
  if (
    incompleteUploads.some(
      (upload) => upload.progress.status === UploadingStatus.pending
    )
  ) {
    return "Uploading";
  } else if (
    incompleteUploads.some(
      (upload) => upload.progress.status === UploadingStatus.uploading
    )
  ) {
    return "Uploading";
  } else if (
    incompleteUploads.some(
      (upload) => upload.progress.status === UploadingStatus.transcoding
    )
  ) {
    return "Transcoding";
  }
  return null;
}
