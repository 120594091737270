import { getOneAccount } from "api/accounts";
import axios from "axios";

import { AccountType } from "shared/types/accounts";

import { JsonResp, unwrapJsonResp } from "../../JsonResp";
import {
  FBAccount,
  FBGroup,
  fbLogin,
  FBPageExtended,
  fbReadGroups,
  fbReadMe,
  fbReadPagesExtended,
} from "./fb-sdk-utils";
import { toast } from "react-toastify";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";
import { globalUserPreferencesToggles } from "utils/accounts";

export type { FBAccount, FBPageExtended, FBGroup } from "./fb-sdk-utils";

export const RequiredFacebookScopes = {
  posting: [
    "pages_show_list",
    "pages_manage_posts",
    "pages_manage_metadata",
    "pages_read_engagement",
    "pages_read_user_content",
    "business_management",
  ],
  insights: ["read_insights", "pages_read_engagement"],
  inbox: ["pages_messaging"],
  inboxComments: ["pages_manage_engagement"],
  // temporarily add business_management here so the user
  // isn't prompted to reconnect FB
  insightsAds: ["ads_read"],
  adPosts: ["pages_manage_ads", "ads_management"],
};

export const FacebookScopes = [
  ...RequiredFacebookScopes.posting,
  ...RequiredFacebookScopes.insights,
  ...RequiredFacebookScopes.inbox,
];

// Groups should also have publish_to_groups scope
export const FacebookGroupScopes = FacebookScopes.concat(["publish_to_groups"]);

export const getFacebookToggleScopes = () => {
  const { enableAdPosts } = globalUserPreferencesToggles;

  let scopes = RequiredFacebookScopes.insightsAds || [];
  if (enableAdPosts) {
    scopes = scopes.concat(RequiredFacebookScopes.adPosts);
  }
  return scopes;
};

export interface FacebookPagesSet {
  account: FBAccount;
  pages: FBPageExtended[];
  session: fb.AuthResponse;
}

export async function getFacebookPages({
  user,
}: {
  user: any;
}): Promise<FacebookPagesSet> {
  try {
    const isInboxCommentsEnabled = true;
    console.log("FB Login");
    const auth = await fbLogin(
      FacebookScopes.concat(
        isInboxCommentsEnabled ? RequiredFacebookScopes.inboxComments : []
      ).concat(getFacebookToggleScopes())
    );
    console.log("FB login complete, reading me");
    const account = await fbReadMe(auth);
    console.log("Completed read me, reading pages");
    if (user.preferences?.useShortPagesFbApi)
      console.log(
        "Using shorter pagination limit due to useShortPagesFbApi boolean being true."
      );
    const pages = await fbReadPagesExtended(
      auth,
      user.preferences?.useShortPagesFbApi
    );
    console.log("FB pages read");
    return { account, pages, session: auth };
  } catch (e) {
    console.error(e);
    // @ts-ignore
    toast.error(getUserFriendlyErrorMessage(e));
    // @ts-ignore
    throw new Error(e);
  }
}

export async function addFacebookPageAccount({
  account,
  page,
  session,
}: {
  account: FBAccount;
  page: FBPageExtended;
  session: fb.AuthResponse;
}): Promise<AccountType> {
  const reqData = {
    session: session,
    user_token: session.accessToken,
    platformType: "FB",
    platformUserId: account.platformUserId,
    first_name: account.first_name,
    last_name: account.last_name,
    login: page.name_with_location_descriptor || page.name,
    defaultPage: page,
    pages: [page],
    profilePictureUrl: `https://graph.facebook.com/${page.id}/picture?type=large`,
  };

  const response = await axios.request<JsonResp<AccountType[]>>({
    method: "post",
    url: "/accounts/facebook",
    data: reqData,
  });

  const accounts = unwrapJsonResp(response.data);
  return accounts[0];
}

export interface FacebookGroupsSet {
  account: FBAccount;
  groups: FBGroup[];
  session: fb.AuthResponse;
}

export async function getFacebookGroups(): Promise<FacebookGroupsSet> {
  const isInboxEnabled = true;
  const isInboxCommentsEnabled = true;

  const auth = await fbLogin(
    FacebookGroupScopes.concat(
      isInboxEnabled ? RequiredFacebookScopes.inbox : []
    ).concat(isInboxCommentsEnabled ? RequiredFacebookScopes.inboxComments : [])
  );
  const account = await fbReadMe(auth);
  const groups = await fbReadGroups(auth);
  return { account, groups, session: auth };
}

export async function addFacebookGroupAccount({
  account,
  group,
  session,
}: {
  account: FBAccount;
  group: FBGroup;
  session: fb.AuthResponse;
}) {
  const reqData = {
    session: session,
    user_token: session.accessToken,
    access_token: session.accessToken,
    platformType: "FB",
    // NOTE: adds 'type' to backend object POSTed
    type: "group",
    platformUserId: account.platformUserId,
    first_name: account.first_name,
    last_name: account.last_name,
    login: group.name,
    // NOTE: shims pages, defaultPage for compatibility
    pages: [group],
    defaultPage: group,
    // TODO: profilePictureUrl: `https://graph.facebook.com/${group.id}/picture?type=large`,
  };

  const response = await axios.request<JsonResp<AccountType[]>>({
    method: "post",
    url: "/accounts/facebook",
    data: reqData,
  });

  const accounts = unwrapJsonResp(response.data);
  return accounts[0];
}

export async function reconnectFacebookAccount(account: AccountType) {
  // use group scopes if clicked for a group
  const scope = account.isGroup ? FacebookGroupScopes : FacebookScopes;
  const isInboxEnabled = true;
  const isInboxCommentsEnabled = true;
  const auth = await fbLogin(
    scope
      .concat(isInboxEnabled ? RequiredFacebookScopes.inbox : [])
      .concat(
        isInboxCommentsEnabled ? RequiredFacebookScopes.inboxComments : []
      )
      .concat(getFacebookToggleScopes())
  );

  const data = account.isGroup
    ? {
        reconnectedAccount: account._id,
        authResponseData: auth,
        isGroup: true,
        accountDatabaseId: account._id,
      }
    : { reconnectedAccount: account._id, authResponseData: auth };

  await axios.request({
    method: "post",
    url: "/accounts/reconnectFacebook",
    data,
  });

  return await getOneAccount(account._id);
}
