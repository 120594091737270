import {
  HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
  SELECT_IG_ACCOUNT_ROUTINE,
  SELECT_NEW_HASHTAG_ROUTINE,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { normalizeEntity } from "reducers/reducerUtils";

const initialState = {
  byId: {},
  allIds: [],
  selectedIds: [],
};

const hashtagsReducer = createReducer(initialState, {
  [HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.SUCCESS]: (state, action) =>
    setFeedHashtags(state, action),
  [HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.FAILURE]: (state, action) => {
    return initialState;
  },
  [HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.TRIGGER]: (state, action) => {
    return initialState;
  },
  [SELECT_NEW_HASHTAG_ROUTINE.SUCCESS]: (state, action) => {
    const { hashtag } = action.payload;

    if (!hashtag) {
      return state;
    }

    // Already set, we are likely updating from expired to active
    if (!state.byId[hashtag.hashtag_id]) {
      state.allIds.push(hashtag.hashtag_id);
    }

    state.byId[hashtag.hashtag_id] = hashtag;

    return state;
  },
  [SELECT_IG_ACCOUNT_ROUTINE.TRIGGER]: (state, action) => {
    return initialState;
  },
});

const setFeedHashtags = (state, action) => {
  const { hashtags } = action.payload;

  if (!hashtags) {
    return initialState;
  }

  const { byId, allIds } = normalizeEntity(hashtags, "hashtag_id");

  return {
    ...initialState,
    byId,
    allIds,
  };
};

export default hashtagsReducer;
