import axios from "axios";

const igBaseUrl = "/instagram-api";

const lookupHashtag = async ({
  type = "recent-media",
  accountId,
  hashtagId,
  filterState = {},
  cursor = null,
}) => {
  const params = {};
  const filterKeys = Object.keys(filterState);

  if (cursor) {
    params["after"] = cursor;
  }

  if (filterKeys.length > 0) {
    filterKeys.forEach((key) => {
      if (filterState?.[key]?.length > 0) {
        params[key] = Array.isArray(filterState[key])
          ? filterState[key].join(",")
          : filterState[key];
      }
    });
  }

  return axios.get(`${igBaseUrl}/${accountId}/${type}/${hashtagId}`, {
    params,
  });
};
export default class instagramApi {
  constructor() {}

  async validateAccount(accountId) {
    const hashtagSearchUrl = `${igBaseUrl}/${accountId}/validate-token`;
    return axios.get(hashtagSearchUrl);
  }

  async getHashtag(accountId, hashtag) {
    const hashtagSearchUrl = `${igBaseUrl}/${accountId}/hashtag/${hashtag}`;
    return axios.get(hashtagSearchUrl);
  }

  async getRecentHashtagCodes(accountId) {
    const recSearchUrl = `${igBaseUrl}/${accountId}/cached-hashtags`;

    //Getting 60 for now, since this will allow up to 30 active + 30+ expired
    return axios.get(recSearchUrl, {
      params: { limit: 60 },
    });
  }

  async lookupHashtagRecentMedia({
    accountId,
    hashtagId,
    filterState = {},
    cursor = null,
  }) {
    return lookupHashtag({
      type: "recent-media",
      accountId,
      hashtagId,
      filterState,
      cursor,
    });
  }

  async lookupHashtagTopMedia({
    accountId,
    hashtagId,
    filterState = {},
    cursor = null,
  }) {
    return lookupHashtag({
      type: "top-media",
      accountId,
      hashtagId,
      filterState,
      cursor,
    });
  }

  async lookupMentionedMedia(accountId, cursor = null) {
    const hashtagId = "17843930926043558";
    const hashUrl = `${igBaseUrl}/${accountId}/recent-media/${hashtagId}${
      cursor ? `?after=${cursor}` : ""
    }`;

    return axios.get(hashUrl);
  }

  async lookupTaggedInMedia(accountId, cursor = null) {
    const hashUrl = `${igBaseUrl}/${accountId}/tags${
      cursor ? `?after=${cursor}` : ""
    }`;

    return axios.get(hashUrl);
  }

  async getRecentlySearchedCreators(accId) {
    const { data } = await axios.get(`${igBaseUrl}/${accId}/user-info`);
    return data;
  }

  async searchCreators(accId, username, cursor) {
    const { data } = await axios.get(
      `${igBaseUrl}/${accId}/user-info/${username}`,
      {
        params: { withMedia: true, after: cursor },
      }
    );
    return data;
  }

  getRecentPosts(accountId, count) {
    return axios
      .get("/instagram/recent", {
        params: { accountId: accountId, count: count || 9 },
      })
      .then(function (resp) {
        if (resp?.data?.error) {
          throw new Error(resp.data.error);
        }

        return resp.data;
      });
  }

  insertRecentPosts(recentPost) {
    return axios.post("/instagram/recent", recentPost).then(function (resp) {
      return resp.data;
    });
  }

  manuallyImportPost(post) {
    return axios.post("/instagram/manual", post).then(function (resp) {
      return resp.data;
    });
  }
}
