import { AccountPlatformType } from "shared/types/accounts";

import {
  findPrefixHashtags,
  findPrefixMentions,
  removeHashTag,
  removeMention,
} from "api/autocomplete/tags";

import {
  findPrefixTemplates,
  removeTemplate,
} from "api/autocomplete/templates";
import {
  lookupLinkedInOrganizationOrPeople,
  suggestFacebookPages,
} from "api/autocomplete";

import { MakeSuggestOption, SuggestOption } from "./utils/autocomplete";
import { SuggestOptionTag, SuggestOptionTemplate } from "./utils/autocomplete";
import { CancelToken } from "axios";

export const SkedCaptionAutocomplete = {
  __GENERIC__: {
    hashtag: {
      suggest: withConverter(findPrefixHashtags, MakeSuggestOption.fromTag),
      remove: (suggestion: SuggestOptionTag) => removeHashTag(suggestion.value),
    },
    mention: {
      suggest: withConverter(findPrefixMentions, MakeSuggestOption.fromTag),
      remove: (suggestion: SuggestOptionTag) => removeMention(suggestion.value),
    },
    template: {
      suggest: withConverter(
        findPrefixTemplates,
        MakeSuggestOption.fromTemplate
      ),
      remove: (suggestion: SuggestOptionTemplate) =>
        removeTemplate(suggestion._id),
    },
  },
  FB: {
    hashtag: {
      suggest: withConverter(findPrefixHashtags, MakeSuggestOption.fromTag),
      remove: (suggestion: SuggestOptionTag) => removeHashTag(suggestion.value),
    },
    mention: {
      suggest: withConverter(
        suggestFacebookPages,
        MakeSuggestOption.fromFacebookPage
      ),
    },
    template: {
      suggest: withConverter(
        findPrefixTemplates,
        MakeSuggestOption.fromTemplate
      ),
      remove: (suggestion: SuggestOptionTemplate) =>
        removeTemplate(suggestion._id),
    },
  },
  LI: {
    hashtag: {
      suggest: withConverter(findPrefixHashtags, MakeSuggestOption.fromTag),
      remove: (suggestion: SuggestOptionTag) => removeHashTag(suggestion.value),
    },
    mention: {
      suggest: withConverter(async (...args) => {
        const org = await lookupLinkedInOrganizationOrPeople(...args);
        if (!org) {
          return [];
        }
        return org;
      }, MakeSuggestOption.fromLinkedInOrg),
    },
    template: {
      suggest: withConverter(
        findPrefixTemplates,
        MakeSuggestOption.fromTemplate
      ),
      remove: (suggestion: SuggestOptionTemplate) =>
        removeTemplate(suggestion._id),
    },
  },
};

// TODO: must be able to differentiate FB group from FB page
export function getSkedCaptionAutocomplete(
  platformType?: AccountPlatformType | "LINKED"
) {
  if (platformType && platformType in SkedCaptionAutocomplete) {
    return SkedCaptionAutocomplete[platformType];
  }
  return SkedCaptionAutocomplete.__GENERIC__;
}

export function useSkedCaptionAutocomplete(
  platformType?: AccountPlatformType | "LINKED"
) {
  // use for LinkedIn and Facebook
  if (platformType === "LI" || platformType === "FB") {
    return getSkedCaptionAutocomplete(platformType);
  } else {
    // only use generic for other platform types
    return SkedCaptionAutocomplete.__GENERIC__;
  }
}

function withConverter<T>(
  getItems: (
    prefix: string,
    ct?: CancelToken,
    metaData?: string
  ) => Promise<T[]>,
  convert: (item: T) => SuggestOption
): (
  prefix: string,
  ct?: CancelToken,
  metaData?: string
) => Promise<SuggestOption[]> {
  return async (prefix: string, ct?: CancelToken, metaData?: string) => {
    const items = await getItems(prefix, ct, metaData);

    return items.map((item) => convert(item));
  };
}
