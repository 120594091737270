import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Button } from "ui/ecosystems/forms";
import { getUser } from "selectors/commonSelectors";

import preview from "./images/approvals-conversation.jpg";

export const CollaborationIsNotEnabled = (): JSX.Element => {
  const user = useSelector(getUser);

  const canBeEnabled =
    user.plan.includes("custom") ||
    user.plan.includes("enterprise") ||
    user.plan.includes("agency") ||
    user.plan.includes("professional");

  const canBeAdded = user.plan.includes("essentials");

  const message = useMemo(() => {
    if (canBeEnabled) {
      return "Your plan comes with unlimited free users.";
    }
    if (canBeAdded) {
      return "Without paying for every single user.";
    }
    return "This feature requires an upgrade to your subscription.";
  }, [canBeAdded, canBeEnabled]);

  const heading = useMemo(() => {
    if (canBeEnabled) {
      return "Collaborate with as many teammates and external collaborators as you need.";
    }
    if (canBeAdded) {
      return "Work on posts with any number of teammates and external collaborators.";
    }
    return "Teamwork & approvals lets you co-create content as a team.";
  }, [canBeAdded, canBeEnabled]);

  const chatUsMessage = useMemo(() => {
    if (canBeEnabled) {
      return " to have Teamwork enabled for you.";
    }
    if (canBeAdded) {
      return " to have Teamwork added to your plan.";
    }

    return " about the right plan for you";
  }, [canBeAdded, canBeEnabled]);

  const handleChatOpen = () => {
    window?.Intercom(
      "showNewMessage",
      "Hi, I'd like to enable the Teamwork option."
    );
  };

  return (
    <Container>
      <H1>Team up on post creation and edits</H1>
      <Preview src={preview} alt="Team up on post creation and edits" />
      <H2>{heading}</H2>
      <Text>{message}</Text>
      <ChatWith>
        <ButtonStyled primary text onClick={handleChatOpen}>
          Chat with us
        </ButtonStyled>
        {chatUsMessage}
      </ChatWith>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const H1 = styled.h1`
  font-size: 20px;
  line-height: 24px;
`;

const Preview = styled.img`
  width: 100%;
`;

const H2 = styled.h2`
  font-size: 14px;
  line-height: 15px;
  max-width: 340px;
  max-width: 280px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 15px;
`;

const ChatWith = styled(Text)`
  max-width: 290px;
  margin: 0 auto;
`;
const ButtonStyled = styled(Button)`
  display: inline;
`;
