import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getSelectedHashtagId } from "selectors/hashtagFeedSelectors";
import moment from "moment";

const HashtagRow = ({ hashtag, onClick }) => {
  const selectedHashtagId = useSelector(getSelectedHashtagId);
  return (
    <div
      className={
        `${
          selectedHashtagId === hashtag.hashtag_id
            ? "tw-text-purple-400 tw-bg-purple-100"
            : ""
        }` +
        ` hover:tw-bg-purple-400 hover:tw-text-white tw-pr-4 tw-cursor-pointer tw-pl-6 tw-flex tw-items-center tw-relative`
      }
      onClick={onClick}
    >
      <div className="tw-w-3/4 tw-break-words">#{hashtag.name}</div>
      <div className="tw-flex-grow tw-text-right tw-p-2 tw-text-gray-400 tw-w-1/4">
        {/*OK so momentjs doesn't let you format the output of to() so I'm sorry*/}
        {hashtag.search_date &&
          moment()
            .to(moment(hashtag.search_date).add(7, "days"), true)
            .replace("a ", "1")
            .replace("minutes", "min")
            .replace("minute", "min")
            .replace("days", "d")
            .replace("day", "d")
            .replace("months", "mo")
            .replace("month", "mo")
            .replace(" ", "")}
        {!hashtag.search_date && "Unknown"}
      </div>
    </div>
  );
};

HashtagRow.propTypes = {
  hashtag: PropTypes.object,
  onClick: PropTypes.func,
};

export default HashtagRow;
