import React, { useEffect, useState } from "react";
import { Select, TagsSelect, MultiLineInput } from "ui/ecosystems/forms";
import { Label, SelectedFilter } from "./filter-template";

interface FilterProps<T> {
  label: string;
  value: T;
  onChange(value: T): void;
  onRemove(defaultValue: T): void;
  placeholder?: string;
  formatLabel?(value: T): string;
}

export function TextFilter(props: FilterProps<string>) {
  const [value, setValue] = useState(props.value);
  return (
    <SelectedFilter
      value={value}
      label={`${props.label}${props.value ? `: ${props.value}` : ""}`}
      onChange={props.onChange}
      onRemove={() => props.onRemove("")}
      hasValue={!!props.value}
      renderEditing={() => (
        <MultiLineInput
          autoFocus
          value={value}
          onChange={setValue}
          placeholder={props.placeholder}
        />
      )}
    />
  );
}

export function SelectFilter(
  props: FilterProps<string> & {
    options: Array<{
      label: string;
      value: string;
    }>;
  }
) {
  const [value, setValue] = useState(props.value);

  const label = props.formatLabel
    ? props.formatLabel(value)
    : `${props.label}: ${value}`;

  return (
    <SelectedFilter
      value={value}
      label={label}
      onChange={props.onChange}
      onRemove={() => props.onRemove("")}
      hasValue={Boolean(props.value)}
      renderEditing={() => (
        <Select
          autoFocus
          options={props.options}
          value={value}
          onChange={setValue}
          placeholder={props.placeholder}
        />
      )}
    />
  );
}

export function TagsFilter(props: FilterProps<string[]> & { tags: string[] }) {
  const [value, setValue] = useState(props.value);

  return (
    <SelectedFilter
      value={value}
      onChange={props.onChange}
      isValueEmpty={(value) => value.length === 0}
      label={`${props.label}${
        props.value ? `: ${props.value.join(", ")}` : ""
      }`}
      onRemove={() => props.onRemove([])}
      hasValue={Boolean(value.length)}
      renderEditing={() => (
        <TagsSelect
          tags={props.tags}
          value={value}
          onChange={setValue}
          placeholder={props.placeholder}
        />
      )}
    />
  );
}

export function CheckBoxFilter(props: FilterProps<boolean>) {
  useEffect(() => {
    props.onChange(true);
  }, []);

  return <Label onRemove={() => props.onRemove(false)}>{props.label}</Label>;
}
