import {
  showExternalCollabModal,
  showExternalPermissionModal,
} from "actions/modal";
import { COLLABORATION_VIEW_LINKS } from "constants/Events";
import { User } from "features/user";
import { trackEventWithUser, useTrackEvent } from "libs/analytics";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "selectors/commonSelectors";
import { Store } from "store";
import { MODAL_NAMES, MODAL_SEARCH_PARAM_NAME } from "constants/Modals";

// TODO add argument types
export const showExternalCollabModalOrPermissionModal = ({
  user,
  dispatch,
  trackEvent,
  location,
  navigate,
}: any) => {
  if (user?.isCollaborationEnabled || user?.isPostStatusEnabled) {
    dispatch(showExternalCollabModal());
    trackEvent({
      eventName: COLLABORATION_VIEW_LINKS,
      metadata: { location },
    });
  } else {
    dispatch(showExternalPermissionModal());
  }

  navigate({
    search: `?${MODAL_SEARCH_PARAM_NAME}=${MODAL_NAMES.EXTERNAL_REVIEW}`,
  });
};

export const showExternalModal = ({
  openFrom,
  navigate,
}: {
  openFrom: string;
  navigate: any;
}) => {
  const dispatch = Store.dispatch;
  const user = getUser(Store.getState());
  const trackEvent = ({
    eventName,
    metadata,
  }: {
    eventName: any;
    metadata: any;
  }) => {
    trackEventWithUser(eventName, metadata, user);
  };

  showExternalCollabModalOrPermissionModal({
    user,
    dispatch,
    trackEvent,
    location: openFrom,
    navigate,
  });
};

export default function useShowExternalModal(openFrom: string): () => void {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const user: User = useSelector(getUser);
  const navigate = useNavigate();

  const dispatchModal = useCallback(() => {
    showExternalCollabModalOrPermissionModal({
      user,
      dispatch,
      trackEvent,
      location: openFrom,
      navigate,
    });
  }, [openFrom, navigate, user.isCollaborationEnabled]);

  return dispatchModal;
}
