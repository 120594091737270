import axios from "axios";

export const getMentions = async (query: string) => {
  const { data } = await axios.get(`/tags/mentions/${query}`);
  return data;
};

export const getInstaPopularUsers = async (accountId: string) => {
  const { data } = await axios.get(`/instagram-api/${accountId}/user-info`);
  return data;
};

export const getInstaUser = async (accountId: string, userId: string) => {
  const { data } = await axios.get(
    `/instagram-api/${accountId}/user-info/${userId}`
  );
  return data;
};
