import { Store as ReduxStore } from "redux";
import { createEffect, forward, restore } from "effector";

import { AccountsCount, getAccountsCount } from "api/accounts";

import { $accounts } from "../table";
import { setAccountsCounts } from "actions/skedCore";

const getAccountsCountFx = createEffect(getAccountsCount);

export const $counts = restore<AccountsCount>(getAccountsCountFx.doneData, {
  fbGroupCount: 0,
  fbPageCount: 0,
  gmbCount: 0,
  igAllowed: 0,
  igCount: 0,
  igFollowers: 0,
  liCount: 0,
  otherAllowed: 0,
  otherCount: 0,
  piCount: 0,
  twCount: 0,
  usesSkedLink: false,
  ytCount: 0,
  notificationCount: 0,
  notificationAllowed: 0,
  totalCount: 0,
  maxAllowed: 0,
  pricingVersion: "",
});

export function syncUpAccountCounts(Store: ReduxStore<unknown>): any {
  $counts.watch((counts) => {
    Store.dispatch(setAccountsCounts(counts));
  });
}

forward({
  from: $accounts,
  to: getAccountsCountFx,
});
