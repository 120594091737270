import { GET_TAG_TEMPLATES } from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

export const getTagTemplates = createAction(
  GET_TAG_TEMPLATES.TRIGGER,
  function prepare() {
    return {
      payload: {},
    };
  }
);
