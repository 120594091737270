import React, { useState } from "react";
import { ConversationAvatar } from "../../conversation-avatar";
import {
  BlockContainer,
  ConversationAvatarWrapper,
  DateLabel,
  MessageContainer,
  MessageHiddenNote,
  RootContainer,
} from "./styled";
import { renderContent } from "./message-types/message-content-render";
import { ServerMessageInfoProps } from "./types";
import { ReplyToBlock } from "./reply-to-block";
import { ReactionsChangeData } from "../components/message-reactions";
import { useFormatMessageDateTime } from "../components/date-label";
import { SMUAccountSummary } from "@inbox/entities/smu-accounts";
import { useSelector } from "react-redux";
import {
  getConversationAccountContext,
  WrappedInboxState,
} from "selectors/conversationInboxSelectors";

interface SMUMessageProps extends ServerMessageInfoProps {
  conversationId: string;
  interlocutorId: string;
  onReactionsChange: (data: ReactionsChangeData) => void;
  opaque?: boolean;
  selected?: boolean;
  onSelected?: (id: string) => void;
}

export function SMUMessage({
  src,
  message,
  timestamp,
  name,
  type,
  story,
  attachments,
  replyTo,
  interlocutorId,
  reactions,
  messageId,
  onReactionsChange,
  attachment,
  originalMessage,
  conversationId,
  opaque,
  selected,
  onSelected,
  conversation,
}: SMUMessageProps): JSX.Element {
  const [showAccountSummary, setShowAccountSummary] = useState<boolean>(false);
  const date = useFormatMessageDateTime(timestamp);
  const convAccount = useSelector((state: WrappedInboxState) =>
    getConversationAccountContext(state, conversationId)
  );

  return (
    <RootContainer left>
      <MessageContainer left>
        <ConversationAvatarWrapper
          onMouseEnter={() => setShowAccountSummary(true)}
          onMouseLeave={() => setShowAccountSummary(false)}
        >
          <ConversationAvatar src={src} name={name} size={29} />
          <SMUAccountSummary
            top={74}
            left={1}
            visible={showAccountSummary}
            conversationId={conversationId}
            interlocutor={originalMessage?.sender}
          />
        </ConversationAvatarWrapper>
        <BlockContainer
          opaque={
            !!originalMessage?.deleted || !!originalMessage?.hidden || opaque
          }
        >
          {replyTo && (
            <ReplyToBlock
              message={replyTo.message}
              name={replyTo.name}
              type={replyTo.type}
              story={replyTo.story}
              attachments={replyTo.attachments}
              isLeft
              isCurrentSMUMessage
              isReplyToSMUMessage={replyTo.sender?.id == interlocutorId}
              messageId={replyTo.messageId}
              originalMessage={replyTo.originalMessage}
              conversationId={conversationId}
            />
          )}
          {renderContent({
            type,
            name,
            story,
            attachments,
            reactions,
            onReactionsChange,
            message,
            isLeft: true,
            isRepliedMessage: false,
            messageId,
            attachment,
            originalMessage,
            conversationId,
            selected,
            onSelected,
            conversation,
            accountContext: convAccount,
          })}
        </BlockContainer>
      </MessageContainer>
      <DateLabel left>{date}</DateLabel>
      {originalMessage?.hidden && (
        <MessageHiddenNote>Message has been hidden</MessageHiddenNote>
      )}
    </RootContainer>
  );
}
