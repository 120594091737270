import _ from "lodash";
import { Conversation, Message } from "types/conversationInbox";
import { ConversationInboxState } from "./conversationInboxReducer";
import { immutableUpdate } from "./reducerUtils";

export function immutableUpdateById<T extends { id: string }>(
  arr: T[],
  id: string,
  onUpdateOrItem: ((foundItem: T) => T) | T
): T[] {
  const foundIdx = arr.findIndex((c) => c.id === id);
  if (foundIdx < 0) {
    return arr;
  }
  if (typeof onUpdateOrItem === "function") {
    return immutableUpdate(arr, foundIdx, onUpdateOrItem(arr[foundIdx]));
  }
  return immutableUpdate(arr, foundIdx, onUpdateOrItem);
}

export function immutableUpdateByIdOrAdd<T extends { id: string }>(
  arr: T[],
  id: string,
  item: T
): T[] {
  const result = immutableUpdateById(arr, id, item);
  if (result === arr) {
    return arr.concat([item]);
  }
  return result;
}

export function immutableRemoveById<T extends { id: string }>(
  arr: T[],
  id: string
): T[] {
  const foundIdx = arr.findIndex((c) => c.id === id);
  if (foundIdx < 0) {
    return arr;
  }
  return immutableUpdate(arr, foundIdx);
}

export function immutableConversationUpdate({
  state,
  convId,
  updater,
}: {
  state: ConversationInboxState;
  convId: string;
  updater: (conv: Conversation) => Conversation;
}): ConversationInboxState {
  const conv = state.conversations?.find((c) => c.id === convId);
  if (!conv) {
    return state;
  }
  const conversations = immutableUpdateById(
    state.conversations || [],
    conv.id,
    updater
  );
  return {
    ...state,
    conversations,
  };
}

export function immutableMessageUpdate({
  state,
  convId,
  msgId,
  updater,
}: {
  state: ConversationInboxState;
  convId: string;
  msgId: string;
  updater: (msg: Message) => Message;
}): ConversationInboxState {
  const conv = state.conversations?.find((c) => c.id === convId);
  const foundMsg = conv?.messages?.find((c) => c.id === msgId);
  if (!foundMsg || !conv) {
    return state;
  }

  const newMsg = updater(foundMsg);
  return {
    ...state,
    conversations: immutableUpdateById(state.conversations || [], conv.id, {
      ...conv,
      messages: immutableUpdateById(conv.messages || [], foundMsg.id, newMsg),
    }),
  };
}

export function immutableArrReplaceById<T extends { id: string }>(
  source: T[],
  replacement: T[]
): T[] {
  if (
    !replacement ||
    replacement.length <= 0 ||
    !source ||
    source.length <= 0
  ) {
    return source || [];
  }

  source = _.clone(source || []);
  replacement.forEach((c) => {
    const idx = source.findIndex((k) => k.id === c.id);
    if (idx >= 0) {
      source[idx] = c;
    }
  });
  return source;
}
