import React, { MouseEvent } from "react";
import styled from "styled-components";

import CloseIcon from "./close.svg";

function ModalHeaderComponent({
  className,
  title,
  subtitle,
  onHide,
}: {
  className?: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  onHide?: (event: MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <div className={className}>
      <Title data-testid="modalHeaderTitle">{title}</Title>
      {subtitle && (
        <Subtitle data-testid="modalHeaderSubTitle">{subtitle}</Subtitle>
      )}
      {onHide && <CloseIconButton onClick={onHide} />}
    </div>
  );
}

export const CloseIconButton = styled(
  ({
    className,
    ...props
  }: {
    className?: string | undefined;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  }) => (
    <button
      className={className}
      {...props}
      title="Close dialog"
      aria-label="Close dialog"
    >
      <CloseIcon />
    </button>
  )
)`
  background: transparent;
  border: none;
  padding: 0;

  color: rgb(51, 51, 51);

  &:hover {
    color: #4f31ff;
  }
`;

const Title = styled.h2`
  font-weight: 800;
  margin: 0;
  font-size: 24px;
  line-height: 1.41;
`;

const Subtitle = styled.div`
  margin: 0;
  font-size: 14px;
  line-height: 1.41;
  font-weight: 300;
`;

export const ModalHeader = styled(ModalHeaderComponent)`
  position: relative;

  ${Subtitle} {
    margin-top: 8px;
  }

  ${CloseIconButton} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
