import React from "react";

const PlatformIcon = ({ platformName, className = "" }) => {
  return (
    <i
      className={`platform-icon avatar-platform platform-icon__${platformName} ${className}`}
    ></i>
  );
};

export default PlatformIcon;
