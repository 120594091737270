import axios from "axios";

import { NoteType, FetchNotesType } from "features/calendar-new/types";

export async function fetchNotes(options: FetchNotesType): Promise<{
  data: { notes: NoteType[] };
}> {
  return await axios.get("/calendars/notes", { params: options });
}

export async function createNote(note: NoteType): Promise<{
  data: any;
}> {
  return await axios.post(`/calendars/notes`, note);
}
export async function updateNote(note: NoteType): Promise<{
  data: any;
}> {
  return await axios.put(`/calendars/notes/${note._id}`, note);
}

export async function removeNote(noteId: string): Promise<{
  data: any;
}> {
  return await axios.delete(`/calendars/notes/${noteId}`);
}
