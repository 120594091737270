import {
  SELECT_COLLECTION_ROUTINE,
  TOGGLE_SELECT_ALL_MEDIA,
  TOGGLE_SELECT_NONE_MEDIA,
  TOGGLE_COLLECTION_ASSET,
  FILTER_ASSETS_ROUTINE,
  UPDATE_COLLECTION_ASSET_ROUTINE,
  CHANGE_ASSET_PAGINATION_ROUTINE,
  APPLY_BULK_TAGS_ROUTINE,
  INIT_MEDIA_LIBRARY_PAGE_ROUTINE,
  GET_ALL_COLLECTION_ASSETS_ROUTINE,
  DELETE_COLLECTION_ASSETS_ROUTINE,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { normalizeEntity } from "reducers/reducerUtils";
import _ from "lodash";

const initialState = {
  byId: {},
  allIds: [],
  selectedIds: [],
  allSelected: false,
  allCollectionAssets: [],
  total: 0,
};

const collectionEntriesReducer = createReducer(initialState, {
  [INIT_MEDIA_LIBRARY_PAGE_ROUTINE.TRIGGER]: (state, action) => {
    return initialState;
  },
  [SELECT_COLLECTION_ROUTINE.SUCCESS]: (state, action) =>
    setEntries(state, action),
  [FILTER_ASSETS_ROUTINE.SUCCESS]: (state, action) => setEntries(state, action),
  [CHANGE_ASSET_PAGINATION_ROUTINE.SUCCESS]: (state, action) =>
    setEntries(state, action),
  [TOGGLE_SELECT_ALL_MEDIA]: (state, action) => {
    state.allSelected = !state.allSelected;
    state.selectedIds = state.selectedIds.filter(
      (assetId) => !state.allIds.includes(assetId)
    );
    if (state.allSelected) {
      state.selectedIds = state.selectedIds.concat(state.allIds);
    }
    return state;
  },
  [TOGGLE_SELECT_NONE_MEDIA]: (state, action) => {
    state.allSelected = false;
    state.selectedIds = [];
    return state;
  },
  [TOGGLE_COLLECTION_ASSET]: (state, action) => {
    const { asset } = action.payload;

    if (state.selectedIds.includes(asset.asset_id)) {
      _.remove(
        state.selectedIds,
        (selectedId) => selectedId === asset.asset_id
      );
    } else {
      state.selectedIds.push(asset.asset_id);
    }

    state.allSelected = state.allIds.every((id) =>
      state.selectedIds.find((selectedId) => selectedId === id)
    );

    return state;
  },
  [UPDATE_COLLECTION_ASSET_ROUTINE.SUCCESS]: (state, action) => {
    const { asset } = action.payload;
    state.byId[asset.asset_id] = asset;
    return state;
  },
  [APPLY_BULK_TAGS_ROUTINE.SUCCESS]: (state) => {
    state.allSelected = false;
    state.selectedIds = [];
    return state;
  },
  [GET_ALL_COLLECTION_ASSETS_ROUTINE.SUCCESS]: (state, action) => {
    state.allCollectionAssets = action.payload.assets;
    return state;
  },
  [DELETE_COLLECTION_ASSETS_ROUTINE.SUCCESS]: (state, action) => {
    const deletedIdsSet = new Set(
      action.payload.assets.map((asset) => asset.asset_id)
    );
    state.selectedIds = state.selectedIds.filter(
      (id) => !deletedIdsSet.has(id)
    );
    return state;
  },
});

const setEntries = (state, action) => {
  const { entries } = action.payload;
  const { data, total } = entries;

  if (!data) {
    return state;
  }

  const { byId, allIds } = normalizeEntity(data, "asset_id");
  const allSelected = allIds.every((id) =>
    state.selectedIds.find((selectedId) => selectedId === id)
  );

  return {
    ...state,
    byId: {
      ...state.byId,
      ...byId,
    },
    allIds,
    allSelected,
    total: total !== null && total !== undefined ? total : state.total,
  };
};

export default collectionEntriesReducer;
