import styled, { css } from "styled-components";
import { focusCss, InputWrapperBaseCss } from "./_input-base-wrapper";
import { FormSize, formSizeStyles } from "./form-theme-provider";

export const Button = styled.button<{
  primary?: boolean;
  secondary?: boolean;
  alert?: boolean;
  text?: boolean;
  outline?: boolean;
  size?: FormSize;
  width?: string;
  onClick?: any;
  height?: string;
  noLeftBorderRadius?: boolean;
  disabled?: boolean;
}>`
  ${InputWrapperBaseCss};

  padding: 0; // ?
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 125.5%;
  border-radius: 5px;

  ${(props) =>
    props.noLeftBorderRadius &&
    css`
      border-radius: 0 5px 5px 0 !important;
    `}

  background-color: var(--buttonColor, #fff);
  border-color: var(--buttonColor, #eee);
  color: var(--textColor);
  text-decoration: none;

  :not(:disabled) {
    :hover {
      color: var(--textColor);
      filter: brightness(120%);
      text-decoration: none;
    }

    :active {
      color: var(--textColor);
      filter: brightness(90%);
      text-decoration: none;
    }
  }

  :active {
    ${focusCss};
  }

  &:disabled {
    --textColor: var(--shade-white, #fff);
    --buttonColor: var(--neutral-n-200, #c4c4c4);
    background-color: var(--buttonColor) !important;
    border-color: var(--buttonColor) !important;
    color: var(--textColor) !important;

    &:hover {
      background-color: var(--buttonColor) !important;
      border-color: var(--buttonColor) !important;
      color: var(--textColor) !important;
    }
  }

  ${formSizeStyles(
    FormSize.small,
    css`
      height: 30px;
      font-size: 10px;
      padding: 0 6px;
    `
  )}

  ${formSizeStyles(
    FormSize.medium,
    css`
      height: 44px;
      font-size: 12px;
      padding: 0 8px;
    `
  )}

  ${formSizeStyles(
    FormSize.large,
    css`
      height: 54px;
      font-size: 14px;
      line-height: 125.5%;
      padding: 0 16px;
    `
  )}



  ${(props) =>
    props.primary &&
    css`
      --textColor: #fff;
      --buttonColor: #4f31ff;
    `}

  ${(props) =>
    props.outline &&
    css`
      --textColor: #4f31ff;
      --buttonColor: #fff;
      border-color: var(--textColor);
      &:disabled {
        --textColor: #c4c4c4;
        color: var(--textColor) !important;
        border-color: var(--textColor) !important;
        &:hover {
          color: var(--textColor) !important;
          border-color: var(--textColor) !important;
        }
      }
    `}

  ${(props) =>
    props.alert &&
    css`
      --textColor: #fff;
      --buttonColor: #fa4f38;
      &:hover,
      &:active,
      &:focus {
        border-color: var(--buttonColor);
      }
    `}

  ${(props) =>
    props.text &&
    css`
      :focus,
      :active {
        border-color: transparent;
      }

      background-color: transparent;
      border-color: transparent;
      padding: 0;
      text-decoration: underline;
      height: auto;
      color: var(--buttonColor, #c4c4c4);

      :not(:disabled) {
        :hover,
        :active {
          color: var(--buttonColor);
          text-decoration: underline;
        }
      }
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;
