import { skedApi } from "../_skedApi";
import { StockImage } from "./types";

export async function getStockImages(
  query: string | null,
  page: number,
  limit: number
): Promise<StockImagesResponse> {
  const search = query || "curated";
  const { data } = await skedApi.get(`/content-inbox/stock-images/${search}`, {
    params: { page, limit },
  });
  return data;
}

interface StockImagesResponse {
  page: number;
  limit: number;
  total: number;
  totalPages: number;
  data: StockImage[];
}
