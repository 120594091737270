import React from "react";
import styled from "styled-components";
import { renderContentProps } from "./types";
import { Attachment } from "types/conversationInbox";
import { MessageMedia } from "../../components/message-attachments";

export const IgStoryMention = (
  props: renderContentProps
): JSX.Element | null => {
  const { story } = props;

  if (!story) return null;

  const media: Attachment = {
    type: story.type,
    payload: {
      url: story.url,
    },
  };

  return <MessageMedia {...props} media={media} portraitMedia />;
};

interface StoryMentionIconBackgroundProps {
  disabled?: boolean;
}

export const StoryMentionIconBackground = styled.div<StoryMentionIconBackgroundProps>`
  position: absolute;
  bottom: 0px;
  right: -35px;

  ${(props) =>
    props.disabled &&
    `
  right: 135px;
  `}
`;
