import {
  CHANGE_PAGE_SIZE,
  CHANGE_PAGE,
  SET_PAGINATION,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

export const changePage = createAction(CHANGE_PAGE, function prepare(page) {
  return {
    payload: {
      page,
    },
  };
});

export const changePageSize = createAction(
  CHANGE_PAGE_SIZE,
  function prepare(pageSize) {
    return {
      payload: {
        pageSize,
      },
    };
  }
);

export const setPagination = createAction(
  SET_PAGINATION,
  function prepare(numPages, currentPage, pageSize) {
    return {
      payload: {
        numPages,
        currentPage,
        pageSize,
      },
    };
  }
);
