import { toast } from "react-toastify";
import axios from "axios";
import { put, select, call, takeEvery, all } from "redux-saga/effects";
import _ from "lodash";

import {
  ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE,
  ADD_COLLECTION_ASSETS_FROM_STOCK_ROUTINE,
  ADD_COLLECTION_ASSETS_ROUTINE,
  ADD_COLLECTION_ASSET_ROUTINE,
  ADD_COLLECTION_ROUTINE,
  APPLY_BULK_TAGS_ROUTINE,
  BULK_MOVE_ASSETS_ROUTINE,
  CHANGE_ASSET_PAGINATION_ROUTINE,
  GET_ALL_COLLECTION_ASSETS_ROUTINE,
  CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE,
  DELETE_COLLECTION_ASSET_ROUTINE,
  DELETE_COLLECTION_ASSETS_ROUTINE,
  DELETE_COLLECTION_ROUTINE,
  DELETE_COLLECTION_USER_ROUTINE,
  FILTER_ASSETS_ROUTINE,
  INIT_GLOBALS,
  INIT_MEDIA_LIBRARY_PAGE_ROUTINE,
  LOAD_COLLECTIONS_ROUTINE,
  LOAD_LIBRARY_USAGE,
  PRE_DOWNLOAD_ASSET_ROUTINE,
  RELOAD_ASSET_TAGS_ROUTINE,
  SELECT_COLLECTION_ROUTINE,
  SHOW_CREATE_POST_ROUTINE,
  SKED_CORE_GET_ACCOUNTS_ROUTINE,
  TOGGLE_SELECT_NONE_MEDIA,
  UPDATE_COLLECTION_ASSET_ROUTINE,
  UPDATE_COLLECTION_ROUTINE,
  TOGGLE_SIDEBAR_FILTERS,
  SHOW_MODAL,
  RELOAD_ASSET_CURRENT_PAGE,
} from "constants/ActionTypes";
import { SOURCE_NAMES, PAGES } from "constants/MediaLibrary";
import Api from "api/media-library";
import { getPagination, getUser } from "selectors/commonSelectors";
import {
  getSelectedCollection,
  getMediaLibraryFilters,
  getPage,
  getMediaLibraryPageSize,
} from "selectors/mediaLibrarySelectors";
import { getFeedCreatorUsername } from "selectors/hashtagFeedSelectors";
import { hideModal } from "actions/modal";
import { changeAssetPagination as changeAssetPaginationAction } from "actions/mediaLibrary";
import { selectCollection, reloadTags } from "actions/mediaLibrary";
import { formatTags } from "reducers/entities/collectionsReducer";

import ngDeps from "ng-react-directives/ngr-injector";
import * as EVENTS from "constants/Events";
import { handleError } from "utils/handleError";
import { isLibraryRestricted } from "features/media-library";

import { trackEventByType, findEventSource } from "./utils";

const mlApi = new Api();

export function* initMediaLibraryPage(action) {
  const { $rootScope } = ngDeps;
  const { eventService } = ngDeps;
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_LOAD_COLLECTIONS_PAGE,
    useServices: ["AMP"],
  });
  try {
    yield put({ type: INIT_MEDIA_LIBRARY_PAGE_ROUTINE.REQUEST });
    yield put({
      type: INIT_GLOBALS,
      payload: {
        user: $rootScope.user,
      },
    });
    const libraryUsage = yield call(mlApi.getLibraryUsage);
    const { asset_size, count } = libraryUsage.data;
    yield put({
      type: LOAD_LIBRARY_USAGE,
      payload: {
        asset_size,
        asset_count: count,
      },
    });
    yield call(loadCollections, action);
    yield put({ type: SKED_CORE_GET_ACCOUNTS_ROUTINE.TRIGGER });
    yield put({ type: INIT_MEDIA_LIBRARY_PAGE_ROUTINE.SUCCESS });
  } catch (err) {
    yield put({
      type: INIT_MEDIA_LIBRARY_PAGE_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

// Collection Sagas
export function* loadCollections(action) {
  yield put({ type: LOAD_COLLECTIONS_ROUTINE.REQUEST });
  const selectedCollection = yield select(getSelectedCollection);
  const user = yield select(getUser);
  try {
    const collectionResp = yield call(mlApi.getCollections);
    let collections = _.sortBy(collectionResp.data.data, ["name"]);
    if (isLibraryRestricted(user)) {
      collections = collections.filter(
        (collection) => collection.isSystemInbox
      );
    }

    yield put({
      type: LOAD_COLLECTIONS_ROUTINE.SUCCESS,
      payload: {
        collections,
      },
    });

    if (collections.length > 0) {
      const collectionId = selectedCollection?._id
        ? selectedCollection._id
        : collections[0]._id;
      yield put(selectCollection(collectionId, true));
    }
  } catch (err) {
    yield put({
      type: LOAD_COLLECTIONS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* selectCollectionSaga(action) {
  const { id, skipTracking } = action.payload;
  yield select(getSelectedCollection);
  const { eventService } = ngDeps;

  if (!skipTracking) {
    eventService.trackEvent({
      eventName: EVENTS.LIBRARY_SELECT_COLLECTION,
      useServices: ["AMP"],
    });
  }

  yield put({
    type: SELECT_COLLECTION_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    const userResp = yield call(mlApi.getCollectionAuth, id);
    const pageSize = yield select(getMediaLibraryPageSize);
    const {
      tags,
      name,
      description,
      favorite,
      mediaType,
      sourceName,
      sort,
      usage,
    } = yield select(getMediaLibraryFilters);

    const entriesResp = yield call(
      mlApi.getEntries,
      id,
      1,
      pageSize,
      tags,
      name,
      description,
      favorite,
      mediaType,
      sourceName,
      usage,
      true, // withDownloadUrl - always want this
      sort
    );
    const tagsResp = yield call(mlApi.getTags, id);

    yield put({
      type: SELECT_COLLECTION_ROUTINE.SUCCESS,
      payload: {
        id: id,
        entries: entriesResp.data,
        users: userResp.data,
        tags: formatTags(tagsResp.data),
      },
    });
  } catch (err) {
    yield put({
      type: SELECT_COLLECTION_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* addCollection(action) {
  const { name, fromInit = false } = action.payload;
  const { eventService } = ngDeps;

  if (!fromInit) {
    eventService.trackEvent({ eventName: EVENTS.LIBRARY_CREATE_COLLECTION });
  }

  yield put({ type: ADD_COLLECTION_ROUTINE.REQUEST });
  try {
    const data = yield call(mlApi.createCollection, name);
    const collection = { ...data.data, asset_count: 0 };

    // // Create each user that was passed in
    // let userResponses = [];
    // if (extAuth && users.length > 0) {
    //   let collectionId = data.data._id;
    //   userResponses = yield all(
    //     _.map(users, user => {
    //       console.log("inside yield all", user);
    //       if (user.description.length > 0) {
    //         return call(mlApi.createCollectionAuthUser, collectionId, user);
    //       }
    //     })
    //   );
    // }
    yield put({
      type: ADD_COLLECTION_ROUTINE.SUCCESS,
      payload: {
        collection,
        // users: userResponses,
      },
    });
    yield put(selectCollection(data.data._id, true));
  } catch (err) {
    yield put({
      type: ADD_COLLECTION_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* deleteCollection(action) {
  const { id } = action.payload;
  const { eventService } = ngDeps;

  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_DELETE_COLLECTION,
    useServices: ["AMP"],
  });

  yield put({
    type: DELETE_COLLECTION_ROUTINE.REQUEST,
    payload: action.payload,
  });
  try {
    yield call(mlApi.deleteCollection, id);
    yield put({
      type: DELETE_COLLECTION_ROUTINE.SUCCESS,
      payload: action.payload,
    });
    toast.success("Successfully deleted collection.");
  } catch (err) {
    yield put({
      type: DELETE_COLLECTION_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    yield put(hideModal());
  }
}

export function* updateCollection(action) {
  const { collection } = action.payload;
  const { _id, name, active, preferences } = collection;
  const { eventService } = ngDeps;
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_UPDATE_COLLECTION,
    useServices: ["AMP"],
  });

  yield put({ type: UPDATE_COLLECTION_ROUTINE.REQUEST });

  try {
    const data = yield call(mlApi.updateCollection, _id, {
      name,
      active,
      preferences,
    });
    if (!data.data.success) {
      throw new Error(data.data.message);
    }
    yield put({
      type: UPDATE_COLLECTION_ROUTINE.SUCCESS,
      payload: {
        collection,
      },
    });
    toast.success("Successfully updated collection.");
  } catch (err) {
    yield put({
      type: UPDATE_COLLECTION_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

// Collection User Sagas
export function* deleteCollectionUser(action) {
  const { collectionId, collectionUserId } = action.payload;
  yield put({ type: DELETE_COLLECTION_USER_ROUTINE.REQUEST });
  try {
    yield call(mlApi.deleteCollectionAuthUser, collectionId, collectionUserId);
    yield put({
      type: DELETE_COLLECTION_USER_ROUTINE.SUCCESS,
      payload: {
        collectionId,
        collectionUserId,
      },
    });
  } catch (err) {
    yield put({
      type: DELETE_COLLECTION_USER_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    yield put(hideModal());
  }
}

export function* createOrUpdateCollectionUser(action) {
  const { collection, collectionUser } = action.payload;

  yield put({
    type: CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE.REQUEST,
    payload: { collection, collectionUser },
  });

  try {
    const { description, create, read, update } = collectionUser;
    const payload = { description, create, read, update };

    let userResp = null;
    if (collectionUser._id === null) {
      userResp = yield call(
        mlApi.createCollectionAuthUser,
        collection._id,
        payload
      );
    } else {
      userResp = yield call(
        mlApi.updateCollectionAuthUser,
        collection._id,
        collectionUser._id,
        payload
      );
    }

    yield put({
      type: CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE.SUCCESS,
      payload: {
        call: collectionUser._id === null ? "create" : "update",
        collection: collection,
        collectionUser: userResp.data,
      },
    });
  } catch (err) {
    yield put({
      type: CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    yield put(hideModal());
  }
}

// Collection Asset Sagas
export function* addCollectionAssetsFromFeed(action) {
  let files = [];
  const { collectionId, feedItems, location, done } = action.payload;
  const page = yield select(getPage);

  yield put({
    type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.REQUEST,
  });

  try {
    for (let feedItem of feedItems) {
      const resp = yield call(axios.get, feedItem.media_url, {
        responseType: "blob",
        timeout: 30000,
      });
      const blobType = resp.data.type;
      const type = blobType.split("/").pop(); // image/jpg => jpg

      const filename = `${feedItem.id}.${type}`;
      let file = new File([resp.data], filename, {
        type: blobType,
      });
      file.source_url = feedItem.permalink;

      const creator = yield select(getFeedCreatorUsername);
      file.source_name = {
        [PAGES.TAGGED_IN]:
          SOURCE_NAMES.TAGGED_IN_FEED +
          (feedItem.username ? ` (${feedItem.username})` : ``),
        [PAGES.HASHTAGS]: SOURCE_NAMES.HASHTAG_FEED,
        [PAGES.CREATOR]: `${creator}'s feed`,
      }[page];
      file.description = feedItem.caption ? feedItem.caption : "";

      // We don't have username for hashtag search, but do for mentions, tagged in
      if (feedItem.username) {
        file.description += `\n\n📸 : @${feedItem.username}`;
      }

      if (feedItem.id) {
        file.localId = feedItem.id;
      }

      files.push(file);
    }
    const results = yield call(addCollectionAssets, {
      type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.REQUEST,
      payload: {
        collectionId,
        files,
        fromFeed: true,
        location,
      },
    });

    yield put({
      type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.SUCCESS,
      payload: {
        collectionId,
        files,
      },
    });

    done && done(results);
  } catch (err) {
    yield put({
      type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    done && done();
  }
}

export function* getAllCollectionAssets(action) {
  const { maxFiles } = action.payload;
  const page = 1;
  const selectedCollection = yield select(getSelectedCollection);

  try {
    const entriesResp = yield call(
      mlApi.getEntries,
      selectedCollection._id,
      page,
      maxFiles
    );

    yield put({
      type: GET_ALL_COLLECTION_ASSETS_ROUTINE.SUCCESS,
      payload: {
        assets: entriesResp.data.data,
      },
    });
  } catch (err) {
    handleError(err);
  }
}

export function* addCollectionAssetsFromStock(action) {
  const { collectionId, stockItems, location, done } = action.payload;

  const imageSrcKey = "original";

  // helper
  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const files = [];
  try {
    for (let item of stockItems) {
      const url = item.src[imageSrcKey];
      const extension = getExtension(url);
      const filename = `${item.id}.${extension}`;
      const file = {};
      file.url = url;
      file.source_name = SOURCE_NAMES.STOCKS;
      file.source_url = url;
      file.description = item.url;
      file.filename = filename;
      file.localId = item?.id;
      files.push(file);
    }

    const results = yield call(addCollectionAssetsViaUrl, {
      type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.REQUEST,
      payload: {
        collectionId,
        files,
        fromFeed: true,
        location,
      },
    });

    yield put({
      type: ADD_COLLECTION_ASSET_ROUTINE.SUCCESS,
      payload: {
        collectionId,
        assetsAdded: stockItems.length,
      },
    });

    yield put({
      type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.SUCCESS,
      payload: {
        collectionId,
        files,
      },
    });

    done && done(results);
  } catch (err) {
    yield put({
      type: ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    done && done();
  }
}

export function* addCollectionAssetsViaUrl(action) {
  const {
    collectionId,
    files,
    fromFeed = false,
    fromInitialLibrary = false,
    location = "library",
  } = action.payload;
  const { eventService } = ngDeps;
  yield select(getSelectedCollection);
  const page = yield select(getPage);
  const user = yield select(getUser);

  yield put({
    type: ADD_COLLECTION_ASSETS_ROUTINE.REQUEST,
    payload: {
      collectionId,
      files,
    },
  });

  const eventSource = findEventSource(page);

  const successfulFiles = [];
  const failedFiles = [];
  const failedFileErrors = [];
  const apiResults = [];
  // Upload all files in parallel not in series
  let promises = files.map((file) => {
    put({
      type: ADD_COLLECTION_ASSET_ROUTINE.REQUEST,
      payload: {
        collectionId,
        file,
      },
    });
    return mlApi
      .addEntryFromUrl(collectionId, file)
      .then((result) => {
        put({
          type: ADD_COLLECTION_ASSET_ROUTINE.SUCCESS,
          payload: {
            collectionId,
            asset: result.data,
          },
        });
        apiResults.push({
          ...(result?.data || {}),
          localId: file?.localId,
        });
        successfulFiles.push(file);
      })
      .catch((err) => {
        console.log("error adding file to library", err);
        put({
          type: ADD_COLLECTION_ASSET_ROUTINE.FAILURE,
          error: true,
          payload: err,
        });
        failedFileErrors.push(err);
        failedFiles.push(file);
      });
  });
  yield all(promises);

  yield put({
    type: ADD_COLLECTION_ASSETS_ROUTINE.SUCCESS,
  });
  if (!fromInitialLibrary && successfulFiles.length > 0) {
    toast.success(
      `Successfully added ${successfulFiles.length}/${files.length} asset${
        files.length > 1 ? "s" : ""
      } to collection.`
    );
  }

  if (!fromInitialLibrary && failedFiles.length > 0) {
    toast.error(
      `Failed to add ${failedFiles.length}/${files.length} asset${
        files.length > 1 ? "s" : ""
      } to collection.`
    );
  }
  if (failedFileErrors && failedFileErrors.length > 0) {
    failedFileErrors.map((err) => {
      handleError(err);
    });
  }

  if (!fromInitialLibrary) {
    eventService.trackEvent({
      eventName: EVENTS.LIBRARY_ADD_ASSETS,
      metadata: {
        total: files.length,
        success: successfulFiles.length,
        failed: failedFiles.length,
        source: eventSource,
        location,
      },
      user,
    });
  }

  if (!fromFeed && !fromInitialLibrary) {
    yield put(selectCollection(collectionId, true));
  }
  yield put(hideModal());
  return apiResults;
}

export function* addCollectionAssets(action) {
  const {
    collectionId,
    files,
    fromFeed = false,
    fromInitialLibrary = false,
    location = "library",
  } = action.payload;
  const { eventService } = ngDeps;
  yield select(getSelectedCollection);
  const page = yield select(getPage);
  const user = yield select(getUser);
  const eventSource = findEventSource(page);

  yield put({
    type: ADD_COLLECTION_ASSETS_ROUTINE.REQUEST,
    payload: {
      collectionId,
      files,
    },
  });

  const successfulFiles = [];
  const failedFiles = [];
  const failedFileErrors = [];
  const apiResults = [];
  // Upload all files in parallel not in series
  let promises = files.map((file) => {
    put({
      type: ADD_COLLECTION_ASSET_ROUTINE.REQUEST,
      payload: {
        collectionId,
        file,
      },
    });
    return mlApi
      .addEntry(collectionId, file, {
        name: file.name,
        description: file.description ? file.description : "",
        source_name: file.source_name ? file.source_name : SOURCE_NAMES.UPLOAD,
        source_url: file.source_url ? file.source_url : "",
        tags: file.tags ? file.tags : [],
      })
      .then((res) => {
        successfulFiles.push(file);
        apiResults.push({
          ...(res?.data || {}),
          localId: file?.localId,
        });
      })
      .catch((err) => {
        console.log("error adding file to library", err);
        put({
          type: ADD_COLLECTION_ASSET_ROUTINE.FAILURE,
          error: true,
          payload: err,
        });
        failedFileErrors.push(err);
        failedFiles.push(file);
      });
  });
  yield all(promises);

  yield put({
    type: ADD_COLLECTION_ASSETS_ROUTINE.SUCCESS,
  });
  if (!fromInitialLibrary && successfulFiles.length > 0) {
    yield put({
      type: ADD_COLLECTION_ASSET_ROUTINE.SUCCESS,
      payload: {
        collectionId,
        assetsAdded: successfulFiles.length,
      },
    });

    toast.success(
      `Successfully added ${successfulFiles.length}/${files.length} asset${
        files.length > 1 ? "s" : ""
      } to collection.`
    );
  }

  if (!fromInitialLibrary && failedFiles.length > 0) {
    toast.error(
      `Failed to add ${failedFiles.length}/${files.length} asset${
        files.length > 1 ? "s" : ""
      } to collection.`
    );
  }
  if (failedFileErrors && failedFileErrors.length > 0) {
    failedFileErrors.map((err) => {
      handleError(err);
    });
  }

  if (!fromInitialLibrary) {
    eventService.trackEvent({
      eventName: EVENTS.LIBRARY_ADD_ASSETS,
      metadata: {
        total: files.length,
        success: successfulFiles.length,
        failed: failedFiles.length,
        source: eventSource,
        location,
      },
      user,
    });
  }

  if (!fromFeed && !fromInitialLibrary) {
    yield put(selectCollection(collectionId, true));
  }
  yield put(hideModal());

  return apiResults;
}

export function* deleteCollectionAsset(action) {
  const { collectionId, entryId, assetId, fromBulk } = action.payload;
  const collection = yield select(getSelectedCollection);

  yield put({
    type: DELETE_COLLECTION_ASSET_ROUTINE.REQUEST,
    payload: action.payload,
  });
  try {
    yield call(mlApi.deleteEntry, assetId);
    yield put({
      type: DELETE_COLLECTION_ASSET_ROUTINE.SUCCESS,
      payload: {
        entryId,
        assetId,
        collectionId,
      },
    });
    if (!fromBulk) {
      toast.success("Successfully deleted asset from collection.");
      yield put(selectCollection(collection._id, true));
      yield put(reloadTags());
    }
  } catch (err) {
    yield put({
      type: DELETE_COLLECTION_ASSET_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
    if (!fromBulk) {
      toast.error("Failed to delete asset from collection.");
    }
  }
}

export function* deleteCollectionAssets(action) {
  const { assets } = action.payload;
  const { eventService } = ngDeps;
  const collection = yield select(getSelectedCollection);
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_DELETE_ASSETS,
    metadata: {
      count: assets.length,
    },
    useServices: ["AMP"],
  });

  yield put({
    type: DELETE_COLLECTION_ASSETS_ROUTINE.REQUEST,
    payload: { assets, fromBulk: true },
  });
  for (let asset of assets) {
    yield call(deleteCollectionAsset, {
      payload: {
        assetId: asset.asset_id,
        fromBulk: true,
      },
    });
  }

  yield put(selectCollection(collection._id, true));

  yield put({
    type: DELETE_COLLECTION_ASSETS_ROUTINE.SUCCESS,
    payload: { assets },
  });
  toast.success(
    `Successfully deleted ${assets.length} asset${
      assets.length > 1 ? "s" : ""
    } from collection.`
  );
  yield put(reloadTags());
}

export function* updateCollectionAsset(action, fromBulk = false) {
  const {
    asset,
    file,
    filename,
    description,
    tags,
    source,
    sourceNotes,
    favorite,
  } = action.payload;
  const { eventService } = ngDeps;
  if (!fromBulk) {
    eventService.trackEvent({
      eventName: EVENTS.LIBRARY_UPDATE_ASSET,
      useServices: ["AMP"],
    });
  }

  if (!_.isEmpty(file)) {
    // newAsset
  }

  yield put({ type: UPDATE_COLLECTION_ASSET_ROUTINE.REQUEST });
  try {
    //TODO will need to do something different when updating img
    // const downloadResp = yield call(mlApi.downloadEntry, assetId);
    // const file = new File([downloadResp.data], "medial-library-asset.jpg");
    const newFile = !_.isEmpty(file) ? file : null;
    yield call(
      mlApi.updateEntry,
      asset.asset_id,
      filename,
      description,
      tags,
      newFile,
      source,
      sourceNotes,
      favorite
    );

    const getAssetResp = yield call(mlApi.getEntry, asset.asset_id);

    yield put({
      type: UPDATE_COLLECTION_ASSET_ROUTINE.SUCCESS,
      payload: {
        asset: getAssetResp.data,
      },
    });

    if (!fromBulk) {
      yield put(reloadTags());
      toast.success("Successfully updated asset.");
    }
  } catch (err) {
    yield put({
      type: UPDATE_COLLECTION_ASSET_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });

    if (!fromBulk) {
      handleError(err);
      toast.error("Failed to update asset.");
    } else {
      handleError(err);
    }
  } finally {
    yield put(hideModal());
  }
}

export function* showCreatePost(action) {
  const { assets } = action.payload;
  const { loadingSpinnerService, modalService } = ngDeps;
  const { eventService } = ngDeps;
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_CREATE_POST,
    metadata: { count: assets.length },
  });

  let files = [];

  loadingSpinnerService.showLoading();
  yield put({ type: SHOW_CREATE_POST_ROUTINE.REQUEST });
  try {
    for (let asset of assets) {
      yield put({
        type: PRE_DOWNLOAD_ASSET_ROUTINE.REQUEST,
        payload: { asset },
      });
      const resp = yield call(mlApi.downloadEntry, asset.asset_id);
      const filename =
        asset.name && asset.name.length > 0 ? asset.name : asset.storage.url;
      let fileFormat = `image/${asset.meta.format}`;

      if (asset.meta.isVideo) {
        fileFormat = "video/mp4"; //hardcode until we have a good way of getting mime.
      }

      let file = new File([resp.data], filename, {
        type: `${fileFormat}`,
      });
      file.imgURL = `${asset.thumbnail}?_`;
      file.thumbnailUrl = `${asset.thumbnail}?_`;
      file.meta = {
        ...asset.meta,
        size: asset.meta.size / 1000, // need this so size validation works later as it needs to be in kb
      };
      file.base_caption = asset.description;

      files.push(file);

      yield put({
        type: PRE_DOWNLOAD_ASSET_ROUTINE.SUCCESS,
        payload: {
          asset,
          file,
        },
      });
    }

    yield put({ type: SHOW_CREATE_POST_ROUTINE.SUCCESS, payload: { files } });
    loadingSpinnerService.hideLoading();
    modalService.createPostModal(
      {
        single: true,
        files: files,
      },
      () => console.log("create post")
    );
    // remove selection of posts
    yield put({ type: TOGGLE_SELECT_NONE_MEDIA });
  } catch (err) {
    console.error(err);
    if (window.Bugsnag && window.Bugsnag.notify) {
      window.Bugsnag.notify(err);
    }
    yield put({
      type: SHOW_CREATE_POST_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* filterAssets(action) {
  const selectedCollection = yield select(getSelectedCollection);
  const pageSize = yield select(getMediaLibraryPageSize);
  const {
    tags,
    name,
    description,
    sourceName,
    favorite,
    mediaType,
    sort,
    usage,
  } = action.payload;
  const { eventService } = ngDeps;
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_FILTER_ASSETS,
    metadata: {
      tags: tags,
      name: name,
      favorite: favorite,
      mediaType: mediaType,
      sourceName: sourceName,
      usage: usage,
      sort: sort,
    },
  });
  // const { loadingSpinnerService } = ngDeps;
  yield put({ type: FILTER_ASSETS_ROUTINE.REQUEST });

  try {
    // loadingSpinnerService.showLoading();
    const entriesResp = yield call(
      mlApi.getEntries,
      selectedCollection._id,
      1,
      pageSize,
      tags,
      name,
      description,
      favorite,
      mediaType,
      sourceName,
      usage,
      true, // withDownloadUrl - always want this
      sort
    );
    yield put({
      type: FILTER_ASSETS_ROUTINE.SUCCESS,
      payload: {
        entries: entriesResp.data,
      },
    });
  } catch (err) {
    yield put({
      type: FILTER_ASSETS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    // loadingSpinnerService.hideLoading();
  }
}

export function* changeAssetPagination(action) {
  const { page, pageSize } = action.payload;
  const selectedCollection = yield select(getSelectedCollection);
  const {
    tags,
    name,
    description,
    favorite,
    mediaType,
    sourceName,
    sort,
    usage,
  } = yield select(getMediaLibraryFilters);
  // const { loadingSpinnerService } = ngDeps;
  yield put({ type: CHANGE_ASSET_PAGINATION_ROUTINE.REQUEST });

  try {
    // loadingSpinnerService.showLoading();
    const entriesResp = yield call(
      mlApi.getEntries,
      selectedCollection._id,
      page,
      pageSize,
      tags,
      name,
      description,
      favorite,
      mediaType,
      sourceName,
      usage,
      true, // withDownloadUrl - always want this
      sort
    );
    yield put({
      type: CHANGE_ASSET_PAGINATION_ROUTINE.SUCCESS,
      payload: {
        entries: entriesResp.data,
      },
    });
  } catch (err) {
    yield put({
      type: CHANGE_ASSET_PAGINATION_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  } finally {
    // loadingSpinnerService.hideLoading();
  }
}

export function* reloadTagsSaga(action) {
  const selectedCollection = yield select(getSelectedCollection);
  yield put({ type: RELOAD_ASSET_TAGS_ROUTINE.REQUEST });
  try {
    const tagsResp = yield call(mlApi.getTags, selectedCollection._id);
    yield put({
      type: RELOAD_ASSET_TAGS_ROUTINE.SUCCESS,
      payload: {
        tags: formatTags(tagsResp.data),
      },
    });
  } catch (err) {
    yield put({
      type: RELOAD_ASSET_TAGS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* applyBulkTags(action) {
  const { assets, tags } = action.payload;
  const { eventService } = ngDeps;
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_BULK_TAG_ASSETS,
    metadata: {
      count: assets.length,
    },
    useServices: ["AMP"],
  });

  yield put({ type: APPLY_BULK_TAGS_ROUTINE.REQUEST });
  try {
    for (let asset of assets) {
      let newTags = [...asset.tags];

      tags.map((tag) => {
        if (!newTags.includes(tag.value)) {
          newTags.push(tag.value);
        }
      });

      yield call(
        updateCollectionAsset,
        {
          payload: {
            asset,
            filename: asset.name,
            description: asset.description,
            tags: newTags,
          },
        },
        true
      );
    }
    yield call(reloadTagsSaga, reloadTags());
    yield put({ type: APPLY_BULK_TAGS_ROUTINE.SUCCESS });
    toast.success(`Successfully updated ${assets.length} collection assets`);
  } catch (err) {
    yield put({
      type: APPLY_BULK_TAGS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
    toast.error(`Failed to update collection asset`);
  }
}

export function* bulkMoveAssets(action) {
  const { assets, collectionId } = action.payload;
  const { eventService } = ngDeps;
  const collection = yield select(getSelectedCollection);
  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_BULK_MOVE_ASSETS,
    metadata: {
      count: assets.length,
    },
    useServices: ["AMP"],
  });

  yield put({ type: BULK_MOVE_ASSETS_ROUTINE.REQUEST });
  try {
    for (let asset of assets) {
      yield call(mlApi.moveEntry, asset.asset_id, collectionId);
    }
    yield put({
      type: BULK_MOVE_ASSETS_ROUTINE.SUCCESS,
      payload: {
        assets,
        toCollectionId: collectionId,
        fromCollectionId: assets[0].inbox_id,
      },
    });
    toast.success(
      `Successfully moved ${assets.length} collection asset${
        assets.length > 1 ? "s" : ""
      }`
    );
    yield put(selectCollection(collection._id, true));
  } catch (err) {
    yield put({
      type: BULK_MOVE_ASSETS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
    toast.error(`Failed to move collection asset`);
  } finally {
    yield put(hideModal());
  }
}

function* reloadAssetCurrentPage() {
  const pagination = yield select(getPagination);
  const pageSize = yield select(getMediaLibraryPageSize);
  yield put(changeAssetPaginationAction(pagination?.page ?? 1, pageSize));
}

// -------------- WATCHERS ----------------------- //

export function* watchInitMediaLibraryPage() {
  yield takeEvery(
    INIT_MEDIA_LIBRARY_PAGE_ROUTINE.TRIGGER,
    initMediaLibraryPage
  );
}

export function* watchLoadCollection() {
  yield takeEvery(LOAD_COLLECTIONS_ROUTINE.TRIGGER, loadCollections);
}

export function* watchSelectCollection() {
  yield takeEvery(SELECT_COLLECTION_ROUTINE.TRIGGER, selectCollectionSaga);
}

export function* watchAddCollection() {
  yield takeEvery(ADD_COLLECTION_ROUTINE.TRIGGER, addCollection);
}

export function* watchUpdateCollection() {
  yield takeEvery(UPDATE_COLLECTION_ROUTINE.TRIGGER, updateCollection);
}

export function* watchDeleteCollectionUser() {
  yield takeEvery(DELETE_COLLECTION_USER_ROUTINE.TRIGGER, deleteCollectionUser);
}

export function* watchCreateOrUpdateCollectionUser() {
  yield takeEvery(
    CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE.TRIGGER,
    createOrUpdateCollectionUser
  );
}

export function* watchAddCollectionAssetsFromFeed() {
  yield takeEvery(
    ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.TRIGGER,
    addCollectionAssetsFromFeed
  );
}

export function* watchAddCollectionAssetsFromStock() {
  yield takeEvery(
    ADD_COLLECTION_ASSETS_FROM_STOCK_ROUTINE.TRIGGER,
    addCollectionAssetsFromStock
  );
}

export function* watchGetAllCollectionAssets() {
  yield takeEvery(
    GET_ALL_COLLECTION_ASSETS_ROUTINE.TRIGGER,
    getAllCollectionAssets
  );
}

export function* watchAddCollectionAssets() {
  yield takeEvery(ADD_COLLECTION_ASSETS_ROUTINE.TRIGGER, addCollectionAssets);
}

export function* watchDeleteCollectionAsset() {
  yield takeEvery(
    DELETE_COLLECTION_ASSET_ROUTINE.TRIGGER,
    deleteCollectionAsset
  );
}

export function* watchDeleteCollectionAssets() {
  yield takeEvery(
    DELETE_COLLECTION_ASSETS_ROUTINE.TRIGGER,
    deleteCollectionAssets
  );
}

export function* watchUpdateCollectionAsset() {
  yield takeEvery(
    UPDATE_COLLECTION_ASSET_ROUTINE.TRIGGER,
    updateCollectionAsset
  );
}

export function* watchShowCreatePost() {
  yield takeEvery(SHOW_CREATE_POST_ROUTINE.TRIGGER, showCreatePost);
}

export function* watchFilterAssets() {
  yield takeEvery(FILTER_ASSETS_ROUTINE.TRIGGER, filterAssets);
}

export function* watchChangeAssetPagination() {
  yield takeEvery(
    CHANGE_ASSET_PAGINATION_ROUTINE.TRIGGER,
    changeAssetPagination
  );
}

export function* watchReloadTags() {
  yield takeEvery(RELOAD_ASSET_TAGS_ROUTINE.TRIGGER, reloadTagsSaga);
}

export function* watchApplyBulkTags() {
  yield takeEvery(APPLY_BULK_TAGS_ROUTINE.TRIGGER, applyBulkTags);
}

export function* watchDeleteCollection() {
  yield takeEvery(DELETE_COLLECTION_ROUTINE.TRIGGER, deleteCollection);
}

export function* watchBulkMoveAssets() {
  yield takeEvery(BULK_MOVE_ASSETS_ROUTINE.TRIGGER, bulkMoveAssets);
}

export function* watchActionsToTrackByType() {
  yield takeEvery(TOGGLE_SIDEBAR_FILTERS, trackEventByType);
  yield takeEvery(SHOW_MODAL, trackEventByType);
}

export function* watchReloadAssetCurrentPage() {
  yield takeEvery(RELOAD_ASSET_CURRENT_PAGE, reloadAssetCurrentPage);
}
