import { useLocalStorage } from "@rehooks/local-storage";
import {
  LocalStorageNullableReturnValue,
  LocalStorageReturnValue,
} from "@rehooks/local-storage/src/use-localstorage";

export interface proxyStorage {
  getItem(key: string): string | null;
  setItem(Key: string, value: string): void;
  removeItem(key: string): void;
}
interface universalStorage {
  syncGetItem(key: string): string | null; // only for localStorage and sessionStorage
  getItem(key: string): Promise<string | null>;
  setItem(Key: string, value: string): void;
  removeItem(key: string): void;
  useStorage<T = string>(key: string): LocalStorageNullableReturnValue<T>;
  useStorage<T = string>(
    key: string,
    defaultValue: T
  ): LocalStorageReturnValue<T>;
}

const createUseStorage = (
  storage: proxyStorage
): universalStorage["useStorage"] => {
  switch (storage) {
    case window.localStorage:
      return useLocalStorage;

    default:
      // TODO: implement universal react hook
      return (key) => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return [null, () => {}, () => {}];
      };
  }
};

/**
 * @param {storage} localStorage, sessionStorage, localForage (indexDB), asyncStorage (React Native)
 * @return {universalStorage} universal storage with the save methods like on Storage plus react hook
 */
const createStorage = (storage: proxyStorage): universalStorage => {
  return {
    syncGetItem(key) {
      if (
        storage === window.localStorage ||
        storage === window.sessionStorage
      ) {
        return storage.getItem(key);
      }

      return null;
    },

    async getItem(key: string) {
      return await storage.getItem(key);
    },

    async setItem(key: string, value: string) {
      storage.setItem(key, value);
    },

    async removeItem(key: string) {
      storage.removeItem(key);
    },

    useStorage: createUseStorage(storage),
  };
};

export default createStorage;
