import React from "react";
import Moment from "moment-timezone";

import { PostAccount } from "components/Posts/Post/index";
import { FIELDS, GMB_CTAS, GMB_TOPICS } from "constants/PostFormFields";
import ngDeps from "ng-react-directives/ngr-injector";

import { Caption, DetailsTemplate, PostDetail } from "./post-details-template";
import { useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";

export function GoogleMyBusinessDetails({ post, account, page }) {
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          <GoogleMyBusinessTitles post={post} />
          <Caption post={post} />
          <GoogleMyBusinessTypeDetails post={post} />
        </>
      )}
    />
  );
}

export function GoogleMyBusinessTitles({ post }) {
  return (
    <>
      {post[FIELDS.YT_TITLE] && (
        <PostDetail label="Video title">{post[FIELDS.YT_TITLE]}</PostDetail>
      )}

      {/* GMB Event/Offer title - can't use field because dot notation */}
      {/* Intentionally moved up here because it should sit above caption */}
      {post.gmbEvent && post.gmbEvent.title && (
        <PostDetail label="Title"> {post.gmbEvent.title}</PostDetail>
      )}
    </>
  );
}

export function GoogleMyBusinessTypeDetails({ post }) {
  const { $rootScope } = ngDeps;
  const user = useSelector(getUser);

  const displayDateFormat =
    $rootScope.user.preferences.timePreference === "12h"
      ? "D MMM hh:mma"
      : "D MMM HH:mm";

  return (
    <>
      {post[FIELDS.GMB_TOPIC_TYPE] && (
        <PostDetail label="Topic type">
          {GMB_TOPICS[post[FIELDS.GMB_TOPIC_TYPE]].LABEL}
        </PostDetail>
      )}

      {/* GMB Event/Offer date - can't use field because dot notation */}
      {post.gmbEvent && post.gmbEvent.startDate && post.gmbEvent.endDate && (
        <PostDetail label="Start and end date">
          <div className="tw-flex tw-flex-wrap tw-items-center">
            {Moment.tz(post.gmbEvent.startDate, user.timezone)
              .tz(user.timezone)
              .format(displayDateFormat)}{" "}
            -{" "}
            {Moment.tz(post.gmbEvent.endDate, user.timezone).format(
              displayDateFormat
            )}{" "}
            ({user.timezone})
          </div>
        </PostDetail>
      )}
      {/* GMB Coupon - can't use field because dot notation */}
      {post.gmbOffer?.couponCode && (
        <PostDetail label="Coupon code">{post.gmbOffer.couponCode}</PostDetail>
      )}
      {post.gmbOffer?.termsConditions && (
        <PostDetail label="Offer terms and conditions ">
          {post.gmbOffer.termsConditions}
        </PostDetail>
      )}
      {/* GMB CTA label - can't use field because dot notation */}
      {post.gmbCallToAction?.actionType && (
        <PostDetail label="Call to action">
          {GMB_CTAS[post.gmbCallToAction.actionType].VALUE}
        </PostDetail>
      )}
    </>
  );
}
