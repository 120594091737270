import {
  INIT_POSTS_PAGE_ROUTINE,
  CHANGE_POSTS_PAGINATION,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  pages: [],
  numPages: null,
  currentPage: null,
  lastPage: null,
  pageSize: null,
};

export const paginationReducer = createReducer(initialState, {
  SET_PAGINATION: (state, action) => {
    let { numPages, currentPage, pageSize } = action.payload;
    return {
      ...state,
      numPages,
      currentPage,
      pageSize,
    };
  },
  CHANGE_PAGE_SIZE: (state, action) => {
    const { pageSize } = action.payload;
    return {
      ...state,
      currentPage: 1,
      pageSize,
    };
  },
  CHANGE_PAGE: (state, action) => {
    const { page } = action.payload;
    return {
      ...state,
      currentPage: page,
    };
  },
  INIT_POSTS_VIEW: (state) => {
    return {
      ...state,
      currentPage: 1,
    };
  },
  CHANGE_POST_PAGE: (state, action) => {
    return {
      ...state,
      currentPage: action.payload,
    };
  },
  CHANGE_POST_PAGE_SIZE: (state, action) => {
    return {
      ...state,
      pageSize: action.payload,
    };
  },
  [CHANGE_POSTS_PAGINATION.TRIGGER]: (state, action) => {
    const { page, pageSize } = action.payload;
    return {
      ...state,
      currentPage: page,
      pageSize,
    };
  },
  [INIT_POSTS_PAGE_ROUTINE.TRIGGER]: (state, action) => {
    state.currentPage = 1;
    return state;
  },
});

function range(start, end) {
  var values = [];
  for (; start <= end; start++) {
    values.push(start);
  }
  return values;
}

export function makePages(data, total, currentPage, lastPage) {
  let pages = [];

  if (lastPage <= 10) {
    return range(1, lastPage);
  }

  if (currentPage <= 4) {
    return range(1, 5).concat([{ placeholder: true }, lastPage]);
  }

  pages = [1, { placeholder: true }];
  if (lastPage - currentPage <= 4) {
    return pages.concat(range(lastPage - 5, lastPage));
  }

  return pages
    .concat(range(currentPage - 2, currentPage + 2))
    .concat({ placeholder: true }, lastPage);
}
