import React from "react";
import styled from "styled-components";
import { AccountType } from "shared/types/accounts";
import { FormSize, Select } from "ui/ecosystems/forms";
import { AccountAvatar } from "../..";
import { AccountName, AccountProvider } from "../..";

interface Props {
  id: string;
  className?: string;
  value: string | null;
  onChange(value: string): void;
  accounts: AccountType[];
  placeholder?: string;
}

function AccountSelectorComponent(props: Props) {
  const options = props.accounts.map((account) => ({
    value: account._id,
    account: account,
  }));

  return (
    <Select
      id={props.id}
      size={FormSize.small}
      className={props.className}
      options={options}
      value={props.value}
      onChange={props.onChange}
      renderOption={(option) => <AccountItem account={option.account} />}
      renderLabel={(option) => <AccountItem account={option.account} />}
      placeholder={props.placeholder}
    />
  );
}

export const AccountSelector = styled(AccountSelectorComponent)``;

function AccountItem({ account, ...props }: { account: AccountType }) {
  return (
    <AccountProvider account={account}>
      <Account {...props}>
        <AccountAvatar size="small" />
        <AccountName weight={300} />
      </Account>
    </AccountProvider>
  );
}
const Account = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  ${AccountAvatar} {
    min-width: 30px;
    max-width: 30px;
    margin-right: 10px;
  }

  ${AccountName} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
