import { MODAL_NAMES, MODAL_SEARCH_PARAM_NAME } from "constants/Modals";
import { createSearchParams } from "react-router-dom";
import { openModal } from "libs/modals";
import { Store } from "store";
import { createBrowserHistory } from "history";
import { showExternalModal } from "external-review/hooks/useShowExternalModal";
import { getUser } from "selectors/commonSelectors";
import { getOpportunityDataToUseAddUserModal } from "ui/header/AddUserMenu";

// NOTE (Andrey) Every time need recreate navigate with new (current location) history.
const getNavigate = () => {
  const history = createBrowserHistory();

  return (pathOrRouteObj: any) => history.push(pathOrRouteObj);
};

const addParamToSearchParamsAndReturnSearchParamsString = ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  const search = createSearchParams(location.search);
  const allKeyValues = search.getAll(key) || [];

  if (allKeyValues.includes(value)) return search.toString();

  search.append(key, value);

  return search.toString();
};

export const MODAL_OPEN_FUNCTIONS = {
  [MODAL_NAMES.EXTERNAL_REVIEW]: () =>
    showExternalModal({ openFrom: "url", navigate: getNavigate() }),
  [MODAL_NAMES.ADD_USER_MODAL]: (dispatch = Store.dispatch) => {
    const user = getUser(Store.getState());
    const {
      canAddUser,
      acceptUsersFeature,
    }: { canAddUser: boolean; acceptUsersFeature: boolean } =
      getOpportunityDataToUseAddUserModal(user);

    if ((canAddUser && !acceptUsersFeature) || !canAddUser) {
      return;
    }

    const newSearchParams = addParamToSearchParamsAndReturnSearchParamsString({
      key: MODAL_SEARCH_PARAM_NAME,
      value: MODAL_NAMES.ADD_USER_MODAL,
    });
    const navigate = getNavigate();
    navigate({ search: newSearchParams });
    dispatch(openModal({ type: MODAL_NAMES.ADD_USER_MODAL }));
  },
};

export const showModalsIfNeeded = () => {
  const location = window.location;
  const search = createSearchParams(location.search);
  const modalsToOpen = search.getAll(MODAL_SEARCH_PARAM_NAME) || [];

  modalsToOpen.forEach((modalName) => {
    if (MODAL_OPEN_FUNCTIONS[modalName]) {
      setTimeout(() => {
        console.log(`Automatically open modal ${modalName}, from the url.`);

        MODAL_OPEN_FUNCTIONS[modalName]();
      }, 0);
    }
  });
};

// modify searchParams
const deleteSearchParamByKeyValue = (
  searchParams: URLSearchParams,
  key: string,
  valueToRemove: string
) => {
  const values = searchParams.getAll(key);

  if (!values) return searchParams;

  if (values.length <= 1) {
    searchParams.delete(key);

    return searchParams;
  }

  searchParams.delete(key);
  for (const value of values) {
    // Remember the values will have been converted to string
    if (value !== valueToRemove) {
      searchParams.append(key, value);
    }
  }

  return searchParams;
};

export const deleteSearchParamWhenCloseModal = (modalName: string) => {
  if (!MODAL_NAMES[modalName]) {
    console.log("Not registered modal name.");

    return;
  }

  const searchParams = createSearchParams(window.location.search);
  deleteSearchParamByKeyValue(searchParams, MODAL_SEARCH_PARAM_NAME, modalName);
  const navigate = getNavigate();
  navigate({ search: searchParams.toString() });
};
