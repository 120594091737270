export const PLATFORMS = {
  IG: {
    NAME: "instagram",
    TYPE: "IG",
    ORDER: 1,
    LABEL: "Instagram",
  },
  FB: {
    NAME: "facebook",
    TYPE: "FB",
    ORDER: 2,
    LABEL: "Facebook",
  },
  TW: {
    NAME: "twitter",
    TYPE: "TW",
    ORDER: 3,
    LABEL: "Twitter",
  },
  LI: {
    NAME: "linkedin",
    TYPE: "LI",
    ORDER: 4,
    LABEL: "LinkedIn",
  },
  GMB: {
    NAME: "googlemybusiness",
    TYPE: "GMB",
    ORDER: 5,
    LABEL: "Google Business Profile",
  },
  YT: {
    NAME: "youtube",
    TYPE: "YT",
    ORDER: 6,
    LABEL: "YouTube",
  },
  PI: {
    NAME: "pinterest",
    TYPE: "PI",
    ORDER: 7,
    LABEL: "Pinterest",
  },
  TT: {
    NAME: "tiktok",
    TYPE: "TT",
    ORDER: 8,
    LABEL: "TikTok",
  },
  TH: {
    NAME: "threads",
    TYPE: "TH",
    ORDER: 9,
    LABEL: "Threads",
  },
  SC: {
    NAME: "snapchat",
    TYPE: "SC",
    ORDER: 10,
    LABEL: "Snapchat",
    ONLY_MANUAL: true,
  },
  OT: {
    NAME: "other",
    TYPE: "OT",
    ORDER: 11,
    LABEL: "Other",
    ONLY_MANUAL: true,
  },
};

export const ONLY_MANUAL_PLATFORMS = Object.values(PLATFORMS)
  .filter(({ ONLY_MANUAL }) => ONLY_MANUAL)
  .map(({ TYPE }) => TYPE);
