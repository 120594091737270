import React, {
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import styled from "styled-components";

import { Alert, TooltipWrapper } from "ui";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Microlink from "@microlink/react";
import { PostStatus } from "components/Posts/Post/index";
import { PAGES } from "constants/Pages";
import { AlertType } from "ui/alert";
import { useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";
import { findManualPlatform } from "utils/accounts";
import { getAccount } from "selectors/skedCoreSelectors";
import { get } from "lodash";
import { FIELDS, FIELDS_DEFAULT } from "constants/PostFormFields";
import { PlatformNameToPlatformTypeMap } from "features/post/format-post";

import { useLabels } from "components/Posts/PostForm/components/labels-hooks.tsx";
import { useContentLabelSection } from "entities/posts/components/templates/content-label-section.tsx";
import { getAccountPlatformType } from "../../../../entities/accounts";

export function DetailsTemplate(props) {
  const user = useSelector(getUser);
  const account = useSelector((state) =>
    getAccount(
      state,
      props?.post?.accountId || props?.post?.accountIds?.[0] || ""
    )
  );

  const {
    isContentLabelFeatureEnabled,
    contentLabelOptions,
    selectedContentLabel,
    isFetchingAccountGroups,
  } = useLabels({
    postFormValue: {
      accountIds: [account?._id],
      labelIds: props?.post?.labelIds || [],
    },
  });
  const { renderLabels } = useContentLabelSection({
    isContentLabelFeatureEnabled,
    contentLabelOptions,
    selectedContentLabel,
    isSummary: true,
    postFormValue: {
      accounts: [account],
    },
    isFetchingAccountGroups,
  });

  const showURL = useMemo(() => {
    const isAccountWithNotificationsAndNormalPosting = [
      PlatformNameToPlatformTypeMap.tiktok,
      PlatformNameToPlatformTypeMap.threads,
    ].some((x) => x === account?.platformType);

    const isManualTTPost = account && findManualPlatform([account], "TT");
    const isManualTHPost = account && findManualPlatform([account], "TH");
    const isNotification = get(
      props.post,
      FIELDS.POST_IS_NOTIFICATION_ONLY,
      FIELDS_DEFAULT.IS_NOTIFICATION_POST_ONLY_DEFAULT
    );
    const isManualPostOrTTNotification =
      isManualTTPost || isManualTHPost || isNotification;

    return (
      props.post.redirectUrl &&
      (isAccountWithNotificationsAndNormalPosting
        ? isManualPostOrTTNotification
        : true)
    );
  }, [account, props.post]);

  return (
    <div className="tw-flex tw-flex-col">
      <DetailsContent>
        <PostStatus post={props.post} />
        <div className="tw-flex tw-flex-wrap">{props.renderAccount?.()}</div>

        {!isFetchingAccountGroups &&
          isContentLabelFeatureEnabled &&
          renderLabels && <LabelsWrap>{renderLabels()}</LabelsWrap>}

        {props.renderDetailsList?.()}
        {props.renderTags && <Tags>{props.renderTags()}</Tags>}
        {showURL && (
          <PostLink
            url={props.post.redirectUrl}
            errorMessage="Unable to load preview for invalid post URL"
          />
        )}
        {props.page === PAGES.UPCOMING &&
          user &&
          user.isPostStatusEnabledForUser() && (
            <AlertForUnpublishablePostStatus user={user} post={props.post} />
          )}
      </DetailsContent>
    </div>
  );
}

export function PostLink(props) {
  return (
    <ErrorBoundary
      errorMessage={<div className="tw-text-red-500">{props.errorMessage}</div>}
    >
      <Microlink
        url={props.url}
        className="tw-m-0"
        apiKey={"JgUARf2HjedOQkin6wQs2Ll11upPpWG5gJ1NTX9c"}
      />
    </ErrorBoundary>
  );
}

export const Caption = ({ post, expanded, label }) => {
  const [showMore, setShowMore] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  const noCaption = (
    <div className="tw-text-gray-300">
      {post.type === "story"
        ? "No manual publishing notes, or post is set to auto-publish."
        : "No caption entered."}
    </div>
  );

  const captionRef = useRef(null);
  const maxCaptionHeight = 100; // px set in less file. num lines * line height.

  const updateShowToggle = () => {
    const shouldShowMoreButton =
      captionRef.current && captionRef.current.offsetHeight > maxCaptionHeight;
    setShowToggle(shouldShowMoreButton);
  };

  useLayoutEffect(() => {
    updateShowToggle();
    window.addEventListener("resize", updateShowToggle);
    return () => window.removeEventListener("resize", updateShowToggle);
  }, []);

  const showCaptionOrNotesForManualPublishing = useMemo(() => {
    const isStoryType = post.type === "story";
    const isShowNotesForManualPublishing =
      isStoryType && post.publishStoryManually;

    return !isStoryType || isShowNotesForManualPublishing;
  }, [post.publishStoryManually, post.type]);

  if (!post.caption || post.caption.length === 0) {
    return noCaption;
  }

  return (
    <>
      <div
        className={`ListPostDetails-Caption tw-mt-4 ListPostDetails_${
          !showMore && !expanded ? "showLess" : "showMore"
        }`}
      >
        {showCaptionOrNotesForManualPublishing && (
          <PostDetail
            label={
              label
                ? label
                : post.type === "story"
                ? "Notes for manual publishing"
                : "Caption"
            }
          >
            <div ref={captionRef}>{post.caption}</div>
          </PostDetail>
        )}
      </div>
      {showToggle && !expanded && (
        <div className="tw-mb-8">
          <ShowMore post={post} showMore={showMore} setShowMore={setShowMore} />
        </div>
      )}
    </>
  );
};
const ShowMore = ({ showMore, setShowMore }) => {
  return (
    <a
      className="ListPostDetails-ShowMore"
      onClick={() => setShowMore(!showMore)}
    >
      {!showMore ? "Show more" : "Show less "}
    </a>
  );
};

export const DetailsContent = styled.div`
  padding-top: 1rem;
  > * {
    margin-bottom: 1rem;
  }
`;

export function PostDetail({ label, children }) {
  return (
    <PostDetailRoot data-testid="postDetail">
      <div className="tw-font-bold">{label}:</div>
      <div>{children}</div>
    </PostDetailRoot>
  );
}

const PostDetailRoot = styled.div`
  word-break: break-word;
`;

export const Tags = styled.div.attrs({
  className: "tw-flex tw-flex-col tw-text-xl",
})``;

export function Tag(props) {
  return (
    <div className="tw-flex tw-items-center tw-mb-2">
      <div className="tw-mr-4 tw-font-bold">{props.label}:</div>
      <div>{props.children}</div>
    </div>
  );
}

export function TagTooltip(props) {
  const content = props.tags.map((tag, index) => (
    <div key={`${tag.value}-${index}`}>{tag.value}</div>
  ));

  return (
    <TooltipWrapper tooltip={content} placement={"bottom"}>
      <div className="tw-cursor-default">{props.children}</div>
    </TooltipWrapper>
  );
}
export function AltTextTags({ post }) {
  let mediaWithAltText = 0;
  if (post.mediaFiles) {
    let filesWithAltText = post.mediaFiles.filter((file) => file.altText);
    mediaWithAltText = filesWithAltText.length;
  }
  // if mediaFiles is empty then use post.altText
  // if mediaFiles is > 0 then use values in that array
  return (
    <div data-testid={`altTextTag-${post._id}`}>
      {post.altText && mediaWithAltText <= 1 && (
        <Tag label="Alt text">{post.altText}</Tag>
      )}
      {mediaWithAltText > 1 && (
        <Tag label="Alt text">
          <span>{mediaWithAltText} media</span>
        </Tag>
      )}
    </div>
  );
}

const AlertForUnpublishablePostStatus = ({ user, post }) => {
  const userCollabSettings = user.getPostStatusList();
  let currentStatus = userCollabSettings.find((s) => {
    return post?.postStatusKey === s.key;
  });
  if (!currentStatus) {
    // the currently selected status does not exist
    // fixes a bug that caused it to throw an error
    return (
      <Alert type={AlertType.error}>
        <strong>
          This post is set to a status that does not exist or has been deleted.
        </strong>{" "}
        Please update the status of the post.
      </Alert>
    );
  } else if (currentStatus.isPublishingAllowed === true) {
    // if the current status is allowed to publish, then don't show a message
    return null;
  } else {
    return (
      <Alert type={AlertType.warning} icon={true}>
        This post will not be published as it is set to the status{" "}
        <strong>{currentStatus.label}</strong>. Make sure you change the post
        status before its publishing time.
      </Alert>
    );
  }
};

const LabelsWrap = styled.div`
  padding-top: 12px;
`;
