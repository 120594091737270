import { skedApi } from "../_skedApi";

export async function transcodeVideoByUrl(
  url: string,
  { onProgress }: { onProgress(progress: number): void }
): Promise<number> {
  const {
    data: { id: jobId },
  } = await skedApi.post<{ id: number }>(`/content-inbox/transcode/start`, {
    url,
  });

  let transcode;
  do {
    await wait(1000);
    transcode = await getJobStatus(jobId);
    onProgress(transcode.progress);
  } while (transcode.status === "processing");

  return jobId;
}

async function getJobStatus(jobId: number): Promise<JobStatus> {
  const { data } = await skedApi.get<JobStatusResponse>(
    `/content-inbox/transcode/${jobId}`
  );

  const urlKey = Object.keys(data.output_urls).find((key) =>
    key.startsWith("mp4")
  );
  const thumbnailKey = Object.keys(data.output_urls).find(
    (key) => key.startsWith("jpg") && key.endsWith("#start")
  );

  return {
    status: data.status,
    progress: parseInt(data.progress, 10),
    url: (urlKey && data.output_urls[urlKey]) ?? "",
    thumbnail: (thumbnailKey && data.output_urls[thumbnailKey]?.[0]) ?? "",
  };
}

async function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface JobStatusResponse {
  id: number;
  created_at: string;
  completed_at: string;
  status: string;
  progress: string;
  output_urls: { [key: string]: string };
}

interface JobStatus {
  progress: number;
  status: string;
  url: string;
  thumbnail: string;
}
