// parse post from any format to Post type
import { DATE_FORMAT } from "constants/common";
import {
  NotificationPerson,
  PlatformTypes,
  Post,
  PostStates,
  PostType,
  TikTokPost,
  ThreadsPost,
  YoutubePost,
  IgCollabPerson,
} from "./types";
import moment from "moment";
import { useSelector } from "react-redux";
import { PostMediaFile } from "shared/types/post";
import { removeZeroWidthSpace } from "utils/text";
import { FIELDS_DEFAULT } from "constants/PostFormFields";
import { localStorageFeatureToggle } from "utils/feature-toggle-utils";

export const groupAccountIdsByPlatformType = (
  allAccounts: { [key: string]: Account },
  accountsNeedGrouped: string[]
) => {
  return accountsNeedGrouped.reduce((acum, accountId) => {
    const account = allAccounts[accountId];

    if (!account) return acum;

    const platformType = account.platformType;

    acum[platformType] = [...(acum[platformType] || []), accountId];

    return acum;
  }, {});
};

export function parseFormDataToPost(
  { post, newPostState, platformFields }: FormData,
  accounts: { [key: string]: Account }
): Post | Post[] {
  const excludedPlatformsTypes = Object.keys(platformFields);

  // if posts has one account or (many accounts and no any unlinked fields) just return one post
  if (excludedPlatformsTypes.length === 0) {
    return parseFormPostToTypedPost(post, accounts, newPostState);
  } else {
    const postAccountId = post.accountId;
    const normalizedAccounts = Object.keys(accounts).reduce((acum, id) => {
      acum[id] = normalizeAccount(accounts[id]);

      return acum;
    }, {} as { [key: string]: Account });

    const groupedAccountIdsByPlatformType = groupAccountIdsByPlatformType(
      normalizedAccounts,
      post.accountIds.filter((accountId) => accountId !== postAccountId)
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...initialPost } = post;

    const newPosts: Post[] = [];

    // post can no has one accountId (e.g. draft post), so we need to separate (one part is edit post and others is new posts)
    const postAccount = normalizedAccounts[postAccountId] || {};
    const postPlatformType =
      postAccountId && (post.platformType || postAccount.platformType)
        ? post.platformType || postAccount.platformType
        : excludedPlatformsTypes[0];
    const postAccountIds = postAccountId
      ? [postAccountId]
      : groupedAccountIdsByPlatformType[postPlatformType];

    const editPost: Post | undefined = post._id
      ? parseFormPostToTypedPost(
          {
            ...post,
            ...(platformFields[postPlatformType] || {}),
            accountIds: postAccountIds,
          },
          accounts,
          newPostState
        )
      : undefined;

    let addedAccounts: string[] = [...postAccountIds];
    post.accountIds.forEach((accountId) => {
      if (addedAccounts.includes(accountId)) return;

      const account = normalizedAccounts[accountId];
      // post can has many accounts by one type (e.g. facebook account1, facebook account2, ...),
      // this is combine to one post (we not separate posts by type and account)
      const newPostAccountIds =
        groupedAccountIdsByPlatformType[account.platformType];
      // not generate new post with the same accounts
      addedAccounts = [...addedAccounts, ...newPostAccountIds];

      const newPost: Post = parseFormPostToTypedPost(
        {
          ...initialPost,
          ...platformFields[account.platformType],
          accountIds: newPostAccountIds,
        },
        accounts,
        newPostState
      );

      newPosts.push(newPost);
    });

    return [...(editPost ? [editPost] : []), ...newPosts];
  }
}

function normalizeAccount(account) {
  // some accounts don't have platformType
  return {
    ...account,
    platformType: PlatformNameToPlatformTypeMap[account.platformName],
  };
}

export const PlatformNameToPlatformTypeMap = {
  instagram: "IG",
  facebook: "FB",
  twitter: "TW",
  linkedin: "LI",
  googlemybusiness: "GMB",
  youtube: "YT",
  pinterest: "PI",
  tiktok: "TT",
  threads: "TH",
  snapchat: "SC",
  other: "OT",
};

export function parseFormPostToTypedPost(
  post: FormPost,
  accounts: { [key: string]: Account },
  newPostState: string
): Post & { isNow?: boolean } {
  const state = getPostTypeFromFormState(newPostState);
  // ensure isNow is set to false for drafts or queued posts
  // as they can't be published 'now'
  let isNow = post.isNow;
  if (
    (state === PostStates.draft || state === PostStates.queue) &&
    isNow === true
  ) {
    isNow = false;
  }
  const postWithoutState = parsePostToTypesPostWithoutState(post, accounts);

  const postWithState = {
    ...postWithoutState,
    state,
    isNow: isNow,
    when:
      [PostStates.schedule, PostStates.queue, PostStates.archived].includes(
        state
      ) && !isNow
        ? moment(post.when).format(DATE_FORMAT)
        : undefined,
  };

  return postWithState;
}

// we have to use it till we don't know about post state
export function parsePostToTypesPostWithoutState(
  post,
  accounts: { [key: string]: Account }
): Omit<Post, "state" | "when"> {
  const accountIds = post.accountIds || [post.accountId];

  const platformType = getPostPlatform({ accountIds }, accounts);

  const postPlatforms = post.accountIds.map((accountId: string) => {
    const platformType = accounts[accountId].platformType;

    if (platformType) {
      return platformType;
    } else {
      return "IG";
    }
  });

  const commonPostInfo = {
    _id: post._id,
    isSuccess: post.isSuccess,
    accountIds,
    labelIds: post.labelIds || [],
    channel: post.channel,
    caption: removeZeroWidthSpace(post.caption),
    postStatusKey: post.postStatusKey,
    ...parsePostMediaFiles(post),
    when: post.when,
    redirectUrl: post.redirectUrl,
    platforms: postPlatforms,
  };

  const hasIG = postPlatforms.includes("IG");
  const hasGMB = postPlatforms.includes("GMB");
  const hasPI = postPlatforms.includes("PI");
  const hasYT = postPlatforms.includes("YT");
  const hasTT = postPlatforms.includes("TT");
  const hasTH = postPlatforms.includes("TH");

  switch (platformType) {
    case PlatformTypes.instagram: {
      return {
        ...commonPostInfo,
        firstcomment: removeZeroWidthSpace(post.firstcomment),
        platformType: PlatformTypes.instagram,
        location: post.location,
        tags: post.tags || [],
        productTags: post.productTags || [],
        isStory: post.isStory,
        ...(post.isStory
          ? {
              storyType: post.storyType,
              storyUrl: post.storyUrl,
              storyLabel: post.storyLabel,
              storyStickerHeight: post.storyStickerHeight || 0.8,
              publishStoryManually: post.publishStoryManually,
            }
          : {}),
        ...parseInstagramData(post),
      };
    }
    case PlatformTypes.facebook: {
      return {
        ...commonPostInfo,
        platformType: PlatformTypes.facebook,
        location: post.location,
      };
    }
    case PlatformTypes.twitter: {
      return {
        platformType: PlatformTypes.twitter,
        ...commonPostInfo,
      };
    }
    case PlatformTypes.linkedin: {
      return {
        platformType: PlatformTypes.linkedin,
        firstcomment: removeZeroWidthSpace(post.firstcomment),
        ...commonPostInfo,
        ...parseLinkedInData(post),
      };
    }
    case PlatformTypes.pinterest: {
      return {
        platformType: PlatformTypes.pinterest,
        ...commonPostInfo,
        ...parsePinterestData(post),
      };
    }
    case PlatformTypes.googlemybusiness: {
      // TODO
      return {
        platformType: PlatformTypes.googlemybusiness,
        ...commonPostInfo,
        ...parseGoogleBusinessData(post),
      };
    }
    case PlatformTypes.youtube: {
      return {
        platformType: PlatformTypes.youtube,
        ...commonPostInfo,
        ...parseYoutubeData(post),
      };
    }
    case PlatformTypes.snapchat: {
      return {
        platformType: PlatformTypes.snapchat,
        ...commonPostInfo,
      };
    }
    case PlatformTypes.tiktok: {
      return {
        platformType: PlatformTypes.tiktok,
        ...commonPostInfo,
        ...parseTikTokData(post),
      };
    }
    case PlatformTypes.threads: {
      return {
        platformType: PlatformTypes.threads,
        ...commonPostInfo,
        ...parseThreadsData(post),
      };
    }
    case PlatformTypes.other: {
      return {
        platformType: PlatformTypes.other,
        ...commonPostInfo,
      };
    }
    case PlatformTypes.cluster: {
      return {
        ...commonPostInfo,
        firstcomment: post.firstcomment,
        platformType: PlatformTypes.cluster,
        location: post.location,
        tags: post.tags,
        productTags: post.productTags,
        ...(hasGMB ? parseGoogleBusinessData(post) : {}),
        ...(hasPI ? parsePinterestData(post) : {}),
        ...(hasYT ? parseYoutubeData(post) : {}),
        ...(hasTT ? parseTikTokData(post) : {}),
        ...(hasIG ? parseInstagramData(post) : {}),
        ...(hasTH ? parseThreadsData(post) : {}),
      };
    }
  }
}

export const parseGoogleBusinessData = function (post: NgPost) {
  return {
    gmbTopicType: post.gmbTopicType,
    ...(post.gmbTopicType === "OFFER"
      ? {
          gmbEvent: {
            title: post.gmbEvent?.title,
            // Will send to backend as in user timezone - on creation we will need to parse to UTC
            startDate: post.gmbEvent?.startDate,
            endDate: post.gmbEvent?.endDate,
          },
          gmbOffer: post.gmbOffer,
        }
      : {}),
    ...(post.gmbTopicType === "STANDARD"
      ? {
          gmbCallToAction: post.gmbCallToAction,
        }
      : {}),
    ...(post.gmbTopicType === "EVENT"
      ? {
          gmbEvent: post.gmbEvent,
          gmbCallToAction: post.gmbCallToAction,
        }
      : {}),
    ...(post.gmbTopicType === "ALERT"
      ? {
          gmbCallToAction: post.gmbCallToAction,
        }
      : {}),
  };
};

function parsePinterestData(post: NgPost) {
  return {
    piBoards: post.piBoards || [],
    piSectionId: post.piSectionId || null,
    piTitle: post.piTitle || null,
  };
}

function parseLinkedInData(post: NgPost) {
  return {
    liTargetEntities: post.liTargetEntities || [],
    // LI document posts
    fileName: post.fileName || undefined,
    meta: post.meta || {},
  };
}

function parseYoutubeData(post: YoutubePost) {
  return {
    ytTitle: post.ytTitle,
    ytCategoryId: post.ytCategoryId,
    ytTags: post.ytTags || null,
    ytPrivacy: post.ytPrivacy,
  };
}

export function parseInstagramData(post: NgPost) {
  const {
    videoCoverPreview,
    videoCoverImageTimestamp,
    thumbnailUrl,
    collaboratingIgUsers,
  } = post;

  return {
    thumbnailUrl,
    videoCoverPreview,
    videoCoverImageTimestamp,
    collaboratingIgUsers,
  };
}

export function parseTikTokData(post: TikTokPost): Partial<TikTokPost> {
  const {
    // default values
    videoCoverImageTimestamp,
    videoCoverPreview,
    thumbnailUrl,
    postOptions = {
      isNotification: true,
      allowComments: true,
      allowStitch: true,
      allowDuet: true,
      title: "",
      autoAddMusic: true,
      privacyLevel: FIELDS_DEFAULT.TT_PRIVACY_LEVEL,
      photoCoverIndex: 0,
    },
  } = post;

  return {
    thumbnailUrl,
    videoCoverImageTimestamp,
    videoCoverPreview,
    postOptions: {
      isNotification: postOptions.isNotification,
      allowComments: postOptions.allowComments,
      allowStitch: postOptions.allowStitch,
      allowDuet: postOptions.allowDuet,
      title: postOptions.title,
      autoAddMusic: postOptions.autoAddMusic,
      privacyLevel:
        postOptions.privacyLevel as TikTokPost["postOptions"]["privacyLevel"],
      photoCoverIndex: postOptions.photoCoverIndex,
    },
  };
}
export function parseThreadsData(post: ThreadsPost) {
  const {
    postOptions = {
      replyControl: "everyone",
    },
  } = post;

  return {
    postOptions: {
      replyControl: postOptions.replyControl,
    },
  };
}

export interface NgPost {
  altText?: string;
  accountName: string;
  clientId: string;
  url: string;
  isVideo: boolean;
  origUrl: string;
  thumbnailUrl: string;
  fileName: string;
  caption: string;
  firstcomment: string;
  redirectUrl: any;
  type: string;
  storyType?: string;
  storyUrl?: any;
  storyLabel?: string;
  storyStickerHeight?: number;
  publishStoryManually: boolean;
  when: string;
  tags: Tag[];
  productTags: any[];
  labelsId: any[];
  postStatusKey: string;
  mediaFiles: NgMediaFile[];
  media: any[];
  platforms: any[];
  originalUrl: string;
  postedBy: string;
  channel: string;
  hash: any;
  location: any;
  platformType: any;
  isStory: boolean;
  _id: string;
  accountIds: string[];
  accountId: string;
  unreadCommentsCount: number;
  meta?: object;
  ytTitle?: string;
  ytPrivacy?: string;
  ytCategoryId?: number | string;
  ytTags?: string | null;
  gmbTopicType?: string;
  gmbCallToAction?: object;
  gmbEvent?: {
    title?: string;
    startDate?: Date;
    endDate?: Date;
  };
  gmbOffer?: object;
  piBoards?: any[];
  piSectionId?: string;
  piTitle?: string;
  pushNotificationMeta?: NotificationPerson[];
  isPosting?: boolean;
  isQueued?: boolean;
  queued?: boolean;
  isSuccess?: boolean;
  isCanceled?: boolean;
  isPotentialError?: boolean;
  liTargetEntities?: {
    [key: string]: string[];
  };
  videoCoverImageTimestamp?: number;
  videoCoverPreview?: string;
  collaboratingIgUsers?: IgCollabPerson[];
}

export interface Tag {
  value: string;
  top: string;
  left: string;
  coordinates: Coordinates;
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface NgMediaFile {
  altText?: string;
  fileName: string;
  isVideo: boolean;
  origUrl: unknown;
  originalUrl?: unknown;
  asset_id?: string;
  thumbnailUrl: string;
  type: string;
  url: string;
  meta?: object;
}

export function parseNgDataToPost(
  ngPost: FormPost,
  accounts: { [key: string]: Account },
  previousPostState: string
): Post {
  return parseFormPostToTypedPost(ngPost, accounts, previousPostState);
}

export function getPostTypeFromFormState(state: string): PostStates {
  return (
    {
      queued: PostStates.queue,
      scheduled: PostStates.schedule,
      // TODO: replace with correct backend fix
      // 'upcoming' comes from the backend
      upcoming: PostStates.schedule,
      draft: PostStates.draft,
      archived: PostStates.archived,
    }[state.toLowerCase()] ?? PostStates.draft
  );
}

export function getPostState(post: Post) {
  if (!post.when) {
    return PostStates.draft;
  }
  return post.queued ? PostStates.queue : PostStates.schedule;
}

function parsePostMediaFiles(post: Post): {
  mediaFiles: PostMediaFile[];
  type: PostType;
} {
  const mediaFiles = (post.mediaFiles || []).map(parseMediaFile);

  if (mediaFiles.length === 0 && post.url) {
    mediaFiles.push(parsePostAsMediaFile(post));
  }

  const type = (() => {
    if (post.isStory || post.type === "story") {
      return "story";
    }
    if (post.mediaFiles && post.mediaFiles.length > 1) {
      return "multiMedia";
    } else if (post.type === "multiMedia") {
      // this post should not be multiMedia anymore as no mediaFiles included…
      // should not have any text posts here as no way to convert from multiMedia to text, so assume either video or image
      if (post.url?.includes(".mp4") || mediaFiles[0].type?.includes("video")) {
        return "video";
      } else if (
        post.url?.includes(".pdf") ||
        mediaFiles[0].type?.includes("pdf")
      ) {
        return "document";
      } else {
        if (post.url || mediaFiles[0].type?.includes("image")) {
          return "image";
        } else {
          return "text";
        }
      }
    } else {
      if (!post.url) {
        return "text";
      }
      if (post.url) {
        if (
          post.url.includes(".jpg") ||
          post.url.includes(".jpeg") ||
          post.url.includes(".png") ||
          mediaFiles[0].type?.includes("image")
        ) {
          return "image";
        } else if (
          post.url.includes(".mp4") ||
          post.url.includes(".gif") ||
          mediaFiles[0].type?.includes("video")
        ) {
          return "video";
        } else if (
          post.url.includes(".pdf") ||
          mediaFiles[0].type?.includes("pdf")
        ) {
          return "document";
        } else {
          return "multiMedia";
        }
      } else {
        return "multiMedia";
      }
    }
  })();

  return {
    mediaFiles,
    type,
  };
}

function parsePostAsMediaFile(post: Post): PostMediaFile {
  const { type, ...data } = post;
  return parseMediaFile(data);
}

export function parseMediaFile(mediaFile: NgMediaFile): PostMediaFile {
  const originalUrl = mediaFile.originalUrl || mediaFile.origUrl;
  const hasFileVideoExt = mediaFile.url.endsWith(".mp4");
  const hasPdfFileExt = mediaFile.url.endsWith(".pdf");

  // delete icc if it is present in the meta object
  // otherwise we can get 413 error when creating posts
  // because the meta object is too large - and icc is not
  // used by our backend. icc is usually a giant blob of binary
  if (mediaFile.meta?.icc) {
    delete mediaFile.meta.icc;
  }
  return {
    originalUrl,
    altText: mediaFile.altText || "",
    url: mediaFile.url,
    asset_id: mediaFile.asset_id,
    thumbnailUrl: mediaFile.thumbnailUrl,
    type:
      mediaFile.type ??
      (hasFileVideoExt ? "video" : hasPdfFileExt ? "document" : "image"),
    isVideo: mediaFile.isVideo || hasFileVideoExt,
    fileName: mediaFile.fileName,
    meta: mediaFile.meta as PostMediaFile["meta"],
  };
}

export function getPostPlatform(
  post: { accountIds: string[] },
  accounts: { [key: string]: Account }
): PlatformTypes {
  if (post.accountIds.length === 1) {
    return PlatformNameToTypeMap[accounts[post.accountIds[0]]?.platformName];
  }

  const platformsByName = post.accountIds.reduce(
    (map, accountId) => ({
      ...map,
      [accounts[accountId].platformName]: true,
    }),
    {}
  );

  const platformsCount = Object.keys(platformsByName).length;

  if (platformsCount === 1) {
    return PlatformNameToTypeMap[accounts[post.accountIds[0]].platformName];
  }

  return PlatformTypes.cluster;
}

const PlatformNameToTypeMap = {
  facebook: PlatformTypes.facebook,
  instagram: PlatformTypes.instagram,
  twitter: PlatformTypes.twitter,
  linkedin: PlatformTypes.linkedin,
  googlemybusiness: PlatformTypes.googlemybusiness,
  youtube: PlatformTypes.youtube,
  pinterest: PlatformTypes.pinterest,
  snapchat: PlatformTypes.snapchat,
  tiktok: PlatformTypes.tiktok,
  threads: PlatformTypes.threads,
  other: PlatformTypes.other,
};

// Hugh 28 Aug 2021: I think that this function is not used??
export function useTypedPost(post) {
  const accounts = useSelector((state) => state.entities.accounts.byId);
  return parsePostToTypesPostWithoutState(post, accounts);
}

// parsed types generated by https://transform.tools/json-to-typescript
interface Account {
  _id: string;
  active: boolean;
  login: string;
  platformId: string;
  status: string;
  legit: boolean;
  followed_by: number;
  profilePictureUrl: string;
  retrySetting: string;
  platformType: string;
  updatedAt: string;
  createdAt: string;
  queueEnabled: boolean;
  platformName: string;
  boards?: any[];
}

export interface FormData {
  originalPostState: string;
  newPostState: string;
  post: FormPost;
  platforms: Platform[];
  platformFields: {
    [key: string]: { unlinked: boolean };
  };
}

export interface FormPost {
  accountName: string;
  clientId: string;
  url: string;
  isVideo: boolean;
  origUrl: string;
  thumbnailUrl: string;
  fileName: string;
  caption: string;
  firstcomment: string;
  redirectUrl: any;
  type: string;
  storyUrl: any;
  storyLabel?: string;
  storyStickerHeight?: number;
  publishStoryManually: boolean;
  when: any;
  isNow: boolean;
  tags: any[];
  productTags: any[];
  labelsId: any[];
  postStatusKey: string;
  mediaFiles: NgMediaFile[];
  media: any[];
  platforms: any[];
  originalUrl: string;
  postedBy: string;
  channel: string;
  hash: any;
  location: any;
  platformType: any;
  isStory: boolean;
  storyType?: string;
  _id?: string;
  accountId: string;
  unreadCommentsCount: number;
  accounts: Account[];
  accountIds: string[];
  ytTitle?: string;
  ytPrivacy?: string;
  ytCategoryId?: number | string;
  ytTags?: string | null;
  gmbTopicType?: string;
  gmbCallToAction?: object;
  gmbEvent?: {
    title?: string;
    startDate?: Date;
    endDate?: Date;
  };
  gmbOffer?: object;
  meta?: object;
  altText?: string;
  piBoards?: any[];
  piSectionId?: string;
  piTitle?: string;
  liTargetEntities?: {
    [key: string]: string[];
  };
  videoCoverImageTimestamp?: number;
  collaboratingIgUsers?: IgCollabPerson[];
}

interface Account {
  _id: string;
  login: string;
  androidIdNew: string;
  active: boolean;
  uses79wilson: boolean;
  status: string;
  retrySetting: string;
  errorNotifications: ErrorNotifications;
  profilePictureUrl: string;
  followed_by: number;
  updatedAt: string;
  createdAt: string;
  plannerMeta: PlannerMeta;
  failReason: string;
  promptApiReconnect: boolean;
  userDisplayReason: string;
  queueEnabled: boolean;
  platformName: string;
  boards?: any[];
}

interface ErrorNotifications {
  enabled: boolean;
  emailAddresses: any[];
}

interface PlannerMeta {
  userId: string;
  userName: string;
  epoch: number;
}

export interface Platform {
  NAME: string;
  TYPE: string;
  ORDER: number;
  LABEL: string;
}
