export interface CollectionFile {
  _id: string;
  source: Source;
  name: string;
  description: string;
  tags: string[];
  favorite: boolean;
  active: boolean;
  storage: Storage;
  thumbnail: string;
  gifThumbnail?: string;
  inbox_id: string;
  created: Date;
  updated: Date;
  meta: Meta;
  credential: Credential;
  original: boolean;
  asset_id: string;
  __v: number;
  downloadUrl: string;
  picker?: string;
  localId?: string;
}

export interface Source {
  name: string;
  notes?: any;
  value: string;
}

export interface Storage {
  bucket: string;
  key: string;
  url: string;
}

export interface Label {
  locations: any[];
  properties: any[];
  mid: string;
  locale: string;
  description: string;
  score: number;
  confidence: number;
  topicality: number;
  boundingPoly?: any;
}

export interface Google {
  labels: Label[];
}

export interface Image {
  Make: string;
  Model: string;
  Software: string;
  ModifyDate: Date;
  Artist: string;
  Copyright: string;
  ExifOffset: number;
  XPAuthor: number[];
  Padding: string;
}

export interface Exif2 {
  ISO: number;
  ExifVersion: string;
  DateTimeOriginal: Date;
  DateTimeDigitized: Date;
  SubSecTimeOriginal: string;
  SubSecTimeDigitized: string;
  ColorSpace: number;
  PixelXDimension: number;
  PixelYDimension: number;
  Padding: string;
}

interface Exif {
  image: Image;
  exif: Exif2;
}

interface Meta {
  format: string;
  size: number;
  width: number;
  height: number;
  space: string;
  channels: number;
  depth: string;
  density: number;
  isProgressive: boolean;
  hasProfile: boolean;
  hasAlpha: boolean;
  // icc: string; => remove icc as we don't use it and it causes 413s
  isVideo: boolean;
  isImage: boolean;
  duration?: number;
  google: Google;
  chromaSubsampling: string;
  exif: Exif;
  isDocument?: boolean;
  icc?: string;
}

interface Credential {
  admin: boolean;
  auth_key: string;
  ext_auth: boolean;
}

export enum MediaSort {
  addedNewestToOldest = "-created",
  addedOldestToNewest = "created",
  updatedNewestToOldest = "-updated",
  updatedOldestToNewest = "updated",
  favoritedFirst = "favorited -created",
  alphabetical = "name",
  alphabeticalReverse = "-name",
}

export enum MediaSource {
  upload = "Upload",
  feed = "Feed",
  instagramHashTags = "Instagram hashtag feed",
  instagramTagged = "Instagram tagged in feed",
  zapier = "Zapier",
  chromeExtension = "Chrome Extension",
}

export interface MediaFilters {
  sort: MediaSort;
  name: string;
  description?: string;
  sourceName?: MediaSource;
  tags: string[];
  excludeAccounts: string[];
  favorite: boolean;
  mediaType: "image" | "video" | null;
  usage: "used" | "unused" | null;
}
