import {
  INIT_GLOBALS,
  INIT_POSTS_PAGE_ROUTINE,
  UPDATE_POSTS_PAGE_NAME,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";

const initialState = "";

const changePage = (state, action) => {
  const { page } = action.payload;

  if (page) {
    state = page;
  }

  return state;
};

export const pageReducer = createReducer(initialState, {
  [INIT_GLOBALS]: changePage,
  [UPDATE_POSTS_PAGE_NAME]: changePage,
  [INIT_POSTS_PAGE_ROUTINE.TRIGGER]: changePage,
});
