import React, { useMemo, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Modal } from "react-bootstrap";

import { TextInput } from "ui";
import { Flex } from "ui/commonStyled/layout";
import { Button, FormSize } from "ui/ecosystems/forms";
import { ModalHeader } from "ui/giselle-modal";

interface DeleteUserTypes {
  title: string;
  subTitle?: string | JSX.Element;
  defaultConfirmText?: string;
  confirmButtonText?: string;
  children?: JSX.Element;
  onSubmit: () => void;
  closeModal: () => void;
  closeButtonText?: string;
  dataTestId?: string;
  confirmBeforeAction?: boolean;
  isAlert?: boolean;
}

export const ConfirmWithText = (props: DeleteUserTypes) => {
  const {
    title,
    subTitle,
    children,
    defaultConfirmText = "Delete",
    onSubmit,
    closeModal,
    confirmButtonText,
    closeButtonText = "Close",
    dataTestId,
    confirmBeforeAction = true,
    isAlert = true,
  } = props;

  const [confirmText, setConfirmText] = useState("");

  const isDeleteDisabled = useMemo(() => {
    if (!confirmBeforeAction) {
      return false;
    }

    return (
      !confirmText ||
      confirmText?.trim().toLocaleLowerCase() !== defaultConfirmText
    );
  }, [confirmText, defaultConfirmText, confirmBeforeAction]);

  return (
    <Modal
      data-testid={dataTestId}
      show
      onHide={closeModal}
      animation={false}
      // @ts-ignore
      bsStyle="giselle user-delete"
      bsSize="md-reasonable"
    >
      <BlockOutsideDelete />
      <ModalContainer>
        <ModalHeaderStyled title={title} onHide={closeModal} />
        {subTitle && <SubHeader>{subTitle}</SubHeader>}

        <ModalBody>
          {children}

          {confirmBeforeAction && (
            <TextInputStyled
              data-testid="removeAccountField"
              placeholder={defaultConfirmText}
              value={confirmText}
              onChange={setConfirmText}
            />
          )}
        </ModalBody>
        <ModalActions justify="space-between" align="center">
          <ActionButton outline size={FormSize.medium} onClick={closeModal}>
            {closeButtonText}
          </ActionButton>
          <ActionButton
            data-testid="confirmDelete"
            alert={isAlert || undefined}
            primary={!isAlert || undefined}
            size={FormSize.medium}
            className={isAlert ? "danger" : ""}
            onClick={onSubmit}
            disabled={isDeleteDisabled}
          >
            {confirmButtonText ? confirmButtonText : "Confirm"}
          </ActionButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

const Text = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
`;

const ModalContainer = styled(Modal.Body)`
  padding: 28px !important;
`;
const ModalBody = styled.div`
  margin-top: 30px;
`;
const ModalHeaderStyled = styled(ModalHeader)`
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
`;

const ModalActions = styled(Flex)`
  margin-top: 51px;
`;

const SubHeader = styled(Text)`
  margin-top: 16px;
`;

const ActionButton = styled(Button)`
  min-width: 120px;
  padding: 0 23px;
`;

// block mouse events style
const BlockOutsideDelete = createGlobalStyle`
  .user-delete:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
`;

const TextInputStyled = styled(TextInput)`
  width: 192px;
  margin-top: 21px;
`;
