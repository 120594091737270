/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { useTrackEvent } from "libs/analytics";

//selectors/
import { getLoading, getUser } from "selectors/commonSelectors";
//constants
import { FIELDS } from "constants/PostFormFields";

//components

import { sortAccounts } from "utils/accounts";
import { countCharacter } from "utils/strings";

import LinkedinTargetAudience from "../LinkedinTargetAudience/LinkedinTargetAudience";
import YouTubeTags from "../YouTubeFields/YouTubeTags";
import PostTags from "../PostTags/PostTags";
import PostUrl from "../PostUrl/PostUrl";
import PostFirstComment from "../PostFirstComment/PostFirstComment";
import PostCaption from "../PostCaption/PostCaption";
import PinterestFields from "../PinterestFields/PinterestFields";
import YouTubeTitle from "../YouTubeFields/YouTubeTitle";
import YouTubeCategory from "../YouTubeFields/YouTubeCategory";
import GmbFields from "../GmbFields/GmbFields";
import { AltTextModal } from "../modals/alt-text";
import { PlatformFieldsOverlay } from "./PlatformFieldsOverlay";
import YouTubePrivacy from "components/Posts/PostForm/YouTubeFields/YouTubePrivacy";
import { getLinkedinTargetEntriesLabels } from "components/Posts/Post/PostDetails/linkedIn-details";
import { SwitchIsStoryOrNotification } from "./SwitchIsStoryOrNotification";
import { TiktokSwitchNotification } from "../TiktokFields/TiktokSwitchNotification";
import { ThreadsSwitchNotification } from "../threadsFields/ThreadsSwitchNotification";
import { StoryFields } from "./StoryFields";
import { TiktokSwitchAllows } from "../TiktokFields/TiktokSwitchAllows";
import { PostAlerts } from "./PostAlerts";
import { useVideoCover, VideoCoverSelector } from "./VideoCoverSelector";
import ThreadsReplyControl from "../threadsFields/ThreadsReplyControl";

export function getLinkedInAccountDetail(accounts) {
  const linkedInAccounts = accounts.filter(
    (profile) => profile.platformType === "LI"
  );
  const hasMoreThanOnelinkedInAccount = linkedInAccounts.length >= 2;
  let selectedLinkedInAccountType = "",
    selectedLinkedUrn = "";

  if (linkedInAccounts.length === 1) {
    const linkedInProfile = linkedInAccounts[0];

    if (linkedInProfile.linkedinUrn?.includes("person")) {
      selectedLinkedInAccountType = "personal";
    } else {
      selectedLinkedInAccountType = "company";
      selectedLinkedUrn =
        linkedInAccounts.length === 1 &&
        linkedInProfile.session &&
        linkedInProfile.session.scopes &&
        linkedInProfile.session.scopes.includes("r_organization_followers") &&
        linkedInProfile.linkedinUrn;
    }
  }

  return {
    hasMoreThanOnelinkedInAccount,
    selectedLinkedInAccountType,
    selectedLinkedUrn,
  };
}

export const SinglePlatformPostForm = (props) => {
  const trackEvent = useTrackEvent();

  const {
    post,
    postFormValue,
    errors,
    warnings,
    platforms,
    platformFields,
    formAccounts,
    updateFields,
    updateField,
    platformPost,
    selectedPlatform,
    visibleFields,
    isSummary,
    hasPostTags,
    hasTikTokPosting,
    oldTiktokAccounts,
    ttIsNotification,
    hasThreadsPosting,
    oldThreadsAccounts,
    thIsNotification,
    showUrlField,
  } = props;
  const [openAddAlt, setOpenAddAlt] = useState(false);

  const [showTargetAudience, setShowTargetAudience] = useState(false);
  const [audience, setAudience] = useState([]);

  // When user clicks outside from PostForm we shows him confirmation window
  const ref = useRef(null);
  const user = useSelector(getUser);
  const isLoading = useSelector(getLoading);
  // Warning: If we are using this for creat single post we need to make sure user is initialized properly.
  const fieldsRef = useRef(null);
  const sortedAccounts = sortAccounts(Object.values(formAccounts || []));
  const audiencesLabelArray = useMemo(() => {
    return getLinkedinTargetEntriesLabels(audience);
  }, [audience]);

  useEffect(() => {
    if (postFormValue?.liTargetEntities) {
      setAudience(postFormValue?.liTargetEntities);
    }
  }, [postFormValue.liTargetEntities]);

  const allDisabled = sortedAccounts.length === 0 || isLoading || post.past;
  let captionHashtagCount = countCharacter(postFormValue?.caption ?? "", "#");
  let firstCommentHashtagCount = countCharacter(
    postFormValue?.firstcomment ?? "",
    "#"
  );
  const hasInsta = sortedAccounts.find(
    (account) => account.platformName === "instagram"
  );

  if (hasInsta) {
    captionHashtagCount = firstCommentHashtagCount =
      captionHashtagCount + firstCommentHashtagCount;
  }

  const {
    showVideoSelector,
    isLoading: isLoadingVideo,
    videoMedia,
    videoUrl,
    thumbnails,
  } = useVideoCover({
    platformPost,
    selectedPlatform,
    platforms: platforms,
  });

  if (!platformPost) {
    return "";
  }

  function handleMediaUpdate(mediaFiles) {
    updateField(FIELDS.MEDIA, mediaFiles);
  }

  function handleImageAltUpdate(value) {
    updateField(FIELDS.ALT_TEXT, value);
  }

  function handleOpenAddAlt() {
    setOpenAddAlt(!openAddAlt);
  }

  function handleTargetAudienceShowModal() {
    trackEvent({
      eventName: "click-linkedin-targeting",
      useServices: ["AMP", "IC"],
    });
    setShowTargetAudience(true);
  }

  function handleTargetAudienceCloseModal(audience) {
    if (audience) {
      setAudience(audience);
      updateField(FIELDS.LI_TARGET_ENTITIES, {
        ...audience,
      });
    }

    setShowTargetAudience(false);
  }

  const {
    hasMoreThanOnelinkedInAccount,
    selectedLinkedInAccountType,
    selectedLinkedUrn,
  } = getLinkedInAccountDetail(postFormValue.accounts);

  // @codeme Break this up into smaller components
  // todo: remove all logic for clusterd
  return (
    <>
      {/* Wrapper for platform fields */}
      <>
        {/* Wrapper for platform fields */}
        <Container ref={ref}>
          {/* Overlay for a linked platform */}
          {selectedPlatform !== "LINKED" &&
            platforms.length > 1 &&
            (!platformFields[selectedPlatform] ||
              platformFields[selectedPlatform].unlinked !== true) && (
              <PlatformFieldsOverlay fieldsRef={fieldsRef} />
            )}
          {/* Story Toggle */}
          {platforms.length === 1 && platforms[0].TYPE === "IG" && (
            <SwitchIsStoryOrNotification
              postFormValue={postFormValue}
              platforms={platforms}
              platformFields={platformFields}
              updateField={updateField}
              selectedPlatform={selectedPlatform}
              allDisabled={allDisabled}
            />
          )}

          <PostAlerts
            postFormValue={postFormValue}
            oldTiktokAccounts={oldTiktokAccounts}
            platforms={platforms}
            selectedPlatform={selectedPlatform}
            ttIsNotification={ttIsNotification}
            oldThreadsAccounts={oldThreadsAccounts}
            isThreadsPostingEnabled={user.preferences?.enableThreadsPosting}
            thIsNotification={thIsNotification}
          />

          <TiktokSwitchNotification
            platforms={platforms}
            value={ttIsNotification}
            hasTikTokPosting={hasTikTokPosting}
            updateField={updateField}
            allDisabled={allDisabled}
            oldTiktokAccounts={oldTiktokAccounts}
          />
          <ThreadsSwitchNotification
            platforms={platforms}
            value={thIsNotification}
            hasThreadsPosting={hasThreadsPosting}
            updateField={updateField}
            allDisabled={allDisabled}
            oldThreadsAccounts={oldThreadsAccounts}
          />

          {showVideoSelector && (
            <VideoCoverSelector
              disabled={false}
              videoUrl={videoUrl}
              isLoading={isLoadingVideo}
              videoMedia={videoMedia}
              thumbnails={thumbnails}
              updateField={updateField}
              platforms={platforms}
              selectedPlatform={selectedPlatform}
              videoCoverPreview={platformPost?.videoCoverPreview}
              originalCover={platformPost?.videoCoverOriginal}
              thumbnailUrl={platformPost?.thumbnailUrl}
              videoCoverImageTimestamp={platformPost?.videoCoverImageTimestamp}
            />
          )}

          {/* Fields for non-story */}
          {!postFormValue.isStory && (
            <div>
              {/* YouTube title */}
              {visibleFields.includes(FIELDS.YT_TITLE) && (
                <YouTubeTitleStyled
                  key={`ytTitle-${selectedPlatform}`}
                  title={`Video title (YouTube)`}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  onChange={(val) => updateField(FIELDS.YT_TITLE, val)}
                  errors={errors}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}
              {/* YouTube category */}
              {visibleFields.includes(FIELDS.YT_CATEGORY) && (
                <YouTubeCategoryStyled
                  key={`ytCategoryId-${selectedPlatform}`}
                  title={`Video category`}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  selectedAccounts={formAccounts}
                  onChange={(val) => updateField(FIELDS.YT_CATEGORY, val)}
                  errors={errors}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}
              {/* YouTube category */}
              {visibleFields.includes(FIELDS.YT_PRIVACY) && (
                <YouTubePrivacyStyled
                  key={`ytPrivacyId-${selectedPlatform}`}
                  title={`Video category`}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  selectedAccounts={formAccounts}
                  onChange={(val) => updateField(FIELDS.YT_PRIVACY, val)}
                  errors={errors}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}
              {/* Pinterest boards */}
              {visibleFields.includes(FIELDS.PI_BOARDS) && (
                <PinterestFieldsStyled
                  key={`piBoards-${selectedPlatform}`}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  selectedAccounts={formAccounts}
                  onChange={updateField}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}
              {/* Caption */}
              {visibleFields.includes(FIELDS.CAPTION) && (
                <BlockMargin>
                  <PostCaption
                    key={`caption-${selectedPlatform}`}
                    post={platformPost}
                    platforms={platforms}
                    selectedPlatform={selectedPlatform}
                    onChange={(val) => updateField(FIELDS.CAPTION, val)}
                    errors={errors}
                    warnings={warnings}
                    hashtagCount={captionHashtagCount}
                    disabled={
                      allDisabled ||
                      (selectedPlatform !== "LINKED" &&
                        (!platforms || (platforms && platforms.length > 1)) &&
                        (!platformFields[selectedPlatform] ||
                          platformFields[selectedPlatform].unlinked !== true))
                    }
                    hasMoreThanOnelinkedInAccount={
                      hasMoreThanOnelinkedInAccount
                    }
                    selectedLinkedInAccountType={selectedLinkedInAccountType}
                    selectedLinkedUrn={selectedLinkedUrn}
                  />
                </BlockMargin>
              )}

              {/* First Comment */}
              {visibleFields.includes(FIELDS.FIRST_COMMENT) && (
                <PostFirstCommentStyled
                  key={`first-comment-${selectedPlatform}`}
                  post={platformPost}
                  platformFields={platformFields}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  hashtagCount={firstCommentHashtagCount}
                  onChange={(val) => updateField(FIELDS.FIRST_COMMENT, val)}
                  errors={errors}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}

              {showUrlField && (
                <PostUrlStyled
                  url={postFormValue.redirectUrl}
                  checkSetting={true}
                  post={postFormValue}
                  selectedPlatform={selectedPlatform}
                  selectedAccounts={formAccounts}
                  platforms={platforms}
                  title="URL"
                  onChange={(newUrl) => updateField(FIELDS.URL, newUrl)}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}

              {/* TT other fields or URL */}
              {hasTikTokPosting && (
                <TiktokSwitchAllows
                  postFormValue={postFormValue}
                  updateField={updateField}
                  allDisabled={allDisabled}
                  errors={errors}
                />
              )}
              {/* Threads fields */}
              {hasThreadsPosting && (
                <ThreadsReplyControl
                  key={`thReplyControlId-${selectedPlatform}`}
                  title={`Reply Control`}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  selectedAccounts={formAccounts}
                  onChange={(val) => updateField(FIELDS.TH_REPLY_CONTROL, val)}
                  errors={errors}
                  disabled={
                    allDisabled ||
                    (selectedPlatform !== "LINKED" &&
                      (!platforms || (platforms && platforms.length > 1)) &&
                      (!platformFields[selectedPlatform] ||
                        platformFields[selectedPlatform].unlinked !== true))
                  }
                />
              )}
              {/* URL */}
              {/* Tags */}
              {hasPostTags && (
                <PostTagsStyled
                  hasTikTokPosting={hasTikTokPosting}
                  post={platformPost}
                  selectedPlatform={selectedPlatform}
                  platforms={platforms}
                  visibleFields={visibleFields}
                  onChange={updateField}
                  handleOpenAddAlt={handleOpenAddAlt}
                  isSummary={isSummary}
                />
              )}
            </div>
          )}
          {postFormValue.isStory && (
            <StoryFields
              postFormValue={postFormValue}
              errors={errors}
              platforms={platforms}
              updateField={updateField}
              platformPost={platformPost}
              selectedPlatform={selectedPlatform}
              allDisabled={allDisabled}
              visibleFields={visibleFields}
            />
          )}
          {/* GMB Fields */}
          {visibleFields.includes(FIELDS.GMB_TOPIC_TYPE) && (
            <GmbFieldsStyled
              className="tw-mt-12 tw-flex-col"
              onChange={updateFields}
              post={{
                ...postFormValue,
                ...platformFields[selectedPlatform],
              }}
              selectedPlatform={selectedPlatform}
              platforms={platforms}
              disabled={
                allDisabled ||
                (selectedPlatform !== "LINKED" &&
                  (!platforms || (platforms && platforms.length > 1)) &&
                  (!platformFields[selectedPlatform] ||
                    platformFields[selectedPlatform].unlinked !== true))
              }
            />
          )}
          {/* YouTube tags */}
          {visibleFields.includes(FIELDS.YT_TAGS) && (
            <YouTubeTagsStyled
              key={`ytTags-${selectedPlatform}`}
              title={`YouTube tags`}
              post={platformPost}
              selectedPlatform={selectedPlatform}
              platforms={platforms}
              selectedAccounts={formAccounts}
              onChange={(val) => updateField(FIELDS.YT_TAGS, val)}
              errors={errors}
              disabled={
                allDisabled ||
                (selectedPlatform !== "LINKED" &&
                  (!platforms || (platforms && platforms.length > 1)) &&
                  (!platformFields[selectedPlatform] ||
                    platformFields[selectedPlatform].unlinked !== true))
              }
            />
          )}
          {platforms.length === 1 && platforms[0].TYPE === "LI" && (
            <div>
              <PostVisibilityStyled>Post visibility</PostVisibilityStyled>
              <TargetAudienceLabelWrap>
                <div data-testid="targetAudiences">
                  {Object.keys(audience).length
                    ? audiencesLabelArray.map((label) => (
                        <div key={label}>{label}</div>
                      ))
                    : "Anyone"}
                </div>
                <div onClick={handleTargetAudienceShowModal}>
                  Target Audience
                </div>
              </TargetAudienceLabelWrap>

              {showTargetAudience && (
                <LinkedinTargetAudience
                  showModal={showTargetAudience}
                  onCloseModal={handleTargetAudienceCloseModal}
                  lists={[
                    {
                      id: "jobFunctions",
                      label: "Job function",
                    },
                    {
                      id: "organizations",
                      label: "University",
                    },
                    {
                      id: "seniorities",
                      label: "Seniority",
                    },
                    {
                      id: "geoLocations",
                      label: "Location",
                    },
                    {
                      id: "staffCountRanges",
                      label: "Company size",
                    },
                    {
                      id: "industries",
                      label: "Industry",
                    },
                  ]}
                  selectedItems={audience}
                />
              )}
            </div>
          )}
        </Container>
      </>

      {openAddAlt && (
        <AltTextModal
          post={post}
          toggleAddAlt={handleOpenAddAlt}
          handleImageAltUpdate={handleImageAltUpdate}
          onSubmit={handleMediaUpdate}
        />
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
`;
const BlockMargin = styled.div`
  margin-bottom: 1rem;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;
export const flexColumnMb = css`
  ${flexColumn};
  margin-bottom: 1rem;
`;
const YouTubeTitleStyled = styled(YouTubeTitle)`
  ${flexColumnMb};
`;
const YouTubeCategoryStyled = styled(YouTubeCategory)`
  ${flexColumnMb};
`;
const YouTubePrivacyStyled = styled(YouTubePrivacy)`
  ${flexColumnMb};
`;
const PinterestFieldsStyled = styled(PinterestFields)`
  ${flexColumn};
`;
const PostFirstCommentStyled = styled(PostFirstComment)`
  ${flexColumnMb};
`;
const PostUrlStyled = styled(PostUrl)`
  margin-bottom: 1rem;
`;
const PostTagsStyled = styled(PostTags)`
  display: flex;
  margin-bottom: 1rem;
`;
const GmbFieldsStyled = styled(GmbFields)`
  display: flex;
  margin-top: 3rem;
`;
const YouTubeTagsStyled = styled(YouTubeTags)`
  ${flexColumnMb}
`;

const PostVisibilityStyled = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 3rem;
`;

const TargetAudienceLabelWrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;

  > div:last-child {
    color: #4f31ff;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
    padding-left: 20px;
  }
`;
