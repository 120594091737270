import styled from "styled-components";
import * as React from "react";
import DotdotDot from "react-dotdotdot";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export const ClampTextWithTooltip = styled((props) => (
  <TooltipWrapper className={props.className} tooltip={props.children}>
    <ClampTextWithTooltipContent clamp={1}>
      {props.children}
    </ClampTextWithTooltipContent>
  </TooltipWrapper>
))``;

const ClampTextWithTooltipContent = styled(DotdotDot)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
