import {
  TOGGLE_OPEN_INBOX_ITEM_ROUTINE,
  TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE,
  TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE,
  REMOVE_INBOX_ITEMS_ROUTINE,
  SAVE_POST_FORM_ROUTINE,
} from "constants/ActionTypes";

import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  openInboxItem: {
    itemId: null,
    autoFocusReply: false,
  },
  commentsSidebar: {
    isOpen: false,
    autoFocusComments: false,
  },
  navigationSidebarOpen: true,
};

const inboxViewReducer = createReducer(initialState, {
  [TOGGLE_OPEN_INBOX_ITEM_ROUTINE.TRIGGER]: (state, action) => {
    const { item, autoFocusReply = false } = action.payload;
    const id = item ? item._id : null;
    state.openInboxItem.itemId = state.openInboxItem.itemId === id ? null : id;
    state.openInboxItem.autoFocusReply = autoFocusReply;
    if (state.openInboxItem.itemId === null) {
      state.commentsSidebarOpen = null;
    }
    return state;
  },
  [TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE.SUCCESS]: (state, action) => {
    const { open } = action.payload;
    state.commentsSidebar = {
      isOpen: open,
      autoFocusComments: true,
    };
    return state;
  },
  [TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.SUCCESS]: (state, action) => {
    state.openInboxItem = initialState.openInboxItem;
    state.commentsSidebarOpen = false;
    return state;
  },
  [REMOVE_INBOX_ITEMS_ROUTINE.SUCCESS]: (state, action) => {
    state.openInboxItem = initialState.openInboxItem;
    state.commentsSidebarOpen = false;
    return state;
  },
});

export default inboxViewReducer;
