import React, { useRef } from "react";
import styled from "styled-components";
import { OpenableState } from "libs/use-openable-state";
import { Collapse } from "ui/collapse";
import { DropdownPopup } from "../index";
import { DropdownContext, useDropdown } from "libs/react-dropdown-elements";
import { useClickOutside } from "libs/use-click-outside";

interface DropdownProps {
  className?: string;
  children: React.ReactNode;
  position?: "right" | null;

  renderTrigger(props: { openAbleState: OpenableState }): React.ReactNode;
}

interface DropdownPopupProps {
  position?: "right" | null;
}

function DropdownComponent(props: DropdownProps) {
  const ref = useRef();

  const dropdown = useDropdown();
  useClickOutside(ref, dropdown.openAbleState.close);

  const className =
    props.className + (dropdown.openAbleState.isOpened ? " isOpened" : "");

  return (
    <DropdownRoot ref={ref} className={className}>
      <DropdownContext.Provider value={dropdown.contextValue}>
        {props.renderTrigger({ openAbleState: dropdown.openAbleState })}
        <DropdownPopupWrapper position={props.position ? props.position : null}>
          <Collapse isOpened={dropdown.openAbleState.isOpened}>
            <DropdownPopup>{props.children}</DropdownPopup>
          </Collapse>
        </DropdownPopupWrapper>
      </DropdownContext.Provider>
    </DropdownRoot>
  );
}

export const Dropdown = styled(DropdownComponent)`
  cursor: pointer;
`;

export const DropdownPopupWrapper = styled.div<DropdownPopupProps>`
  return ${(props: DropdownPopupProps) => {
    if (props.position && props.position === "right") {
      return `right: 0;`;
    } else {
      return ``;
    }
  }}`;

const DropdownRoot = styled.div`
  position: relative;

  ${DropdownPopupWrapper} {
    position: absolute;
    top: calc(100% + 7px);
    z-index: 11;
  }
`;
