import { all } from "redux-saga/effects";

export { closePostForm, postsCreatedOrUpdated } from "./post-form.model";

import { createPostSaga } from "./create-modal.model";
import { editModalPostSaga } from "./editing/edit-modal.model";
import type {
  Post,
  InstagramPost,
  PlatformPost,
  NotificationPerson,
} from "./types";
import type { PostApiInput } from "./format-typed-post-to-api";

export { PostStates, PlatformTypes } from "./types";

export {
  getPostDataFromTypedPost,
  normalizePlatformType,
} from "./format-typed-post-to-api";
export { useTypedPost } from "./format-post";

export {
  openCreatePostModal,
  closeCreatePostModal,
} from "./create-modal.model";
export {
  openEditPostModal,
  closeEditPostModal,
} from "./editing/edit-modal.model";

export function* postSaga() {
  yield all([createPostSaga(), editModalPostSaga()]);
}

export { getPostFeatureReducer, postFormSelector } from "./reducer";

export type {
  Post,
  PostApiInput,
  InstagramPost,
  PlatformPost,
  NotificationPerson,
};

export type { MediaFilesErrors, MediaFilesWarning } from "./validation";
export { startPostEditing } from "./editing/model";
