import React, { useCallback, useEffect, useState } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FormControl } from "ui";
import { FIELDS, GMB_TOPICS } from "constants/PostFormFields";
import { useDebouncedCallback } from "use-debounce";
import { TextArea } from "ui/ecosystems/forms/inputs";

const PostTerms = ({
  topicType,
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  className = "",
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (post["gmbOffer"] && post["gmbOffer"]["termsConditions"]) {
      setValue(post["gmbOffer"]["termsConditions"]);
    }
  }, []);

  const handleTopicChange = (item) => {
    onChange({ [FIELDS.GMB_TERMS]: item.key });
  };

  const bounce = useDebouncedCallback((value) => {
    onChange({ [FIELDS.GMB_TERMS]: value });
  }, 500);

  const forTopics = [GMB_TOPICS.OFFER.VALUE];
  if (!forTopics.includes(topicType)) {
    return "";
  }

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Offer terms and conditions (optional)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.GMB_TERMS}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <p className="small">
        The terms and conditions for the offer, displayed in the GMB post.
      </p>
      <TextArea
        value={value}
        placeholder="Enter your written terms here - it is not a URL box"
        className="tw-pt-4 tw-min-h-100px"
        onChange={(e) => {
          const newVal = e.target.value;
          setValue(newVal);
          bounce(newVal);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default PostTerms;
