import React from "react";
import styled from "styled-components";

import { PlatformName } from "shared/types/accounts";

import fbIcon from "./icons/fb.image.svg";
import gbIcon from "./icons/gb.image.svg";
import igIcon from "./icons/ig.image.svg";
import lnIcon from "./icons/ln.image.svg";
import piIcon from "./icons/pi.image.svg";
import scIcon from "./icons/sc.image.svg";
import ttIcon from "./icons/tt.image.svg";
import twIcon from "./icons/tw.image.svg";
import ytIcon from "./icons/yt.image.svg";
import otIcon from "./icons/ot.image.svg";
import thIcon from "./icons/th.image.svg";

export const IconMap: {
  [key: PlatformName]: string;
} = {
  facebook: fbIcon,
  googlemybusiness: gbIcon,
  instagram: igIcon,
  linkedin: lnIcon,
  pinterest: piIcon,
  snapchat: scIcon,
  tiktok: ttIcon,
  threads: thIcon,
  other: otIcon,
  youtube: ytIcon,
  twitter: twIcon,
};

function PlatformIconComponent(props: {
  className?: string;
  type: PlatformName | string;
  altText?: string;
}) {
  return (
    <Icon
      src={IconMap[props.type]}
      className={props.className}
      alt={props.altText || undefined}
    />
  );
}

export const PlatformIcon = styled(PlatformIconComponent)``;

const Icon = styled.img``;
