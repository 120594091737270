import React from "react";
import styled from "styled-components";
import QuokkaPointingUpImg from "assets/icons/ai-caption/quokka-pointing-up.png";
import CircleCloseIcon from "assets/icons/ai-caption/circle-close.svg";
import { Wrap } from "./AICaption";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Header } from "./shared-components";

export const ExceedUsage = ({ exceedUsage }: { exceedUsage: string }) => (
  <ErrorBoundary>
    <Wrap>
      <Header />
      <ExceedUsageWrap>
        <ErrorBadge>
          <CircleCloseIcon />
          <span>{exceedUsage}</span>
        </ErrorBadge>
        <img src={QuokkaPointingUpImg} alt="quokka pointing up" />
      </ExceedUsageWrap>
    </Wrap>
  </ErrorBoundary>
);

const ExceedUsageWrap = styled.div`
  margin-top: 28px;

  img {
    margin-top: 80px;
    width: 298px;
    display: flex;
    margin: 80px auto 0;
  }
`;

const ErrorBadge = styled.div`
  background: #fef1ef;
  position: relative;
  padding: 10px 12px 12px 32px;
  border-radius: 5px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fa4f38;
  }

  svg {
    position: absolute;
    left: 8px;
    top: 11px;
    width: 18px;
    height: 18px;
  }
`;
