// wrapper for effector-storage for saving data to local and session storages

import { Store } from "effector";
import { persist as persistLocal } from "effector-storage/local";

export function persist<T>({
  store,
  key,
  serialize,
  deserialize,
}: {
  store: Store<T>;
  key?: string;
  serialize?: (value: any) => string;
  deserialize?: (value: string) => any;
}) {
  persistLocal({
    sync: false,
    store: store,
    key,
    serialize,
    deserialize,
  });
}
