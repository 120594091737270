import React from "react";
import { useSelector } from "react-redux";
import { getFormErrors } from "selectors/postFormSelectors";
import { FIELDS } from "constants/PostFormFields";
import {
  PinterestBoards,
  PinterestBoardSection,
  PinterestTitle,
} from "components/Posts/PostForm/index";
import { Alert } from "ui";
import { AlertType } from "ui/alert";

const PinterestFields = ({
  post,
  selectedPlatform,
  platforms,
  selectedAccounts,
  onChange,
  className = "",
  disabled = false,
}) => {
  const errors = useSelector((state) => getFormErrors(state, post._id));
  const piAccounts = selectedAccounts.filter(
    (account) => account.platformType === "PI"
  );
  if (piAccounts.length > 1) {
    // error
    return (
      <Alert type={AlertType.error}>
        <strong>
          Currently we only support scheduling to one Pinterest account at a
          time.
        </strong>
      </Alert>
    );
  } else if (piAccounts.length === 0) {
    return null;
  }
  {
    /* Each Pinterest account will eventually need its own board and section picker. Currently only supporting one at a time so we don't go insane */
  }
  let account = piAccounts[0];
  const changePiBoard = (val) => {
    // any time we change board, clear section if it exists
    // otherwise we get Request failed with status code 404, code 2031, message: Sorry! We couldn't find this board section
    onChange(FIELDS.PI_SECTION_ID, null);
    return onChange(FIELDS.PI_BOARDS, val);
  };

  return (
    <div className={className}>
      <PinterestBoards
        className="tw-mb-8"
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        account={account}
        onChange={changePiBoard}
        errors={errors}
        disabled={disabled}
        title={`Board(s)`}
      />
      <PinterestBoardSection
        post={post}
        className="tw-mb-8"
        title={`Board section`}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={(val) => onChange(FIELDS.PI_SECTION_ID, val)}
        account={account}
        errors={errors}
        disabled={disabled}
      />
      <PinterestTitle
        className="tw-mb-8"
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={(val) => onChange(FIELDS.PI_TITLE, val)}
        selectedAccounts={selectedAccounts}
        errors={errors}
        title={`Pin title`}
        disabled={disabled}
      />
    </div>
  );
};

export default PinterestFields;
