import React from "react";
import { BlockContainer } from "./styled";
import { renderContent } from "./message-types/message-content-render";
import { ServerMessageInfoProps } from "./types";
import styled from "styled-components";

interface Props
  extends Pick<
    ServerMessageInfoProps,
    | "message"
    | "name"
    | "type"
    | "story"
    | "attachments"
    | "messageId"
    | "originalMessage"
    | "conversationId"
  > {
  isLeft: boolean;
  isCurrentSMUMessage: boolean;
  isReplyToSMUMessage: boolean;
}

export const ReplyToBlock = ({
  message,
  name,
  type,
  story,
  attachments,
  isLeft,
  isCurrentSMUMessage,
  isReplyToSMUMessage,
  messageId,
  originalMessage,
  conversationId,
}: Props): JSX.Element => {
  return (
    <ReplyToBlockContainer isLeft={isLeft}>
      <ReplyToSenderTitle>
        {resolveRepliedMessageHeader(isCurrentSMUMessage, isReplyToSMUMessage)}
      </ReplyToSenderTitle>
      <BlockContainer>
        {renderContent({
          type,
          name,
          story,
          attachments,
          message,
          isTeamMessage: false,
          isLeft,
          isRepliedMessage: true,
          reactions: undefined,
          messageId,
          onReactionsChange: undefined,
          originalMessage,
          conversationId,
          conversation: undefined,
          accountContext: undefined,
        })}
      </BlockContainer>
    </ReplyToBlockContainer>
  );
};

const ReplyToBlockContainer = styled.div<{ isLeft: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isLeft }) => (isLeft ? `flex-start` : `flex-end`)};
`;

function resolveRepliedMessageHeader(
  isCurrentSMUMessage: boolean,
  isReplyToSMUMessage: boolean
): string {
  if (isCurrentSMUMessage) {
    if (isReplyToSMUMessage) {
      return "Replied to themselves";
    }
    return "Replied to you";
  }

  if (isReplyToSMUMessage) {
    return "You replied";
  }

  return "You replied to yourself";
}

const ReplyToSenderTitle = styled.span`
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  font-style: italic;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #c4c4c4;
  font-size: 12px;
`;
