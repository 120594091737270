import React from "react";

import { PostAccount } from "components/Posts/Post/index";
import { FIELDS } from "constants/PostFormFields";

import { Caption, DetailsTemplate, PostDetail } from "./post-details-template";

export function YouTubeDetails({ post, account, page }) {
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          <YouTubeTitleAndCategory post={post} account={account} />
          <Caption post={post} />
        </>
      )}
    />
  );
}

export function YouTubeTitleAndCategory({ post, account }) {
  let category = null;
  if (post[FIELDS.YT_CATEGORY] && account.availableCategories) {
    category = account.availableCategories.find(
      (cat) => cat.id === post[FIELDS.YT_CATEGORY]
    );
  }
  return (
    <>
      {post[FIELDS.YT_TITLE] && (
        <PostDetail label="Video title">{post[FIELDS.YT_TITLE]}</PostDetail>
      )}
      {category && (
        <PostDetail label="Video category">{category.snippet.title}</PostDetail>
      )}
    </>
  );
}
