import { getGenericEntities } from "./selectorUtils";
const COLLAB_ENTITY = "externalCollab";
const COLLAB_CHAT_ENTITY = "collaboration";

export const getThemes = (state) =>
  getGenericEntities(state, state.entities[COLLAB_ENTITY].themes);
export const getThemesById = (state) =>
  state.entities[COLLAB_ENTITY].themes.byId;
export const getLinks = (state) =>
  getGenericEntities(state, state.entities[COLLAB_ENTITY].links);

// chat related
export const getComments = (state) =>
  state.entities[COLLAB_CHAT_ENTITY].comments;
export const getCollaborators = (state) =>
  state.entities[COLLAB_CHAT_ENTITY].collaborators;

export const isRequestingCommentsSelector = (state) =>
  state.entities[COLLAB_CHAT_ENTITY].isRequestingComments;

export const unreadCommentsSelector = (state) =>
  state.entities[COLLAB_CHAT_ENTITY].unreads;
