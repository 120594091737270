import { skedInboxApi } from "./_skedInboxApi";
import { Pagination, Message } from "types/conversationInbox";
import { CreateMessageAction } from "actions/conversationInbox";

interface getMessagesProps {
  conversationId: string;
  next?: string | null;
}

export async function getMessages({
  conversationId,
  next,
}: getMessagesProps): Promise<{
  data: Pagination<Message>;
}> {
  return await skedInboxApi.get("/messages", {
    params: {
      conversationId,
      sort: "createdAt:DESC",
      next,
    },
  });
}

export async function createMessage({
  conversationId,
  text,
  isInternal,
  closeConversationAction,
  replyToId,
}: CreateMessageAction["payload"]): Promise<{
  data: Message;
}> {
  return await skedInboxApi.post("/messages", {
    conversationId,
    text,
    isInternal,
    closeConversation: !!closeConversationAction,
    replyToId,
  });
}

export async function setMessageDeletedStatus({
  id,
}: {
  id: string;
}): Promise<void> {
  await skedInboxApi.put(`/messages/${id}/deleted-status`, {});
}
export async function setMessageHidden({
  id,
  hidden,
}: {
  id: string;
  hidden: boolean;
}): Promise<void> {
  await skedInboxApi.put(`/messages/${id}/hidden-status`, {
    hidden,
  });
}
