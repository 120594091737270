import React, { useEffect, useState } from "react";
import {
  PlatformUsage,
  PostFieldCounter,
  PostFieldLabel,
} from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import { useDebouncedCallback } from "use-debounce";
import { TextInput } from "ui/ecosystems/forms/inputs";

const StoryLabel = ({
  post,
  selectedPlatform,
  platforms,
  title,
  className = "",
  onChange,
  disabled = false,
}) => {
  const [value, setValue] = useState(
    post && post["storyLabel"] ? post["storyLabel"] : ""
  );
  useEffect(() => {
    if (post && post["storyLabel"]) {
      setValue(post["storyLabel"]);
    }
  }, []);

  const bounce = useDebouncedCallback((value) => {
    const label = post["storyLabel"];
    if (value !== label) {
      setValue(value);
      onChange(value);
    }
  }, 200);

  return (
    <div className={className}>
      <div className="tw-flex tw-flex-col tw-mb-4">
        <div className="tw-flex tw-items-center">
          <PostFieldLabel className="tw-mb-2 tw-mr-2">{title}</PostFieldLabel>
          <PlatformUsage
            platforms={platforms}
            field={FIELDS.STORY_LABEL}
            selectedPlatform={selectedPlatform}
          />
        </div>
        <TextInput
          value={value}
          disabled={disabled}
          placeholder="Label for the link sticker (optional, BETA)"
          onChange={(value) => {
            setValue(value);
            bounce(value);
          }}
        />
        <PostFieldCounter
          count={value.length}
          max={100}
          className="tw-flex-grow tw-text-right tw-mt-2 tw-mr-8"
        >
          Characters
        </PostFieldCounter>
      </div>
    </div>
  );
};

export default StoryLabel;
