import React from "react";
import styled, { css } from "styled-components";

interface DotsProps {
  className?: string;
  total: number;
  active: number;
}

export const Dots = styled(function Dots({
  className,
  total,
  active,
}: DotsProps) {
  return (
    <div className={className}>
      {Array(total)
        .fill(undefined)
        .map((_, i) => (
          <Dot key={i} active={i === active} />
        ))}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;

export const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #4f31ff;
  transition: background-color 0.3s;

  ${(props) =>
    props.active &&
    css`
      background-color: #4f31ff;
    `}
`;
