import styled, { css } from "styled-components";
import { MessageSubComponentProps } from "./message-types/types";
import { breakpoints } from "@inbox/pages/home/breakpoints";
import { MediaTypes } from "../components/multi-media";

export interface MessageContainerProps {
  left?: boolean;
  opaque?: boolean;
}

export const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  align-items: center;

  ${(props) =>
    !props.left &&
    `
    justify-content: flex-end;
  `}
  ${(p) => (p.opaque ? "opacity: 0.5;" : undefined)}
`;

interface MultiMediaBackgroundProps {
  left?: boolean;
  marginBottom?: boolean;
  isReady?: boolean;
  mediaType?: string;
  portraitMedia?: boolean;
}

export const MultiMediaBackground = styled.div<MultiMediaBackgroundProps>`
  background-color: ${(props) =>
    props.mediaType === MediaTypes.storyMention ? "f8f8f8" : "#fff"};
  border-radius: 5px;
  width: 150px;

  @media (min-width: 1920px) {
    width: 350px;
  }

  @media (min-width: 2100px) {
    width: 500px;
  }

  position: relative;
  overflow: hidden;

  ${(props) => (props.left ? `margin-right: 15px;` : `margin-left: 15px;`)}

  ${(props) =>
    props.marginBottom &&
    `
    margin-bottom: 12px;
  `}

  ${(props) =>
    props.portraitMedia ? `min-height: 250px;` : `min-height: 100px;`}

  ${(props) => props.isReady && `min-height: auto;`}
`;

export interface MessageBlockProps extends MessageSubComponentProps {
  isTeamMessage?: boolean;
  isRepliedMessage: boolean;
  isFailed?: boolean;
  removeMargin?: boolean;
  removeRadius?: boolean;
  italic?: boolean;
}

export const resolveMessageBackground = ({
  isRepliedMessage,
  isTeamMessage,
  isFailed,
  left,
}: MessageBlockProps): string => {
  if (isFailed) {
    return "#f8f8f8";
  }
  if (isRepliedMessage) {
    return "#DEDAFE";
  }
  if (left) {
    return "#eee";
  }
  return isTeamMessage ? "#FFE7E7" : "#f4f3ff";
};

export const MessageBlock = styled.div<MessageBlockProps>`
  ${(props) => {
    if (props.left) {
      return `
      border-radius: 15px 15px 15px 0px;
    `;
    }
    return `border-radius: 15px 15px 0px 15px;`;
  }}
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  width: auto;
  max-width: 260px;
  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: 450px;
  }
  @media screen and (min-width: ${breakpoints.desktop}) {
    max-width: 550px;
  }
  @media screen and (min-width: ${breakpoints.ultrawide}) {
    max-width: 550px;
  }
  overflow-wrap: break-word;
  white-space: pre-line;

  ${(props) => {
    if (props.removeRadius) {
      return ``;
    }
    if (props.left) {
      return `
      border-radius: 15px 15px 15px 0px;
    `;
    }
    return `border-radius: 15px 15px 0px 15px;`;
  }}

  ${(props) => {
    if (props.removeMargin) {
      return ``;
    }
    return `
      margin-left: 15px;
      margin-right: 15px;
    `;
  }}
  ${(props) => {
    if (props.isRepliedMessage) {
      return css`
        margin-bottom: -10px;
        padding-bottom: 16px;
        font-size: 12px;
      `;
    }
    return ``;
  }}

  ${(p) => (p.italic ? "font-style: italic;" : undefined)}

  @media (min-width: ${breakpoints.ultrawide}) {
    max-width: 550px;
  }
`;

export const DateLabel = styled.div<MessageSubComponentProps>`
  font-size: 12px;
  color: #c4c4c4;
  padding-top: 3px;
  ${(props) =>
    props.left
      ? `
    padding-left: 45px;
  `
      : `
    padding-right 45px;
  `}
`;

interface MentionLabelProps {
  left?: boolean;
}

export const MentionLabel = styled.span<MentionLabelProps>`
  font-style: italic;
  font-size: 10px;
  margin: 0 15px 10px;

  ${(props) =>
    !props.left &&
    `
    text-align: end;
  `}
`;

export const BlockContainer = styled.div<{ opaque?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(p) => (p.opaque ? "opacity: 0.5;" : undefined)}
`;

export const InvalidLabel = styled.span`
  color: #999999;
  font-size: 10px;
  position: absolute;
  top: calc(50% + 30px);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  margin-left: -50%;
`;

interface MultiMediaContainerProps {
  left?: boolean;
}

export const MultiMediaContainer = styled.div<MultiMediaContainerProps>`
  display: flex;
  align-items: center;
  margin-left: 15px;
  position: relative;

  ${(props) =>
    !props.left &&
    `
    justify-content: flex-end;
    margin-right: 15px;
  `}
  video::-webkit-media-controls-panel {
    background-color: #eee;
  }
`;

export const RootContainer = styled.div<MessageSubComponentProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props) =>
    props.left
      ? `
          padding-top: 12px;
        `
      : `
        align-items: flex-end;
        padding-top: 12px;
      `}
  ${(p) => (p.opaque ? "opacity: 0.5;" : undefined)}
`;

export const RetryContainer = styled.div<{ onLeft?: boolean }>`
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  ${(props) =>
    props.onLeft
      ? css`
          text-align: left;
          padding-left: 42px;
        `
      : css`
          text-align: right;
        `}

  color: #fa4f38;
  padding-right: 45px;
  margin-top: 5px;

  .icon {
    margin-right: 3px;
  }

  span {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
  }
`;
export const MessageHiddenNote = styled.span`
  font-size: 10px;
  font-style: italic;
  padding-top: 5px;
  text-align: center;
  padding-bottom: 10px;
`;

export const ConversationAvatarWrapper = styled.div`
  align-self: flex-end;
`;
