import React from "react";
import { CollectionSelector } from "features/media-library/components/collection-selector";
import { MediaCollection } from "api/media-library";
import { isUserPlan } from "features/upsell/plan-utils";
import { getUser as getCurrentUser } from "selectors/commonSelectors";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { ColoredItem } from "features/media-library/components/collection-selector/collection-selector";
import { SelectAbleItem } from "ui/ecosystems/dropdown";

interface TypedOption {
  value: string;
  color: string;
  label: string;
}
export const CollectionSettings = ({
  collectionUserCanAccess,
  setCollectionUserCanAccess,
  collections,
}: {
  collectionUserCanAccess: MediaCollection | string[] | null;
  setCollectionUserCanAccess: (
    newValue: MediaCollection | string[] | null
  ) => void;
  collections?: MediaCollection[];
}): React.ReactElement => {
  const currentUser = useSelector(getCurrentUser);

  const isEnterpriseUser = isUserPlan(currentUser, "sked-enterprise");
  const options = (collections || []).map((collection) => ({
    value: collection._id,
    label: collection.name,
    color: collection?.preferences?.color || null,
  })) as TypedOption[];
  return (
    <div>
      <p>
        <strong>
          Collection that this user can <em>only</em> create, view, update and
          remove assets from:
        </strong>
      </p>
      {isEnterpriseUser ? (
        // use native react-select first, as the Select from 'ui/ecosystems/forms' doesn't work properly with isMulti
        <ReactSelect
          isMulti
          closeMenuOnSelect={false}
          placeholder={"Select a collection…"}
          value={options.filter((c) =>
            ((collectionUserCanAccess as string[]) || []).includes(c.value)
          )}
          options={options}
          onChange={(selectedOption) => {
            setCollectionUserCanAccess(
              ((selectedOption as TypedOption[]) || []).map((c) => c.value)
            );
          }}
          isSearchable
          components={{ Option: ColorOption }}
          noOptionsMessage={() => "No other collections"}
        />
      ) : (
        <>
          <CollectionSelector
            selectedCollection={collectionUserCanAccess as MediaCollection}
            collections={collections || []}
            onChange={(id) => {
              setCollectionUserCanAccess(
                collections?.find((collection: any) => collection._id === id) ??
                  null
              );
            }}
          />
          {!isEnterpriseUser && (
            <div style={{ marginTop: 10 }}>
              Upgrade to enable Multi-collection access for users.
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ColorOption = (optionProps: {
  innerProps: unknown;
  data: TypedOption;
}) => {
  return (
    <SelectAbleItem {...optionProps.innerProps}>
      <ColoredItem color={optionProps.data?.color}>
        {optionProps.data.label}
      </ColoredItem>
    </SelectAbleItem>
  );
};
