import {
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";
import { useStore } from "effector-react";
import { toast } from "react-toastify";

import { getPlanEstimate, Plan } from "api/plan-change";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

import { PlanChangeIntention } from "../../PlanChangeIntention.type";
import { PlansModalGate } from "./PlansModalGate";

export const goBack = createEvent();

const $showConfirm = createStore<boolean>(false)
  .on(goBack, () => false)
  .reset(PlansModalGate.close);

const $planChangeIntention = createStore<PlanChangeIntention | null>(null)
  .reset(goBack)
  .reset(PlansModalGate.close);

export const getPlanEstimateFx = createEffect(
  async ({
    planId,
    plan,
    isAnnual,
  }: {
    planId: string;
    plan: Plan;
    isAnnual: boolean;
  }) => {
    const estimate = await getPlanEstimate({ planId, ugcLibraryAddon: false });

    return {
      planId,
      ugcLibraryAddon: false,
      plan,
      isAnnual,
      estimate,
    };
  }
);

forward({
  from: getPlanEstimateFx.doneData,
  to: $planChangeIntention,
});

sample({
  source: $planChangeIntention,
  fn: (intention) => Boolean(intention),
  target: $showConfirm,
});

getPlanEstimateFx.failData.watch((err) => {
  toast.error(getUserFriendlyErrorMessage(err));
});

export function usePlanEstimate() {
  const planChangeIntention = useStore($planChangeIntention);
  const isLoading = useStore(getPlanEstimateFx.pending);
  const showConfirm = useStore($showConfirm);

  return {
    planChangeIntention,
    isLoading,
    showConfirm,
    goBack,
    getPlanEstimate: getPlanEstimateFx,
  };
}
