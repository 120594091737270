import { getEntities, getEntityMap } from "./selectorUtils";
import { createSelector } from "@reduxjs/toolkit";
import { getPlatforms as getAccountPlatforms } from "utils/accounts";
import {
  postsContentOptions,
  postsContentAllOption,
} from "constants/PostsFliters";

const POSTS_ENTITY = "posts";

export const getPageInitialized = (state) => state.postsView.initialized;

export const getExpanded = (state) => state.postsView.expanded;
export const getSidebar = (state) => state.postsView.sidebar;
export const getPlatforms = (state) => state.postsView.platforms;

export const getFilters = (state) => state.postsView.filters;
export const getStorePostContentTypes = (state) =>
  state.postsView.filters.postContentTypes;
export const getUpcomingFilters = (state) =>
  state.entities[POSTS_ENTITY].filters;
export const getPostsFilter = (state) => state.postsView.postsFilter;
export const getTotalPosts = (state) => state.entities[POSTS_ENTITY].total;
export const getHasMore = (state) => state.entities[POSTS_ENTITY].hasMore;
export const getSelectedPostIds = (state) =>
  state.entities[POSTS_ENTITY].selectedIds;
export const getSelectAll = (state) => state.entities[POSTS_ENTITY].allSelected;

// Accounts
export const getSelectedAccounts = (state) =>
  state.entities.accounts.selectedIds;
export const getSelectedAccountsEntity = createSelector(
  (state) => state.entities.accounts,
  (entities) => entities.selectedIds.map((id) => entities.byId[id])
);
export const getAccounts = createSelector(
  (state) => state.entities.accounts,
  (entities) => entities.allIds.map((id) => entities.byId[id])
);

// Posts
export const getPosts = createSelector(
  (state) => state.entities[POSTS_ENTITY].allIds,
  (state) => state.entities[POSTS_ENTITY].byId,
  (allIds, byId) => {
    return getEntityMap(allIds, byId);
  }
);
export const getPostIds = (state) => state.entities[POSTS_ENTITY].allIds;
export const getLoadedPostCount = (state) =>
  state.entities[POSTS_ENTITY].allIds.length;
export const getSelectedPosts = (state) =>
  getEntities(state, POSTS_ENTITY, getSelectedPostIds(state));

// Post - Edit Mode
export const getEdittingPostIds = (state) => state.forms.postForms.allIds;

// Posts old
export const getNumSelected = (state) => state.postsView.selectedPostIds.length;
export const getPostCount = (state) => state.entities.posts.allIds.length;

// Pagination
export const getPagination = (state) => state.pagination;
export const getPages = (state) => getPagination(state).pages;
export const getCurrentPage = (state) => getPagination(state).currentPage;
export const getLastPage = (state) => getPagination(state).lastPage;
export const getPageSize = (state) => getPagination(state).pageSize;

export const getPostContentTypes = createSelector(
  getStorePostContentTypes,
  getSelectedAccountsEntity,
  (statePostContentTypes, selectedAccountsEntity) => {
    const platformsTypes = getAccountPlatforms(selectedAccountsEntity).map(
      ({ TYPE }) => TYPE
    );
    const postContentTypes = postsContentOptions
      .filter((option) => {
        return (
          statePostContentTypes.includes(option.value) &&
          option.relatedTo?.some((platform) =>
            platformsTypes.includes(platform)
          )
        );
      })
      .map((option) => option.value);

    if (statePostContentTypes.includes("*")) {
      postContentTypes.push("*");
    }

    return postContentTypes;
  }
);
