import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useTrackEvent } from "libs/analytics";

import {
  DropdownButton,
  DropdownText,
  IncreaseQuotaButton,
  Link,
  StyledPlatform,
} from "./header";
import { Flex } from "ui/commonStyled/layout";
import { getAccountsCounts } from "selectors/skedCoreSelectors";
import {
  MultiplePlatforms,
  MultiplePlatformsWithIg,
} from "./MultiplePlatforms";
import { useOpenIntercomChat } from "libs/use-open-intercom-chat";
import {
  ManageSocialAccounts,
  StyledSocialAccountIcon,
} from "./AddAccountMenu";
import { showUpsellModal } from "actions/typed-modal";
import { getUser } from "selectors/commonSelectors";
import { AccountsCount } from "../../api/accounts";
import { UPGRADE_ESSENTIALS_VIEWED } from "constants/Events";
import { isLegacyPlan } from "features/upsell/plan-utils";
import { openSupportChat } from "libs/support";
import { socialAccountConnectEffectorRefs } from "features/social-account-connect/components/social-account-connect/model-ref";

function NoSlots() {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const user = useSelector(getUser);

  const upsell = !isLegacyPlan(user);
  const upsellFundamental = user?.plan?.includes("fundamentals");

  const contactSupportText =
    "I'd like to add more accounts. Can you please help me?";

  return (
    <div>
      <DropdownText>
        <div>
          You’ve filled all the social account slots included in your plan.
        </div>
        <Flex marginTop={15}>Need more accounts? </Flex>
      </DropdownText>
      {upsell ? (
        <IncreaseQuotaButton
          endIcon="users-up"
          onClick={() => {
            upsellFundamental
              ? dispatch(showUpsellModal("upsell", "add-account-user"))
              : dispatch(showUpsellModal("upsell", "increase-account-quota"));

            trackEvent({
              eventName: UPGRADE_ESSENTIALS_VIEWED,
              useServices: ["AMP", "IC", "AC", "HS"],
            });
          }}
          text="Increase account quota"
          size="md"
        />
      ) : (
        <DropdownButton
          data-test-id="no-slot-contact-support"
          onClick={() => {
            openSupportChat(contactSupportText);
          }}
        >
          Contact Support
        </DropdownButton>
      )}
      <AddNotificationAccountLink />
      <ManageSocialAccounts
        justify="center"
        data-testid="manage-social-account-link"
        style={{
          ...(upsell ? { marginTop: 0 } : {}),
        }}
      >
        <Link to="/dashboard/accounts">
          <DropdownMenu.Item>
            <StyledSocialAccountIcon />
            Manage social accounts & groups
          </DropdownMenu.Item>
        </Link>
      </ManageSocialAccounts>
    </div>
  );
}

export const isReachedAccountsLimit = (
  accountsCount: AccountsCount
): {
  isReachedAccountsLimit: boolean;
  possibleIgAccountsCount: number;
  possibleOtherAccountsCount: number;
  isPossibleIgAccountsCount: boolean;
  isPossibleOtherAccountsCount: boolean;
  possibleAccountsCount: number;
  isPossibleAccountsCount: boolean;
} => {
  const {
    igAllowed,
    igCount,
    otherAllowed,
    otherCount,
    totalCount,
    maxAllowed,
    pricingVersion,
  } = accountsCount;
  // pre 2024 pricing
  const possibleIgAccountsCount = igAllowed - igCount;
  const possibleOtherAccountsCount = otherAllowed - otherCount;
  const isPossibleIgAccountsCount = possibleIgAccountsCount > 0;
  const isPossibleOtherAccountsCount = possibleOtherAccountsCount > 0;
  // 2024
  const possibleAccountsCount = maxAllowed - totalCount;
  const isPossibleAccountsCount = possibleAccountsCount > 0;

  return {
    isReachedAccountsLimit:
      pricingVersion && pricingVersion.includes("2024")
        ? !isPossibleAccountsCount
        : !isPossibleIgAccountsCount && !isPossibleOtherAccountsCount,
    possibleIgAccountsCount,
    possibleOtherAccountsCount,
    isPossibleIgAccountsCount,
    isPossibleOtherAccountsCount,
    possibleAccountsCount,
    isPossibleAccountsCount,
  };
};

export function AddAccounts(props: {
  openSocialAccountConnectDialog: () => void;
}): React.ReactElement {
  const counts: AccountsCount = useSelector(getAccountsCounts);
  const { pricingVersion } = counts;
  const {
    isReachedAccountsLimit: isReachedAccountsLimitCheck,
    possibleIgAccountsCount,
    possibleOtherAccountsCount,
    isPossibleIgAccountsCount,
    isPossibleOtherAccountsCount,
    possibleAccountsCount,
  } = isReachedAccountsLimit(counts);
  const openIntercomChat = useOpenIntercomChat();

  if (pricingVersion && !pricingVersion.includes("2024")) {
    return (
      <>
        {isReachedAccountsLimitCheck ? (
          <NoSlots />
        ) : (
          <div>
            <DropdownText>You can add:</DropdownText>
            {isPossibleIgAccountsCount && (
              <Flex marginTop={15}>
                <CountNumber>{possibleIgAccountsCount}</CountNumber>
                <div>
                  <StyledPlatform size={26} type="instagram" />
                  <PlatformDescriptionWithTopMargin>
                    Instagram Business Accounts.
                  </PlatformDescriptionWithTopMargin>
                </div>
              </Flex>
            )}

            {isPossibleIgAccountsCount && isPossibleOtherAccountsCount && (
              <Divider />
            )}

            {isPossibleOtherAccountsCount && (
              <Flex marginTop={15}>
                <CountNumber>{possibleOtherAccountsCount}</CountNumber>
                <div>
                  <MultiplePlatforms />
                  <div>
                    <PlatformDescriptionWithTopMargin>
                      Facebook, Linkedin, Twitter, Pinterest, Google Business
                      Profile, YouTube, or TikTok accounts.
                    </PlatformDescriptionWithTopMargin>
                  </div>
                </div>
              </Flex>
            )}

            <DropdownButton
              data-test-id="add-more-account-button"
              onClick={props.openSocialAccountConnectDialog}
            >
              Add more accounts
            </DropdownButton>

            {!isPossibleIgAccountsCount && (
              <>
                <Flex justify="center" align="center" marginTop={15}>
                  <PlatformDescription>
                    Need to add more Instagram accounts?
                  </PlatformDescription>
                </Flex>
                <SupportLink
                  data-testid="need-more-ig-contact-support"
                  onClick={openIntercomChat}
                >
                  Contact Support
                </SupportLink>
              </>
            )}

            {!isPossibleOtherAccountsCount && (
              <>
                <Flex
                  direction="column"
                  align="center"
                  gap={10}
                  justify="center"
                  marginTop={15}
                >
                  <PlatformDescription>
                    Need to add more accounts?
                  </PlatformDescription>
                </Flex>
                <SupportLink
                  data-testid="need-more-other-contact-support"
                  onClick={openIntercomChat}
                >
                  Contact Support
                </SupportLink>
              </>
            )}

            <ManageSocialAccounts
              justify="center"
              data-testid="manage-social-account-link"
            >
              <Link to="/dashboard/accounts">
                <DropdownMenu.Item>
                  <StyledSocialAccountIcon />
                  Manage social accounts & groups
                </DropdownMenu.Item>
              </Link>
            </ManageSocialAccounts>
          </div>
        )}
      </>
    );
  } else {
    // new pricing version
    return (
      <>
        {isReachedAccountsLimitCheck ? (
          <NoSlots />
        ) : (
          <div>
            <DropdownText>You can add:</DropdownText>

            {!!possibleAccountsCount && (
              <>
                <Flex marginTop={15}>
                  <CountNumber>{possibleAccountsCount}</CountNumber>
                  <div>
                    <MultiplePlatformsWithIg />
                    <div>
                      <PlatformDescriptionWithTopMargin>
                        Instagram, Facebook, Linkedin, Twitter, Pinterest,
                        Google Business Profile, YouTube or TikTok accounts.
                      </PlatformDescriptionWithTopMargin>
                    </div>
                  </div>
                </Flex>

                <DropdownButton
                  data-test-id="add-more-account-button"
                  onClick={props.openSocialAccountConnectDialog}
                >
                  Add more accounts
                </DropdownButton>
              </>
            )}

            {!possibleAccountsCount && (
              <>
                <Flex justify="center" align="center" marginTop={15}>
                  <PlatformDescription>
                    Need to add more accounts?
                  </PlatformDescription>
                </Flex>
                <SupportLink
                  data-testid="need-more-ig-contact-support"
                  onClick={openIntercomChat}
                >
                  Contact Support
                </SupportLink>
              </>
            )}

            <ManageSocialAccounts
              justify="center"
              data-testid="manage-social-account-link"
            >
              <Link to="/dashboard/accounts">
                <DropdownMenu.Item>
                  <StyledSocialAccountIcon />
                  Manage social accounts & groups
                </DropdownMenu.Item>
              </Link>
            </ManageSocialAccounts>
          </div>
        )}
      </>
    );
  }
}

export const AddNotificationAccountLink = ({
  className,
}: {
  className?: string;
}) => {
  const counts: AccountsCount = useSelector(getAccountsCounts);
  const { isReachedAccountsLimit: isReachedAccountsLimitCheck } =
    isReachedAccountsLimit(counts);
  if (isReachedAccountsLimitCheck) {
    return (
      <AddNotificationAccountText
        className={className}
        onClick={() => {
          socialAccountConnectEffectorRefs.openSocialAccountConnectDialog?.();
        }}
      >
        Add notification channel
      </AddNotificationAccountText>
    );
  }
  return null;
};

const AddNotificationAccountText = styled.div`
  font-size: 14px;
  color: #4f31ff;
  cursor: pointer;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
`;

export const PlatformDescription = styled.div`
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  color: #a9a9a9;
  max-width: 320px;
`;

export const PlatformDescriptionWithTopMargin = styled(PlatformDescription)`
  margin-top: 10px;
`;

export const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: rgba(14, 22, 128, 0.04);
  margin-top: 15px;
`;

export const CountNumber = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #0e1680;
  margin-right: 15px;
  margin-left: 5px;
`;

export const SupportLink = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #454545;
  margin: 12px 0 10px;
`;
