import React from "react";
import { TooltipWrapper } from "ui";
import { MultiMediaProps, UnsupportedMediaWrapper } from ".";
import { MessageBlock, MessageContainer } from "../../message/styled";

export const MediaFile = (props: MultiMediaProps): JSX.Element => {
  const { media, left, setIsReady } = props;
  React.useEffect(() => {
    setIsReady();
  }, []);

  return (
    <MessageContainer left={left}>
      <MessageBlock {...props} removeMargin>
        <UnsupportedMediaWrapper>
          <a
            href={media.payload?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Sent a file
          </a>
          <TooltipWrapper
            tooltip="This file has not been verified. Be careful downloading files from users you don’t know."
            placement="bottom"
          >
            <span data-testid="tooltip">?</span>
          </TooltipWrapper>
        </UnsupportedMediaWrapper>
      </MessageBlock>
    </MessageContainer>
  );
};
