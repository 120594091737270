import { Effect } from "effector";
import { toast } from "react-toastify";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

export function notify<T, S>(
  effect: Effect<T, S>,
  params: {
    success?: (() => string) | string;
    fail?: (() => string) | string;
  }
) {
  if (params.success) {
    effect.done.watch(() => {
      const message =
        typeof params.success === "function"
          ? params.success()
          : params.success;
      toast.success(message);
    });
  }
  effect.failData.watch((error) => {
    const message =
      typeof params.fail === "function" ? params.fail() : params.fail;
    toast.error(getUserFriendlyErrorMessage(error, message));
  });
}
