import React from "react";

import { filterAccountsByPlatformType } from "utils/accounts";
import { PostAccount } from "components/Posts/Post/index";
import { FIELDS } from "constants/PostFormFields";

import {
  Caption,
  DetailsTemplate,
  PostDetail,
  AltTextTags,
} from "./post-details-template";

export function PinterestDetails({ post, account, page }) {
  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          <PinterestBoardsAndSection post={post} accounts={[account]} />
          <PinterestTitle post={post} account={account} />
          <Caption post={post} label={`Description`} />
          <AltTextTags post={post} />
        </>
      )}
    />
  );
}
export function PinterestTitle({ post }) {
  if (post[FIELDS.PI_TITLE]) {
    return <PostDetail label="Pin title">{post[FIELDS.PI_TITLE]}</PostDetail>;
  } else {
    return null;
  }
}
export function PinterestBoardsAndSection({ post, accounts }) {
  if (accounts) {
    let piAccounts = accounts.filter(filterAccountsByPlatformType("PI"));

    return piAccounts.map((acct) => {
      return renderBoards(post, acct);
    });
  } else {
    return null;
  }
}
const renderBoardList = (boards) => {
  var output = [];
  boards.forEach(function (board, i) {
    if (boards.length > 1 && i === boards.length - 1) output.push("and ");
    output.push(board.name);
    if (boards.length > 2 && i < boards.length - 1) output.push(",");
    if (boards.length > 1 && i < boards.length - 1) output.push(" ");
  });
  return output;
};
const renderBoards = (post, account) => {
  let boards = [];
  if (account && post[FIELDS.PI_BOARDS] && account.boards) {
    boards = account.boards.filter((cat) =>
      post[FIELDS.PI_BOARDS].includes(cat.id)
    );
  }
  // section only possible to select if pre-existing
  let section = null;
  if (post[FIELDS.PI_SECTION_ID] && boards.length === 1) {
    if (boards[0] && boards[0].sections && boards[0].sections.length > 0) {
      section = boards[0].sections.find(
        (section) => section.id === post[FIELDS.PI_SECTION_ID]
      );
    }
  }

  // pinterest v3 - section.title, v5 - section.name
  const sectionName =
    section && (section.title || section.name)
      ? section.title || section.name
      : "No section selected";
  return (
    <>
      {boards && boards.length > 0 && (
        <PostDetail
          label={`Board${boards.length > 1 ? "s (" + boards.length + ")" : ""}`}
        >
          {renderBoardList(boards)}
        </PostDetail>
      )}
      {boards && boards.length === 1 && (
        <PostDetail label={`Board section`}>{sectionName}</PostDetail>
      )}
    </>
  );
};
