import React from "react";
import styled, { css } from "styled-components";
import NumberFormat from "react-number-format";

import { useTrackEvent } from "libs/analytics";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import * as EVENTS from "constants/Events";
import { useSelector } from "react-redux";
import {
  getConversation,
  WrappedInboxState,
} from "selectors/conversationInboxSelectors";
import { Interlocutor } from "types/conversationInbox";
import InboxPreferences from "@inbox/pages/conversation-page/inbox-preferences";

interface Props {
  conversationId: string;
  visible: boolean;
  top?: number;
  left?: number;
  interlocutor?: Partial<Interlocutor>;
}

export function SMUAccountSummary({
  conversationId,
  visible = false,
  top,
  left,
  interlocutor: originalInterlocutor,
}: Props): JSX.Element {
  const { interlocutor, unknownInfo } = useSMUInterloctorInfo(
    conversationId,
    originalInterlocutor
  );

  const { name, followersCount, followingMe, followingHim, handle, platform } =
    interlocutor;

  const accountHandle = InboxPreferences.getSmuAccountHandle(platform, handle);

  const userWarningTooltip = unknownInfo;
  const showProfileLink = !userWarningTooltip;
  const shouldShowDetails =
    InboxPreferences.getSumAccountAllowedShowDetails(platform) &&
    !userWarningTooltip;

  return (
    <Wrapper visible={visible} top={top} left={left}>
      <Card>
        <CardArrow />
        <Header>
          <HeaderLeft>
            <Name>{name}</Name>
            {accountHandle && <Handle>{`@${accountHandle}`}</Handle>}
          </HeaderLeft>
          <HeaderRight>
            {showProfileLink && (
              <ProfileIconWrapper>
                <ProfileLink name={name} handle={handle} />
              </ProfileIconWrapper>
            )}
          </HeaderRight>
        </Header>
        <Footer>
          {shouldShowDetails ? (
            <>
              <LabelText>
                <NumberFormat
                  data-testid="number-formatter"
                  value={followersCount}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                followers
              </LabelText>
              {(followingMe || followingHim) && (
                <FollowContainer>
                  {followingMe && <LabelText bold>Follows You</LabelText>}
                  {followingMe && followingHim && (
                    <FollowDot> &bull; </FollowDot>
                  )}
                  {followingHim && <LabelText bold>Following</LabelText>}
                </FollowContainer>
              )}
            </>
          ) : (
            <LabelText>
              {userWarningTooltip || "Follow data not available"}
            </LabelText>
          )}
        </Footer>
      </Card>
    </Wrapper>
  );
}

export const ProfileLink = ({
  handle,
  name,
}: {
  handle?: string;
  name?: string;
}): JSX.Element => {
  const trackEvent = useTrackEvent();

  const onHandleClick = () => {
    if (handle) {
      trackEvent({ eventName: EVENTS.INBOX_PROFILE_LINK_CLICK });
      window.open(handle, "_blank");
    }
  };

  return (
    <Icon
      icon={faExternalLinkAlt}
      onClick={onHandleClick}
      title={`${name}'s profile`}
      disabled={!handle}
    />
  );
};

export function useSMUInterloctorInfo(
  conversationId: string,
  interlocutor?: Partial<Interlocutor>
) {
  const conversation = useSelector((state: WrappedInboxState) =>
    getConversation(state, conversationId)
  );

  interlocutor = interlocutor || conversation?.interlocutor || {};

  const { platform, unknownUser } = interlocutor;

  function provideUnknownInfo() {
    if (platform === "FB" && unknownUser) {
      return "Currently, the User Profile API does not support retrieving profile information for Messenger accounts that were created using a phone number.";
    }

    if ((!platform || platform === "IG") && unknownUser) {
      return "Meta does not provide further details about this user.";
    }

    return;
  }

  return {
    interlocutor,
    unknownInfo: provideUnknownInfo(),
  };
}

const Wrapper = styled.div<{ visible: boolean; left?: number; top?: number }>`
  transition: all 0.3s;
  position: absolute;
  z-index: 10;
  left: ${(props) => (props.left ? `${props.left}px` : "0")};
  top: ${(props) => (props.top ? `${props.top}px` : "100%")};

  ${(props) =>
    props.visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
  padding: 13px 15px;
  align-self: flex-start;
  width: 235px;
`;

const CardArrow = styled.span`
  position: absolute;
  background: #ffffff;
  border-radius: 0px 0px 2px 0px;
  transform: matrix(-0.72, -0.69, 0.72, -0.69, 0, 0);
  width: 13.7px;
  height: 13.7px;
  display: block;
  top: -7px;
  left: 50%;
  margin-left: -6.85px;
  border-right: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
`;

const Header = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeft = styled.div`
  width: calc(100% - 60px);
`;
const HeaderRight = styled.div``;

const Footer = styled.div`
  padding-top: 8px;
`;
const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  line-break: auto;
  margin-bottom: 4px;
`;
const Handle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LabelText = styled.span<{ bold?: boolean }>`
  font-size: 14px;
  line-height: 14px;
  color: #c4c4c4;
  ${(props) =>
    props.bold &&
    css`
      font-weight: 450;
    `}
`;

const FollowContainer = styled.div`
  line-height: 1.1;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
`;

interface FollowLabel {
  active: boolean;
}

const FollowLabel = styled.span<FollowLabel>`
  font-size: 14px;
  color: #c4c4c4;
`;

const FollowDot = styled.span`
  font-size: 14px;
  color: #c4c4c4;
  margin: 0 5px;
`;

interface IconProp {
  disabled: boolean;
}

const Icon = styled(FontAwesomeIcon)<IconProp>`
  font-size: 14px;
  color: #4f31ff;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: none;
      color: #ccc;
    `}
`;

export const ProfileIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  background: #f6f5ff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
