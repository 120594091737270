import React from "react";
import styled from "styled-components";

import { BaseModal } from "ui/giselle-modal";
import { useDispatch } from "react-redux";
import { hideModal } from "actions/modal";
// import { CrossFadeReplace } from "ui/cross-fade-replace";

import { PlanConfirm, PlanSelection } from "../../components";
import { useChangePlan } from "../../models/change-plan.model";

import { PlansModalGate } from "./PlansModalGate";
import { usePlanEstimate } from "./plan-estimate.model";
import { useSelectedPlan } from "./selected-plan.model";

export const PlansModal = () => {
  const { selectedPlan, selectPlan } = useSelectedPlan();
  const {
    planChangeIntention,
    isLoading: isLoadingEstimate,
    getPlanEstimate,
    goBack,
    showConfirm,
  } = usePlanEstimate();
  const { isChangingPlan, changePlan } = useChangePlan();
  const dispatch = useDispatch();
  const close = () => dispatch(hideModal());

  return (
    <ModalComponent show={true} onHide={close}>
      <PlansModalGate />
      {showConfirm && planChangeIntention ? (
        <PlanConfirm
          key="confirm"
          intention={planChangeIntention}
          isChangingPlan={isChangingPlan}
          onConfirm={() => {
            if (planChangeIntention) {
              changePlan({
                planId: planChangeIntention.planId,
                ugcLibraryAddon: planChangeIntention.ugcLibraryAddon,
                onComplete: close,
              });
            }
          }}
          onBack={goBack}
        />
      ) : (
        <PlanSelection
          key="plans"
          selectedPlan={selectedPlan}
          onSelectPlan={selectPlan}
          onLoadEstimate={getPlanEstimate}
          isLoadingEstimate={isLoadingEstimate}
          onHide={close}
        />
      )}
    </ModalComponent>
  );
};

const ModalComponent = styled(BaseModal)`
  font-size: 14px;
  max-width: 975px;
`;
