import React from "react";
import styled from "styled-components";
import { FIELDS } from "constants/PostFormFields";
import { SkedCheckbox } from "ui";
import { getStoryLabel } from "utils/accounts";
import PostFieldLabel from "../PostFieldLabel/PostFieldLabel";

export function PublishManually({
  postFormValue,
  platforms,
  updateField,
  selectedPlatform,
  allDisabled,
}) {
  const isNotStory = postFormValue[FIELDS.STORY_TYPE]
    ? postFormValue[FIELDS.STORY_TYPE] !== "story"
    : !(postFormValue.isStory || postFormValue.type === "story");

  return (
    <PublishManuallyContainer>
      <SkedCheckbox
        checked={!!postFormValue[FIELDS.PUBLISH_STORY_MANUALLY] || isNotStory}
        disabled={allDisabled || isNotStory}
        onClick={() =>
          updateField(
            FIELDS.PUBLISH_STORY_MANUALLY,
            !postFormValue[FIELDS.PUBLISH_STORY_MANUALLY]
          )
        }
      />
      <PostFieldLabelStyled
        disabled={isNotStory}
        onClick={() => {
          // don't allow changing when the story type is not story
          if (isNotStory) {
            return;
          }
          return updateField(
            FIELDS.PUBLISH_STORY_MANUALLY,
            !postFormValue[FIELDS.PUBLISH_STORY_MANUALLY]
          );
        }}
      >
        Publish{" "}
        {getStoryLabel(
          postFormValue[FIELDS.STORY_TYPE],
          platforms,
          selectedPlatform,
          postFormValue?.accounts || []
        )}{" "}
        manually with push notification or email
      </PostFieldLabelStyled>
    </PublishManuallyContainer>
  );
}

const PublishManuallyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 8px;
`;

export const PostFieldLabelStyled = styled(PostFieldLabel)`
  cursor: pointer;
`;
