import React from "react";
import styled from "styled-components";

import Link from "./Link";

type ExternalLinkProps = React.ComponentProps<typeof Link> & {
  openInNewTab?: boolean;
  trusted?: boolean;
};

const ExternalLink = styled(function ExternalLink({
  className,
  openInNewTab = true,
  trusted = false,
  ...restProps
}: ExternalLinkProps) {
  return (
    <Link
      className={className}
      target={openInNewTab ? "_blank" : undefined}
      rel={trusted ? "noopener" : "noopener noreferrer"}
      {...restProps}
    />
  );
})``;

export default ExternalLink;
