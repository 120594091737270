import { useSearchIgCreators } from "components/MediaLibrary/LibrarySidebar/SourceMediaSidebar/CreatorSidebar/UserSelector";
import { GET_CREATOR_MEDIA_ROUTINE } from "constants/ActionTypes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoutinesLoading } from "selectors/commonSelectors";
import {
  getFeedCreatorUsername,
  getFeedMedia,
  getSelectedIgAccount,
} from "selectors/hashtagFeedSelectors";
import { LoadingCover } from "ui/cover";
import { CollectionFilesList } from "../../collection-files-list";
import { PickerProps } from "../media-picker";
import {
  EmptyAlertWrapper,
  Title,
  TitleBar,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
} from "../components/common";
import { FeedMedia, InstagramUser } from "../types";
import { feedMediaToCollectionFile } from "../helper";
import { AccountSelector } from "../components/account-selector";
import { CreatorSelector } from "./creator-selector";
import { PAGES } from "constants/MediaLibrary";
import { LoadMore } from "../components/load-more";
import { initCreatorFeed } from "actions/sourceMedia";
import { getUser } from "selectors/commonSelectors";
import styled from "styled-components";
import { Button } from "@dialogueconsulting/sked-ui";
import { useOpenIntercomChat } from "libs/use-open-intercom-chat";
import { showUpsellModal } from "actions/typed-modal";
import { useTrackEvent } from "libs/analytics";
import { UPGRADE_LIBRARY_VIEWED } from "constants/Events";

export function CreatorPicker(props: PickerProps) {
  const dispatch = useDispatch();
  const {
    isLoading: isLoadingCreators,
    requestCreator,
    recentCreators,
    selectedCreator,
    handleRemoveCreator,
  } = useSearchIgCreators();

  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const creatorUsername = useSelector(getFeedCreatorUsername) as string;

  const feedMedia = useSelector((state) =>
    getFeedMedia(state, PAGES.CREATOR)
  ) as FeedMedia[];
  const files = feedMedia.map(feedMediaToCollectionFile);

  const loadingInitialMedia = useSelector((state) =>
    getRoutinesLoading(state, [GET_CREATOR_MEDIA_ROUTINE])
  );
  const isLoading = loadingInitialMedia || isLoadingCreators;

  const onCreatorSelected = React.useCallback(
    (user: InstagramUser) => {
      if (!selectedIgAccount?._id) {
        return;
      }
      dispatch(initCreatorFeed({ user, accountId: selectedIgAccount._id }));
    },
    [selectedIgAccount]
  );

  React.useEffect(() => {
    if (!isLoading && !selectedCreator && recentCreators.length > 0) {
      onCreatorSelected(recentCreators[0]);
    }
  }, [isLoading, selectedCreator, recentCreators, onCreatorSelected]);

  const user = useSelector(getUser);
  const upsell = Boolean(user?.plan?.includes("fundamentals"));

  if (upsell) {
    return (
      <UpsellWrap>
        <strong className="head">
          Save content by brand partners, influencers and advocates.
        </strong>
        <strong>1. Search a creator's Instagram account</strong>
        <strong>2. Choose posts to save</strong>
        <p>
          The original creators' info is automatically saved so you can pass on
          the credit
        </p>
        <strong>3. Add it to your Library</strong>
        <strong>
          4. Share it across Instagram, Facebook, Twitter, Pinterest and
          LinkedIn
        </strong>

        <UpsellUGCCTA />
      </UpsellWrap>
    );
  }

  return (
    <LoadingCover isLoading={isLoading}>
      <Toolbar>
        <ToolbarLeft>
          <AccountSelector type="creator" />
        </ToolbarLeft>

        <ToolbarRight>
          <CreatorSelector
            requestUser={requestCreator}
            recentUsers={recentCreators}
            onCreatorSelected={onCreatorSelected}
            handleRemoveCreator={handleRemoveCreator}
          />
        </ToolbarRight>
      </Toolbar>
      {isLoading ? (
        <EmptyAlertWrapper empty />
      ) : selectedIgAccount && feedMedia.length < 1 ? (
        <EmptyAlert />
      ) : (
        <>
          <TitleBar>
            <Title>{creatorUsername}</Title>
          </TitleBar>
          <CollectionFilesList
            files={files}
            selectedMediaSet={props.selectedMediaFiles}
            selectedCollection={props.selectedCollection}
            onSelect={props.handleFileAdd}
            onRemove={props.handleFileRemove}
          />
          <LoadMore page={PAGES.CREATOR} />
        </>
      )}
    </LoadingCover>
  );
}

function EmptyAlert() {
  return (
    <EmptyAlertWrapper>
      <h3>Source UGC from Influencers and Business Accounts you work with.</h3>
      <p>
        Collaborating with an Influencer or Business? Search for them and
        reshare their engaging content across your channels.
      </p>
      <p>Make sure you have permission to repost!</p>
    </EmptyAlertWrapper>
  );
}

export function UpsellUGCCTA({
  hideSupportLink,
}: {
  hideSupportLink?: boolean;
}) {
  const openIntercomChat = useOpenIntercomChat();
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  return (
    <UpsellUGCCTAWrap>
      <UpsellButton
        text="Add Library to your plan"
        endIcon="upsell-rocket-white-bg"
        onClick={() => {
          dispatch(showUpsellModal("upsell", "user-generated-content"));
          trackEvent({
            eventName: UPGRADE_LIBRARY_VIEWED,
            useServices: ["AMP", "IC", "AC", "HS"],
          });
        }}
        size="md"
        useDefaultIconColor
      />
      {!hideSupportLink && (
        <p className="help">
          Get help from
          <a
            onClick={() => {
              openIntercomChat();
            }}
          >
            {" "}
            Sked Support
          </a>
        </p>
      )}
    </UpsellUGCCTAWrap>
  );
}

export const UpsellWrap = styled.div`
  padding: 20px 0;
  line-height: 2;

  .head {
    margin-left: 12px;
  }

  strong {
    display: block;
  }

  p {
    font-size: 14px;
    margin-bottom: 8px;
    margin-left: 18px;
  }
`;

const UpsellUGCCTAWrap = styled.div`
  text-align: center;
`;

const UpsellButton = styled(Button)`
  margin: 15px auto 10px;

  p {
    margin-bottom: 0;
    margin-left: 0;
  }
`;
