import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  getLocalStorageSelectedAccounts,
  setLocalStorageHomeSelectedGroupName,
  setLocalStorageSelectedAccounts,
} from "libs/storage/adapters";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import { SkedButton, SkedCheckbox, SkedIcon } from "ui";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useStore } from "effector-react";
import WarningAlertIcon from "entities/accounts/components/accounts-group-template/warningAlert.svg";

import {
  initSelectedAccounts,
  setSelectedAccounts as setSelectedAccountIdsAction,
} from "actions/accountPicker";
import { getLoading } from "selectors/commonSelectors";
import { navigateTo } from "utils/navigation";
import { getAccountNameParts, splitAccounts } from "utils/accounts";

import {
  $selectedAccountsIds,
  // AccountAvatar,
  AccountGroupTemplate,
  AccountName,
  // AccountPlatformIcon,
  AccountProvider,
  accountsSelected,
  getAccountDisplayName,
  doesAccountNeedApiReconnect,
  isAccountPostingAndInsightsEnabled,
} from "entities/accounts";
import {
  AccountAvatar,
  AccountPlatformIcon,
} from "entities/accounts/components/account/avatar";
import { ACCOUNT_DISCONNECTED_MSG } from "constants/TooltipMessages";

const AccountPicker = ({
  accounts,
  selectedAccountIds,
  isFilter = false,
  isGlobal = true,
  onAccountsChange, // handler that responds w/ selected ids
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const selectedAccounts = useStore($selectedAccountsIds);

  const [selectedAccountsIds, setSelectedAccountsIds] = useState(
    selectedAccountIds ? selectedAccountIds : selectedAccounts
  );

  useEffect(() => {
    if (isGlobal) {
      setSelectedAccountsIds(selectedAccountIds);
    }
  }, [isGlobal, selectedAccountIds]);

  useEffect(() => {
    if (isFilter) {
      const localStorageSelectedIds = JSON.parse(
        getLocalStorageSelectedAccounts() || "[]"
      );
      dispatch(initSelectedAccounts(localStorageSelectedIds));
      setSelectedAccountsIds(localStorageSelectedIds);
    }
  }, []);

  if (!loading && accounts.length === 0) {
    return (
      <div className="tw-bg-yellow-100 tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-12 tw-m-4 tw-border-2 tw-border-solid tw-border-gray-300 tw-rounded-lg">
        <SkedIcon icon="icon-neutral" size="20" className="tw-my-4" />
        <div className="tw-font-black tw-pb-4">No accounts found</div>
        <SkedButton
          className="tw-mb-4"
          size="small"
          onClick={() => navigateTo("root.accounts")}
        >
          Add/Reconnect Account
        </SkedButton>
      </div>
    );
  }

  const { groupedAccounts, otherAccounts } = splitAccounts(accounts);

  const setAccountIds = (accounts, value = null) => {
    let ids = [...selectedAccountsIds];
    accounts.map((account) => {
      const index = _.indexOf(ids, account._id);

      const shouldRemove = value === false || (value === null && index > -1);
      const shouldAdd = value === true || (value === null && index === -1);

      // Unselect
      if (shouldRemove && index > -1) {
        _.pullAt(ids, [index]);
      } else if (shouldAdd && index === -1) {
        ids.push(account._id);
      }
    });

    setSelectedAccountsIds(ids);

    if (isFilter) {
      dispatch(setSelectedAccountIdsAction(ids));
    }

    if (onAccountsChange) {
      onAccountsChange(ids);
    }
    if (isGlobal) {
      accountsSelected(ids);
      setLocalStorageSelectedAccounts(JSON.stringify(ids));
      const [groupName] = getAccountNameParts(accounts[0]);
      value && groupName && setLocalStorageHomeSelectedGroupName(groupName);
    }
  };

  const allSelected = accounts.every((account) =>
    selectedAccountsIds?.includes(account._id)
  );

  const accountsSelectedCount = selectedAccountsIds.length;
  const isPartialChecked =
    accountsSelectedCount > 0 && accountsSelectedCount < accounts.length;
  const isGroupTabDefaultOpen = groupedAccounts.length < 3 ? true : false;
  return (
    <AccountsWrapper>
      <div className="tw-flex tw-items-center tw-mb-4 tw-py-4 tw-px-8 hover:tw-bg-purple-50 tw-cursor-pointer">
        <SkedCheckbox
          checked={allSelected}
          onChange={() => setAccountIds(accounts, !allSelected)}
          isPartialChecked={isPartialChecked}
        >
          <div className="tw-flex-grow tw-px-4 tw-font-medium">
            Select all accounts
          </div>
        </SkedCheckbox>
      </div>
      {_.map(groupedAccounts, ({ title, accounts }) => {
        return (
          <Brand
            key={`brand-name-${title}`}
            groupedAccounts={accounts}
            groupName={title}
            setAccountIds={setAccountIds}
            selectedIds={selectedAccountsIds}
            defaultGroupOpenState={isGroupTabDefaultOpen}
          />
        );
      })}
      <div className="tw-mt-4 tw-mb-75px">
        <AccountList
          accounts={otherAccounts}
          setAccountIds={setAccountIds}
          selectedIds={selectedAccountsIds}
        />
      </div>
    </AccountsWrapper>
  );
};

const Brand = ({
  groupName,
  groupedAccounts,
  setAccountIds,
  selectedIds,
  defaultGroupOpenState,
}) => {
  const toggleGroup = () => {
    setAccountIds(groupedAccounts, !groupChecked);
  };
  const groupChecked = useMemo(() => {
    const selectedAccountsInBrand = groupedAccounts.filter((account) =>
      selectedIds.includes(account._id)
    );

    return selectedAccountsInBrand.length === groupedAccounts.length;
  }, [groupedAccounts, selectedIds]);

  const isPartialChecked = useMemo(() => {
    const selectedAccountsInBrand = groupedAccounts.filter((account) =>
      selectedIds.includes(account._id)
    );

    return (
      selectedAccountsInBrand.length > 0 &&
      selectedAccountsInBrand.length < groupedAccounts.length
    );
  }, [groupedAccounts, selectedIds]);

  const hasDisconnectedAccount = groupedAccounts.some(
    (account) =>
      doesAccountNeedApiReconnect(account) ||
      !isAccountPostingAndInsightsEnabled(account)
  );

  return (
    <div className="tw-mx-4 tw-mb-4">
      <AccountGroupTemplate
        hasDisconnectedAccount={hasDisconnectedAccount}
        defaultOpenState={defaultGroupOpenState}
        renderTitle={() => (
          <Label onClick={(e) => e.stopPropagation()}>
            <SkedCheckbox
              checked={groupChecked}
              isPartialChecked={isPartialChecked}
              onChange={toggleGroup}
            />
            <div>{groupName}</div>
          </Label>
        )}
        renderContent={() => (
          <div className="tw-py-4 tw-bg-purple-50 tw-bg-opacity-25">
            <AccountList
              accounts={groupedAccounts}
              setAccountIds={setAccountIds}
              selectedIds={selectedIds}
              fromBrands={true}
            />
          </div>
        )}
      />
    </div>
  );
};

const Label = styled.label`
  font-size: inherit;
  font-weight: inherit;

  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 0;

  ${SkedCheckbox} {
    margin: 0;
    margin-right: 10px;
  }
`;

const AccountsWrapper = styled.div`
  overflow-y: auto;
`;

const AccountList = ({
  accounts,
  setAccountIds,
  selectedIds,
  fromBrands = false,
}) => {
  if (!accounts || accounts.length === 0) {
    return "";
  }

  // We want the remaining accounts to be lined up w/ the brands
  const leftPadding = fromBrands ? "tw-pl-4" : "tw-pl-8";

  return (
    <div>
      {accounts.map((account) => {
        const checked = selectedIds.includes(account._id);
        const displayName = getAccountDisplayName(account) || "";
        const accountDisconnected =
          account.status === "failed" ||
          doesAccountNeedApiReconnect(account) ||
          !isAccountPostingAndInsightsEnabled(account);

        return (
          <SkedCheckboxStyled
            key={account._id}
            className={`tw-flex tw-items-center tw-my-2 ${leftPadding} tw-cursor-pointer hover:tw-bg-purple-50 tw-h-50px`}
            checked={checked}
            onChange={() => setAccountIds([account], !checked)}
          >
            <AccountProvider account={account}>
              <AccountItem
                className={`tw-relative tw-flex-grow tw-flex tw-items-center tw-truncate tw-h-50px`}
              >
                <AccountIconContainer>
                  {/* Add tooltip if length > 20, otherwise hide */}
                  <TooltipWrapper
                    className={
                      accountDisconnected ? "disconnected-account" : null
                    }
                    tooltip={
                      accountDisconnected
                        ? ACCOUNT_DISCONNECTED_MSG
                        : displayName.length > 20
                        ? displayName
                        : null
                    }
                    placement="top"
                  >
                    <RelativeDiv>
                      <AccountAvatar
                        size="medium"
                        className="tw-mr-4"
                        disconnected={accountDisconnected}
                      />
                      {accountDisconnected && <DisconnectWarningIcon />}
                    </RelativeDiv>
                  </TooltipWrapper>
                </AccountIconContainer>
                <AccountName weight={300} maxLength={20} />
              </AccountItem>
            </AccountProvider>
          </SkedCheckboxStyled>
        );
      })}
    </div>
  );
};

const AccountItem = styled.div`
  ${AccountPlatformIcon} {
    width: 18px;
    bottom: -2px;
  }
`;

function TooltipWrapper({ className, children, tooltip, placement }) {
  if (tooltip) {
    let tooltipJsx = (
      <TooltipStyled className={className} id={tooltip}>
        {tooltip}
      </TooltipStyled>
    );
    return (
      <OverlayTrigger placement={placement} overlay={tooltipJsx}>
        {children}
      </OverlayTrigger>
    );
  }

  return children;
}
const DisconnectWarningIcon = styled(WarningAlertIcon)`
  color: #fa4f38;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: auto;
`;

const RelativeDiv = styled.div`
  position: relative;
`;
const AccountIconContainer = styled.div``;
const SkedCheckboxStyled = styled(SkedCheckbox)`
  ${AccountIconContainer} {
    opacity: ${(props) => (props.checked ? "1" : "0.6")};
  }

  ${AccountName} {
    color: ${(props) => (props.checked ? "#333333" : "#838383")};
  }
`;
const TooltipStyled = styled(Tooltip)`
  &.disconnected-account {
    .tooltip-inner {
      width: 200px;
      text-align: left;
      padding: 4px 8px;
      z-index: 1;
    }
  }
`;

export default AccountPicker;
