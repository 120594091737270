import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  getGroups,
  addGroups,
  updateGroups,
  deleteGroups,
} from "api/accountGroups";
import { toast } from "react-toastify";
import { AccountType } from "shared/types/accounts";

export interface AccountGroupType {
  id?: string;
  _id: string;
  name: string;
  description: string;
  groupDescription?: string;
  key?: string;
  accountIds: string[];
  accounts: AccountType[];
}

const accountGroupsAdapter = createEntityAdapter({
  // sortComparer: (a: AccountGroupType, b: AccountGroupType) => a.name.localeCompare(b.name),
  selectId: (item: AccountGroupType) => item._id || "",
});
export interface InitialAccountGroupsStateType {
  isLoading: boolean;
  ids: string[];
  entities: any;
}
const initialState = {
  isLoading: true,
  ids: [],
  entities: {},
} as InitialAccountGroupsStateType;

export const fetchAccountGroups = createAsyncThunk(
  "accountGroups/fetchAccountGroups",
  async () => {
    try {
      const result = await getGroups();

      return result?.data?.items;
    } catch (err) {
      console.log("fetchAccountGroups err", err);
      throw err;
    }
  }
);

export const createAccountGroups = createAsyncThunk(
  "accountGroups/createAccountGroups",
  async ({ accountGroupsData }: { accountGroupsData: AccountGroupType }) => {
    try {
      const result = await addGroups(accountGroupsData);

      return result?.data;
    } catch (err: any) {
      const serverMessage = err?.response?.data?.message;

      if (serverMessage && serverMessage.includes("E11000")) {
        throw new Error("A group with the same name already exists");
      }

      throw err;
    }
  }
);

export const updateAccountGroups = createAsyncThunk(
  "accountGroups/updateAccountGroups",
  async ({ id, accountGroupsData }: { id: string; accountGroupsData: any }) => {
    try {
      const result = await updateGroups(id, accountGroupsData);

      return result?.data;
    } catch (err: any) {
      const serverMessage = err?.response?.data?.message;

      if (serverMessage && serverMessage.includes("E11000")) {
        throw new Error("A group with the same name already exists");
      }

      throw err;
    }
  }
);
export const deleteAccountGroups = createAsyncThunk(
  "accountGroups/deleteAccountGroups",
  async ({ id }: { id: string }, ...arg) => {
    try {
      const result = await deleteGroups(id);

      return result?.data;
    } catch (err: any) {
      const serverMessage = err?.response?.data?.message;

      if (serverMessage && serverMessage.includes("E11000")) {
        throw new Error("A group with the same name already exists");
      }

      throw err;
    }
  }
);

export const accountGroupsSlice = createSlice({
  name: "accountGroups",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // GET
    builder.addCase(
      fetchAccountGroups.pending,
      (state: InitialAccountGroupsStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchAccountGroups.fulfilled,
      (state: InitialAccountGroupsStateType, action: { payload: any[] }) => {
        accountGroupsAdapter.setAll(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchAccountGroups.rejected,
      (state: InitialAccountGroupsStateType) => {
        state.isLoading = false;
      }
    );

    // CREATE
    builder.addCase(
      createAccountGroups.pending,
      (state: InitialAccountGroupsStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      createAccountGroups.fulfilled,
      (
        state: InitialAccountGroupsStateType,
        action: { payload: AccountGroupType }
      ) => {
        toast.success(
          `New group “${action.payload.name}” has been successfully created.`
        );
        accountGroupsAdapter.addOne(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      createAccountGroups.rejected,
      (state: InitialAccountGroupsStateType, action: any) => {
        state.isLoading = false;
        toast.error(action?.error?.message || "Group could not be added");
      }
    );

    // UPDATE
    builder.addCase(
      updateAccountGroups.pending,
      (state: InitialAccountGroupsStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      updateAccountGroups.fulfilled,
      (state: InitialAccountGroupsStateType, action: { payload: any }) => {
        state.isLoading = false;
        const { _id } = action.payload;
        accountGroupsAdapter.updateOne(state, {
          id: _id,
          changes: action.payload,
        });
        toast.success("Group details have been successfully updated.");
      }
    );
    builder.addCase(
      updateAccountGroups.rejected,
      (
        state: InitialAccountGroupsStateType,
        action: { error?: { message: string } }
      ) => {
        state.isLoading = false;
        toast.error(
          action?.error?.message || "Group details could not be updated."
        );
      }
    );

    // REMOVE
    builder.addCase(
      deleteAccountGroups.pending,
      (state: InitialAccountGroupsStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      deleteAccountGroups.fulfilled,
      (state: InitialAccountGroupsStateType, action: { payload: string }) => {
        toast.success("Account group removed successfully");
        accountGroupsAdapter.removeOne(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      deleteAccountGroups.rejected,
      (
        state: InitialAccountGroupsStateType,
        action: { error?: { message: string } }
      ) => {
        state.isLoading = false;
        toast.error(action?.error?.message || "Group could not be deleted.");
      }
    );
  },
});

export const accountGroupsReducer = accountGroupsSlice.reducer;
export const accountGroupsActions = accountGroupsSlice.actions;
