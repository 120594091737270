import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkedIcon } from "ui";
import Disclaimer from "components/MediaLibrary/SourceMedia/Disclaimer/Disclaimer";
import { initTaggedInFeed } from "actions/sourceMedia";
import { getSelectedIgAccount } from "selectors/hashtagFeedSelectors";

export const TaggedInFeedRefreshButton = () => {
  const dispatch = useDispatch();
  const selectedIgAccount = useSelector(getSelectedIgAccount);

  return (
    <SkedIcon
      icon="icon-refresh"
      size="18"
      className="tw-mr-4"
      onClick={() => dispatch(initTaggedInFeed(selectedIgAccount._id))}
    />
  );
};

const TaggedInFeedActions = () => {
  return (
    <div>
      <div className="CollectionActions tw-mx-8 tw-flex">
        <div></div>
        <div className="CollectionActions-RightActions tw-ml-12 tw-grow">
          <TaggedInFeedRefreshButton />
        </div>
      </div>
      <div className="tw-mb-4">
        <Disclaimer />
      </div>
    </div>
  );
};

export default TaggedInFeedActions;
