import { useCallback, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";

export interface OpenableState {
  isOpened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export function useOpenableState(defaultValue = false): OpenableState {
  const [isOpened, setIsOpened] = useState(defaultValue);
  const open = useCallback(() => setIsOpened(true), []);
  const close = useCallback(() => setIsOpened(false), []);
  const toggle = useCallback(() => setIsOpened(!isOpened), [isOpened]);

  return { isOpened, open, close, toggle };
}

export function useOpenableLocalStorageState(
  storageKey: string,
  defaultValue = false
): OpenableState {
  const [isOpened, setIsOpened] = useLocalStorage(storageKey, defaultValue);
  const open = useCallback(() => setIsOpened(true), [setIsOpened]);
  const close = useCallback(() => setIsOpened(false), [setIsOpened]);
  const toggle = useCallback(
    () => setIsOpened(!isOpened),
    [isOpened, setIsOpened]
  );

  return { isOpened, open, close, toggle };
}
