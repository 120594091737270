import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from "effector";
import { createGate } from "effector-react";
import { Store as ReduxStore } from "redux";
import { sortBy } from "lodash";

import { pagination } from "libs/effector-pagination";
import { persist } from "libs/effector-storage";
import { FileToUpload } from "api/media-library";
import { CollectionFile, MediaFilters, MediaSort } from "shared/types/files";
import { getSelectedCollection } from "selectors/mediaLibrarySelectors";
import { selectCollection } from "actions/mediaLibrary";
import { $collections, getCollectionsFx } from "entities/media-collections";

import {
  getCollectionTagsFx,
  geCollectionMediaFilesFx,
  mediaFilesTable,
  uploadFilesToCollectionFx,
} from "../../collection-media-files.model";
import {
  $selectedMediaSet,
  selectedMediaSetApi,
} from "../../select-media.model";
import {
  uploadingFileTable,
  computeUploadingProgress,
  UploadingMode,
} from "../../uploading-files.model";
import { resetCollectionsTags } from "../collection-media/reset-collection-tags";

export { getCollectionsFx } from "entities/media-collections";

const mediaPageLimit = 20;

export const Gate = createGate();
export const userFilesSelected = createEvent<FileToUpload[]>();
export const filtersChanged = createEvent<MediaFilters>();
export const collectionFileSelected = selectedMediaSetApi.add;
export const collectionFilesClear = selectedMediaSetApi.clear;
export const collectionSelected = createEvent<string>();
export const setSelectedCollection = createEvent<string>();
export const collectionFileDeselected = selectedMediaSetApi.remove;
export const setPage = createEvent<number>();
export const filtersReset = createEvent();

const fileUploaded = createEvent<string>();

const $currentPage = restore(setPage, 1);
const $pagesCount = createStore(0);
const $currentCollectionId = restore<string>(collectionSelected, null).on(
  setSelectedCollection,
  (_, collectionId) => collectionId
);
const $collectionsTags = restore<string[]>(
  getCollectionTagsFx.doneData,
  []
).reset($currentCollectionId, resetCollectionsTags);

const $showingFilesIds = createStore<string[]>([]).on(
  fileUploaded,
  (state, newId) => [newId, ...state].slice(0, mediaPageLimit)
);

export function syncUpSelectedCollection(Store: ReduxStore<unknown>) {
  Store.subscribe(() => {
    const collection = getSelectedCollection(Store.getState());
    if (collection) {
      setSelectedCollection(collection._id);
    }
  });

  collectionSelected.watch((collectionId) => {
    Store.dispatch(selectCollection(collectionId, true));
  });
}

export const defaultFilters: MediaFilters = {
  sort: MediaSort.addedNewestToOldest,
  name: "",
  description: "",
  usage: null,
  tags: [],
  favorite: false,
  mediaType: null,
};

const $filters = restore(filtersChanged, defaultFilters).reset([filtersReset]);

persist({
  key: "libraryPickerFilter",
  store: $filters,
});

const $uploadingFilesIds = createStore<string[]>([]);

const $uploadingStatus = uploadingFileTable
  .getByIds($uploadingFilesIds)
  .map(computeUploadingProgress);

export const $model = combine({
  collections: $collections,
  currentCollection: combine(
    [$collections, $currentCollectionId],
    ([collections, id]) =>
      collections.find((collection) => collection._id === id)
  ),
  filters: $filters,
  collectionsTags: $collectionsTags,
  currentPage: $currentPage,
  pagesCount: $pagesCount,
  selectedMediaSet: $selectedMediaSet,
  selectedMediaFiles: mediaFilesTable.getByIds($selectedMediaSet),
  media: mediaFilesTable.getByIds($showingFilesIds),
  uploadingStatus: $uploadingStatus,
  pendingCollections: getCollectionsFx.pending,
});

// select first collection as current
guard({
  source: getCollectionsFx.doneData.map((collections) => collections[0]._id),
  filter: $currentCollectionId.map((value) => !value),
  target: collectionSelected,
});

guard({
  source: resetCollectionsTags,
  filter: $currentCollectionId.map((id) => Boolean(id)),
  target: getCollectionTagsFx.prepend(() => {
    const id = $currentCollectionId.getState();
    if (id) return id;
    throw new Error("Current collection ID is null");
  }),
});

guard({
  source: $currentCollectionId,
  filter: Boolean,
  target: getCollectionTagsFx,
});

forward({
  from: geCollectionMediaFilesFx.doneData.map((data) =>
    data.list.map((item) => item._id)
  ),
  to: $showingFilesIds,
});

const startUploadingFilesToSelectedCollection = attach({
  effect: uploadFilesToCollectionFx,
  source: combine({
    collectionId: $currentCollectionId,
    selectedMediaSet: $selectedMediaSet,
  }),
  mapParams: (files: FileToUpload[], { collectionId, selectedMediaSet }) => {
    const currentSize = selectedMediaSet.size;

    return {
      files: sortBy(files, (file) => file.name).reverse(),
      collectionId: collectionId ?? "default",
      onFileUploaded(file: CollectionFile) {
        selectedMediaSetApi.addAtIndex({
          id: file._id,
          index: currentSize,
        });
        fileUploaded(file._id);
      },
      uploadingMode: "subsequent" as UploadingMode,
    };
  },
});

forward({
  from: userFilesSelected,
  to: startUploadingFilesToSelectedCollection,
});

forward({
  from: startUploadingFilesToSelectedCollection.doneData,
  to: $uploadingFilesIds,
});

const $requestsParams = combine(
  $filters,
  $currentCollectionId,
  $currentPage,
  (filters, collectionId, page) => ({
    collectionId: collectionId || "",
    page,
    limit: mediaPageLimit,
    ...filters,
  })
);

export const getCurrentCollectionFiles = attach({
  effect: geCollectionMediaFilesFx,
  source: $requestsParams,
  mapParams: (_, data) => data,
});

pagination({
  $currentPage,
  setPage,
  $filters: combine(
    $filters,
    $currentCollectionId,
    Gate.status,
    (filters, collectionId) => ({ collectionId, ...filters })
  ),
  getFx: getCurrentCollectionFiles,
  filter: combine(
    $currentCollectionId,
    Gate.status,
    (collection, gateIsOpened) => collection && gateIsOpened
  ),
  $pagesCount,
  params: { limit: mediaPageLimit },
  timeout: 300,
});

$selectedMediaSet.reset(Gate.close);
