import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getRoutinesLoading } from "selectors/commonSelectors";
import { getSelectedIgAccount } from "selectors/hashtagFeedSelectors";
import AccountPickerDropdown from "components/AccountPicker/AccountPickerDropdown";

import {
  HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
  SELECT_IG_ACCOUNT_ROUTINE,
} from "constants/ActionTypes";
import { selectIgAccount } from "actions/sourceMedia";

const SelectFeedAccount = ({ onClick, accounts }) => {
  const dispatch = useDispatch();
  const selectedIgAccount = useSelector(getSelectedIgAccount);
  const [selectedAccount, setSelectedAccount] = useState(selectedIgAccount);
  const loading = useSelector(getLoading);
  const loadingHashtags = useSelector((state) =>
    getRoutinesLoading(state, [
      SELECT_IG_ACCOUNT_ROUTINE,
      HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
    ])
  );
  const handleChange = (account) => {
    setSelectedAccount(account);
    dispatch(selectIgAccount(account._id));
    onClick(account);
  };

  return (
    <>
      <div className="tw-mt-4 tw-mb-4">Select an Instagram account:</div>
      <AccountPickerDropdown
        accounts={accounts}
        onChange={handleChange}
        selectedAccountIds={selectedAccount ? [selectedAccount._id] : []}
      />
      {!loading && accounts.length === 0 && (
        <div className="tw-text-red-400 tw-font-bold tw-mt-4 tw-px-8">
          Update your Instagram permissions to search and save posts you were
          tagged in.
        </div>
      )}
      {loadingHashtags && <p className={`tw-mt-4 tw-mb-4`}>Loading…</p>}
    </>
  );
};
export default SelectFeedAccount;
