import React, { useMemo } from "react";

import { PlatformUsage } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import { CHARACTER_LIMITS } from "constants/CharacterLimits";
import CaptionField from "./CaptionField";
import { $selectedAccounts } from "entities/accounts/model/selected-accounts";
import { useStore } from "effector-react";

const PostCaption = ({
  platforms,
  selectedPlatform,
  post,
  onChange,
  errors = {},
  warnings = {},
  className = "",
  disabled = false,
  hashtagCount,
  hasMoreThanOnelinkedInAccount,
  selectedLinkedInAccountType,
  selectedLinkedUrn,
}) => {
  const caption = useMemo(() => post.caption || "", [post]);
  const hasYT = platforms.some((platform) => platform.TYPE === "YT");
  const hasPI = platforms.some((platform) => platform.TYPE === "PI");
  // Get the lowest character limit from postPlatformConfig
  const limits = platforms.map(
    (p) => CHARACTER_LIMITS[p.TYPE] || CHARACTER_LIMITS.FB
  );
  const length = Math.min(...limits);

  return (
    <CaptionField
      label={
        <>
          <div className="tw-mr-2">Caption</div>
          {hasYT &&
            ["LINKED", "YT"].includes(selectedPlatform) &&
            " (YouTube video description)"}
          {hasPI &&
            ["LINKED", "PI"].includes(selectedPlatform) &&
            " (Pin description)"}
          <PlatformUsage
            platforms={platforms}
            field={FIELDS.CAPTION}
            selectedPlatform={selectedPlatform}
          />
        </>
      }
      hashtagCount={hashtagCount}
      className={className}
      value={caption}
      disabled={disabled}
      onChange={onChange}
      maxLength={length}
      errors={errors}
      warnings={warnings}
      selectedPlatform={selectedPlatform}
      platforms={platforms}
      hasMoreThanOnelinkedInAccount={hasMoreThanOnelinkedInAccount}
      selectedLinkedInAccountType={selectedLinkedInAccountType}
      selectedLinkedUrn={selectedLinkedUrn}
    />
  );
};

export default PostCaption;
