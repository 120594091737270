import { skedApi } from "api/_skedApi";
import { UserFriendlyError } from "api/UserFriendlyError";

export async function updateCollection(
  id: string,
  collectionPatch: Record<string, unknown>
) {
  const response = await skedApi.request({
    method: "put",
    url: `/content-inbox/inbox/${id}`,
    data: collectionPatch,
  });
  if (!response.data.success) {
    throw new UserFriendlyError(response.data.message);
  }
}
