import React from "react";
import styled from "styled-components";
import { FIELDS } from "constants/PostFormFields";
import { countCharacter } from "utils/strings";
import PostFieldLabel from "../PostFieldLabel/PostFieldLabel";
import CaptionField from "../PostCaption/CaptionField";
import { flexColumn } from "./single-post-form";

export function NotesForManualPublish({
  postFormValue,
  updateField,
  allDisabled,
  errors,
}) {
  return (
    <div>
      <NotesContainer>
        <LabelNotesStyled>Notes for manual publishing</LabelNotesStyled>
        <CaptionField
          value={postFormValue.caption}
          onChange={(val) => updateField(FIELDS.CAPTION, val)}
          upcomingEditPopup={true}
          hashtagCount={countCharacter(postFormValue.caption ?? "", "#")}
          disabled={allDisabled}
          errors={errors}
        />
      </NotesContainer>
    </div>
  );
}
const NotesContainer = styled.div`
  ${flexColumn};
`;
const LabelNotesStyled = styled(PostFieldLabel)`
  margin-bottom: 0.5rem;
`;
