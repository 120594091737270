import * as React from "react";
import styled from "styled-components";

import { Select } from "../select/select";

export function SimpleSelectComponent(props: {
  className?: string;
  value: string;
  options: string[];
  onChange(value: string): void;
  placeholder?: string;
  disabled?: boolean;
}) {
  const options = props.options.map((value) => ({
    value: value,
    label: value,
  }));

  return (
    <Select
      options={options}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
}

export const SimpleSelect = styled(SimpleSelectComponent)``;
