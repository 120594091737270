import React, { useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import styled, { createGlobalStyle } from "styled-components";
import Zuck from "@dialogueconsulting/zuck.js";
import "@dialogueconsulting/zuck.js/dist/zuck.min.css";

import { StoryblockStory } from "api/storyblock";
import { useTrackEvent } from "libs/analytics";

import CloseIcon from "./close.image.svg";
import { VIEWED_SKED_STORY } from "constants/Events";

export interface ZuckStory {
  id: string;
  name: string;
  photo: string;
  link: string;
  seen: boolean;
  lastUpdated: number;
  items: Array<{
    id: string;
    time: number;
    seen: boolean;
    src: string;
    length: number;
    link: string;
    linkText: string;
    type: string;
    preview: string;
  }>;
}

export function ReactZuck({
  initialStories,
  onStoryViewerClosed,
}: {
  initialStories: Array<ZuckStory>;
  onStoryViewerClosed(): void;
}) {
  const storiesElementRef = useRef<HTMLDivElement>(null);

  const [stories, setStories] = useState(initialStories);

  const trackEvent = useTrackEvent();

  useEffect(() => {
    new Zuck(storiesElementRef.current, {
      skin: "snapgram",
      autoFullScreen: true,
      backButton: true,
      previousTap: true,
      avatars: true,
      paginationArrows: true,
      list: false,
      cubeEffect: false,
      localStorage: true,
      stories: cloneDeep(stories),
      reactive: true,
      callbacks: {
        onDataUpdate: (stories: ZuckStory[]) => setStories(stories),
        onView: (storyId: string) => {
          trackEvent({
            eventName: VIEWED_SKED_STORY,
            metadata: { storygroup: stories[storyId] },
          });
        },
        onClose: (_storyId: string, callback: Function) => {
          onStoryViewerClosed();
          callback();
        },
      },
    });
  }, []);

  const timelineItems = useMemo(() => {
    const timelineItems: React.ReactElement[] = [];

    stories.forEach((story, storyId) => {
      const storyItems: React.ReactElement[] = [];

      story.items.forEach((storyItem) => {
        storyItems.push(
          <li
            key={storyItem.id}
            data-id={storyItem.id}
            data-time={storyItem.time}
            className={storyItem.seen ? "seen" : ""}
          >
            <a
              href={storyItem.src}
              data-type={storyItem.type}
              data-length={storyItem.length}
              data-link={storyItem.link}
              data-link-text={storyItem.linkText}
            >
              <img src={storyItem.preview} />
            </a>
          </li>
        );
      });

      let arrayFunc = story.seen ? "push" : "unshift";
      timelineItems[arrayFunc](
        <div
          className={story.seen ? "story seen" : "story"}
          key={storyId}
          data-id={storyId}
          data-last-updated={story.lastUpdated}
          data-photo={story.photo}
        >
          <a className="item-link" href={story.link}>
            <span className="item-preview">
              <img src={story.photo} />
            </span>
            <span
              className="info"
              itemProp="author"
              itemScope
              itemType="http://schema.org/Person"
            >
              <strong className="name" itemProp="name">
                {story.name}
              </strong>
              <span className="time">{story.lastUpdated}</span>
            </span>
          </a>

          <ul className="items">{storyItems}</ul>
        </div>
      );
    });

    return timelineItems;
  }, [stories]);

  return (
    <Root>
      <ZuckModalStyles />
      <div
        ref={storiesElementRef}
        id="stories-react"
        className="storiesWrapper"
      >
        {timelineItems}
      </div>
    </Root>
  );
}

const Root = styled.div`
  --storySize: 45px;
  .stories.carousel {
    overflow: initial !important;
  }

  .stories.carousel .story {
    width: calc(var(--storySize) * 1.4);
    max-width: calc(var(--storySize) * 1.4);
  }

  .stories.carousel .story > .item-link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stories.carousel .story > .item-link > .item-preview {
    height: var(--storySize);
    max-height: var(--storySize);
    width: var(--storySize);
    max-width: var(--storySize);
  }
  .stories.carousel .story > .item-link > .info > .name {
    font-size: 13px;
    line-weight: 1.2;
    font-weight: 500;
    color: #000000;
  }
`;

const ZuckModalStyles = createGlobalStyle`
  #zuck-modal #zuck-modal-content .story-viewer .slides .item {
    background: #333;
  }
  #zuck-modal-content .story-viewer .head {
    height: 68px;
    background-color: rgba(0, 0, 0, 0.31);
  }
  #zuck-modal-content .story-viewer.loading .head .loading, #zuck-modal-content .story-viewer.muted .tip.muted {
    top: 75px;
    right: 10px;
    bottom: unset;
    left: unset;
    z-index: 10000;
  }
  #zuck-modal-content .story-viewer .tip.link {
    background-color: #4F31FF;
    box-shadow: 0px 4px 10px rgba (0, 0, 0, 0.15);
  }
  #zuck-modal-content .story-viewer .slides .item > .media {
    max-width: 100% !important;
  }

  #zuck-modal #zuck-modal-content .story-viewer .close {
    background: url(${CloseIcon});
    width: 23px;
    height: 23px;
    font-size: 0;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
`;

export function createZuckStory(storyblockStory: StoryblockStory): ZuckStory {
  return Zuck.buildTimelineItem(
    storyblockStory.id,
    storyblockStory.content.profile_pic.filename,
    storyblockStory.name,
    "",
    new Date(storyblockStory.published_at).getTime() / 1000,
    storyblockStory.content.stories.map(createZuckStoryItem)
  );
}

// Credits: https://stackoverflow.com/a/51399781
type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

function createZuckStoryItem(
  storyblockStoryItem: ArrayElement<StoryblockStory["content"]["stories"]>
) {
  return [
    storyblockStoryItem._uid,
    storyblockStoryItem.type,
    parseInt(storyblockStoryItem.length),
    storyblockStoryItem.src.filename,
    storyblockStoryItem.preview.filename,
    getStoryItemLink(storyblockStoryItem),
    storyblockStoryItem.link_text ?? "",
    false,
    new Date(storyblockStoryItem.timestamp).getTime() / 1000,
  ];
}

function getStoryItemLink(
  storyblockStoryItem: ArrayElement<StoryblockStory["content"]["stories"]>
) {
  if (!storyblockStoryItem.link) {
    return "";
  }
  if (storyblockStoryItem.link.linktype !== "url") {
    return "";
  }

  return storyblockStoryItem.link.url ?? "";
}
