import React from "react";
import { FormControl as FC } from "react-bootstrap";
import "./FormControl.less";

const FormControl = React.forwardRef(
  ({ className = "", type = "text", ...other }, ref) => {
    return (
      <FC
        inputRef={ref}
        className={`${className} SkedFormControl`}
        type={type}
        {...other}
      />
    );
  }
);

export default FormControl;
