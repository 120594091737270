import { isUserPlan } from "features/upsell/plan-utils";
import { User } from "../features/user";

export const getUserFullName = (user: {
  fullName: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
}): string => {
  const { firstName, fullName, lastName } = user;

  if (fullName) {
    return fullName;
  }

  return `${firstName} ${lastName}`;
};

export const getUserFirstName = (user: {
  fullName: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
}): string => {
  const { firstName, fullName, lastName } = user;

  if (firstName) {
    return firstName;
  }

  return lastName || fullName || "";
};

export const getUserNameInitials = (user: {
  firstName: string | undefined;
  lastName: string | undefined;
}): string => {
  const fInit = user.firstName ? user.firstName[0] : "";
  const lInit = user.lastName ? user.lastName[0] : "";

  return fInit + lInit;
};

export const getUserDefaultStatus = (user: User) => {
  const postStatuses =
    user?.isPostStatusEnabled && user?.postStatuses.length > 0
      ? user.postStatuses
      : [];
  const defaultPostStatus = postStatuses.find((status) => status.isDefault);

  return defaultPostStatus;
};

export const getUserDefaultStatusKey = (user: User) => {
  const defaultPostStatus = getUserDefaultStatus(user);

  return defaultPostStatus?.key;
};

export const isUserCollaborator = (user: User) => {
  return user.role === "collaborator";
};

export function isUpsellRequiredForApproval(user: User) {
  return (
    isUserPlan(user, "sked-fundamentals", "sked-essentials") &&
    !user.isPostStatusEnabled
  );
}
