//helpers

import moment from "moment";
import _, { get } from "lodash";
import twitter from "twitter-text";

import ngDeps from "ng-react-directives/ngr-injector";
import { capitalize, countCharacter } from "utils/strings";
import { dateInThePast } from "utils/dates";

import { postPlatformsConfig, RULES } from "./postPlatforms.config";
import { DATE_FORMAT } from "constants/common";
import { FIELDS, GMB_TOPICS } from "constants/PostFormFields";
import { POST_STATES, POST_TYPES } from "constants/PostsView";
import { getMediaFileDuration, getRatio } from "entities/media-files";
import { MediaFilesErrors, MediaFilesWarning } from "features/post";
import {
  filterAccountsByPlatformType,
  getAccountNameParts,
} from "utils/accounts";
import { Platform } from "features/post/format-post";
import { MediaUrlInfo, PostMediaFile } from "shared/types/post";
import { localStorageFeatureToggle } from "utils/feature-toggle-utils";

type LinkedUnlinkedPlatforms = {
  linkedPlatforms: Platform[];
  unlinkedPlatforms: Platform[];
};

export let fbMinimum = 120;

export const validate = (
  postState,
  post,
  platformFields,
  platforms,
  overlapSchedule
) => {
  /*
      The form can have error states (currently defined)
      and warning states.

      An error state should mean that the form is not able to be
      saved. The user must fix the errors prior to saving.

      Warning states are used where the subsequent post will likely
      be different to what the user might think (e.g. it will not include
      a file for one social network). The user can still save the post
      as it will still go "live". Errors should be used where the post
      will not be posted at all.
   */
  const { $rootScope } = ngDeps;
  const timezone = $rootScope.user.timezone;
  const platformTypes = _.map(platforms, "TYPE");
  const hasIG = platformTypes.includes("IG");
  const hasFB = platformTypes.includes("FB");
  const hasTW = platformTypes.includes("TW");
  const hasLI = platformTypes.includes("LI");
  const hasPI = platformTypes.includes("PI");
  const hasGMB = platformTypes.includes("GMB");
  const hasTH = platformTypes.includes("TH");
  const hasTT = platformTypes.includes("TT");
  /**
   * Format: {
   *   field: "",
   *   message: "",
   * }
   */
  const errors = {};
  const warnings = {};
  // TEMPORARY PI ERROR
  if (post && post.accounts && post.accounts.length > 0) {
    const piAccounts = post.accounts.filter(filterAccountsByPlatformType("PI"));

    if (piAccounts && piAccounts.length > 1) {
      errors[FIELDS.ACCOUNTS] = {
        errors: [
          "You can currently only schedule a post to one Pinterest account at a time.",
        ],
      };
    }
  }
  // END TEMPORARY PI
  let mediaFiles = post.mediaFiles ? _.cloneDeep(post.mediaFiles) : [];
  // shim a mediaFile object in here so that we still run the validations
  if (mediaFiles && mediaFiles.length === 0) {
    if (post.url) {
      mediaFiles = [
        {
          _id: post.id,
          altText: post.altText,
          url: post.url,
          meta: post.meta || {},
          fileName: post.fileName || null,
          isVideo: post.isVideo || false,
          isDocument: post.isDocument || false,
        },
      ];
    }
  }

  if (post && postState === POST_STATES.SCHEDULED) {
    // Post is in the past
    if (!post.isNow && post.when && dateInThePast(post.when)) {
      errors[FIELDS.POST_STATE] = {
        errors: [`Scheduled date cannot be in the past (${timezone}).`],
      };
    }

    //post schedule overlap another one
    if (
      overlapSchedule &&
      overlapSchedule.overlappingPostId &&
      overlapSchedule.overlappingPostId !== post.id
    ) {
      const [, name] = getAccountNameParts({
        login: overlapSchedule.accountName,
      });
      const errorMessage = `This ${
        post.isStory ? "story" : "post"
      } is scheduled within 15 minutes of a post already in the database at ${moment(
        overlapSchedule.nextRunAt
      ).format(DATE_FORMAT)} for ${name}. Please change the time.`;

      errors[FIELDS.POST_STATE]?.errors
        ? errors[FIELDS.POST_STATE].errors.push(errorMessage)
        : (errors[FIELDS.POST_STATE] = { errors: [errorMessage] });
    }
  }

  const mediaErrors: MediaFilesErrors = {};
  const mediaWarnings: MediaFilesWarning = {};

  if (mediaFiles && mediaFiles.length > 0) {
    // Max media assets is 10
    if (mediaFiles.length > 10) {
      mediaErrors.tooManyFiles = 10;
    }

    // Warning about auto-posting stories > 5 items in length
    if (
      post.type === POST_TYPES.STORY &&
      !(post[FIELDS.PUBLISH_STORY_MANUALLY] === true) &&
      mediaFiles &&
      mediaFiles.length > 5
    ) {
      mediaWarnings.IGStoryItemsCount = true;
    }

    /*
      Start ratio check for IG feed posts

      For Instagram feed posts (not stories), the 2nd and later
      assets should always be the same aspect ratio as the first
      item in the array.

      Do not run this for stories as it doesn't matter
     */
    if (hasIG && post.type !== POST_TYPES.STORY) {
      // ratio of the first file in the array
      const ratio = (() => {
        if (mediaFiles[0].size || mediaFiles[0].meta) {
          return getRatio(mediaFiles[0]);
        } else {
          // return 0 if we don't know/have meta
          return 0;
        }
      })();

      // check the ratios of later posts if we have a ratio for
      // the first post - they shoudl all be equal
      const ratioErrIndexes: any[] = [];
      if (ratio > 0) {
        mediaFiles.map((mediaFile, index) => {
          if (mediaFile.size || mediaFile.meta) {
            if (getRatio(mediaFile) !== ratio) {
              ratioErrIndexes.push(index);
            }
          } else {
            // no metadata so we can't check ratio errors
          }
        });
      }

      if (ratioErrIndexes.length > 0) {
        mediaWarnings.notSameRatioIndexes = ratioErrIndexes;
      }
    }
    // End IG profile ratio check

    /* START MEDIAFILES VALIDATIONS */

    /*
      Platform resolution and aspect ratio checks

      Check resolution and aspect ratios of files
      Against the recommended minimums for each platform
     */
    const resolutionWarnIndexesIG: any[] = [];
    const resolutionErrIndexesFB: any[] = [];
    const resolutionWarnIndexesFB: any[] = [];
    const resolutionWarnIndexesGMB: any[] = [];

    const videoIndexes: number[] = [];
    const documentIndexes: number[] = [];
    const imageIndexes: number[] = [];

    // minimum pixels for FB
    if (post.type === POST_TYPES.STORY) {
      fbMinimum = 500;
    }
    mediaFiles.forEach((mediaFile, index) => {
      if (mediaFile.size && !mediaFile.meta) {
        // shim
        mediaFile.meta = mediaFile.size;
        delete mediaFile.size;
      }

      if (mediaFile.meta) {
        if (mediaFile.meta.width < 1080) {
          resolutionWarnIndexesIG.push(index);
          resolutionWarnIndexesFB.push(index);
        }
        // GMB requires images to be at least 250x250
        if (mediaFile.meta.width < 250 || mediaFile.meta.height < 250) {
          resolutionWarnIndexesGMB.push(index);
        }

        // FB requires width and height to be at least 120px
        if (
          mediaFile.meta.width < fbMinimum ||
          mediaFile.meta.height < fbMinimum
        ) {
          resolutionErrIndexesFB.push(index);
        }
      }

      if (
        mediaFile.isVideo ||
        (mediaFile.url && mediaFile.url.includes(".mp4"))
      ) {
        videoIndexes.push(index);
      } else if (
        mediaFile.type === "document" ||
        (mediaFile.url && mediaFile.url.includes(".pdf"))
      ) {
        documentIndexes.push(index);
      } else {
        imageIndexes.push(index);
      }

      // IG FEED RATIO CHECK
      // Validate Instagram specifically here rather than platform config
      // as we need to do IG feed separate to IG story
      if (hasIG && post.type !== POST_TYPES.STORY) {
        // Check that videos are at least 3 seconds
        if (videoIndexes.length > 0 && !post[FIELDS.PUBLISH_STORY_MANUALLY]) {
          const duration = getMediaFileDuration(mediaFiles[index]);
          if (duration) {
            if (duration < 3) {
              (mediaErrors.IGVideoTooShortVideoIndexes ??= []).push(index);
            }
          }
        }
        if (
          getRatio(mediaFile) > 0 &&
          (getRatio(mediaFile) < 0.56 || getRatio(mediaFile) > 1.91)
        ) {
          (mediaWarnings.IGRatioRecommendationIndexes ??= []).push(index);
        }
      }
      // IG STORY RATIO CHECK
      // Validate Instagram stories here rather than platform config
      // as we need to do IG feed separate to IG story
      // stories should be approx 0.6 (1836x3019), show warning otherwise
      if (
        hasIG &&
        post[FIELDS.TYPE] === POST_TYPES.STORY &&
        post[FIELDS.STORY_TYPE] !== "reel" &&
        post[FIELDS.STORY_TYPE] !== "igtv" &&
        !post[FIELDS.PUBLISH_STORY_MANUALLY]
      ) {
        // check stories are 3-15 seconds
        // only run this validation if the story is NOT set to post manually.
        // user can crop manually or if it is a reels or IGTV post then they can post manually
        // we do not do any validations for items posted manually so do not worry about IGTV/Reels spec.
        if (videoIndexes.length > 0) {
          const duration = getMediaFileDuration(mediaFiles[index]);
          if (duration) {
            if (duration < 3) {
              (mediaErrors.IGStoryVideoTooShortVideoIndexes ??= []).push(index);
            } else if (duration > 60) {
              (mediaErrors.IGStoryVideoTooLongVideoIndexes ??= []).push(index);
            }
          }
        }
        const currentRatio = getRatio(mediaFile);
        // exclude if 1080x1920 - these stories are now supported
        // for auto story publishing
        if (currentRatio > 0 && (currentRatio < 0.56 || currentRatio > 0.61)) {
          (mediaWarnings.IGStoryRatioIndexes ??= []).push(index);
        }
      }
    });
    if (hasIG && resolutionWarnIndexesIG.length > 0) {
      mediaWarnings.IGResolutionIndexes = resolutionWarnIndexesIG;
    }
    if (hasGMB && resolutionWarnIndexesGMB.length > 0) {
      mediaWarnings.GMBResolutionIndexes = resolutionWarnIndexesGMB;
    }

    if (hasFB) {
      // TODO: check it. resolutionWarnIndexesFB contain indexes for mediaFile.meta.width < 1080
      // but we text "Facebook's recommended 500 pixels"

      if (resolutionErrIndexesFB) {
        if (resolutionErrIndexesFB.length > 1) {
          mediaWarnings.FBResolutionIndexes = resolutionErrIndexesFB;
        }
      }
    }

    // END Platform resolution and aspect ratio checks

    // START VIDEO FILE CHECKS
    /* START FB VALIDATIONS */
    // FB validations - does not support videos as part of a carousel, so warn if mediaFiles > 1
    // (we will post only the first one(s)
    if (hasFB) {
      if (videoIndexes.length > 0) {
        videoIndexes.forEach((index) => {
          const duration = getMediaFileDuration(mediaFiles[index]);
          if (duration) {
            if (duration < 1) {
              (mediaErrors.FBVideoTooShortVideoIndexes ??= []).push(index);
            } else if (duration > 14400) {
              (mediaErrors.FBVideoTooLongVideoIndexes ??= []).push(index);
            }
          }
        });
      }
      if (videoIndexes.length > 0 && mediaFiles.length > 1) {
        mediaWarnings.FBVideoIndexes = videoIndexes;
      }
    }
    /* END FB VALIDATIONS */

    /* START LI VALIDATIONS */
    // LinkedIn validations - does not support videos in carousels
    if (hasLI) {
      // only one document allowed per post
      if (post.type === POST_TYPES.DOCUMENT) {
        if (mediaFiles && mediaFiles.length > 1) {
          mediaErrors.LIDocumentsLimit = true;
        }
      }
      // if only 1 video, that is fine. Run validations if > 1 video
      if (videoIndexes.length > 0) {
        if (imageIndexes.length > 0) {
          mediaWarnings.LIVideosInCarouselIndexes = videoIndexes;
        } else if (videoIndexes.length === 1) {
          // do nothing, this is fine as post has only 1 video
        } else {
          // no images and > 1 video - this will not post to LinkedIn at all
          mediaErrors.LIVideosInCarouselIndexes = videoIndexes;
        }
      }
      // Failed. com.linkedin.content.common.ResponseException: Media length greater than 9 not supported for images.
      if (imageIndexes && imageIndexes.length > 9) {
        mediaErrors.LIImagesLimit = true;
      }
    }
    /* END LI VALIDATIONS */
    if (hasTH) {
      // no docs on TH
      if (post.type === POST_TYPES.DOCUMENT || documentIndexes.length > 0) {
        mediaWarnings.THDocumentNotSupported = true;
      }
      // images are auto scaled by threads platform
      if (imageIndexes.length > 0) {
        imageIndexes.forEach((index) => {
          if (!mediaFiles[index].meta) {
            return;
          }
          if (
            mediaFiles[index].meta.width > 1440 ||
            mediaFiles[index].meta.width < 360
          ) {
            (mediaWarnings.THResolutionImageIndexes ??= []).push(index);
          }
          if (
            Number.isInteger(mediaFiles[index].meta.size) &&
            mediaFiles[index].meta.size > 8000000
          ) {
            (mediaErrors.THImageTooBigIndexes ??= []).push(index);
          }
        });
      }
      // check video attributes
      if (videoIndexes.length > 0) {
        videoIndexes.forEach((index) => {
          if (!mediaFiles[index].meta) {
            return;
          }
          const duration = getMediaFileDuration(mediaFiles[index]);
          if (duration) {
            if (duration < 1) {
              (mediaErrors.THVideoTooShortIndexes ??= []).push(index);
            } else if (duration > 300) {
              (mediaErrors.THVideoTooLongIndexes ??= []).push(index);
            }
          }
          // some videos have "N/A" for size but just in case
          // they get a value, we will check for it
          if (
            Number.isInteger(mediaFiles[index].meta.size) &&
            mediaFiles[index].meta.size > 10000000
          ) {
            (mediaErrors.THVideoTooBigIndexes ??= []).push(index);
          }
          if (mediaFiles[index].meta.width > 1920) {
            (mediaErrors.THVideoTooWideIndexes ??= []).push(index);
          }
        });
      }
    }

    /* START PI VALIDATIONS */
    if (hasPI) {
      // currently none - only run the full validation
    }
    /* END PI VALIDATIONS */

    /* START TW VALIDATIONS */
    // Twitter validations - videos not supported in carousel, only images. So fail if mediaFiles > 1
    if (hasTW) {
      // at least two mediaFile in the array and 1+ video
      if (mediaFiles.length > 1 && videoIndexes.length > 0) {
        if (imageIndexes.length === 0) {
          // all of the mediaFiles are videos, so we cannot post this post
          mediaErrors.TWImagesLimit = true;
        } else {
          // hast at least one image
          mediaWarnings.TWHasVideoInCarousel = true;
        }
      }
      if (videoIndexes.length > 0) {
        videoIndexes.map((index) => {
          const duration = getMediaFileDuration(mediaFiles[index]);
          if (duration) {
            if (duration < 1) {
              (mediaErrors.TWVideoTooShortIndexes ??= []).push(index);
            } else if (duration > 140) {
              (mediaErrors.TWVideoTooLongIndexes ??= []).push(index);
            }
          }
        });
      }
      // where mediaFiles is 5+ items and there is at least one image and one video, warn that we will only post the images
      if (
        mediaFiles.length > 4 &&
        imageIndexes.length > 0 &&
        videoIndexes.length > 0
      ) {
        mediaWarnings.TWFilesLimit = true;
      }

      if (mediaFiles.length > 4) {
        if (postState === POST_STATES.DRAFT) {
          mediaWarnings.TWFilesLimit = true;
        } else {
          mediaErrors.TWImagesOverLimit = true;
        }
      }
    }
    /* END TW VALIDATIONS */

    /* START GMB VALIDATIONS */
    // GMB validations - will always not post videos
    if (hasGMB) {
      // videos not supported
      const gmbPost = { ...post, ...(platformFields.GMB ?? {}) };
      if (videoIndexes.length > 0) {
        if (imageIndexes.length > 0) {
          mediaWarnings.GMBHasVideo = true;
        } else {
          // only videos so error this post
          mediaErrors.GMBHasVideo = true;
        }
      }

      // Will only post the first image of a  carousel
      if (mediaFiles.length > 1 && imageIndexes.length > 0) {
        mediaWarnings.GMBHasMoreOneImages = true;
      }
      // ensure we have a topic type
      if (!gmbPost.gmbTopicType) {
        errors[FIELDS.GMB_TOPIC_TYPE] = {
          errors: ["A topic type is required."],
        };
      }
    }
    /* END GMB VALIDATIONS */

    if (hasTT) {
      validateTTMedia({
        post,
        mediaErrors,
        mediaWarnings,
        mediaFiles,
        imageIndexes,
        videoIndexes,
      });
    }
  }
  /* END MEDIAFILES VALIDATIONS */

  if (Object.keys(mediaErrors).length !== 0) {
    errors[FIELDS.MEDIA] = {
      errors: mediaErrors,
    };
  }

  if (Object.keys(mediaWarnings).length !== 0) {
    warnings[FIELDS.MEDIA] = {
      warnings: mediaWarnings,
    };
  }

  const validatePlatform = (platform, post) => {
    const rules = postPlatformsConfig[platform.TYPE];
    if (!rules) {
      return;
    }
    const { validation } = rules;
    if (!validation) {
      return;
    }

    // check errors
    if (validation.error && Object.keys(validation.error).length > 0) {
      Object.keys(validation.error).map((field) => {
        // do not test type in light validation
        if (field !== FIELDS.TYPE) {
          Object.keys(validation.error[field]).map((ruleKey) => {
            let value = getSafeFieldValue(post, field);
            // first comment and post caption for insta should have not more then 30 hashtags together
            if (
              hasIG &&
              platform.TYPE === "IG" &&
              ruleKey === RULES.HASHTAG_COUNT
            ) {
              value = post.caption + " " + post.firstcomment;
            }
            const errMsg = validateRule(
              field,
              ruleKey,
              validation.error[field][ruleKey],
              value,
              platform
            );
            if (errMsg !== null && field !== FIELDS.MEDIA) {
              if (!errors[field]) {
                errors[field] = { errors: [] };
              }
              errors[field]["errors"].push(errMsg);
            }

            if (errMsg !== null && field === FIELDS.MEDIA) {
              if (!errors[field]) {
                errors[field] = { errors: {} };
              }
              errors[field]["errors"]["tooManyFiles"] =
                validation.error[field][ruleKey][1];
            }
          });
        }
      });
    }
    // check warnings
    if (validation.warn && Object.keys(validation.warn).length > 0) {
      Object.keys(validation.warn).map((field) => {
        Object.keys(validation.warn[field]).map((ruleKey) => {
          const errMsg = validateRule(
            field,
            ruleKey,
            validation.warn[field][ruleKey],
            getSafeFieldValue(post, field),
            platform
          );
          if (errMsg !== null) {
            if (!warnings[field]) {
              warnings[field] = { warnings: [] };
            }
            warnings[field]["warnings"].push(errMsg);
          }
        });
      });
    }
  };

  const linkedPlatforms = platforms.filter(
    (platform) => !platformFields[platform.TYPE]?.unlinked
  );

  const unlinkedPlatforms = Object.keys(platformFields)
    .filter((platform) => platform.unlinked)
    .map((platformKey) => ({ TYPE: platformKey }));

  // Process rules for each platform.
  linkedPlatforms.forEach((platform) => validatePlatform(platform, post));
  unlinkedPlatforms.forEach((platform) =>
    validatePlatform(platform, { ...post, ...platformFields[platform.TYPE] })
  );

  return [errors, warnings];
};

function validateTTMedia({
  mediaErrors,
  videoIndexes,
  imageIndexes,
  mediaFiles,
  mediaWarnings,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: any;
  mediaErrors: MediaFilesErrors;
  mediaWarnings: MediaFilesWarning;
  mediaFiles: PostMediaFile[];
  imageIndexes: number[];
  videoIndexes: number[];
}) {
  if (videoIndexes.length > 1) {
    mediaErrors.TTVideoTooMany = true;
    return;
  }
  if (videoIndexes.length && imageIndexes.length) {
    mediaErrors.TTMixedPhotoVideo = true;
  }

  if (
    mediaFiles.some(
      (c) => getMediaType(c) !== "image" && getMediaType(c) !== "video"
    )
  ) {
    mediaErrors.TTUnsupportedMediaFile = true;
  }

  let ttWrongImageSizeIndexes: number[] = [];
  const LONG_SIZE_MAX = 1920;
  const SHORT_SIZE_MAX = 1080;

  for (const imgIdx of imageIndexes) {
    const img = mediaFiles[imgIdx];
    if (!img.meta) {
      continue;
    }
    const { width, height } = img.meta;
    if (
      !width ||
      !height ||
      (width <= SHORT_SIZE_MAX && height <= SHORT_SIZE_MAX)
    ) {
      continue;
    }
    if (width >= height && (width > LONG_SIZE_MAX || height > SHORT_SIZE_MAX)) {
      ttWrongImageSizeIndexes = ttWrongImageSizeIndexes.concat(imgIdx);
    } else if (
      height >= width &&
      (height > LONG_SIZE_MAX || width > SHORT_SIZE_MAX)
    ) {
      ttWrongImageSizeIndexes = ttWrongImageSizeIndexes.concat(imgIdx);
    }
  }
  if (ttWrongImageSizeIndexes.length) {
    mediaWarnings.TTWrongImageSizeIndexes = ttWrongImageSizeIndexes;
  }
}

export const validateFull = (
  postState,
  post,
  platformFields,
  platforms,
  overlapSchedule
) => {
  let [errors, warnings] = validate(
    postState,
    post,
    platformFields,
    platforms,
    overlapSchedule
  );
  // validate that the selected accounts have queue enabled
  if (
    postState === POST_STATES.QUEUED &&
    post.accounts &&
    post.accounts.length > 0
  ) {
    post.accounts.map((account) => {
      if (!account.queueEnabled) {
        if (!errors[FIELDS.POST_STATE]) {
          errors[FIELDS.POST_STATE] = { errors: [] };
        }
        // show one error box per account that does not have queue enabled
        // so the customer knows which to remove or change
        errors[FIELDS.POST_STATE]["errors"].push(
          `${account.loginAlias || account.login} (${
            account.platformType || "IG"
          }) does not have queue enabled. To schedule this post to ${
            account.loginAlias || account.login
          }, pick a scheduled time instead or set up the queue in queue settings.`
        );
      }
    });
  }
  // check GMB
  const [gmbErrors, gmbWarnings] = validateGmb(
    postState,
    post,
    platformFields,
    platforms
  );

  if (gmbErrors) {
    errors = { ...errors, ...gmbErrors };
  }
  if (gmbWarnings) {
    warnings = { ...warnings, ...gmbWarnings };
  }

  // check PI
  const [piErrors, piWarnings] = validatePi(
    postState,
    post,
    platformFields,
    platforms
  );

  if (piErrors) {
    errors = { ...errors, ...piErrors };
  }
  if (piWarnings) {
    warnings = { ...warnings, ...piWarnings };
  }
  // validate twitter with the relevant library
  const [twErrors, twWarnings] = validateTw(
    postState,
    post,
    platformFields,
    platforms
  );

  if (twErrors) {
    errors = { ...errors, ...twErrors };
  }
  if (twWarnings) {
    warnings = { ...warnings, ...twWarnings };
  }
  // validate threads with the relevant library
  const [thErrors, thWarnings] = validateTh(
    postState,
    post,
    platformFields,
    platforms
  );

  if (thErrors) {
    errors = { ...errors, ...thErrors };
  }
  if (thWarnings) {
    warnings = { ...warnings, ...thWarnings };
  }
  // Process rules for each platform.

  function validatePlatform(platform: Platform, post) {
    const rules = postPlatformsConfig[platform.TYPE];
    if (!rules) {
      return;
    }
    const { validation } = rules;
    if (!validation) {
      return;
    }
    // check errors only
    if (validation.error && Object.keys(validation.error).length > 0) {
      Object.keys(validation.error).map((field) => {
        // do not test type in light validation
        Object.keys(validation.error[field]).map((ruleKey) => {
          const errMsg = validateRule(
            field,
            ruleKey,
            validation.error[field][ruleKey],
            getSafeFieldValue(post, field),
            platform
          );
          if (errMsg !== null) {
            if (!errors[field]) {
              errors[field] = { errors: [] };
            }
            errors[field]["errors"].push(errMsg);
          }
        });
      });
    }
  }

  const { linkedPlatforms, unlinkedPlatforms }: LinkedUnlinkedPlatforms =
    platforms.reduce(
      (acum: LinkedUnlinkedPlatforms, platform: Platform) => {
        if (platformFields[platform.TYPE]?.unlinked) {
          acum.unlinkedPlatforms.push(platform);
        } else {
          acum.linkedPlatforms.push(platform);
        }

        return acum;
      },
      { linkedPlatforms: [], unlinkedPlatforms: [] }
    );

  // Process rules for each platform.
  linkedPlatforms.forEach((platform) => validatePlatform(platform, post));
  unlinkedPlatforms.forEach((platform) =>
    validatePlatform(platform, { ...post, ...platformFields[platform.TYPE] })
  );

  // validate empty posts
  const isEmpty = !(post.caption || post.url || post.mediaFiles?.length);
  const topicType = post[FIELDS.GMB_TOPIC_TYPE] || null;
  const isGMBAlertPost = topicType === GMB_TOPICS.ALERT.VALUE;

  if (isEmpty && !isGMBAlertPost) {
    const action =
      postState === POST_STATES.SCHEDULED || postState === POST_STATES.QUEUED
        ? "schedule"
        : "save";

    errors[FIELDS.POST_STATE] = {
      errors: [
        `You can't ${action} empty post, at least 1 attachment or caption is required.`,
      ],
    };
  }

  return [errors, warnings];
};
const validateTh = (postState, post, platformFields, platforms) => {
  const errors = {};
  const warnings = {};
  const isThPost = platforms.some((platform) => platform.TYPE === "TH");
  if (!platforms) {
    return [];
  }

  if (!isThPost) {
    return [];
  }

  const fullPost = { ...post, ...(platformFields.TH ?? {}) };

  // validate caption length when you add the URL to the caption.

  let caption = fullPost.caption;
  if (fullPost.redirectUrl) {
    caption = fullPost.caption + " " + fullPost.redirectUrl;
  }
  const result = caption.length;
  if (result > 500) {
    errors[FIELDS.CAPTION] = {
      errors: [
        `Your caption (and URL if included) is over the maximum limit of characters - it is ${result} characters long. We validate the full length including the URL only when you hit save.`,
      ],
    };
  }
  console.log("errors full", errors);
  return [errors, warnings];
};
const validateTw = (postState, post, platformFields, platforms) => {
  const errors = {};
  const warnings = {};
  const isTwPost = platforms.some((platform) => platform.TYPE === "TW");
  if (!platforms) {
    return [];
  }

  if (!isTwPost) {
    return [];
  }

  const fullPost = { ...post, ...(platformFields.TW ?? {}) };

  // validate caption length when you add the URL to the caption for Twitter.
  // This is because Twitter uses a weird 'weighted length' rather than a fixed character length for Tweets
  let caption = fullPost.caption;
  if (fullPost.redirectUrl) {
    caption = fullPost.caption + " " + fullPost.redirectUrl;
  }

  const result = twitter.parseTweet(caption);
  console.log("result", result, result);
  console.log("ParseTweet result", result);
  if (result.weightedLength > 280) {
    errors[FIELDS.CAPTION] = {
      errors: [
        `Your Tweet (and URL if included) is over the maximum limit of 280 characters - it is ${result.weightedLength} characters long. We validate the full Tweet length including the URL only when you hit save.`,
      ],
    };
  } else if (!result.valid) {
    // not sure this should ever be possible but I figure we should error if Twitter's JS says it's not valid
    const errorMessage = `Your Tweet (and URL if included) is over the maximum limit of 280 characters - it is ${result.weightedLength} characters long. We validate the full Tweet length including the URL only when you hit save.`;
    if (result.weightedLength === 0) {
      // Twitter can show an invalid result when there is no caption. However it is possible to post e.g. just an image or video, which will fail this test but still succeed when published.
      // so we ignore the invalid result if the weighted length is 0
    } else {
      errors[FIELDS.CAPTION] = {
        errors: [errorMessage],
      };
    }
  }
  console.log("errors full", errors);
  return [errors, warnings];
};

const validateGmb = (postState, post, platformFields, platforms) => {
  const errors = {};
  const warnings = {};
  let isGMBPost = false;
  if (!platforms) {
    return [];
  }
  platforms.map((platform) => {
    if (platform.TYPE === "GMB") {
      isGMBPost = true;
    }
  });

  if (!isGMBPost) {
    return [];
  }

  const { gmbTopicType, gmbEvent } = {
    ...post,
    ...(platformFields.GMB ?? {}),
  };

  if (!gmbTopicType) {
    errors[FIELDS.GMB_TOPIC_TYPE] = { errors: ["A topic type is required."] };
  }

  if ([GMB_TOPICS.OFFER.VALUE, GMB_TOPICS.EVENT.VALUE].includes(gmbTopicType)) {
    if (!gmbEvent?.title) {
      errors[FIELDS.GMB_TITLE] = {
        errors: ["An event title is required."],
      };
    }
    if (gmbEvent.title && gmbEvent.title.length > 58) {
      errors[FIELDS.GMB_TITLE] = {
        errors: ["Your event title can't be longer than 58 characters."],
      };
    }

    if (!gmbEvent?.startDate) {
      errors[FIELDS.GMB_START_DATE] = {
        errors: ["The event start date is required."],
      };
    }

    if (!gmbEvent || !gmbEvent.endDate) {
      errors[FIELDS.GMB_END_DATE] = {
        errors: ["The event end date is required."],
      };
    }

    if (gmbEvent?.startDate && gmbEvent?.endDate) {
      const startDate = new moment(gmbEvent.startDate);
      const endDate = new moment(gmbEvent.endDate);
      const now = new moment();

      if (endDate < startDate) {
        errors[FIELDS.GMB_END_DATE] = {
          errors: ["End date cannot be before start date."],
        };
      } else if (startDate < now && endDate < now) {
        errors[FIELDS.GMB_START_DATE] = {
          errors: ["Start and end dates are in the past."],
        };
      }
    }
  }

  // Alerts cannot have media
  if (
    gmbTopicType === GMB_TOPICS.ALERT.VALUE &&
    post.type !== POST_TYPES.TEXT
  ) {
    errors[FIELDS.GMB_TOPIC_TYPE] = {
      errors: ["Alert posts cannot have images or videos."],
    };
  }

  if (
    post.gmbCallToAction &&
    post.gmbCallToAction.actionType &&
    _.isEmpty(post.redirectUrl)
  ) {
    // "URL is not needed for CALL actions"
    if (post.gmbCallToAction.actionType !== "CALL") {
      errors[FIELDS.GMB_CTA] = {
        errors: ["A URL is required for GMB posts with a call to action."],
      };
    }
  }

  return [errors, warnings];
};

// Very simple validation at present - just ensure board is selected
const validatePi = (postState, post, platformFields, platforms) => {
  const errors = {};
  const warnings = {};
  let isPIPost = false;
  if (!platforms) {
    return [];
  }
  platforms.map((platform) => {
    if (platform.TYPE === "PI") {
      isPIPost = true;
    }
  });

  if (!isPIPost) {
    return [];
  }

  const { piBoards } = {
    ...post,
    ...(platformFields.PI ?? {}),
  };

  if (
    !piBoards ||
    (piBoards && Array.isArray(piBoards) && piBoards.length < 1)
  ) {
    errors[FIELDS.PI_BOARDS] = {
      errors: ["At least one Pinterest board is required."],
    };
  }

  return [errors, warnings];
};

const validateRule = (field, rule, ruleParams, value, platform) => {
  let errMsg = null;
  switch (rule) {
    case RULES.LENGTH: {
      const [min, max, customMsg] = ruleParams;

      if (value && value.length < min) {
        errMsg =
          customMsg ||
          `${platform.LABEL}: ${field} needs to be at least ${min} characters.`;
        break;
      }

      if (value && value.length > max) {
        errMsg =
          customMsg ||
          `${platform.LABEL}: ${field} - ${max} characters are supported.`;
        break;
      }
      break;
    }
    case RULES.HASHTAG_COUNT: {
      const [hashMin, hashMax] = ruleParams;
      const hashtagCount = countCharacter(value, "#");
      if (hashtagCount < hashMin) {
        errMsg = `${platform.LABEL}: Number of hashtags must not be less than ${hashMin} in both the caption and comment combined.`;
        break;
      }

      if (hashtagCount > hashMax) {
        errMsg = `${platform.LABEL}: Number of hashtags must not be greater than ${hashMax} in both the caption and comment combined.`;
        break;
      }
      break;
    }
    case RULES.MENTION_COUNT: {
      const [hashMin, hashMax] = ruleParams;
      const mentionCount = countCharacter(value, "@");
      if (mentionCount < hashMin) {
        errMsg = `${platform.LABEL}: Number of mentions must not be less than ${hashMin}`;
        break;
      }

      if (mentionCount > hashMax) {
        errMsg = `${platform.LABEL}: Number of mentions must not be greater than ${hashMax}`;
        break;
      }
      break;
    }
    case RULES.SUPPORTED_POST_TYPE: {
      const valueIn = ruleParams.includes(value);
      if (!valueIn) {
        errMsg = capitalize(
          `${platform.LABEL} does not support ${value} posts.`
        );
      }
      break;
    }
    case RULES.REQUIRED:
      if (!value) {
        errMsg = `${field} is required.`;
      }
      break;
  }

  return errMsg;
};

export const getMediaType = (mediaFile: MediaUrlInfo) => {
  if (mediaFile.isVideo || (mediaFile.url && mediaFile.url.includes(".mp4"))) {
    return "video";
  } else if (
    mediaFile.type === "document" ||
    (mediaFile.url && mediaFile.url.includes(".pdf"))
  ) {
    return "document";
  }
  return "image";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getSafeFieldValue(object: any, field: string | symbol | number) {
  if (String(field).includes(".")) {
    return get(object, field);
  }
  return object[field];
}

// given a platform type, return errors
// error wrapper will find the errors for platform, if "LINKED", then just take the first error for a field
// given a field return errors (only ever print out the first error for a field)
