import axios, { AxiosError, AxiosResponse } from "axios";

export class UserFriendlyError extends Error {
  userFriendly = true;

  constructor(message: string) {
    super(message);
  }
}

export function isUserFriendlyError(err: Error) {
  return "userFriendly" in err;
}

export function getUserFriendlyErrorMessage(
  err: AxiosError | Error,
  defaultMessage = "Something went wrong."
): string {
  // the jsonResponse error structure used by BE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errResMessage = (err as any)?.response?.data?.message;
  if (errResMessage) {
    /** same as checking from getErrorMessageFromAxiosResponse function */
    if (
      typeof errResMessage === "object" &&
      typeof Object.values(errResMessage)[0] === "string"
    ) {
      return Object.values(errResMessage)[0] as string;
    }

    return errResMessage;
  } else if (isUserFriendlyError(err)) {
    return err.message;
  } else if (axios.isAxiosError(err)) {
    return getErrorMessageFromAxiosError(err, defaultMessage);
  } else {
    return defaultMessage;
  }
}

function getErrorMessageFromAxiosError(
  err: AxiosError,
  defaultMessage: string
): string {
  if (err.response && isJsonResponse(err.response)) {
    return getErrorMessageFromAxiosResponse(err.response, defaultMessage);
  } else if (err.response && err.response.status === 401) {
    return "There was a permissions error with that request. Please try logging out and back in before trying again, or speak to support if it persists.";
  } else {
    return defaultMessage;
  }
}

function getErrorMessageFromAxiosResponse(
  response: AxiosResponse,
  defaultMessage: string
): string {
  if (typeof response.data === "string") {
    return response.data;
  } else if (typeof response.data?.error === "string") {
    return response.data.error;
  } else if (typeof response.data?.message === "string") {
    return response.data.message;
  } else if (
    typeof response.data?.message === "object" &&
    typeof Object.values(response.data.message)[0] === "string"
  ) {
    return Object.values(response.data.message)[0] as string;
  } else {
    return defaultMessage;
  }
}

function isJsonResponse(response: AxiosResponse): boolean {
  return response.headers["content-type"].indexOf("application/json") != -1;
}
