import { TaggedInFeedRefreshButton } from "components/MediaLibrary/SourceMedia/TaggedInFeed/TaggedInFeedActions";
import { GET_TAGGED_IN_MEDIA_ROUTINE } from "constants/ActionTypes";
import React from "react";
import { useSelector } from "react-redux";
import { getLoading, getRoutinesLoading } from "selectors/commonSelectors";
import {
  getFeedMedia,
  getSelectedIgAccount,
} from "selectors/hashtagFeedSelectors";
import { CollectionFile } from "shared/types/files";
import styled from "styled-components";
import { LoadingCover } from "ui/cover";
import { Select } from "ui/ecosystems/forms";
import { CollectionFilesList } from "../../collection-files-list";
import { PickerProps } from "../media-picker";
import {
  EmptyAlertWrapper,
  Title,
  TitleBar,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
} from "../components/common";
import { feedMediaToCollectionFile } from "../helper";
import { AccountSelector } from "../components/account-selector";
import { PAGES } from "constants/MediaLibrary";
import { LoadMore } from "../components/load-more";
import { getUser } from "selectors/commonSelectors";
import { UpsellWrap, UpsellUGCCTA } from "../creator/creator-picker";

export function TaggedInPicker(props: PickerProps) {
  const selectedIgAccount = useSelector(getSelectedIgAccount);

  const doesSelectedIgAccountHaveCommentPermission = (
    selectedIgAccount?.session?.scopes || []
  ).includes("instagram_manage_comments");

  const loading = useSelector(getLoading);
  const loadingMedia = useSelector((state) =>
    getRoutinesLoading(state, [GET_TAGGED_IN_MEDIA_ROUTINE])
  );
  const feedMedia = useSelector((state) =>
    getFeedMedia(state, PAGES.TAGGED_IN)
  );

  const files: CollectionFile[] = (feedMedia || []).map(
    feedMediaToCollectionFile
  );

  const isLoading = loading || loadingMedia;

  const user = useSelector(getUser);
  const upsell = Boolean(user?.plan?.includes("fundamentals"));

  if (upsell) {
    return (
      <UpsellWrap>
        <strong className="head">
          Tagged in Post is how you find and celebrate the most dedicated
          followers and customers in your audience. Here's how it works:{" "}
        </strong>

        <strong>1. Select an account you manage</strong>

        <p>You can track up to 30 hashtags at a time</p>
        <strong>2. Choose posts to save</strong>
        <p>
          The original creators' info is automatically saved so you can pass on
          the credit
        </p>

        <strong>3. Add it to your Library</strong>
        <strong>
          4. Share it across Instagram, Facebook, Twitter, Pinterest and
          Linkedln
        </strong>
        <UpsellUGCCTA />
      </UpsellWrap>
    );
  }

  return (
    <LoadingCover isLoading={isLoading}>
      <Toolbar>
        <ToolbarLeft>
          <AccountSelector type="tagged-in" />
        </ToolbarLeft>

        <ToolbarRight>
          <Select
            value="recent"
            options={[{ value: "recent", label: "Recent added" }]}
          />
        </ToolbarRight>
      </Toolbar>
      {isLoading ? (
        <EmptyAlertWrapper empty />
      ) : !doesSelectedIgAccountHaveCommentPermission ? (
        <CommnetPermissionAlert />
      ) : selectedIgAccount && feedMedia.length < 1 ? (
        <EmptyAlert />
      ) : (
        <>
          <TitleBar>
            <Title>Tagged in posts</Title>
            <MediaInfo>
              <span>{`Displaying ${feedMedia.length} posts`}</span>
              <TaggedInFeedRefreshButton />
            </MediaInfo>
          </TitleBar>
          <CollectionFilesList
            files={files}
            selectedMediaSet={props.selectedMediaFiles}
            selectedCollection={props.selectedCollection}
            onSelect={props.handleFileAdd}
            onRemove={props.handleFileRemove}
          />
          <LoadMore page={PAGES.TAGGED_IN} />
        </>
      )}
    </LoadingCover>
  );
}

function CommnetPermissionAlert() {
  return (
    <EmptyAlertWrapper>
      <h3>
        Update your Instagram permissions to search and save posts you were
        tagged in.
      </h3>
    </EmptyAlertWrapper>
  );
}

function EmptyAlert() {
  return (
    <EmptyAlertWrapper>
      <h3>We didn’t find any posts you were tagged in.</h3>
      <p>
        When an Instagram public account tags you in a post, it will populate
        here.
      </p>
      <p>
        You can easily use this user generated content in all your posts (just
        make sure you have permission to use it!)
      </p>
    </EmptyAlertWrapper>
  );
}

const MediaInfo = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #c4c4c4;
  }

  .SkedIcon {
    color: #333;
    font-size: 15px;
    margin-left: 10px;
  }
`;
