import { put, takeEvery } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";

import { reloadPosts as reloadPostsAction } from "actions/postsView";
import { closeModal, openModal, registerModal } from "libs/modals";

import { NgPost } from "../format-post";
import { EditPostModal } from "../components/CreatePostModal";
import { Post } from "../types";
import { editPost } from "./model";

const EDIT_POST_MODAL = "EDIT_POST_MODAL";

registerModal(EDIT_POST_MODAL, EditPostModal);

type OpenEditPostModalPayload = {
  callback?(data: FormData): void;
  onCloseCallback?(post: NgPost): void;
  post: NgPost;
  onSubmit?(post: Post): Post;
};

export const openEditPostModal = createAction<OpenEditPostModalPayload>(
  "OPEN_EDIT_POST_MODAL"
);
export const closeEditPostModal = createAction("CLOSE_EDIT_POST_MODAL");

function* editPostModalSaga({
  payload,
}: {
  payload: OpenEditPostModalPayload;
  type: string;
}) {
  const { callback, onSubmit, onCloseCallback, ...props } = payload;
  const { post } = props;

  yield put(
    openModal({
      type: EDIT_POST_MODAL,
      props,
    })
  );
  // @ts-ignore
  const formData = yield editPost(post, onSubmit);
  if (formData && typeof callback === "function") {
    callback(formData);
  } else if (formData) {
    yield put(reloadPostsAction());
  }

  if (!formData && typeof onCloseCallback === "function") {
    onCloseCallback(post);
  }

  yield put(
    closeModal({
      type: EDIT_POST_MODAL,
    })
  );
}

export function* editModalPostSaga(): Generator<unknown, void, unknown> {
  yield takeEvery(openEditPostModal.type, editPostModalSaga);
}
