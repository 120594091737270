import React, { useMemo, useState, useCallback } from "react";
import Select, { components } from "react-select";

import { splitAccounts, filterAccountsByText } from "utils/accounts";
import { getAccountDisplayName } from "entities/accounts";
import { AccountGroup, AccountType } from "shared/types/accounts";
import { OptionBrandedType, OptionType, PickerType } from "./types";
import { SelectOptionLabel } from "./components/SelectOptionLabel";
import { SelectGroupHeading } from "./components/SelectGroupHeading";
import { SelectMultipleOption } from "./components/SelectMultipleOption";
import { addDataTestIdToReactSelectComponent } from "ui/ecosystems/forms/react-select-with-test-id";
import { getStyles } from "./components/account-dropdown-styles";

const SelectOption = (props: any) => {
  return <SelectMultipleOption {...props} />;
};

const getOptionLabel = (option: any) => {
  return getAccountDisplayName(option.account as AccountType);
};

const FormatSelectOptionLabel = (option: any) => (
  <SelectOptionLabel account={option.account} />
);

const customFilter = (option: any, searchText: string): boolean => {
  if (!searchText || !option) return true;

  return filterAccountsByText(searchText)(option.data.account);
};

const GroupHeading = (props: { data: OptionType; selectProps: any }) => {
  return (
    <SelectGroupHeading
      value={props.selectProps?.value}
      handleChange={props.selectProps?.onChange}
      data={props.data}
    />
  );
};

const AccountPickerDropdown = (props: PickerType): JSX.Element => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const {
    accounts,
    closeMenuOnSelect = !props.isMulti,
    selectedAccountIds = [],
    platforms = [],
    isMulti = false,
    selectFirst = false,
    minHeight = "",
    onChange = null,
    className = "",
    disabled,
    isSearchable, // used for angularjs option
  } = props;

  const styles = getStyles({ minHeight, isSearchable });

  const sortedAccounts = useMemo((): AccountType[] => {
    // Only display accounts of the selected platform type.

    return platforms.length
      ? accounts.filter((a) => {
          const platformType = a.platformType ? a.platformType : "IG";
          return platforms.includes(platformType);
        })
      : accounts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts.map((a) => a._id).join(","), platforms.join(",")]);

  const { groupedAccounts, otherAccounts } = useMemo(
    () => splitAccounts(sortedAccounts),
    [sortedAccounts]
  );

  const [options, value] = useMemo(() => {
    const newOptions: (OptionType | OptionBrandedType)[] = [];

    const newDefaultOptions: OptionBrandedType[] = [];
    groupedAccounts.forEach((group: AccountGroup) => {
      const brandAccounts = group.accounts;
      const brandOptions: OptionBrandedType[] = [];
      brandAccounts.map((account) => {
        const option = {
          account: account,
          value: account._id,
          inputValue: account.loginAlias ? account.loginAlias : account.login,
          isSearchable: true,
        };

        if (
          newDefaultOptions.length === 0 &&
          selectFirst &&
          selectedAccountIds.length === 0
        ) {
          newDefaultOptions.push(option);
        }

        if (selectedAccountIds.includes(account._id)) {
          newDefaultOptions.push(option);
        }

        brandOptions.push(option);
      });

      newOptions.push({
        label: group.title,
        options: brandOptions,
        value: group.title,
      });
    });

    otherAccounts.forEach((account) => {
      const option = {
        account: account,
        value: account._id,
        inputValue: account.loginAlias ? account.loginAlias : account.login,
        isSearchable: true,
      };
      newOptions.push(option);

      if (
        newDefaultOptions.length === 0 &&
        selectFirst &&
        selectedAccountIds.length === 0
      ) {
        newDefaultOptions.push(option);
      }

      if (selectedAccountIds.includes(account._id)) {
        newDefaultOptions.push(option);
      }
    });

    return [newOptions, newDefaultOptions];
  }, [groupedAccounts, otherAccounts, selectFirst, selectedAccountIds]);

  const handleChange = useCallback(
    (
      selected: any //AccountsPropType | AccountsPropType[] | AccountType[]
    ) => {
      const clear = selected === null || selected?.length === 0;
      // clear search for non multiselect
      if (!isMulti) {
        setSearchInputValue("");
      }

      if (onChange) {
        if (clear) {
          return onChange([]);
        }

        if (isMulti) {
          const selectedAccounts = selected.map(
            ({ account }: { account: AccountType }) => account
          );
          return onChange(selectedAccounts);
        }
        // single account dropdown
        if (selected && !selected.length && selected.account) {
          onChange(selected.account);
        }
      }
    },
    [onChange, isMulti]
  );

  const customSelectComponents = useMemo(() => {
    const defaultComponents = isMulti
      ? {
          GroupHeading,
          Option: SelectOption,
        }
      : {};

    if (process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "test") {
      return {
        ClearIndicator: addDataTestIdToReactSelectComponent(
          components.ClearIndicator,
          "account-picker-clear-indicator"
        ),
        DropdownIndicator: addDataTestIdToReactSelectComponent(
          components.DropdownIndicator,
          "account-dropdown-indicator"
        ),
        SelectContainer: addDataTestIdToReactSelectComponent(
          components.SelectContainer,
          "accountPickerSelectContainer"
        ),
        Menu: addDataTestIdToReactSelectComponent(
          components.Menu,
          "accountPickerSelectMenu"
        ),
        ...defaultComponents,
      };
    }

    return defaultComponents;
  }, [isMulti]);

  const filterOption = useMemo(() => {
    return isMulti || isSearchable ? customFilter : undefined;
  }, [isMulti, isSearchable]);

  const placeholder = useMemo(() => {
    return `Select account${isMulti ? "s" : ""}…`;
  }, [isMulti]);

  const handleInputChange = (value: string, action: { action: string }) => {
    if (action.action === "input-change") {
      setSearchInputValue(value);
    }
  };

  return (
    <Select
      data-testid="selectAccounts"
      components={customSelectComponents}
      isDisabled={disabled}
      hideSelectedOptions={!isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable={isMulti || isSearchable}
      options={options}
      styles={styles}
      value={value}
      inputValue={searchInputValue}
      className={className}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={FormatSelectOptionLabel}
      filterOption={filterOption}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

export default AccountPickerDropdown;
