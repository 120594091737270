import { registerModal } from "libs/modals";
import { CollaboratorModal } from "./collaborator-modal";

export {
  CollaboratorModal,
  SelectedCollaborator,
  SelectedCollaboratorTag,
} from "./collaborator-modal";

export const ADD_COLLABORATOR_MODAL = "ADD_COLLABORATOR_MODAL";
registerModal(ADD_COLLABORATOR_MODAL, CollaboratorModal);
