import React from "react";

/** @deprecated */
const DeprecatedLink = ({
  children,
  className = "",
  type = "primary",
  ...other
}) => {
  const linkTypes = {
    primary: "purple",
    info: "blue",
    success: "green",
    warning: "orange",
    danger: "red",
    neutral: "gray",
  };

  const typeColor = linkTypes[type];

  const classes = `tw-text-${typeColor}-500 hover:tw-text-${typeColor}-600 tw-mr-4`;

  return (
    <a className={`${className} ${classes}`} {...other}>
      {children}
    </a>
  );
};

export default DeprecatedLink;
