import React from "react";
import { PostAccount } from "components/Posts/Post/index";
import {
  Caption,
  DetailsTemplate,
  Tag,
  AltTextTags,
} from "./post-details-template";

export function FacebookDetails({ post, account, page }) {
  return (
    <DetailsTemplate
      page={page}
      post={post}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={() => (
        <>
          <Caption post={post} />
          <AltTextTags post={post} />
        </>
      )}
      renderTags={() =>
        post.location?.name && <Tag label="Location">{post.location.name}</Tag>
      }
    />
  );
}
