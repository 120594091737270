import React, { useMemo } from "react";
import { PostFieldLabel, PostFieldCounter } from "components/Posts/PostForm";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { countCharacter } from "utils/strings";
import { FIELDS } from "constants/PostFormFields";
import { SkedCaption, useSkedCaptionAutocomplete } from "ui/SkedCaption";
import { postPlatformsConfig, RULES } from "../postPlatforms.config";
import { useDebouncedCallback } from "use-debounce";

const getMaxMentionCount = (platformType) =>
  postPlatformsConfig?.[platformType]?.validation?.error?.[FIELDS.CAPTION]?.[
    RULES.MENTION_COUNT
  ]?.[1];

export default function CaptionField(props) {
  // eslint-disable-next-line react/prop-types
  const {
    platforms = [],
    selectedPlatform,
    value,
    className,
    label,
    disabled,
    onChange,
    maxLength,
    hashtagCount,
    errors,
    warnings,
    hasMoreThanOnelinkedInAccount,
    selectedLinkedInAccountType,
    disableMention,
    selectedLinkedUrn,
  } = props;
  const mentionCount = countCharacter(value, "@");
  const debouncedOnChange = useDebouncedCallback((newValue) => {
    if (value !== newValue) {
      onChange(newValue);
    }
  }, 500);

  const maxMentionCount = useMemo(() => {
    const isMultiplyPlatform = platforms.length > 1;

    return isMultiplyPlatform
      ? platforms.reduce((minCount, { TYPE }) => {
          const max = getMaxMentionCount(TYPE);

          if (minCount === undefined || minCount > max) {
            return max;
          }

          return minCount;
        }, undefined)
      : getMaxMentionCount(selectedPlatform);
  }, [platforms, selectedPlatform]);

  const skedCaptionAutocomplete = useSkedCaptionAutocomplete(selectedPlatform);

  return (
    <div data-testid="postCaption" className={className}>
      <div className="tw-mb-2">
        <PostFieldLabel className="tw-flex tw-items-center">
          {label}
        </PostFieldLabel>
      </div>

      <SkedCaption
        initialValue={value}
        disabled={disabled}
        onChange={debouncedOnChange}
        suggestions={true}
        upcomingEditPopup={true}
        autocomplete={skedCaptionAutocomplete}
        selectedPlatform={selectedPlatform}
        hasMoreThanOnelinkedInAccount={hasMoreThanOnelinkedInAccount}
        selectedLinkedInAccountType={selectedLinkedInAccountType}
        disableMention={disableMention}
        selectedLinkedUrn={selectedLinkedUrn}
      />
      <div className="tw-flex">
        <PostFieldCounter
          count={value ? value.length : 0}
          max={maxLength}
          className="tw-flex-grow tw-text-right tw-mt-2 tw-mr-8"
        >
          Characters
        </PostFieldCounter>
        <PostFieldCounter
          count={hashtagCount}
          max={30}
          className="tw-text-right tw-mt-2"
        >
          Hashtags
        </PostFieldCounter>
        {!disableMention && mentionCount > 0 && (
          <PostFieldCounter
            count={mentionCount}
            max={maxMentionCount ? maxMentionCount : 0}
            className="tw-text-right tw-mt-2 tw-ml-8"
            selectedPlatform={selectedPlatform}
          >
            Mentions
          </PostFieldCounter>
        )}
      </div>
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.CAPTION]} // todo: move it
        errors={errors}
        warnings={warnings}
      />
    </div>
  );
}
