import React from "react";
import styled from "styled-components";
import { PostAccount } from "components/Posts/Post/index";
import { Caption, DetailsTemplate, PostDetail } from "./post-details-template";
import type { AccountType } from "shared/types/accounts";
import { LinkedInPost, Post } from "features/post/types";

export const getLinkedinTargetEntriesLabels = (
  liTargetEntities: LinkedInPost["liTargetEntities"]
) => {
  return Object.entries(liTargetEntities || {})
    .filter(([key]) => key !== "interfaceLocales")
    .reduce((acc, [, audienceValue]) => {
      return [...acc, ...audienceValue.map(({ label }) => label)];
    }, [] as string[]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LinkedinTags = ({ post }: { post: Post & LinkedInPost }) => {
  const { liTargetEntities } = post;

  if (!liTargetEntities) return null;

  const audiencesLabelArray = getLinkedinTargetEntriesLabels(liTargetEntities);
  return (
    <div>
      <LiTargetEntriesLabel>Target Audience:</LiTargetEntriesLabel>
      <LiTargetEntriesUl>
        {audiencesLabelArray.map((label) => (
          <LiTargetEntriesLi key={label}>{label}</LiTargetEntriesLi>
        ))}
      </LiTargetEntriesUl>
    </div>
  );
};

export function LinkedInDetails({
  post,
  account,
  page,
}: {
  post: Post;
  account: AccountType;
  page: string;
}) {
  const renderDetailsList = () => {
    return (
      <>
        <Caption post={post} />
        {post.firstcomment && (
          <PostDetail label="First comment">{post.firstcomment}</PostDetail>
        )}
        <LinkedinTags post={post} />
      </>
    );
  };

  return (
    <DetailsTemplate
      page={page}
      post={post}
      renderAccount={() => account && <PostAccount account={account} />}
      renderDetailsList={renderDetailsList}
    />
  );
}

const LiTargetEntriesLabel = styled.div`
  font-weight: bold;
`;

const LiTargetEntriesUl = styled.ul`
  padding-left: 20px;
`;

const LiTargetEntriesLi = styled.li`
  list-style-type: disc;
  font-size: 14px;
`;
