import React, { ReactNode, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { changeCollabState } from "actions/collaboration";
import {
  getPage,
  getRoutinesLoading,
  getUser,
  getUserPostStatuses,
} from "selectors/commonSelectors";
import { Icon, Tooltip } from "@dialogueconsulting/sked-ui";

import { PAGES } from "constants/Pages";

import {
  BULK_CHANGE_COLLAB_STATE_ROUTINE,
  CHANGE_COLLAB_STATE_ROUTINE,
} from "constants/ActionTypes";
import { CollaborationPostStatus } from "features/user";
import { getFilters } from "selectors/postsPageSelectors";
import { filterCollabStatus } from "actions/postsView";

import { Post } from "features/post";
import { FormSize, FormThemeProvider, Select } from "ui/ecosystems/forms";
import { TooltipWrapper } from "ui/TooltipWrapper";

import { OptionStatus } from "ui/ecosystems/dropdown";
import { isUpsellRequiredForApproval } from "utils/user";

interface ItemProps {
  option: {
    color?: string;
    numberSelected?: number;
    label?: string;
    disabledTooltip?: string;
  };
}

interface Option {
  label: string;
  value: string;
  color?: string;
  numberSelected?: number;
  isDisabled?: boolean;
}
interface Props {
  post?: Post;
  disabled?: boolean;
  numberSelected?: number;
  placeholder?: string;
  selectDefault?: boolean;
  isClearable?: boolean;
  isAbleToUpdateState?: boolean;
  originalPostState?: string;
  postType?: string;

  onChange(value: string): void;

  onMenuOpen?: () => void;
  selectedStatusKey?: string;
  additionalOptions?: Option[];
  size?: FormSize;
}

export const StatusChange = ({
  post,
  onChange,
  disabled = false,
  numberSelected = 0,
  placeholder = "",
  selectDefault = true,
  isClearable = false,
  postType,
  onMenuOpen,
  selectedStatusKey,
  additionalOptions,
  size = FormSize.default,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const page = useSelector(getPage);
  const isLoadingRoutines = useSelector((state) =>
    getRoutinesLoading(state, [
      BULK_CHANGE_COLLAB_STATE_ROUTINE,
      CHANGE_COLLAB_STATE_ROUTINE,
    ])
  );

  const { postStatusKey } = useSelector(getFilters);
  const userCollabSettings: [CollaborationPostStatus] =
    useSelector(getUserPostStatuses);

  const options = useMemo(() => {
    const userOptions = userCollabSettings.map((setting) => {
      const isDisabled =
        user.role === "collaborator" &&
        !user.preferences?.canChangeStatusTo?.includes(setting.key);

      return {
        label: setting.label,
        value: setting.key,
        color: setting.color,
        numberSelected,
        isDisabled: isDisabled,
        disabledTooltip: isDisabled
          ? "You are not allowed to change posts to this status."
          : undefined,
      };
    });

    return additionalOptions
      ? [...userOptions, ...additionalOptions]
      : userOptions;
  }, [
    numberSelected,
    user.preferences?.canChangeStatusTo,
    user.role,
    userCollabSettings,
  ]);

  const upsell = isUpsellRequiredForApproval(user);

  const defaultSetting = upsell
    ? userCollabSettings.find((setting) => {
        return setting.label === "Approved";
      }) ||
      userCollabSettings.find((setting) => {
        return setting.isPublishingAllowed;
      })
    : userCollabSettings.find((setting) => {
        return setting.isDefault;
      });

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState(
    selectDefault
      ? selectedStatusKey || post?.postStatusKey || defaultSetting?.key
      : null
  );

  const onStatusOnChange = (statusId: string) => {
    if (
      (page === PAGES.DRAFT || page === PAGES.UPCOMING) &&
      post?._id &&
      !post?.newPost
    ) {
      dispatch(
        filterCollabStatus(postStatusKey?.length ? [...postStatusKey] : [])
      );
    }

    setCurrentSelectedStatus(statusId);
    onChange && onChange(statusId);
  };

  const onStatusChange = async (statusId: string) => {
    if (statusId === post?.postStatusKey) {
      return;
    }
    let postStatusType = postType;

    // only perform these actions if page and post are provided
    // and post has other object other than postStatusKey
    if (!post || post?.newPost) {
      onStatusOnChange(statusId);
    } else if (post) {
      // if no page is passed in use whether the post has a date prop of some kind to determine status type
      const { nextRunAt, when, scheduledFor, postType } = post;
      const possibleScheduledPost = nextRunAt || when || scheduledFor;
      const guessedPostType = possibleScheduledPost ? "schedule" : "draft";
      postStatusType = postStatusType || postType || guessedPostType;

      // do dispatch first before changing the dropdown in case it fails
      await dispatch(
        changeCollabState([post._id], postStatusType, statusId, () => {
          onStatusOnChange(statusId);
        })
      );
    }
  };

  const renderLabel = (option: {
    value: unknown;
    label?: ReactNode;
  }): JSX.Element => {
    // @ts-ignore
    return <StatusLabel option={option} />;
  };

  const renderOption = (option: {
    value: unknown;
    label?: ReactNode;
  }): JSX.Element => {
    // @ts-ignore
    return <StatusDropdownOption option={option} />;
  };

  return (
    <FormThemeProvider size={FormSize.large}>
      <StatusChangeSelect
        isReadonly={upsell}
        size={size}
        isSearchable={false}
        placeholder={placeholder || "Select state"}
        value={currentSelectedStatus}
        // @ts-ignore
        options={options}
        disabled={isLoadingRoutines || disabled}
        renderLabel={renderLabel}
        renderOption={renderOption}
        onChange={onStatusChange}
        noOptionsMessage={"No other statuses available"}
        menuPlacement={"auto"}
        isClearable={isClearable}
        onMenuOpen={onMenuOpen}
      />
    </FormThemeProvider>
  );
};

const StatusChangeSelect = styled(Select)`
  min-width: 250px;
  line-height: 38px;
`;

export default StatusChange;

const StatusDropdownOption = ({
  option: { color, numberSelected, label, disabledTooltip },
}: ItemProps) => {
  return (
    <TooltipWrapper tooltip={disabledTooltip}>
      <OptionWrapper>
        <OptionStatus color={color} label={label} />
        {numberSelected ? <div>({numberSelected})</div> : null}
      </OptionWrapper>
    </TooltipWrapper>
  );
};

const StatusLabel = ({
  option: { color, label, numberSelected },
}: ItemProps) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const upsell = isUpsellRequiredForApproval(user);

  return (
    <StatusLabelRoot>
      <OptionStatus color={color} label={label} />
      {numberSelected ? <div>({numberSelected})</div> : null}
      {upsell && <Icon name="upsell-rocket-blue-bg" size={20} />}
    </StatusLabelRoot>
  );
};

const OptionWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusLabelRoot = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 14px;
`;
