import React from "react";
import { renderContentProps } from "./types";
import { NormalMessage } from "./normal-message";
import { MessageAttachments } from "../../components/message-attachments";

export const IgMessage = (props: renderContentProps): JSX.Element => {
  return (
    <>
      <MessageAttachments {...props} />
      <NormalMessage {...props} />
    </>
  );
};
