import createStorage, { proxyStorage } from "./storage";
import {
  LocalStorageNullableReturnValue,
  LocalStorageReturnValue,
} from "@rehooks/local-storage/src/use-localstorage";
import { getNamespacedKey } from "libs/get-namespaced-key";
import { MbscEventcalendarView } from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import { InboxLocalStorageKey } from "types/conversationInbox";
import _ from "lodash";

let localStorageWrapper = createStorage(window.localStorage);

export const setLocalStorage = (storage: proxyStorage): void => {
  localStorageWrapper = createStorage(storage);
};

// GETS
export const getLocalStorageSkedApiToken = (): string | null =>
  localStorageWrapper.syncGetItem("skedApiToken");

export const getLocalStorageSelectedAccounts = (): string | null =>
  localStorageWrapper.syncGetItem(InboxLocalStorageKey.selectedAccounts);

export const getLocalStoragePostStatusKey = (): string | null =>
  localStorageWrapper.syncGetItem("postStatusKey");

export const getLocalStorageSelectedCalendarsIds = (): string | null =>
  localStorageWrapper.syncGetItem("$selectedCalendarsIds");

export const getLocalStorageLibraryFilter = (): string | null =>
  localStorageWrapper.syncGetItem("libraryFilter");

export const getLocalStorageSelectedPostContentTypes = (): string | null =>
  localStorageWrapper.syncGetItem("selectedPostContentTypes");

export const getLocalStoragePlannerSelectedAccount = (): string | null =>
  localStorageWrapper.syncGetItem("plannerSelectedAccount");

export const getLocalStorageIsConfirmDraftDelete = (): string | null =>
  localStorageWrapper.syncGetItem("isConfirmDraftDelete");

export const getLocalStorageIsConfirmPostCancel = (): string | null =>
  localStorageWrapper.syncGetItem("isConfirmPostCancel");

export const getLocalStorageIsConfirmPostDelete = (): string | null =>
  localStorageWrapper.syncGetItem("isConfirmPostDelete");

export const getLocalStorageMentionAutocomplete = (): string | null =>
  localStorageWrapper.syncGetItem("mentionAutocomplete");

export const getLocalStorageHomeSelectedGroupName = (): string | null =>
  localStorageWrapper.syncGetItem("homeSelectedGroupName");

export const getLocalStorageAddAccountToGroup = (): string | null =>
  localStorageWrapper.syncGetItem("addAccountToGroup");

export const getLocalStorageLiAudiences = (): string | null =>
  localStorageWrapper.syncGetItem("LiAudiences");

export const getLocalStorageMentionAutocompleteLi = (): [] | null => {
  const allAutocomplete = getLocalStorageMentionAutocomplete();

  if (!allAutocomplete) return null;

  return JSON.parse(allAutocomplete)["Li"] || null;
};

// SETS
export const setLocalStorageSelectedAccounts = (value: string): void =>
  localStorageWrapper.setItem(InboxLocalStorageKey.selectedAccounts, value);

export const setLocalStorageSkedApiToken = (value: string): void =>
  localStorageWrapper.setItem("skedApiToken", value);

export const setLocalStoragePostStatusKey = (value: string): void =>
  localStorageWrapper.setItem("postStatusKey", value);

export const setLocalStorageLibraryFilter = (value: string): void =>
  localStorageWrapper.setItem("libraryFilter", value);

export const setLocalStorageSelectedCalendarsIds = (value: string): void =>
  localStorageWrapper.setItem("$selectedCalendarsIds", value);

export const setLocalStoragePlannerSelectedAccount = (value: string): void =>
  localStorageWrapper.setItem("plannerSelectedAccount", value);

export const setLocalStorageIsConfirmDraftDelete = (value: string): void =>
  localStorageWrapper.setItem("isConfirmDraftDelete", value);

export const setLocalStorageIsConfirmPostCancel = (value: string): void =>
  localStorageWrapper.setItem("isConfirmPostCancel", value);

export const setLocalStorageIsConfirmPostDelete = (value: string): void =>
  localStorageWrapper.setItem("isConfirmPostDelete", value);

export const setLocalStorageMentionAutocomplete = (value: string): void =>
  localStorageWrapper.setItem("mentionAutocomplete", value);

export const setLocalStorageHomeSelectedGroupName = (value: string): void =>
  localStorageWrapper.setItem("homeSelectedGroupName", value);

export const setLocalStorageAddAccountToGroup = (value: string): void =>
  localStorageWrapper.setItem("addAccountToGroup", value);

export const setLocalStorageByKey = ({
  storageDataString,
  newValue,
  key,
  uniqKey,
}: {
  storageDataString: string | null;
  newValue: Record<string, unknown>;
  key?: string;
  uniqKey: string;
}): string => {
  const defaultStorageData = key ? {} : [];
  let storageData = storageDataString
    ? JSON.parse(storageDataString)
    : defaultStorageData;
  const storageDataByKey = key ? storageData[key] || [] : storageData;

  if (key) {
    storageData[key] = _.uniqBy([...storageDataByKey, newValue], uniqKey);
  } else {
    storageData = _.uniqBy([...storageDataByKey, newValue], uniqKey);
  }

  return JSON.stringify(storageData);
};

export const setLocalStorageMentionAutocompleteLi = (
  mentionAutocomplete: Record<string, unknown>
): void => {
  const storageDataString = getLocalStorageMentionAutocomplete();
  const newData = setLocalStorageByKey({
    storageDataString,
    newValue: mentionAutocomplete,
    key: "Li",
    uniqKey: "urn",
  });
  setLocalStorageMentionAutocomplete(newData);
};

export const setLocalStorageLiAudiences = (
  value: Record<string, unknown>
): void => {
  const storageDataString = getLocalStorageLiAudiences();
  const newData = setLocalStorageByKey({
    storageDataString,
    newValue: value,
    uniqKey: "id",
  });
  localStorageWrapper.setItem("LiAudiences", newData);
};

// HOOKS
export const useStorageStoriesShown = (defaultValue: boolean): BooleanStorage =>
  localStorageWrapper.useStorage(
    getNamespacedKey("stories-shown"),
    defaultValue
  );

export const useStorageSelectedPostContentTypes = (
  defaultValue: any[] | null
): LocalStorageNullableReturnValue<any[] | null> =>
  localStorageWrapper.useStorage("selectedPostContentTypes", defaultValue);

export const useStorageDeletedRecentUser = (
  defaultValue: string[] | null
): LocalStorageNullableReturnValue<string[] | null> =>
  localStorageWrapper.useStorage("deletedRecentUser", defaultValue);

export const useStoragePostStatusKey = (
  defaultValue: string[]
): LocalStorageReturnValue<string[]> =>
  localStorageWrapper.useStorage("postStatusKey", defaultValue);

export enum AccountFilterEnum {
  accounts = "accounts",
  events = "events",
}

export const useStorageAccountFilter = (
  defaultValue: AccountFilterEnum
): LocalStorageReturnValue<AccountFilterEnum> =>
  localStorageWrapper.useStorage("accountFilter", defaultValue);

type BooleanStorage = LocalStorageReturnValue<boolean>;

export const useStorageIsFiltersOpen = (
  defaultValue: boolean
): BooleanStorage =>
  localStorageWrapper.useStorage("isFiltersOpen", defaultValue);

export const useStorageIsDraftsOpen = (defaultValue: boolean): BooleanStorage =>
  localStorageWrapper.useStorage("isDraftsOpen", defaultValue);

export const useStorageIsWorkHours = (defaultValue: boolean): BooleanStorage =>
  localStorageWrapper.useStorage("isWorkHours", defaultValue);

export const useStorageCalendarView = (
  defaultValue: MbscEventcalendarView
): [
  MbscEventcalendarView,
  (newValue: MbscEventcalendarView | null) => void,
  () => void
] => localStorageWrapper.useStorage("calendarView", defaultValue);
