import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPagination } from "actions/pagination";
import PropTypes from "prop-types";
import PageSizes from "./PageSizes";
import PageSelector from "./PageSelector";
import "./Pagination.less";

const defaultPageSizes = [6, 12, 24, 36, 51, 102];

const Pagination = ({
  className = "",
  numItems,
  currentPage = 1,
  defaultPageSize,
  pageSize,
  pageSizeOptions = defaultPageSizes,
  onChange,
}) => {
  const dispatch = useDispatch();

  let _currentPage = currentPage ? currentPage : 1;
  let _pageSize = pageSize ? pageSize : defaultPageSize;
  if (!_pageSize) {
    _pageSize = pageSizeOptions[0];
  }

  let numPages = Math.ceil(numItems / _pageSize);

  if (_currentPage > numPages) {
    _currentPage = 1;
  }

  const onPageChange = (newPage) => {
    onChange(newPage, _pageSize);
  };

  const onPageSizeChange = (newPageSize) => {
    onChange(1, newPageSize);
  };

  useEffect(() => {
    dispatch(setPagination(numPages, _currentPage, _pageSize));
  }, [numItems]);

  return (
    <div className={`${className} SkedPagination`}>
      <nav className="activity-pagination">
        <PageSelector
          numPages={numPages}
          currentPage={_currentPage}
          onChange={onPageChange}
        />
        {pageSizeOptions !== null && (
          <PageSizes
            pageSize={_pageSize}
            pageSizeOptions={pageSizeOptions}
            onChange={onPageSizeChange}
          />
        )}
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  numItems: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  onChange: PropTypes.func,
};

export default Pagination;
