import React from "react";
import { SkedIcon, TooltipWrapper } from "ui";
import _ from "lodash";

const PostType = ({ type, size, color, className, isInstagram }) => {
  let displayType = _.capitalize(type);
  if (type === "multiMedia") {
    displayType = "Carousel";
  }

  let typeIcon = null;
  let iconSize = size || "20";
  switch (type) {
    case "text":
      typeIcon = "icon-post-text";
      break;
    case "image":
    case "photo":
      typeIcon = "icon-post-image";
      break;
    case "video":
    case "reel":
      // now show reel not video icon because no more videos
      typeIcon = isInstagram ? "icon-post-reel" : "icon-post-video";
      displayType = isInstagram ? "Video/Reel" : "Video";
      break;
    case "multiMedia":
      typeIcon = "icon-post-carousel";
      break;
    case "igtv":
      typeIcon = "icon-post-igtv";
      displayType = "IGTV";
      break;
    case "document":
      typeIcon = "icon-post-document";
      displayType = "Document/PDF";
      break;
    case "story":
      typeIcon = "icon-post-story";
      break;
  }

  if (typeIcon === null) {
    <div className="tw-flex tw-justify-center tw-items-center tw-border tw-border-gray-500 tw-border-solid tw-rounded-lg tw-font-bold tw-p-2 tw-text-gray-400 tw-min-w-50px tw-cursor-default">
      {displayType}
    </div>;
  }

  return (
    <TooltipWrapper placement="top" tooltip={displayType}>
      <div className={`tw-flex tw-items-center tw-text-gray-500 ${className}`}>
        <SkedIcon icon={typeIcon} size={iconSize} color={color} />
      </div>
    </TooltipWrapper>
  );
};

export default PostType;
