import _ from "lodash";
import { getEntities, getEntitiesByPage } from "./selectorUtils";
import { getAccount } from "selectors/skedCoreSelectors";

const FEED_MEDIA_ENTITY = "feedMedia";
const FEED_RECENT_HASHTAGS = "recentHashtags";
const COLLECTION_ENTITY = "collections";

// Feed Media
export const getFeedMedia = (state, page) =>
  getEntitiesByPage(state, FEED_MEDIA_ENTITY, page);
export const getFeedMediaCount = (state, page) =>
  state.entities[FEED_MEDIA_ENTITY][page].allIds.length;

export const getSelectedFeedIds = (state) =>
  state.entities[FEED_MEDIA_ENTITY].selectedItems.map((item) => item.id);
export const getFeedMediaCursor = (state, page) =>
  state.entities[FEED_MEDIA_ENTITY][page].cursor;

export const getFeedCreatorUsername = (state) =>
  state.entities[FEED_MEDIA_ENTITY].creatorUsername;

export const getSelectedFeedItems = (state) =>
  state.entities[FEED_MEDIA_ENTITY].selectedItems;

export const getRecentSearchCreators = (state) =>
  state.entities[FEED_MEDIA_ENTITY].recentSearchCreators;

export const getSelectedCreator = (state) =>
  state.entities[FEED_MEDIA_ENTITY].selectedCreator;

export const getSelectedHashtagId = (state) =>
  state.mediaLibraryView.selectedHashtag;

export const getSelectedIgAccountId = (state) =>
  state.mediaLibraryView.selectedIgAccount;

export const getInvalidIgAccount = (state) =>
  state.mediaLibraryView.invalidIgAccount;

export const getHashtags = (state) =>
  _.sortBy(getEntities(state, FEED_RECENT_HASHTAGS), ["name"]);

export const getActiveHashtags = (state) => {
  return _.filter(getHashtags(state), ["expired", false]);
};

export const getExpiredHashtags = (state) => {
  return _.filter(getHashtags(state), ["expired", true]);
};

export const getHashtag = (state, hashtag) => {
  const hashtags = getHashtags(state);
  const foundHashtag = _.first(_.filter(hashtags, { name: hashtag }));
  return foundHashtag ? foundHashtag : null;
};

export const getSelectedHashtagFeedCollection = (state) => {
  const id = state.mediaLibraryView.selectedHashtagFeedCollection;
  if (!id) {
    return null;
  }
  return state.entities[COLLECTION_ENTITY].byId[id];
};

export const getSelectedIgAccount = (state) => {
  const selectedId = state.mediaLibraryView.selectedIgAccount;
  if (selectedId === null) {
    return null;
  }

  return getAccount(state, selectedId);
};

export const getSelectedHashtag = (state) => {
  const hashtagId = state.mediaLibraryView.selectedHashtag;
  const hashtag = state.entities.recentHashtags.byId[hashtagId];
  return hashtag ? hashtag : null;
};

export const getAllMediaLoaded = (state, page) =>
  state.entities[FEED_MEDIA_ENTITY][page].allLoaded;

export const getHashtagFeedView = (state) =>
  state.mediaLibraryView.hashtagFeedView;
