import React, { useState, useMemo, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { SkedIcon } from "ui";
import { useSelector, useDispatch } from "react-redux";
import { showConfirmModal } from "actions/modal";
import _ from "lodash";
import "./SkedDataTable.less";

const initialState = {
  data: [],
  editRow: null,
  editCopy: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "editRow":
      return {
        ...state,
        editRow: action.payload.row._id,
        editCopy: _.cloneDeep(action.payload.row),
      };
    case "addRow":
      return { ...state, editRow: "NEW", editCopy: { _id: "NEW" } };
    case "saveRow":
      const index = _.findIndex(state.data, ["_id", action.payload.row._id]);
      let dataCopy = _.cloneDeep(state.data);
      dataCopy[index] = action.payload.row;

      return { editRow: null, editCopy: {}, data: dataCopy };
    case "cancelEditing":
      return { ...state, editRow: null, editCopy: {} };
    case "updateField":
      return {
        ...state,
        editCopy: {
          ...state.editCopy,
          [action.payload.field]: action.payload.value,
        },
      };
    case "refreshData":
      return { ...state, data: action.payload.data };
    default:
      return state;
  }
};

export const useSkedDataTable = () => {
  return useReducer(reducer, initialState);
};

const SkedDataTable = ({ headerConfig, bodyConfig, data, state, dispatch }) => {
  // Set initial data
  useEffect(() => {
    dispatch({ type: "refreshData", payload: { data } });
  }, []);

  // Update reducer if main redux data changes
  useEffect(() => {
    dispatch({ type: "refreshData", payload: { data } });
  }, [data]);

  return (
    <div
      className="tw-flex tw-flex-col SkedDataTable"
      data-testid="externalCollabThemesTable"
    >
      <div className="tw-my-2 tw-py-2 tw-sm:-mx-6 tw-sm:px-6 tw-lg:-mx-8 tw-lg:px-8 tw-overflow-x-visible">
        <div className="tw-align-middle tw-inline-block tw-min-w-full tw-sm:rounded-lg">
          <table className="tw-min-w-full">
            <TableHead headerConfig={headerConfig} />
            <TableBody
              bodyConfig={bodyConfig}
              state={state}
              dispatch={dispatch}
            />
          </table>
        </div>
      </div>
    </div>
  );
};

const TableHead = ({ headerConfig }) => {
  return (
    <thead>
      <tr>
        {headerConfig.map((item) => {
          return (
            <th
              key={`th-${item.label}`}
              className={`tw-pr-6 tw-pt-6 tw-pb-2 tw-border-0 tw-border-b-2 tw-border-gray-600 tw-border-solid tw-text-left tw-text-xl tw-leading-4 tw-font-medium tw-uppercase tw-tracking-wider`}
            >
              <div
                className={`tw-flex tw-items-center ${
                  item.className ? item.className : ""
                }`}
              >
                {item.label && <div className="tw-mr-2">{item.label}</div>}
                {item.tooltip && (
                  <SkedIcon
                    className="tw-ml-2 tw-inline-block"
                    icon="icon-help"
                    size="12"
                    tooltip={item.tooltip}
                    placement="top"
                  />
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const TableBody = ({ bodyConfig, state, dispatch }) => {
  return (
    <tbody className="">
      {state.data.map((row, index) => {
        return (
          <tr key={`row-${row._id ? row._id : index}`}>
            {bodyConfig.map((tdConfig, tdIndex) => {
              return (
                <TableTd
                  key={`row-td-${tdIndex}`}
                  config={tdConfig}
                  row={row}
                  state={state}
                  dispatch={dispatch}
                />
              );
            })}
          </tr>
        );
      })}
      {state.editRow === "NEW" && (
        <tr key={`row-NEW`}>
          {bodyConfig.map((tdConfig, tdIndex) => {
            return (
              <TableTd
                key={`row-td-${tdIndex}`}
                config={tdConfig}
                row={state.editCopy}
                state={state}
                dispatch={dispatch}
              />
            );
          })}
        </tr>
      )}
    </tbody>
  );
};

const TableTd = ({ config, row, state, dispatch }) => {
  return (
    <td
      className={`tw-py-6 tw-border-0 tw-border-b tw-border-solid tw-border-gray-100 tw-text-xl tw-pr-6 ${
        state.editRow && state.editRow === row._id ? "tw-align-top" : null
      }`}
    >
      {(state.editRow === null || state.editRow !== row._id) &&
        config.viewRender(row, state, dispatch)}
      {state.editRow && state.editRow === row._id && (
        <>
          {config.editRender
            ? config.editRender(row, state, dispatch)
            : config.viewRender(row, state, dispatch)}
        </>
      )}
    </td>
  );
};

SkedDataTable.propTypes = {
  headerArray: PropTypes.array,
  className: PropTypes.string,
};

export default SkedDataTable;
