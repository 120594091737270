const util = {};

util.replaceSelection = (function () {
  if (window.getSelection) {
    return function (content, replacePrefix) {
      var range,
        sel = window.getSelection();
      var node =
        typeof content === "string"
          ? document.createTextNode(content)
          : content;
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(" "));
        range.insertNode(node);
        range.setStart(node, replacePrefix ? node.nodeValue.length - 1 : 0);

        window.setTimeout(function () {
          range = document.createRange();
          range.setStartAfter(node);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          if (replacePrefix) {
            const text = node.previousSibling.nodeValue;
            const prefixStart = text.lastIndexOf(replacePrefix);
            node.previousSibling.nodeValue =
              text.slice(0, prefixStart) +
              text.slice(prefixStart + replacePrefix.length);
          }
        }, 20); // set a tiny timeout to give wrappers and safari time to update
      }
    };
  } else if (document.selection && document.selection.createRange) {
    return function (content) {
      var range = document.selection.createRange();
      if (typeof content === "string") {
        range.text = content;
      } else {
        range.pasteHTML(content.outerHTML);
      }
    };
  }
})();

util.restoreSelection = (function () {
  if (window.getSelection) {
    return function (savedSelection) {
      var sel = window.getSelection();
      sel.removeAllRanges();
      for (var i = 0, len = savedSelection.length; i < len; ++i) {
        sel.addRange(savedSelection[i]);
      }
    };
  } else if (document.selection && document.selection.createRange) {
    return function (savedSelection) {
      if (savedSelection) {
        savedSelection.select();
      }
    };
  }
})();

util.saveSelection = (function () {
  if (window.getSelection) {
    return function () {
      var sel = window.getSelection(),
        ranges = [];
      if (sel.rangeCount) {
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i));
        }
      }
      return ranges;
    };
  } else if (document.selection && document.selection.createRange) {
    return function () {
      var sel = document.selection;
      return sel.type.toLowerCase() !== "none" ? sel.createRange() : null;
    };
  }
})();

util.replaceText = function (text) {
  var range,
    sel = window.getSelection();
  var node = document.createTextNode(text);

  if (sel && sel.getRangeAt && sel.rangeCount) {
    range = sel.getRangeAt(0);
    range.deleteContents();
    range.insertNode(node);
    range = document.createRange();
    range.setStartAfter(node);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

util.getSelection = function () {
  var selection = window.getSelection();
  var range = selection.getRangeAt(0);
  return {
    text: range.startContainer.textContent,
    startIndex: range.startOffset,
  };
};

export default util;

// lodash
export function shuffle(array) {
  const length = array.length;
  if (!length) {
    return [];
  }
  let index = -1;
  const lastIndex = length - 1;
  while (++index < length) {
    const rand = index + Math.floor(Math.random() * (lastIndex - index + 1));
    const value = array[rand];
    array[rand] = array[index];
    array[index] = value;
  }
  return array;
}
