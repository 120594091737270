import { useStore } from "effector-react";
import { useEffect } from "react";

import { useCurrentUser } from "entities/current-user";
import { MediaCollection } from "api/media-library";

import { $store, getCollectionsFx } from "./collections.model";

export function useMediaCollections() {
  const { collections, loadingError, isLoading } = useStore<{
    collections?: MediaCollection[];
    loadingError?: Error | null;
    isLoading?: boolean;
  }>($store);
  const user = useCurrentUser();

  useEffect(() => {
    getCollectionsFx(user);
  }, [user]);

  return {
    collections,
    loadingError,
    isLoading,
  };
}
