import React from "react";
import styled from "styled-components";

function CollaborationAvatar({ user, isOwnMessage, small }) {
  const { firstName, lastName } = user;
  //   in case when user doesnt have one of fields set
  const initials = [firstName, lastName]
    .filter((val) => !!val)
    .reduce((ac, cur) => (ac += cur[0]), "");

  return (
    <AvatarWrap isOwnMessage={isOwnMessage} small={small}>
      {user.profileImage ? (
        <img src={user.profileImage} />
      ) : !small ? (
        <span>{initials}</span>
      ) : null}
    </AvatarWrap>
  );
}

const getSize = ({ small }) => {
  return small ? "14px" : "28px";
};

const AvatarWrap = styled.div`
  border-radius: 50%;
  background-color: ${({ isOwnMessage }) =>
    isOwnMessage ? "#4F31FF" : "#FEB5B5"};
  min-width: ${getSize};
  min-height: ${getSize};
  width: ${getSize};
  height: ${getSize};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  order: ${({ isOwnMessage }) => (isOwnMessage ? 2 : 1)};
  margin: ${({ isOwnMessage }) => (isOwnMessage ? "0 0 0 12px" : "0 12px 0 0")};
  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  span {
    font-weight: 500;
    color: #fff;
  }
`;

export default CollaborationAvatar;
