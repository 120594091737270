import { combine, createDomain, forward, restore, sample } from "effector";
import axios from "axios";

import { notify } from "libs/effector-notify";
import { getImageDimensions } from "libs/files";

import { createWidget } from "libs/effector-widget";
import { PostMediaFile } from "shared/types/post";

export const EditPostMediaDomain = createDomain();

const { createStore, createEvent, createEffect } = EditPostMediaDomain;

export const fileForEditingSelected = createEvent<PostMediaFile>();
export const editorClosed = createEvent();
export const fileExported = createEvent<Blob>();
export const fileChanged = createEvent<PostMediaFile>();
export const progressChanged = createEvent<number>();

export const $isModalOpened = createStore(false).on(
  fileForEditingSelected,
  () => true
);
export const $selectedFile = restore(fileForEditingSelected, null);
const $progress = restore(progressChanged, null);

export const uploadFileToS3Fx = createEffect(async (blob: Blob) => {
  progressChanged(100);
  const {
    data: { fileUrl, thumbnailUrl, uploadUrl },
  } = await axios.post("/getSignedUrl", {
    contentType: "image/png",
    fileExtension: ".png",
  });

  await axios.put(uploadUrl, blob);

  return { fileUrl, thumbnailUrl };
});

export const uploadEditedFileFx = createEffect(
  async ({
    previousFile,
    newBlob,
  }: {
    previousFile: PostMediaFile;
    newBlob: Blob;
  }): Promise<PostMediaFile> => {
    const { thumbnailUrl, fileUrl } = await uploadFileToS3Fx(newBlob);

    return {
      originalUrl: previousFile.originalUrl,
      altText: previousFile.altText,
      fileName: previousFile.fileName,
      isVideo: false,
      thumbnailUrl: thumbnailUrl,
      url: fileUrl,
      type: "image",
      asset_id: previousFile.asset_id,
      meta: {
        ...(await getImageDimensions(newBlob)),
        size: newBlob.size / 1024,
      },
    };
  }
);

sample({
  clock: fileExported,
  source: $selectedFile,
  fn: (previousFile, newBlob) => ({
    previousFile: previousFile as PostMediaFile,
    newBlob,
  }),
  target: uploadEditedFileFx,
});

forward({
  from: uploadEditedFileFx.doneData,
  to: fileChanged,
});

notify(uploadEditedFileFx, {});

export const $model = combine({
  isModalOpened: $isModalOpened,
  fileSrc: $selectedFile.map((file) => (file?.originalUrl || file?.url) ?? ""),
  progress: $progress,
});

export const EditPostPostMediaWidget = createWidget(EditPostMediaDomain);

EditPostMediaDomain.onCreateStore((store) =>
  store.reset([editorClosed, uploadEditedFileFx.done])
);
