import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";

import {
  FormSize,
  TextInput,
  SubmitButton,
  FormThemeProvider,
} from "ui/ecosystems/forms";
import { updateCollection } from "api/media-library";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

interface CollectionNameFormProps {
  className?: string;
  collection: { _id: string; name: string };
  onCollectionUpdated(collection: unknown): void;
}

export const CollectionNameForm = styled(function CollectionNameForm({
  className,
  collection,
  onCollectionUpdated,
}: CollectionNameFormProps) {
  const [value, setValue] = useState(collection.name);
  const [isSubmitting, setSubmitting] = useState(false);

  async function handleSubmit(evt: React.FormEvent) {
    try {
      setSubmitting(true);
      evt.preventDefault();
      const updatedCollection = {
        ...collection,
        name: value,
      };
      await updateCollection(collection._id, updatedCollection);
      toast.success("Successfully renamed collection!");
      onCollectionUpdated(updatedCollection);
    } catch (err) {
      toast.error(getUserFriendlyErrorMessage(err));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <FormThemeProvider size={FormSize.medium}>
      <Form className={className} onSubmit={handleSubmit}>
        <TextInput
          disabled={isSubmitting}
          autoFocus
          value={value}
          onChange={setValue}
        />
        <SubmitButton
          onClick={handleSubmit}
          size={FormSize.small}
          primary
          isSubmitting={isSubmitting}
          css="margin-top: 8px; width: 100%;"
        >
          Save
        </SubmitButton>
      </Form>
    </FormThemeProvider>
  );
})``;

const Form = styled.form`
  padding: 2px 8px;
`;
