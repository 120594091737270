import React, { useEffect, useState } from "react";
import { PlatformUsage, PostFieldLabel } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";

const YouTubePrivacy = ({
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  errors,
  className = "",
}) => {
  //default to public because customers really aren't going to use private let's be honest
  const [selected, setSelected] = useState(
    post && post[FIELDS.YT_PRIVACY] ? post[FIELDS.YT_PRIVACY] : "public"
  );

  const categories = [
    {
      value: "public",
      label: "Public",
    },
    {
      value: "unlisted",
      label: "Unlisted",
    },
    {
      value: "private",
      label: "Private",
    },
  ];

  useEffect(() => {
    setSelected(post[FIELDS.YT_PRIVACY]);
  }, []);

  const handleCategoryChange = (item) => {
    setSelected(item);
    onChange(item);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          YouTube privacy status
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.YT_PRIVACY}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <Select
        value={selected}
        options={categories}
        onChange={handleCategoryChange}
        disabled={disabled}
      />
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.YT_PRIVACY]}
        errors={errors}
      />
    </div>
  );
};

export default YouTubePrivacy;
