import React, { useContext, useEffect, useRef } from "react";

import { useOpenableState, OpenableState } from "libs/use-openable-state";
import { useClickOutside } from "../use-click-outside";

const noop = () => {};
export const DropdownContext = React.createContext<OpenableState>({
  isOpened: false,
  open: noop,
  close: noop,
  toggle: noop,
});

export function useDropdown() {
  const openAbleState = useOpenableState();
  const ref = useRef<HTMLElement>();
  useClickOutside(ref, openAbleState.close);

  useEffect(() => {
    function handleEsc(e) {
      if (e.keyCode === 27) {
        openAbleState.close();
      }
    }

    document.addEventListener("keydown", handleEsc, false);

    return () => document.removeEventListener("keydown", handleEsc, false);
  }, []);

  const dropdownProps = {
    ref,
    openAbleState: openAbleState,
    contextValue: openAbleState,
  };

  return dropdownProps;
}

export function DropdownItem(props) {
  const dropdown = useContext(DropdownContext);

  return (
    <div
      {...props}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        dropdown.close();
      }}
    >
      {props.children}
    </div>
  );
}
