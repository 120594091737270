import { InitialCalendarStateType } from "features/calendar-new/types";

export const getCalendarPostsIds = (state: InitialCalendarStateType): [] => {
  return state.entities.calendarPosts.ids;
};

export const getCalendarPosts = (state: InitialCalendarStateType): any => {
  return state.entities.calendarPosts.entities;
};
export const getCalendarPostsLoading = (
  state: InitialCalendarStateType
): boolean => {
  return state.entities.calendarPosts.isLoading;
};
