import React from "react";
import styled from "styled-components";

const Dot = ({ color, size, children = null, className = "" }) => {
  return (
    <StyledDot color={color} size={size} className={className}>
      {children}
    </StyledDot>
  );
};

const StyledDot = styled.div`
  width: ${(props) => (props.size ? props.size : "20")}px;
  height: ${(props) => (props.size ? props.size : "20")}px;
  ${({ color }) => color && `background: ${color};`}
  border-radius: 9999px;
`;

export default Dot;
