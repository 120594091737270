import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import { Country } from "shared/types/calendar";
import { useTrackEvent } from "libs/analytics";
import {
  getHolidaysCalendars,
  getCountries,
} from "selectors/calendarFtilterSelectors";
import {
  importCountry,
  fetchHolidaysFromUrl,
  importCalendarFromUrl,
} from "../CalendarFilterModel";
import { ICS_IMPORTED } from "constants/Events";

interface Props {
  fromUrl: boolean;
  setFromUrl: (fromUrl: boolean) => void;
  setShowHolidayModal: (showHolidayModal: boolean) => void;
  countries?: Country[];
}

export const ImportHoliday: React.FC<Props> = (props: Props) => {
  const { fromUrl, setFromUrl, setShowHolidayModal } = props;

  const dispatch = useDispatch();
  const countries = useSelector(getCountries);
  const holidaysCaledars = useSelector(getHolidaysCalendars);
  const trackEvent = useTrackEvent();

  const [selectedCountry, setSelectedCountry] = useState<Country>({
    name: "",
    _id: "",
    iso: "",
  });
  const [calendarURL, setCalendarURL] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleClose = () => {
    setShowHolidayModal(false);
  };

  const toggleImportEvents = () => {
    setFromUrl(!fromUrl);
  };

  const handleSelectCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const countryName = event.target.value;
    const country = countries.find((country) => country.name === countryName);

    if (country) {
      setSelectedCountry(country);
    }
  };

  const handleUrlInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarURL(event.target.value);
  };

  const validateCalendar = () => {
    let predicate: (country: Country) => boolean;

    if (fromUrl) {
      predicate = () => {
        return false;
      };
    } else {
      predicate = (country: Country) => {
        return country._id === selectedCountry._id;
      };
    }

    const calendar = holidaysCaledars.find(predicate);
    if (calendar) {
      toast.error({
        title: 'Calendar "' + selectedCountry.name + '" already exists',
      });
      setCalendarURL("");
      return true;
    }

    return false;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);

    const calendarExists = validateCalendar();
    if (calendarExists) {
      return;
    }

    if (fromUrl) {
      const result: any = await dispatch(fetchHolidaysFromUrl(calendarURL));
      if (result?.payload?.name) {
        await dispatch(
          importCalendarFromUrl({
            calendarName: result.payload.name,
            url: calendarURL,
          })
        );
      }
    } else {
      dispatch(importCountry(selectedCountry));
    }

    trackEvent({ eventName: ICS_IMPORTED });
    handleClose();
  };

  return (
    <Modal show={true} animation={false} bsStyle="giselle" bsSize="small">
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <button onClick={handleClose} type="button" className="close">
            &times;
          </button>
          <h4 className="modal-title">Import Holiday</h4>
        </div>

        <div className="modal-body">
          {fromUrl ? (
            <div className="calendar-import">
              <div className="calendar-import__label">Import ICS URL:</div>

              <input
                type="url"
                className="calendar-import__url-input"
                placeholder="http://www.example.com/file.ics"
                onChange={handleUrlInput}
                value={calendarURL}
                required
              />

              <button
                className="link calendar-events__trigger-btn calendar-import__toggle-btn"
                onClick={toggleImportEvents}
              >
                or select from a list of holidays
              </button>
            </div>
          ) : (
            <>
              <div className="calendar-import__label">
                Choose a holiday calendar to add:
              </div>
              <select
                className="calendar-import__dropdown"
                value={selectedCountry.name}
                onChange={handleSelectCountry}
                required
              >
                <option />
                {countries.map((country) => (
                  <option key={country._id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              <button
                className="link calendar-events__trigger-btn calendar-import__toggle-btn"
                type="button"
                onClick={toggleImportEvents}
              >
                or import an ICS from a URL
              </button>
            </>
          )}
        </div>

        <div className="modal-footer">
          <button type="button" className="link" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn primary" disabled={isSubmitted}>
            {isSubmitted ? (
              <span>Adding...</span>
            ) : (
              <span>Add to Calendar</span>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};
