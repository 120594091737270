import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  addCollaborationComment,
  deleteCollaborationComment,
  editCollaborationComment,
  getCollaborationComments,
} from "actions/collaboration";
import CollaborationInputControls from "components/Collaboration/ChatUtils/CollaborationInputControls";
import CollaborationMessage from "components/Collaboration/ChatUtils/CollaborationMessage";
import {
  getCollaborators,
  getComments,
  isRequestingCommentsSelector,
} from "selectors/collaborationSelectors";

import collabApi from "api/collaboration";

import { SmallLoadingSpinner } from "ui/LoadingSpinner/LoadingSpinner";

const CollaborationChat = ({
  channelId,
  user,
  postState,
  postId,
  disabled,
}) => {
  const dispatch = useDispatch();

  const comments = useSelector(getComments);
  const users = useSelector(getCollaborators);
  const isRequestingComments = useSelector(isRequestingCommentsSelector);

  const messagesContainer = useRef(null);

  const hasLegacyMessages = comments?.legacyThread?.length > 0;
  const hasMessages = comments?.activeThread?.length > 0;

  const [editingComment, setEditingComment] = useState(null);

  const scrollToBottom = () => {
    if (hasMessages || hasLegacyMessages) {
      setTimeout(() => {
        if (messagesContainer && messagesContainer.current) {
          const { scrollHeight, scrollTop, clientHeight } =
            messagesContainer.current;
          const shouldScroll = scrollTop + clientHeight - scrollHeight < 0;
          if (shouldScroll) {
            messagesContainer.current.scrollTop = scrollHeight;
          }
        }
      }, 0);
    }
  };

  // on component creation
  useEffect(() => {
    collabApi.subscribeToCollabChannel(
      user.userId,
      user.adminId,
      channelId,
      dispatch
    );
    return () => {
      collabApi.unsubscribeFromCollabChannel(user.adminId, channelId);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const promisifyAction = (action, args) => {
    return new Promise((resolve) => {
      dispatch(action(...args, resolve));
    });
  };

  const handleMessageFormSubmit = async (text) => {
    if (editingComment) {
      await promisifyAction(editCollaborationComment, [
        channelId,
        editingComment._id,
        text,
      ]);
      setEditingComment(null);
    } else {
      const metadata = {
        post: { postId },
        postType: postState.toLowerCase(),
      };
      await promisifyAction(addCollaborationComment, [
        channelId,
        text,
        metadata,
      ]);
    }
    dispatch(getCollaborationComments(channelId));
  };

  const handleMessageDeleted = async (comment) => {
    await promisifyAction(deleteCollaborationComment, [channelId, comment._id]);
    dispatch(getCollaborationComments(channelId));
  };

  return (
    <ChatContainer className="tw-min-w-400px tw-max-w-400px tw-flex tw-flex-col">
      <MessageListContainer ref={messagesContainer}>
        {users?.length > 0 &&
          // show messages container only if messages are present
          (hasLegacyMessages || hasMessages ? (
            <>
              {isRequestingComments && <LoadingSpinnerStyled />}

              {hasLegacyMessages && (
                <LegacyThread>
                  {comments?.legacyThread?.map((comment) => (
                    <CollaborationMessage
                      comment={comment}
                      collaborators={users}
                      currentUser={user}
                      key={comment._id}
                    />
                  ))}
                </LegacyThread>
              )}
              {comments?.activeThread?.map((comment) => (
                <CollaborationMessage
                  comment={comment}
                  collaborators={users}
                  currentUser={user}
                  key={comment._id}
                  onEdit={(msg) => setEditingComment(msg)}
                  onDelete={(msg) => handleMessageDeleted(msg)}
                  isBlured={editingComment && editingComment._id != comment._id}
                />
              ))}
            </>
          ) : (
            // if no messages
            <EmptyState>
              <img
                src="/assets/img/comments-empty-state.png"
                alt="empty-comment"
                width="100%"
              />
              <p>No existing comments!</p>
              <span>
                To start the conversation, type your message below to notify
                your team. To mention a user, type @ followed by their name.
              </span>
            </EmptyState>
          ))}
      </MessageListContainer>

      <CollaborationInputControls
        collaborators={users}
        onMessageSend={(text) => handleMessageFormSubmit(text)}
        editingComment={editingComment}
        cancelEdit={() => setEditingComment(null)}
        disabled={disabled}
      />
    </ChatContainer>
  );
};

const LegacyThread = styled.div`
  background: #fbfbfb;
  border: 1px dashed #c4c4c4;
  border-radius: 10px;
  padding: 16px 10px 0 0;
  margin: 0px -10px 24px 14px;
`;

const ChatContainer = styled.div`
  --height: 620px;
  padding: 25px 16px 30px;
  max-height: var(--height);
  height: var(--height);
`;

const MessageListContainer = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
  padding-right: 14px;
  /* scrollbar */

  ::-webkit-scrollbar {
    width: 10px;

    &-track {
      color: #fff;
      background: #f3f3f3;
      border-radius: 3px;
      box-shadow: inset 0 -4px 0 0, inset 0 4px 0 0;
    }

    &-corner {
      background: #fff;
    }

    &-thumb {
      background: #d9d9de;
    }

    &-button {
      display: none;
    }
  }
`;

const EmptyState = styled.div`
  width: 280px;
  margin: 60px auto 0;

  img {
    margin-bottom: 12px;
  }

  span,
  p {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    display: block;
    margin-left: 10px;
  }

  p {
    font-weight: 500;
    margin-bottom: 12px;
  }

  span {
    font-weight: 300;
  }
`;

const LoadingSpinnerStyled = styled(SmallLoadingSpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

export default CollaborationChat;
