import React from "react";
import { useEvent, useStore } from "effector-react/ssr";

import { PhotoEditorModal } from "ui/photo-editor";
import {
  $model,
  editorClosed,
  fileForEditingSelected,
  fileChanged,
  fileExported,
  EditPostPostMediaWidget,
} from "../model";

import { createWidgetComponent, useWatch } from "libs/effector-widget";
import { PostMediaFile } from "shared/types/post";

interface Props {
  children(params: { onEdit(file: PostMediaFile): void }): React.ReactNode;
  onChange(file: PostMediaFile): void;
}

export const PostMediaEditing = createWidgetComponent(
  EditPostPostMediaWidget,
  (props: Props) => {
    const { isModalOpened, fileSrc, progress } = useStore($model);
    const [onEdit, handleSave, handleClose] = useEvent([
      fileForEditingSelected,
      fileExported,
      editorClosed,
    ]);

    useWatch(fileChanged, props.onChange);

    return (
      <>
        {props.children({ onEdit: onEdit })}
        <PhotoEditorModal
          image={fileSrc}
          show={isModalOpened}
          onClose={handleClose}
          onSave={handleSave}
          progress={progress}
        />
      </>
    );
  }
);
