import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ngDeps from "ng-react-directives/ngr-injector";
import StatusChange from "components/Collaboration/StatusChange/StatusChange";
import PostLink from "components/Posts/Post/PostLink/PostLink";
import { PAGES } from "constants/Pages";
import { sendPostNotification } from "actions/postsView";
import { getPosts } from "selectors/postsPageSelectors";
import { unreadCommentsSelector } from "selectors/collaborationSelectors";
import { markCollaborationChannelAsRead } from "actions/collaboration";
import { getPageVerbs } from "utils/posts";
import { getUserDefaultStatusKey } from "utils/user";
import { showPreviewPostModal, showSkedUrlModal } from "actions/modal";
import { openEditPostModal, openCreatePostModal } from "features/post";
import { useTrackEvent } from "libs/analytics";
import { isInstagramAccount } from "entities/accounts";

import "./PostActions.less";
import {
  CLICKED_PREVIEW_POST_MODAL,
  COPY_TO_DRAFT_SELECT,
} from "constants/Events";

const PostActions = ({
  post,
  accounts,
  page,
  user,
  channelId,
  showText = true,
}) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { $rootScope } = ngDeps;
  const pageVerbs = getPageVerbs(page, post, user, $rootScope);
  const needToOpenSuccessEditPostModal = post.isSuccess;
  const editPostModalSuccessProps = {
    postType: "schedule", // to show when posted
    past: true, // to disable all inputs
    isPotentialError: false, // to disable all inputs
  };

  let pageItemName = useMemo(() => {
    switch (page) {
      case PAGES.ARCHIVE:
        return "View Post";

      case PAGES.DRAFT:
        return "Edit Draft";

      default:
        return "Edit Post";
    }
  }, [page]);

  const posts = useSelector(getPosts);

  const unreads =
    useSelector(unreadCommentsSelector)?.find(
      (channel) => channel.channelId === channelId
    )?.unreads || 0;

  const accountMap = $rootScope.accountsMap[post.accountId];

  const isInsta = isInstagramAccount(accountMap);
  const showPostStatus =
    user && user.isPostStatusEnabledForUser() && page !== PAGES.ARCHIVE;

  const dispatchShowSkedUrlModal = () =>
    dispatch(showSkedUrlModal(post, Boolean(accountMap.accountPublicKey)));

  const copyToDraft = () => {
    trackEvent({ eventName: COPY_TO_DRAFT_SELECT });

    dispatch(
      openCreatePostModal({
        post: {
          ...post,
          isSuccess: false,
          postStatusKey: getUserDefaultStatusKey(user),
        },
      })
    );
  };

  const postAgain = () =>
    dispatch(
      openEditPostModal({
        post: {
          ...post,
          postStatusKey: getUserDefaultStatusKey(user),
        },
      })
    );

  return (
    <div className="PostActions">
      {showPostStatus && (
        <div
          className={`ListPostDetails-Collab tw-border-0 tw-border-b tw-border-solid tw-border-gray-100 tw-pb-6 tw-mb-4`}
        >
          <StatusChange
            user={user}
            post={post.schedule ? post.schedule : post}
            page={page}
          />
        </div>
      )}
      <ul className={`PostActions-Ul list-unstyled`}>
        {pageVerbs.previewable && (
          <li>
            <PostLink
              action={() => {
                trackEvent({ eventName: CLICKED_PREVIEW_POST_MODAL });
                dispatch(showPreviewPostModal(post, accounts, posts));
              }}
              icon="icon-eye"
              actionText="Preview"
              showText={showText}
            />
          </li>
        )}
        {pageVerbs.notifiable && (
          <li>
            <PostLink
              action={() => dispatch(sendPostNotification(post._id))}
              icon="icon-eye"
              actionText="Send post notification"
              showText={showText}
            />
          </li>
        )}

        {pageVerbs.duplicatable && (
          <li>
            <PostLink
              action={copyToDraft}
              icon="icon-retry"
              actionText="Copy to drafts"
              showText={showText}
            />
          </li>
        )}

        {pageVerbs.repostable && (
          <li>
            <PostLink
              action={postAgain}
              icon="icon-retry"
              actionText="Post Again"
              showText={showText}
            />
          </li>
        )}
        {(pageVerbs.editable || needToOpenSuccessEditPostModal) && (
          <li>
            <PostLink
              action={() => {
                dispatch(markCollaborationChannelAsRead(channelId));
                dispatch(
                  openEditPostModal({
                    post: needToOpenSuccessEditPostModal
                      ? {
                          ...post,
                          ...editPostModalSuccessProps,
                        }
                      : post,
                  })
                );
              }}
              icon="icon-pencil"
              actionText={pageItemName}
              showText={showText}
              notifyCount={unreads}
            />
          </li>
        )}
        {isInsta && pageVerbs.urlEditable && (
          <li>
            <PostLink
              action={dispatchShowSkedUrlModal}
              icon="icon-link"
              actionText={`${post.url ? "Update" : "Add"} URL`}
              showText={showText}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

PostActions.propTypes = {
  actions: PropTypes.object,
  accounts: PropTypes.array,
  post: PropTypes.object,
  page: PropTypes.string,
  user: PropTypes.object,
  showText: PropTypes.string,
  channelId: PropTypes.string,
};

export default PostActions;
