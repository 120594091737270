import React, { useState, useEffect } from "react";

import { SkedIcon } from "ui";
import { PlatformIcon } from "entities/accounts";

const LINKED_PLATFORM = "LINKED";
const linkedPlatformObj = { NAME: LINKED_PLATFORM, TYPE: LINKED_PLATFORM };

const PlatformTabs = ({ platforms, onChange }) => {
  const [activePlatform, setActivePlatform] = useState(null);

  useEffect(() => {
    const platformObjs = platforms;
    let defaultPlatform = LINKED_PLATFORM;
    if (platformObjs.length === 1) {
      defaultPlatform = platformObjs[0].TYPE;
    }

    setActivePlatform(defaultPlatform);
    if (onChange) {
      onChange(defaultPlatform);
    }
  }, [platforms]);

  const handleChange = (type) => {
    setActivePlatform(type);
    if (onChange) {
      onChange(type);
    }
  };

  return (
    <ul className="tw-p-0">
      {Object.keys(platforms).length > 1 && (
        <PlatformTab
          platform={linkedPlatformObj}
          active={activePlatform === LINKED_PLATFORM}
          onChange={handleChange}
        />
      )}
      {platforms.map((platform) => (
        <PlatformTab
          key={platform.NAME}
          platform={platform}
          active={activePlatform === platform.TYPE}
          onChange={handleChange}
        />
      ))}
    </ul>
  );
};

const PlatformTab = ({ platform, active, onChange }) => {
  const activeClasses = "tw-bg-white tw-border-r-0 tw-border-r-white";
  const inactiveClasses = "tw-bg-sked-200";
  return (
    <li
      className={`tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-mb-2 tw-border tw-border-solid tw-rounded-l-lg tw-w-40px tw-h-40px tw-p-4 tw-border-sked-300 ${
        active ? activeClasses : inactiveClasses
      }`}
      onClick={() => onChange(platform.TYPE)}
    >
      {platform.NAME === LINKED_PLATFORM && (
        <SkedIcon icon="icon-link" className="tw-font-bold tw-text-sked-500" />
      )}
      {platform.NAME !== LINKED_PLATFORM && (
        <PlatformIcon css="width: 14px" type={platform.NAME} />
      )}
    </li>
  );
};

export default PlatformTabs;
