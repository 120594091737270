import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getUser } from "selectors/commonSelectors";
import { getAccountsCounts } from "selectors/skedCoreSelectors";
import { AddAccounts, isReachedAccountsLimit } from "./AddAccounts";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import {
  DropdownButton,
  DropdownMenuContent,
  DropdownText,
  Link,
  Trigger,
} from "./header";
import { Flex } from "ui/commonStyled/layout";
import { useTrackEvent } from "libs/analytics";
import { HEADER_MENU_ADD_ACCOUNT_CLICK } from "constants/Events";
import { useOpenIntercomChat } from "libs/use-open-intercom-chat";
import SocialAccountIcon from "./icons/socialAccountIcon.svg";
import { AccountsCount } from "../../api/accounts";

export function AddAccountMenu(props: {
  openSocialAccountConnectDialog: () => void;
}): React.ReactElement {
  const user = useSelector(getUser);
  const openIntercomChat = useOpenIntercomChat();
  const isAdmin = user && user.role === "admin";
  const canAddAccounts = isAdmin || user.preferences?.canAddAccounts || false;
  const trackEvent = useTrackEvent();
  const onClickTrigger = () => {
    trackEvent({
      eventName: HEADER_MENU_ADD_ACCOUNT_CLICK,
      useServices: ["AMP"],
    });
  };
  const counts: AccountsCount = useSelector(getAccountsCounts);

  const dropdownAlignOffset = useMemo(() => {
    const { pricingVersion } = counts;

    const {
      possibleIgAccountsCount,
      possibleOtherAccountsCount,
      possibleAccountsCount,
    } = isReachedAccountsLimit(counts);

    if (!possibleIgAccountsCount) {
      return -50;
    }

    if (
      (pricingVersion &&
        pricingVersion.includes("2024") &&
        possibleAccountsCount <= 0) ||
      (pricingVersion &&
        !pricingVersion.includes("2024") &&
        possibleIgAccountsCount <= 0 &&
        possibleOtherAccountsCount <= 0) ||
      !canAddAccounts
    ) {
      return -40;
    }

    return -10;
  }, [canAddAccounts, counts]);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <Trigger
          data-testid="add-account-menu-trigger"
          onClick={onClickTrigger}
        >
          <span className="mr-2">+</span>
          Add accounts
        </Trigger>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent
          minWidth={canAddAccounts ? 366 : 330}
          asChild
          align="end"
          sideOffset={8}
          alignOffset={dropdownAlignOffset}
          padding="16px"
        >
          <div>
            {canAddAccounts ? (
              <AddAccounts
                openSocialAccountConnectDialog={
                  props.openSocialAccountConnectDialog
                }
              />
            ) : (
              <div>
                <Flex>
                  <DropdownText>
                    <div>
                      You’ve filled all the social account slots included in
                      your plan.
                    </div>
                    <Flex marginTop={10}>Need more accounts?</Flex>
                    <DropdownMenu.Item>
                      <Flex>
                        <DropdownButton
                          data-testid="not-can-add-account-contact-support"
                          className="btn"
                          onClick={openIntercomChat}
                        >
                          Contact Support
                        </DropdownButton>
                      </Flex>
                    </DropdownMenu.Item>
                    <ManageSocialAccounts
                      justify="center"
                      data-testid="manage-social-account-link"
                    >
                      <Link to="/dashboard/accounts">
                        <DropdownMenu.Item>
                          <StyledSocialAccountIcon />
                          Manage social accounts & groups
                        </DropdownMenu.Item>
                      </Link>
                    </ManageSocialAccounts>
                  </DropdownText>
                </Flex>
              </div>
            )}
          </div>
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export const StyledSocialAccountIcon = styled(SocialAccountIcon)`
  width: 16px;
  flex-shrink: 0;
`;

export const ManageSocialAccounts = styled(Flex)`
  padding: 10px 10px 4px;
  margin: 10px 0 -2px;

  a {
    margin: 0;
    font-weight: 450;

    svg {
      margin-right: 12px;
      position: relative;
      top: 2px;
      width: 14px;
    }
  }
`;
