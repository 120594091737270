import { skedInboxApi } from "./_skedInboxApi";
import {
  Conversation,
  ConversationPost,
  Conversations,
  ConversationsFilters,
  ConversationType,
} from "types/conversationInbox";
import _ from "lodash";

interface getConversationProps {
  next?: string;
  isStarred: boolean;
  isMentioned: boolean;
  sort?: string;
  accounts?: string[];
  assignedTo?: string | null;
  types: ConversationType[];
  status?: string | null;
  unassigned: boolean | null;
  ignoredAccountsComments: string[] | null;
}

export async function getConversations({
  next,
  isStarred,
  isMentioned,
  sort,
  accounts,
  assignedTo,
  types,
  status,
  unassigned,
  ignoredAccountsComments,
}: getConversationProps): Promise<{
  data: Conversations;
}> {
  const requestBody = _.omitBy(
    {
      next,
      ...(isStarred ? { isStarred } : {}),
      ...(isMentioned ? { isMentioned } : {}),
      sort,
      accounts,
      assignedTo,
      type: types,
      status,
      unassigned,
      ignoredAccountsComments,
    },
    _.isNil
  );

  if ((accounts?.length || 0) > 200) {
    return await skedInboxApi.post("/conversations/bulk/get", requestBody);
  }

  return await skedInboxApi.get("/conversations", {
    params: requestBody,
  });
}

export async function getConversation({
  conversationId,
}: {
  conversationId: string;
}): Promise<{
  data: Conversation;
}> {
  return await skedInboxApi.get(`/conversations/${conversationId}`);
}

interface patchConversationProps {
  conversationId: string;
  status?: string;
  isStarred?: boolean;
  assignedTo?: string | null;
  unreadCount?: number;
}

export async function patchConversation({
  conversationId,
  status,
  isStarred,
  assignedTo,
  unreadCount,
}: patchConversationProps): Promise<{
  data: Conversation;
}> {
  return await skedInboxApi.patch(`/conversations/${conversationId}`, {
    status,
    isStarred,
    assignedTo,
    unreadCount,
  });
}

interface getConversationsCounterProps {
  accounts: string[];
  types: ConversationType[];
  ignoredAccountsComments: string[] | null;
}

export async function getConversationsCounter({
  accounts,
  types,
  ignoredAccountsComments,
}: getConversationsCounterProps): Promise<{
  data: ConversationsFilters;
}> {
  const requestBody = _.omitBy(
    {
      accounts,
      type: types,
      ignoredAccountsComments,
    },
    _.isNil
  );

  if ((accounts?.length || 0) > 200) {
    return await skedInboxApi.post("/conversations/bulk/counters", requestBody);
  }

  return await skedInboxApi.get("/conversations/counters", {
    params: requestBody,
  });
}

export async function deleteConversation(conversationId: string): Promise<{}> {
  return await skedInboxApi.delete(`/conversations/${conversationId}`);
}

export async function resyncInterlocutor({
  conversationId,
  interlocutorId,
}: {
  conversationId: string;
  interlocutorId: string;
}): Promise<{}> {
  return await skedInboxApi.put(
    `/conversations/${conversationId}/interlocutor/${interlocutorId}/resync`
  );
}

export async function closeAllConversation(accounts: string[]): Promise<void> {
  return await skedInboxApi.put(`/conversations/close-all`, {
    accounts,
  });
}

export interface patchConversationsProps {
  conversationIds: string[];
  status?: string;
  isStarred?: boolean;
  assignedTo?: string | null;
  unreadCount?: number;
}

export async function patchConversations({
  conversationIds,
  status,
  isStarred,
  assignedTo,
  unreadCount,
}: patchConversationsProps): Promise<{
  data: Conversation[];
}> {
  return await skedInboxApi.patch(`/conversations`, {
    ids: conversationIds,
    status,
    isStarred,
    assignedTo,
    unreadCount,
  });
}

export async function resyncPostContent({
  postId,
  conversationId,
}: {
  postId: string;
  conversationId: string;
}): Promise<{
  data: ConversationPost;
}> {
  return await skedInboxApi.put(
    `/conversations/${conversationId}/posts/${postId}/resync`,
    {}
  );
}
