import React from "react";
import styled from "styled-components";
import { ErrorList } from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Alert } from "ui";
import { AlertType } from "ui/alert";
import { ExternalLink } from "ui/Link";
import { fbMinimum } from "components/Posts/PostForm/postFormValidator";
import { getMediaFileDuration } from "entities/media-files";
import { PostAssetProps } from "./post-selected-assets";
import { MediaFilesErrors, MediaFilesWarning } from "../../validation";

interface Props {
  warnings: MediaFilesWarning;
  errors: MediaFilesErrors;
  files: PostAssetProps[];
  className?: string;
}

function WarningsErrorsComponent(props: Props) {
  return (
    <WarningsErrorsComponentRoot className={props.className}>
      <ErrorList>
        {props.errors.tooManyFiles && (
          <Alert type={AlertType.error}>
            Too many files - we support up to {props.errors.tooManyFiles || 10}{" "}
            files in this post.
          </Alert>
        )}
        {props.errors.IGVideoTooShortVideoIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.IGVideoTooShortVideoIndexes
            )}) are less than the Instagram minimum of 3 seconds long.`}
            type={AlertType.error}
          >
            Instagram requires videos to be at least 3 seconds long.
            <VideoFilesDurations
              files={props.files}
              indexes={props.errors.IGVideoTooShortVideoIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.IGStoryVideoTooShortVideoIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.IGStoryVideoTooShortVideoIndexes
            )}) are less than the Instagram Story minimum of 3 seconds long.`}
            type={AlertType.error}
          >
            Instagram story posts must be at least 3 seconds long.
            <VideoFilesDurations
              files={props.files}
              indexes={props.errors.IGStoryVideoTooShortVideoIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.IGStoryVideoTooLongVideoIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.IGStoryVideoTooLongVideoIndexes
            )})  are more than the Instagram Story maximum of 60 seconds long.`}
            type={AlertType.error}
          >
            Instagram stories must be no more than 60 seconds long.
            Alternatively, set the post to manual posting and you can post it
            manually, cropping it at upload in the Instagram app.
            <VideoFilesDurations
              files={props.files}
              indexes={props.errors.IGStoryVideoTooLongVideoIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.FBVideoTooLongVideoIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.FBVideoTooLongVideoIndexes
            )}) are more than the Facebook maximum of 240 minutes.`}
            type={AlertType.error}
          >
            Facebook requires videos to be no more than 240 minutes.
            <VideoFilesDurations
              files={props.files}
              indexes={props.errors.FBVideoTooLongVideoIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.LIVideosInCarouselIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.LIVideosInCarouselIndexes
            )}) are videos, and can not be posted to LinkedIn as a carousel.`}
            type={AlertType.error}
          >
            The LinkedIn API supports only images in carousels, so either add
            images to post an image carousel or remove video(s) to only have a
            single video.
            <VideoFilesDurations
              files={props.files}
              indexes={props.errors.LIVideosInCarouselIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.LIImagesLimit && (
          <Alert type={AlertType.error}>
            LinkedIn only supports up to 9 images in a post. Please reduce the
            number of images in this post for the LinkedIn post.
          </Alert>
        )}

        {props.errors.LIImagesUnsupportedFileExtensionIndexes && (
          <GroupedAlertMessage
            type={AlertType.error}
            title={`Files (${renderFilesIndexes(
              props.errors.LIImagesUnsupportedFileExtensionIndexes
            )}) are unsupported image type by LinkedIn`}
          >
            The LinkedIn API only supports png, jpg, and gif image types.
          </GroupedAlertMessage>
        )}
        {props.errors.LIDocumentsLimit && (
          <Alert type={AlertType.error}>
            Document posts for LinkedIn can only have one attached document, and
            cannot have more than one type of document.
          </Alert>
        )}

        {props.errors.TWImagesLimit && (
          <Alert type={AlertType.error}>
            Twitter only supports up to 4 images in a carousel. As there are no
            images in this carousel, the post will not post to Twitter.
          </Alert>
        )}

        {props.errors.TWImagesOverLimit && (
          <Alert type={AlertType.error}>
            Twitter only supports up to 4 images in a carousel. As there are
            more than 4 images in this carousel, the post will not post to
            Twitter.
          </Alert>
        )}

        {props.errors.TWVideoTooLongIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.TWVideoTooLongIndexes
            )})  are more than the Twitter maximum of 2 minutes and 20 seconds (140 seconds
            total).`}
            type={AlertType.error}
          >
            Twitter videos can be at most 2 minutes and 20 seconds (140 seconds
            total).
            <VideoFilesDurations
              files={props.files}
              indexes={props.errors.TWVideoTooLongIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.THVideoTooWideIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.THVideoTooWideIndexes
            )})  are more than the Threads maximum width of 1920px.`}
            type={AlertType.error}
          >
            Threads videos can be at most 1920px wide.
            <FilesDimensions
              files={props.files}
              indexes={props.errors.THVideoTooWideIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.errors.THVideoTooShortIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.THVideoTooShortIndexes
            )})  are less than the Threads minimum length of 1 second.`}
            type={AlertType.error}
          >
            Threads videos should be at least 1 second long.
            <FilesDimensions
              files={props.files}
              indexes={props.errors.THVideoTooShortIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.errors.THVideoTooLongIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.THVideoTooLongIndexes
            )})  are greater than the Threads maximum length of 300 seconds.`}
            type={AlertType.error}
          >
            Threads videos should be at most 5 minutes long.
            <FilesDimensions
              files={props.files}
              indexes={props.errors.THVideoTooLongIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.errors.THVideoTooBigIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.THVideoTooBigIndexes
            )})  are greater than the Threads maximum size of 1GB.`}
            type={AlertType.error}
          >
            Threads videos should be at most 1GB in size.
            <FilesDimensions
              files={props.files}
              indexes={props.errors.THVideoTooBigIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.errors.THImageTooBigIndexes && (
          <GroupedAlertMessage
            title={`Files (${renderFilesIndexes(
              props.errors.THImageTooBigIndexes
            )})  are greater than the Threads maximum size of 8MB.`}
            type={AlertType.error}
          >
            Threads images should be at most 8MB in size.
            <FilesDimensions
              files={props.files}
              indexes={props.errors.THImageTooBigIndexes}
            />
          </GroupedAlertMessage>
        )}

        {props.errors.GMBHasVideo && (
          <Alert type={AlertType.error}>
            Google only supports images (not videos) uploaded via their API, and
            so we cannot upload this post to your Google Business Profile.
          </Alert>
        )}

        {props.warnings.IGStoryItemsCount && (
          <Alert type={AlertType.warning}>
            While we support story chains up to 10 items long, we see higher
            failure rates for those over 5 items. You might want to consider{" "}
            <ExternalLink href="https://help.skedsocial.com/en/articles/3969654-how-does-manual-story-publishing-work">
              manual posting
            </ExternalLink>{" "}
            for this story chain.
          </Alert>
        )}
        {props.warnings.notSameRatioIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.notSameRatioIndexes
            )}) are not the same aspect ratio as the first image, they may be cropped by Instagram.`}
          >
            Instagram crops all posts to be the same aspect ratio as the first
            file we select, so they may appear cropped/distorted.
            <Tip>
              Tip: You may want to crop files using our photo editor before
              publishing.
            </Tip>
          </GroupedAlertMessage>
        )}
        {props.warnings.IGRatioRecommendationIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.IGRatioRecommendationIndexes
            )}) are outside of recommended aspect ratio, they may be cropped by Instagram.`}
          >
            The recommended aspect ratio is from 4:5 (eg 1080x1350) to 1.91:1
            (eg 1080x566). Files outside of these ratios may be cropped when
            published.
            <FilesDimensions
              files={props.files}
              indexes={props.warnings.IGRatioRecommendationIndexes}
            />
            <Tip>
              Tip: You may want to crop files using our photo editor before
              publishing.
            </Tip>
          </GroupedAlertMessage>
        )}
        {props.warnings.IGStoryRatioIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.IGStoryRatioIndexes
            )}) is a different aspect ratio to our story ratios`}
          >
            Aspect ratio to our story ratios for auto-publishing should be
            1836x3019, 1080x1776 or alternatively 1080x1920. You may see some
            cropping when this is auto-published. Consider cropping the story,
            or using the manual upload feature.
            <FilesDimensions
              files={props.files}
              indexes={props.warnings.IGStoryRatioIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.warnings.IGResolutionIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Assets (${renderFilesIndexes(
              props.warnings.IGResolutionIndexes
            )}) have width less than Instagram's recommended size, they may be pixelated.`}
          >
            The minimum recommended Instagram's resolution is 1080 pixels. Using
            lower resolution files may result in a pixelated, low-resolution
            post. We suggest following Instagram's recommendations for best
            quality.
            <FilesDimensions
              files={props.files}
              indexes={props.warnings.IGResolutionIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.warnings.GMBResolutionIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.GMBResolutionIndexes
            )}) are less than GMB's minimum size`}
          >
            The minimum required resolution for Google Business posts is 250x250
            pixels. You must upload higher resolution file(s) for this to post
            to Google Business Profile.
            <FilesDimensions
              files={props.files}
              indexes={props.warnings.GMBResolutionIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.warnings.THResolutionImageIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.THResolutionImageIndexes
            )}) are outside the Threads image resolution requirements`}
          >
            Threads images should be between 360 and 1440 pixels wide. Images
            will be resized by Threads Platform to fit within these dimensions.
            <FilesDimensions
              files={props.files}
              indexes={props.warnings.THResolutionImageIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.warnings.FBResolutionIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.FBResolutionIndexes
            )}) are less than Facebook's minimum ${fbMinimum} pixel width`}
          >
            Facebook's minimum is {fbMinimum} pixel width. You must upload
            higher resolution file(s) for this to post.
            <FilesDimensions
              files={props.files}
              indexes={props.warnings.FBResolutionIndexes}
            />
          </GroupedAlertMessage>
        )}
        {props.warnings.FBVideoIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.FBVideoIndexes
            )}) are videos, and may not be posted to Facebook`}
          >
            The Facebook API supports only images in carousels/albums. If you
            have only videos in this carousel, we will post the first video
            only.
          </GroupedAlertMessage>
        )}
        {props.warnings.LIVideosInCarouselIndexes?.length && (
          <GroupedAlertMessage
            type={AlertType.warning}
            title={`Files (${renderFilesIndexes(
              props.warnings.LIVideosInCarouselIndexes
            )}) are videos, and will not be posted to LinkedIn.`}
          >
            The LinkedIn API supports only images in carousels (and so we will
            only post your images) - you can post a single video post though.
          </GroupedAlertMessage>
        )}
        {props.warnings.TWHasVideoInCarousel && (
          <Alert type={AlertType.warning}>
            Twitter only supports up to 4 images in a carousel. We will only
            post the image(s) in the carousel to your Twitter account(s).
          </Alert>
        )}
        {props.warnings.TWFilesLimit && (
          <Alert type={AlertType.warning}>
            You have {props.files.length} files selected. Twitter only supports
            the first 4 images in a carousel, so we will only post the first 4
            images.
          </Alert>
        )}
        {props.warnings.GMBHasVideo && (
          <Alert type={AlertType.warning}>
            Google only supports images (not videos) uploaded via their API, and
            so your video(s) will not be included in the Google Business Profile
            post.
          </Alert>
        )}
        {props.warnings.GMBHasMoreOneImages && (
          <Alert type={AlertType.warning}>
            Google only supports (up to) one image (and no videos) in posts made
            via their API. We will publish this post to Google Business Profile
            with the first image only.
          </Alert>
        )}
        {props.warnings.THDocumentNotSupported && (
          <Alert type={AlertType.warning}>
            Threads does not support documents and it will be ignored.
          </Alert>
        )}
        {[
          props.errors.FBVideoTooShortVideoIndexes,
          props.errors.TWVideoTooShortIndexes,
        ].some(Boolean) && (
          <PlatformsCommonMessage
            facebook={props.errors.FBVideoTooShortVideoIndexes}
            twitter={props.errors.TWVideoTooShortIndexes}
          >
            {(platformsString, indexes) => (
              <GroupedAlertMessage
                title={`Files (${renderFilesIndexes(
                  indexes
                )}) are less than ${platformsString}'s minimum of 1 second.`}
                type={AlertType.error}
              >
                {platformsString} requires videos to be at least 1 seconds long.
                <VideoFilesDurations indexes={indexes} files={props.files} />
              </GroupedAlertMessage>
            )}
          </PlatformsCommonMessage>
        )}
        <TTWarningsErrors
          warnings={props.warnings}
          errors={props.errors}
          files={props.files}
        />
      </ErrorList>
    </WarningsErrorsComponentRoot>
  );
}

const TTWarningsErrors = ({
  warnings,
  errors,
  files,
}: {
  warnings: MediaFilesWarning;
  errors: MediaFilesErrors;
  files: PostAssetProps[];
}) => {
  return (
    <>
      {errors.TTVideoTooMany && (
        <Alert type={AlertType.error}>
          Tiktok only supports to upload one Video.
        </Alert>
      )}
      {errors.TTMixedPhotoVideo && (
        <Alert type={AlertType.error}>
          Tiktok does not support posting mixed photos and video.
        </Alert>
      )}
      {errors.TTUnsupportedMediaFile && (
        <Alert type={AlertType.error}>
          Tiktok does not support document files.
        </Alert>
      )}
    </>
  );
};

export const WarningsErrors = styled(WarningsErrorsComponent)``;

function GroupedAlertMessage(props: {
  type: AlertType;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Alert type={props.type} title={props.title} isExpandable>
      <GroupedAlertMessageContent>{props.children}</GroupedAlertMessageContent>
    </Alert>
  );
}

const Tip = styled.div`
  font-weight: 700;
`;

const GroupedAlertMessageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 14px;
  color: #333;

  > p,
  li,
  div {
    font-size: 14px;
    line-height: 17px;
  }
`;

const WarningsErrorsComponentRoot = styled.div``;

function FilesDimensions(props: {
  files: PostAssetProps[];
  indexes: number[];
}) {
  return (
    <FilesErrorsList>
      {props.indexes.map((index) => {
        const file = props.files[index];
        if (!file?.meta) return null;
        return (
          <li>
            File {index + 1} has dimensions {file.meta.width} x{" "}
            {file.meta.height}
          </li>
        );
      })}
    </FilesErrorsList>
  );
}

function VideoFilesDurations(props: {
  files: PostAssetProps[];
  indexes: number[];
}) {
  return (
    <FilesErrorsList>
      {props.indexes.map((index) => {
        const file = props.files[index];
        if (!file) return null;
        const duration = getMediaFileDuration(file);
        if (!duration) return null;
        return (
          <li>
            File {index + 1} is {duration} seconds in length
          </li>
        );
      })}
    </FilesErrorsList>
  );
}

function renderFilesIndexes(indexes: number[]) {
  return indexes.map((index) => index + 1).join(", ");
}

function PlatformsCommonMessage(props: {
  facebook?: number[];
  twitter?: number[];
  children(
    platformsString: React.ReactNode,
    indexes: number[]
  ): React.ReactNode;
}) {
  const { children, ...platformsFilesIndexes } = props;

  const platforms = Object.keys(platformsFilesIndexes).filter((platform) =>
    Boolean(platformsFilesIndexes[platform])
  );

  if (platforms.length === 0) {
    return null;
  }
  const platformsNameMap = {
    facebook: "Facebook",
    twitter: "Twitter",
  };

  const platformsString = platforms
    .map((platform) => platformsNameMap[platform])
    .join(" & ");

  const indexes = platformsFilesIndexes[platforms[0]];

  return <>{children(platformsString, indexes)}</>;
}

const FilesErrorsList = styled.ul`
  li {
    font-size: 14px;
  }
`;
