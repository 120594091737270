import React from "react";
import styled from "styled-components";

import { openSupportChat } from "libs/support";
import { ModalHeader, ModalContent, ModalActions } from "ui/giselle-modal";
import { Link } from "ui/Link";
import { PlatformIcon } from "entities/accounts";
import { useUserCurrency } from "entities/current-user";

import { Dots } from "../dots";
import { UpgradeToEssentials } from "./upgrade-to-essentials";
import { PlanChangeIntention } from "../../PlanChangeIntention.type";
import { useLibraryUpgradeIntentions } from "../../models/use-library-upgrade-intentions";

interface LibraryPlanSelectionProps {
  className?: string;
  onUpgrade(intention: PlanChangeIntention): void;
  onHide(): void;
}

export const LibraryPlanSelection = ({
  className,
  onUpgrade,
  onHide,
}: LibraryPlanSelectionProps) => {
  const user_currency = useUserCurrency();

  const intentions = useLibraryUpgradeIntentions();

  // TODO: handle trial
  // const isTrial =
  //   !user?.subscription.subscription ||
  //   user?.subscription.subscription.status === "in_trial";

  return (
    <Root className={className}>
      <ModalHeader
        title="Upgrade your Library"
        subtitle={
          <>
            Upgrade to Essentials to get full access to your Library.{" "}
            <Link as="button" onClick={() => openSupportChat()}>
              Contact support for help
            </Link>
            .
          </>
        }
        onHide={onHide}
      />
      <SyledModalContent>
        <Grid css="margin-top: 20px;">
          <Column>
            <UpgradeToEssentials
              css="flex-grow: 1"
              intention={intentions?.upgrade}
              onUpgrade={() => intentions && onUpgrade(intentions.upgrade)}
            />
          </Column>
        </Grid>
        <div css="margin-top: 12px;">
          <p css="margin: 0;">
            * Excludes customers with addon accounts or other plan changes.{" "}
            <Link as="button" onClick={() => openSupportChat()}>
              Contact support
            </Link>{" "}
            if you need to confirm
          </p>
          <p css="margin: 4px 0 0;">
            Your Currency is set to {user_currency.toUpperCase()}. To change to
            another currency (USD, AUD, GBP and EURO),{" "}
            <Link as="button" onClick={() => openSupportChat()}>
              contact support
            </Link>
            .
          </p>
        </div>
        <ModalActions variant="space-between" css="margin-top: 12px;">
          <Link
            as="button"
            color="neutral"
            onClick={onHide}
            underline="always"
            css="font-weight: 500"
          >
            Close
          </Link>
        </ModalActions>
        <Dots total={2} active={0} />
      </SyledModalContent>
    </Root>
  );
};

const Root = styled.div`
  min-width: 600px;
`;

const SyledModalContent = styled(ModalContent)`
  & > p {
    font-size: 14px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: 38px;
  }
`;

const Column = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const HeaderCell = styled.div`
  text-align: center;
  padding-top: 15px;
  padding-bottom: 18px;
  font-weight: 700;
  border-bottom: 1px solid #c4c4c4;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled(PlatformIcon)`
  width: 22px;
`;
