import { combine, Effect, Event, forward, guard, Store } from "effector";
import { debounce } from "patronum/debounce";

export function pagination<D, F, P>(options: {
  setPage: Event<number>;
  $currentPage: Store<number>;
  $pagesCount: Store<number>;
  $filters: Store<F>;
  getFx: Effect<
    F & P & { page: number },
    {
      list: D[];
      pagesCount: number;
    }
  >;
  filter: Store<any>;
  params: P;
  timeout?: number;
}) {
  const {
    setPage,
    $currentPage,
    $filters,
    getFx,
    $pagesCount,
    params,
    timeout = 200,
    filter,
  } = options;

  guard({
    source: debounce({
      source: combine([$currentPage, $filters], ([page, filters]) => ({
        page,
        ...filters,
        ...params,
      })),
      timeout,
    }),
    filter: filter,
    target: getFx,
  });

  forward({
    from: $filters,
    to: setPage.prepend(() => 1),
  });

  forward({
    from: getFx.doneData.map((data) => data.pagesCount),
    to: $pagesCount,
  });
}
