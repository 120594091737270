import { ReactionsChangeData } from "@inbox/entities/conversations/ui/conversation-messages/components/message-reactions";
import { ReactionsContext } from "@inbox/entities/conversations/ui/conversation-messages/message/types";
import { getAccountPlatformType } from "entities/accounts";
import moment from "moment";
import { AccountType } from "shared/types/accounts";
import {
  Conversation,
  ConversationPost,
  InterlocutorPlatformType,
  Message,
  MessageStatus,
  MessageType,
  PostPreviewIn,
} from "types/conversationInbox";

export const disableReplyForIgTaggedPostMessage =
  "To comment on this post or remove the tag, please visit Instagram directly. You can still make internal comments.";
const InboxPreferences = {
  disableSocialReplyWithMsg: (
    conversation: Conversation,
    targetAccount: AccountType
  ) => {
    const isIgPlatform = (targetAccount.platformType || "IG") === "IG";

    return conversation.type === "TAGGED_POST" && isIgPlatform
      ? disableReplyForIgTaggedPostMessage
      : undefined;
  },
  isConversationExpired: (conversation: Conversation, duration = 7) => {
    const lastRealMessage = [...(conversation?.messages || [])]
      .reverse()
      .find((msg) => !msg.isInternal);
    return (
      moment(lastRealMessage?.createdAt).isSameOrBefore(
        moment().subtract(duration, "days")
      ) && conversation.type === "MESSAGE"
    );
  },
  showTaggedInPostActionButtons: (
    post: ConversationPost,
    postPreviewIn: PostPreviewIn
  ) => {
    const allowedPlatforms = ["IG"];
    const postHasMedia = !!(post.mediaUrl || post.thumbnail);
    return (
      postHasMedia &&
      (postPreviewIn === "tagged-in-post" ||
        postPreviewIn === "mention-in-caption" ||
        postPreviewIn === "mention-in-comment") &&
      allowedPlatforms.includes(post.platform)
    );
  },
  showPreviewOwnerHandle: (account: AccountType) => {
    return ["IG", "TT"].includes(getAccountPlatformType(account));
  },
  getSmuAccountHandle: (
    platform?: InterlocutorPlatformType,
    handle?: string
  ) => {
    if (platform === "IG" && handle) {
      return new URL(handle)?.pathname?.replace("/", "");
    }
    return null;
  },
  getSumAccountAllowedShowDetails: (platform?: InterlocutorPlatformType) =>
    platform && ["IG", "FB"].includes(platform),
  allowReactionsMessageTypes: () => [
    MessageType.igMessage,
    MessageType.ttThreadedComment,
  ],
  shouldEnableReaction: (
    type: MessageType,
    reactions?: ReactionsContext,
    onReactionsChange?: (data: ReactionsChangeData) => void
  ) => {
    const allowReactionsMessageTypes = [
      MessageType.igMessage,
      MessageType.ttThreadedComment,
    ];
    return (
      allowReactionsMessageTypes.includes(type) &&
      reactions &&
      !!onReactionsChange
    );
  },
  shouldRenderMessageOptions: (
    originalMessage: Message,
    isTeamMessage?: boolean
  ) => {
    return (
      (!originalMessage?.status ||
        originalMessage?.status === MessageStatus.public ||
        originalMessage?.status === MessageStatus.success ||
        originalMessage?.status === MessageStatus.pending) &&
      !isTeamMessage &&
      !originalMessage?.deleted &&
      !!originalMessage?.id
    );
  },
};

export default InboxPreferences;
