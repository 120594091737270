//Library
export const LIBRARY_LOAD_COLLECTIONS_PAGE = "library-load-collections-page";
export const LIBRARY_CREATE_FIRST_COLLECTION =
  "library-create-first-collection";

export const LIBRARY_ADD_CREATOR = "library-add-creator";
export const LIBRARY_ADD_TAGGED_IN = "library-add-tagged-in";
export const LIBRARY_ADD_HASHTAG = "library-add-hashtag";

// Collections
export const LIBRARY_SELECT_COLLECTION = "library-select-collection";
export const LIBRARY_CREATE_COLLECTION = "library-create-collection";
export const LIBRARY_UPDATE_COLLECTION = "library-update-collection";
export const LIBRARY_DELETE_COLLECTION = "library-delete-collection";
export const LIBRARY_TOGGLE_FILTERS = "library-toggle-filters";

// Assets
export const LIBRARY_ADD_ASSETS = "library-add-assets";
export const PICKER_ADD_ASSETS = "picker-add-assets";
export const LIBRARY_BULK_MOVE_ASSETS = "library-bulk-move-assets";
export const LIBRARY_BULK_TAG_ASSETS = "library-bulk-tag-assets";
export const LIBRARY_DELETE_ASSETS = "library-delete-assets";
export const LIBRARY_CREATE_POST = "library-create-post";
export const LIBRARY_VIEW_ASSET_DETAIL = "library-view-asset-detail";
export const LIBRARY_UPDATE_ASSET = "library-update-asset";
export const LIBRARY_FILTER_ASSETS = "library-filter-assets";
export const ASSETS_IMPORTED_FRONTIFY = "assets-imported-frontify";

// Search
export const LIBRARY_SEARCH_TAGGED_IN = "library-search-tagged-in";
export const LIBRARY_SEARCH_INFLUENCER = "library-search-influencer";
export const LIBRARY_SEARCH_STOCK = "library-search-stock";
export const LIBRARY_SEARCH_HASHTAG = "library-search-hashtag";

export const LIBRARY_SELECT_HASHTAG_ACCOUNT_SUCCESS =
  "libary-select-hashtag-account-success";
export const LIBRARY_SELECT_HASHTAG_ACCOUNT_FAILURE =
  "library-select-hashtag-account-failure";

export const LIBRARY_LOAD_RECENT_MEDIA = "library-load-recent-media";
export const LIBRARY_LOAD_TOP_MEDIA = "library-load-top-media";
export const LIBRARY_LOAD_MORE_HASHTAG_MEDIA =
  "library-load-more-hashtag-media";
export const LIBRARY_ADD_HASHTAG_MEDIA_TO_COLLECTION =
  "library-add-hashtag-media-to-collection";
export const LIBRARY_VIEW_HASHTAG_MEDIA_DETAIL =
  "library-view-hashtag-media-detail";
export const LIBRARY_ACCEPT_HASHTAG_DISCLAIMER =
  "library-accept-hashtag-disclaimer";
export const INBOX_ACCEPT_HASHTAG_DISCLAIMER =
  "inbox-accept-hashtag-disclaimer";
export const INBOX_ACCEPT_HASHTAG_CREATE_POST_DISCLAIMER =
  "inbox-accept-hashtag-create-disclaimer";

// Stock actions
export const LIBRARY_ADD_STOCK_MEDIA_TO_COLLECTION = "library-add-asset-stock";
