import {
  INIT_GLOBALS,
  UPDATE_USER_PRICING_PLAN,
  UPDATE_USER_STATUSES,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { updateUserPricingPlanReducer } from "./typed-user-reducer";

const initialState = {};

const initGlobals = (state, action) => {
  if (action.payload && action.payload.user) {
    return action.payload.user;
  }
  return state;
};

const updateUserStatuses = (state, action) => {
  if (action?.payload?.user?.postStatuses) {
    return {
      ...state,
      postStatuses: action.payload.user.postStatuses,
    };
  }

  return state;
};

export const userReducer = createReducer(initialState, {
  [INIT_GLOBALS]: initGlobals,
  [UPDATE_USER_STATUSES]: updateUserStatuses,
  [UPDATE_USER_PRICING_PLAN]: updateUserPricingPlanReducer,
});
