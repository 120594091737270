import { selectHashtagFeedCollection } from "actions/sourceMedia";
import React, { useState } from "react";
import { LoadingSpinner, TooltipWrapper } from "ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollections,
  getSelectedCollection,
} from "selectors/mediaLibrarySelectors";
import styled from "styled-components";
import { CollectionSelector } from "features/media-library/components/collection-selector";
import { Button, FormSize } from "ui/ecosystems/forms";

type Props = {
  disabled: boolean;
  onSave: () => void;
  isLoading?: boolean;
};

const SaveDropdown = ({ disabled, onSave, isLoading }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const collections = useSelector(getCollections);
  const currentSelectedCollection = useSelector(getSelectedCollection);
  const [selectedCollection, setSelectedCollection] = useState(
    currentSelectedCollection
  );

  const handleChange = (collectionId: string) => {
    setSelectedCollection(collectionId);
    dispatch(selectHashtagFeedCollection(collectionId));
  };

  return (
    <Container>
      <div className="tw-mr-4">Save to:</div>
      <CollectionSelector
        collections={collections}
        selectedCollection={selectedCollection}
        onChange={handleChange}
        className={`tw-flex-auto`}
      />
      <TooltipWrapper
        tooltip={
          disabled
            ? "Select some items to save to the collection"
            : !selectedCollection
            ? "Pick a collection to save the assets to"
            : null
        }
      >
        <Button
          primary
          size={FormSize.medium}
          noLeftBorderRadius
          disabled={!selectedCollection || disabled}
          onClick={onSave}
          height={"52px"}
        >
          Save
          {isLoading && <LoadingSpinner isSmall={true} />}
        </Button>
      </TooltipWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 3;
`;

export default SaveDropdown;
