import React from "react";
import { PostAccount } from "components/Posts/Post/index";

import {
  Caption,
  DetailsTemplate,
  PostDetail,
  Tag,
  Tags,
  AltTextTags,
} from "./post-details-template";
import {
  GoogleMyBusinessTitles,
  GoogleMyBusinessTypeDetails,
} from "./google-my-business-details";
import { PinterestBoardsAndSection, PinterestTitle } from "./pinterest-details";
import { InstagramTags, InstagramCollaboratorTag } from "./instagram-details";
import { LinkedinTags } from "./linkedIn-details";

export function ClusterPostDetails({ post, accounts, page }) {
  // some posts were saved with redundant data
  const hasIG = hasPlatformType("IG", accounts, post.accountIds);
  const hasGMB = hasPlatformType("GMB", accounts, post.accountIds);
  const hasPI = hasPlatformType("PI", accounts, post.accountIds);
  const hasLI = hasPlatformType("LI", accounts, post.accountIds);

  return (
    <DetailsTemplate
      post={post}
      page={page}
      renderAccount={() =>
        accounts.map((account) => (
          <PostAccount key={account._id} account={account} />
        ))
      }
      renderDetailsList={() => (
        <>
          {hasGMB && <GoogleMyBusinessTitles post={post} />}
          {hasPI && <PinterestTitle post={post} />}
          {hasPI && (
            <PinterestBoardsAndSection post={post} accounts={accounts} />
          )}
          <Caption post={post} />
          {hasIG && <InstagramCollaboratorTag post={post} />}
          {hasIG && post.firstcomment && (
            <PostDetail label="First comment">{post.firstcomment}</PostDetail>
          )}
          {hasGMB && <GoogleMyBusinessTypeDetails post={post} />}
          <Tags>
            {post.location?.name && (
              <Tag label="Location">{post.location.name}</Tag>
            )}
            {hasIG && <InstagramTags post={post} />}
            {hasLI && <LinkedinTags post={post} />}
            {hasIG && <AltTextTags post={post} />}
          </Tags>
        </>
      )}
    />
  );
}

function hasPlatformType(type, allAccounts, accountIds = []) {
  const platformAccounts = allAccounts
    .filter((account) => {
      let { platformType } = account;
      if (!platformType) platformType = "IG";
      return platformType === type;
    })
    .reduce(
      (accounts, account) => ({
        ...accounts,
        [account._id]: account,
      }),
      {}
    );

  return accountIds.some((accountId) => Boolean(platformAccounts[accountId]));
}
