import {
  INIT_SELECTED_ACCOUNTS,
  SET_SELECTED_ACCOUNTS,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

export const initSelectedAccounts = createAction(
  INIT_SELECTED_ACCOUNTS,
  function prepare(selectedAccountIds) {
    return {
      payload: {
        selectedAccountIds,
      },
    };
  }
);

export const setSelectedAccounts = createAction(
  SET_SELECTED_ACCOUNTS,
  function prepare(selectedAccountIds) {
    return {
      payload: {
        selectedAccountIds,
      },
    };
  }
);
