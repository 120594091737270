import { GET_TAG_TEMPLATES } from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { TagTemplate } from "../../features/user/types";
import _ from "lodash";

type State = {
  tagTemplates: TagTemplate[];
};
type Action = {
  type: string;
  payload: TagTemplate[];
  error: boolean;
};

const initialState: State = {
  tagTemplates: [],
};

const collaborationReducer = createReducer(initialState, {
  [GET_TAG_TEMPLATES.SUCCESS]: (state, action: Action) => {
    return {
      ...state,
      tagTemplates: action.payload,
    };
  },
  [GET_TAG_TEMPLATES.FAILURE]: (state, action: Action) => {
    console.error(action.payload);
    return {
      ...state,
      tagTemplates: [],
    };
  },
});

export default collaborationReducer;
