import {
  generateVideoThumbnails,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Icon, Tooltip, Button } from "@dialogueconsulting/sked-ui";
import { AccountPlatformType } from "shared/types/accounts";
import { FIELDS } from "constants/PostFormFields";
import { SmallLoadingSpinner } from "ui/LoadingSpinner/LoadingSpinner";
import { Platform } from "features/post/format-post";
import { PlatformIcon } from "entities/accounts";
import { useTrackEvent } from "libs/analytics";
import { COVER_IMAGE_SELECTED } from "constants/Events";
import { toast } from "react-toastify";
import { MediaModal } from "features/media-library";
import { useOpenableState } from "libs/use-openable-state";
import { Alert, AlertType } from "../../../../ui/alert";
import { extractMediaUrls } from "../utils";

interface VideoCoverSelectorProps {
  videoUrl: string;
  isLoading: boolean;
  videoMedia: File | null;
  thumbnails: string[];
  originalCover: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateField: (field: string, value: any) => void;
  videoCoverImageTimestamp?: number; // in ms
  videoCoverPreview?: string;
  thumbnailUrl?: string;
  selectedPlatform: AccountPlatformType & "LINKED";
  platforms: Platform[];
  disabled: boolean;
}

interface UseVidoeCoverProps {
  platformPost: any;
  selectedPlatform: AccountPlatformType & "LINKED";
  platforms: Platform[];
}

export const allowedSelectCoverPlatforms: string[] = ["TT", "IG"];
export const allowedUploadCoverPlatforms: string[] = ["IG"];

const allowedPlatformFullnameMapping: Record<string, string> = {
  TT: "Tiktok",
  IG: "Instagram",
};

function resolveToastPlatformName(platform: string) {
  switch (platform) {
    case "TT":
      return "Tiktok";
    case "IG":
      return "Instagram Reel";
    default:
      return "";
  }
}

export const useVideoCover = ({
  platformPost,
  selectedPlatform,
  platforms,
}: UseVidoeCoverProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [videoMedia, setVideoMedia] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [thumbnails, setThumbnails] = useState<string[]>([]);

  const mediaFiles = useMemo(() => {
    const { mediaFiles, isVideo, url } = platformPost;

    if (Array.isArray(mediaFiles) && mediaFiles.length > 0) {
      return mediaFiles;
    }

    if (isVideo && url) {
      return [
        {
          isVideo,
          url,
        },
      ];
    }

    return [];
  }, [platformPost]);

  const showVideoSelector = useMemo(() => {
    const { isVideo, isStory, storyType } = platformPost;
    const mediaUrls = extractMediaUrls(platformPost);
    if (
      mediaUrls.length > 1 || // for more than 1 assets
      (mediaUrls.length > 0 && !isVideo) || // for image assets
      (isStory && storyType !== "reel")
    ) {
      return false;
    }

    if (selectedPlatform === "LINKED") {
      return platforms.some((platform) =>
        allowedSelectCoverPlatforms.includes(platform.TYPE)
      );
    }

    return allowedSelectCoverPlatforms.includes(selectedPlatform);
  }, [platformPost, selectedPlatform, platforms]);

  const mediaUrl = mediaFiles?.[0]?.url;

  useEffect(() => {
    if (!mediaUrl || !showVideoSelector) {
      if (videoUrl) {
        setVideoUrl("");
        setThumbnails([]);
        setVideoMedia(null);
      }
      return;
    }

    const init = async () => {
      setIsLoading(true);

      try {
        const video = await convertVideoUrlToBlob(mediaUrl);

        if (!video) {
          toast.error(
            "Fail to load the video, please remove the video and try again."
          );
          setIsLoading(false);
          return;
        }

        const thumbs = await generateVideoThumbnails(video as File, 10, "jpg");
        const res = await importFileandPreview(video as File);

        setVideoMedia(video as File);
        setThumbnails(thumbs);
        setVideoUrl(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaUrl, showVideoSelector]);

  return {
    showVideoSelector,
    isLoading,
    videoMedia,
    videoUrl,
    thumbnails,
  };
};

export const VideoCoverSelector = ({
  videoUrl,
  isLoading: isLoadingVideo,
  videoMedia,
  thumbnails,
  updateField,
  videoCoverImageTimestamp,
  videoCoverPreview = "",
  originalCover = "",
  thumbnailUrl,
  selectedPlatform,
  platforms,
  disabled,
}: VideoCoverSelectorProps) => {
  const trackEvent = useTrackEvent();
  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [cover, setCover] = useState(videoCoverPreview);
  const [isEditing, setIsEditing] = useState(false);

  const isLoading = isLoadingVideo || isLoadingThumb;
  const allowUpload = useMemo(() => {
    if (!videoUrl) {
      return false;
    }

    if (allowedUploadCoverPlatforms.includes(selectedPlatform)) {
      return true;
    }

    return platforms
      .filter((platform) => allowedSelectCoverPlatforms.includes(platform.TYPE))
      .every((platform) => allowedUploadCoverPlatforms.includes(platform.TYPE));
  }, [selectedPlatform, platforms, videoUrl]);

  useEffect(() => {
    if (isLoadingVideo) {
      return;
    }

    if (!videoUrl) {
      if (originalCover) {
        setCover("");
        updateField(FIELDS.VIDEO_COVER_ORIGINAL, "");
        updateField(FIELDS.VIDEO_COVER_PREVIEW, "");
        updateField(FIELDS.VIDEO_COVER_IMAGE_TIMESTAMP, 0);
      }

      return;
    }

    const getOriginalCover = async () => {
      setIsLoadingThumb(true);
      try {
        let thumb;

        if (videoCoverPreview) {
          thumb = videoCoverPreview;
          // FOR IG upload cover
        } else if (thumbnailUrl && videoCoverImageTimestamp === -1) {
          thumb = thumbnailUrl;
        } else {
          thumb = await getVideoPreviewImage(
            videoUrl,
            (videoCoverImageTimestamp || 0) / 1000
          );
        }

        setCover(thumb);
        updateField(FIELDS.VIDEO_COVER_ORIGINAL, thumb);
        updateField(FIELDS.VIDEO_COVER_PREVIEW, thumb);
      } catch (error) {
        console.log(error);

        toast.error(
          <ToastWrap>
            <Icon name="x-circle" size={18} color="#FA4F38" />
            <span>{`There was an issue selecting your cover, please try again or contact Support.`}</span>
          </ToastWrap>
        );
      } finally {
        setIsLoadingThumb(false);
      }
    };

    if (!originalCover) {
      getOriginalCover();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    originalCover,
    videoUrl,
    isLoadingVideo,
    thumbnailUrl,
    videoCoverImageTimestamp,
  ]);

  const onCoverChanged = (
    cover: string,
    timestampInMs: number,
    message?: string
  ) => {
    setCover(cover);
    updateField(FIELDS.VIDEO_COVER_PREVIEW, cover);
    updateField(FIELDS.VIDEO_COVER_IMAGE_TIMESTAMP, timestampInMs);

    if (message) {
      toast.success(
        <ToastWrap>
          <Icon name="check-circle-broken" size={18} color="#fff" />
          <span>{message}</span>
        </ToastWrap>
      );
    }
    trackEvent({
      eventName: COVER_IMAGE_SELECTED,
      metadata: {
        platforms,
        selected: "Frame", //TODO: Can be Frame or Upload in the future
      },
      useServices: ["AMP"],
    });
  };

  const onCoverUploaded = (url: string) => {
    setCover(url);
    updateField(FIELDS.VIDEO_COVER_PREVIEW, url);
    updateField(FIELDS.THUMBNAILURL, url);
    // FOR IG upload cover
    updateField(FIELDS.VIDEO_COVER_IMAGE_TIMESTAMP, -1);
  };

  const displayPlatforms = platforms.filter((platform) =>
    allowedSelectCoverPlatforms.includes(platform.TYPE)
  );

  const platformFullname =
    allowedPlatformFullnameMapping[selectedPlatform] || "";

  return (
    <Wrap>
      {isSafariLikeUserAgent() && (
        <Alert
          type={AlertType.warning}
          icon={true}
          isExpandable={true}
          title="Safari Compatibility Issue"
          defaultExpanded={false}
        >
          Looks like you are using Safari. The cover selection might not work
          properly due to technical limitations. Please use a different browser
          e.g. Google Chrome or Firefox. Contact support if you have any
          questions.
        </Alert>
      )}
      <Head>
        <Title>Cover</Title>
        {!isEditing &&
          selectedPlatform === "LINKED" &&
          displayPlatforms.map((platform) => (
            <PlatformIcon
              key={platform.TYPE}
              type={platform.NAME}
              css="width: 14px; height: 14px; margin-left: 5px;"
            />
          ))}
        {isEditing && (
          <Tooltip
            text="Drag or click on a thumbnail and click 'done' to finalize your selection."
            side="right"
            textSize="12px"
            contentWidth="450px"
            container={
              document.querySelector(
                ".modal-giselle.modal-dialog"
              ) as HTMLElement
            }
          >
            <div style={{ position: "relative" }}>
              <Icon name="alert-circle" color="#333333" size={16} />
            </div>
          </Tooltip>
        )}
      </Head>

      {isEditing && (
        <VideoFrameSelector
          videoUrl={videoUrl}
          thumbnails={thumbnails}
          media={videoMedia}
          isLoading={isLoading}
          onClose={() => setIsEditing(false)}
          onChange={(cover: string, timestamp: number) =>
            onCoverChanged(
              cover,
              timestamp,
              `${platformFullname} cover updated.`
            )
          }
        />
      )}

      {!isEditing && (
        <Content>
          {isLoading ? (
            <Cover>
              <SmallLoadingSpinner />
            </Cover>
          ) : (
            <Cover>
              {cover || originalCover ? (
                <img src={cover || originalCover} alt="video-cover" />
              ) : (
                <Icon name="image-01" color="#333333" size={16} />
              )}
            </Cover>
          )}
          {!disabled && (
            <Actions>
              {videoMedia && (
                <Button
                  hierarchy="secondary"
                  onClick={() => setIsEditing(true)}
                  text="Select cover"
                />
              )}
              {allowUpload && (
                <VideoCoverUploadButton
                  onUpload={onCoverUploaded}
                  platformName={resolveToastPlatformName(selectedPlatform)}
                />
              )}
              {cover && cover !== originalCover && (
                <Button
                  hierarchy="tertiary"
                  onClick={() => {
                    onCoverChanged(
                      originalCover,
                      videoCoverImageTimestamp || 0,
                      `The ${platformFullname} video cover has been reverted to original.`
                    );
                  }}
                  text="Revert to original"
                />
              )}
            </Actions>
          )}
        </Content>
      )}
    </Wrap>
  );
};
function isSafariLikeUserAgent() {
  return (
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome")
  );
}
function VideoCoverUploadButton({
  onUpload,
  platformName,
}: {
  onUpload: (url: string) => void;
  platformName: string;
}) {
  const MediaLibraryModal = useOpenableState();

  const onSelect = ([media]: any[]) => {
    if (media.type !== "image") {
      toast.error("Selected media is not an image.");
      return;
    }

    if (media?.meta?.width < 1080) {
      toast.error(
        "Selected image has width less than Instagram's recommended size, they may be pixelated."
      );
      return;
    }

    onUpload(media?.url);

    toast.success(`The ${platformName} cover has been successfully updated.`);
    MediaLibraryModal.close();
  };

  return (
    <>
      <MediaModal
        show={MediaLibraryModal.isOpened}
        onHide={MediaLibraryModal.close}
        limit={1}
        onSelect={onSelect}
        selectedAccountIds={[]}
      />
      <Button
        hierarchy="tertiary"
        onClick={MediaLibraryModal.open}
        text="Upload cover"
      />
    </>
  );
}

const WIDTH = 64;
const HEIGHT = 112;

const VideoFrameSelector = ({
  media,
  isLoading,
  onChange,
  onClose,
  videoUrl,
  thumbnails,
}: {
  media: File | null;
  isLoading: boolean;
  onChange: (cover: string, timestampInMs: number) => void;
  onClose: () => void;
  videoUrl: string;
  thumbnails: string[];
}) => {
  const [, setVideo] = useState<File | null>(null);
  const [videoThumb, setVideoThumb] = useState<string>("");
  const [sliderX, setSliderX] = useState<number | null>(null);
  const [duration, setDuration] = useState<number>(0); // in second;
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const sliderRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (media) {
      setFile(media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startDrag = (e: React.MouseEvent | React.TouchEvent): void => {
    isDragging.current = true;
    updateValue(e);
  };

  const stopDrag = async (): Promise<void> => {
    isDragging.current = false;
    if (videoUrl && videoDuration) {
      const thumb = await getVideoPreviewImage(videoUrl, videoDuration);
      setVideoThumb(thumb);
    }
  };

  const updateValue = (e: React.MouseEvent | React.TouchEvent): void => {
    const { left, width } = sliderRef?.current?.getBoundingClientRect() || {};

    if (!isDragging.current || !left || !width) return;

    let clientX = 0;
    if ("touches" in e) {
      clientX = e.touches[0].clientX;
    } else {
      clientX = e.clientX;
    }

    if (clientX - left + 2 - WIDTH / 2 <= 0) {
      setVideoDuration(0);
      return;
    }
    if (clientX - left - 2 + WIDTH / 2 >= width) {
      setVideoDuration(duration);
      return;
    }

    const newValue = Math.max(0, Math.min(1, (clientX - left) / width)) * 100;
    setVideoDuration((newValue * duration) / 100);
    setSliderX(newValue);
  };

  const setFile = async (file: File) => {
    setVideo(null);
    setVideoThumb("");
    setSliderX(null);
    setVideoDuration(0);

    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      setDuration(video.duration);
    };

    video.src = URL.createObjectURL(file);
    videoRef.current = video;

    setVideo(file);
  };

  const onCoverSelect = () => {
    onChange?.(videoThumb, Math.floor(videoDuration * 1000));
    onClose?.();
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoDuration;
    }
  }, [videoDuration]);

  if (isLoading || thumbnails.length < 1) {
    return <SmallLoadingSpinner />;
  }

  return (
    <VideoFrameSelectorWrap>
      <FrameWrap>
        <IndicatorWrap
          ref={sliderRef}
          onMouseDown={startDrag}
          onMouseMove={updateValue}
          onMouseUp={stopDrag}
          onMouseLeave={stopDrag}
          onTouchStart={startDrag}
          onTouchMove={updateValue}
          onTouchEnd={stopDrag}
        >
          <Indicator
            style={{ left: sliderX ? `${sliderX}%` : `${WIDTH / 2}px` }}
          >
            <video
              src={videoUrl}
              height={HEIGHT}
              ref={(el) => (videoRef.current = el)}
            />
          </Indicator>
        </IndicatorWrap>
        <Thumbnails>
          {thumbnails.map((item, key) => {
            return (
              <Thumbnail key={key}>
                <img
                  src={item}
                  onClick={() => {
                    setVideoThumb(item);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                />
              </Thumbnail>
            );
          })}
        </Thumbnails>
      </FrameWrap>
      <FrameActionWrap>
        <Button
          hierarchy="primary"
          onClick={onCoverSelect}
          text="Done"
          disabled={videoThumb === ""}
        />
        <Button hierarchy="tertiary" onClick={onClose} text="Cancel" />
      </FrameActionWrap>
    </VideoFrameSelectorWrap>
  );
};

const Wrap = styled.div`
margin: 16px 0;
}
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const Title = styled.div`
  color: var(--Text-T01, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  margin-right: 8px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;
const Cover = styled.div`
  width: 64px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Neutral-N200, #c4c4c4);
  background: var(--Neutral-N50, #f2f2f2);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Actions = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VideoFrameSelectorWrap = styled.div`
  display: flex;
`;

const FrameWrap = styled.div`
  position: relative;
  width: ${WIDTH * 10}px;
`;

const FrameActionWrap = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`;

const Thumbnails = styled.div`
  width: ${WIDTH * 10}px;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Neutral-N100, #e6e6e6);
  height: ${HEIGHT}px;
  overflow: hidden;
`;

const Thumbnail = styled.div`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: ${HEIGHT}px;
    object-fit: cover;
  }
`;

const IndicatorWrap = styled.div`
  width: 100%;
  height: ${HEIGHT}px;
  position: absolute;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
`;

const Indicator = styled.div`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  background: transparent;
  border: 3px solid #ddd;
  position: relative;
  left: 0;
  cursor: pointer;
  background: blue;
  transform: translateX(-50%);
  border-radius: 4.125px;
  border: 4px solid var(--Primary-P500, #4f31ff);
  background: var(--Text-T01, #333);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export function getVideoPreviewImage(
  videoUrl: string,
  timestamp: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    // Dynamically create a video element
    const videoElement = document.createElement("video");
    videoElement.style.display = "none"; // Hide the video element
    videoElement.crossOrigin = "Anonymous";
    document.body.appendChild(videoElement); // Append it to the body to ensure it can load and play

    // Dynamically create a canvas element
    const canvasElement = document.createElement("canvas");
    const context = canvasElement.getContext("2d");

    videoElement.addEventListener("loadedmetadata", () => {
      // Seek to the specified timestamp once the video metadata is loaded
      videoElement.currentTime = Math.min(timestamp, videoElement.duration);
    });

    videoElement.addEventListener("seeked", () => {
      // Ensure the canvas size matches the video frame size
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;

      // Draw the video frame to the canvas
      context?.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );

      // Convert the canvas to a data URL and resolve the promise with it
      resolve(canvasElement.toDataURL());

      // Clean up by removing the video and canvas elements
      videoElement.remove();
      canvasElement.remove();
    });

    videoElement.addEventListener("error", () => {
      // Clean up in case of error
      videoElement.remove();
      canvasElement.remove();
      reject("Error loading video");
    });

    // Set the video source to trigger loading
    videoElement.src = videoUrl;
    videoElement.load(); // Explicitly load the video
  });
}

export const convertVideoUrlToBlob = async (url: string) => {
  try {
    // Fetch the video from the URL
    const response = await fetch(url);
    // Ensure the fetch was successful
    if (!response.ok) {
      throw new Error(`Failed to fetch video: ${response.statusText}`);
    }
    // Convert the video data to a Blob object
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error converting video URL to Blob:", error);
    return Promise.resolve(undefined);
  }
};

const ToastWrap = styled.div`
  display: flex;
  span {
    margin-left: 10px;
  }
`;
