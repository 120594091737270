import React from "react";
import styled from "styled-components";

import { Checkbox } from "ui/ecosystems/forms";
import { OptionBrandedType } from "../types";
import {
  doesAccountNeedApiReconnect,
  isAccountPostingAndInsightsEnabled,
} from "entities/accounts";

export const SelectMultipleOption = (props: {
  children?: JSX.Element;
  data: OptionBrandedType;
  isSelected: boolean;
  selectOption: (value: OptionBrandedType) => void;
}): JSX.Element => {
  const { children, data, isSelected, selectOption } = props;

  const isInGroup = /->/gi.test(data.inputValue);
  const handleClick = () => {
    selectOption(data);
  };

  const needReconnection = !(
    doesAccountNeedApiReconnect(data.account) ||
    isAccountPostingAndInsightsEnabled(data.account)
  );

  return (
    <Container
      isSelected={isSelected}
      isInGroup={isInGroup}
      onClick={handleClick}
    >
      <Checkbox checked={isSelected} readOnly={true} />
      {children}
      {needReconnection && (
        <ReconnectionWrapper>Requires reconnection</ReconnectionWrapper>
      )}
    </Container>
  );
};

const ReconnectionWrapper = styled.div`
  padding: 10px 12px;
  color: #fa4f38;
  background-color: #fef1ef;
  flex-shrink: 0;
  border-radius: 5px;
  font-size: 14px;
  line-height: 17px;
`;

const Container = styled.div<{ isSelected: boolean; isInGroup: boolean }>`
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: ${(props) => (props.isInGroup ? "8px 12px 8px 40px" : "8px 12px")};
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.isSelected ? "rgba(222, 235, 255, 0.6)" : "#deebff"};
  }
  label > div:before {
    content: "";
  }
`;
