import axios, { CancelToken } from "axios";
import {
  getPostDataFromTypedPost,
  Post,
  PostApiInput,
  normalizePlatformType,
} from "features/post";
import { skedApi } from "./_skedApi";

const apiHashtagUrl = "https://hashtag.skedsocial.com/recommend";

interface MediaFileApiInput {
  isVideo?: boolean;
  originalUrl?: boolean;
  url?: string;
  thumbnailUrl: string;
  fileName?: string;
  type?: string;
  meta?: object;
  altText?: string;
}

type PostApiInput = MediaFileApiInput & {
  caption: string;
  mediaFiles: MediaFileApiInput[];
  location: unknown;
  // instagram field
  firstcomment?: string;
  tags?: unknown[];
  productsTags?: [];
  meta?: object;
};

type DraftApiInput = {
  _id: string;
  accountIds: string[];
  schedule: PostApiInput;
};

type ScheduledPostApiInput = PostApiInput & {
  _id: string;
  accountIds: string[];
};

export async function getPostById(postId: string) {
  const { data } = await skedApi.get(`/sked/posts/${postId}`);
  return {
    ...data,
    accountIds: data.accountIds || [data.accountId],
  };
}

export async function createDraft(post: Post) {
  const data = parseDataForCreatingPost(post, "draft");
  await axios.post(`/schedules`, data);
}

export async function createScheduledPost(post: Post) {
  const data = parseDataForCreatingPost(post, "schedule");
  await axios.post(`/schedules`, data);
}

export async function createQueuedPost(post: Post) {
  const data = parseDataForCreatingPost(post, "queue");
  await axios.post(`/schedules`, data);
}

export function schedulesValidate(data: {
  accountIds: [];
  schedules: [];
  timezone: string;
  uploader: string;
}): Promise<any> {
  return axios.post(`/schedules/validate`, data).catch((err) => {
    throw err.isAxiosError ? err.response.data.error : err;
  });
}
export async function editDraftValidation(post: Post): Promise<void> {
  const data = postToDraftApiInput(post);
  await axios.put(`/drafts/${post._id}/validate`, data);
}

export async function editDraft(
  post: Post,
  { validate }: { validate: boolean } = { validate: true }
): Promise<void> {
  const data = postToDraftApiInput(post);
  validate && (await editDraftValidation(post));
  await axios.put(`/drafts/${post._id}`, data);
}

export async function moveDraftToSchedule(post) {
  try {
    const data = postToDraftApiInput(post);
    await axios.put(`/drafts/${post._id}/schedule`, data);
  } catch (error) {
    throw error.response.data.error;
  }
}

export async function moveQueueToDraft(postId: string) {
  try {
    const { data } = await axios.post(`/queue/${postId}/draft`);
    return data.newId;
  } catch (error) {
    throw error.response.data.error;
  }
}

export async function moveScheduleToDraft(postId: string): Promise<string> {
  try {
    const { data } = await axios.post(`/schedules/${postId}/draft`);
    return data.newId;
  } catch (error) {
    throw error.response.data.error;
  }
}

export async function moveQueueToSchedule(
  post: Post
): Promise<{ data: string[] }> {
  const data = postToScheduledPostApiInput(post);
  return await axios.put(`/queue/${post._id}/schedule`, data);
}

export async function editScheduledPost(post: Post): Promise<string[]> {
  const data = postToScheduledPostApiInput(post);
  const { data: ids } = await axios.put(`/schedules/${post._id}`, data);
  return ids;
}

export async function editQueuedPost(post: Post): Promise<void> {
  const data = postToScheduledPostApiInput(post);
  await axios.put(`/queue/${post._id}`, data);
}

export async function moveDraftToQueue(postId: string | string[]) {
  await axios.post(`/drafts/bulk/queue`, {
    postIds: Array.isArray(postId) ? postId : [postId],
  });
}

export async function moveScheduledPostToQueue(postId: string | string[]) {
  const { data } = await axios.post(`/posts/bulk/queue`, {
    postIds: Array.isArray(postId) ? postId : [postId],
  });

  return data;
}

function parseDataForCreatingPost(post, state) {
  return {
    accountIds: post.accountIds,
    schedules: [
      {
        actionType: state,
        ...getPostDataFromTypedPost(post),
      },
    ],
  };
}

function postToDraftApiInput(post: Post): DraftApiInput {
  return <DraftApiInput>{
    _id: post._id,
    // how this works previously??
    accountIds: post.accountIds,
    schedule: getPostDataFromTypedPost(post),
  };
}

function postToScheduledPostApiInput(post) {
  post.platformType = normalizePlatformType(post.platformType);

  return {
    _id: post._id,
    accountIds: post.accountIds,
    ...getPostDataFromTypedPost(post),
  };
}

export interface HashtagRecommendation {
  hashtag: string;
  metrics: {
    engagement: number;
    popularity: number;
    relevancy: Record<string, number>;
  };
}

export async function getRecommendedTags(
  data: {
    hashtags: string[];
  },
  cancelToken?: CancelToken
) {
  const response = await axios.request<{
    recommendation?: HashtagRecommendation[];
  }>({
    method: "post",
    url: apiHashtagUrl,
    data,
    cancelToken,
  });
  return response.data.recommendation ?? [];
}

export async function markTagNegative(
  data: { hashtags: string[]; irrelevantHashtag: string },
  cancelToken?: CancelToken
) {
  const response = await axios.request<{
    recommendation?: HashtagRecommendation[];
  }>({
    method: "post",
    url: `${apiHashtagUrl}/negative`,
    data,
    cancelToken,
  });
  return response.data.recommendation ?? [];
}

export async function markTagPositive(
  data: { hashtags: string[]; relevantHashtag: string },
  cancelToken?: CancelToken
): Promise<void> {
  await axios.request({
    method: "post",
    url: `${apiHashtagUrl}/positive`,
    data,
    cancelToken,
  });
}

interface checkOverlappingPostsArguments {
  accountIds: string[];
  date: string;
  postId: string | undefined;
}

export async function checkOverlappingPosts({
  accountIds,
  date,
  postId,
}: checkOverlappingPostsArguments): Promise<any> {
  const { data } = await axios.get("/instagram/checktime", {
    params: { accountIds, date, postId },
  });

  return data;
}
