import React, { useState, useEffect, useMemo } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";

const YouTubeCategory = ({
  post,
  selectedPlatform,
  platforms,
  selectedAccounts,
  disabled = false,
  onChange,
  errors,
  className = "",
}) => {
  const [selected, setSelected] = useState(
    post && post[FIELDS.YT_CATEGORY] ? post[FIELDS.YT_CATEGORY] : null
  );

  let accountCategories = [];
  selectedAccounts.map((account) => {
    if (accountCategories.length === 0 && account.availableCategories) {
      accountCategories = account.availableCategories;
    }
  });
  let categories = [];
  accountCategories.forEach((category) => {
    categories.push({
      value: category.id,
      label: category.snippet.title,
    });
  });
  useEffect(() => {
    setSelected(post[FIELDS.YT_CATEGORY]);
  }, []);

  const handleCategoryChange = (item) => {
    setSelected(item);
    onChange(item);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Video category
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.YT_CATEGORY}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <Select
        value={selected}
        options={categories}
        onChange={handleCategoryChange}
        disabled={disabled}
      />
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.YT_CATEGORY]}
        errors={errors}
      />
    </div>
  );
};

export default YouTubeCategory;
