const range = (start, end) => {
  let values = [];
  for (; start <= end; start++) {
    values.push(start);
  }
  return values;
};

export const makePages = (currentPage, lastPage) => {
  let pages = [];

  if (lastPage === 1) {
    return [1];
  }

  if (lastPage <= 10) {
    return range(1, lastPage);
  }

  if (currentPage <= 4) {
    return [...range(1, 5), { placeholder: true }, lastPage];
  }

  pages = [1, { placeholder: true }];

  if (lastPage - currentPage <= 4) {
    return [...pages, ...range(lastPage - 5, lastPage)];
  }

  return [
    ...pages,
    ...range(currentPage - 2, currentPage + 2),
    { placeholder: true },
    lastPage,
  ];
};
