import styled, { css } from "styled-components";

const Link = styled.a<{
  color?: keyof typeof LinkColors;
  underline?: "never" | "hover" | "always";
}>`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  color: #4a47f1;
  text-decoration: ${(props) =>
    props.underline === "always" ? "underline" : "none"};

  &:hover {
    color: #340aea;
    text-decoration: ${(props) =>
      props.underline === "never" ? "none" : "underline"};
  }

  &:disabled,
  &:disabled:hover {
    background: transparent !important;
    color: #b3b3b3 !important;
    text-decoration: ${(props) =>
      props.underline === "always" ? "underline" : "none"};
  }

  ${(props) => props.color && LinkColors[props.color]}
`;

const LinkColors = {
  primary: css`
    color: #4a47f1;
    &:hover {
      color: #340aea;
    }
  `,
  info: css`
    color: #4299e1;
    &:hover {
      color: #3182ce;
    }
  `,
  success: css`
    color: #66b913;
    &:hover {
      color: #38a169;
    }
  `,
  warning: css`
    color: #fa4e37;
    &:hover {
      color: #e6300e;
    }
  `,
  danger: css`
    color: #ff0000;
    &:hover {
      color: #ea0000;
    }
  `,
  neutral: css`
    color: #b3b3b3;
    &:hover {
      color: #9d9d9d;
    }
  `,
};

export default Link;
