/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { chunk, debounce } from "lodash";
import { BaseModal, ModalHeader } from "ui/giselle-modal";
import { TextInput } from "ui/ecosystems/forms/inputs";
import { Button } from "ui/ecosystems/forms";
import { SuggestionsPopup } from "ui/suggestions-popup";
import CloseIcon from "ui/giselle-modal/close.svg";
import {
  fetchLocations,
  fetchOrganizationLabelById,
} from "api/linkedinTargetAudience";
import { lookupLinkedInOrganization } from "api/autocomplete";
import companySizeData from "constants/linkedinAudiences/companySize.json";
import jobFunctionsData from "constants/linkedinAudiences/jobFunctions.json";
import senioritiesData from "constants/linkedinAudiences/seniorities.json";
import industriesData from "constants/linkedinAudiences/industries.json";

export type ListItem = {
  id: string;
  label: string;
};

export type ListItemWithKey = ListItem & {
  fieldKey: string;
};

export type Audiences = {
  [key: string]: ListItem[];
};

export interface TargetAudienceProps {
  showModal: boolean;
  onCloseModal: (selectedItem?: Audiences) => void;
  lists: ListItem[];
  selectedItems: Audiences;
}

export const generateAudienceLabel = (audiences: { string: ListItem[] }[]) => {
  return audiences
    .filter((audience) => Object.keys(audience)[0] !== "interfaceLocales")
    .map((audience) => {
      const audienceData = Object.values(audience)[0];

      return audienceData.map((item) => item.label);
    });
};

const LinkedinTargetAudience: React.FC<TargetAudienceProps> = ({
  showModal,
  onCloseModal,
  lists,
  selectedItems,
}) => {
  const [selectedItemToEdit, setSelectedItemToEdit] = React.useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestionList, setSuggestionList] = useState<ListItemWithKey[]>([]);
  const [selectedSuggestionItem, setSelectedSuggestionItem] =
    useState<Audiences>(selectedItems ? selectedItems : {});

  const handleAddItem = (id: string) => () => {
    setSelectedItemToEdit(id);
  };

  const debouncedSearch = useCallback(
    debounce(async (selectedItemToEdit, searchTerm) => {
      if (selectedItemToEdit === "geoLocations") {
        fetchLocations(selectedItemToEdit, searchTerm).then((data) => {
          setSuggestionList(data);
        });
      } else if (selectedItemToEdit === "organizations") {
        lookupLinkedInOrganization(searchTerm).then((data) => {
          if (data && data.urn) {
            fetchOrganizationLabelById(
              data.urn.replace("urn:li:organization:", "")
            )
              .then((data) => {
                return (
                  Object.entries(data.results)
                    // For some reason, primaryOrganizationType filed of schools is NONE
                    .filter(
                      ([, val]) =>
                        val.primaryOrganizationType === "SCHOOL" ||
                        val.primaryOrganizationType === "NONE"
                    )
                    .map(([, val]) => ({
                      fieldKey: selectedItemToEdit,
                      id: `${val.id}`.includes("urn")
                        ? val.id
                        : `urn:li:organization:${val.id}`,
                      label: val.localizedName,
                    })) as ListItemWithKey[]
                );
              })
              .then((data) => setSuggestionList(data));
          }
        });
      } else if (selectedItemToEdit === "staffCountRanges") {
        setSuggestionList(
          companySizeData
            .map((item: ListItem) => ({
              ...item,
              fieldKey: selectedItemToEdit,
            }))
            .filter((item) => item.label.toLowerCase().includes(searchTerm))
        );
      } else if (selectedItemToEdit === "seniorities") {
        setSuggestionList(
          senioritiesData
            .map((item: ListItem) => ({
              ...item,
              fieldKey: selectedItemToEdit,
            }))
            .filter((item) => item.label.toLowerCase().includes(searchTerm))
        );
      } else if (selectedItemToEdit === "jobFunctions") {
        setSuggestionList(
          jobFunctionsData
            .map((item: ListItem) => ({
              ...item,
              fieldKey: selectedItemToEdit,
            }))
            .filter((item) => item.label.toLowerCase().includes(searchTerm))
        );
      } else if (selectedItemToEdit === "industries") {
        setSuggestionList(
          industriesData
            .map((item: ListItem) => ({
              ...item,
              fieldKey: selectedItemToEdit,
            }))
            .filter((item) => item.label.toLowerCase().includes(searchTerm))
        );
      }
    }, 500),
    []
  );

  useEffect(() => {
    const searchTermTrimmedAndLowerCased = searchTerm.trim().toLowerCase();

    if (searchTermTrimmedAndLowerCased.length > 0) {
      setSuggestionList([]);
      debouncedSearch(selectedItemToEdit, searchTermTrimmedAndLowerCased);
    }
  }, [debouncedSearch, selectedItemToEdit, searchTerm]);

  const handleInputChange = (value: string) => setSearchTerm(value);
  const handleInputBlur = () => {
    if (searchTerm.length === 0) {
      setSelectedItemToEdit("");
      setSuggestionList([]);
    }
  };
  const handleOnSelectSuggestion = (suggestion: ListItemWithKey) => {
    const itemExists = selectedSuggestionItem[suggestion.fieldKey]?.find(
      (item) => item.id === suggestion.id
    );

    if (!itemExists) {
      setSelectedSuggestionItem({
        ...selectedSuggestionItem,
        [suggestion.fieldKey]: [
          ...(selectedSuggestionItem[suggestion.fieldKey] || []),
          suggestion,
        ],
      });
    }

    setSelectedItemToEdit("");
    setSearchTerm("");
  };

  const handleRemoveSuggestionItem = (itemId: string) => () => {
    const selectedSuggestionItemKeys = Object.keys(selectedSuggestionItem);
    const nextSelectedSuggestionItem = selectedSuggestionItemKeys.reduce(
      (acum: Audiences, key: string) => {
        const items = selectedSuggestionItem[key];

        if (key === "interfaceLocales") return acum;

        const nextItems = items.filter((item) => item.id !== itemId);

        return { ...acum, [key]: nextItems };
      },
      {}
    );

    setSelectedSuggestionItem(nextSelectedSuggestionItem);
  };

  const handleSave = (selectedItem?: Audiences) => () => {
    onCloseModal(selectedItem);
  };

  const filteredSuggestionList = useMemo(
    () =>
      suggestionList.filter((suggestion: ListItem) => {
        const selectedSuggestionItemValuesIds = Object.entries(
          selectedSuggestionItem
        ).reduce((acum: string[], [key, items]) => {
          if (key === "interfaceLocales") return acum;

          acum = [...acum, ...items.map((item) => item.id)];

          return acum;
        }, []);

        return !selectedSuggestionItemValuesIds.includes(suggestion.id);
      }),
    [suggestionList, selectedSuggestionItem]
  );

  return (
    <Modal show={showModal} onHide={onCloseModal}>
      <ModalHeaderStyled
        title=""
        subtitle="Target audience setting"
        onHide={() => onCloseModal(undefined)}
      />

      {Object.keys(selectedSuggestionItem).length > 0 && (
        <SelectedSuggestionItemWrap>
          {Object.entries(selectedSuggestionItem)
            .filter(([key]) => key !== "interfaceLocales")
            .map(([, items]) =>
              items.map((item) => (
                <div key={item.id}>
                  <SelectedItem>
                    {item.label}
                    <CloseIcon onClick={handleRemoveSuggestionItem(item.id)} />
                  </SelectedItem>
                </div>
              ))
            )}
        </SelectedSuggestionItemWrap>
      )}

      <Wrap>
        {(chunk(lists, 3) as typeof lists[]).map((list) => (
          <div key={list[0].id}>
            {list.map(({ id, label }) => (
              <Item key={id}>
                <div>{label}</div>
                {selectedItemToEdit !== id && (
                  <AddLabel onClick={handleAddItem(id)}>
                    + Add <span>{label}</span>
                  </AddLabel>
                )}
                {selectedItemToEdit === id && (
                  <TextInputStyled
                    placeholder={label}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    autoFocus
                    size
                  />
                )}
                {selectedItemToEdit === id &&
                  searchTerm.length > 0 &&
                  filteredSuggestionList &&
                  filteredSuggestionList.length > 0 && (
                    <SuggestionsPopupStyled
                      suggestions={filteredSuggestionList}
                      onSelect={handleOnSelectSuggestion}
                      renderSuggestion={({
                        suggestion,
                      }: {
                        suggestion: any;
                      }) => <div>{suggestion.label as string}</div>}
                    ></SuggestionsPopupStyled>
                  )}
              </Item>
            ))}
          </div>
        ))}
      </Wrap>

      <ModalFooter>
        <Button
          data-testid="saveAudience"
          primary
          onClick={handleSave(selectedSuggestionItem)}
        >
          <span>Save</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Wrap = styled.div`
  display: flex;
  gap: 30px;
  margin: 30px auto 0;

  > div {
    width: 200px;
  }
`;

const Item = styled.div`
  height: 90px;
`;

const AddLabel = styled.div`
  cursor: pointer;
  color: #4f31ff;
  font-weight: 500;
  margin: 8px 0 0 4px;

  &:hover {
    text-decoration: underline;
  }

  span {
    text-transform: lowercase;
  }
`;

const TextInputStyled = styled(TextInput)`
  margin-top: 4px;
  padding: 8px;
  font-size: 14px;
`;

const Modal = styled(BaseModal)`
  max-width: 500px;
`;

const ModalHeaderStyled = styled(ModalHeader)`
  margin: 20px 20px 0 !important;

  > div {
    margin-top: 0 !important;
    font-weight: bold;
    font-size: 16px !important;
  }
`;

const SelectedSuggestionItemWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  font-size: 12px;
  padding: 20px 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: -15px;
`;

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;

  svg {
    cursor: pointer;
    width: 10px;
  }
`;

const ModalFooter = styled.div`
  margin: 20px;
  margin-left: auto;

  button {
    font-size: 14px;
    min-width: 100px;
  }
`;

const SuggestionsPopupStyled = styled(SuggestionsPopup)`
  box-shadow: 0 5px 15px rgb(0 0 0 / 12%);
  margin-top: 2px;
  border-radius: 5px;
  z-index: 2;
  .simplebar-content > div {
    padding: 8px;
  }
`;

export default LinkedinTargetAudience;
