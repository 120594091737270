import moment from "moment";
import { put, putResolve, select, call, takeLatest } from "redux-saga/effects";

import {
  GET_MENTIONED_MEDIA_ROUTINE,
  GET_TAGGED_IN_MEDIA_ROUTINE,
  HANDLE_INVALID_IG_ACCOUNT,
  HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE,
  HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE,
  INIT_HASHTAG_FEED_ROUTINE,
  INIT_CREATOR_FEED_ROUTINE,
  GET_CREATOR_MEDIA_ROUTINE,
  INIT_MENTIONED_FEED_ROUTINE,
  INIT_TAGGED_IN_FEED_ROUTINE,
  LOAD_MORE_IG_ASSETS_ROUTINE,
  SELECT_HASHTAG_FEED_VIEW_ROUTINE,
  SELECT_IG_ACCOUNT_ROUTINE,
  SELECT_EXISTING_HASHTAG_ROUTINE,
  SELECT_NEW_HASHTAG_ROUTINE,
  SELECT_RECENT_SEARCH_CREATORS,
  SEARCH_CREATOR,
} from "constants/ActionTypes";

import api from "api/instagramApi";
import {
  getFeedMediaCursor,
  getSelectedHashtagId,
  getSelectedIgAccountId,
  getHashtag,
  getHashtagFeedView,
  getFeedCreatorUsername,
  getSelectedCreator,
} from "selectors/hashtagFeedSelectors";
import { getPage } from "selectors/mediaLibrarySelectors";
import { getAccount } from "selectors/skedCoreSelectors";
import { hideModal } from "actions/modal";
import {
  selectExistingHashtag,
  getRecentHashtags,
  getHashtagFeedMedia,
} from "actions/sourceMedia";
import { changeLibraryPage } from "actions/mediaLibrary";

import ngDeps from "ng-react-directives/ngr-injector";
import { HASHTAG_FEED_VIEWS, PAGES } from "constants/MediaLibrary";
import * as EVENTS from "constants/Events";

import { handleError } from "utils/handleError";

const igApi = new api();

export function* initHashtagFeed(action) {
  const { accountId } = action.payload;
  const selectedAccount = yield select((state) => getAccount(state, accountId));
  yield putResolve(changeLibraryPage(PAGES.HASHTAGS));
  yield putResolve(getRecentHashtags(selectedAccount._id));
}

export function* initCreatorFeed(action) {
  const { accountId, user } = action.payload;
  let selectedCreator = yield select((state) => getSelectedCreator(state));

  try {
    if (!user && !selectedCreator) {
      yield call(selectRecentSearchCreators, {
        payload: { selectedIgAccountId: accountId },
      });
      return;
    }

    yield putResolve(changeLibraryPage(PAGES.CREATOR));

    yield put({
      type: GET_CREATOR_MEDIA_ROUTINE.REQUEST,
      payload: action.payload,
    });

    const creator = user || selectedCreator;

    const mediaResp = yield call(
      igApi.searchCreators,
      accountId,
      creator.userName
    );
    yield put({
      type: GET_CREATOR_MEDIA_ROUTINE.SUCCESS,
      payload: {
        igPosts: mediaResp.media.data,
        cursor: mediaResp.media?.paging?.cursors?.after || null,
        creator,
      },
    });
  } catch (err) {
    yield put({
      type: GET_CREATOR_MEDIA_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* initMentionedFeed(action) {
  const { accountId } = action.payload;
  yield put(changeLibraryPage(PAGES.MENTIONED_IN));
  yield call(getMentionedMediaSaga, { payload: { accountId: accountId } });
}

export function* initTaggedInFeed(action) {
  const { accountId } = action.payload;
  yield put(changeLibraryPage(PAGES.TAGGED_IN));
  yield call(getTaggedInMediaSaga, { payload: { accountId: accountId } });
}

export function* getMentionedMediaSaga(action) {
  yield put({
    type: GET_MENTIONED_MEDIA_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    const { accountId } = action.payload;
    const mediaResp = yield call(igApi.lookupMentionedMedia, accountId);

    yield put({
      type: GET_MENTIONED_MEDIA_ROUTINE.SUCCESS,
      payload: { igPosts: mediaResp.data.data, cursor: mediaResp.data.cursor },
    });
  } catch (err) {
    yield put({
      type: GET_MENTIONED_MEDIA_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* getHashtagMediaSaga(action) {
  const { eventService } = ngDeps;
  const viewType = yield select(getHashtagFeedView);

  if (viewType === HASHTAG_FEED_VIEWS.RECENT) {
    eventService.trackEvent({
      eventName: EVENTS.LIBRARY_LOAD_RECENT_MEDIA,
      useServices: ["AMP"],
    });
  } else {
    eventService.trackEvent({ eventName: EVENTS.LIBRARY_LOAD_TOP_MEDIA });
  }

  yield put({
    type: HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.REQUEST,
    payload: action.payload,
  });
  try {
    const { accountId, hashtagId, filterState } = action.payload;
    const feedViewCall =
      viewType === HASHTAG_FEED_VIEWS.TOP
        ? igApi.lookupHashtagTopMedia
        : igApi.lookupHashtagRecentMedia;

    const mediaResp = yield call(feedViewCall, {
      accountId,
      hashtagId,
      filterState,
    });

    yield put({
      type: HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.SUCCESS,
      payload: { igPosts: mediaResp.data.data, cursor: mediaResp.data.cursor },
    });
  } catch (err) {
    yield put({
      type: HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* getTaggedInMediaSaga(action) {
  yield put({
    type: GET_TAGGED_IN_MEDIA_ROUTINE.REQUEST,
    payload: action.payload,
  });

  try {
    const { accountId } = action.payload;
    const mediaResp = yield call(igApi.lookupTaggedInMedia, accountId);

    yield put({
      type: GET_TAGGED_IN_MEDIA_ROUTINE.SUCCESS,
      payload: { igPosts: mediaResp.data.data, cursor: mediaResp.data.cursor },
    });
  } catch (err) {
    yield put({
      type: GET_TAGGED_IN_MEDIA_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* getRecentHashtagsSaga(action) {
  yield put({ type: HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.REQUEST });
  try {
    const { accountId } = action.payload;

    // Get tags
    const recentHashtagsResp = yield call(
      igApi.getRecentHashtagCodes,
      accountId
    );
    // (Need to handle case where user has searched hashtags with another platform)
    yield put({
      type: HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.SUCCESS,
      payload: { hashtags: recentHashtagsResp.data.data },
    });
  } catch (err) {
    yield put({
      type: HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* searchNewHashtag(action) {
  // search to see if the hashtag is already in the list
  const { accountId, hashtag, location = "library" } = action.payload;
  const { eventService } = ngDeps;

  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_SEARCH_HASHTAG,
    metadata: { hashtag, location },
  });

  try {
    let existingHashtag = yield select((state) => getHashtag(state, hashtag));

    // We will treat expired hashtags as if they don't exist
    if (existingHashtag && existingHashtag.expired === true) {
      existingHashtag = null;
    }

    let hashtagId = existingHashtag ? existingHashtag.hashtag_id : null;

    yield put({
      type: SELECT_NEW_HASHTAG_ROUTINE.REQUEST,
      payload: action.payload,
    });
    // Look up the hashtag
    if (existingHashtag === null) {
      // look up hashtag on IG
      try {
        const hashtagInfo = yield call(igApi.getHashtag, accountId, hashtag);
        hashtagId = hashtagInfo.data.data[0].id;

        yield put({
          type: SELECT_NEW_HASHTAG_ROUTINE.SUCCESS,
          payload: {
            hashtag: {
              hashtag_id: hashtagId,
              name: hashtag,
              search_date: new moment().format(),
              expired: false,
            },
          },
        });
      } catch (err) {
        yield put({
          type: SELECT_NEW_HASHTAG_ROUTINE.FAILURE,
          error: true,
          payload: err,
        });
        handleError(err);
        yield put(hideModal());

        return;
      }
    } else {
      yield put({
        type: SELECT_NEW_HASHTAG_ROUTINE.SUCCESS,
        payload: {
          hashtag: {
            id: existingHashtag.hashtag_id,
            name: existingHashtag.name,
            created: existingHashtag.search_date,
          },
        },
      });
    }

    // select and search for the new hashtag
    yield put(selectExistingHashtag(accountId, hashtagId));
  } catch (err) {
    yield put({
      type: SELECT_NEW_HASHTAG_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* loadMoreIgAssets(action) {
  const { filterState = {} } = action.payload;
  const { eventService } = ngDeps;
  const accountId = yield select(getSelectedIgAccountId);
  const hashtagId = yield select(getSelectedHashtagId);
  const page = yield select(getPage);
  const cursor = yield select((state) => getFeedMediaCursor(state, page));
  const viewType = yield select(getHashtagFeedView);

  yield put({
    type: LOAD_MORE_IG_ASSETS_ROUTINE.REQUEST,
    payload: {
      cursor,
    },
  });

  eventService.trackEvent({
    eventName: EVENTS.LIBRARY_LOAD_MORE_HASHTAG_MEDIA,
    useServices: ["AMP"],
  });

  try {
    let feedViewCall = null;
    let mediaResp = null;
    const username = yield select(getFeedCreatorUsername);

    switch (page) {
      case PAGES.HASHTAGS:
        feedViewCall =
          viewType === HASHTAG_FEED_VIEWS.TOP
            ? igApi.lookupHashtagTopMedia
            : igApi.lookupHashtagRecentMedia;
        mediaResp = yield call(feedViewCall, {
          accountId,
          hashtagId,
          filterState,
          cursor,
        });
        break;
      case PAGES.MENTIONED_IN:
        feedViewCall = igApi.lookupMentionedMedia;
        mediaResp = yield call(feedViewCall, accountId, cursor);
        break;
      case PAGES.TAGGED_IN:
        feedViewCall = igApi.lookupTaggedInMedia;
        mediaResp = yield call(feedViewCall, accountId, cursor);
        break;
      case PAGES.CREATOR:
        mediaResp = yield call(
          igApi.searchCreators,
          accountId,
          username,
          cursor
        );
    }

    const igPosts =
      page === PAGES.CREATOR ? mediaResp.media.data : mediaResp.data.data;
    const newCursor =
      page === PAGES.CREATOR
        ? mediaResp.media.paging.cursors.after
        : mediaResp.data.cursor;
    yield put({
      type: LOAD_MORE_IG_ASSETS_ROUTINE.SUCCESS,
      payload: {
        igPosts,
        cursor: newCursor,
        page,
      },
    });
  } catch (err) {
    yield put({
      type: LOAD_MORE_IG_ASSETS_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
  }
}

export function* selectIgAccountSaga(action) {
  const { accountId } = action.payload;

  yield put({
    type: SELECT_IG_ACCOUNT_ROUTINE.REQUEST,
    payload: action.payload,
  });

  // Validate account to see if it has the right token
  try {
    yield call(igApi.validateAccount, accountId);
  } catch (err) {
    console.log("err validating: ", err.response);
    yield put({ type: HANDLE_INVALID_IG_ACCOUNT });

    yield put({
      type: SELECT_IG_ACCOUNT_ROUTINE.FAILURE,
      error: true,
      payload: err.response,
    });
    handleError(err);
    return;
  }

  yield put({
    type: SELECT_IG_ACCOUNT_ROUTINE.SUCCESS,
    payload: action.payload,
  });
}

export function* selectExistingHashtagSaga(action) {
  const { accountId, hashtagId, filterState } = action.payload;

  yield put(hideModal());

  yield put(getHashtagFeedMedia(accountId, hashtagId, filterState));
}

export function* selectHashtagFeedView(action) {
  const { filterState } = action.payload;

  const accountId = yield select(getSelectedIgAccountId);
  const hashtagId = yield select(getSelectedHashtagId);

  yield put(getHashtagFeedMedia(accountId, hashtagId, filterState));
}

export function* selectRecentSearchCreators(action) {
  const { selectedIgAccountId, done } = action.payload;

  yield put({
    type: SELECT_RECENT_SEARCH_CREATORS.REQUEST,
    payload: action.payload,
  });

  try {
    const creators = yield call(
      igApi.getRecentlySearchedCreators,
      selectedIgAccountId
    );

    yield put({
      type: SELECT_RECENT_SEARCH_CREATORS.SUCCESS,
      payload: {
        creators,
      },
    });
    done && done(creators);
  } catch (err) {
    yield put({
      type: SELECT_RECENT_SEARCH_CREATORS.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
    done && done(err);
  }
}

export function* searchCreator(action) {
  const { selectedIgAccountId, searchQuery, done } = action.payload;

  yield put({
    type: SEARCH_CREATOR.REQUEST,
    payload: action.payload,
  });

  try {
    const creator = yield call(
      igApi.searchCreators,
      selectedIgAccountId,
      searchQuery
    );
    yield call(selectRecentSearchCreators, {
      payload: { selectedIgAccountId },
    });
    yield put({
      type: SEARCH_CREATOR.SUCCESS,
      payload: {
        creator,
      },
    });
    done && done(creator);
  } catch (err) {
    yield put({
      type: SEARCH_CREATOR.FAILURE,
      error: true,
      payload: err,
    });
    handleError(err);
    done && done(err);
  }
}

/// WATCHERS
export default function* sourceMediaSagas() {
  yield takeLatest(
    HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.TRIGGER,
    getHashtagMediaSaga
  );
  yield takeLatest(
    HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE.TRIGGER,
    getRecentHashtagsSaga
  );
  yield takeLatest(SELECT_NEW_HASHTAG_ROUTINE.TRIGGER, searchNewHashtag);
  yield takeLatest(LOAD_MORE_IG_ASSETS_ROUTINE.TRIGGER, loadMoreIgAssets);
  yield takeLatest(SELECT_IG_ACCOUNT_ROUTINE.TRIGGER, selectIgAccountSaga);
  yield takeLatest(
    SELECT_EXISTING_HASHTAG_ROUTINE.TRIGGER,
    selectExistingHashtagSaga
  );
  yield takeLatest(
    SELECT_HASHTAG_FEED_VIEW_ROUTINE.TRIGGER,
    selectHashtagFeedView
  );
  yield takeLatest(
    SELECT_RECENT_SEARCH_CREATORS.TRIGGER,
    selectRecentSearchCreators
  );
  yield takeLatest(SEARCH_CREATOR.TRIGGER, searchCreator);
  yield takeLatest(INIT_HASHTAG_FEED_ROUTINE.TRIGGER, initHashtagFeed);
  yield takeLatest(INIT_CREATOR_FEED_ROUTINE.TRIGGER, initCreatorFeed);
  yield takeLatest(INIT_MENTIONED_FEED_ROUTINE.TRIGGER, initMentionedFeed);
  yield takeLatest(INIT_TAGGED_IN_FEED_ROUTINE.TRIGGER, initTaggedInFeed);
}
