import React, { useState } from "react";
import styled from "styled-components";
import { BaseModal } from "ui/giselle-modal";

import { LibraryPlanSelection } from "../../components/library-plan-selection";
import { PlanChangeIntention } from "../../PlanChangeIntention.type";
import { PlanConfirm } from "../../components";
import { useChangePlan } from "../../models/change-plan.model";

interface PlansModalProps {
  show: boolean;
  onHide: () => void;
  onComplete: () => void;
}

export const UpgradeLibraryDialog = ({
  show,
  onHide,
  onComplete,
}: PlansModalProps) => {
  const [intention, setIntention] = useState<PlanChangeIntention | null>(null);

  const { isChangingPlan, changePlan } = useChangePlan();

  return (
    <ModalComponent
      show={show}
      onHide={onHide}
      onExited={() => setIntention(null)}
    >
      {intention ? (
        <PlanConfirm
          key="confirm"
          intention={intention}
          isChangingPlan={isChangingPlan}
          onConfirm={() => {
            if (intention) {
              changePlan({
                planId: intention.planId,
                ugcLibraryAddon: intention.ugcLibraryAddon,
                onComplete: onComplete,
              });
            }
          }}
          onBack={() => setIntention(null)}
        />
      ) : (
        <LibraryPlanSelection onUpgrade={setIntention} onHide={onHide} />
      )}
    </ModalComponent>
  );
};

const ModalComponent = styled(BaseModal)`
  max-width: 975px;
`;
