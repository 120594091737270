import React, { useContext } from "react";
import styled from "styled-components";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";

import { AccountType } from "shared/types/accounts";
import { getAccountDisplayName } from "../..";

interface Props {
  account: AccountType;
  children: React.ReactNode;
}

// @ts-ignore
export const AccountContext = React.createContext<AccountType>();

export function AccountProvider(props: Props): React.ReactElement {
  return (
    <AccountContext.Provider value={props.account}>
      {props.children}
    </AccountContext.Provider>
  );
}

export const AccountName = styled((props) => {
  const account = props.account || useContext(AccountContext);
  // show a tooltip if it is truncated beyond maxLength
  if (
    props.maxLength &&
    getAccountDisplayName(account)?.length > props.maxLength
  ) {
    const accountTooltip = (
      <Tooltip id="account-name-tooltip">
        {getAccountDisplayName(account)}
      </Tooltip>
    );
    return (
      <OverlayTrigger placement="top" overlay={accountTooltip}>
        <div {...props}>
          {getAccountDisplayName(account).substring(0, props.maxLength) + "…"}
        </div>
      </OverlayTrigger>
    );
  }
  return <div {...props}>{getAccountDisplayName(account)}</div>;
})`
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  font-size: 14px;
  line-height: 17px;
`;
