import {
  INIT_INBOX_PAGE_ROUTINE,
  TOGGLE_INBOX_ITEM,
  TOGGLE_ALL_INBOX_ITEMS,
  INBOX_HOTKEY,
  MOVE_INBOX_CURSOR,
  TOGGLE_OPEN_INBOX_ITEM_ROUTINE,
  REMOVE_INBOX_ITEMS_ROUTINE,
  TAG_INBOX_ITEMS_ROUTINE,
  TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE,
  ASSIGN_INBOX_ITEMS_TO_USER_ROUTINE,
  FAVORITE_INBOX_ITEMS_ROUTINE,
  COMMENT_ON_INBOX_ITEM_ROUTINE,
  ACTION_INBOX_ITEM_ROUTINE,
  TOGGLE_READ_INBOX_ITEMS_ROUTINE,
  TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

// ---------- GENERAL -------------//
export const initInboxPage = createAction(INIT_INBOX_PAGE_ROUTINE.TRIGGER);
export const inboxHotkey = createAction(INBOX_HOTKEY, function prepare(hotkey) {
  return {
    payload: {
      hotkey,
    },
  };
});
// TODO: enable inbox feature
// TODO: Add Seat
// TODO: Remove Seat

// TODO: Create Group
// TODO: Remove Group?
// TODO: Toggle Inbox

// ---------- INBOX ITEM -------------//
export const toggleInboxItem = createAction(
  TOGGLE_INBOX_ITEM,
  function prepare(id) {
    return {
      payload: {
        id,
      },
    };
  }
);
export const toggleAllInboxItems = createAction(TOGGLE_ALL_INBOX_ITEMS);

export const toggleOpenInboxItem = createAction(
  TOGGLE_OPEN_INBOX_ITEM_ROUTINE.TRIGGER,
  function prepare(item, withComments = false, autoFocusReply = false) {
    return {
      payload: {
        item,
        withComments,
        autoFocusReply,
      },
    };
  }
);

// ---------- INBOX ACTIONS -------------//
export const removeInboxItems = createAction(
  REMOVE_INBOX_ITEMS_ROUTINE.TRIGGER,
  function prepare(items) {
    return {
      payload: {
        items,
      },
    };
  }
);

export const tagInboxItems = createAction(
  TAG_INBOX_ITEMS_ROUTINE.TRIGGER,
  function prepare(items, tags) {
    return {
      payload: {
        items,
        tags,
      },
    };
  }
);

export const archiveInboxItems = (items) =>
  toggleArchiveInboxItems(items, true);
export const unachiveInboxItems = (items) =>
  toggleArchiveInboxItems(items, false);

export const toggleArchiveInboxItems = createAction(
  TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.TRIGGER,
  function prepare(items, archived = true) {
    return {
      payload: {
        items,
        archived,
      },
    };
  }
);

export const assignInboxItemsToUser = createAction(
  ASSIGN_INBOX_ITEMS_TO_USER_ROUTINE.TRIGGER,
  function prepare(items, userId) {
    return {
      payload: {
        items,
        userId,
      },
    };
  }
);

export const favoriteInboxItems = createAction(
  FAVORITE_INBOX_ITEMS_ROUTINE.TRIGGER,
  function prepare(items) {
    return {
      payload: {
        items,
      },
    };
  }
);

export const toggleInboxItemComments = createAction(
  TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE.TRIGGER,
  function prepare(item, open = true) {
    return {
      payload: {
        item,
        open,
      },
    };
  }
);

export const commentOnInboxItem = createAction(
  COMMENT_ON_INBOX_ITEM_ROUTINE.TRIGGER,
  function prepare(item) {
    return {
      payload: {
        item,
      },
    };
  }
);

export const actionInboxItem = createAction(
  ACTION_INBOX_ITEM_ROUTINE.TRIGGER,
  function prepare(item, action, payload) {
    return {
      payload: {
        item,
        action,
        payload,
      },
    };
  }
);

export const readInboxItems = (items) => toggleReadInboxItems(items, true);
export const unreadInboxItems = (items) => toggleReadInboxItems(items, false);

const toggleReadInboxItems = createAction(
  TOGGLE_READ_INBOX_ITEMS_ROUTINE.TRIGGER,
  function prepare(items, read = true) {
    return {
      payload: {
        items,
        read,
      },
    };
  }
);

// TODO: filters
// TODO: OPEN / CLOSE
// TODO: Pagination
// TODO: Text search

// ---------- INBOX SIDEBAR -------------//
// TODO: Select group?
// TODO: Select inbox?
// TODO: Add tag?

// ---------- INBOX HOTKEYS -------------//
export const moveCursor = createAction(
  MOVE_INBOX_CURSOR,
  function prepare(direction) {
    return {
      payload: {
        direction,
      },
    };
  }
);
