import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getAccountsStatistic } from "api/accountsStatistic";

export const getAccountsStatisticSelector = (state: any) =>
  state.entities.accountsStatistics.entities;

export interface AccountsStatisticPostCountType {
  drafts: number;
  scheduled: number;
  queued: number;
}

export interface AccountsStatisticExternalReviewsCountType {
  pending: number;
  approved: number;
  rejected: number;
}

export interface AccountsStatisticType {
  groupName: string;
  postsCount: AccountsStatisticPostCountType;
  externalReviewsCount: AccountsStatisticExternalReviewsCountType;
}

const accountsStatisticsAdapter = createEntityAdapter({
  selectId: (item: any) => item.groupName || "",
});

interface InitialAccountsStatisticsStateType {
  isLoading: boolean;
  ids: string[];
  entities: any;
}

const initialState = {
  // lastFetched: []
  isLoading: false,
  ids: [],
  entities: {},
} as InitialAccountsStatisticsStateType;

export const fetchAccountsStatistics = createAsyncThunk(
  "accountsStatistics/fetchAccountsStatistics",
  async ({
    accountsIds,
    groupName,
  }: {
    accountsIds: string[];
    groupName: string;
  }) => {
    try {
      const accountsStatistics = await getAccountsStatistic(accountsIds);

      return { ...accountsStatistics, groupName };
    } catch (err) {
      console.log("fetchAccountGroups err", err);
      throw err;
    }
  }
);

export const accountsStatistics = createSlice({
  name: "accountsStatistics",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // GET
    builder.addCase(
      fetchAccountsStatistics.pending,
      (state: InitialAccountsStatisticsStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchAccountsStatistics.fulfilled,
      (
        state: InitialAccountsStatisticsStateType,
        action: { payload: AccountsStatisticType }
      ) => {
        accountsStatisticsAdapter.upsertOne(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchAccountsStatistics.rejected,
      (state: InitialAccountsStatisticsStateType) => {
        state.isLoading = false;
      }
    );
  },
});

export const accountsStatisticsReducer = accountsStatistics.reducer;
export const accountsStatisticsActions = accountsStatistics.actions;
