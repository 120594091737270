import { createAction } from "@reduxjs/toolkit";
import { UPDATE_USER_PRICING_PLAN } from "constants/ActionTypes";
import { SkedPlanCodeName } from "features/upsell/types";
import { User } from "features/user";

export const updateUserPricingPlan = createAction(
  UPDATE_USER_PRICING_PLAN,
  function prepare(
    plan: SkedPlanCodeName,
    numberOfAccounts: number,
    subscription: User["subscription"]
  ): UpdateUserPricingPlanAction {
    return {
      payload: {
        plan,
        subscription,
        numberOfAccounts,
      },
    };
  }
);

export interface UpdateUserPricingPlanAction {
  payload: {
    plan: SkedPlanCodeName;
    subscription: User["subscription"];
    numberOfAccounts: number;
  };
}
