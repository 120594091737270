import React from "react";
import styled from "styled-components";
import { FIELDS } from "constants/PostFormFields";
import { SwitchField } from "./SwitchField";

export function SwitchIsStoryOrNotification({
  selectedPlatform,
  postFormValue,
  updateField,
  allDisabled,
  platforms,
  platformFields,
}) {
  return (
    <SwitchFieldStyled
      label="Switch to Instagram Story or Notification Post"
      defaultChecked={postFormValue.isStory === true}
      onChange={() => {
        updateField(FIELDS.IS_STORY, !postFormValue.isStory);
        updateField(FIELDS.PUBLISH_STORY_MANUALLY);
      }}
      disabled={
        allDisabled ||
        (selectedPlatform !== "LINKED" &&
          (!platforms || (platforms && platforms.length > 1)) &&
          (!platformFields[selectedPlatform] ||
            platformFields[selectedPlatform].unlinked !== true))
      }
    />
  );
}

const SwitchFieldStyled = styled(SwitchField)`
  margin-bottom: 1rem;
`;
