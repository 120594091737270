import React from "react";
import { useSelector } from "react-redux";

import { getFormErrors } from "selectors/postFormSelectors";
import { FIELDS } from "constants/PostFormFields";
import {
  YouTubeCategory,
  YouTubeTags,
  YouTubeTitle,
} from "components/Posts/PostForm/index";
import YouTubePrivacy from "components/Posts/PostForm/YouTubeFields/YouTubePrivacy";

const YtFields = ({
  post,
  selectedPlatform,
  platforms,
  onChange,
  className = "",
  disabled = false,
  selectedAccounts,
}) => {
  const errors = useSelector((state) => getFormErrors(state, post._id));
  return (
    <div className={className}>
      <YouTubeTitle
        className="tw-mb-4"
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={(val) => onChange(FIELDS.YT_TITLE, val)}
        errors={errors}
        disabled={disabled}
        title={"Title of the video"}
      />
      <YouTubeCategory
        className="tw-mb-4"
        platforms={platforms}
        post={post}
        selectedPlatform={selectedPlatform}
        selectedAccounts={selectedAccounts}
        onChange={(val) => onChange(FIELDS.YT_CATEGORY, val)}
        errors={errors}
        disabled={disabled}
      />
      <YouTubePrivacy
        className="tw-mb-4"
        platforms={platforms}
        post={post}
        selectedPlatform={selectedPlatform}
        selectedAccounts={selectedAccounts}
        onChange={(val) => onChange(FIELDS.YT_PRIVACY, val)}
        errors={errors}
        disabled={disabled}
      />
      <YouTubeTags
        className="tw-mb-4"
        post={post}
        platforms={platforms}
        selectedPlatform={selectedPlatform}
        onChange={(val) => onChange(FIELDS.YT_TAGS, val)}
        errors={errors}
        disabled={disabled}
        title={"Tags for the video"}
      />
    </div>
  );
};

export default YtFields;
