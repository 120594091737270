import { useEffect, useState } from "react";
import { AccountType } from "shared/types/accounts";

export enum FeatureName {
  NewAddAccountModal = "NewAddAccountModal",
  IgCollab = "IgCollab",
  ReportBuilder = "ReportBuilder",
  LIFirstComment = "LIFirstComment",
}

class LocalStorageFeatureToggle {
  set(featureName: `${FeatureName}`, value: boolean) {
    this.validateFeatureName(featureName);
    if (value) {
      window.localStorage.setItem(featureName, "1");
    } else {
      window.localStorage.removeItem(featureName);
    }
  }

  get(featureName: `${FeatureName}`) {
    return !!window.localStorage.getItem(featureName);
  }

  private validateFeatureName(featureName: string) {
    if (Object.values(FeatureName).indexOf(featureName as FeatureName) === -1) {
      throw new Error(`Invalid feature name: ${featureName}`);
    }
  }
}

class Deferred<T> {
  private resolveFunction!: (value: T | PromiseLike<T>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rejectFunction!: (reason?: any) => void;
  public promise: Promise<T>;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolveFunction = resolve;
      this.rejectFunction = reject;
    });
  }

  resolve(value: T | PromiseLike<T>) {
    this.resolveFunction(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject(reason?: any) {
    this.rejectFunction(reason);
  }
}

type AdminPanelToggleName = "enableReportBuilder";

class AdminPanelFeatureToggle {
  private toggleContextDeferred = new Deferred<Record<string, boolean>>();
  private toggleContext: Record<string, boolean> = {};
  async get(name: AdminPanelToggleName): Promise<boolean> {
    const toggles = await this.toggleContextDeferred.promise;
    return !!toggles[name];
  }
  syncGet(name: AdminPanelToggleName): boolean {
    return !!this.toggleContext[name];
  }

  public notifyToggleContext(toggles: Record<string, boolean> | undefined) {
    console.log("notifyToggleContext", toggles);
    this.toggleContext = toggles || {};
    this.toggleContextDeferred.resolve(toggles || {});
  }
  public notifyFromAccounts(users: AccountType[] | undefined) {
    /** make sure this function does not block any processing */
    setTimeout(() => {
      const found = users?.find(
        (user) => (user.platformType as string) !== "BRANDGROUPSUMMARY"
      );
      if (found?.featureToggles) {
        this.notifyToggleContext(found.featureToggles);
      } else {
        this.notifyToggleContext({});
      }
    }, 0);
  }
}

export const adminPanelFeatureToggle = new AdminPanelFeatureToggle();

export const useAdminPanelToggle = (
  name: AdminPanelToggleName,
  localToggleName?: `${FeatureName}`
) => {
  const [value, setValue] = useState<boolean | undefined>(
    adminPanelFeatureToggle.syncGet(name)
  );
  useEffect(() => {
    adminPanelFeatureToggle.get(name).then((enabled) => {
      if (enabled !== value) {
        setValue(enabled);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  if (localToggleName && localStorageFeatureToggle.get(localToggleName)) {
    return true;
  }

  return value;
};

export const localStorageFeatureToggle = new LocalStorageFeatureToggle();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).localStorageFeatureToggle = localStorageFeatureToggle;
