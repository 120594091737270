import { FBPageWithPicture, LIOrganization } from "api/autocomplete";

export type SuggestOptionTag = {
  type: "tag";
  value: string;
};

export type SuggestOptionTemplate = {
  type: "template";
  _id: string;
  name: string;
  template: string;
};

export type SuggestOptionFbPage = {
  type: "fb-page";
} & FBPageWithPicture;

export type SuggestOptionLiOrg = {
  type: "li-organization";
} & LIOrganization;

export type SuggestOptionCollaborator = {
  type: "collaborator";
  picture: string | null;
  name: string;
  id: string;
};

export type SuggestOption =
  | SuggestOptionTag
  | SuggestOptionTemplate
  | SuggestOptionFbPage
  | SuggestOptionLiOrg
  | SuggestOptionCollaborator;

export function isSuggestOptionTag(
  option: SuggestOption
): option is SuggestOptionTag {
  return option.type === "tag";
}

export function isSuggestOptionTemplate(
  option: SuggestOption
): option is SuggestOptionTemplate {
  return option.type === "template";
}

export function isSuggestOptionFbPage(
  option: SuggestOption
): option is SuggestOptionFbPage {
  return option.type === "fb-page";
}

export function isSuggestOptionLiOrg(
  option: SuggestOption
): option is SuggestOptionLiOrg {
  return option.type === "li-organization";
}
