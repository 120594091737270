import { AccountType } from "shared/types/accounts";

import { getAccountPlatformType } from "../account-platform";

export function getAccountLink(account: AccountType) {
  switch (getAccountPlatformType(account)) {
    case "IG": {
      return `https://www.instagram.com/${account.login}`;
    }
    case "FB": {
      const page = account.platformPageId || account.defaultPage?.id;
      return page ? `https://facebook.com/${page}` : null;
    }
    case "TW": {
      return `https://twitter.com/${account.login}`;
    }
    case "LI": {
      if (account.linkedinUrn?.includes("organization") && account.platformId) {
        return `https://www.linkedin.com/organization/${account.platformId}`;
      }
      if (account.linkedinVanityName) {
        return `https://www.linkedin.com/in/${account.linkedinVanityName}`;
      }
      return null;
    }
    case "PI": {
      return `https://www.pinterest.com/${account?.piData?.username}`;
    }
    case "GMB": {
      // Some GMB locations may not actually have a map URL because
      // they are not actually location based ("client site only")
      return (
        account.gmbData?.metadata?.mapsUrl ||
        account.gmbData?.metadata?.mapsUri ||
        ""
      );
    }
    case "YT": {
      return `https://www.youtube.com/channel/${account.platformId}`;
    }
    default:
      return null;
  }
}
