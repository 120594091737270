import React from "react";
import styled from "styled-components";

interface OptionStatusTypes {
  color?: string;
  label?: string;
}

export const OptionStatus = (props: OptionStatusTypes): JSX.Element => {
  const { color, label } = props;
  return (
    <>
      {color && <Circle color={color} />}
      <Label>
        <StatusLabelText>{label}</StatusLabelText>
      </Label>
    </>
  );
};

const Label = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const Circle = styled.div`
  margin-right: 7px;
  display: inline-block;
  background-color: ${({ color }) => color || "#FFF"};
  border-radius: 50%;
  width: 12px;
  min-width: 12px;
  height: 12px;
  flex-grow: 1;
`;

const StatusLabelText = styled.span`
  flex-grow: 1;
  overflow-wrap: break-word;
  font-size: 14px;
`;
