import React, { useState } from "react";
import styled, { css } from "styled-components";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
  setMessageHidden,
  setMessageStatusEditing,
  setMessageStatusRemoved,
} from "actions/conversationInbox";
import { PopupPosition } from "reactjs-popup/dist/types";
import { Message } from "types/conversationInbox";
import { TooltipWrapper } from "ui";
import { useTrackEvent } from "libs/analytics";

const MessageActionContainer = ({
  children,
  position,
  disabledWithTooltip,
}: {
  children: (closeModal: () => void) => React.ReactNode;
  position: PopupPosition;
  disabledWithTooltip?: string;
}) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  if (disabledWithTooltip) {
    return (
      <TooltipWrapper tooltip={disabledWithTooltip} placement="top">
        <IconContainer disabled>
          <Icon icon={faEllipsisV} />
        </IconContainer>
      </TooltipWrapper>
    );
  }
  return (
    <MainContainer
      className="message-more-actions-container"
      data-testid="message-more-options"
    >
      <Popup
        open={open}
        onClose={() => closeModal()}
        onOpen={() => setOpen(true)}
        trigger={
          <IconContainer>
            <Icon icon={faEllipsisV} />
          </IconContainer>
        }
        position={position}
        on="click"
        closeOnDocumentClick
        contentStyle={{ padding: "0px", border: "none" }}
        arrow={false}
      >
        <MenuContainer>{children(closeModal)}</MenuContainer>
      </Popup>
    </MainContainer>
  );
};

interface MessageMoreOptionsProps {
  conversationId: string;
  messageId: string;
  messageHidden: boolean;
  isHiddenProcessing: boolean;
  supportHiddenAction: boolean;
  supportDeleteAction?: boolean;
  isSending?: boolean;
  platform: string;
}

export const MessageMoreOptions = ({
  messageId,
  messageHidden,
  conversationId,
  isHiddenProcessing,
  supportHiddenAction,
  supportDeleteAction = true,
  isSending,
  platform,
}: MessageMoreOptionsProps): JSX.Element => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  return (
    <MessageActionContainer
      position="right top"
      disabledWithTooltip={
        isSending
          ? "Still sending... You can delete or hide once sent"
          : undefined
      }
    >
      {(closeModal: () => void) => (
        <>
          {supportDeleteAction && (
            <MenuItemAction
              data-testid="moreOption-delete-button"
              onClick={() => {
                dispatch(
                  setMessageStatusRemoved({ conversationId, id: messageId })
                );
                closeModal();
                trackEvent({
                  eventName: "comment-delete",
                  useServices: ["AMP"],
                  metadata: { platform },
                });
              }}
            >
              Delete comment on post
            </MenuItemAction>
          )}
          {supportHiddenAction && (
            <MenuItemAction
              disabled={isHiddenProcessing}
              data-testid="moreOption-hide-button"
              onClick={() => {
                if (isHiddenProcessing) {
                  return;
                }
                dispatch(
                  setMessageHidden({
                    conversationId,
                    id: messageId,
                    hidden: !messageHidden,
                  })
                );
                closeModal();
                trackEvent({
                  eventName: messageHidden ? "comment-unhide" : "comment-hide",
                  useServices: ["AMP"],
                  metadata: { platform },
                });
              }}
            >
              {resolveMessageHiddenAction({
                hidden: messageHidden,
                isHiddenProcessing,
              })}
            </MenuItemAction>
          )}
        </>
      )}
    </MessageActionContainer>
  );
};

export const GMBMessageMoreOptions = ({
  conversationId,
  message,
  position = "left",
  label,
}: {
  conversationId: string;
  message: Message;
  position: "left" | "right";
  label: string;
}) => {
  const dispatch = useDispatch();
  return (
    <MessageActionContainer position={`${position} top`}>
      {(closeModal: () => void) => (
        <>
          <MenuItemAction
            onClick={() => {
              dispatch(
                setMessageStatusRemoved({ conversationId, id: message.id })
              );
              closeModal();
            }}
          >
            {`Delete reply to ${label}`}
          </MenuItemAction>
          <MenuItemAction
            onClick={() => {
              dispatch(
                setMessageStatusEditing({
                  message,
                  action: "enable",
                })
              );
              closeModal();
            }}
          >
            {`Edit reply to ${label}`}
          </MenuItemAction>
        </>
      )}
    </MessageActionContainer>
  );
};

function resolveMessageHiddenAction({
  isHiddenProcessing,
  hidden,
}: {
  hidden?: boolean;
  isHiddenProcessing?: boolean;
}) {
  if (isHiddenProcessing) {
    return "Processing hidden message action";
  }
  if (hidden) {
    return "Unhide comment on post";
  }
  return "Hide comment on post";
}

const MenuContainer = styled.div`
  background-color: white;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
`;

const MenuItemAction = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  padding: 7px 15px;
  ${(p) => (p.disabled ? "color:gray;" : undefined)}
  &:hover {
    background-color: #eeeeee;
  }
`;

const MainContainer = styled.div`
  margin-top: 6px;
`;
const IconContainer = styled.span<{ disabled?: boolean }>`
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: -10px;
  margin-right: -10px;
  ${(p) =>
    p.disabled
      ? css`
          opacity: 0.5;
          cursor: default;
        `
      : undefined}
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: #454545;
  vertical-align: 2px;
`;
