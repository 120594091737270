import React, { FormEvent } from "react";
import styled from "styled-components";

import { Button, FormSize, SubmitButton } from "ui/ecosystems/forms";
import { Collapse } from "ui/collapse";

interface Props {
  children: React.ReactNode;
  isActionShown: boolean;
  isSubmitting: boolean;
  onSubmit(): void;
  onCancel(): void;
  disabledSave?: boolean;
}

export function SimpleFormTemplate(props: Props) {
  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit();
  }

  const disabled = props.isSubmitting;

  return (
    <AccountFormTemplateRoot>
      <form onSubmit={handleSubmit}>
        {props.children}
        <Collapse isOpened={props.isActionShown}>
          {React.useMemo(
            () => (
              <Actions>
                <Button
                  text
                  type="button"
                  onClick={props.onCancel}
                  disabled={disabled}
                >
                  Cancel
                </Button>
                <SubmitButton
                  type="submit"
                  primary
                  size={FormSize.small}
                  disabled={disabled || props.disabledSave}
                  isSubmitting={props.isSubmitting}
                >
                  Save
                </SubmitButton>
              </Actions>
            ),
            [disabled, props.disabledSave]
          )}
        </Collapse>
      </form>
    </AccountFormTemplateRoot>
  );
}

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 18px;

  align-items: center;
  justify-content: end;

  ${Button}[type="submit"] {
    width: 90px;
  }
`;

const AccountFormTemplateRoot = styled.div`
  ${Actions} {
    padding-top: 8px;
  }
`;
