import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { getPlanEstimate, getPlans, Plan } from "api/plan-change";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";
import { useCurrentUser } from "entities/current-user";

import { PlanChangeIntention } from "../PlanChangeIntention.type";

async function getLibraryUpgradeEstimates(isAnnual: boolean): Promise<{
  addLibrary: PlanChangeIntention;
  upgrade: PlanChangeIntention;
}> {
  const targetPlanIds = {
    addLibrary: isAnnual ? "sked-fundamentals-annual" : "sked-fundamentals",
    upgrade: isAnnual ? "sked-essentials-annual" : "sked-essentials",
  };

  const [plans, addLibraryEstimate, upgradeEstimate] = await Promise.all([
    getPlans(),
    getPlanEstimate({
      planId: targetPlanIds.addLibrary,
      ugcLibraryAddon: true,
    }),
    getPlanEstimate({ planId: targetPlanIds.upgrade, ugcLibraryAddon: false }),
  ]);

  const addLibraryPlan = findPlan(plans, {
    planId: targetPlanIds.addLibrary,
    isAnnual,
  });
  if (!addLibraryPlan) {
    throw Error("could not find add library plan");
  }
  const upgradePlan = findPlan(plans, {
    planId: targetPlanIds.upgrade,
    isAnnual,
  });
  if (!upgradePlan) {
    throw Error("could not find upgrade plan");
  }

  return {
    addLibrary: {
      planId: targetPlanIds.addLibrary,
      ugcLibraryAddon: true,
      plan: addLibraryPlan,
      isAnnual: isAnnual,
      estimate: addLibraryEstimate,
    },
    upgrade: {
      planId: targetPlanIds.upgrade,
      ugcLibraryAddon: false,
      plan: upgradePlan,
      isAnnual: isAnnual,
      estimate: upgradeEstimate,
    },
  };
}

export function useLibraryUpgradeIntentions() {
  const [intentions, setIntentions] = useState<{
    addLibrary: PlanChangeIntention;
    upgrade: PlanChangeIntention;
  } | null>(null);

  const user = useCurrentUser();

  // TODO: do something more reliable
  const isAnnual = user.plan?.endsWith("-annual");

  useEffect(() => {
    async function request() {
      try {
        const data = await getLibraryUpgradeEstimates(isAnnual);
        setIntentions(data);
      } catch (err) {
        console.error(err);
        setIntentions(null);
        toast.error(getUserFriendlyErrorMessage(err as Error));
      }
    }

    request();
  }, [isAnnual]);

  return intentions;
}

function findPlan(
  plans: Plan[],
  { planId, isAnnual }: { planId: string; isAnnual: boolean }
) {
  return plans.find((plan) => {
    if (isAnnual) {
      return plan.primaryCbId.annual === planId;
    } else {
      return plan.primaryCbId.monthly === planId;
    }
  });
}
