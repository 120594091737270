import React from "react";
import PropTypes from "prop-types";
import HashtagListWrapper from "./HashtagListWrapper";
import HashtagRow from "./HashtagRow";
import { useDispatch } from "react-redux";
import { showConfirmModal } from "actions/modal";
import { searchNewHashtag } from "actions/sourceMedia";
import { SELECT_NEW_HASHTAG_ROUTINE } from "constants/ActionTypes";
import { WEEKLY_HASHTAG_SEARCH_MAX } from "constants/MediaLibrary";
import { ExternalLink } from "ui/Link";

const ExpiredHashtags = ({ account, activeHashtags, expiredHashtags }) => {
  const dispatch = useDispatch();
  const showActivateHashtagConfirmModal = (hashtag) => {
    const atWeeklyMax = activeHashtags.length === WEEKLY_HASHTAG_SEARCH_MAX;
    const confirmMessage = (
      <div>
        <div className="tw-mb-8">
          Instagram limits users to 30 hashtags in a rolling 7 day period, so
          please check that it&apos;s the right tag. You can{" "}
          <ExternalLink
            href={`https://www.instagram.com/explore/tags/${hashtag.name}/`}
            type="primary"
          >
            {" "}
            preview #{hashtag.name} on Instagram
          </ExternalLink>
        </div>
        {atWeeklyMax && (
          <div className="tw-text-red-500">
            You have reached Instagram&apos;s weekly max of{" "}
            {WEEKLY_HASHTAG_SEARCH_MAX} searches. You will need to wait for the
            next active hashtag to expire before you can activate this hashtag.
          </div>
        )}
        {!atWeeklyMax && (
          <div>
            Add{" "}
            <span className="tw-font-black tw-text-purple-400">
              #{hashtag.name}
            </span>{" "}
            to reactivate hashtag search for 7 days?
          </div>
        )}
      </div>
    );

    dispatch(
      showConfirmModal({
        title: "Reactivate Hashtag",
        messageJsx: confirmMessage,
        confirmType: "primary",
        confirmText: `Add #${hashtag.name}`,
        handleConfirm: () => {
          dispatch(searchNewHashtag(account._id, hashtag.name.toLowerCase()));
        },
        disabled: atWeeklyMax,
        loadingRoutines: [SELECT_NEW_HASHTAG_ROUTINE],
      })
    );
  };

  if (expiredHashtags.length === 0) {
    return "";
  }
  return (
    <HashtagListWrapper defaultIsOpen={false} title="Expired Hashtags">
      {expiredHashtags.map((hashtag) => {
        return (
          <HashtagRow
            key={hashtag.hashtag_id}
            account={account}
            hashtag={hashtag}
            onClick={() => showActivateHashtagConfirmModal(hashtag)}
          />
        );
      })}
    </HashtagListWrapper>
  );
};

ExpiredHashtags.propTypes = {
  account: PropTypes.object,
  activeHashtags: PropTypes.array,
  expiredHashtags: PropTypes.array,
};

export default ExpiredHashtags;
