import React from "react";
import styled from "styled-components";

import { OptionBrandedType, OptionType } from "../types";
import { Checkbox } from "ui/ecosystems/forms";
import { useTrackEvent } from "libs/analytics";
import { GROUP_SELECT_POST_CREATE } from "constants/Events";

export const SelectGroupHeading = (props: {
  value: any;
  handleChange: (value: OptionBrandedType[]) => void;
  data: OptionType;
}): JSX.Element => {
  const { handleChange, value, data } = props;

  const trackEvent = useTrackEvent();

  const accountIds: [] =
    value?.map((option: OptionBrandedType) => option.value) || [];

  const isSelected =
    data.options?.filter((option) => {
      // @ts-ignore
      return accountIds.includes(option.value);
      // @ts-ignore
    }).length === data.options.length;

  const handleGroupClick = (selected: any) => {
    if (!isSelected) {
      trackEvent({ eventName: GROUP_SELECT_POST_CREATE });
    }
    const selectedIds = selected.map(
      (option: OptionBrandedType) => option.value
    );
    let updatedValue = value.filter(
      (option: OptionBrandedType) => !selectedIds.includes(option.value)
    );

    if (!isSelected) {
      updatedValue = updatedValue.concat(selected);
    }

    handleChange(updatedValue);
  };

  const handleClick = () => {
    handleGroupClick(data.options);
  };

  return (
    <GroupLabel onClick={handleClick}>
      <Checkbox checked={isSelected} readOnly={true} />
      SELECT ALL {data.label}
    </GroupLabel>
  );
};

const GroupLabel = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-items: start;
  align-items: center;
  gap: 10px;

  line-height: 19px;
  color: #999;
  cursor: default;
  font-size: 75%;
  font-weight: 500;
  margin-bottom: 0.25em;
  padding: 8px 12px;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    background-color: #deebff;
  }
  label > div:before {
    content: "";
  }
`;
