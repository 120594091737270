import { AccountAvatar, AccountProvider } from "entities/accounts";
import { AvatarSizeType } from "entities/accounts/components/account/avatar";
import React from "react";
import { AccountType } from "shared/types/accounts";
import styled from "styled-components";

export const SelectedAccountOverview = ({
  selectedAccounts,
  avatarSize = "medium",
}: {
  selectedAccounts: AccountType[];
  avatarSize?: AvatarSizeType;
}): JSX.Element => {
  const maxShowAcc = 5;
  const moreAccountCount = selectedAccounts.length - maxShowAcc;
  const first5Accounts = selectedAccounts.slice(0, maxShowAcc);
  return (
    <ListOption>
      {first5Accounts.map((account, idx) => {
        return (
          <AccountProvider key={account._id} account={account}>
            <AccountAvartarContainer
              shiftPx={resolveAvatarShift(idx, selectedAccounts.length)}
            >
              <AccountAvatar size={avatarSize} />
            </AccountAvartarContainer>
          </AccountProvider>
        );
      })}
      {moreAccountCount > 0 && (
        <MoreAccountCount>+{moreAccountCount}</MoreAccountCount>
      )}
    </ListOption>
  );
};

const resolveAvatarShift = (itemIdx: number, itemLength: number) => {
  if (itemIdx <= 0) {
    return 0;
  }
  if (itemLength < 5) {
    return 5;
  }
  if (itemLength === 5) {
    return -5;
  }

  return -12;
};

const MoreAccountCount = styled.div`
  background-color: #333333;
  color: white;
  border-radius: 10px;
  width: 20px;
  font-size: 9px;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
`;

const AccountAvartarContainer = styled.div<{ shiftPx: number }>`
  margin-left: ${(p) => p.shiftPx}px;
`;

const ListOption = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;
