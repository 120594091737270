import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  createNode,
  Domain,
  fork,
  forward,
  step,
  clearNode,
  Unit,
  Scope,
} from "effector";
import { Provider } from "effector-react/ssr";

export const ScopeContext = React.createContext<Scope | null>(null);

export function createWidget(domain: Domain): Domain {
  domain.onCreateEvent((evt) => {
    evt.graphite.seq.push(
      step.compute({
        fn(payload, scope, stack) {
          if (!stack.scope) {
            throw new Error(`event ${evt.shortName} called without scope`);
          }
          return payload;
        },
      })
    );
  });

  return domain;
}

export function createWidgetComponent<T>(
  domain: Domain,
  Component: React.FunctionComponent<T>
): React.FunctionComponent<T> {
  return function WidgetProvider(props) {
    const scope = useRef(useMemo(() => fork(domain), [])).current;
    return (
      <Provider value={scope}>
        <ScopeContext.Provider value={scope}>
          <Component {...props} />
        </ScopeContext.Provider>
      </Provider>
    );
  };
}

export function useWatch<T>(unit: Unit<T>, cb: (param: T) => void) {
  const scope = useContext(ScopeContext);

  useEffect(() => {
    return createWatch(scope, unit, cb);
  }, [unit, cb]);
}

function createWatch(scope, unit, fn) {
  const id = unit.graphite.id;
  const links = scope.additionalLinks[id] || [];
  scope.additionalLinks[id] = links;
  const node = createNode({
    node: [step.run({ fn })],
  });
  links.push(node);

  return () => {
    if (links.includes(node)) links.splice(links.indexOf(node), 1);
    clearNode(node);
  };
}
