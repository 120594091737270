import React, { useMemo } from "react";

import { FIELDS } from "constants/PostFormFields";
import { PLATFORMS } from "constants/Platforms";

import { SwitchField } from "../components/SwitchField";

export const ThreadsSwitchNotification = ({
  platforms,
  value,
  hasThreadsPosting,
  updateField,
  allDisabled,
  oldThreadsAccounts,
}: {
  platforms: any[];
  value: boolean;
  hasThreadsPosting: boolean;
  updateField: (field: string, value: any) => void;
  allDisabled: false;
  oldThreadsAccounts: any[];
}) => {
  const showSwitcher = useMemo(() => {
    const isNewTHAndOldTHOnly =
      oldThreadsAccounts.length > 0 &&
      hasThreadsPosting &&
      platforms.length === 1;

    return (
      !isNewTHAndOldTHOnly &&
      platforms.length > 0 &&
      platforms.some(({ TYPE }) => TYPE === PLATFORMS.TH.TYPE)
    );
  }, [hasThreadsPosting, oldThreadsAccounts.length, platforms]);

  if (!showSwitcher) {
    return <></>;
  }

  const onChange = () => {
    // note: this field is just "isNotification" in the postFormValue
    // so i'll have to check if it can be shared across post types
    // we will need something though most likely
    updateField(FIELDS.POST_IS_NOTIFICATION_ONLY, !value);
  };

  return (
    <SwitchField
      defaultChecked={value}
      onChange={onChange}
      disabled={allDisabled || oldThreadsAccounts.length !== 0}
    />
  );
};
