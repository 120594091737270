import React, { useContext } from "react";

import { DropdownContext } from "libs/react-dropdown-elements";
import { Item } from "../../atoms/item";

export function SelectAbleItem(props: {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  "data-testid"?: string;
  selected?: boolean;
}): React.ReactElement {
  const dropdown = useContext(DropdownContext);
  const onItemClick = () => {
    if (props.disabled) return;

    if (props.onClick) {
      props.onClick();
    }

    dropdown.close();
  };

  return (
    <Item
      data-testid={props["data-testid"]}
      selected={props.selected}
      onClick={onItemClick}
      disabled={props.disabled}
      role="option"
    >
      {props.children}
    </Item>
  );
}
