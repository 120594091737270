import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCountriesList,
  fetchCountryHolidays,
  fetchHolidayCalendars,
  fetchFromUrl,
  fetchHolidaysFromApi,
  removeCountryHolidays,
  importHolidaysFromUrl,
} from "api/calendarEvents";
import { Country, HolidaysCalendar, Holiday } from "shared/types/calendar";

export const getCountriesList = createAsyncThunk(
  "calendarFilter/getCountriesList",
  async (_, { rejectWithValue }) => {
    try {
      return await fetchCountriesList();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const importCountry = createAsyncThunk(
  "calendarFilter/importCountry",
  async (country: Country, { rejectWithValue }) => {
    try {
      return await fetchCountryHolidays(country);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHolidayCalendars = createAsyncThunk(
  "calendarFilter/getHolidayCalendars",
  async (calendarsIds: string[], { rejectWithValue }) => {
    try {
      const holidaysCalendars = await fetchHolidayCalendars();
      return { calendarsIds, holidaysCalendars };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCountryHolidays = createAsyncThunk(
  "calendarFilter/deleteCoutryHolidays",
  async (calendarId: string, { rejectWithValue }) => {
    try {
      await removeCountryHolidays(calendarId);
      return calendarId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHolidays = createAsyncThunk(
  "calendarFilter/getHolidays",
  async (holidaysCalendars: HolidaysCalendar[]): Promise<Holiday[]> => {
    if (holidaysCalendars.length) {
      const holidays = await Promise.all(
        holidaysCalendars.map(async (calendar) => {
          if (calendar.type === "custom") {
            if (calendar.events) {
              return calendar.events;
            } else {
              return await fetchFromUrl(calendar.url);
            }
          }
          return fetchHolidaysFromApi(calendar.countryIso);
        })
      );
      // @ts-ignore
      return holidays.flat(Infinity);
    }

    return [];
  }
);

export const fetchHolidaysFromUrl = createAsyncThunk(
  "calendarFilter/fetchHolidaysFromUrl",
  async (url: string): Promise<Holiday[]> => {
    return await fetchFromUrl(url, true);
  }
);

export const importCalendarFromUrl = createAsyncThunk(
  "calendarFilter/importHolidaysFromUrl",
  async ({ calendarName, url }: { calendarName: string; url: string }) => {
    return await importHolidaysFromUrl({
      calendarName,
      url,
    });
  }
);

export const calendarFilterSlice = createSlice({
  name: "calendarFilter",
  initialState: {
    countries: [],
    holidaysCalendars: [],
    holidays: [],
  },
  reducers: {
    toggleCountryHolidays: (state: any, action: { payload: string }) => {
      state.holidaysCalendars = state.holidaysCalendars.map(
        (holidayCalendar: HolidaysCalendar) => {
          if (holidayCalendar._id === action.payload) {
            holidayCalendar.checked = holidayCalendar.checked ? false : true;
          }
          return holidayCalendar;
        }
      );
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      getCountriesList.fulfilled,
      (state: any, action: { payload: Country[] }) => {
        state.countries = action.payload.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
    );
    builder.addCase(
      importCountry.fulfilled,
      (state: any, action: { payload: HolidaysCalendar }) => {
        const countryHolidays = { ...action.payload, checked: true };
        state.holidaysCalendars = [...state.holidaysCalendars, countryHolidays];
      }
    );
    builder.addCase(
      getHolidayCalendars.fulfilled,
      (
        state: any,
        action: {
          payload: {
            calendarsIds: string[];
            holidaysCalendars: HolidaysCalendar[];
          };
        }
      ) => {
        const { calendarsIds, holidaysCalendars } = action.payload;

        state.holidaysCalendars = holidaysCalendars?.map((holidayCalendar) => {
          if (
            calendarsIds?.some(
              (calendarId) => calendarId === holidayCalendar._id
            )
          ) {
            holidayCalendar.checked = true;
          }

          return holidayCalendar;
        });
      }
    );
    builder.addCase(
      deleteCountryHolidays.fulfilled,
      (state: any, action: { payload: string }) => {
        state.holidaysCalendars = state.holidaysCalendars.filter(
          (country: Country) => country._id !== action.payload
        );
      }
    );
    builder.addCase(
      getHolidays.fulfilled,
      (state: any, action: { payload: Holiday[] }) => {
        state.holidays = action.payload;
      }
    );
    builder.addCase(
      fetchHolidaysFromUrl.fulfilled,
      (state: any, action: { payload: any }) => {
        state.holidays = [...state.holidays, ...action.payload.events];
      }
    );
    builder.addCase(
      importCalendarFromUrl.fulfilled,
      (state: any, action: { payload: any }) => {
        const { name, url, _id } = action.payload.data;
        const holiday = {
          type: "custom",
          _id,
          url,
          name,
          checked: true,
        };
        state.holidaysCalendars = [...state.holidaysCalendars, holiday];
      }
    );
  },
});

export const calendarFilterReducer = calendarFilterSlice.reducer;
export const calendarFilterActions = calendarFilterSlice.actions;
