import {
  createPath,
  History,
  createMemoryHistory,
  LocationDescriptor,
} from "history";

interface HistoryConfig {
  baseUrl: string;
}

export function createPartUrlHistory({ baseUrl }: HistoryConfig): History {
  const history = createMemoryHistory({
    initialEntries: [
      {
        pathname: window.location.pathname.replace(
          new RegExp(`^${baseUrl}`),
          ""
        ),
        search: window.location.search,
      },
    ],
  });

  function createFullPathFromPart(to: LocationDescriptor) {
    return typeof to === "string"
      ? `${baseUrl}${to}`
      : createPath({
          ...to,
          pathname: `${baseUrl}${to.pathname}`,
        });
  }

  const push = history.push;
  const replace = history.replace;

  const pushState = window.history.pushState;
  const replaceState = window.history.replaceState;

  history.push = (...args) => {
    pushState(null, "", createFullPathFromPart(args[0]));
    push(...args);
  };

  history.replace = (...args) => {
    replaceState(null, "", createFullPathFromPart(args[0]));
    replace(...args);
  };

  function trimBaseUrl(url: string) {
    return url
      .replace(window.location.origin, "")
      .replace(new RegExp(`^${baseUrl}`), "");
  }

  window.history.pushState = (...args) => {
    const href = args[2];
    push(trimBaseUrl(href as string));
    pushState.bind(window.history, ...args)();
  };

  window.history.replaceState = (...args) => {
    const href = args[2];
    replace(trimBaseUrl(href as string));
    replaceState.bind(window.history, ...args)();
  };

  return history;
}
