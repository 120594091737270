import axios from "axios";

export async function getAllUsersRequest() {
  return await axios.get("/multiuser");
}

export async function getAllUsers() {
  try {
    const { data } = await getAllUsersRequest();
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function addUser(user: any) {
  try {
    const { data } = await axios.post("/multiuser/", user);
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function deleteAvatar({ email }: { email: string }) {
  try {
    const { data } = await axios.delete(`/multiuser/${email}/profile`);
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function updateAvatar({
  email,
  url,
}: {
  email: string;
  url: string;
}) {
  try {
    const { data } = await axios.put(`/multiuser/${email}/profile`, { url });
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function getUser(email: string) {
  try {
    const { data } = await axios.get(`/multiuser/${email}`);
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function updateUser({
  email,
  ids,
}: {
  email: string;
  ids?: string[];
}) {
  try {
    const { data } = await axios.put(`/multiuser/${email}`, { ids });
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function updateUserPreferences({
  userId,
  preferences,
}: {
  userId: string;
  preferences: any;
}) {
  try {
    const { data } = await axios.put(`/multiuser/setPreferences/${userId}`, {
      ...preferences,
    });
    return data;
  } catch (error) {
    return error.response;
  }
}

export async function deleteUser({ email }: { email: string }) {
  try {
    const { data } = await axios.delete(`/multiuser/${email}`);
    return data;
  } catch (error) {
    return error.response;
  }
}
