import { createEvent, restore } from "effector";
import { useStore } from "effector-react";

import { Plan } from "api/plan-change";

import { PlansModalGate } from "./PlansModalGate";

export const selectPlan =
  createEvent<{ planId: string; plan: Plan; isAnnual: boolean }>();

const $selectedPlan = restore(selectPlan, {
  planId: "",
  isAnnual: false,
  plan: {
    _id: "",
    active: false,
    currentPlan: false,
    displayName: "",
    displayPrice: 0,
    igIncluded: 0,
    igIncludedUpTo: 0,
    otherIncludedUpTo: 0,
    otherIncluded: 0,
    price: { usd: 0, aud: 0, eur: 0, gbp: 0 },
    primaryCbId: { monthly: "", annual: "" },
    secondaryCbIds: [],
    tooManyAccounts: false,
  },
}).reset(PlansModalGate.close);

export function useSelectedPlan() {
  const selectedPlan = useStore($selectedPlan);

  return {
    selectedPlan,
    selectPlan,
  };
}
