import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects";
import { ContentLabel } from "@workshop/types";
import {
  AddContentLabelAction,
  GetCaptivateTopicSuggestionAction,
  RemoveContentLabelAction,
  UpdateContentLabelAction,
} from "actions/workshop";
import {
  createContentLabel,
  getContentLabels,
  updateContentLabel as updateContentLabelApi,
  removeContentLabel as removeContentLabelApi,
} from "api/content-labels";
import { insightsApi } from "api/insightsApi";
import { contentLabelsCache } from "components/AICaption/content-labels-cache";

import {
  ADD_CONTENT_LABEL_ROUTINE,
  GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE,
  GET_CONTENT_LABELS_ROUTINE,
  REMOVE_CONTENT_LABEL_ROUTINE,
  UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE,
  UPDATE_CONTENT_LABEL_ROUTINE,
} from "constants/ActionTypes";
import { AccountGroupType } from "features/account-groups-form/accountsGroups.model";
import { getSelectedGroup } from "selectors/contentLabelsSelectors";

export function* fetchContentLabels() {
  try {
    const selectedGroup: AccountGroupType = yield select(getSelectedGroup);

    if (!selectedGroup || !selectedGroup._id) {
      throw new Error("No selected group or group Id");
    }

    const returnData: { data: ContentLabel[] } = yield call(
      getContentLabels,
      selectedGroup._id
    );

    yield put({
      type: GET_CONTENT_LABELS_ROUTINE.SUCCESS,
      payload: {
        data: (returnData.data || []).reverse(),
      },
    });
  } catch (err) {
    console.debug(err);
    yield put({
      type: GET_CONTENT_LABELS_ROUTINE.FAILURE,
    });
  }
}

export function* getCaptivateTopicSuggestion(
  action: GetCaptivateTopicSuggestionAction
) {
  try {
    const returnData: { answer: string } = yield call(
      [insightsApi, insightsApi.getCaptivateTopicSuggestion],
      action.payload
    );

    yield put({
      type: GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.SUCCESS,
      payload: {
        ...action.payload,
        generatedTopic: returnData.answer,
      },
    });

    yield put({
      type: GET_CONTENT_LABELS_ROUTINE.TRIGGER,
    });
  } catch (error) {
    console.debug(
      "Error on getting captivate topic suggtion",
      error,
      action.payload
    );
    yield put({
      type: GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.FAILURE,
      payload: action.payload,
    });
  }
}

export function* addContentLabel(action: AddContentLabelAction) {
  const { contentLabel, selectedGroup } = action.payload;
  try {
    if (!selectedGroup || !selectedGroup.name) {
      throw new Error("No selected group or group name");
    }

    const returnData: { data: ContentLabel } = yield call(createContentLabel, {
      ...contentLabel,
      groupId: selectedGroup.id,
      groupName: selectedGroup.name,
    });

    yield put({
      type: ADD_CONTENT_LABEL_ROUTINE.SUCCESS,
      payload: {
        ...returnData.data,
      },
    });

    yield put({
      type: GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.TRIGGER,
      payload: {
        groupDescription: selectedGroup.groupDescription,
        labelId: returnData.data._id,
        labelDescription: contentLabel.description,
        labelType:
          contentLabel.label === "CAMPAIGN" ? "campaign" : "content-pillar",
      },
    });
  } catch (err) {
    console.debug(err);
    yield put({
      type: ADD_CONTENT_LABEL_ROUTINE.FAILURE,
    });
  }
}

export function* updateContentLabel(action: UpdateContentLabelAction) {
  const {
    id,
    contentLabel,
    refreshTopic,
    originalContentLabel,
    selectedGroup,
    done,
  } = action.payload;
  try {
    /** no id, update label for captivate */
    if (!id && originalContentLabel) {
      contentLabelsCache.updateLabelCache({
        groupName: selectedGroup.name,
        label: {
          label: contentLabel.name || originalContentLabel.name,
          description:
            contentLabel.description || originalContentLabel.description,
          emoji: contentLabel.emoji || originalContentLabel.emoji,
          color: contentLabel.color || originalContentLabel.color,
        },
        prevLabelName: originalContentLabel.name,
      });
      yield delay(700);
      yield put({
        type: UPDATE_CONTENT_LABEL_ROUTINE.SUCCESS,
        payload: action.payload,
        id: action.payload.id,
      });
      done?.(true);
      return;
    }

    const returnData: { data: ContentLabel } = yield call(
      updateContentLabelApi,
      id,
      contentLabel
    );

    yield put({
      type: UPDATE_CONTENT_LABEL_ROUTINE.SUCCESS,
      payload: {
        ...returnData.data,
        refreshTopic,
      },
    });

    if (refreshTopic) {
      const selectedGroup: AccountGroupType = yield select(getSelectedGroup);

      yield put({
        type: GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.TRIGGER,
        payload: {
          groupDescription: selectedGroup.groupDescription,
          labelId: returnData.data._id,
          labelDescription: contentLabel.description,
          labelType:
            contentLabel.label === "CAMPAIGN" ? "campaign" : "content-pillar",
        },
      });
    }

    yield put({
      type: GET_CONTENT_LABELS_ROUTINE.TRIGGER,
    });
    done?.(true);
  } catch (err) {
    console.debug(err);
    yield put({
      type: UPDATE_CONTENT_LABEL_ROUTINE.FAILURE,
    });
    done?.(true);
  }
}

export function* updateContentLabelGeneratedTopic(action: {
  payload: {
    id: string;
    generatedTopic: string;
  };
}) {
  const { id, generatedTopic } = action.payload;

  yield put({
    type: UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE.SUCCESS,
    payload: {
      id,
      generatedTopic,
    },
  });
}

export function* removeContentLabel(action: RemoveContentLabelAction) {
  try {
    yield put({
      type: REMOVE_CONTENT_LABEL_ROUTINE.SUCCESS,
      payload: action.payload,
    });

    yield call(removeContentLabelApi, action.payload.id);

    yield put({
      type: GET_CONTENT_LABELS_ROUTINE.TRIGGER,
    });
  } catch (err) {
    console.debug(err);
    yield put({
      type: REMOVE_CONTENT_LABEL_ROUTINE.FAILURE,
      payload: action.payload,
    });
    yield put({
      type: GET_CONTENT_LABELS_ROUTINE.TRIGGER,
    });
  }
}

export const contentLabelSagas = [
  takeEvery(
    GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE.TRIGGER,
    getCaptivateTopicSuggestion
  ),
  takeLatest(GET_CONTENT_LABELS_ROUTINE.TRIGGER, fetchContentLabels),
  takeEvery(ADD_CONTENT_LABEL_ROUTINE.TRIGGER, addContentLabel),
  takeEvery(UPDATE_CONTENT_LABEL_ROUTINE.TRIGGER, updateContentLabel),
  takeEvery(REMOVE_CONTENT_LABEL_ROUTINE.TRIGGER, removeContentLabel),
  takeEvery(
    UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE.TRIGGER,
    updateContentLabelGeneratedTopic
  ),
];
