import React from "react";
import PropTypes from "prop-types";
import SkedIcon from "ui/SkedIcon/SkedIcon";
import "./PostTag.less";

function PostTag({ type, title }) {
  let icon = null;
  switch (type) {
    case "user":
      icon = <SkedIcon className="PostTag-Icon" icon="user" size="14" />;
      break;
    case "product":
      icon = (
        <SkedIcon className="PostTag-Icon" icon="icon-shopping-bag" size="14" />
      );
      break;
  }

  return (
    <div className="PostTag">
      <div className="PostTag-Point"></div>
      {icon !== null && icon}
      <span className="PostTag-Title">{title}</span>
    </div>
  );
}

PostTag.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
};

export default PostTag;
