import axios from "axios";

export const signupReminder = async (email: string) => {
  try {
    const { data } = await axios.post(`/signup/reminder`, { email });
    return data;
  } catch (error) {
    return error.response.data[0];
  }
};
