import React, { useCallback, useMemo, useEffect, useState } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FormControl } from "ui";
import { FIELDS, GMB_TOPICS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { useDebouncedCallback } from "use-debounce";
import { TextInput } from "ui/ecosystems/forms/inputs";

const PostTopicTitle = ({
  topicType,
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  className = "",
  errors,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (post.gmbEvent?.title) {
      setValue(post.gmbEvent.title);
    }
  }, []);
  const bounce = useDebouncedCallback((value) => {
    const title = post.gmbEvent?.title;
    if (value != title) {
      onChange({ [FIELDS.GMB_TITLE]: value });
    }
  }, 200);

  const forTopics = [GMB_TOPICS.OFFER.VALUE, GMB_TOPICS.EVENT.VALUE];
  if (!forTopics.includes(topicType)) {
    return "";
  }

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          {topicType === GMB_TOPICS.OFFER.VALUE ? "Offer" : "Event"} title
          (required)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.GMB_TOPIC_TYPE}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <p className="small">
        This will lead the post with the caption ('event details') you enter
        above shown below the title.
      </p>
      <TextInput
        value={value}
        onChange={(value) => {
          const newVal = value;
          setValue(newVal);
          bounce(newVal);
        }}
        disabled={disabled}
      />
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.GMB_TITLE]}
        errors={errors}
      />
    </div>
  );
};

export default PostTopicTitle;
