import { MediaSort } from "shared/types/files";
import React from "react";
import styled from "styled-components";

import { Select } from "ui/ecosystems/forms";

interface MLSortByProps {
  disabled: boolean;
  value: MediaSort;
  onChange(sort: MediaSort): void;
  className?: string;
}

export const MLSortBy = styled(function MLSortBy({
  value,
  onChange,
  disabled,
  className,
}: MLSortByProps) {
  const selectedOption = SortOptions.find((o) => o.value === value);

  return (
    <Container>
      <Select
        disabled={disabled}
        options={SortOptions}
        value={selectedOption?.value}
        onChange={onChange}
        className={className}
      />
    </Container>
  );
})``;

const SortOptions = [
  {
    label: "Recently Added",
    value: MediaSort.addedNewestToOldest,
  },
  {
    label: "Oldest Added",
    value: MediaSort.addedOldestToNewest,
  },
  {
    label: "Recently Updated",
    value: MediaSort.updatedNewestToOldest,
  },
  {
    label: "Oldest Updated",
    value: MediaSort.updatedOldestToNewest,
  },
  {
    label: "Alphabetical A-Z",
    value: MediaSort.alphabetical,
  },
  {
    label: "Alphabetical Z-A",
    value: MediaSort.alphabeticalReverse,
  },
];

const Container = styled.div`
  position: relative;

  div[class*="-control"] {
    margin-right: 10px;
  }

  .select__control {
    --inputHeight: 18px;
    min-width: 172px;

    div[class*="-indicatorContainer"] {
      padding: 5px;
    }
  }
`;
