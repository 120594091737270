import React from "react";
import { MetaReaction, ReactionType } from "types/conversationInbox";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import styled, { css, keyframes } from "styled-components";
import { ReactionsContext } from "../message/types";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  isLeft: boolean;
  reactions: ReactionsContext;
  messageId: string;
  onReactionsChange: (data: ReactionsChangeData) => void;
}

export type ReactionsChangeData = AddReactionsChange | RemoveReactionsChange;
export interface AddReactionsChange {
  type: "add";
  messageId: string;
  reaction: MetaReaction;
}
export interface RemoveReactionsChange {
  type: "remove";
  messageId: string;
  reactionId: string;
}

export const MessageReaction = ({
  isLeft,
  reactions,
  messageId,
  onReactionsChange,
}: Props): JSX.Element => {
  const { skedReaction, smuReaction } = extractReactionSide(reactions);
  const onAddReaction = () => {
    onReactionsChange({
      type: "add",
      messageId,
      reaction: MetaReaction.Love,
    });
  };

  const onRemoveReaction = (reactionId: string) => {
    onReactionsChange({
      type: "remove",
      messageId,
      reactionId: reactionId,
    });
  };
  if (smuReaction) {
    if (skedReaction && isSameReaction(skedReaction, smuReaction)) {
      return (
        <EmojiReaction
          active={true}
          reaction={smuReaction}
          readonly={false}
          onIconClick={() => onRemoveReaction(skedReaction.id)}
          count={2}
        />
      );
    }
    const smuReactionJsx = (
      <EmojiReaction
        rightGap={isLeft}
        leftGap={!isLeft}
        active={true}
        reaction={smuReaction}
        readonly={true}
        onIconClick={_.noop}
      />
    );
    const skedReacitonJsx = (
      <EmojiReaction
        active={!!skedReaction}
        reaction={skedReaction || { reaction: MetaReaction.Love }}
        readonly={false}
        rightGap={isLeft}
        leftGap={!isLeft}
        onIconClick={() => {
          if (skedReaction) {
            onRemoveReaction(skedReaction.id);
          } else {
            onAddReaction();
          }
        }}
      />
    );
    return isLeft ? (
      <div>
        {smuReactionJsx}
        {skedReacitonJsx}
      </div>
    ) : (
      <div>
        {skedReacitonJsx}
        {smuReactionJsx}
      </div>
    );
  }

  if (skedReaction) {
    return (
      <EmojiReaction
        active={true}
        reaction={skedReaction}
        readonly={false}
        onIconClick={() => onRemoveReaction(skedReaction.id)}
      />
    );
  }

  return (
    <EmojiReaction
      active={false}
      reaction={{ reaction: MetaReaction.Love }}
      readonly={false}
      onIconClick={() => onAddReaction()}
    />
  );
};

function isSameReaction(left: ReactionType, right: ReactionType) {
  return extractReactionText(left) === extractReactionText(right);
}

function extractReactionText(reaction: ReactionType) {
  return reaction.reaction === MetaReaction.Love
    ? "#love"
    : reaction.emoji || "#love";
}

function extractReactionSide(reactions: ReactionsContext) {
  const [skedReaction, smuReaction] = _.partition(
    reactions.reactions,
    (c) => c.reactee === reactions.skedAccountId
  );
  return {
    smuReaction: smuReaction[0] as ReactionType | undefined,
    skedReaction: skedReaction[0] as ReactionType | undefined,
  };
}

interface EmojiReactionProps {
  reaction: Pick<ReactionType, "reaction" | "emoji">;
  active: boolean;
  readonly: boolean;
  rightGap?: boolean;
  leftGap?: boolean;
  count?: number;
  onIconClick: (() => void) | undefined;
}
const EmojiReaction = ({
  active,
  reaction,
  readonly,
  rightGap,
  leftGap,
  count,
  onIconClick,
}: EmojiReactionProps) => {
  if (reaction.reaction === MetaReaction.Love || !reaction.emoji) {
    return (
      <EmojiReactionContainer
        data-testid="reaction-icon-container"
        onClick={readonly ? undefined : onIconClick}
        readonly={readonly}
        rightGap={rightGap}
        leftGap={leftGap}
        heartActive={!readonly && active}
        expand={!!count && count > 1}
        className={!readonly && active ? "heart-active" : undefined}
      >
        <HeartIconContainer iconActive={active}>
          <HeartIcon icon={faHeart} className={active ? "active" : undefined} />
        </HeartIconContainer>

        {!!count && count > 1 && <ReactionCount isHeart>2</ReactionCount>}
      </EmojiReactionContainer>
    );
  }
  return (
    <EmojiReactionContainer
      data-testid="reaction-icon-container"
      rightGap={rightGap}
      leftGap={leftGap}
      readonly={readonly}
      onClick={readonly ? undefined : onIconClick}
      expand={!!count && count > 1}
    >
      <UnicodeEmoji>{reaction.emoji}</UnicodeEmoji>
      {!!count && count > 1 && <ReactionCount>2</ReactionCount>}
    </EmojiReactionContainer>
  );
};

const ReactionCount = styled.span<{ isHeart?: boolean }>`
  font-size: 12px;
  padding-left: 5px;
  display: inline-block;
  vertical-align: ${(p) => (p.isHeart ? "middle" : "1px")};
`;

const HeartIconContainer = styled.span<{ iconActive?: boolean }>`
  color: ${(p) => (p.iconActive ? "#fa4f38" : "#fff")};
  vertical-align: middle;
`;

const heartAnimation = keyframes`
  25% { height: 18px; padding-top:3px }
  50% { width: 18px;  height: 25px; padding-top:0; margin-top:-1px;}
  100% { height: 18px; padding-top:3px }
`;

const HeartIcon = styled(FontAwesomeIcon)`
  height: 18px;
  padding-top: 3px;
  &.active {
    animation-name: ${heartAnimation};
    animation-duration: 1s;
  }
`;

const EmojiReactionContainerAnimation = keyframes`
  0% { background-color: #fff;}
  50% { background-color: #fee5e1;}
  100% { background-color: #fee5e1;}
`;
const EmojiReactionContainer = styled.span<{
  rightGap?: boolean;
  leftGap?: boolean;
  heartActive?: boolean;
  expand?: boolean;
  readonly: boolean;
}>`
  display: inline-block;
  background-color: #eeeeee;
  width: ${(p) => (p.expand ? "40px" : "24px")};
  height: 24px;
  border-radius: 20px;
  text-align: center;
  vertical-align: text-bottom;
  padding: 2px;
  cursor: ${(p) => (p.readonly ? "default" : "pointer")};
  ${(p) => {
    if (p.leftGap) {
      return css`
        margin-left: "5px";
      `;
    }
    return css`
      margin-left: 15px;
    `;
  }}
  ${(p) => {
    if (p.rightGap) {
      return css`
        margin-right: "5px";
      `;
    }
    return css`
      margin-right: 15px;
    `;
  }}
  ${(p) => (p.heartActive ? "background-color: #fee5e1;" : undefined)}
  &.heart-active {
    animation-name: ${EmojiReactionContainerAnimation};
    animation-duration: 1s;
  }
`;

const UnicodeEmoji = styled.span``;
