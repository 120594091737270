import React, { useState } from "react";
import styled from "styled-components";

import { NativeInput } from "../input";
import { Eye } from "./eye";

type PasswordInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "className" | "style" | "onChange"
> & {
  className?: string;
  style?: React.CSSProperties;
  onChange(value: string): void;
};

export function PasswordInput({
  className,
  style,
  disabled,
  onChange,
  ...otherProps
}: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Container className={className} style={style}>
      <Input
        type={showPassword ? "text" : "password"}
        disabled={disabled}
        onChange={(evt) => onChange(evt.target.value)}
        {...otherProps}
      />
      <IconButton
        title="Show Password"
        aria-label="Show Password"
        type="button"
        onClick={() => setShowPassword((show) => !show)}
        disabled={disabled}
        shown={showPassword}
      >
        <Eye crossed={showPassword} />
      </IconButton>
    </Container>
  );
}

// 25px - Eye width
// 6px - horizontal paddings
const Input = styled(NativeInput)`
  padding-right: calc(25px + 6px * 3);
`;

const IconButton = styled.button<{ shown: boolean }>`
  position: absolute;
  right: calc(6px * 2);
  top: 50%;
  transform: translateY(-50%);

  background: transparent;
  border: none;
  padding: 0;
  font-size: 0;

  color: ${(props) => (props.shown ? "#4f31ff" : "black")};

  &:active {
    color: #4f31ff;
  }

  &:disabled {
    background: transparent !important;
    color: gray;
  }
`;

const Container = styled.div`
  position: relative;
`;
