import { AccountPlatformType, AccountType } from "shared/types/accounts";

export function getAccountPlatformType(
  account?: AccountType
): AccountPlatformType {
  return account?.platformType || "IG";
}

export function isInstagramAccount(account: AccountType): boolean {
  return getAccountPlatformType(account) === "IG";
}

export function isLinkedInAccount(account: AccountType): boolean {
  return getAccountPlatformType(account) === "LI";
}

export function isAccountFacebookPage(account: AccountType): boolean {
  return getAccountPlatformType(account) === "FB" && !account.isGroup;
}

export function showInsightsEmails(account: AccountType): boolean {
  return (
    isAccountFacebookPage(account) ||
    isLinkedInAccount(account) ||
    isInstagramAccount(account)
  );
}

export function getPFItemDesc(account: AccountType): string {
  if (account.isGroup === true) {
    return "Group";
  }
  switch (getAccountPlatformType(account)) {
    case "FB":
      return "Page";
    case "LI":
      return "LinkedIn Page";
    case "TW":
      return "account";
    case "GMB":
      return "location";
    case "YT":
      return "channel";
    default:
      // PI, TT, SC, OT
      return "account";
  }
}
