import React from "react";
import styled, { css } from "styled-components";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { Icon } from "@dialogueconsulting/sked-ui";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "selectors/commonSelectors";
import { isUserPlan } from "features/upsell/plan-utils";
import { showUpsellModal } from "actions/typed-modal";
import { useTrackEvent } from "libs/analytics";
import { UPGRADE_TEAMWORK_VIEWED } from "constants/Events";

const Separator = styled.span<{ leftRight: "left" | "right" }>`
  &:after {
    content: "";
    position: absolute;
    width: 1px;
    background: #c4c4c480;
    height: 140%;
    transform: translate(0, -50%);
    top: 50%;

    ${({ leftRight }) =>
      leftRight === "left"
        ? "right: 0"
        : leftRight === "right"
        ? "left: 0"
        : ""};
  }
`;

const StyledItem = styled(ToggleGroupPrimitive.Item)<{
  size: ToggleGroupProps["size"];
}>`
  border-radius: 6px;
  border: unset;
  color: #4f31ff;
  background: #eeeeee;

  ${({ size }) => {
    if (size === "sm") {
      return css`
        font-size: 10px;
        padding: 9px 0;
      `;
    } else if (size === "md") {
      return css`
        font-size: 12px;
        padding: 10px 0;
      `;
    }

    return css`
      font-size: 14px;
      padding: 14px 0;
    `;
  }}

  font-weight: 700;
  cursor: pointer;

  &:hover {
    background: #dedafe;
  }

  &[data-state="on"] {
    background: #4f31ff !important;
    color: #ffffff !important;
    z-index: 1;

    ${Separator} {
      display: none;
    }
  }

  &[data-state="off"] {
    svg {
      rect {
        fill: #4f31ff;
      }

      path {
        stroke: #ffffff;
      }
    }
  }
`;

const ItemInnerWrap = styled.div`
  position: relative;
`;

const ToggleGroup = styled(ToggleGroupPrimitive.Root)`
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));

  border-radius: 6px;
  overflow: hidden;
  background: #eeeeee;
  white-space: nowrap;
`;

const UpsellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ToggleGroupItem = StyledItem;

export interface ToggleGroupProps {
  defaultValue: string;
  leftItem: {
    value: string;
    label: string;
    id: string;
  };
  middleItem?: {
    value: string;
    label: string;
    id: string;
  };
  rightItem: {
    value: string;
    label: string;
    id: string;
  };
  onValueChange: (value: string) => void;
  size?: string;
  className?: string;
}

const ToggleGroupComponent: React.FC<ToggleGroupProps> = ({
  defaultValue,
  leftItem,
  middleItem,
  rightItem,
  onValueChange,
  size,
  className,
}: ToggleGroupProps) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const upsellTeamwork = isUserPlan(user, "sked-fundamentals");

  const [value, setValue] = React.useState<string>(defaultValue);

  const hasMoreThanTwoItems = leftItem && middleItem && rightItem;
  const isMiddleItemSelected = value === middleItem?.value;
  const trackEvent = useTrackEvent();

  return (
    <ToggleGroup
      type="single"
      value={value}
      onValueChange={(value: string) => {
        if (upsellTeamwork && value === middleItem?.value) {
          trackEvent({
            eventName: UPGRADE_TEAMWORK_VIEWED,
            useServices: ["AMP", "IC", "AC", "HS"],
          });
          dispatch(showUpsellModal("upsell", "teamwork"));
          return;
        }

        if (value) {
          setValue(value);
          onValueChange(value);
        }
      }}
      orientation="horizontal"
      className={className}
    >
      <ToggleGroupItem value={leftItem.value} id={leftItem.id} size={size}>
        <ItemInnerWrap>
          {leftItem.label}{" "}
          {hasMoreThanTwoItems && !isMiddleItemSelected && (
            <Separator data-testid="separator" leftRight="left" />
          )}
        </ItemInnerWrap>
      </ToggleGroupItem>
      {middleItem && (
        <ToggleGroupItem
          value={middleItem.value}
          id={middleItem.id}
          size={size}
        >
          {upsellTeamwork ? (
            <UpsellWrap>
              {middleItem.label}
              <Icon name="upsell-rocket-white-bg" size={20} />
            </UpsellWrap>
          ) : (
            middleItem.label
          )}
        </ToggleGroupItem>
      )}
      <ToggleGroupItem value={rightItem.value} id={rightItem.id} size={size}>
        <ItemInnerWrap>
          {rightItem.label}{" "}
          {hasMoreThanTwoItems && !isMiddleItemSelected && (
            <Separator data-testid="separator" leftRight="right" />
          )}
        </ItemInnerWrap>
      </ToggleGroupItem>
    </ToggleGroup>
  );
};

export default ToggleGroupComponent;
