import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { getUserPreferences } from "selectors/commonSelectors";
import { MessageType } from "types/conversationInbox";
import { getDateFormat } from "../components/date-format";
import { AccountMessageProps } from "../message/account-message";
import { DateLabel as DateLabelTag } from "../message/styled";

type Props = Pick<
  AccountMessageProps,
  "type" | "userName" | "timestamp" | "shouldDisplaySender" | "forceLeft"
>;

export function useFormatMessageDateTime(timestamp: Date): string {
  const preferences = useSelector(getUserPreferences);
  const messageDateFormat = getDateFormat(preferences);
  try {
    const date = format(timestamp, messageDateFormat);
    return date;
  } catch {
    return "";
  }
}

export function DateLabel({
  type,
  userName,
  timestamp,
  shouldDisplaySender,
  forceLeft,
}: Props): JSX.Element {
  const date = useFormatMessageDateTime(timestamp);

  shouldDisplaySender =
    shouldDisplaySender &&
    ![
      MessageType.igMention,
      MessageType.igStoryReply,
      MessageType.igStoryMention,
    ].includes(type);
  const prefix = shouldDisplaySender ? `${userName} at ` : "";

  return (
    <DateLabelTag left={forceLeft} data-testid="date-label">
      {prefix}
      {date}
    </DateLabelTag>
  );
}
