export const getPage = (state) => state.common.page;
export const getUser = (state) => state.user;
export const getUserPostStatuses = (state) => {
  return state.user?.isPostStatusEnabled && state.user?.postStatuses.length > 0
    ? state.user.postStatuses
    : [];
};
export const getUserPreferences = (state) => state.user?.preferences ?? {};
export const getUserCollaborators = (state) => state.user?.collaborators ?? [];
export const getUserCollaboratorAdmin = (state) => {
  const collaborators = state.user?.collaborators ?? [];
  return collaborators.find((collaborator) => collaborator.role === "admin");
};
export const getLoading = (state) => state.common.loading.isLoading;
export const getRoutinesLoading = (state, _routines = []) => {
  const loadingState = state.common.loading;

  // If empty array is supplied then nothing to do.
  if (!_routines || (Array.isArray(_routines) && _routines.length === 0)) {
    return false;
  }

  const isArray = Array.isArray(_routines);
  const routines = isArray ? _routines : [_routines];

  let isLoading = false;
  routines.map((routine) => {
    const prefix = routine._PREFIX;
    if (loadingState[prefix] === true) {
      isLoading = true;
    }
  });
  return isLoading;
};

// Pagination
export const getPagination = (state) => state.pagination;
export const getPageSize = (state) => state.pagination.pageSize;
export const getCurrentPage = (state) => state.pagination.currentPage;

// Modals
export const getModals = (state) => state.modal.modals;
export const getModalsLoading = (state) => state.modal.loading;
export const getLibsModals = (state) => state["@libs/modals"].openedModals;
