import { useDisclaimer } from "components/MediaLibrary/SourceMedia/Disclaimer/Disclaimer";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { SkedIcon } from "ui";
import { FormSize } from "ui/ecosystems/forms";
import { Button } from "ui/ecosystems/forms/button";

interface ConfirmRightsAlertProps {
  pickerType: string;
  onAccept?: () => void;
}

export function ConfirmRightsAlert(props: ConfirmRightsAlertProps) {
  if (props.pickerType === "stocks") {
    return <PexelsConfirmRightsAlert />;
  }

  if (["hashtag", "tagged-in", "creator"].includes(props.pickerType)) {
    return <IgConfirmRightsAlert onAccept={props.onAccept} />;
  }

  return <></>;
}

function PexelsConfirmRightsAlert() {
  return (
    <ConfirmAlert
      data-testid="pexels-confirm-rights-alert"
      fgColor="#4F31FF"
      bgColor="#F6F5FF"
    >
      <SkedIcon icon="warning" />
      Check the{" "}
      <a
        href="https://www.pexels.com/license/"
        target="__blank"
        rel="noreferrer"
      >
        Pexels license
      </a>{" "}
      before saving
    </ConfirmAlert>
  );
}

function IgConfirmRightsAlert({ onAccept }: { onAccept?: () => void }) {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  return (
    <>
      <ConfirmAlert
        data-testid="ig-confirm-rights-alert"
        fgColor="#DE6716"
        bgColor="#FDF4EE"
      >
        <SkedIcon icon="warning" />
        Confirm rights{" "}
        <span onClick={() => setShowConfirmModal(true)}>here</span> before
        saving.
      </ConfirmAlert>
      <IgConfirmRightsModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          onAccept && onAccept();
        }}
      />
    </>
  );
}

export function IgConfirmRightsModal({
  show,
  onConfirm,
  onClose,
}: {
  show: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) {
  return (
    <Modal show={show} data-testid="ig-confirm-rights-modal">
      <ModalBody>
        <h4>
          <SkedIcon icon="warning" />
          Confirm rights before saving
        </h4>
        <p>
          If you save content that belongs to someone else, please ensure that
          you have obtained permission and/or have an appropriate license from
          them, and you provide attribution if appropriate.
        </p>
        <p>
          Per our Terms of Service, you warrant that you have the necessary
          rights, licenses and consents when adding content to Sked.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button size={FormSize.medium} onClick={onClose}>
          Cancel
        </Button>
        <Button primary size={FormSize.medium} onClick={onConfirm}>
          Accept and Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const ModalBody = styled(Modal.Body)`
  h4 {
    background: #fdf4ee;
    border-radius: 5px;
    height: 38px;
    padding: 0 10px;
    line-height: 38px;
    font-size: 14px;
    color: #de6716;

    .SkedIcon {
      margin-right: 10px;
      path {
        fill: #de6716;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    font-weight: 450;
    margin-bottom: 20px;
  }
`;
const ModalFooter = styled(Modal.Footer)`
  &.modal-footer {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;

    button {
      margin-left: 15px;
    }
  }
`;

const ConfirmAlert = styled.div<{ fgColor: string; bgColor: string }>`
  background: ${(props) => props.bgColor};
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 14px;
  color: ${(props) => props.fgColor};
  font-weight: 450;

  .SkedIcon {
    margin-right: 10px;
    path {
      fill: ${(props) => props.fgColor};
    }
  }

  span,
  a {
    margin: 0 5px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
