import {
  INIT_POSTS_PAGE_ROUTINE,
  INIT_SELECTED_ACCOUNTS,
  SET_SELECTED_ACCOUNTS,
  SKED_CORE_GET_ACCOUNTS_ROUTINE,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNTS_COUNT,
  UPDATE_SELECTED_ACCOUNTS,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { normalizeEntity } from "reducers/reducerUtils";
import _ from "lodash";
import { adminPanelFeatureToggle } from "utils/feature-toggle-utils";

const initialState = {
  byId: {},
  allIds: [],
  selectedIds: [],
  withToken: [],
  counts: {
    fbGroupCount: 0,
    fbPageCount: 0,
    gmbCount: 0,
    igAllowed: 0,
    igCount: 0,
    igFollowers: 0,
    liCount: 0,
    otherAllowed: 0,
    otherCount: 0,
    piCount: 0,
    twCount: 0,
    usesSkedLink: false,
    ytCount: 0,
    notificationCount: 0,
    notificationAllowed: 0,
    maxAllowed: 0,
    totalCount: 0,
    pricingVersion: "",
  },
};

const loadAccounts = (state, action) => {
  let { accounts } = action.payload;

  if (!accounts) {
    return state;
  }

  adminPanelFeatureToggle.notifyFromAccounts(accounts);

  const withToken = accounts.filter((a) => {
    return !!a.igTokenAddedAt;
  });

  const { byId, allIds } = normalizeEntity(accounts);

  return {
    ...state,
    byId,
    allIds,
    withToken,
  };
};

const accountsReducer = createReducer(initialState, {
  [INIT_SELECTED_ACCOUNTS]: (state, action) => {
    const { selectedAccountIds } = action.payload;
    state.selectedIds = selectedAccountIds;
    return state;
  },
  [SET_SELECTED_ACCOUNTS]: (state, action) => {
    const { selectedAccountIds } = action.payload;
    state.selectedIds = selectedAccountIds;
    return state;
  },
  [SKED_CORE_GET_ACCOUNTS_ROUTINE.SUCCESS]: (state, action) =>
    loadAccounts(state, action),
  [SKED_CORE_GET_ACCOUNTS_ROUTINE.FAILURE]: (state, action) => {
    state.byId = {};
    state.allIds = [];
    state.selectedIds = [];
    return state;
  },
  [INIT_POSTS_PAGE_ROUTINE.TRIGGER]: (state, action) => {
    // const { selectedAccountIds } = action.payload;
    // if (_.isEqual(selectedAccountIds, state.selectedIds)) {
    //   return state;
    // }
    //
    // state.selectedIds = selectedAccountIds;
    return state;
  },
  [UPDATE_SELECTED_ACCOUNTS]: (state, action) => {
    const { selectedAccountIds } = action.payload;
    if (_.isEqual(selectedAccountIds, state.selectedIds)) {
      return state;
    }

    state.selectedIds = selectedAccountIds;
    return state;
  },
  [UPDATE_ACCOUNT]: (state, action) => {
    const { accountId, account } = action.payload;
    const targetAccount = state.byId[accountId];

    if (!targetAccount) {
      return;
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [accountId]: {
          ...targetAccount,
          ...account,
        },
      },
    };
  },

  [UPDATE_ACCOUNTS_COUNT]: (state, action) => {
    return {
      ...state,
      counts: {
        ...state.counts,
        ...action.payload.counts,
      },
    };
  },
});

export default accountsReducer;
