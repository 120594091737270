import { useStore } from "effector-react";
import { accountsTable } from "./table";

export function useAccount(id: string) {
  return useStore(accountsTable.getById(id));
}

export function useAllAccounts() {
  return useStore(accountsTable.getAll());
}
