import {
  START_POST_FORM_ROUTINE,
  CLOSE_POST_FORM,
  UPDATE_POST_FORM_FIELD,
  SAVE_POST_FORM_ROUTINE,
  SELECT_POST_FORM_PLATFORM,
  TOGGLE_POST_FORM_PLATFORM_LINK,
  CHECK_INSTAGRAM_POST_OVERLAPPING,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";

// Post - FORM (edit / create)
export const startPostForm = createAction(
  START_POST_FORM_ROUTINE.TRIGGER,
  function prepare(post, accounts) {
    return {
      payload: {
        post,
        accounts,
      },
    };
  }
);

export const closePostForm = createAction(
  CLOSE_POST_FORM,
  function prepare(postId) {
    return {
      payload: {
        postId,
      },
    };
  }
);

export const savePostForm = createAction(
  SAVE_POST_FORM_ROUTINE.TRIGGER,
  function prepare(form) {
    return {
      payload: {
        form,
      },
    };
  }
);

export const updatePostFormField = createAction(
  UPDATE_POST_FORM_FIELD,
  function prepare(postId, values) {
    return {
      payload: {
        postId,
        values,
      },
    };
  }
);

export const selectPlatform = createAction(
  SELECT_POST_FORM_PLATFORM,
  function prepare(platformType) {
    return {
      payload: {
        platformType,
      },
    };
  }
);

export const checkInstagramPostOverlapping = createAction(
  CHECK_INSTAGRAM_POST_OVERLAPPING.TRIGGER,
  function prepare({ postId, accounts, isNow, when, done }) {
    return {
      payload: {
        postId,
        accounts,
        isNow,
        when,
        done,
      },
    };
  }
);

export const togglePlatformLink = createAction(TOGGLE_POST_FORM_PLATFORM_LINK);
