import { createEffect, createEvent, forward, restore } from "effector";
import { toast } from "react-toastify";
import { useStore } from "effector-react";

import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

interface CurrentUser {
  plan: string;
  role: string;
}

export const currentUserChanged = createEvent<CurrentUser | any>();
export const $currentUser = restore(currentUserChanged, {});

export function useCurrentUser(): any {
  return useStore($currentUser);
}

export function useUserCurrency(): string {
  const user = useCurrentUser();
  return user?.currency?.toUpperCase() ?? "USD";
}

export const subscriptionChanged = createEvent();
const updateSubscriptionInfoFx = createEffect(() => {
  window.location.reload();
});

updateSubscriptionInfoFx.failData.watch((err) => {
  toast.error(getUserFriendlyErrorMessage(err));
});

forward({
  from: subscriptionChanged,
  to: updateSubscriptionInfoFx,
});

export function useTimePreferences(): string {
  const user = useCurrentUser();
  return user?.preferences?.timePreference ?? "24h";
}

const DateTimeFormats = {
  "24h": "H:mm",
  "12h": "h:mm a",
};

export function useUserTimeFormat(): string {
  const timePreferences = useTimePreferences();
  return DateTimeFormats[timePreferences];
}
