import _ from "lodash";

export const getFirstForm = (state, formId = null) => {
  const id = formId ? formId : state.forms.postForms.allIds[0];
  return state.forms.postForms.byId[id];
};

export const getFormById = (state, formId) => {
  return state.forms.postForms.byId[formId];
};

export const getOriginalPost = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.originalPost : undefined;
};

export const getOriginalPostState = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.originalPostState : undefined;
};

export const getFormPost = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.post : undefined;
};

export const getDescriptions = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  if (form && form.post.mediaFiles) {
    const descriptions = form.post.mediaFiles.map((mediaFile) => {
      // only return object if the file has a description
      if (mediaFile.description) {
        return {
          description: mediaFile.description,
          thumbnailUrl: mediaFile.thumbnailUrl,
        };
      } else {
        // if no description return null - will be removed in _.compact()
        return null;
      }
    });

    return _.compact(descriptions);
  } else {
    return [];
  }
};

export const getFormAccounts = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.accounts : undefined;
};

export const getPostState = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.postState : undefined;
};

export const getSelectedPlatform = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.view.selectedPlatform : undefined;
};

export const getVisibleFields = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.view.visibleFields : undefined;
};

export const getFormErrors = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.validation.errors : undefined;
};

export const getFormWarnings = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.validation.warnings : undefined;
};

export const getPlatforms = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.platforms : [];
};

export const getPlatformFields = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form ? form.platformFields : undefined;
};

export const getPlatformPost = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  if (!form) {
    return undefined;
  }
  let post = { ...form.post, _id: formId };
  let selectedPlatform = form.view.selectedPlatform;

  let platformTypes = _.map(form.platforms, "TYPE");
  if (selectedPlatform === "LINKED") {
    post["platformType"] = platformTypes.includes("IG")
      ? "IG"
      : post.platformType;
  } else {
    post = {
      ...post,
      ...form.platformFields[selectedPlatform],
      platformType: selectedPlatform,
    };
  }

  return post;
};

export const getFormResults = (state, formId = null) => {
  const form = formId ? getFormById(state, formId) : getFirstForm(state);
  return form?.results ?? null;
};
