import axios from "axios";
import mime from "mime-types";

type FileObj = {
  url?: string;
  type?: string;
  mimeType?: string;
};

export function isVideoFile(file: FileObj) {
  return (
    file.url?.includes(".mp4") ||
    file.type?.includes("video") ||
    file.mimeType?.includes("video")
  );
}

function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}

export async function getImageDimensions(
  file: string | Blob
): Promise<{ height: number; width: number }> {
  return new Promise(async (resolve) => {
    const img = new Image();

    img.onload = function () {
      const height = img.height;
      const width = img.width;

      resolve({
        height,
        width,
      });
    };

    img.src = typeof file === "string" ? file : await blobToBase64(file);
  });
}

export async function loadUrlAsBlob(
  url: string,
  { onProgress }: { onProgress(value: number): void }
) {
  const { data: blob } = await axios.get(url, {
    responseType: "blob",
    onDownloadProgress: ({ loaded, total }) => {
      onProgress((loaded / total) * 100);
    },
  });
  return blob;
}

// TODO: seems like this loadBlob function does useless work
// since promise is resolved with the same blob object it gets as an argument
export function loadBlob(blob: File): Promise<Blob> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(blob);
    };
  });
}

export function getImageFromFile(file: File): Promise<string> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.addEventListener("load", function () {
      resolve(this.result);
    });
  });
}

export function getFileTypeByName(
  name: string
): "video" | "image" | "document" {
  const mimeType = mime.lookup(name);
  // images *excluding gif* should be uploaded as images
  if (mimeType && mimeType.includes("image") && !mimeType.includes("gif")) {
    return "image";
    // GIFs and video files should be passed in as video
  } else if (
    mimeType &&
    (mimeType.includes("gif") || mimeType.includes("video"))
  ) {
    return "video";
  } else if (mimeType && mimeType.includes("pdf")) {
    return "document";
  } else {
    throw new Error("Unsupported file type " + name + " " + mimeType);
  }
}

export function getFileExtension(filename: string) {
  return filename.split(".").pop();
}
