import React, { useState, useEffect } from "react";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { FIELDS, GMB_TOPICS, GMB_CTAS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";
import { Alert, AlertType } from "ui/alert";

const PostCallToAction = ({
  topicType,
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  errors,
  className = "",
}) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    let { gmbCallToAction } = post;
    if (gmbCallToAction && gmbCallToAction.actionType) {
      setSelected(gmbCallToAction.actionType);
    }
  }, []);

  let CTA = GMB_CTAS;

  let config = [
    {
      value: null,
      label: "No button",
    },
    {
      value: CTA.BOOK.VALUE,
      label: CTA.BOOK.LABEL,
    },
    {
      value: CTA.ORDER.VALUE,
      label: CTA.ORDER.LABEL,
    },
    {
      value: CTA.SHOP.VALUE,
      label: CTA.SHOP.LABEL,
    },
    {
      value: CTA.LEARN_MORE.VALUE,
      label: CTA.LEARN_MORE.LABEL,
    },
    {
      value: CTA.SIGN_UP.VALUE,
      label: CTA.SIGN_UP.LABEL,
    },
    {
      value: CTA.CALL.VALUE,
      label: CTA.CALL.LABEL,
    },
  ];

  const handleCtaChange = (item) => {
    setSelected(item && item.key ? item.key : item);
    onChange({ [FIELDS.GMB_CTA]: item && item.key ? item.key : item });
  };

  const forTopics = [
    GMB_TOPICS.STANDARD.VALUE,
    GMB_TOPICS.EVENT.VALUE,
    GMB_TOPICS.ALERT.VALUE,
  ];
  if (!forTopics.includes(topicType)) {
    return "";
  }

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Add a call to action button (optional)
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.GMB_CTA}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <p className="small">
        The call to action button URL will be set to the 'Website or product
        URL' field above.
      </p>
      <Select
        options={config}
        value={selected}
        defaultItem={config[0]}
        onChange={handleCtaChange}
        disabled={disabled}
      />
      {!topicType && (
        <Alert type={AlertType.error} icon={true}>
          Pick a topic type to enter other GMB values.
        </Alert>
      )}
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.GMB_CTA]}
        errors={errors}
      />
    </div>
  );
};

export default PostCallToAction;
