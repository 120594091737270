import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "libs/modals";
import { FileToUpload } from "api/media-library";
import { ADD_FRONTIFY_MODAL } from ".";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useTrackEvent } from "libs/analytics";
import { ASSETS_IMPORTED_FRONTIFY } from "constants/Events";

interface FrontifyMediaModalProps {
  limit: number;
  onSelect(files: FileToUpload[]): void;
}

export const FrontifyMediaModal: React.FC<FrontifyMediaModalProps> = ({
  limit,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  function closeFrontifyModal() {
    dispatch(closeModal({ type: ADD_FRONTIFY_MODAL }));
  }

  const initFrontify = async () => {
    try {
      const element = document.getElementById("frontify-finder");
      if (!element) {
        toast.error("Failed to find the Frontify element in the DOM");
        return;
      }

      const finder = await window.FrontifyFinder.create({
        clientId: "skedsocial",
        options: {
          allowMultiSelect: true,
        },
      });

      if (!finder) {
        toast.error("Failed to initialize Frontify Finder");
        dispatch(closeModal({ type: ADD_FRONTIFY_MODAL }));
        return;
      }

      finder.mount(element);
      finder.onAssetsChosen(async (assets: any[]) => {
        const dataFiles = assets.map(
          ({
            filename,
            downloadUrl,
            type,
            tags,
            description,
            title,
            extension,
          }) => ({
            name: filename,
            title: title,
            extension: extension,
            url: downloadUrl,
            type: type,
            tags: tags,
            description: description,
          })
        );

        const files: FileToUpload[] = dataFiles
          .slice(0, limit)
          .map((frontifyFile) => ({
            contentType: "url",
            name: frontifyFile.title
              ? `${frontifyFile.title}.${frontifyFile.extension}`
              : frontifyFile.name,
            url: frontifyFile.url,
            tag: frontifyFile.tags
              ? frontifyFile.tags.map(
                  (perTag: { value: string }) => perTag.value
                )
              : [],
            description: frontifyFile.description,
            type: frontifyFile.type.toLowerCase().includes("image")
              ? "image"
              : frontifyFile.type.toLowerCase().includes("video")
              ? "video"
              : "document",
          }));
        try {
          trackEvent({
            eventName: ASSETS_IMPORTED_FRONTIFY,
            useServices: ["AMP", "AC"],
          });

          onSelect(files);
          dispatch(closeModal({ type: ADD_FRONTIFY_MODAL }));
        } catch (e) {
          toast.error(`Error processing selected files: ${e.message}`);
        }
      });
      finder.onCancel(() => closeFrontifyModal());
    } catch (e) {
      toast.error(`Unexpected error: ${e.message}`);
      dispatch(closeModal({ type: ADD_FRONTIFY_MODAL }));
    }
  };

  return (
    <div>
      <Modal
        data-testid="add-user-modal"
        onShow={async () => {
          await initFrontify();
        }}
        show
        onHide={closeFrontifyModal}
        animation={false}
        bsSize="lg"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={closeFrontifyModal}>
            &times;
          </button>
          <h4 className="modal-title">Import Frontify Media Files</h4>
        </div>
        <div className="modal-body">
          <FrontifyFinder id="frontify-finder"></FrontifyFinder>
        </div>
      </Modal>
    </div>
  );
};

const FrontifyFinder = styled.div`
  height: 80vh;
`;
