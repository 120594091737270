import { Tool, ExportFormat, ImageFormat } from "photoeditorsdk";

const tools = [
  Tool.TRANSFORM,
  Tool.FILTER,
  Tool.ADJUSTMENT,
  Tool.FOCUS,
  Tool.TEXT,
  Tool.TEXT_DESIGN,
  Tool.STICKER,
  Tool.BRUSH,
  Tool.FRAME,
  Tool.OVERLAY,
];
const customTransforms = [
  {
    identifier: "instagram",
    name: "Instagram",
    items: [
      {
        identifier: "portrait_ig",
        name: "Max Portrait (0.8:1/4:5)",
        ratio: 1080 / 1350,
      },
      {
        identifier: "square_ig",
        name: "Square",
        ratio: 1,
      },
      {
        identifier: "landscape_ig",
        name: "Max Landscape (1.91:1)",
        ratio: 1080 / 566,
      },
      {
        identifier: "story_ig",
        name: "IG Story",
        ratio: 1080 / 1920,
      },
    ],
  },
  {
    identifier: "pinterest",
    name: "Pinterest",
    items: [
      {
        identifier: "pinterest_recommended",
        name: "2:3 - recommended",
        ratio: 1000 / 1500,
      },
    ],
  },
  {
    identifier: "linkedin",
    name: "LinkedIn",
    items: [
      {
        identifier: "linkedin_recommended",
        name: "LI recommended",
        ratio: 1200 / 627,
      },
    ],
  },
];
const customFrames = [
  {
    identifier: "white-plain-frame",
    name: "White",
    layoutMode: "horizontal-inside",
    thumbnailURI: "items/simple/icon.png",
    tintable: false,
    imageGroups: {
      top: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
      left: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
      right: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
      bottom: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
    },
  },
  {
    identifier: "black-plain-frame",
    name: "Black",
    layoutMode: "horizontal-inside",
    thumbnailURI: "items/black/icon.png",
    tintable: false,
    imageGroups: {
      top: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
      left: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
      right: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
      bottom: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
    },
  },
];
export const config = {
  // yes weirdly the license is meant to be delivered as a string
  license:
    '{"owner":"Dialogue Consulting Pty Ltd","version":"2.1","enterprise_license":false,"available_actions":["magic","filter","transform","sticker","text","adjustments","brush","focus","frames","camera"],"features":["adjustment","filter","focus","overlay","transform","text","sticker","frame","brush","library","export"],"platform":"HTML5","app_identifiers":["localhost","127.0.0.1","app.schedugr.am","staging.schedugr.am","staging.skedsocial.com","app.skedsocial.com","testdrive.skedsocial.com"],"api_token":"zggCndbLszjRTLAJQLDW6g","domains":["https://api.photoeditorsdk.com"],"issued_at":1556515728,"expires_at":null,"signature":"RegTAvuyGKgwX6Wf3VE0VdSv4zn7K9nckV9ipcE5sB5wJ1ZOgvGqZHFJx/qE4vShROV4UauibjWmKpPGYwSelhvQnJMwlHK8ERth0/60WyIEXrv0p3MdSn8G1j9yGBJfyIOc0zfIXPR37DpSsRJm+qGeHQLaANKO9GgEuxoQRK7m5rb0fSHwNoTSwEgIhpliAC2qoE9zCCFJ8XfFaCEmawXZcbF5pI6XUGB3T1uD9oBwn5bAcgwqlV73JDsBOMpUjbEe+QRp24KGnKHAsvrTKrt/iwyvclNMHTYPUuDD2/o2a2wrmSogxjS5AYAbEWsn+0XQsgPNW0afZomDFU4bfUEaO3m4Y/NJ8wml1OnrGWbthbIjFCpVMcuWQF9/4Q5hfHXycqMc+JS/21mJbbe3b6TXl235Cj8eRhsQ5/0GgIDhb+t51e6j/qgzks9uTjeB543gw7DYMoPy2+hsonefzKoEObIKWqYN3zVYudLYeeoNOlEkOFM9CI1V3J8/q8BtBBwd+xwc4/I0PHDx/CjvFNV/h/6VjHKBEFlNCpMK2UOEaTndiPnrmLv3awItJXtDfuosoPreOio04BV9q7i1r1uwxYkGKMjYaKUQjq/fgwLVjjboNV9PZZvz5BgRt4TNK4I6JTYHQLQw3O7nCDF1i1zpH5JavDQesqVRu2CxVtY="}',
  assetBaseUrl: "/assets/resources/photoeditor",
  title: "Sked Image Editor",
  custom: {
    languages: {
      en: {
        mainCanvasActions: {
          buttonExport: "Save",
        },
      },
    },
  },
  responsive: true,
  tools,
  engine: {
    downscaleOptions: {
      maxMegaPixels: {
        desktop: 9,
        mobile: 5,
      },
    },
  },
  displayResizeWarning: true,
  export: {
    image: {
      format: ImageFormat.JPEG,
      exportType: ExportFormat.BLOB,
      enableDownload: false,
      quality: 1,
    },
  },
  // probably does nothing after migrating to v5
  library: {
    enableUpload: false,
    enableWebcam: false,
  },
  frame: {
    items: [
      ...customFrames,
      { identifier: "imgly_frame_dia" },
      { identifier: "imgly_frame_art_decor" },
      { identifier: "imgly_frame_black_passepartout" },
      { identifier: "imgly_frame_wood_passepartout" },
      { identifier: "imgly_frame_lowpoly_shadow" },
    ],
  },
  transform: {
    categories: [
      ...customTransforms,
      {
        identifier: "imgly_transforms_common",
      },
      {
        identifier: "imgly_transforms_facebook",
      },
      {
        identifier: "imgly_transforms_twitter",
      },
    ],
  },
  displayCloseButton: true,
};
