import { PostContentType } from "types/postsFilters";

export const postsContentOptions: PostContentType[] = [
  { label: "Story", value: "story", isSelected: false, relatedTo: ["IG"] },
  {
    label: "Reels",
    value: "reel",
    isSelected: false,
    relatedTo: ["IG"],
  },
  {
    label: "Text",
    value: "text",
    isSelected: false,
    relatedTo: ["FB", "TW", "LI", "SC", "TH"],
  },
  {
    label: "Single Image",
    value: "image",
    isSelected: false,
    relatedTo: ["IG", "FB", "TW", "PI", "LI", "TT", "TH", "SC"],
  },
  {
    label: "Single Video",
    value: "video",
    isSelected: false,
    relatedTo: ["IG", "FB", "TW", "YT", "TT", "TH", "SC", "PI", "LI"],
  },
  {
    label: "Carousel",
    value: "multiMedia",
    isSelected: false,
    relatedTo: ["IG", "FB", "TW", "LI", "TT", "TH", "SC"],
  },
];

export const postsContentAllOption: PostContentType = {
  label: "All post types",
  value: "*",
  relatedTo: ["IG", "FB", "TW", "YT", "LI", "TT", "TH", "SC", "PI"],
};
