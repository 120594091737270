import React, { MouseEvent } from "react";
import { useOpenableState } from "libs/use-openable-state";
import { ConfirmModal } from "./confirm-modal";

interface Props
  extends Omit<React.ComponentProps<typeof ConfirmModal>, "onCancel" | "show"> {
  children(props: { onClick(): void }): React.ReactNode;
  dialogClassName?: string;
  confirmType?: { alert: boolean };
}

export function ConfirmRequired(props: Props): JSX.Element {
  const confirmModal = useOpenableState();
  const preventClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleConfirmClick = () => {
    props.onConfirm();
    confirmModal.close();
  };

  const handleCloseClick = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      preventClick(event);
    }
    confirmModal.close();
  };

  return (
    <div onClick={preventClick}>
      {props.children({ onClick: confirmModal.open })}
      <ConfirmModal
        dialogClassName={props.dialogClassName}
        show={confirmModal.isOpened}
        title={props.title}
        message={props.message}
        cancelText={props.cancelText}
        confirmText={props.confirmText}
        confirmType={props.confirmType}
        onConfirm={handleConfirmClick}
        onCancel={handleCloseClick}
      />
    </div>
  );
}
