/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import { AxiosError } from "axios";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTrackEvent } from "libs/analytics";
import CopyIcon from "assets/icons/ai-caption/copyIcon.svg";
import { COPY_IDEA, UPDATE_TO_CAPTIVATE_COPY_IDEA } from "constants/Events";
import TextareaAutosize from "react-textarea-autosize";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import type { Label } from "../../entities/posts/components/templates/post-form-base-template";
import { useManualCaptivate } from "./useManualCaptivate";
import { CaptivateForm } from "./CaptivateForm";
import { ExceedUsage } from "./ExceedUsage";
import { Header } from "./shared-components";
import { insightsApi } from "api/insightsApi";
import type { AccountType } from "shared/types/accounts";
import { useSelector } from "react-redux";
import { getAccounts } from "selectors/skedCoreSelectors";
import QuokkaReadingImg from "assets/icons/ai-caption/quokka-reading.png";

export const Wrap = styled.div`
  position: relative;
  padding: 18px 22px 22px;
  background: linear-gradient(
    195.61deg,
    rgba(250, 79, 56, 0.2) 8.55%,
    rgba(37, 9, 198, 0.2) 93.35%
  );
  border-radius: 5px;
  height: auto;
`;

export const Explainer = styled.div`
  margin-top: 52px;
  display: grid;
  place-items: center;

  p {
    font-size: 14px;
  }

  img {
    margin-top: 38px;
    max-width: 266px;
  }
`;

export const Title = styled.p`
  line-height: initial;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 7px;
  align-items: center;

  span {
    color: var(--neutral-n-500, #808080);
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 325;
    line-height: 16px; /* 133.333% */
  }
`;

export const Textarea = styled(TextareaAutosize)`
  padding: 8px 12px;
  width: 100%;
  border: 1px solid #dcdcdc;
  height: 74px;
  border-radius: 5px;
  font-size: 14px;
  resize: vertical;

  ::placeholder {
    color: #797979;
    opacity: 1; /* Firefox */
  }
`;

export const TextareaExtraDesc = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  right: 0;
  color: #797979;
  pointer-events: none;
  margin-bottom: 16px;

  svg {
    width: 5px;
    height: 5px;
    margin-left: 4px;
  }
`;

const CaptionItemWrap = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

const UserInputText = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  color: #454545;
`;

const CaptionsTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #454545;
  line-height: 17px;
`;

const CaptionItem = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 12px;
  padding-right: 30px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CopyIconWrap = styled.div`
  position: absolute;
  right: 12px;
  bottom: 8px;
  cursor: pointer;

  svg {
    height: 19px;
    width: 16px;
  }
`;

const QuokkaWrap = styled.div`
  display: grid;
  place-content: center;
  margin-top: 30px;

  > img {
    width: 220px;
  }
`;

interface AICaptionProps {
  isContentLabelFeatureEnabled: boolean;
  contentLabels: Label[];
  selectedAccountIds: string[];
  selectedAccountGroupId: string;
}

const AICaption: React.FC<AICaptionProps> = ({
  isContentLabelFeatureEnabled,
  contentLabels,
  selectedAccountIds,
  selectedAccountGroupId,
}) => {
  const [captions, setCaptions] = useState<string[]>([]);
  const [exceedUsage, setExceedUsage] = useState("");

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const allAccounts = useSelector(getAccounts);
  const selectedAccounts = allAccounts
    .filter((account: AccountType) => selectedAccountIds.includes(account._id))
    .map((account: AccountType) => ({
      _id: account._id || "",
      platformId: account.platformId || "",
      platformType: account.platformType || "",
    }));

  const trackEvent = useTrackEvent();
  const {
    placeholderText,
    setUserInput,
    userInput,
    includeEmoji,
    fetchingCaptions,
    setIncludeEmoji,
    handleCreateButtonClick,
  } = useManualCaptivate({ textareaRef });

  const [fetchingCaptionsIncludeLabel, setFetchingCaptionsIncludeLabel] =
    useState(false);
  const [includeContentLabel, setIncludeContentLabel] = useState(false);

  const handleFetchCaptionsIncludeLabel = async (
    prompt: string,
    includeEmoji: boolean
  ) => {
    setFetchingCaptionsIncludeLabel(true);
    setExceedUsage("");

    try {
      const response = await insightsApi.getSuggestionsCaptions({
        nCaptions: 5,
        accounts: selectedAccounts,
        userInput: prompt,
        labelIds: contentLabels.map((label) => label.value),
        groupId: selectedAccountGroupId,
        includeEmoji,
      });

      setCaptions(response.captions || []);
    } catch (error) {
      setExceedUsage((error as AxiosError).response?.data?.message);
    } finally {
      setFetchingCaptionsIncludeLabel(false);
    }
  };

  if (exceedUsage) {
    {
      /* Include an error boundary because we don't really care about failures here*/
    }
    return <ExceedUsage exceedUsage={exceedUsage} />;
  }

  const hasCaptions = captions.length > 0;

  return (
    <ErrorBoundary>
      <Wrap>
        <Header description="Create 5 caption ideas about:" />
        <CaptivateForm
          textareaRef={textareaRef}
          placeholderText={placeholderText}
          setUserInput={setUserInput}
          userInput={userInput}
          fetchCaptions={handleFetchCaptionsIncludeLabel}
          includeEmoji={includeEmoji}
          fetchingCaptions={fetchingCaptions}
          setIncludeEmoji={setIncludeEmoji}
          handleCreateButtonClick={handleCreateButtonClick}
          hasCaptions={hasCaptions}
          handleNewIdeaClick={() => {
            setCaptions([]);
            setExceedUsage("");
          }}
          includeContentLabel={includeContentLabel}
          setIncludeContentLabel={setIncludeContentLabel}
          fetchingCaptionsIncludeLabel={fetchingCaptionsIncludeLabel}
          isContentLabelFeatureEnabled={isContentLabelFeatureEnabled}
          contentLabels={contentLabels}
        />

        {hasCaptions ? (
          <div>
            <UserInputText>{userInput}</UserInputText>
            <CaptionsTitle>Copy to your caption box and edit.</CaptionsTitle>
            <CaptionItemWrap>
              {captions.map((text) => (
                <CaptionItem key={text}>
                  {text}
                  <CopyIconWrap>
                    <CopyToClipboard
                      text={text.trim()}
                      onCopy={() => {
                        if (
                          (includeContentLabel && contentLabels?.length > 0) ||
                          includeEmoji
                        ) {
                          trackEvent({
                            eventName: COPY_IDEA,
                            metadata: {
                              "labels on":
                                includeContentLabel &&
                                contentLabels?.length > 0,
                              "emoji on": includeEmoji,
                            },
                          });
                          trackEvent({
                            eventName: UPDATE_TO_CAPTIVATE_COPY_IDEA,
                            metadata: {
                              "labels on":
                                includeContentLabel &&
                                contentLabels?.length > 0,
                              "emoji on": includeEmoji,
                            },
                          });
                        }
                      }}
                    >
                      <CopyIcon />
                    </CopyToClipboard>
                  </CopyIconWrap>
                </CaptionItem>
              ))}
            </CaptionItemWrap>
          </div>
        ) : (
          !fetchingCaptionsIncludeLabel && (
            <QuokkaWrap>
              <img src={QuokkaReadingImg} alt="quokka reading" />
            </QuokkaWrap>
          )
        )}
      </Wrap>
    </ErrorBoundary>
  );
};

export default AICaption;
