import { AccountType } from "shared/types/accounts";

function addFullStop(str?: string): string | undefined {
  if (str && str[str.length - 1] !== ".") {
    return str + ".";
  } else {
    return str;
  }
}

export function getAccountFailReasonMessage(
  account: AccountType,
  defaultMessage = "Something went wrong - there is not a failure message for this account. Please try reconnecting or ask our team for assistance."
): string {
  // userDisplayReason will not include a full stop at the end for the most common messages
  return (
    addFullStop(account.userDisplayReason) ??
    account.failReason ??
    defaultMessage
  );
}

export function tryReconnectingAccountError(account: AccountType) {
  return (
    account.status === "failed" &&
    account.failReason &&
    /try reconnecting this account/.test(account.failReason)
  );
}
