import { combine, createEffect, restore } from "effector";
import { toast } from "react-toastify";

import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";
import { getCollections } from "api/media-library";
import { User } from "features/user";
import { isFullLibrary } from "features/media-library";

export const getCollectionsFx = createEffect(async (user?: User) => {
  const collections = await getCollections();
  if (isFullLibrary(user)) {
    return collections;
  } else {
    return collections.filter((c) => c.isSystemInbox);
  }
});

getCollectionsFx.failData.watch((err) => {
  toast.error(getUserFriendlyErrorMessage(err));
});

export const $collections = restore(getCollectionsFx.doneData, []);

export const $store = combine({
  collections: $collections,
  loadingError: restore(getCollectionsFx.failData, null),
  isLoading: getCollectionsFx.pending,
});
