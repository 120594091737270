import axios from "axios";

import { skedApi } from "../_skedApi";

export async function uploadFileToS3(
  { blob, fileName }: { blob: Blob; fileName: string },
  options?: {
    onProgress(progress: number): void;
  }
) {
  const { uploadUrl, fileUrl, mimeType } = await getSignedUrl(fileName);
  const headers = {};
  if (mimeType) {
    headers["Content-Type"] = mimeType;
  }
  await axios.put(uploadUrl, blob, {
    onUploadProgress: ({ loaded, total }) => {
      options?.onProgress(((loaded / total) * 100 + 100) / 2);
    },
    headers,
  });

  return fileUrl;
}

interface GetSignedUrlResponse {
  fileUrl: string;
  uploadUrl: string;
  mimeType?: string;
}

async function getSignedUrl(fileName: string): Promise<GetSignedUrlResponse> {
  const response = await skedApi.post<GetSignedUrlResponse>(
    "/content-inbox/get-signed-url",
    {
      fileName,
    }
  );
  return response.data;
}
