import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { PostFieldLabel, PlatformUsage } from "components/Posts/PostForm";
import { DeprecatedLink } from "ui/Link";
import { FIELDS } from "constants/PostFormFields";
import {
  hideModal,
  showPostLocationModal,
  showPostUserTagsModal,
  showPostProductTagsModal,
} from "actions/modal";
import { toast } from "react-toastify";
import { POST_TYPES } from "constants/PostsView";
import { QuestionTooltip } from "ui/quetion-tooltip";
import { openModal } from "libs/modals";
import { ADD_COLLABORATOR_MODAL } from "../components/CollaboratorsModal/index";
import {
  localStorageFeatureToggle,
  FeatureName,
} from "utils/feature-toggle-utils";

const PostTags = ({
  post,
  selectedPlatform,
  platforms,
  visibleFields,
  className = "",
  onChange,
  handleOpenAddAlt,
  isSummary,
  hasTikTokPosting,
}) => {
  const dispatch = useDispatch();

  const location = useMemo(() => {
    return post.location;
  }, [post]);

  const collaboratorIg = post.collaboratingIgUsers;
  const userTags = useMemo(() => {
    return post.tags || [];
  }, [post]);

  const productTags = useMemo(() => {
    return post.productTags || [];
  }, [post]);

  const canAltText = true;
  const hasAlt =
    canAltText &&
    (post.type === POST_TYPES.MULTI_MEDIA ||
      post.type === POST_TYPES.PHOTO ||
      post.type === POST_TYPES.IMAGE);

  const altText = useMemo(() => {
    if (!post) {
      return [];
    }
    if (!post.altText) {
      if (post.mediaFiles && post.mediaFiles.length < 1) {
        return [];
      } else {
        // continue - use post.alttext if no mediaFiles items
      }
    }
    if (post.mediaFiles && post.mediaFiles.length > 0) {
      return post.mediaFiles.flatMap((file) =>
        file.altText ? file.altText : []
      );
    } else {
      return post.altText ? [post.altText] : [];
    }
  }, [post]);

  function getIGAccountId() {
    let accountId;
    if (post.accountIds?.length > 1) {
      const result = post.accounts.find(
        (perAccount) =>
          perAccount.platformName === "instagram" ||
          perAccount.platformType === "IG"
      );
      accountId = result._id;
    } else {
      accountId = post.accountIds;
    }
    return accountId;
  }

  // We can't attach any tags to videos
  const firstMediaIsVideo = useMemo(() => {
    if (!post.mediaFiles || post.mediaFiles.length === 0) {
      return false;
    }

    let firstMedia = post.mediaFiles[0];

    return firstMedia.isVideo;
  }, [post]);

  const alertFirstMediaVideo = () => {
    toast.error("You can't tag the post if the first media asset is a video.");
  };

  const dispatchUserTagModal = async () => {
    if (!firstMediaIsVideo) {
      dispatch(
        showPostUserTagsModal({
          post: post,
          onSubmit: (newTags) => {
            onChange(FIELDS.USER_TAGS, newTags);
            dispatch(hideModal());
          },
        })
      );
    } else {
      alertFirstMediaVideo();
    }
  };

  const dispatchProductTagModal = () => {
    if (firstMediaIsVideo) {
      alertFirstMediaVideo();
      return;
    }
    dispatch(
      showPostProductTagsModal({
        post: post,
        onSubmit: (newTags) => {
          onChange(FIELDS.PRODUCT_TAGS, newTags);
          dispatch(hideModal());
        },
      })
    );
  };

  const dispatchLocationModal = () => {
    if (firstMediaIsVideo) {
      alertFirstMediaVideo();
      return;
    }
    dispatch(
      showPostLocationModal({
        location: post.location,
        onSubmit: (newLocation) => {
          onChange(FIELDS.LOCATION_TAG, newLocation);
          dispatch(hideModal());
        },
      })
    );
  };
  const dispatchCollaboratorModal = () => {
    dispatch(
      openModal({
        type: ADD_COLLABORATOR_MODAL,
        props: {
          collaboratorIg: collaboratorIg,
          beta: true,
          title: "Add Collaborators",
          notification:
            "If they accept, their usernames will be added to the Instagram post. It will be shared to their followers and shown on their profile. Maximum 3 collaborators.",
          limit: 3,
          onSubmit: (Collaborator) => {
            const collaboratorsNames = Collaborator.map((aCollaborator) => {
              return {
                username: aCollaborator.username,
                profilePictureUrl: aCollaborator.profilePictureUrl,
              };
            });
            onChange(FIELDS.COLLABORATOR_TAG, collaboratorsNames);
            if (Collaborator.length > 0) {
              toast.success(
                `Pow! ${Collaborator.length} collaborator(s) added. `
              );
            }
          },
          accountId: getIGAccountId(),
        },
      })
    );
  };
  // only show the field label and field if any of the fields are visible
  // so that it doesn't show a heading with an empty section
  if (
    visibleFields.includes(FIELDS.LOCATION_TAG) ||
    visibleFields.includes(FIELDS.USER_TAGS) ||
    visibleFields.includes(FIELDS.PRODUCT_TAGS) ||
    (hasAlt && visibleFields.includes(FIELDS.ALT_TEXT))
  ) {
    const hasProductTags = productTags.length > 0;
    const hasUserTags = userTags.length > 0;

    const hasUserAndProductTags = hasProductTags && hasUserTags; // for access for legacy post to change tags value

    const productTagsCanBeChanged = !hasUserTags || hasUserAndProductTags;
    const userTagsCanBeChanged = !hasProductTags || hasUserAndProductTags;

    return (
      <div className={`${className} tw-flex tw-flex-col`}>
        {!hasTikTokPosting && (
          <PostFieldLabel className="tw-mr-8">
            Other post information
          </PostFieldLabel>
        )}
        <table className={``}>
          <tbody>
            {/* Location Tag */}
            {visibleFields.includes(FIELDS.LOCATION_TAG) && (
              <tr>
                <td className="tw-pt-4">
                  <PlatformUsage
                    platforms={platforms}
                    field={FIELDS.LOCATION_TAG}
                    selectedPlatform={selectedPlatform}
                  />
                </td>
                <td className="tw-pr-4 tw-pt-4">
                  {location && location.name ? location.name : "No location"}
                </td>
                {!isSummary && (
                  <td className="tw-pt-4" onClick={dispatchLocationModal}>
                    <Action>{location ? "Edit" : "Add"} location</Action>
                  </td>
                )}
              </tr>
            )}
            {/* Collaboration Tag */}
            {localStorageFeatureToggle.get(FeatureName.IgCollab) &&
              visibleFields.includes(FIELDS.COLLABORATOR_TAG) && (
                <tr>
                  <td className="tw-pt-4">
                    <PlatformUsage
                      platforms={platforms}
                      field={FIELDS.COLLABORATOR_TAG}
                      selectedPlatform={selectedPlatform}
                    />
                  </td>
                  <td className="tw-pr-4 tw-pt-4">
                    {!collaboratorIg || collaboratorIg.length === 0
                      ? "No collaborators"
                      : collaboratorIg.length === 1
                      ? "1 collaborator"
                      : `${collaboratorIg.length} collaborators`}
                  </td>
                  {!isSummary && (
                    <td className="tw-pt-4" onClick={dispatchCollaboratorModal}>
                      <CollaboratorsTagWarp>
                        <Action>
                          {collaboratorIg && collaboratorIg.length > 0
                            ? "Edit"
                            : "Add"}{" "}
                          collaborators
                        </Action>
                        <Beta>Beta</Beta>
                      </CollaboratorsTagWarp>
                    </td>
                  )}
                </tr>
              )}
            {/* User Tags */}
            {visibleFields.includes(FIELDS.USER_TAGS) && (
              <tr>
                <td className="tw-pt-4">
                  <PlatformUsage
                    platforms={platforms}
                    field={FIELDS.USER_TAGS}
                    selectedPlatform={selectedPlatform}
                  />
                </td>
                <td className="tw-pr-4 tw-pt-4">
                  {hasUserTags
                    ? `${userTags.length} user tag${
                        userTags.length === 1 ? "" : "s"
                      }`
                    : "No user tags"}
                </td>
                {!isSummary && (
                  <td className="tw-pt-4">
                    <Action
                      disabled={!userTagsCanBeChanged}
                      onClick={dispatchUserTagModal}
                    >
                      {hasUserTags ? "Edit" : "Add"} user tags
                      {!userTagsCanBeChanged && (
                        <QuestionTooltip>
                          User tags cannot be added if product tags are added.
                          Remove product tags.
                        </QuestionTooltip>
                      )}
                    </Action>
                  </td>
                )}
              </tr>
            )}
            {/* Product Tags */}
            {visibleFields.includes(FIELDS.PRODUCT_TAGS) && (
              <tr>
                <td className="tw-pt-4">
                  <PlatformUsage
                    platforms={platforms}
                    field={FIELDS.PRODUCT_TAGS}
                    selectedPlatform={selectedPlatform}
                  />
                </td>
                <td className="tw-pr-4 tw-pt-4">
                  {productTags.length > 0
                    ? `${productTags.length} product tag${
                        productTags.length === 1 ? "" : "s"
                      }`
                    : "No product tags"}
                </td>
                {!isSummary && (
                  <td className="tw-pt-4">
                    <Action
                      disabled={!productTagsCanBeChanged}
                      onClick={dispatchProductTagModal}
                    >
                      {hasProductTags ? "Edit" : "Add"} product tags
                      {!productTagsCanBeChanged && (
                        <QuestionTooltip>
                          Product tags cannot be added if user tags are added.
                          Remove user tags.
                        </QuestionTooltip>
                      )}
                    </Action>
                  </td>
                )}
              </tr>
            )}
            {hasAlt && visibleFields.includes(FIELDS.ALT_TEXT) && (
              <tr>
                <td className="tw-pt-4">
                  <PlatformUsage
                    platforms={platforms}
                    field={FIELDS.ALT_TEXT}
                    selectedPlatform={selectedPlatform}
                  />
                </td>
                <td
                  data-testid="addEditAltTextLabel"
                  className="tw-pr-4 tw-pt-4"
                >
                  {altText.length > 0
                    ? `${altText.length} alt text media`
                    : "No alt text"}
                </td>
                {!isSummary && (
                  <td
                    data-testid="addEditAltTextButton"
                    className="tw-pt-4"
                    onClick={handleOpenAddAlt}
                  >
                    <Action>
                      {altText.length > 0 ? "Edit" : "Add"} alt text
                    </Action>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
};

const Action = styled.div.attrs((props) =>
  props.disabled
    ? {
        onClick: false,
      }
    : {
        className: "tw-underline",
        as: DeprecatedLink,
      }
)`
  ${(props) =>
    props.disabled &&
    css`
      color: #c4c4c4;
      text-decoration: none;
    `};

  ${QuestionTooltip} {
    margin-left: 7px;
  }
`;

const CollaboratorsTagWarp = styled.div`
  display: flex;
  flex-direction: row;
`;

const Beta = styled.div`
  color: #808080;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  align-self: flex-start;
  margin-top: -3px;
  margin-left: -3px;
`;

export default PostTags;
