import React from "react";
import { renderContentProps } from "./types";
import { MessageMedia } from "../../components/message-attachments";
import { Attachment } from "types/conversationInbox";
import { NormalMessage } from "./normal-message";

export const IgStoryReply = (props: renderContentProps): JSX.Element | null => {
  const { story } = props;

  if (!story) return null;

  const media: Attachment = {
    type: story.type,
    payload: {
      url: story.url,
    },
  };

  return (
    <>
      <MessageMedia {...props} media={media} portraitMedia />
      <NormalMessage {...props} />
    </>
  );
};
