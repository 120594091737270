import axios from "axios";

import { AppConfig } from "app-config";

export interface StoryblockStory {
  id: number;
  uuid: string;
  name: string;
  created_at: string;
  published_at: string;
  slug: string;
  full_slug: string;
  content: {
    _uid: string;
    name: string;
    profile_pic: {
      id: number;
      alt: string;
      name: string;
      title: string;
      filename: string;
    };
    stories: Array<{
      _uid: string;
      name: string;
      type: "photo" | "video";
      link_text: string;
      length: string;
      timestamp: string;
      preview: {
        filename: string;
      };
      src: {
        filename: string;
      };
      link?: {
        linktype: string;
        url?: string;
      };
    }>;
  };
}

export async function getStories() {
  const response = await axios.request<{ stories: Array<StoryblockStory> }>({
    method: "GET",
    url: "https://api.storyblok.com/v2/cdn/stories",
    params: {
      token: AppConfig.SkedStories.Token,
      starts_with: AppConfig.SkedStories.StartWith,
      version: "published",
      sort_by: "published_at:desc",
    },
  });

  return response.data.stories;
}
