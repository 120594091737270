import { isEmpty } from "lodash";
import {
  LOAD_LIBRARY_USAGE,
  FILTER_ASSETS_ROUTINE,
  TOGGLE_SIDEBAR_FILTERS,
  SELECT_IG_ACCOUNT_ROUTINE,
  SELECT_EXISTING_HASHTAG_ROUTINE,
  SELECT_COLLECTION_ROUTINE,
  SELECT_HASHTAG_FEED_COLLECTION,
  SELECT_COLLECTION_LAYOUT,
  INIT_MEDIA_LIBRARY_PAGE_ROUTINE,
  HANDLE_INVALID_IG_ACCOUNT,
  SELECT_HASHTAG_FEED_VIEW_ROUTINE,
  CHANGE_LIBRARY_PAGE,
} from "constants/ActionTypes";
import {
  getLocalStorageLibraryFilter,
  setLocalStorageLibraryFilter,
} from "libs/storage/adapters";
import { PAGES, HASHTAG_FEED_VIEWS } from "constants/MediaLibrary";
import { createReducer } from "@reduxjs/toolkit";
import { LAYOUT_OPTIONS } from "enums/layout-options";

const getFiltersState = () => {
  const defaultFilters = {
    name: "",
    tags: [],
    description: "",
    sourceName: "",
    favorite: false,
    mediaType: null,
    usage: null,
    sort: "-created",
  };
  const storedFilters = JSON.parse(getLocalStorageLibraryFilter());

  return storedFilters || defaultFilters;
};

const initialState = {
  initialized: false,
  filtersVisible: false,
  filters: getFiltersState(),
  hashtagFeedView: HASHTAG_FEED_VIEWS.RECENT,
  selectedIgAccount: null,
  invalidIgAccount: false,
  selectedHashtag: null,
  selectedHashtagFeedCollection: null,
  page: PAGES.COLLECTIONS,
  layout: LAYOUT_OPTIONS.GRID,
  libraryUsage: {
    asset_size: null,
    asset_count: null,
  },
};

const mediaLibraryViewReducer = createReducer(initialState, {
  [FILTER_ASSETS_ROUTINE.TRIGGER]: (state, action) => {
    const {
      tags,
      name,
      description,
      sourceName,
      favorite,
      mediaType,
      sort,
      usage,
    } = action.payload;
    state.filters.tags = tags;
    state.filters.name = name;
    state.filters.description = description;
    state.filters.sourceName = sourceName;
    state.filters.favorite = favorite;
    state.filters.mediaType = mediaType;
    state.filters.usage = usage;
    state.filters.sort = sort;

    setLocalStorageLibraryFilter(JSON.stringify(action.payload));

    return state;
  },
  [LOAD_LIBRARY_USAGE]: (state, action) => {
    state.libraryUsage = action.payload;
  },
  [TOGGLE_SIDEBAR_FILTERS]: (state, action) => {
    state.filtersVisible = !state.filtersVisible;
    return state;
  },
  [SELECT_COLLECTION_ROUTINE.TRIGGER]: (state, action) =>
    clearSourceMediaSelections(state, action, PAGES.COLLECTIONS),
  [SELECT_IG_ACCOUNT_ROUTINE.TRIGGER]: (state, action) => {
    const { accountId } = action.payload;
    state.selectedIgAccount = accountId;
    state.selectedHashtag = null;
    state.invalidIgAccount = false;
    return state;
  },
  [SELECT_EXISTING_HASHTAG_ROUTINE.TRIGGER]: (state, action) => {
    const { hashtagId } = action.payload;
    state.page = PAGES.HASHTAGS;
    state.selectedHashtag = hashtagId;
    return state;
  },
  [SELECT_HASHTAG_FEED_COLLECTION]: (state, action) => {
    const { id } = action.payload;
    state.selectedHashtagFeedCollection = id;
    return state;
  },
  [SELECT_COLLECTION_LAYOUT]: (state, action) => {
    const { layout } = action.payload;
    if (LAYOUT_OPTIONS[layout]) {
      state.layout = layout;
    }
    return state;
  },
  [INIT_MEDIA_LIBRARY_PAGE_ROUTINE.TRIGGER]: (state, action) => {
    return {
      ...initialState,
      filters: getFiltersState(),
    };
  },
  [INIT_MEDIA_LIBRARY_PAGE_ROUTINE.SUCCESS]: (state, action) => {
    state.initialized = true;
    return state;
  },
  [HANDLE_INVALID_IG_ACCOUNT]: (state, action) => {
    state.invalidIgAccount = true;
    return state;
  },
  [SELECT_HASHTAG_FEED_VIEW_ROUTINE.TRIGGER]: (state, action) => {
    const { view } = action.payload;
    state.hashtagFeedView = view;
    return state;
  },
  [CHANGE_LIBRARY_PAGE]: (state, action) => {
    const { page } = action.payload;
    state.page = page;
  },
});

const clearSourceMediaSelections = (state, action, newPage) => {
  state.selectedHashtag = null;
  state.selectedHashtagFeedCollection = null;
  state.page = newPage;
  return state;
};

export default mediaLibraryViewReducer;
