import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showFeedModal } from "actions/modal";
import {
  getFeedMediaCount,
  getSelectedFeedIds,
} from "selectors/hashtagFeedSelectors";
import SaveDropdown from "./SaveDropdown.tsx";
import { PAGES } from "constants/MediaLibrary";

const FeedHeader = ({ headerText, subtitleText }) => {
  const dispatch = useDispatch();
  const selectedFeedIds = useSelector(getSelectedFeedIds);
  const feedMediaCount = useSelector((state) =>
    getFeedMediaCount(state, PAGES.HASHTAGS)
  );

  const isDisabled = selectedFeedIds.length === 0;

  return (
    <div className="FeedHeader tw-flex tw-mb-4 tw-mt-4">
      <div className="tw-flex tw-flex-col" style={{ flex: 2 }}>
        <div className="tw-flex tw-items-center">
          <div className="tw-text-4xl tw-font-black">{headerText}</div>
          {selectedFeedIds.length > 0 && (
            <div className="tw-ml-4 tw-text-l tw-text-gray-400">
              {selectedFeedIds.length} selected
            </div>
          )}
        </div>

        <div className="tw-mt-2 tw-text-gray-400">
          {subtitleText || `Displaying ${feedMediaCount} posts`}
        </div>
      </div>
      <SaveDropdown
        disabled={isDisabled}
        onSave={() => dispatch(showFeedModal())}
      />
    </div>
  );
};

export default FeedHeader;
