import { useEffect } from "react";
import { createEffect, restore } from "effector";
import { useStore } from "effector-react";
import { toast } from "react-toastify";

import { getPlans } from "api/plan-change";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

export const getPlansFx = createEffect(getPlans);

getPlansFx.failData.watch((err) => {
  toast.error(getUserFriendlyErrorMessage(err, "Could not get plans"));
});

const $plans = restore(getPlansFx.doneData, null);

export function usePlans() {
  useEffect(() => {
    getPlansFx();
  }, []);

  const plans = useStore($plans);
  const isLoading = useStore(getPlansFx.pending);

  return { plans, isLoading };
}
