import React from "react";
import styled from "styled-components";

import { openSupportChat } from "libs/support";
import { Link } from "ui/Link";

import { AddLibraryButton, Center, List, SMSplash } from "./atoms";
import demo from "./sm-splash-creators-demo.jpg";

export function SMSplashCreators() {
  return (
    <SMSplash
      title="Save content by Instagram Creators"
      subtitle="Easily find and save content from your team and advocates"
    >
      <Center>
        <AddLibraryButton />
      </Center>
      <Grid css="margin-top: calc(var(--spacing) * 3);">
        <SMSplashCreatorsContent />
        <div css="justify-self: center">
          <img
            css="width: 30vw; height: auto;"
            src={demo}
            alt="Creators functionality demo"
          />
        </div>
      </Grid>
    </SMSplash>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: calc(var(--spacing) * 2);
`;

export function SMSplashCreatorsContent() {
  return (
    <div>
      <p>
        <b>Save content by brand partners, influencers and advocates.</b>
      </p>
      <List css="margin-top: var(--spacing);">
        <li>
          <p>
            <b>Search a creator’s Instagram account</b>
          </p>
        </li>
        <li>
          <p>
            <b>Choose posts to save</b>
          </p>
          <p>
            The original creators' info is automatically saved so you can pass
            on the credit
          </p>
        </li>
        <li>
          <p>
            <b>Add it to your Library</b>
          </p>
        </li>
        <li>
          <p>
            <b>
              Share it across Instagram, Facebook, Twitter, Pinterest and
              LinkedIn
            </b>
          </p>
        </li>
      </List>
      <Center css="margin-top: calc(var(--spacing) * 2);">
        <AddLibraryButton />
      </Center>
      <Center css="margin-top: calc(var(--spacing) * 0.5);">
        <p>
          Get help from{" "}
          <Link as="button" onClick={() => openSupportChat()}>
            Sked Support
          </Link>
        </p>
      </Center>
    </div>
  );
}
