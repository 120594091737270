import axios from "axios";
import { AccountType } from "shared/types/accounts";

export async function changeAccountColor(accountId: string, color: string) {
  await axios.put(`/accounts/color/${accountId}`, {
    color,
  });
}

export async function changeAccountSetting(
  accountId: string,
  account: AccountType
) {
  // TODO: move these defaults to server-side
  const data = {
    setting: account.retrySetting ?? "conservative",
    errorNotifications: account.errorNotifications && {
      enabled: true,
      emailAddresses: account.errorNotifications?.emailAddresses || [],
    },
    successNotifications: account.successNotifications,
    weeklyInsightsEmail: {
      enabled: account.weeklyInsightsEmail?.enabled ?? false,
      emailAddresses: account.weeklyInsightsEmail?.emailAddresses ?? [],
    },
    monthlyInsightsEmail: {
      enabled: account.monthlyInsightsEmail?.enabled ?? false,
      emailAddresses: account.monthlyInsightsEmail?.emailAddresses ?? [],
    },
    url: account.url,
    loginType: account.loginType,
    forceComment: account.forceComment || false,
    changeDefaultThumb: account.changeDefaultThumb || false,
    notifyEmptyQueue: account.notifyEmptyQueue || false,
    loginAlias: account.loginAlias || null,
    utms: account.utms || {},
    notificationSettings: account.notificationSettings,
  };
  await axios.put(`/accounts/settings/${accountId}`, data);
}

interface User {
  _id: string;
  firstName: string;
  lastName: string;
}

export async function getAccountAvailableUsers(
  accountId: string
): Promise<User[]> {
  const {
    data: {
      data: { users },
    },
  } = await axios.get(`/accounts/setting/${accountId}/availableUsers`);
  return users;
}

export type AccountsCount = {
  pricingVersion: string;
  igCount: number;
  igFollowers: number;
  otherCount: number;
  igAllowed: number;
  otherAllowed: number;
  usesSkedLink: boolean;
  piCount: number;
  fbPageCount: number;
  fbGroupCount: number;
  twCount: number;
  gmbCount: number;
  ytCount: number;
  liCount: number;
  ttCount: number;
  notificationCount: number;
  notificationAllowed: number;
  maxAllowed: number;
  totalCount: number;
};

export async function getAccountsCount(): Promise<AccountsCount> {
  const { data } = await axios.get(`/accounts/count`);
  return data;
}

export async function removeAccount(account: AccountType) {
  await axios.request({ method: "delete", url: `/accounts/${account._id}` });
}

export async function getOneAccount(accountId: string): Promise<AccountType> {
  const response = await axios.request<AccountType>({
    method: "get",
    url: `/accounts/${accountId}`,
  });
  return response.data;
}

export async function pauseAccount(account: AccountType) {
  await axios.request({ method: "put", url: `/accounts/pause/${account._id}` });
  return await getOneAccount(account._id);
}

export async function reconnectManualAccount(account: AccountType) {
  await axios.request({
    method: "put",
    url: `/accounts/reconnect-manual-account/${account._id}`,
  });
  return await getOneAccount(account._id);
}

export async function createSkedLinkPublicKey(
  account_id: string
): Promise<string> {
  const {
    data: {
      data: { accountPublicKey },
    },
  } = await axios.put(`/accounts/createPublicKey/${account_id}`);
  return accountPublicKey;
}

export async function setTwoFactorKey({
  accountId,
  twoFactorKey,
}: {
  accountId: string;
  twoFactorKey: string;
}) {
  await axios.put(`/accounts/settings/twoFactorKey/${accountId}`, {
    twoFactorKey,
  });
}
