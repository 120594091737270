import { put, call, takeEvery } from "redux-saga/effects";
import { GET_TAG_TEMPLATES } from "constants/ActionTypes";
import { TagTemplate } from "../features/user/types";
import { getAllTemplates } from "api/autocomplete/templates";

function* getTagTemplates(_: void) {
  try {
    const data: TagTemplate = yield call(getAllTemplates);
    yield put({
      type: GET_TAG_TEMPLATES.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: GET_TAG_TEMPLATES.FAILURE,
      error: true,
      payload: err,
    });
  }
}

export default function* tagTemplatesSagas() {
  yield takeEvery(GET_TAG_TEMPLATES.TRIGGER, getTagTemplates);
}
