import React, { useEffect } from "react";
import styled from "styled-components";
import {
  AttachmentGenericElement,
  AttachmentGenericElementButton,
} from "types/conversationInbox";
import { MultiMediaProps } from ".";

const TemplateButton = ({
  btn,
}: {
  btn: AttachmentGenericElementButton;
}): JSX.Element => {
  if (btn.type === "open_url" && btn.url) {
    return (
      <a href={btn.url} target="_blank" rel="noopener noreferrer">
        {btn.title}
      </a>
    );
  }

  return <button>{btn.title}</button>;
};

const TemplateElement = ({
  element,
  setIsReady,
}: {
  element: AttachmentGenericElement;
  setIsReady: () => void;
}): JSX.Element => {
  const [isError, setError] = React.useState(false);

  const onError = () => {
    setIsReady();
    setError(true);
  };

  useEffect(() => {
    if (!element?.image_url) {
      setIsReady();
    }
  }, [element?.image_url, setIsReady]);

  return (
    <MediaTempalteWrapper>
      {element?.image_url && (
        <MediaTempalteImageWrapper>
          {isError ? (
            <p>No longer available in Sked</p>
          ) : (
            <img
              src={element.image_url}
              onLoad={setIsReady}
              onError={onError}
            />
          )}
        </MediaTempalteImageWrapper>
      )}
      <h4>{element.title}</h4>
      {element?.subtitle && <h5>{element.subtitle}</h5>}
      <MediaTempalteButtonWrapper>
        {(element.buttons || []).map(
          (btn: AttachmentGenericElementButton, key) => (
            <TemplateButton key={key} btn={btn} />
          )
        )}
      </MediaTempalteButtonWrapper>
    </MediaTempalteWrapper>
  );
};

export const MediaTempalte = ({
  media,
  setIsReady,
}: MultiMediaProps): JSX.Element => {
  return (
    <>
      {media.payload.generic?.elements.map(
        (element: AttachmentGenericElement, key) => (
          <TemplateElement
            key={key}
            element={element}
            setIsReady={setIsReady}
          />
        )
      )}
    </>
  );
};

const MediaTempalteWrapper = styled.div`
  background: #f8f8f8;
  border-radius: 10px 10px 0px 10px;
  padding: 8px;

  h4 {
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }

  h5 {
    font-weight: 350;
    font-size: 10px;
    line-height: 12px;
    color: #454545;
  }
`;

const MediaTempalteImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;

  p {
    font-size: 10px;
    color: #999999;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const MediaTempalteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  button,
  a {
    background: #d9d9d9;
    border-radius: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    border: none;
    padding: 14px;
    width: 100%;
    margin: 5px 0;
    text-align: center;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;
