import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const modalComponents = {};

const { reducer, actions } = createSlice({
  name: "@libs/modals",
  initialState: {
    openedModals: [],
    hiddenModals: [],
  },
  reducers: {
    openModal(state, { payload }) {
      state.openedModals.push({
        type: payload.type,
        props: payload.props,
      });
      return state;
    },
    closeModal(state, { payload }) {
      state.openedModals = state.openedModals.filter(
        (modal) => modal.type !== payload.type
      );
      return state;
    },
    toggleModalVisibility(state, { payload }) {
      const hasThisTypeOfModalOpenned = state.openedModals
        .map((m) => m.type)
        .includes(payload.type);
      if (!hasThisTypeOfModalOpenned) return state;

      if (state.hiddenModals.map((m) => m.type).includes(payload.type)) {
        state.hiddenModals = state.hiddenModals.filter(
          (modal) => modal.type !== payload.type
        );
      } else {
        state.hiddenModals.push({ type: payload.type });
      }
      return state;
    },
  },
});

export const { openModal, closeModal, toggleModalVisibility } = actions;

export function registerModal(name, Component) {
  modalComponents[name] = Component;

  return {
    open: (dispatch) => dispatch(openModal({ type: name })),
    close: (dispatch) => dispatch(closeModal({ type: name })),
  };
}

export function getReducer() {
  return {
    "@libs/modals": reducer,
  };
}

export function Modals() {
  const { openedModals, hiddenModals } = useSelector(
    (state) => state["@libs/modals"]
  );

  return (
    <>
      {openedModals.map((modal) => {
        const ModalComponent = modalComponents[modal.type];
        const isHidden = hiddenModals.map((m) => m.type).includes(modal.type);
        if (ModalComponent) {
          return (
            <ModalComponent
              key={modal.type}
              {...modal.props}
              isHidden={isHidden}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
}
