import { skedApi } from "api/_skedApi";

export default class externalApproval {
  constructor() {
    this.baseUrlThemes = "/rest/externalapprovalthemes";
    this.baseUrlLinks = "/rest/externalapprovals";
  }

  getExternalCollabThemes = () =>
    skedApi.get(`${this.baseUrlThemes}`, {
      params: {
        sort: "name",
        limit: 9999,
      },
    });
  createTheme = (name, imageUrl, companyName) =>
    skedApi.post(`${this.baseUrlThemes}`, {
      name,
      imageUrl,
      companyName,
    });
  updateTheme = (themeId, name, imageUrl, companyName) =>
    skedApi.put(`${this.baseUrlThemes}/${themeId}`, {
      name,
      imageUrl,
      companyName,
    });
  deleteTheme = (themeId) => skedApi.delete(`${this.baseUrlThemes}/${themeId}`);
  getExternalCollabLinks = () =>
    skedApi.get(`${this.baseUrlLinks}`, {
      params: {
        sort: "name",
        limit: 9999,
      },
    });
  createLink = (link) => {
    delete link._id;
    return skedApi.post(`${this.baseUrlLinks}`, link);
  };
  updateLink = (link) => skedApi.put(`${this.baseUrlLinks}/${link._id}`, link);
  deleteLink = (themeId) => skedApi.delete(`${this.baseUrlLinks}/${themeId}`);
}
