import { createAction } from "@reduxjs/toolkit";
import { HIDE_MODAL, SHOW_MODAL } from "constants/ActionTypes";
import {
  PlanPeriodUnit,
  SkedPlanCodeName,
  UpsellFeatureType,
  UpsellModalType,
} from "features/upsell/types";

export const showUpsellModal = createAction(
  SHOW_MODAL,
  function prepare(
    modalType: UpsellModalType,
    triggerFeature: UpsellFeatureType = "approvals"
  ) {
    return {
      payload: {
        modalType: "UPSELL",
        unique: true,
        modalProps: {
          modalType,
          triggerFeature,
        },
      },
    };
  }
);
export const showChangePlanUpsellConfirmModal = createAction(
  SHOW_MODAL,
  function prepare(toPlan: SkedPlanCodeName) {
    return {
      payload: {
        modalType: "UPSELL",
        unique: true,
        modalProps: {
          modalType: "upsell-confirm",
          toPlan,
        },
      },
    };
  }
);
export const showChangePeriodUpsellConfirmModal = createAction(
  SHOW_MODAL,
  function prepare(period: PlanPeriodUnit) {
    return {
      payload: {
        modalType: "UPSELL",
        unique: true,
        modalProps: {
          modalType: "upsell-confirm",
          period,
        },
      },
    };
  }
);
export const showChangeNumberOfAccountUpsellConfirmModal = createAction(
  SHOW_MODAL,
  function prepare(count: number) {
    return {
      payload: {
        modalType: "UPSELL",
        unique: true,
        modalProps: {
          modalType: "upsell-confirm",
          numberOfAccounts: count,
        },
      },
    };
  }
);

export const showLegacyUpsellModalModal = createAction(
  SHOW_MODAL,
  function prepare() {
    return {
      payload: {
        modalType: "UPSELL",
        unique: true,
        modalProps: {
          modalType: "legacy-upsell-generic",
        },
      },
    };
  }
);

export const closeUpsellModal = createAction(HIDE_MODAL, function prepare() {
  return {
    payload: {
      modalType: "UPSELL",
    },
  };
});
