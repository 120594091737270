import {
  ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE,
  GET_MENTIONED_MEDIA_ROUTINE,
  GET_TAGGED_IN_MEDIA_ROUTINE,
  GET_CREATOR_MEDIA_ROUTINE,
  HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE,
  LOAD_MORE_IG_ASSETS_ROUTINE,
  SELECT_EXISTING_HASHTAG_ROUTINE,
  SELECT_HASHTAG_FEED_VIEW_ROUTINE,
  SELECT_IG_ACCOUNT_ROUTINE,
  TOGGLE_FEED_MEDIA,
  SELECT_RECENT_SEARCH_CREATORS,
  SEARCH_CREATOR,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { PAGES } from "constants/MediaLibrary";

const initialState = {
  [PAGES.HASHTAGS]: {
    byId: {},
    allIds: [],
    cursor: null,
    allLoaded: false,
  },
  [PAGES.MENTIONED_IN]: {
    byId: {},
    allIds: [],
    cursor: null,
    allLoaded: false,
  },
  [PAGES.TAGGED_IN]: {
    byId: {},
    allIds: [],
    cursor: null,
    allLoaded: false,
  },
  [PAGES.CREATOR]: {
    byId: {},
    allIds: [],
    cursor: null,
    allLoaded: false,
  },
  recentSearchCreators: [],
  selectedCreator: null,
  selectedItems: [],
  creatorUsername: null,
};

const feedMediaReducer = createReducer(initialState, {
  [TOGGLE_FEED_MEDIA]: (state, action) => {
    const { selectedItem } = action.payload;
    if (state.selectedItems.find((item) => item.id === selectedItem.id)) {
      state.selectedItems = state.selectedItems.filter(
        (item) => item.id !== selectedItem.id
      );
    } else {
      state.selectedItems.push(selectedItem);
    }
    return state;
  },
  [HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.SUCCESS]: (state, action) =>
    setFeedMedia(state, action, PAGES.HASHTAGS),
  [LOAD_MORE_IG_ASSETS_ROUTINE.SUCCESS]: (state, action) =>
    setFeedMedia(state, action, action.payload.page),
  [GET_MENTIONED_MEDIA_ROUTINE.SUCCESS]: (state, action) =>
    setFeedMedia(state, action, PAGES.MENTIONED_IN),
  [GET_TAGGED_IN_MEDIA_ROUTINE.SUCCESS]: (state, action) =>
    setFeedMedia(state, action, PAGES.TAGGED_IN),
  [GET_CREATOR_MEDIA_ROUTINE.SUCCESS]: (state, action) => {
    const { creator } = action.payload;
    state.creatorUsername = creator.userName;
    state.selectedCreator = creator;
    state.selectedItems = [];
    state[PAGES.CREATOR] = {
      byId: {},
      allIds: [],
      cursor: null,
      allLoaded: false,
    };
    setFeedMedia(state, action, PAGES.CREATOR);
  },
  [HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      [PAGES.HASHTAGS]: {
        byId: {},
        allIds: [],
        cursor: null,
        allLoaded: false,
      },
      selectedItems: [],
    };
  },
  [SELECT_EXISTING_HASHTAG_ROUTINE.TRIGGER]: (state) => {
    return {
      ...state,
      [PAGES.HASHTAGS]: {
        byId: {},
        allIds: [],
        cursor: null,
        allLoaded: false,
      },
      selectedItems: [],
    };
  },
  [SELECT_IG_ACCOUNT_ROUTINE.TRIGGER]: () => {
    return initialState;
  },
  [ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.SUCCESS]: (state) => {
    state.selectedItems = [];
    return state;
  },
  [SELECT_HASHTAG_FEED_VIEW_ROUTINE.TRIGGER]: (state) => {
    return {
      ...state,
      [PAGES.HASHTAGS]: {
        byId: {},
        allIds: [],
        cursor: null,
        allLoaded: false,
      },
      selectedItems: [],
    };
  },
  [SELECT_RECENT_SEARCH_CREATORS.SUCCESS]: (state, action) => {
    const { creators } = action.payload;

    return {
      ...state,
      recentSearchCreators: creators,
    };
  },
  [SELECT_RECENT_SEARCH_CREATORS.FAILURE]: (state) => {
    return {
      ...state,
      recentSearchCreators: [],
    };
  },
  [SEARCH_CREATOR.SUCCESS]: (state, action) => {
    const { creator } = action.payload;

    return {
      ...state,
      selectedCreator: creator,
    };
  },
  [SEARCH_CREATOR.FAILURE]: (state) => {
    return {
      ...state,
      selectedCreator: null,
    };
  },
});

const setFeedMedia = (state, action, page) => {
  const igPostKey = "id";
  const allowedMediaTypes = ["IMAGE", "VIDEO"];

  const { cursor, igPosts } = action.payload;

  if (!igPosts) {
    return initialState;
  }

  // Page sizes were seemingly returning either 24 or 25, so just gonna check for 0 instead.
  if (igPosts.length === 0) {
    state[page].allLoaded = true;
  }

  _.map(igPosts, (igPost) => {
    if (!igPost.media_type || !allowedMediaTypes.includes(igPost.media_type)) {
      return;
    }

    if (_.indexOf(state[page].allIds, igPost[igPostKey]) === -1) {
      state[page].allIds.push(igPost[igPostKey]);
    }

    state[page].byId[igPost[igPostKey]] = igPost;
  });

  state[page].cursor = cursor ? cursor : null;

  return state;
};

export default feedMediaReducer;
