import * as React from "react";
import styled, { css } from "styled-components";

import { EditAbleMedia } from "ui/editable-media";
import { SkedButton, SkedIcon } from "ui";
import { SmallMediaFilePreview } from "entities/media-files";
import { CollectionFile } from "shared/types/files";
import { useSelector } from "react-redux";
import { getRoutinesLoading } from "selectors/commonSelectors";
import { ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE } from "constants/ActionTypes";

export function SelectedMediaFiles(props: {
  limit: number;
  selectedMediaFiles: CollectionFile[];
  onRemove(file: CollectionFile): void;
  onClear(): void;
  onSubmit(): void;
  title?: string;
  buttonTitle?: string;
  disabled: boolean;
}): JSX.Element {
  const {
    title = "Selected Assets",
    buttonTitle = "Use media",
    selectedMediaFiles,
    limit,
    onRemove,
    onClear,
    onSubmit,
    disabled,
  } = props;

  const isUploadingImages = useSelector((state) =>
    getRoutinesLoading(state, [ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE])
  );

  return (
    <SelectedMediaFilesRoot>
      <Header>
        <Title>{title}</Title>
        <Count overLimit={selectedMediaFiles.length > limit}>
          {selectedMediaFiles.length} / {limit}
        </Count>
      </Header>
      <Content>
        <List>
          {Array(10)
            .fill(null)
            .map((_, key) => {
              const media = selectedMediaFiles[key];
              return media ? (
                <EditAbleMedia key={media._id} onDelete={() => onRemove(media)}>
                  <SelectedMediaFile
                    src={media.thumbnail}
                    isVideo={media.meta.isVideo}
                  />
                </EditAbleMedia>
              ) : (
                <EmptySlot key={key} />
              );
            })}
        </List>
        <UseMediaButton
          onClick={onSubmit}
          disabled={disabled || isUploadingImages}
          loading={isUploadingImages}
          type="primary"
          size="small"
        >
          {buttonTitle}
        </UseMediaButton>
        <DeleteButton
          icon="trash"
          color="red"
          size="25"
          onClick={onClear}
          disabled={selectedMediaFiles.length < 1}
        />
      </Content>
    </SelectedMediaFilesRoot>
  );
}

const Button = styled(SkedIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DeleteButton = styled(Button)`
  svg {
    width: 25px !important;
    height: 25px !important;
  }

  :hover {
    background-color: #ffdede;
  }
`;

const UseMediaButton = styled(SkedButton)`
  margin: 4px;
  button {
    padding: 0 16px !important;
    font-weight: 400 !important;
    height: 30px !important;
    line-height: 30px !important;
    font-size: 10px !important;
  }
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;

const Count = styled.div<{ overLimit: boolean }>`
  font-size: 10px;
  line-height: 12px;
  color: #454545;

  ${(props) =>
    props.overLimit &&
    css`
      font-weight: 800;
      color: red;
    `}
`;

const SelectedMediaFile = styled(SmallMediaFilePreview)`
  width: var(--itemHeight);
  height: var(--itemHeight);
`;

const List = styled.div`
  --itemHeight: 50px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 12px;
  min-height: var(--itemHeight);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: #333433;

  ${Title} {
    margin-right: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
`;

const EmptySlot = styled.div`
  background: #eeeeee;
  border: 1px dashed #d9d9d9;
  width: 50px;
  height: 50px;
`;

export const SelectedMediaFilesRoot = styled.div`
  border: none;
  padding: 0;
  margin: 0;

  ${Header} {
    margin-bottom: 7px;
  }
`;
