import {
  INIT_GLOBALS,
  FETCH_POSTS_ROUTINE,
  FETCH_UPCOMING_POSTS_ROUTINE,
  FETCH_DRAFT_POSTS_ROUTINE,
  FETCH_ARCHIVED_POSTS_ROUTINE,
  REMOVE_POSTS_ROUTINE,
  REMOVE_POST_ROUTINE,
  SEND_POSTS_TO_QUEUE_ROUTINE,
  SEND_POST_TO_QUEUE_ROUTINE,
  SEND_POSTS_TO_DRAFT_ROUTINE,
  SEND_POST_TO_DRAFT_ROUTINE,
  SAVE_POST_ROUTINE,
  SORT_POST_ROUTINE,
  EDIT_POST_URL_ROUTINE,
  SHUFFLE_QUEUE_ROUTINE,
  INIT_POSTS_PAGE_ROUTINE,
  RELOAD_POSTS_ROUTINE,
  LOAD_MORE_POSTS_ROUTINE,
  TOGGLE_COLLABORATE_WIDGET,
  CHANGE_POSTS_PAGINATION,
  SAVE_POST_FORM_ROUTINE,
  UPDATE_POST_FORM_VALIDATION,
  START_POST_FORM_ROUTINE,
  SEND_POST_NOTIFICATION_ROUTINE,
} from "constants/ActionTypes";
import ngDeps from "ng-react-directives/ngr-injector";
import _ from "lodash";

import { put, select, call, takeEvery, all } from "redux-saga/effects";
import {
  getPosts,
  getPostIds,
  getFilters,
  getLoadedPostCount,
  getUpcomingFilters,
  getPageInitialized,
} from "selectors/postsPageSelectors";
import {
  getAccountsGroups,
  getSelectedAccountIds,
} from "selectors/skedCoreSelectors";
import {
  getPage,
  getUser,
  getPagination,
  getRoutinesLoading,
} from "selectors/commonSelectors";
import { FIELDS } from "constants/PostFormFields";
import { PAGES } from "constants/Pages";
import { NEW_POST_ID, POST_STATES } from "constants/PostsView";
import { makePages } from "reducers/paginationReducer";
import { hideModal } from "actions/modal";
import {
  filterCollabStatus,
  filterPostContentType,
  reloadPosts as reloadPostsAction,
} from "actions/postsView";
import {
  normalizeDraft,
  partitionSelectedPostIds,
} from "reducers/entities/postsReducer";
import { validateFull } from "components/Posts/PostForm/postFormValidator";
import { toast } from "react-toastify";
import { postsCreatedOrUpdated } from "features/post";
import { getPostContentTypes } from "../selectors/postsPageSelectors";
import { schedulesValidate } from "api/post";
import { sagaErrorHandlerWrapper } from "./utils";
import {
  standardizationPostData,
  standardizationUpcomingPosts,
} from "utils/posts";
import {
  postsContentAllOption,
  postsContentOptions,
} from "constants/PostsFliters";
import { fetchAccountsStatistics } from "pages/home/accountsStatistics.model";
import { getLocalStorageHomeSelectedGroupName } from "libs/storage/adapters";
import {
  creatingPostNeedUploadCover,
  getUpdateThumbnail,
} from "features/post/utils";

const standardizationDraftPosts = (posts) =>
  Promise.all(posts.map((post) => normalizeDraft(post)));
const standardizationArchivePosts = (posts) =>
  Promise.all(posts.map((post) => standardizationPostData(post)));

const endpoints = {
  [PAGES.UPCOMING]: {
    bulkDelete: "/posts/bulk/delete",
    bulkQueue: "/posts/bulk/queue",
    bulkDraft: "/posts/bulk/drafts",
  },
  [PAGES.DRAFT]: {
    bulkDelete: "/drafts/bulk/delete",
    bulkQueue: "/drafts/bulk/queue",
  },
  [PAGES.ARCHIVE]: {
    bulkDelete: "/archives/bulk/delete",
  },
};

export function* initPostsPage(action) {
  const { page } = action.payload;
  let currentPage = 1;
  let pageSize = 24;

  if (page === PAGES.UPCOMING) {
    pageSize = 10;
  }

  yield put({ type: INIT_POSTS_PAGE_ROUTINE.REQUEST, payload: action.payload });

  yield put({
    type: INIT_GLOBALS,
    payload: {
      page: page,
    },
  });

  const selectedAccountIds = yield select(getSelectedAccountIds);

  yield call(fetchPosts, {
    type: FETCH_POSTS_ROUTINE.TRIGGER,
    payload: {
      currentPage: currentPage,
      pageSize: pageSize,
      selectedAccounts: selectedAccountIds,
    },
  });

  yield put({ type: INIT_POSTS_PAGE_ROUTINE.SUCCESS });
}

export function* startPostForm(action) {
  yield put({ type: START_POST_FORM_ROUTINE.SUCCESS, payload: action.payload });
}

function* fetchUpcoming(action) {
  const { scheduleService, postsService } = ngDeps;

  const {
    currentPage,
    pageSize,
    selectedAccounts,
    excludedIds,
    addMore,
    lastNextRunAt,
  } = action.payload;
  const postFilters = yield select(getFilters);
  const selectedPostContentTypes = yield select(getPostContentTypes);
  const upcomingFilters = yield select(getUpcomingFilters);
  const user = yield select(getUser);

  let filters = _.cloneDeep(upcomingFilters);
  if (filters === null) {
    filters = postsService.makeFilters(
      selectedAccounts,
      currentPage * pageSize
    );
  }

  if (postFilters.postType && postFilters.postType !== "all") {
    filters.postType = postFilters.postType;
  }

  if (postFilters.postStatusKey) {
    if (user.postStatuses) {
      const selectedPostStatusKeys = postFilters.postStatusKey.filter(
        (key) => key !== "*"
      );
      if (user.postStatuses.length !== selectedPostStatusKeys.length) {
        filters.postStatusKey = postFilters.postStatusKey;
      }
    }
  }

  if (selectedPostContentTypes) {
    filters.postContentTypes = selectedPostContentTypes.includes("*")
      ? []
      : selectedPostContentTypes;
  }

  if (excludedIds) {
    filters.excludedIds = excludedIds;
  }

  if (lastNextRunAt) {
    filters.lastNextRunAt = lastNextRunAt;
  }

  const statePosts = yield select(getPosts);

  yield put({
    type: FETCH_UPCOMING_POSTS_ROUTINE.REQUEST,
    currentPage,
    filters,
  });
  yield put({
    type: CHANGE_POSTS_PAGINATION.SUCCESS,
  });
  try {
    const data = yield call(scheduleService.findPosts, filters);

    const existing = addMore ? statePosts : [];
    const newPosts = yield call(
      standardizationUpcomingPosts,
      data.posts,
      existing
    );

    const posts = _.chain(existing)
      .concat(newPosts)
      .orderBy("timestamp", "asc")
      .value();

    // Create new filters
    const hasMore = data.hasMore;

    yield put({
      type: FETCH_UPCOMING_POSTS_ROUTINE.SUCCESS,
      payload: {
        posts: posts,
        filters: filters,
        hasMore: hasMore,
      },
    });
  } catch (err) {
    yield put({
      type: FETCH_UPCOMING_POSTS_ROUTINE.FAILURE,
      message: err.message,
      err: err,
    });
  }
}

function* fetchDrafts(action) {
  const { scheduleService } = ngDeps;
  const { currentPage, pageSize, selectedAccounts } = action.payload;
  const postFilters = yield select(getFilters);
  const selectedPostContentTypes = yield select(getPostContentTypes);
  const page = yield select(getPage);
  let params = { order: "desc" };
  if (
    postFilters.postStatusKey &&
    (page === PAGES.DRAFT || page === PAGES.UPCOMING || page === PAGES.CALENDAR)
  ) {
    params.postStatusKey = postFilters.postStatusKey;
  }

  if (selectedPostContentTypes) {
    params.postContentTypes = selectedPostContentTypes.includes("*")
      ? []
      : selectedPostContentTypes;
  }

  yield put({ type: FETCH_DRAFT_POSTS_ROUTINE.REQUEST, currentPage });
  try {
    const data = yield call(
      scheduleService.findDrafts,
      currentPage,
      pageSize,
      selectedAccounts,
      params
    );
    const posts = yield call(standardizationDraftPosts, data.schedules);
    const total = data.total;
    const lastPage = Math.ceil(data.total / pageSize) || 1;

    yield put({
      type: FETCH_DRAFT_POSTS_ROUTINE.SUCCESS,
      payload: {
        posts,
        total: total,
        pagination: {
          currentPage: currentPage,
          lastPage: lastPage,
          pageSize: pageSize,
          pages: makePages(data.schedules, total, currentPage, lastPage),
        },
      },
    });
  } catch (err) {
    yield put({
      type: FETCH_DRAFT_POSTS_ROUTINE.FAILURE,
      message: err.message,
      err: err,
    });
  }
}

function* fetchArchived(action) {
  const { scheduleService } = ngDeps;
  const { currentPage, pageSize, selectedAccounts } = action.payload;
  const postFilters = yield select(getFilters);
  const selectedPostContentTypes = yield select(getPostContentTypes);

  let params = { order: "desc" };

  if (postFilters.postStatusKey) {
    params.postStatusKey = postFilters.postStatusKey;
  }

  if (selectedPostContentTypes) {
    params.postContentTypes = selectedPostContentTypes.includes("*")
      ? []
      : selectedPostContentTypes;
  }

  yield put({
    type: FETCH_ARCHIVED_POSTS_ROUTINE.REQUEST,
    payload: { currentPage, pageSize, selectedAccounts },
  });

  try {
    const data = yield call(
      scheduleService.findArchived,
      currentPage,
      pageSize,
      selectedAccounts,
      params
    );

    const total = data.total;
    const lastPage = Math.ceil(data.total / pageSize) || 1;
    const posts = yield call(standardizationArchivePosts, data.schedules);

    yield put({
      type: FETCH_ARCHIVED_POSTS_ROUTINE.SUCCESS,
      payload: {
        posts,
        total: total,
        pagination: {
          currentPage: currentPage,
          lastPage: lastPage,
          pageSize: pageSize,
          pages: makePages(data.schedules, total, currentPage, lastPage),
        },
      },
    });
  } catch (err) {
    yield put({
      type: FETCH_ARCHIVED_POSTS_ROUTINE.FAILURE,
      message: err.message,
      err: err,
    });
  }
}

export function* fetchPosts(action) {
  const page = yield select(getPage);
  const fetchTypes = {
    [PAGES.PLANNER]: () => false,
    [PAGES.UPCOMING]: fetchUpcoming,
    [PAGES.DRAFT]: fetchDrafts,
    [PAGES.CALENDAR]: fetchDrafts,
    [PAGES.ARCHIVE]: fetchArchived,
  };

  // if (
  //   accounts.length === 0 ||
  //   !action.payload.selectedAccounts ||
  //   action.payload.selectedAccounts.length === 0
  // ) {
  //   yield put({
  //     type: FETCH_POSTS_IGNORE,
  //     payload: { message: "No accounts selected" },
  //   });
  //   return;
  // }

  yield call(fetchTypes[page], action);
}

export function* reloadPosts() {
  const { currentPage, pageSize } = yield select(getPagination);
  const selectedAccountIds = yield select(getSelectedAccountIds);
  const posts = yield select(getPosts);
  const page = yield select(getPage);
  const pageInitialized = yield select(getPageInitialized);
  const isFetching = yield select((state) =>
    getRoutinesLoading(state, [
      FETCH_UPCOMING_POSTS_ROUTINE,
      FETCH_DRAFT_POSTS_ROUTINE,
      FETCH_ARCHIVED_POSTS_ROUTINE,
    ])
  );

  // fix wrong Calendar page init, we need to reload drafts posts after
  // the new post was created

  if (isFetching || (!pageInitialized && page !== PAGES.CALENDAR)) {
    return;
  }

  let pageToFetch = currentPage;
  if (currentPage !== 1 && posts.length === 1) {
    pageToFetch -= 1;
  }

  if (page === PAGES.UPCOMING) {
    // todo reload for activities
    // return dispatch(fetchPosts())
  }

  yield call(fetchPosts, {
    type: FETCH_POSTS_ROUTINE.TRIGGER,
    payload: {
      currentPage: pageToFetch,
      pageSize,
      selectedAccounts: selectedAccountIds,
    },
  });
}

// Actions
export function* removePosts(action) {
  const { $http } = ngDeps;
  const { postIds } = action.payload;

  if (postIds.length === 0) {
    yield put(hideModal());
    return;
  }

  const page = yield select(getPage);
  const posts = yield select(getPosts);

  const idGroups = partitionSelectedPostIds(posts, postIds);
  yield put({ type: REMOVE_POSTS_ROUTINE.REQUEST });

  try {
    yield call($http.post, endpoints[page].bulkDelete, idGroups);
    yield put({
      type: REMOVE_POSTS_ROUTINE.SUCCESS,
      payload: {
        removedPostIds: postIds,
      },
    });
    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: REMOVE_POSTS_ROUTINE.FAILURE,
      payload: err,
      error: true,
    });
  } finally {
    yield put(hideModal());
  }
}

export function* removePost(action) {
  const { $http, scheduleService } = ngDeps;
  const page = yield select(getPage);
  const { post } = action.payload;

  let removePostCall = null;
  let removePostParams = [];
  switch (page) {
    case PAGES.UPCOMING:
      if (post.queued) {
        removePostCall = $http.delete;
        removePostParams.push("/queue/" + post._id);
        break;
      }

      removePostCall = scheduleService.cancel;
      removePostParams.push(post._id);
      break;
    case PAGES.DRAFT:
      removePostCall = $http.delete;
      removePostParams.push("/drafts/" + post._id);
      removePostParams.push({
        success: "Removed draft successfully",
        error: "An error occurred in removing draft",
      });
      break;
    case PAGES.ARCHIVE:
      if (post.isQueued) {
        removePostCall = scheduleService.removeFromQueue;
        removePostParams.push(post._id);
        break;
      }
      removePostCall = scheduleService.remove;
      removePostParams.push(post._id);
      break;
  }

  yield put({
    type: REMOVE_POSTS_ROUTINE.REQUEST,
    payload: { removedPostIds: [post._id] },
  });

  try {
    yield call(removePostCall, ...removePostParams);

    yield put({
      type: REMOVE_POSTS_ROUTINE.SUCCESS,
      payload: {
        removedPostIds: [post._id],
      },
    });

    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: REMOVE_POSTS_ROUTINE.FAILURE,
      payload: err,
      error: true,
    });
  } finally {
    yield put(hideModal());
  }
}

export function* sendPostsToQueue(action) {
  const { $http } = ngDeps;
  const { selectedPostIds } = action.payload;

  const page = yield select(getPage);
  const posts = yield select(getPosts);

  const idGroups = partitionSelectedPostIds(posts, selectedPostIds);

  let endpointPayload = { postIds: idGroups.postIds };
  if (!idGroups.postIds.length) {
    toast.warning("You can only queue posts that are not already queued.");
    yield put(hideModal());
    return;
  }

  yield put({ type: SEND_POSTS_TO_QUEUE_ROUTINE.REQUEST });
  try {
    yield call($http.post, endpoints[page].bulkQueue, endpointPayload);
    toast.success(
      `Successfully sent your post${
        idGroups.postIds.length > 1 ? "s" : ""
      } to queue.`
    );
    yield put({
      type: SEND_POSTS_TO_QUEUE_ROUTINE.SUCCESS,
      payload: {
        selectedPostIds: idGroups.postIds,
      },
    });
    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: SEND_POSTS_TO_QUEUE_ROUTINE.FAILURE,
      payload: err,
      error: true,
    });
    throw err;
  } finally {
    yield put(hideModal());
  }
}

export function* sendPostToQueue(action) {
  const { post } = action.payload;

  let queuedPost = Object.assign({}, post);
  queuedPost.accountIds = [queuedPost.accountId];
  queuedPost.queued = true;
  try {
    const page = yield select(getPage);
    switch (page) {
      case PAGES.UPCOMING:
        yield call(savePost, {
          type: SAVE_POST_ROUTINE.TRIGGER,
          payload: {
            post: queuedPost,
          },
        });
        break;
      case PAGES.DRAFT:
        yield call(sendPostsToQueue, {
          type: SEND_POSTS_TO_QUEUE_ROUTINE.TRIGGER,
          payload: {
            selectedPostIds: [post._id],
          },
        });
        break;
    }

    yield put({
      type: SEND_POST_TO_QUEUE_ROUTINE.SUCCESS,
      payload: {
        page: PAGES.UPCOMING,
        selectedPostIds: [post._id],
      },
    });

    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: SEND_POST_TO_QUEUE_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  } finally {
    yield put(hideModal());
  }
}

export function* sendPostsToDraft(action) {
  const { selectedPostIds } = action.payload;
  const { $http } = ngDeps;

  const page = yield select(getPage);
  const posts = yield select(getPosts);

  const idGroups = partitionSelectedPostIds(posts, selectedPostIds);
  yield put({ type: SEND_POSTS_TO_DRAFT_ROUTINE.REQUEST });

  try {
    yield call($http.post, endpoints[page].bulkDraft, idGroups);
    toast.success(
      `Successfully sent your post${
        idGroups.postIds.length > 1 ? "s" : ""
      } to draft.`
    );
    yield put({
      type: SEND_POSTS_TO_DRAFT_ROUTINE.SUCCESS,
      payload: {
        selectedPostIds,
      },
    });
    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: SEND_POSTS_TO_DRAFT_ROUTINE.TRIGGER,
      error: true,
      payload: err,
    });
    throw err;
  } finally {
    yield put(hideModal());
  }
}

export function* sendPostToDraft(action) {
  const { scheduleService } = ngDeps;
  const { post } = action.payload;

  yield put({ type: SEND_POST_TO_DRAFT_ROUTINE.REQUEST });

  try {
    //archived passed false for the 2nd param but should be okay passing like this?
    yield call(scheduleService.moveToDraft, post._id, post.queued);
    yield put({
      type: SEND_POST_TO_DRAFT_ROUTINE.SUCCESS,
    });

    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: SEND_POST_TO_DRAFT_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  } finally {
    yield put(hideModal());
  }
}

export function* savePost(action) {
  const { scheduleService } = ngDeps;
  const { post } = action.payload;

  yield put({
    type: SAVE_POST_ROUTINE.REQUEST,
  });

  try {
    const promise = scheduleService.savePost(post);
    yield all([promise]);
    yield put({
      type: SAVE_POST_ROUTINE.SUCCESS,
      payload: {
        post,
      },
    });
  } catch (err) {
    yield put({
      type: SAVE_POST_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
    throw err;
  } finally {
    put(hideModal());
  }
}

export function* sortPost(action) {
  const { $http } = ngDeps;
  const { fromIndex, toIndex } = action.payload;

  const posts = yield select(getPosts);
  const page = yield select(getPage);

  if (page !== PAGES.UPCOMING) {
    return;
  }

  let fromPost = _.cloneDeep(posts[fromIndex]);
  let toPost = _.cloneDeep(posts[toIndex]);

  if (!fromPost.queued || !toPost.queued) {
    toast.error(
      "Posts cannot be drag when there is a mixture of scheduled and queued posts. This feature is only available when you use the queue feature exclusively."
    );

    return;
  }

  if (fromPost.accountId !== toPost.accountId) {
    toast.error(
      "Drag the post above or below a queued post for the same account."
    );

    return;
  }

  let when = fromPost.when;
  fromPost.when = toPost.when;
  toPost.when = when;

  yield put({
    type: SORT_POST_ROUTINE.REQUEST,
    payload: { fromIndex, toIndex },
  });

  try {
    yield call(
      $http.post,
      `/queue/${fromPost.accountId}/reorder`,
      { fromPostId: fromPost._id, toPostId: toPost._id },
      { success: "Post moved successfully" }
    );

    yield put({
      type: SORT_POST_ROUTINE.SUCCESS,
      payload: {
        fromIndex,
        toIndex,
      },
    });

    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: SORT_POST_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  }
}

export function* loadMorePosts(action) {
  const selectedAccountIds = yield select(getSelectedAccountIds);

  const loadedPostCount = yield select(getLoadedPostCount);
  const posts = yield select(getPosts);

  const excludedIds = posts.map((post) => post._id);

  const pageSize = 10;
  const nextPageToLoad = Math.ceil(loadedPostCount / pageSize) + 1;
  const lastNextRunAt = posts[posts.length - 1].timestamp;
  // currentPage = currentPage > 0 ? currentPage - 1 : 0;
  console.log(loadedPostCount, pageSize, nextPageToLoad);

  yield put({
    type: CHANGE_POSTS_PAGINATION.TRIGGER,
    payload: { page: nextPageToLoad, pageSize },
  });

  yield call(fetchUpcoming, {
    type: FETCH_UPCOMING_POSTS_ROUTINE.TRIGGER,
    payload: {
      currentPage: nextPageToLoad,
      pageSize,
      selectedAccounts: selectedAccountIds,
      excludedIds,
      addMore: true,
      lastNextRunAt,
    },
  });
}

export function* editPostUrl(action) {
  const { postsController, scheduleService } = ngDeps;
  const { post } = action.payload;

  yield put({ type: EDIT_POST_URL_ROUTINE.REQUEST });

  try {
    yield call(scheduleService.updateRedirectUrl, post);

    if (postsController) {
      delete postsController.currentPostUrl;
      delete postsController.post;
    }

    yield put({
      type: EDIT_POST_URL_ROUTINE.SUCCESS,
      payload: {
        post,
      },
    });
  } catch (err) {
    yield put({
      type: EDIT_POST_URL_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  } finally {
    postsController?.closeModal();
  }
}

export function* shuffleQueue(action) {
  const { $http, $rootScope } = ngDeps;
  const { accountIds } = action.payload;

  yield put({ type: SHUFFLE_QUEUE_ROUTINE.REQUEST });
  try {
    yield call($http.post, "/queue/shuffle", { accountIds: accountIds });
    toast.success("Queue shuffled successfully");
    yield put({ type: SHUFFLE_QUEUE_ROUTINE.SUCCESS });

    yield put(reloadPostsAction());
  } catch (err) {
    yield put({
      type: SHUFFLE_QUEUE_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  } finally {
    $rootScope.closeModal();
  }
}
export function* sendPostNotification(action) {
  const { $http } = ngDeps;
  const { postId } = action.payload;

  yield put({ type: SEND_POST_NOTIFICATION_ROUTINE.REQUEST });
  try {
    yield call($http.post, `/schedules/${postId}/sendNotification`, { postId });
    toast.success(
      "Sent notification to you via email and push (if available)."
    );
    yield put({ type: SEND_POST_NOTIFICATION_ROUTINE.SUCCESS });
  } catch (err) {
    yield put({
      type: SEND_POST_NOTIFICATION_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  } finally {
  }
}

export function* toggleCollaborateWidget(action) {
  const { post } = action.payload;
  const postIds = yield select(getPostIds);
  const index = _.indexOf(postIds, post._id);
  const { postsController } = ngDeps;
  if (index === -1) {
    return;
  }

  postsController.toggleCollaborateWidget(index, _.cloneDeep(post));
}

export function* changePostsPagination(action) {
  const { page, pageSize } = action.payload;
  // const selectedCollection = yield select(getSelectedCollection);
  // const { tags, name } = yield select(getMediaLibraryFilters);
  yield put({
    type: CHANGE_POSTS_PAGINATION.REQUEST,
    payload: { page, pageSize },
  });

  try {
    yield call(reloadPosts);
    yield put({
      type: CHANGE_POSTS_PAGINATION.SUCCESS,
    });
  } catch (err) {
    yield put({
      type: CHANGE_POSTS_PAGINATION.FAILURE,
      error: true,
      payload: err,
    });
  }
}

export function* savePostForm(action) {
  const { form } = action.payload;
  const { scheduleService, postService } = ngDeps;
  yield put({
    type: SAVE_POST_FORM_ROUTINE.REQUEST,
    payload: { form },
  });

  try {
    let { post, platformFields, originalPostState, newPostState, platforms } =
      form;
    const page = yield select(getPage);
    const user = yield select(getUser);
    const overlapSchedule = yield select(
      (state) => state.forms.postForms.byId[post._id]?.overlapSchedule
    );

    const [errors, warnings] = validateFull(
      newPostState,
      post,
      platformFields,
      platforms,
      overlapSchedule
    );

    // Need to fix errors before we can save;
    if (errors && Object.keys(errors).length > 0) {
      yield put({
        type: UPDATE_POST_FORM_VALIDATION,
        payload: { postId: post["_id"], errors, warnings },
      });
      yield put({
        type: SAVE_POST_FORM_ROUTINE.FAILURE,
        error: false,
        payload: "",
      });
      return;
    }

    // for archived posts when we "post again" they should
    // be treated same as scheduled
    const postServiceMap = {
      [POST_STATES.DRAFT]: "draft",
      [POST_STATES.QUEUED]: "queue",
      [POST_STATES.SCHEDULED]: "schedule",
    };
    // archived posts are scheduled posts
    if (originalPostState === "ARCHIVED") {
      originalPostState = "SCHEDULED";
      if (!post.when) {
        // this is because we have to save the current post before
        // then moving to drafts etc etc
        // only change this if the user has not otherwise selected a time
        // otherwise it will throw an error because it will try and save with a schedule for the current datetime
        post = _.cloneDeep(post);
        post.when = new Date(Date.now() + 5 * 60 * 1000);
      }
    }
    let originalEntity = postServiceMap[originalPostState];

    let postServiceVerb = "update"; // Assume update
    // We have a state change
    if (originalPostState !== newPostState) {
      postServiceVerb = postServiceMap[newPostState];
    }

    // If we are still in draft then we may need to split up the post manually.
    // Due to the unlinking logic.
    let postsObj = buildPosts(post, platformFields, page, newPostState);

    // Only save the original post if there are still accounts.
    // If there aren't any accounts, it means all the platforms were unlinked
    if (postsObj.originalPost && postsObj.originalPost.accounts.length > 0) {
      // From  => To     : Needs Save Before Convert :
      // Schedule => Draft  : Y :
      // Schedule => Queue  : Y :
      // Queue => Draft     : Y :
      // Draft => Queue     : Y :
      // Queue => Schedule  : N :
      // Draft => Schedule  : N :
      if (
        postServiceVerb !== "update" &&
        newPostState !== POST_STATES.SCHEDULED
      ) {
        let originalPostUpdatePromise = postService[originalEntity]["update"](
          postsObj.originalPost
        );
        yield all([originalPostUpdatePromise]);
      }
      let originalPostPromise = postService[originalEntity][postServiceVerb](
        postsObj.originalPost
      );

      yield all([originalPostPromise]);
    }

    const extractNewPosts = (newPosts) => {
      const promises = newPosts.map(async (newPost) => {
        if (creatingPostNeedUploadCover(newPost)) {
          newPost = {
            ...newPost,
            thumbnailUrl: await getUpdateThumbnail(newPost),
          };
        }
        // create payload
        let newFormattedPost = scheduleService.createPost(
          newPost,
          postServiceMap[newPostState]
        );

        return {
          accountIds: newPost["accountIds"],
          schedules: [newFormattedPost],
        };
      });

      return Promise.all(promises);
    };

    const newPosts = yield extractNewPosts(postsObj.newPosts);

    let serverValidationPromises = newPosts.map(({ accountIds, schedules }) => {
      return schedulesValidate({
        accountIds,
        schedules,
        timezone: user.timezone,
        uploader: "multiposter",
      });
    });

    if (serverValidationPromises.length > 0) {
      yield all(serverValidationPromises);
    }

    let promises = newPosts.map(({ accountIds, schedules }) => {
      return scheduleService.create(
        {
          accountIds,
          schedules,
          timezone: user.timezone,
        },
        "multiposter",
        true
      );
    });

    if (promises.length > 0) {
      yield all(promises);
    }

    yield put({
      type: SAVE_POST_FORM_ROUTINE.SUCCESS,
      payload: {
        postId: post._id,
        originalPost: postsObj.originalPost,
        newPosts: postsObj.newPosts,
      },
    });

    // Only Edit
    let newPostLength = postsObj.newPosts ? postsObj.newPosts.length : 0;

    if (postsObj.originalPost && newPostLength === 0) {
      toast.success(`Successfully edited post`);
      // Edit + Create
    } else if (postsObj.originalPost && newPostLength > 0) {
      toast.success(
        `Successfully edited original post and created ${
          newPostLength === 1 ? "a post" : `${newPostLength} posts`
        }`
      );
      // Only Create
    } else if (!postsObj.originalPost && newPostLength > 0) {
      toast.success("Post created! Your page filters have been reset.");

      // NOTE only if we add post from sked Homepage, and if we add new post for account that selected on sked home, need update sked home statistic info
      const currentRoutePathname = window.location.pathname;
      const selectedGroupName = getLocalStorageHomeSelectedGroupName();

      if (
        ["/dashboard", "/dashboard/"].includes(currentRoutePathname) &&
        selectedGroupName
      ) {
        const accountsGroups = yield select(getAccountsGroups);
        const selectedGroup = accountsGroups.find(
          (group) => group.name === selectedGroupName
        );

        if (selectedGroup) {
          yield put(
            fetchAccountsStatistics({
              groupName: selectedGroup.name,
              accountsIds: selectedGroup.accountIds,
            })
          );
        } else {
          // no selectedGroup due to first created account with default group
          yield put(
            fetchAccountsStatistics({
              groupName: selectedGroupName,
              accountsIds: newPosts[0]?.accountIds,
            })
          );
        }
      }
    }

    postsCreatedOrUpdated({ post: postsObj });

    if (post._id === NEW_POST_ID) {
      // If new post is created, change filters to default (select all) -> that this auto run reloadPostsAction
      const userCollabSettings = user.getPostStatusList();
      const initialPostStatuses = userCollabSettings.map((item) => item.key);

      yield put(filterCollabStatus(initialPostStatuses));
      yield put(
        filterPostContentType(
          [postsContentAllOption, ...postsContentOptions].map(
            (item) => item.value
          )
        )
      );
    } else {
      yield put(reloadPostsAction());
    }
  } catch (err) {
    console.error(err);
    yield put({
      type: SAVE_POST_FORM_ROUTINE.FAILURE,
      error: true,
      payload: err,
    });
  }
}

const buildPosts = (_post, platformFields, page, postState) => {
  let newPosts = [];
  let post = cleanPost(_post);

  // No need to split up
  if (!platformFields && page !== PAGES.UPCOMING) {
    return { originalPost: post, newPosts: [] };
  }

  // We will fill in the accounts later
  let originalPost = _.cloneDeep(post);
  delete originalPost["accountId"];
  delete originalPost["accountIds"];
  delete originalPost["accounts"];
  delete originalPost["accountName"];

  // take off accounts
  let accounts = post.accounts;

  // upcoming and archive are the same
  const isUpcoming = page === PAGES.UPCOMING || page === PAGES.ARCHIVE;
  // if it's a draft then it should be created as cluster
  const isCreatingDraft = postState === POST_STATES.DRAFT;
  if (isUpcoming && !isCreatingDraft) {
    for (let i = 0; i < accounts.length; i++) {
      let upcomingAccount = accounts[i];
      let upcomingAccountType = upcomingAccount.platformType || "IG";
      if (i === 0) {
        originalPost["accounts"] = [upcomingAccount];
        originalPost["accountIds"] = [upcomingAccount["_id"]];
      }

      let newUpcomingPost = _.cloneDeep(originalPost);
      newUpcomingPost["accounts"] = [upcomingAccount];
      newUpcomingPost["accountIds"] = [upcomingAccount["_id"]];

      if (
        platformFields[upcomingAccountType] &&
        platformFields[upcomingAccountType]["unlinked"]
      ) {
        newUpcomingPost = {
          ...newUpcomingPost,
          ...platformFields[upcomingAccountType],
        };
        delete newUpcomingPost["unlinked"];
      }

      delete newUpcomingPost["_id"];
      delete newUpcomingPost["newPost"];
      newPosts.push(newUpcomingPost);
    }

    originalPost = null;
  } else {
    // Draft => (Queue / Scheduled)
    let platformAccounts = {};
    accounts.map((account) => {
      const platformType = account.platformType || "IG";
      if (!platformAccounts[platformType]) {
        platformAccounts[platformType] = [];
      }
      platformAccounts[platformType].push(account);
    });

    Object.keys(platformFields).map((platformType) => {
      let platformPost = { ...originalPost, ...platformFields[platformType] };

      // If a user unlinked a platform then removed the accounts then
      // the platform fields would still be set but there isn't an
      // account, so ignore.
      if (!platformAccounts[platformType]) {
        return;
      }

      let accounts = platformAccounts[platformType];
      let accountIds = _.map(accounts, "_id");

      platformPost["accounts"] = accounts;
      platformPost["accountIds"] = accountIds;

      delete platformAccounts[platformType];

      if (platformPost["unlinked"]) {
        delete platformPost["unlinked"];
        delete platformPost["_id"];
        delete platformPost["newPost"];
        newPosts.push(platformPost);
      }
    });

    let leftoverAccounts = _.concat(...Object.values(platformAccounts));
    let leftoverAccountIds = _.map(leftoverAccounts, "_id");

    // If they unlink everything then original post is not required.
    if (leftoverAccounts.length === 0) {
      originalPost = null;
    } else {
      originalPost["accounts"] = leftoverAccounts;
      originalPost["accountIds"] = leftoverAccountIds;
    }

    if (originalPost?.newPost) {
      delete originalPost["_id"];
      delete originalPost["newPost"];
      newPosts.push(originalPost);
      originalPost = null;
    }
  }

  return { originalPost: originalPost, newPosts: newPosts };
};

const cleanPost = (post) => {
  let cleanedPost = _.cloneDeep(post);

  // Remove fields if a story post
  if (cleanedPost.isStory) {
    cleanedPost["redirectUrl"] = "";
    cleanedPost["firstcomment"] = "";
    cleanedPost["tags"] = [];
    cleanedPost["productTags"] = [];
  }

  if (cleanedPost.gmbTopicType) {
    switch (cleanedPost.gmbTopicType) {
      case "STANDARD":
        delete cleanedPost[FIELDS.GMB_EVENT];
        delete cleanedPost[FIELDS.GMB_OFFER];
        break;
      case "OFFER":
        delete cleanedPost["gmbCallToAction"];
        break;
      case "EVENT":
        break;
      case "ALERT":
        delete cleanedPost[FIELDS.GMB_EVENT];
        delete cleanedPost[FIELDS.GMB_OFFER];
        break;
    }
  }
  return cleanedPost;
};

// --------------------- WATCHERS --------------------- //

export default function* postsSagas() {
  yield takeEvery(INIT_POSTS_PAGE_ROUTINE.TRIGGER, initPostsPage);
  yield takeEvery(FETCH_POSTS_ROUTINE.TRIGGER, fetchPosts);
  yield takeEvery(FETCH_DRAFT_POSTS_ROUTINE.TRIGGER, fetchDrafts);
  yield takeEvery(RELOAD_POSTS_ROUTINE.TRIGGER, reloadPosts);
  yield takeEvery(REMOVE_POSTS_ROUTINE.TRIGGER, removePosts);
  yield takeEvery(REMOVE_POST_ROUTINE.TRIGGER, removePost);
  yield takeEvery(
    SEND_POSTS_TO_QUEUE_ROUTINE.TRIGGER,
    sagaErrorHandlerWrapper({ saga: sendPostsToQueue })
  );
  yield takeEvery(SEND_POST_TO_QUEUE_ROUTINE.TRIGGER, sendPostToQueue);
  yield takeEvery(SEND_POSTS_TO_DRAFT_ROUTINE.TRIGGER, sendPostsToDraft);
  yield takeEvery(SEND_POST_TO_DRAFT_ROUTINE.TRIGGER, sendPostToDraft);
  yield takeEvery(SHUFFLE_QUEUE_ROUTINE.TRIGGER, shuffleQueue);
  yield takeEvery(SORT_POST_ROUTINE.TRIGGER, sortPost);
  yield takeEvery(LOAD_MORE_POSTS_ROUTINE.TRIGGER, loadMorePosts);
  yield takeEvery(EDIT_POST_URL_ROUTINE.TRIGGER, editPostUrl);
  yield takeEvery(TOGGLE_COLLABORATE_WIDGET, toggleCollaborateWidget);
  yield takeEvery(CHANGE_POSTS_PAGINATION.TRIGGER, changePostsPagination);
  yield takeEvery(SAVE_POST_FORM_ROUTINE.TRIGGER, savePostForm);
  yield takeEvery(START_POST_FORM_ROUTINE.TRIGGER, startPostForm);
  yield takeEvery(SEND_POST_NOTIFICATION_ROUTINE.TRIGGER, sendPostNotification);
}
