import React, { useMemo } from "react";
import styled from "styled-components";
import { MultiSelect } from "../multi-select";

interface TagsSelectorProps {
  value: string[];
  tags: string[];
  onChange(value: string[]): void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const TagsSelectorComponent = ({
  onChange,
  tags,
  value,
  placeholder,
  className,
  disabled,
}: TagsSelectorProps) => {
  const options = useMemo(
    () => tags.map((value) => ({ value, label: value })),
    [tags]
  );
  const passedValue = value.map((v) =>
    options.find((option) => option.value === v)
  );

  return (
    <MultiSelect
      value={passedValue}
      disabled={disabled}
      options={options}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export const TagsSelect = styled(TagsSelectorComponent)``;
