import * as actionTypes from "constants/ActionTypes";
import _ from "lodash";
import { shouldIgnoreLoadingRoutineName } from "./ignore-loading-action";

const initialState = {
  isLoading: false,
};

const loadingStartActions = [];

const loadingStopActions = [actionTypes.CLEAR_ACCOUNTS];

export const loadingReducer = (state = initialState, action) => {
  const { type } = action;

  if (type === actionTypes.COMMON_CLEAN_UP_LOADING) {
    /** filter out @@redux key */
    const keys = Object.keys(state).filter((c) => !c.startsWith("@"));
    if (keys.some((k) => state[k] === true)) {
      const falsyObj = {};
      keys.forEach((k) => {
        if (state[k] === true) {
          falsyObj[k] = false;
        }
      });

      return {
        ...state,
        ...falsyObj,
      };
    }
    return state;
  }

  if (_.isEmpty(type)) {
    console.error("Action without a type", action);
    return state;
  }

  const routineActionParts = type.split("/");
  if (routineActionParts.length > 1) {
    return routineActionReducer(state, action, routineActionParts);
  }

  if (loadingStartActions.includes(type)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (loadingStopActions.includes(type)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
};

const routineActionReducer = (state, action, routineActionParts) => {
  const [routineName, routineAction] = routineActionParts;
  if (shouldIgnoreLoadingRoutineName(routineName)) {
    return state;
  }

  let newState = { ...state };

  // if (!routines.includes(routineName)) {
  newState[routineName] = routineAction === "REQUEST";
  // }

  // The below pieces are for the old style, vs storing all routines
  if (routineAction === "REQUEST") {
    newState["isLoading"] = true;
  }

  if (["SUCCESS", "FAILURE"].includes(routineAction)) {
    const keys = Object.keys(newState);
    newState["isLoading"] = false;
    keys.map((key) => {
      // If other things are still loading, then keep isLoading = true
      if (key !== "isLoading" && newState[key] === true) {
        newState["isLoading"] = true;
      }
    });
  }

  return newState;
};
