import React from "react";
import styled from "styled-components";
import BootstrapTooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

interface ToolTipProps {
  children: JSX.Element;
  tooltip?: string | React.ReactNode;
  placement?: string;
  delayHide?: number;
  className?: string;
}

export const TooltipWrapper = ({
  children,
  tooltip,
  placement = "top",
  delayHide = 0,
  className,
}: ToolTipProps): JSX.Element => {
  if (tooltip) {
    const renderedJsx = (
      <Tooltip id="tooltip" data-testid="tooltip">
        {tooltip}
      </Tooltip>
    );
    return (
      <OverlayTrigger
        data-testid="tooltipWrapper"
        placement={placement}
        overlay={renderedJsx}
        delayHide={delayHide}
      >
        <div className={className}>{children}</div>
      </OverlayTrigger>
    );
  }

  return children;
};

const Tooltip = styled(BootstrapTooltip)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  word-break: normal;
`;

export default TooltipWrapper;
