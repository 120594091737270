function requireAll(r) {
  r.keys().forEach(r);
}

window.jquery = window.jQuery = window.$ = require("jquery");
window.Bugsnag = require("@bugsnag/js");
require("@bugsnag/plugin-react");
window.moment = require("moment");
window.microlink = require("@microlink/vanilla/amd/microlink.js");
window.d3 = require("d3");
window._ = require("lodash");
window.jstz = require("jstimezonedetect");
window.PhotoEditorSDK = require("photoeditorsdk");

// Node modules
// require('@microlink/vanilla/amd/microlink.min.js');
require("angular");
require("bootstrap/dist/js/bootstrap.js");
require("angularjs-toaster");
require("angularjs-scroll-glue");
require("angular-animate");
require("angular-bootstrap-colorpicker");
require("angular-cookies");
require("bootstrap-daterangepicker");
require("angular-daterangepicker");
require("angular-google-analytics");
require("angular-google-picker/dist/google-picker.js");
require("angular-sanitize");
require("angular-timezone-selector");
require("angular-uuid4");
require("angular-ui-bootstrap");
require("angular-ui-bootstrap-datetimepicker");
require("angular-ui-calendar");
require("angular-ui-router");
require("angular-ui-sortable");
require("chosen-js");
require("clipboard");
require("fullcalendar");
require("ical.js/build/ical.min.js");
require("jquery-ui-bundle");
require("mailcheck");
require("moment-timezone");
require("ngclipboard");
require("ngsticky");
require("ng-file-upload");
require("ng-infinite-scroll");
require("ng-sortable");
require("papaparse");
require("photoeditorsdk");
require("./ngReact");
require("react");
require("react-dom");

window.mime = require("./../modules/mime/mime.dist.js");

require("./../js/calendly.js");
require("./../js/status-3.4.3.js");
