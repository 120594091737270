import React from "react";
import styled from "styled-components";

import { openSupportChat } from "libs/support";
import { Link } from "ui/Link";

import { AddLibraryButton, Center, List, SMSplash } from "./atoms";
import demo from "./sm-splash-hashtag-demo.jpg";

export function SMSplashHashtag() {
  return (
    <SMSplash
      title="Hashtags Search"
      subtitle="Add UGC (user generated content) to your Library straight from Instagram Hashtags"
    >
      <Center>
        <AddLibraryButton />
      </Center>
      <Grid css="margin-top: calc(var(--spacing) * 2);">
        <SMSplashHashtagContent />
        <div css="justify-self: center">
          <img
            css="width: 30vw; height: auto;"
            src={demo}
            alt="Hashtag Search functionality demo"
          />
        </div>
      </Grid>
    </SMSplash>
  );
}

export function SMSplashHashtagContent() {
  return (
    <div>
      <List>
        <li>
          <p>
            <b>Add the hashtag you want to track</b>
          </p>
          <p>You can track up to 30 hashtags at a time</p>
        </li>
        <li>
          <p>
            <b>Check your results</b>
          </p>
          <p>
            See all public posts with the hashtag, and sort by recent or top
            posts
          </p>
        </li>
        <li>
          <p>
            <b>Select the posts you want to add</b>
          </p>
          <p>
            Add one or multiple posts to a Library Collection. Your images are
            automatically tagged by AI so you can find them later.
          </p>
        </li>
        <li>
          <p>
            <b>Post your new UGC</b>
          </p>
          <p>
            Schedule your UGC content to Instagram, Pinterest, Facebook, Twitter
            LinkedIn, or Google Business Profile. Automatically saved metadata
            makes sure you’re acknowledging the original creator.
          </p>
        </li>
      </List>
      <Center css="margin-top: calc(var(--spacing) * 2);">
        <AddLibraryButton />
      </Center>
      <Center css="margin-top: calc(var(--spacing) * 0.5);">
        <p>
          Get help from{" "}
          <Link as="button" onClick={() => openSupportChat()}>
            Sked Support
          </Link>
        </p>
      </Center>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: calc(var(--spacing) * 2);
`;
