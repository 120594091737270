import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import fbIcon from "entities/accounts/components/plaform-icon/icons/fb.image.svg";
import igIcon from "entities/accounts/components/plaform-icon/icons/ig.image.svg";
import liIcon from "entities/accounts/components/plaform-icon/icons/ln.image.svg";
import gmbIcon from "entities/accounts/components/plaform-icon/icons/gmb.image.svg";
import tiktokIcon from "entities/accounts/components/plaform-icon/icons/tt.image.svg";
import unknownUserAvatarSvg from "./unknown-user-avatar.svg";
import { AccountPlatformType } from "shared/types/accounts";

interface Props {
  src: string | undefined | null;
  name: string;
  size?: number;
  platform?: AccountPlatformType;
  showInitial?: boolean;
  unknownUser?: boolean;
  onError?: () => void;
}

const iconMap = {
  IG: igIcon,
  FB: fbIcon,
  LI: liIcon,
  GMB: gmbIcon,
  TT: tiktokIcon,
};

export function ConversationAvatar({
  src,
  name = "",
  size = 29,
  platform,
  showInitial,
  onError: onErrorProp,
  unknownUser,
}: Props): JSX.Element {
  const [isError, setError] = React.useState(false);
  const onError = () => {
    onErrorProp?.();
    setError(true);
  };
  useEffect(() => {
    if (src) {
      setError(false);
    }
  }, [src]);
  const splitName = name?.split(" ");
  const initial = `${splitName?.[0]?.[0] ?? ""}${splitName?.[1]?.[0] ?? ""}`;

  if (showInitial) {
    return (
      <RootContainer>
        <AvatarContainer size={size} data-testid="conversation-avatar">
          <AvatarInitial>{initial}</AvatarInitial>
        </AvatarContainer>
      </RootContainer>
    );
  }

  function renderAvatar() {
    if (unknownUser) {
      return <UnknownUserAvatar data-testid="unknown-user-avatar" />;
    }

    return src && !isError ? (
      <AvatarImage
        src={src}
        onError={onError}
        data-testid="conversation-avatar"
      />
    ) : (
      <AvatarInitial>{initial}</AvatarInitial>
    );
  }

  return (
    <RootContainer>
      <AvatarContainer size={size}>{renderAvatar()}</AvatarContainer>
      {platform && <PlatformIcon src={iconMap[platform]} alt={platform} />}
    </RootContainer>
  );
}

interface AvatarContainerProps {
  size: number;
}

const RootContainer = styled.div`
  position: relative;
`;

const AvatarContainer = styled.div<AvatarContainerProps>`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarInitial = styled.div`
  color: #555;
  font-weight: bold;
  font-size: 12px;
`;

const PlatformIcon = styled.img`
  width: 11px;
  height: 11px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const UnknownUserAvatar = styled(unknownUserAvatarSvg)`
  width: 100%;
  height: 100%;
`;
