import { combineReducers, createAction, createSlice } from "@reduxjs/toolkit";
import { createEvent } from "effector";

export const closePostForm = createAction("closePostForm");

export const isFormSubmitting = createSlice({
  name: "isFormSubmitting",
  initialState: false,
  reducers: {
    startFormSubmitting: () => true,
    finishFormSubmitting: () => false,
  },
});

export const formReducer = combineReducers({
  isSubmitting: isFormSubmitting.reducer,
});

export const postsCreatedOrUpdated = createEvent();
