import React, { useCallback } from "react";
import styled from "styled-components";

import { PhotoEditorSDKUI, UIEvent } from "photoeditorsdk";
import { config } from "./config";
import { BaseModal, ModalContent } from "../giselle-modal";
import { ProgressBar } from "../progress-bar/progress-bar";
import { useTrackEvent } from "libs/analytics";
import { PHOTO_EDITOR_EXPORT } from "constants/Events";

export function PhotoEditorModal(props: {
  show: boolean;
  image: string;
  onClose(): void;
  onSave(blob: Blob): void;
  progress: number | null;
}) {
  const trackEvent = useTrackEvent();
  const render = useCallback(async () => {
    const editor = await PhotoEditorSDKUI.init({
      ...config,
      // NOTE HTMLElement not work with version > 17
      container: "#photo-editor-container",
      image: `${props.image}?editor`,
    });
    editor.on(UIEvent.EXPORT, (imageBlob) => {
      trackEvent({ eventName: PHOTO_EDITOR_EXPORT });
      props.onSave(imageBlob);
    });
    editor.on(UIEvent.CLOSE, props.onClose);
  }, [props.image, props.onSave, props.onClose]);

  return (
    <Modal
      as={BaseModal}
      show={props.show}
      onHide={props.onClose}
      onShow={render}
    >
      <ModalContent>
        <div id="photo-editor-container" />
      </ModalContent>
      {props.progress && (
        <Cover>
          <ProgressBar>Saving...</ProgressBar>
        </Cover>
      )}
    </Modal>
  );
}

const Cover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  ${ProgressBar} {
    width: 80%;
  }
`;

const Modal = styled.div`
  width: 95%;
  max-width: none;
  height: 90%;

  .modal-content {
    width: 100%;
    height: 100%;
  }
`;
