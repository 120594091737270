import { take, takeEvery, put } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";

import { closeModal, openModal, registerModal } from "libs/modals";
import { CreatePostModal } from "./components/CreatePostModal";

interface openCreatePostModalProps {
  type?: string;
  accounts?: unknown[];
  assets?: any[];
  caption?: string;
  labelIds?: string[];
}

const CREATE_POST_MODAL = "CREATE_POST_MODAL";

registerModal(CREATE_POST_MODAL, CreatePostModal);

export const openCreatePostModal = createAction<openCreatePostModalProps>(
  "OPEN_CREATE_POST_MODAL"
);
export const closeCreatePostModal = createAction("CLOSE_CREATE_POST_MODAL");

function* createPostModalSaga({ payload }: any) {
  const { callback, ...props } = payload;

  yield put(
    openModal({
      type: CREATE_POST_MODAL,
      props,
    })
  );

  const formData = yield take(closeCreatePostModal.type);
  yield put(
    closeModal({
      type: CREATE_POST_MODAL,
    })
  );
  callback?.(formData);
}

export function* createPostSaga() {
  yield takeEvery(openCreatePostModal.type, createPostModalSaga);
}
