import _ from "lodash";
import { createReducer } from "@reduxjs/toolkit";

import {
  CREATE_MESSAGE_ROUTINE,
  GET_CONVERSATIONS_ROUTINE,
  GET_MESSAGES_ROUTINE,
  PATCH_CONVERSATION_ROUTINE,
  SELECT_CONVERSATION_ACCOUNTS_ROUTINE,
  UPDATE_STAR_FILTER_ROUTINE,
  UPDATE_CONVERSATION_CONFIG_ROUTINE,
  DELETE_CONVERSATION_ROUTINE,
  GET_CONVERSATIONS_COUNTER_ROUTINE,
  ADD_CONVERSATION_ROUTINE,
  REMOVE_CONVERSATION_ROUTINE,
  ADD_MESSAGE_ROUTINE,
  REMOVE_MESSAGE_ROUTINE,
  GET_CONVERSATION_ROUTINE,
  ADD_COLLABORATOR_TYPING_ROUTINE,
  REMOVE_COLLABORATOR_TYPING_ROUTINE,
  RESYNC_INTERLOCUTOR,
  ADD_MESSAGE_REACTION_ROUTINE,
  REMOVE_MESSAGE_REACTION_ROUTINE,
  SET_MESSAGE_REACTION_PROCESSING,
  CLOSE_ALL_CONVERSATIONS_ROUTINE,
  SYNC_MESSAGES_ROUTINE,
  SET_CONVERSATION_TYPES_FILTER_ROUTINE,
  SELECT_CONVERSATIONS_ROUTINE,
  PATCH_CONVERSATIONS_ROUTINE,
  SET_COLLABORATOR_TYPINGS_ROUTINE,
  INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE,
  INBOX_SET_MESSAGE_HIDDEN_ROUTINE,
  RESYNC_POST_CONTENT,
  INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE,
  NOTIFY_INBOX_URL_CHANGE_ROUTINE,
  DELETE_MESSAGE_STATUS_ROUTINE,
} from "constants/ActionTypes";

import {
  ConversationPost,
  InboxLocalStorageKey,
} from "types/conversationInbox";

import { getLocalStorageSelectedAccounts } from "libs/storage/adapters";

import {
  Conversation,
  Conversations,
  Pagination,
  Message,
  ConversationsFilters,
  Status,
  TypingCollaborator,
  MessageStatus,
  ConversationsResponse,
  MessageType,
  ConversationType,
} from "types/conversationInbox";

import { immutableUpdate, immutableAdd } from "./reducerUtils";
import {
  AddReactionPayload,
  FetchMessagesAction,
  FetchMessagesSuccessAction,
  patchConversationsAction,
  RemoveReactionPayload,
  setConversationTypesFilterAction,
  selectConversationsPayload,
  setMessageReactionProcessingPayload,
  SyncMessagesAction,
  addMessageSuccessAction,
  collaboratorTypingAction,
  setMessageStatusRemovedAction,
  setMessageHiddenAction,
  setMessageStatusEditingAction,
} from "actions/conversationInbox";
import {
  immutableConversationUpdate,
  immutableMessageUpdate,
  immutableArrReplaceById,
  immutableUpdateById,
  immutableUpdateByIdOrAdd,
  immutableRemoveById,
} from "./immutableUtils";

type ConversationFilterConfigType = "MENTION";
export interface ConversationFilterConfig {
  isStarred: boolean;
  isMentioned: boolean;
  assignedTo: string | null;
  type: ConversationFilterConfigType | null;
  status: Status | null;
  unassigned: boolean | null;
}

export enum ConversationSort {
  Newest = "updatedAt:DESC",
  Oldest = "updatedAt:ASC",
}

export type ConversationInboxState = Conversations & {
  isConversationLoading: boolean;
  isConversationJustCleared: boolean;
  isConversationCountersLoading: boolean;
  sort: string;
  isStarred: boolean;
  isConversationUpdating: boolean;
  selectedAccounts: string[];
  assignedTo: string | null;
  status: Status | null;
  counter: ConversationsFilters;
  typingCollaborators: TypingCollaborator[];
  unassigned: boolean | null;
  isClosingAllConversationRequest?: boolean;
  types?: ConversationType[];
  selectedConversations: string[];
  editingMessage: Message | null;
};

export const defaultConfigState: ConversationFilterConfig = {
  isStarred: false,
  isMentioned: false,
  assignedTo: null,
  type: null,
  status: null,
  unassigned: null,
};

export const defaultFilterState: ConversationsFilters = {
  all: null,
  unassigned: null,
  assignedToMe: null,
  mentioned: null,
  starred: null,
  closed: null,
  unreadMessages: null,
  unreadConversations: null,
  taggedInPost: null,
};

const initSelectedConvTypes: ConversationType[] = [
  "MESSAGE",
  "THREADED_COMMENT",
  "POST",
];
export const initialState: ConversationInboxState = {
  isConversationLoading: false,
  isConversationUpdating: false,
  isConversationCountersLoading: false,
  // [24842] if the last conversation is handled, will mark as true and show a confetti
  isConversationJustCleared: false,
  conversations: [],
  typingCollaborators: [],
  next: null,
  previous: null,
  hasPrevious: false,
  hasNext: false,
  sort: ConversationSort.Newest,
  selectedAccounts: JSON.parse(
    getLocalStorageSelectedAccounts() || JSON.stringify([])
  ),
  types: JSON.parse(
    localStorage.getItem(InboxLocalStorageKey.inboxSelectedConversationTypes) ||
      JSON.stringify(initSelectedConvTypes)
  ),
  selectedConversations: [],
  ...defaultConfigState,
  counter: defaultFilterState,
  editingMessage: null,
};

const conversationInboxReducer = createReducer(initialState, {
  [NOTIFY_INBOX_URL_CHANGE_ROUTINE.REQUEST]: (state) => {
    return {
      ...state,
      editingMessage: null,
    };
  },
  [GET_CONVERSATIONS_ROUTINE.REQUEST]: (state) => {
    return {
      ...state,
      isConversationJustCleared: false,
      isConversationLoading: true,
    };
  },

  [GET_CONVERSATIONS_ROUTINE.SUCCESS]: (state, action) => {
    const {
      next = null,
      previous = null,
      hasPrevious = false,
      hasNext = false,
      items = [],
    }: ConversationsResponse = action?.payload?.data ?? {};
    const reset = action?.payload?.reset;
    const existingConversations: Conversations["conversations"] = reset
      ? []
      : state.conversations || [];

    const newConversations = reset
      ? items.reduce((list, conversation) => {
          const existingConv = state.conversations?.find(
            (stateConv) => stateConv.id === conversation.id
          );
          if (existingConv) {
            return [
              ...list,
              {
                ...conversation,
                ...existingConv,
              },
            ];
          }
          return [...list, conversation];
        }, [] as Conversation[])
      : items;

    const uniqConverations = _.uniqBy(
      [...existingConversations, ...newConversations],
      "id"
    );
    return {
      ...state,
      next,
      previous,
      hasPrevious,
      hasNext,
      conversations: uniqConverations,
      isConversationLoading: false,
    };

    // TODO: Write logic for pagination
  },

  [GET_CONVERSATIONS_ROUTINE.FAILURE]: (state) => {
    // TODO: Find a way to display error message?
    return {
      ...state,
      isConversationLoading: false,
    };
  },

  [GET_CONVERSATION_ROUTINE.REQUEST]: (state) => {
    return {
      ...state,
      isConversationLoading: true,
    };
  },

  [GET_CONVERSATION_ROUTINE.SUCCESS]: (state, action) => {
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conversation: Conversation) => conversation.id === action.payload.data.id
    );

    if (conversationIdIndex === -1) {
      return {
        ...state,
        conversations: _.orderBy(
          [...conversations, action.payload.data],
          "updatedAt",
          "desc"
        ),
      };
    } else {
      return {
        ...state,
        conversations: immutableUpdate(
          conversations,
          conversationIdIndex,
          action.payload.data
        ),
      };
    }
  },

  [GET_CONVERSATION_ROUTINE.FAILURE]: (state) => {
    // TODO: Find a way to display error message?
    return {
      ...state,
      isConversationLoading: false,
    };
  },

  [GET_MESSAGES_ROUTINE.TRIGGER]: (
    state,
    action: FetchMessagesAction
  ): ConversationInboxState => {
    const conversationId = action.payload.conversationId;
    return {
      ...state,
      conversations: immutableUpdateById(
        state.conversations || [],
        conversationId,
        (conv) => ({
          ...conv,
          isMessagesLoading: true,
          isMessagesFetched: true,
          isLastMessagesFetchedError: false,
          lastMessagesFetchedTime: new Date(),
        })
      ),
    };
  },
  [GET_MESSAGES_ROUTINE.SUCCESS]: (
    state: ConversationInboxState,
    action: FetchMessagesSuccessAction
  ): ConversationInboxState => {
    const conversations = state.conversations || [];
    const conversationIdIndex = conversations.findIndex(
      (c) => c.id === action?.payload?.conversationId
    );

    if (conversationIdIndex < 0) {
      return state;
    }

    const {
      next = null,
      previous = null,
      hasPrevious = false,
      hasNext = false,
      items,
    }: Pagination<Message> = action?.payload?.data;
    const conv = conversations[conversationIdIndex];
    let existingMessages = conv?.messages ?? [];

    const [replacingMessages, addedMessages] = _.partition(items, (c) =>
      existingMessages.some((d) => c.id == d.id)
    );

    existingMessages = immutableArrReplaceById(
      existingMessages,
      replacingMessages
    );
    const { setMessagesOnly } = action.payload;
    const paginationInfo: Partial<Conversation> = setMessagesOnly
      ? {}
      : {
          next,
          previous,
          hasPrevious,
          hasNext,
        };
    return {
      ...state,
      conversations: immutableUpdate(conversations, conversationIdIndex, {
        ...paginationInfo,
        messages: orderMessages(existingMessages.concat(addedMessages)),
        isMessagesLoading: false,
        isMessagesFetched: true,
        isLastMessagesFetchedError: false,
        lastMessagesFetchedTime: new Date(),
      }),
    };
  },
  [GET_MESSAGES_ROUTINE.FAILURE]: (state, action) => {
    const conversationId = action.payload.conversationId;
    return {
      ...state,
      conversations: immutableUpdateById(
        state.conversations || [],
        conversationId,
        (conv) => ({
          ...conv,
          isMessagesLoading: false,
          isMessagesFetched: true,
          isLastMessagesFetchedError: true,
        })
      ),
    };
  },

  [SYNC_MESSAGES_ROUTINE.REQUEST]: (state, action: SyncMessagesAction) => {
    return immutableConversationUpdate({
      state,
      convId: action.payload.conversationId,
      updater: (c) => ({ ...c, lastMessagesFetchedTime: new Date() }),
    });
  },
  [CREATE_MESSAGE_ROUTINE.REQUEST]: (state, action) => {
    const conversationId: string = action?.payload?.conversationId;
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conversation: Conversation) => conversation.id === conversationId
    );
    const existingMessages = conversations[conversationIdIndex]?.messages ?? [];
    state.conversations = immutableUpdate(conversations, conversationIdIndex, {
      messages: [...existingMessages, action.payload.message],
    });
  },

  [CREATE_MESSAGE_ROUTINE.SUCCESS]: (state, action) => {
    const { conversationId, message, temporaryId } = action?.payload;
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conversation: Conversation) => conversation.id === conversationId
    );

    if (conversationIdIndex < 0) {
      return;
    }

    let existingMessages = conversations[conversationIdIndex]?.messages ?? [];
    // Find target message with the same temporaryId
    const targetMessageIndex = existingMessages.findIndex(
      (message) => message.id === temporaryId
    );

    if (targetMessageIndex > -1) {
      // Replace the temporary message with real data
      existingMessages = immutableUpdate(existingMessages, targetMessageIndex, {
        ...message,
        status: MessageStatus.success,
      });

      return {
        ...state,
        conversations: immutableUpdate(conversations, conversationIdIndex, {
          messages: [...existingMessages],
        }),
      };
    }

    return state;
  },

  [CREATE_MESSAGE_ROUTINE.FAILURE]: (state, action) => {
    const conversationId: string = action?.payload?.conversationId;
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conversation: Conversation) => conversation.id === conversationId
    );

    let existingMessages = conversations[conversationIdIndex]?.messages ?? [];
    const targetMessageIndex = existingMessages.findIndex(
      (message) => message.id === action.payload.message.id
    );

    if (targetMessageIndex > -1) {
      existingMessages = immutableUpdate(existingMessages, targetMessageIndex, {
        ...existingMessages[targetMessageIndex],
        status: MessageStatus.failed,
      });

      return {
        ...state,
        conversations: immutableUpdate(conversations, conversationIdIndex, {
          messages: [...existingMessages],
        }),
      };
    }

    return state;
  },

  [UPDATE_STAR_FILTER_ROUTINE.REQUEST]: (state, action) => {
    return {
      ...state,
      isStarred: action.payload.isStarred,
      next: null,
    };
  },
  [PATCH_CONVERSATION_ROUTINE.REQUEST]: (state, action) => {
    const { conversationId, ...conversation } = action?.payload;

    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conversation: Conversation) => conversation.id === conversationId
    );

    if (conversationIdIndex > -1) {
      const targetConversation = conversations[conversationIdIndex];

      return {
        ...state,
        conversations: immutableUpdate(conversations, conversationIdIndex, {
          ...targetConversation,
          ...conversation,
        }),
        isConversationUpdating: false,
      };
    }

    return {
      ...state,
      isConversationUpdating: false,
    };
  },
  [PATCH_CONVERSATION_ROUTINE.SUCCESS]: (state, action) => {
    const conversation: Conversation = action.payload.data;
    const conversationId: string = conversation.id;

    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conversation: Conversation) => conversation.id === conversationId
    );

    if (conversationIdIndex > -1) {
      const orignalConv = conversations[conversationIdIndex];

      const updatingConversation = {
        ...orignalConv,
        ...conversation,
        post: {
          ...orignalConv?.post,
          ...conversation.post,
        },
      };

      return {
        ...state,
        conversations: immutableUpdate(
          conversations,
          conversationIdIndex,
          updatingConversation
        ),
        isConversationUpdating: false,
      };
    }

    return {
      ...state,
      isConversationUpdating: false,
    };
  },
  [PATCH_CONVERSATION_ROUTINE.FAILURE]: (state, action) => {
    const conversation: Conversation = action?.payload?.conversation;
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const conversationIdIndex: number = conversations.findIndex(
      (conv: Conversation) => conv.id === conversation.id
    );

    if (conversationIdIndex > -1) {
      return {
        ...state,
        conversations: immutableUpdate(conversations, conversationIdIndex, {
          ...conversation,
        }),
        isConversationUpdating: false,
      };
    }

    return {
      ...state,
      isConversationUpdating: false,
    };
  },
  [PATCH_CONVERSATIONS_ROUTINE.REQUEST]: (state, action) => {
    const {
      conversationIds,
      ...updateConversation
    }: patchConversationsAction["payload"] = action.payload;
    const conversations: Conversations["conversations"] =
      state.conversations || [];

    return {
      ...state,
      conversations: conversations.map((conversation) => {
        if (conversationIds.includes(conversation.id)) {
          return {
            ...conversation,
            ...updateConversation,
          };
        }
        return {
          ...conversation,
        };
      }),
    };
  },
  [PATCH_CONVERSATIONS_ROUTINE.SUCCESS]: (state, action) => {
    const { data }: { data: Conversation[] } = action.payload;
    const conversations: Conversations["conversations"] =
      state.conversations || [];

    const updatedConversationMap = data.reduce((accMap, conversation) => {
      accMap[conversation.id] = conversation;
      return accMap;
    }, {});

    const updatedConversationIds = Object.keys(updatedConversationMap);

    return {
      ...state,
      conversations: conversations.map((conversation) => {
        if (updatedConversationIds.includes(conversation.id)) {
          return {
            ...conversation,
            ...updatedConversationMap[conversation.id],
          };
        }
        return {
          ...conversation,
        };
      }),
    };
  },
  [PATCH_CONVERSATIONS_ROUTINE.FAILURE]: (state, action) => {
    const { conversations }: { conversations: Conversation[] } = action.payload;
    return {
      ...state,
      conversations: [...conversations],
    };
  },
  [SELECT_CONVERSATION_ACCOUNTS_ROUTINE.REQUEST]: (
    state,
    action: { payload: string[] }
  ) => {
    const selectedAccounts = action.payload;
    return {
      ...state,
      selectedAccounts,
    };
  },
  [UPDATE_CONVERSATION_CONFIG_ROUTINE.REQUEST]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [DELETE_CONVERSATION_ROUTINE.SUCCESS]: (state, action) => {
    const conversationIds = Array.isArray(action.payload)
      ? action.payload
      : [action.payload];
    const conversations: Conversations["conversations"] =
      state.conversations || [];

    return {
      ...state,
      conversations: conversations.filter((conversation) => {
        return !conversationIds.includes(conversation.id);
      }),
    };
  },
  [GET_CONVERSATIONS_COUNTER_ROUTINE.REQUEST]: (state) => {
    return {
      ...state,
      isConversationCountersLoading: true,
    };
  },
  [GET_CONVERSATIONS_COUNTER_ROUTINE.SUCCESS]: (state, action) => {
    return {
      ...state,
      counter: action.payload,
      isConversationCountersLoading: false,
    };
  },
  [GET_CONVERSATIONS_COUNTER_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      isConversationCountersLoading: false,
    };
  },
  [ADD_CONVERSATION_ROUTINE.REQUEST]: (state, action) => {
    const { conversationIdIndex, conversation } = action?.payload;
    const conversations: Conversations["conversations"] =
      state.conversations || [];

    return {
      ...state,
      conversations: immutableAdd(
        conversations,
        conversationIdIndex,
        conversation
      ),
    };
  },
  [REMOVE_CONVERSATION_ROUTINE.REQUEST]: (state, action) => {
    const conversationIds = action.payload;
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    return {
      ...state,
      conversations: conversations.map((conversation) => {
        if (conversationIds.includes(conversation.id)) {
          return {
            ...conversation,
            isComplete: true,
          };
        }
        return {
          ...conversation,
        };
      }),
    };
  },
  [REMOVE_CONVERSATION_ROUTINE.SUCCESS]: (state, action) => {
    const conversationIds = action.payload;
    const conversations: Conversations["conversations"] =
      state.conversations || [];
    const newConversations = conversations.filter(
      (conversation) => !conversationIds.includes(conversation.id)
    );
    return {
      ...state,
      isConversationJustCleared: newConversations.length === 0,
      conversations: newConversations,
    };
  },

  [ADD_MESSAGE_ROUTINE.SUCCESS]: (
    state: ConversationInboxState,
    action: addMessageSuccessAction
  ) => {
    const { conversationId, message, ignoreIncreaseUnreadCount } =
      action.payload;
    const conversations = state.conversations || [];
    const convIdx = conversations.findIndex((c) => c.id === conversationId);

    const conv = conversations[convIdx];
    if (!conv || convIdx < 0) {
      return state;
    }

    const existingMessages = conv.messages ?? [];
    if (existingMessages.find((c) => c.id === message.id)) {
      return state;
    }

    const isMessagefromSked = message.sender?.platform === "SK";
    const unreadCountIncrease =
      ignoreIncreaseUnreadCount || isMessagefromSked ? 0 : 1;
    const unreadCount =
      conv.status === Status.closed
        ? 0
        : (conv.unreadCount ?? 0) + unreadCountIncrease;

    return {
      ...state,
      conversations: immutableUpdate(conversations, convIdx, {
        unreadCount,
        updatedAt: new Date().toISOString(),
        messages: orderMessages([...existingMessages, message]),
      }),
    };
  },
  [REMOVE_MESSAGE_ROUTINE.SUCCESS]: (state, action): ConversationInboxState => {
    const { conversationId, messageId } = action.payload;
    if (!messageId) {
      return state;
    }

    const conversations = state.conversations || [];
    const conversationIdIndex = conversations.findIndex(
      (c) => c.id === conversationId
    );

    if (conversationIdIndex < 0) {
      return state;
    }
    const conv = conversations[conversationIdIndex];
    if (!conv) {
      return state;
    }

    const messages = conv.messages ?? [];
    const messageIdIndex = messages.findIndex((m) => m.id === messageId);
    if (messageIdIndex < 0) {
      return state;
    }

    const newMessages = immutableUpdate(messages, messageIdIndex);
    const unreadCount = conversations[conversationIdIndex].unreadCount;
    // if message that get deleted are one of unread messages, reduce unread message count
    const decrementUnreadCount =
      unreadCount > 0 && newMessages.length - messageIdIndex <= unreadCount;

    return {
      ...state,
      conversations: immutableUpdate(conversations, conversationIdIndex, {
        messages: newMessages,
        updatedAt: newMessages[newMessages.length - 1]?.createdAt || Date.now(),
        ...(decrementUnreadCount ? { unreadCount: unreadCount - 1 } : {}),
      }),
    };
  },
  [ADD_MESSAGE_REACTION_ROUTINE.SUCCESS]: (
    state,
    { payload }: { payload: AddReactionPayload }
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: payload.conversationId,
      msgId: payload.messageId,
      updater: (foundMsg) => {
        if (foundMsg.type === MessageType.ttThreadedComment) {
          return {
            ...foundMsg,
            liked: true,
          };
        }
        let newReactions = (foundMsg.reactions || []).filter(
          (c) =>
            c.id !== payload.id &&
            c.id !== payload.tempId &&
            c.reactee !== payload.reactee
        );

        newReactions = newReactions.concat([
          {
            id: payload.id,
            reactee: payload.reactee,
            reaction: payload.reaction,
            emoji: payload.emoji,
            createdAt: new Date().toISOString(),
          },
        ]);
        return {
          ...foundMsg,
          reactions: newReactions,
        };
      },
    });
  },
  [DELETE_MESSAGE_STATUS_ROUTINE.SUCCESS]: (
    state,
    action
  ): ConversationInboxState => {
    const { conversationId, messageId } = action.payload;
    if (!messageId) {
      return state;
    }

    const conversations = state.conversations || [];
    const conversationIdIndex = conversations.findIndex(
      (c) => c.id === conversationId
    );

    if (conversationIdIndex < 0) {
      return state;
    }
    const conv = conversations[conversationIdIndex];
    if (!conv) {
      return state;
    }

    const messages = conv.messages ?? [];
    const messageIdIndex = messages.findIndex((m) => m.id === messageId);
    if (messageIdIndex < 0) {
      return state;
    }

    const targetMessage = messages[messageIdIndex];
    if (!targetMessage) {
      return state;
    }

    const newMessages = immutableUpdate(messages, messageIdIndex, {
      ...targetMessage,
      deleted: true,
    });

    return {
      ...state,
      conversations: immutableUpdate(conversations, conversationIdIndex, {
        messages: newMessages,
        updatedAt: Date.now(),
      }),
    };
  },
  [REMOVE_MESSAGE_REACTION_ROUTINE.SUCCESS]: (
    state,
    { payload }: { payload: RemoveReactionPayload }
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: payload.conversationId,
      msgId: payload.messageId,
      updater: (foundMsg) => {
        if (foundMsg.type === MessageType.ttThreadedComment) {
          return {
            ...foundMsg,
            liked: false,
          };
        }

        const newReactions = (foundMsg.reactions || []).filter(
          (c) => c.id !== payload.id && c.reactee !== payload.reactee
        );
        return {
          ...foundMsg,
          reactions: newReactions,
        };
      },
    });
  },
  [SET_MESSAGE_REACTION_PROCESSING]: (
    state,
    { payload }: { payload: setMessageReactionProcessingPayload }
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: payload.conversationId,
      msgId: payload.messageId,
      updater: (foundMsg) => {
        return {
          ...foundMsg,
          isReationProcessing: payload.isProcessing,
        };
      },
    });
  },
  [ADD_COLLABORATOR_TYPING_ROUTINE.SUCCESS]: (
    state,
    action: collaboratorTypingAction
  ) => {
    const { typingCollaborators = [] } = state;
    const { payload } = action;
    if (payload.enabled) {
      return {
        ...state,
        typingCollaborators: immutableUpdateByIdOrAdd(
          typingCollaborators,
          payload.id,
          {
            ...payload,
            createdAt: new Date().getTime(),
          }
        ),
      };
    }
    return {
      ...state,
      typingCollaborators: immutableRemoveById(typingCollaborators, payload.id),
    };
  },
  [REMOVE_COLLABORATOR_TYPING_ROUTINE.SUCCESS]: (state, action) => {
    return {
      ...state,
      typingCollaborators: immutableRemoveById(
        state.typingCollaborators || [],
        action.payload.id
      ),
    };
  },
  [SET_COLLABORATOR_TYPINGS_ROUTINE.SUCCESS]: (
    state,
    { payload }: { payload: TypingCollaborator[] }
  ) => {
    return {
      ...state,
      typingCollaborators: payload,
    };
  },
  [RESYNC_INTERLOCUTOR.SUCCESS]: (state, action) => {
    const conversations = state.conversations || [];
    const conversationToUpdateIndex = conversations?.findIndex(
      (conv) => conv.id === action.payload.conversationId
    );
    const conversationToUpdate = conversations[conversationToUpdateIndex];

    if (!conversationToUpdate) {
      return state;
    }

    const updatedConversation = {
      ...conversationToUpdate,
      interlocutor: {
        ...conversationToUpdate.interlocutor,
        image: action.payload.image,
      },
    };

    return {
      ...state,
      conversations: immutableUpdate(
        conversations,
        conversationToUpdateIndex,
        updatedConversation
      ),
    };
  },
  [CLOSE_ALL_CONVERSATIONS_ROUTINE.REQUEST]: (state) => {
    return {
      ...state,
      isClosingAllConversationRequest: true,
    };
  },
  [CLOSE_ALL_CONVERSATIONS_ROUTINE.SUCCESS]: (state) => {
    return {
      ...state,
      isClosingAllConversationRequest: false,
    };
  },
  [CLOSE_ALL_CONVERSATIONS_ROUTINE.FAILURE]: (state) => {
    return {
      ...state,
      isClosingAllConversationRequest: false,
    };
  },
  [SET_CONVERSATION_TYPES_FILTER_ROUTINE.REQUEST]: (
    state,
    action: setConversationTypesFilterAction
  ): ConversationInboxState => {
    localStorage.setItem(
      InboxLocalStorageKey.inboxSelectedConversationTypes,
      JSON.stringify(action.payload.types)
    );
    return {
      ...state,
      types: action.payload.types,
    };
  },
  [SELECT_CONVERSATIONS_ROUTINE.REQUEST]: (state, action) => {
    const { selected, conversationIds }: selectConversationsPayload =
      action.payload;

    if (conversationIds.length === 0) {
      return {
        ...state,
        selectedConversations: [],
      };
    }

    let selectedConversations;

    if (selected) {
      selectedConversations = Array.from(
        new Set([...state.selectedConversations, ...conversationIds])
      );
    } else {
      selectedConversations = state.selectedConversations.filter(
        (convId) => !conversationIds.includes(convId)
      );
    }

    return {
      ...state,
      selectedConversations,
    };
  },
  [INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.REQUEST]: (
    state,
    { payload: { conversationId, id } }: setMessageStatusRemovedAction
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: conversationId,
      msgId: id,
      updater: (msg) => ({ ...msg, deleted: true }),
    });
  },
  [INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.FAILURE]: (
    state,
    { payload: { conversationId, id } }: setMessageStatusRemovedAction
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: conversationId,
      msgId: id,
      updater: (msg) => ({ ...msg, deleted: false }),
    });
  },
  [INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.REQUEST]: (
    state,
    { payload: { message, action } }: setMessageStatusEditingAction
  ): ConversationInboxState => {
    return {
      ...state,
      editingMessage: action === "enable" && message ? message : null,
    };
  },
  [INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.FAILURE]: (
    state
  ): ConversationInboxState => {
    return {
      ...state,
      editingMessage: null,
    };
  },
  [INBOX_SET_MESSAGE_HIDDEN_ROUTINE.REQUEST]: (
    state,
    { payload: { conversationId, id, hidden } }: setMessageHiddenAction
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: conversationId,
      msgId: id,
      updater: (msg) => ({ ...msg, hidden, isHiddenProcessing: true }),
    });
  },
  [INBOX_SET_MESSAGE_HIDDEN_ROUTINE.SUCCESS]: (
    state,
    { payload: { conversationId, id, hidden } }: setMessageHiddenAction
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: conversationId,
      msgId: id,
      updater: (msg) => ({ ...msg, hidden, isHiddenProcessing: false }),
    });
  },
  [INBOX_SET_MESSAGE_HIDDEN_ROUTINE.FAILURE]: (
    state,
    { payload: { conversationId, id, hidden } }: setMessageHiddenAction
  ): ConversationInboxState => {
    return immutableMessageUpdate({
      state,
      convId: conversationId,
      msgId: id,
      updater: (msg) => ({
        ...msg,
        hidden: !hidden,
        isHiddenProcessing: false,
      }),
    });
  },
  [RESYNC_POST_CONTENT.SUCCESS]: (
    state: ConversationInboxState,
    { payload: post }: { payload: ConversationPost }
  ): ConversationInboxState => {
    const excludeFields: (keyof ConversationPost)[] = ["id", "owner"];
    return {
      ...state,
      conversations: updateConversationPosts(state.conversations, post.id, {
        ..._.omit(post, excludeFields),
        resyncing: false,
        resyncRequested: true,
      }),
    };
  },
  [RESYNC_POST_CONTENT.REQUEST]: (
    state: ConversationInboxState,
    { payload }: { payload: { postId: string } }
  ): ConversationInboxState => {
    return {
      ...state,
      conversations: updateConversationPosts(
        state.conversations,
        payload.postId,
        {
          resyncing: true,
          resyncRequested: true,
        }
      ),
    };
  },
  [RESYNC_POST_CONTENT.FAILURE]: (
    state: ConversationInboxState,
    { payload }: { payload: { postId: string } }
  ): ConversationInboxState => {
    return {
      ...state,
      conversations: updateConversationPosts(
        state.conversations,
        payload.postId,
        {
          resyncing: false,
          resyncRequested: true,
        }
      ),
    };
  },
});

function updateConversationPosts(
  conversations: Conversation[] | undefined,
  postId: string,
  data: Partial<ConversationPost>
): Conversation[] {
  return (conversations || []).map((conv) => {
    if (conv.post?.id === postId) {
      return {
        ...conv,
        post: {
          ...conv.post,
          ...data,
        },
      };
    }
    return conv;
  });
}

export function orderMessages<T extends Message>(messages: T[]): T[] {
  messages = _.uniqBy(messages, (c) => c.id);
  return _.orderBy(
    messages,
    [
      (c) => {
        return getMessageCreatedAtAsTime(c);
      },
      (c) => (c.type === MessageType.system ? 1 : 0),
    ],
    ["asc", "asc"]
  );
}
export const getMessageCreatedAtAsTime = (message: Message): number => {
  try {
    if (typeof message.createdAt === "string") {
      return new Date(message.createdAt).getTime();
    }
    if (message.createdAt instanceof Date) {
      return message.createdAt.getTime();
    }
    return message.createdAt;
  } catch {
    return new Date().getTime();
  }
};

export type RootState = ReturnType<typeof conversationInboxReducer>;

export default conversationInboxReducer;
