import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { handleError } from "utils/handleError";
import {
  fetchCalendar,
  removePastQueue,
  removeSchedule,
  removeScheduledQueue,
  scheduleDraft,
  updateScheduledPost,
  sendCalendarFeedback,
  deleteDraft,
  updateDraftPost,
} from "api/calendar";

import {
  moveQueueToSchedule,
  moveQueueToDraft,
  moveScheduleToDraft,
  editDraft,
} from "api/post";

import {
  CalendarPostType,
  InitialCalendarStateType,
  RequestPostsOptionsTypes,
} from "./types";
import { NgPost } from "../post/format-post";
import { Post } from "../post";
import { standardizationPostData } from "utils/posts";

export const fetchCalendarPosts = createAsyncThunk<
  string,
  RequestPostsOptionsTypes
>(
  "calendar/fetchCalendarPosts",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const result = await fetchCalendar(data);

      return Promise.all(
        (result?.data?.posts || []).map(standardizationPostData)
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePost = createAsyncThunk<string, any>(
  "calendar/updatePost",
  async (data, { rejectWithValue }) => {
    const { postId, post } = data;

    try {
      if (post.queued) {
        const result = await moveQueueToSchedule({ ...post, _id: postId });

        return result?.data[0];
      } else {
        const result = await updateScheduledPost(postId, post);
        return result?.data;
      }
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDraft = createAsyncThunk<string, any>(
  "calendar/updateDraft",
  async (post, { rejectWithValue }) => {
    try {
      const result = await updateDraftPost(post);

      return result?.data;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err.response.data);
    }
  }
);

export const scheduleDraftPost = createAsyncThunk<Post, any>(
  "calendar/scheduleDraftPost",
  async (post, { rejectWithValue }) => {
    try {
      const result = await scheduleDraft(post);

      return result?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDraftPost = createAsyncThunk<string, any>(
  "calendar/deleteDraftPost",
  async (data, { rejectWithValue }) => {
    try {
      const result = await deleteDraft(data.postId);

      return result?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const moveToDraft = createAsyncThunk<string, any>(
  "calendar/moveToDraft",
  async (post: NgPost): Promise<any> => {
    if (!post.queued) {
      return await moveScheduleToDraft(post._id);
    }

    const newId = await moveQueueToDraft(post._id);
    // @ts-ignore
    return await editDraft({
      ...post,
      accountIds: Array.isArray(post.accountIds)
        ? post.accountIds
        : [post.accountId],
      _id: newId,
    });
  }
);

export const removeScheduledPost = createAsyncThunk<string, any>(
  "calendar/removePost",
  // @ts-ignore
  async ({
    postId,
    isQueue,
    isActive,
  }: {
    postId: string;
    isQueue: boolean;
    isActive: boolean;
  }) => {
    if (isQueue) {
      return isActive
        ? await removeScheduledQueue(postId)
        : await removePastQueue(postId);
    }
    return await removeSchedule(postId, isActive);
  }
);

export const calendarFeedback = createAsyncThunk<string, any>(
  "calendar/feedback",
  // @ts-ignore
  async ({ feedback }: { feedback: string }) =>
    await sendCalendarFeedback({ feedback })
);

const calendarAdapter = createEntityAdapter({
  sortComparer: (a: CalendarPostType, b: CalendarPostType) =>
    a._id.localeCompare(b._id),
  selectId: (item) => item._id,
});

const initialState = {
  isLoading: false,
  ids: [],
  entities: {},
} as InitialCalendarStateType;

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    changePostProperty: (
      state: InitialCalendarStateType,
      action: { payload: { postId: string; key: string; value: any } }
    ) => {
      const { postId, key, value } = action.payload;
      // @ts-ignore
      calendarAdapter.updateOne(state, {
        id: postId,
        changes: { [key]: value },
      });
    },
    updateEntity: (state, action) => {
      calendarAdapter.updateOne(state, action.payload);
    },
    clearPosts: (state: InitialCalendarStateType) => {
      calendarAdapter.removeAll(state);
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      fetchCalendarPosts.fulfilled,
      (
        state: InitialCalendarStateType,
        action: { payload: CalendarPostType[] }
      ) => {
        calendarAdapter.setAll(state, action.payload);
        state.isLoading = false;
      }
    );
    builder.addCase(
      fetchCalendarPosts.pending,
      (state: InitialCalendarStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      fetchCalendarPosts.rejected,
      (state: InitialCalendarStateType, action: { payload: string }) => {
        calendarAdapter.setAll(state, []);
        handleError(action.payload, "There was an error");
        state.isLoading = false;
      }
    );

    builder.addCase(
      updatePost.rejected,
      (_: any, action: { payload: string }) => {
        handleError(action.payload, "There was an error");
      }
    );
    builder.addCase(
      scheduleDraftPost.pending,
      (state: InitialCalendarStateType) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      scheduleDraftPost.fulfilled,
      (state: InitialCalendarStateType) => {
        toast.success("Schedule added successfully");
        state.isLoading = false;
      }
    );

    builder.addCase(
      scheduleDraftPost.rejected,
      (state: InitialCalendarStateType, { payload }: { payload: any }) => {
        state.isLoading = false;
        toast.error(payload?.response?.data?.error || "There was an error");
      }
    );

    builder.addCase(
      calendarFeedback.pending,
      (state: InitialCalendarStateType) => {
        state.isLoading = true;
      }
    );

    builder.addCase(
      calendarFeedback.fulfilled,
      (state: InitialCalendarStateType) => {
        toast.success("Feedback added successfully");
        state.isLoading = false;
      }
    );

    builder.addCase(
      calendarFeedback.rejected,
      (state: InitialCalendarStateType, { payload }: { payload: any }) => {
        state.isLoading = false;
        toast.error(payload?.response?.data?.error || "There was an error");
      }
    );
  },
});

export const calendarReducer = calendarSlice.reducer;
export const calendarActions = calendarSlice.actions;
