import { uploadFileToS3 } from "api/media-library/upload-file-to-s3";
import { FormPost, NgPost } from "./format-post";

export function creatingPostNeedUploadCover(post: any) {
  return post?.videoCoverImageTimestamp > 0 && post.videoCoverPreview;
}

export function editingPostNeedUploadCover(
  currentPost: NgPost,
  previousPost: FormPost
) {
  return (
    currentPost.videoCoverImageTimestamp &&
    currentPost.videoCoverImageTimestamp > 0 &&
    currentPost.videoCoverImageTimestamp !==
      previousPost?.videoCoverImageTimestamp
  );
}

export async function getUpdateThumbnail(currentPost: any) {
  return fetch(currentPost.videoCoverPreview)
    .then((res) => res.blob())
    .then((blob) =>
      uploadFileToS3({
        blob,
        fileName: `${currentPost?._id}-video_cover_${Date.now()}.png`,
      })
    )
    .catch(() => currentPost?.thumbnailUrl);
}
