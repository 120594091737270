import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { changePage } from "actions/pagination";
import { makePages } from "ui/Pagination/make-pages";
import styled from "styled-components";

const PageSelector = ({ numPages, currentPage, onChange }) => {
  const dispatch = useDispatch();
  const pages = makePages(1, numPages);

  const goTo = (e, page) => {
    e.preventDefault();
    if (page < 1 || page > numPages) {
      return;
    }

    if (currentPage === page) {
      return;
    }

    dispatch(changePage(page));

    onChange(page);
  };

  return (
    <PageSelectorWrapper className="pagination pagination-sm">
      <li className={`${currentPage === 1 ? "disabled" : ""}`}>
        <a aria-label="Previous" onClick={(e) => goTo(e, currentPage - 1)}>
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      {pages.map((page, index) => {
        return (
          <li
            key={`page-${index}`}
            className={`${currentPage === page ? "active" : ""}`}
          >
            {!page.placeholder && <a onClick={(e) => goTo(e, page)}>{page}</a>}
            {page.placeholder && <a href="">...</a>}
          </li>
        );
      })}
      <li className={`${currentPage === numPages ? "disabled" : ""}`}>
        <a aria-label="Next" onClick={(e) => goTo(e, currentPage + 1)}>
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </PageSelectorWrapper>
  );
};

PageSelector.propTypes = {
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
};

const PageSelectorWrapper = styled.ul`
  // NOTE (Andrey) centered pagination special for get spaces for intercom
  margin-left: 40px;
`;

export default PageSelector;
