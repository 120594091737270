import React, { useState } from "react";
import { PlatformUsage, PostFieldLabel } from "components/Posts/PostForm";
import { FIELDS } from "constants/PostFormFields";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { Select } from "ui/ecosystems/forms";
import { get } from "lodash";

const ThreadsReplyControl = ({
  post,
  selectedPlatform,
  platforms,
  disabled = false,
  onChange,
  errors,
  className = "",
}: {
  post: any;
  disabled?: boolean;
  errors: any;
  onChange: (value: any) => void;
  className?: string;
  updateField: (field: string, value: any) => void;
  allDisabled: false;
  platforms?: any[];
  selectedPlatform?: string;
}) => {
  // sometimes the post is a prop of the "post" passed in
  // (post.post) haven't checked why this is the case
  const thReplyValueInPost =
    post && get(post, FIELDS.TH_REPLY_CONTROL, undefined);
  const thReplyValueInPostPropertyOfPost =
    post && post.post && get(post.post, FIELDS.TH_REPLY_CONTROL, undefined);
  const thReplyValue =
    thReplyValueInPost || thReplyValueInPostPropertyOfPost || "everyone";
  const [selected, setSelected] = useState(thReplyValue);

  const replyControlOptions = [
    {
      value: "everyone",
      label: "Everyone",
    },
    {
      value: "accounts_you_follow",
      label: "Accounts you follow",
    },
    {
      value: "mentioned_only",
      label: "Mentioned only",
    },
  ];

  const handleSelectChange = (item: any) => {
    setSelected(item);
    onChange(item);
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-items-center">
        <PostFieldLabel className="tw-mb-2 tw-mr-4">
          Threads reply control
        </PostFieldLabel>
        <PlatformUsage
          platforms={platforms}
          field={FIELDS.TH_REPLY_CONTROL}
          selectedPlatform={selectedPlatform}
        />
      </div>
      <Select
        value={selected}
        options={replyControlOptions}
        onChange={handleSelectChange}
        disabled={disabled}
      />
      <PostFieldError
        className="tw-mt-4"
        fields={[FIELDS.TH_REPLY_CONTROL]}
        errors={errors}
      />
    </div>
  );
};

export default ThreadsReplyControl;
