import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { selectExistingHashtag } from "actions/sourceMedia";
import HashtagListWrapper from "./HashtagListWrapper";
import AddHashtag from "./AddHashtag";
import HashtagRow from "./HashtagRow";
import {
  WEEKLY_HASHTAG_SEARCH_MAX,
  DEFAULT_HASHTAG_FILTER,
} from "constants/MediaLibrary";

const ActiveHashtags = ({ account, hashtags, selectedIgAccount }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="tw-mx-8 tw-mb-8 tw-text-xl tw-font-hairline">
        Select an active hashtag below to generate results.{" "}
        <b>
          {hashtags.length} of {WEEKLY_HASHTAG_SEARCH_MAX}
        </b>{" "}
        hashtags have been activated and each will expire after 7 days.
      </div>

      {hashtags.length > 0 && (
        <HashtagListWrapper defaultIsOpen={true} title="Active Hashtags">
          {hashtags.map((hashtag) => {
            return (
              <HashtagRow
                key={hashtag.hashtag_id}
                account={account}
                hashtag={hashtag}
                onClick={() =>
                  dispatch(
                    selectExistingHashtag(account._id, hashtag.hashtag_id, {
                      ...DEFAULT_HASHTAG_FILTER,
                      excludeAccounts: [selectedIgAccount._id],
                    })
                  )
                }
              />
            );
          })}
        </HashtagListWrapper>
      )}

      <div className="tw-mt-4 tw-mb-8 tw-mx-8">
        <AddHashtag account={selectedIgAccount} hashtags={hashtags} />
      </div>
    </>
  );
};

ActiveHashtags.propTypes = {
  account: PropTypes.object,
  hashtags: PropTypes.array,
  selectedIgAccount: PropTypes.object,
};

export default ActiveHashtags;
