import React from "react";
import styled from "styled-components";

import { UploadingMediaPreview } from "../uploading-media-preview";
import { UploadingProgressIndicator } from "../uploading-progress-indicator";
import { UploadingFile } from "../../uploading-files.model";
import {
  HeadCell,
  Row,
  Table,
  TableCell,
  TableHead,
  TBody,
} from "ui/simple-table";

function UploadingMediaListComponent({
  uploads,
}: {
  className?: string;
  uploads: UploadingFile[];
}) {
  return (
    <Table>
      <TableHead>
        <Row>
          <HeadCell>File</HeadCell>
          <HeadCell>Progress</HeadCell>
        </Row>
      </TableHead>
      <TBody className="tw-text-xl">
        {uploads.map((item) => (
          <Row key={item.id}>
            <FileInfoCell>
              <UploadingMediaPreview className="tw-mr-4" file={item.file} />
              <p>{item.file.name}</p>
            </FileInfoCell>
            <TableCell>
              <UploadingProgressIndicator uploadingFile={item} />
            </TableCell>
          </Row>
        ))}
      </TBody>
    </Table>
  );
}

export const UploadingMediaList = styled(UploadingMediaListComponent)``;

const FileInfoCell = styled(TableCell)`
  display: flex;
  align-items: center;

  & > ${UploadingMediaPreview} {
    margin-right: 8px;
  }

  & > p {
    margin: 0;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 400;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
`;
