import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TextInput } from "ui";
import {
  useStorageAccountFilter,
  AccountFilterEnum,
} from "libs/storage/adapters";
import { useStore } from "effector-react";

import AccountPicker from "components/AccountPicker/AccountPicker";
import { CalendarEvents } from "./CalendarEvents";
import {
  $selectedAccountsIds,
  accountsSelected,
  useAllAccounts,
} from "entities/accounts";
import { getHolidaysCalendars } from "selectors/calendarFtilterSelectors";
import PostContentTypeFilter from "components/Posts/PostsFilter/PostContentTypeFilter";
import { PostStatusFilter } from "components/Posts/PostsFilter/PostStatusFilter";
import { getUser } from "selectors/commonSelectors";

import { getLocalStorageSelectedCalendarsIds } from "libs/storage/adapters";
import { filterAccountsByText } from "utils/accounts";
import { useTrackEvent } from "libs/analytics";
import { SkedIcon } from "ui/index";
import {
  getCountriesList,
  getHolidayCalendars,
  getHolidays,
} from "./CalendarFilterModel";
import { $selectedAccounts } from "entities/accounts/model/selected-accounts";
import { AccountPickerWithSearch } from "components/AccountPicker/AccountPickerWithSearch";
import { NEW_CAL_FILTERS_ACCOUNTS } from "constants/Events";

export const CalendarFilter = (props: {
  showFilter: boolean;
  onToggleFilter: () => void;
}): JSX.Element => {
  const [accountFilterText, setAccountsFilterText] = useState("");
  const { showFilter, onToggleFilter } = props;
  const dispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = useStorageAccountFilter(
    AccountFilterEnum.accounts
  );
  const selectedAccountsIds = useStore($selectedAccountsIds);
  const selectedAccounts = useStore($selectedAccounts);

  const accounts = useAllAccounts();
  const trackEvent = useTrackEvent();

  const holidaysCalendars = useSelector(getHolidaysCalendars);
  const user = useSelector(getUser);

  const selectedCalendarsIds = getLocalStorageSelectedCalendarsIds();
  const parsedCalendarsIds =
    selectedCalendarsIds?.length && JSON.parse(selectedCalendarsIds);

  useEffect(() => {
    dispatch(getCountriesList());
    dispatch(getHolidayCalendars(parsedCalendarsIds));
  }, []);

  useEffect(() => {
    const checkedCalendars = holidaysCalendars.filter(
      (holidaysCalendar) => holidaysCalendar.checked
    );

    dispatch(getHolidays(checkedCalendars));
  }, [holidaysCalendars, dispatch]);

  const accountsHandler = () => {
    setCurrentFilter(AccountFilterEnum.accounts);
  };

  const eventsHandler = () => {
    setCurrentFilter(AccountFilterEnum.events);
  };

  const handleAccountSelected = (accounts: string[]) => {
    trackEvent({
      eventName: NEW_CAL_FILTERS_ACCOUNTS,
      metadata: { count: accounts.length },
    });
    accountsSelected(accounts);
  };

  const handleAccountFilterTextChange = (val: string) => {
    setAccountsFilterText(val.trim());
  };

  const filteredAccounts = accounts.filter(
    filterAccountsByText(accountFilterText)
  );

  return (
    <>
      <FiltersExpandBadge showFilter={showFilter} onClick={onToggleFilter}>
        <span>Filters</span>
      </FiltersExpandBadge>

      <CalendarFilterPanel>
        <PanelContent showFilter={showFilter}>
          <Header>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <Icon icon="icon-close" onClick={onToggleFilter} />
            <span>Filters</span>
          </Header>
          <Filters>
            <FilterButton
              isActive={currentFilter === "accounts"}
              onClick={accountsHandler}
            >
              Accounts
            </FilterButton>
            <FilterButton
              isActive={currentFilter === "events"}
              onClick={eventsHandler}
            >
              Events
            </FilterButton>
          </Filters>

          <AccountsTab isActive={currentFilter === "accounts"}>
            <PostStatusFilterWrapper>
              {user?.isPostStatusEnabledForUser() && <PostStatusFilter />}
            </PostStatusFilterWrapper>
            <PostContentTypeFilterWrapper>
              <PostContentTypeFilter />
            </PostContentTypeFilterWrapper>
            <AccountPickerContainer>
              <AccountPickerWithSearch
                selectedAccounts={selectedAccounts}
                onSearch={handleAccountFilterTextChange}
                renderAccountPicker={() => (
                  <AccountPicker
                    accounts={filteredAccounts}
                    //TODO: fix after transfer AccountPicker to TS
                    // eslint-disable-next-line
                    // @ts-ignore
                    selectedAccountIds={selectedAccountsIds}
                    onAccountsChange={handleAccountSelected}
                  />
                )}
              />
            </AccountPickerContainer>
          </AccountsTab>

          {currentFilter === "events" && <CalendarEvents />}
        </PanelContent>
      </CalendarFilterPanel>
    </>
  );
};

const FiltersExpandBadge = styled("div")<{ showFilter: boolean }>`
  width: 40px;
  height: 120px;
  position: fixed;
  top: 360px;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: #0e1680;
  z-index: 9999;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transform: ${({ showFilter }) =>
    showFilter ? "translateX(40px)" : "translateX(0)"};
  transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9);

  & span {
    color: #fff;
    font-size: 2rem;
    display: inline-block;
    transform: rotate(270deg);
  }
`;

const CalendarFilterPanel = styled.aside``;

const PanelContent = styled("div")<{ showFilter: boolean }>`
  position: ${({ showFilter }) => (showFilter ? "relative" : "absolute")};
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 24vw;
  height: calc(100vh - var(--header-height));
  background: #f9f9f9;
  overflow-y: hidden;
  z-index: 15;
  border-left: 1px solid #eee;
  transform: ${({ showFilter }) =>
    showFilter ? "translateX(0)" : "translateX(24vw)"};
  transition: transform 0.15s cubic-bezier(0.2, 0.3, 0.25, 0.9);
`;

const Header = styled.header`
  position: relative;
  height: 50px;
  line-height: 50px;
  background: #0e1680;
  font-size: 16px;
  color: #fff;
  text-align: center;

  span:not(:first-child) {
    margin: auto;
  }
`;

const Icon = styled(SkedIcon)`
  margin: 0 2rem;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
`;

const Filters = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
  background: #fff;
  width: 100%;
  padding: 17px 10px;
`;

const FilterButton = styled("button")<{ isActive: boolean }>`
  height: 40px;
  flex-grow: 1;
  line-height: 40px;
  font-size: 0.9em;
  border-radius: unset;
  color: ${({ isActive }) => (isActive ? "#fff" : "#4f31ff")};
  border: ${({ isActive }) => (isActive ? "none" : "1px solid #4f31ff")};
  background: ${({ isActive }) => (isActive ? "#4f31ff" : "#fff")};
  ${({ isActive }) => isActive && "pointer-events: none"};

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const AccountsTab = styled.div`
  display: ${(props: { isActive?: boolean }) =>
    props.isActive ? "block" : "none"};
`;

const PostStatusFilterWrapper = styled.div`
  padding: 10px 10px 0 10px;
`;

const PostContentTypeFilterWrapper = styled.div`
  padding: 10px 10px 0 10px;
`;

const AccountPickerContainer = styled.div`
  height: calc(100vh - var(--header-height) - 244px);
  overflow-y: auto;
  padding: 20px;
`;

const AccountPickerSearchInput = styled(TextInput)`
  margin: 10px;
  width: calc(100% - 20px);
  font-size: 14px;
`;
