import _ from "lodash";
import React from "react";
import styled from "styled-components";

export function truncateSpans(
  spans: StyledTextSpan[],
  maxLength: number
): StyledTextSpan[] {
  const spansResult: StyledTextSpan[] = [];
  let totalLength = 0;
  for (const span of spans) {
    spansResult.push(span);
    totalLength += span.text.length;
    if (totalLength > maxLength) {
      break;
    }
  }

  if (spansResult.length <= 0) {
    return spansResult;
  }

  const lastSpan = _.last(spansResult);
  /**truncate text if last span is normal style */
  if (lastSpan?.style === "normal") {
    spansResult[spansResult.length - 1] = {
      ...lastSpan,
      text: lastSpan.text.substring(
        0,
        maxLength - (totalLength - lastSpan.text.length)
      ),
    };
  }
  return spansResult.filter((c) => !!c.text);
}

export function getComponentFromSpans(spans: StyledTextSpan[]): JSX.Element {
  return (
    <span>
      {spans.map((c, idx) => {
        switch (c.style) {
          case "link":
            return (
              <HashTagLink
                key={idx}
                target="_blank"
                rel="noopener noreferrer"
                href={c.text}
              >
                {c.text}
              </HashTagLink>
            );
          case "hashtag":
            return (
              <HashTagLink
                key={idx}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.linkedin.com/feed/hashtag/?keywords=${c.text.substring(
                  1
                )}`}
              >
                {c.text}
              </HashTagLink>
            );
          case "mention":
            return <MentionedSpan key={idx}>{c.text}</MentionedSpan>;
        }

        return <span key={idx}>{c.text}</span>;
      })}
    </span>
  );
}
export function parseLittleTextFormat(text: string): StyledTextSpan[] {
  try {
    text = replaceEscapeChar(text);
    const phases: ParsePhase[] = [
      { run: parseTextWithHashTags },
      { run: parseTextWithMentions },
      { run: parseTextWithLinks },
      { run: parseTextWithSimpleHashTags },
    ];
    let spans: StyledTextSpan[] = [{ style: "normal", text }];
    phases.forEach((phase) => {
      spans = _.flatMap(spans, (span) => {
        if (span.style === "normal") {
          return phase.run(span);
        }
        return [span];
      });
    });

    return spans;
  } catch (e) {
    console.warn(e);
  }
  return [{ style: "normal", text }];
}
interface ParsePhase {
  run(span: StyledTextSpan): StyledTextSpan[];
}
const regexes: [RegExp, string][] = [
  [/\\\|/g, "|"],
  [/\\\{/g, "{"],
  [/\\\}/g, "}"],
  [/\\@/g, "@"],
  [/\\\[/g, "["],
  [/\\\]/g, "]"],
  [/\\\(/g, "("],
  [/\\\)/g, ")"],
  [/\\</g, "<"],
  [/\\>/g, ">"],
  [/\\#/g, "#"],
  [/\\\\/g, "\\"],
  [/\\\*/g, "*"],
  [/\\_/g, "_"],
  [/\\~/g, "~"],
];
/** https://learn.microsoft.com/en-us/linkedin/marketing/integrations/community-management/shares/little-text-format?view=li-lms-2022-11#text */
function replaceEscapeChar(text: string): string {
  return regexes.reduce((text, reg) => text.replace(reg[0], reg[1]), text);
}

export const urlRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
);

function parseTextWithLinks(span: StyledTextSpan): StyledTextSpan[] {
  const matches = regexMatchAll(span.text, urlRegex);
  if (matches.length <= 0) {
    return [span];
  }

  return buildSpansWithMatchedRegex(span.text, matches, (m) => ({
    style: "link",
    text: `${m[0]}`,
  }));
}

function parseTextWithMentions(span: StyledTextSpan): StyledTextSpan[] {
  /** https://learn.microsoft.com/en-us/linkedin/marketing/integrations/community-management/shares/little-text-format?view=li-lms-2022-11#mentionelement */
  const mentionRegex = /@\[([^\]]+)\]\((urn:[^)]+)\)/g;
  const matches = regexMatchAll(span.text, mentionRegex);
  if (matches.length <= 0) {
    return [span];
  }

  return buildSpansWithMatchedRegex(span.text, matches, (m) => ({
    style: "mention",
    text: `${m[1]}`,
    mentionedUrn: m[2],
  }));
}

function parseTextWithHashTags(span: StyledTextSpan): StyledTextSpan[] {
  /** https://learn.microsoft.com/en-us/linkedin/marketing/integrations/community-management/shares/little-text-format?view=li-lms-2022-11#hashtagtemplate */
  const hashtagRegex = /\{hashtag\|\\?[#＃]\|([^}]+)\}/g;
  const matches = regexMatchAll(span.text, hashtagRegex);
  if (matches.length <= 0) {
    return [span];
  }

  return buildSpansWithMatchedRegex(span.text, matches, (m) => ({
    style: "hashtag",
    text: `#${m[1]}`,
  }));
}

function parseTextWithSimpleHashTags(span: StyledTextSpan): StyledTextSpan[] {
  /** https://stackoverflow.com/a/16942496 */
  const hashtagRegex = /#[a-zA-z0-9_]+/g;
  const matches = regexMatchAll(span.text, hashtagRegex);
  if (matches.length <= 0) {
    return [span];
  }

  return buildSpansWithMatchedRegex(span.text, matches, (m) => ({
    style: "hashtag",
    text: `${m[0]}`,
  }));
}

function buildSpansWithMatchedRegex(
  text: string,
  matches: RegExpExecArray[],
  makeSpan: (match: RegExpExecArray) => StyledTextSpan
): StyledTextSpan[] {
  const spans: StyledTextSpan[] = [];
  for (let idx = 0; idx < matches.length; idx++) {
    const match = matches[idx];
    const prevMatch = idx > 0 ? matches[idx - 1] : undefined;
    spans.push({
      style: "normal",
      text: text.substring(
        prevMatch ? prevMatch.index + prevMatch[0].length : 0,
        match.index
      ),
    });
    spans.push(makeSpan(match));
    if (idx >= matches.length - 1) {
      spans.push({
        style: "normal",
        text: text.substring(match.index + match[0].length, text.length),
      });
    }
  }

  return spans.filter((c) => !!c.text);
}

interface StyledTextSpan {
  style: "normal" | "hashtag" | "mention" | "link";
  text: string;
  mentionedUrn?: string;
}

function regexMatchAll(text: string, regex: RegExp) {
  let matched;
  const result: RegExpExecArray[] = [];
  do {
    matched = regex.exec(text);
    if (matched) {
      result.push(matched);
    }
  } while (matched);
  return result;
}

const HashTagLink = styled.a`
  color: #4f31ff;
  font-weight: bold;
  :visited,
  :link,
  :active,
  :hover {
    color: #4f31ff;
  }
`;
const MentionedSpan = styled.span`
  font-weight: bold;
`;
