import {
  INIT_MEDIA_LIBRARY_PAGE_ROUTINE,
  SELECT_COLLECTION_ROUTINE,
  UPDATE_COLLECTION_ROUTINE,
  DELETE_COLLECTION_USER_ROUTINE,
  DELETE_COLLECTION_ASSET_ROUTINE,
  DELETE_COLLECTION_ASSETS_ROUTINE,
  CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE,
  TOGGLE_SELECT_ALL_MEDIA,
  TOGGLE_SELECT_NONE_MEDIA,
  TOGGLE_COLLECTION_ASSET,
  TOGGLE_SIDEBAR_FILTERS,
  ADD_COLLECTION_ASSETS_ROUTINE,
  ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE,
  ADD_COLLECTION_ASSETS_FROM_STOCK_ROUTINE,
  ADD_COLLECTION_ASSET_ROUTINE,
  PRE_DOWNLOAD_ASSET_ROUTINE,
  UPDATE_COLLECTION_ASSET_ROUTINE,
  SHOW_CREATE_POST_ROUTINE,
  FILTER_ASSETS_ROUTINE,
  CHANGE_ASSET_PAGINATION_ROUTINE,
  RELOAD_ASSET_TAGS_ROUTINE,
  APPLY_BULK_TAGS_ROUTINE,
  ADD_COLLECTION_ROUTINE,
  DELETE_COLLECTION_ROUTINE,
  BULK_MOVE_ASSETS_ROUTINE,
  SELECT_COLLECTION_LAYOUT,
  CHANGE_LIBRARY_PAGE,
  GET_ALL_COLLECTION_ASSETS_ROUTINE,
  FILTER_SELECTED_ASSETS,
  RELOAD_ASSET_CURRENT_PAGE,
  LOAD_COLLECTIONS_ROUTINE,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";
import _ from "lodash";

// -------------- General --------------- //
export const initMediaLibraryPage = createAction(
  INIT_MEDIA_LIBRARY_PAGE_ROUTINE.TRIGGER
);

export const changeLibraryPage = createAction(
  CHANGE_LIBRARY_PAGE,
  function prepare(page) {
    return {
      payload: {
        page,
      },
    };
  }
);

export const toggleSidebarFilters = createAction(TOGGLE_SIDEBAR_FILTERS);

// -------------- Collections --------------- //
export const loadCollections = createAction(LOAD_COLLECTIONS_ROUTINE.TRIGGER);

export const selectCollection = createAction(
  SELECT_COLLECTION_ROUTINE.TRIGGER,
  function prepare(id, skipTracking = false) {
    return {
      payload: {
        id,
        skipTracking,
      },
    };
  }
);

export const updateCollection = createAction(
  UPDATE_COLLECTION_ROUTINE.TRIGGER,
  function prepare(collection) {
    return {
      payload: {
        collection,
      },
    };
  }
);

export const createCollection = createAction(
  ADD_COLLECTION_ROUTINE.TRIGGER,
  function prepare(name) {
    return {
      payload: {
        name,
      },
    };
  }
);

export const selectCollectionLayout = createAction(
  SELECT_COLLECTION_LAYOUT,
  function prepare(layout) {
    return {
      payload: {
        layout,
      },
    };
  }
);

export const deleteCollection = createAction(
  DELETE_COLLECTION_ROUTINE.TRIGGER,
  function prepare(id) {
    return {
      payload: {
        id,
      },
    };
  }
);

export const increaseCollectionAssetsCounter = createAction(
  ADD_COLLECTION_ASSET_ROUTINE.SUCCESS,
  function prepare(assetsAdded) {
    return {
      payload: {
        assetsAdded,
      },
    };
  }
);

// -------------- Collection Users --------------- //

export const createOrUpdateCollectionUser = createAction(
  CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE.TRIGGER,
  function prepare(collection, collectionUser) {
    return {
      payload: {
        collection: collection,
        collectionUser: collectionUser,
      },
    };
  }
);

export const deleteCollectionUser = createAction(
  DELETE_COLLECTION_USER_ROUTINE.TRIGGER,
  function prepare(collection, collectionUser) {
    return {
      payload: {
        collectionId: collection._id,
        collectionUserId: collectionUser._id,
      },
    };
  }
);

// -------------- Assets --------------- //

export const toggleSelectAll = createAction(TOGGLE_SELECT_ALL_MEDIA);
export const toggleSelectNone = createAction(TOGGLE_SELECT_NONE_MEDIA);

export const toggleCollectionAsset = createAction(
  TOGGLE_COLLECTION_ASSET,
  function prepare(asset) {
    return {
      payload: {
        asset,
      },
    };
  }
);

export const addCollectionAssetsFromFeed = createAction(
  ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE.TRIGGER,
  function prepare({ collectionId, feedItems, location, done }) {
    return {
      payload: {
        collectionId,
        feedItems,
        location,
        done,
      },
    };
  }
);

export const addCollectionAssetsFromStock = createAction(
  ADD_COLLECTION_ASSETS_FROM_STOCK_ROUTINE.TRIGGER,
  function prepare({ collectionId, stockItems, location, done }) {
    return {
      payload: {
        collectionId,
        stockItems,
        location,
        done,
      },
    };
  }
);

export const addCollectionAssets = createAction(
  ADD_COLLECTION_ASSETS_ROUTINE.TRIGGER,
  function prepare(collectionId, files) {
    return {
      payload: {
        collectionId,
        files,
      },
    };
  }
);

export const getAllCollectionAssets = createAction(
  GET_ALL_COLLECTION_ASSETS_ROUTINE.TRIGGER,
  function prepare(maxFiles) {
    return {
      payload: {
        maxFiles,
      },
    };
  }
);

export const filterSelectedAssets = createAction(FILTER_SELECTED_ASSETS);

export const updateCollectionAsset = createAction(
  UPDATE_COLLECTION_ASSET_ROUTINE.TRIGGER,
  function prepare(
    asset,
    file,
    filename,
    description,
    tags,
    source,
    sourceNotes,
    favorite
  ) {
    return {
      payload: {
        asset,
        file,
        filename,
        description,
        tags,
        source: { name: source, notes: sourceNotes },
        favorite,
      },
    };
  }
);

export const deleteCollectionAsset = createAction(
  DELETE_COLLECTION_ASSET_ROUTINE.TRIGGER,
  function prepare(collectionId, entryId, assetId) {
    return {
      payload: {
        collectionId,
        entryId,
        assetId,
      },
    };
  }
);

export const deleteCollectionAssets = createAction(
  DELETE_COLLECTION_ASSETS_ROUTINE.TRIGGER,
  function prepare(assets) {
    return {
      payload: {
        assets,
      },
    };
  }
);

export const preDownloadAsset = createAction(
  PRE_DOWNLOAD_ASSET_ROUTINE.TRIGGER,
  function prepare(asset) {
    return {
      payload: {
        asset,
      },
    };
  }
);

export const filterAssets = createAction(
  FILTER_ASSETS_ROUTINE.TRIGGER,
  function prepare(
    tags = [],
    name = "",
    description = "",
    sourceName = "",
    favorite = false,
    mediaType = null,
    usage = null,
    sort = "-created"
  ) {
    return {
      payload: {
        tags: !_.isEmpty(tags) ? tags : [],
        name,
        description,
        sourceName,
        favorite,
        mediaType,
        usage,
        sort,
      },
    };
  }
);

export const changeAssetPagination = createAction(
  CHANGE_ASSET_PAGINATION_ROUTINE.TRIGGER,
  function prepare(page, pageSize) {
    return {
      payload: {
        page,
        pageSize,
      },
    };
  }
);

export const reloadAssetCurrentPage = createAction(RELOAD_ASSET_CURRENT_PAGE);

export const reloadTags = createAction(RELOAD_ASSET_TAGS_ROUTINE.TRIGGER);

export const applyBulkTags = createAction(
  APPLY_BULK_TAGS_ROUTINE.TRIGGER,
  function prepare(assets, tags) {
    return {
      payload: {
        assets,
        tags,
      },
    };
  }
);

export const bulkMoveAssets = createAction(
  BULK_MOVE_ASSETS_ROUTINE.TRIGGER,
  function prepare(assets, collectionId) {
    return {
      payload: {
        assets,
        collectionId,
      },
    };
  }
);

// -------------- Posts --------------- //
export const showCreatePost = createAction(
  SHOW_CREATE_POST_ROUTINE.TRIGGER,
  function prepare(assets) {
    return {
      payload: {
        assets,
      },
    };
  }
);
